import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Tag } from "antd";
import React from "react";
import "../.././App.css";

const CustomToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#344563",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "inter",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  red: {
    fontSize: 10,
    fontWeight: 600,
    color: "#B92500",
    fontFamily: "inter",
    textAlign: "center",
  },
  green: {
    fontSize: 10,
    fontWeight: 600,
    color: "#006644",
    fontFamily: "inter",
    textAlign: "center",
  },
  yellow: {
    fontSize: 10,
    fontWeight: 600,
    color: "#172B4D",
    fontFamily: "inter",
    textAlign: "center",
  },
}));

function ReturnStatusText(props) {
  const classes = useStyles();

  return (
    <>
      {props.text !== undefined ? (
        <CustomToolTip title={props.text}>
          <Tag
            color={
              props.text.toLowerCase().startsWith("in_transit") ||
              props.text.toLowerCase().startsWith("returned")
                ? "red"
                : props.text.toLowerCase().startsWith("delivered")
                ? "green"
                : props.text.toLowerCase().startsWith("pending") ||
                  props.text.toLowerCase().startsWith("not delivered")
                ? "gold"
                : "red"
            }
          >
            {" "}
            {props.text.replace(/(.{15})..+/, "$1…")}
          </Tag>
        </CustomToolTip>
      ) : null}
    </>
  );
}

export default ReturnStatusText;
