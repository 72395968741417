import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Box, Button, Checkbox, Grid, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker, Radio } from "antd";
import { format } from "date-fns";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import "react-day-picker/lib/style.css";
import MaterialIcon from "react-google-material-icons";
import { connect } from "react-redux";
import ".././App.css";
import ApiWrapper from ".././utils/ApiWrapper.js";
import { abortController } from "../utils/ApiWrapper";
import CustomSearchHeader from "./CustomSearchHeader.js";
import PageHeader from "./PageHeader.js";
import { useHistory } from "react-router-dom";

const { RangePicker } = DatePicker;

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 44,
  },
  title: {
    flexGrow: 1,
  },
  marketplace: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
  },
  calendarButton: {
    minWidth: 160,
  },
  checkbox: {
    width: 16,
    height: 16,
    backgroundColor: "#2684FF",
  },
  syncButton: {
    marginStart: 26,
  },
  accountSelector: {
    marginTop: 44,
  },
});

const CreateCampaignButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
  },
  contained: {
    fontFamily: "inter",
    background: "#2684FF",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0065FF",
      color: "#FFFFFF",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);
const AccountSelectorButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
  },
  outlined: {
    borderColor: "#DFE1E6",
    background: "#FAFBFC",
    color: "#44444F",
    "&:hover": {
      background: "#0065FF",
      color: "#FFFFFF",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const ManualSyncButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
  },
  contained: {
    fontFamily: "inter",
    background: "#2684FF",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0065FF",
      color: "#FFFFFF",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const CustomCheckBox = withStyles({
  root: {
    paddingLeft: 9,
    paddingRight: 9,
    paddingTop: 9,
    paddingBottom: 9,
    color: "#2684FF",
    "&:checked": {
      color: "#2684FF",
    },
  },
})(Checkbox);

const CustomMenuItem = withStyles({
  root: {
    fontFamily: "inter",
    fontSize: 14,
    fontWeight: 500,
  },
  gutters: {
    paddingLeft: 0,
  },
})(MenuItem);

const quickDateFilters = [
  { name: "Today", id: "today" },
  { name: "Yesterday", id: "yesterday" },
  { name: "Last Week", id: "last-week" },
  { name: "Last Month", id: "last-month" },
];

function getUpdatedMarketplaceText(selectedMarketplaces) {
  var text;
  var selectedNumber = 0;
  for (var i = 0; i < selectedMarketplaces.length; i++) {
    if (selectedMarketplaces[i].checked === true) {
      selectedNumber++;
      text = selectedMarketplaces[i].name;
    }
  }

  if (selectedNumber > 1) {
    text = `Channel: ${selectedNumber} selected`;
  }
  if (selectedNumber === 0) {
    text = "Select Marketplace";
  }
  return text;
}

let SyncInterval;
function CustomFilterHeader(props) {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showManualSync, setShowManualSync] = React.useState(false);
  const [dateRange, setDateRange] = React.useState();
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [selectedToDate, setSelectedToDate] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (props.filter.dateFilter) {
      switch (props.filter.dateFilter) {
        case "today":
          updateDateAndSendCallback(
            moment().startOf("days").subtract(1, "milliseconds").utc().format(),
            moment().endOf("days").utc().format(),
            props.filter.reviewStatus
          );
          break;
        case "yesterday":
          updateDateAndSendCallback(
            moment()
              .startOf("days")
              .subtract(1, "milliseconds")
              .subtract(1, "day")
              .utc()
              .format(),
            moment().endOf("days").subtract(1, "day").utc().format(),
            props.filter.reviewStatus
          );
          break;
        case "last-week":
          updateDateAndSendCallback(
            moment()
              .startOf("days")
              .subtract(1, "milliseconds")
              .subtract(1, "week")
              .utc()
              .format(),
            moment().endOf("day").utc().format(),
            props.filter.reviewStatus
          );
          break;
        case "last-month":
          updateDateAndSendCallback(
            moment()
              .startOf("days")
              .subtract(1, "milliseconds")
              .subtract(1, "month")
              .utc()
              .format(),
            moment().endOf("days").utc().format(),
            props.filter.reviewStatus
          );
          break;
        default:
          updateDateAndSendCallback(
            moment().startOf("days").subtract(1, "milliseconds").utc().format(),
            moment().endOf("days").utc().format(),
            props.filter.reviewStatus
          );
          break;
      }
    } else if (props.filter.startDate && props.filter.endDate) {
      updateDateAndSendCallback(
        moment(props.filter.startDate).startOf("day").utc().format(),
        moment(props.filter.endDate).endOf("day").utc().format(),
        props.filter.reviewStatus
      );
    }
    CheckPendingSync();
  }, []);
  const SetManualPendingSyncTimeout = () => {
    SyncInterval = setInterval(() => {
      CheckPendingSync();
    }, 3000000); //3 sec, 3000000
  };
  const CheckPendingSync = () => {
    if (props.manualSyncModule) {
      let requestJsonBody = {
        module: props.manualSyncModule,
      };
      ApiWrapper("manualPendingSync", requestJsonBody).then(
        function (response) {
          setLoading(false);
          if (response.status) {
            if (!response.requests.length) {
              clearInterval(SyncInterval);
              SyncInterval = 0;
              setShowManualSync(true);
            } else {
              SetManualPendingSyncTimeout();
              setShowManualSync(false);
            }
            //props.reloadApi(data);
          }
        },
        function (error) {
          setLoading(false);
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    }
  };
  const handleMarketplaceMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMarketplaceMenuClose = (event, value) => {
    setAnchorEl(null);
  };

  const handleMarketplaceCheck = (event, row) => {
    let newArr = [...props.filter.marketplace];
    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i].id === row.id) {
        // newArr[i].checked = event.target.checked;
        if (event.target.checked) {
          newArr[i].checked = true;
        } else {
          newArr[i].checked = false;
        }
      }
    }
    abortController &&
      Object.keys(abortController).map((fetchCall) => {
        abortController[fetchCall].abort();
      });
    props.updateMarketplace({ marketplace: newArr });

    updateDateAndSendCallback(
      moment(selectedFromDate).startOf("day").utc().format(),
      moment(selectedToDate).endOf("day").utc().format(),
      props.filter.reviewStatus
    );
  };

  const onDateRangeSelect = (selectedDates) => {
    props.updateDateFilter({
      dateFilter: null,
      startDate: selectedDates[0],
      endDate: selectedDates[1],
    });

    if (selectedDates[0] && selectedDates[1]) {
      updateDateAndSendCallback(
        moment(selectedDates[0]).startOf("day").utc().format(),
        moment(selectedDates[1]).endOf("day").utc().format(),
        props.filter.reviewStatus
      );
    } else {
      handleQuickDateFilterType({}, "last-month");
    }
  };

  const onReviewTypeChanged = (newStatus) => {
    props.updateReviewFilter({ reviewStatus: newStatus });
    updateDateAndSendCallback(
      moment(selectedFromDate).startOf("day").utc().format(),
      moment(selectedToDate).startOf("day").utc().format(),
      newStatus
    );
  };

  const handleQuickDateFilterType = (event, newFilterValue) => {
    if (newFilterValue) {
      props.updateDateFilter({
        dateFilter: newFilterValue,
        startDate: null,
        endDate: null,
      });
      switch (newFilterValue) {
        case "today":
          updateDateAndSendCallback(
            moment().startOf("days").subtract(1, "milliseconds").utc().format(),
            moment().endOf("days").utc().format(),
            props.filter.reviewStatus
          );
          break;
        case "yesterday":
          updateDateAndSendCallback(
            moment()
              .startOf("days")
              .subtract(1, "milliseconds")
              .subtract(1, "day")
              .utc()
              .format(),
            moment().endOf("days").subtract(1, "day").utc().format(),
            props.filter.reviewStatus
          );
          break;
        case "last-week":
          updateDateAndSendCallback(
            moment()
              .startOf("days")
              .subtract(1, "milliseconds")
              .subtract(1, "week")
              .utc()
              .format(),
            moment().endOf("day").utc().format(),
            props.filter.reviewStatus
          );
          break;
        case "last-month":
          updateDateAndSendCallback(
            moment()
              .startOf("days")
              .subtract(1, "milliseconds")
              .subtract(1, "month")
              .utc()
              .format(),
            moment().endOf("days").utc().format(),
            props.filter.reviewStatus
          );
          break;
        default:
          updateDateAndSendCallback(
            moment().startOf("days").subtract(1, "milliseconds").utc().format(),
            moment().endOf("days").utc().format(),
            props.filter.reviewStatus
          );
          break;
      }
    }
  };

  const updateDateAndSendCallback = (fromDate, toDate, reviewTypeStatus) => {
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);

    var arrayToSend = [];
    props.filter.marketplace.forEach((item, i) => {
      if (item.checked) {
        arrayToSend.push(item);
      }
    });

    props.handleMarketplaceChange(
      arrayToSend,
      fromDate,
      toDate,
      reviewTypeStatus
    );
  };

  const handleManualSync = () => {
    setLoading(true);
    if (props.manualSyncModule) {
      let requestJsonBody = {
        module: props.manualSyncModule,
      };
      setShowManualSync(false);
      SetManualPendingSyncTimeout();
      // return;
      ApiWrapper("manualSync", requestJsonBody).then(
        function (response) {
          setLoading(false);
          if (response.status) {
            setShowManualSync(false);
            SetManualPendingSyncTimeout();
            //props.reloadApi(data);
          }
        },
        function (error) {
          setLoading(false);
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    }
  };
  function onChange(value, dateString) {
    setDatePickerOpen(true);
    setDateRange(dateString);
  }
  function onSubmit() {
    onDateRangeSelect(dateRange);
    setDatePickerOpen(false);
  }

  return (
    <>
      <div className="common-header">
        <div className={`payments-header`}>
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div className="flex justify-between">
              <Grid
                container
                className="flex items-center tablet:justify-between"
              >
                {/* <Grid item>
                  <PageHeader title={props.headerTitle} />
                </Grid> */}
                <Grid item>
                  <div
                    className={`${props.isLoading ? "cursor-not-allowed" : ""}`}
                  >
                    <div
                      className={`${
                        props.isLoading
                          ? "pointer-events-none cursor-not-allowed"
                          : ""
                      }`}
                    >
                      {/* <Grid container>
                        {props.filter.marketplace.map((row, i) =>
                          row.checked ? (
                            <div
                              key={`Grid-${i}`}
                              className="flex items-center font-Inter font-medium text-sm bg-white rounded ml-2"
                              style={{
                                padding: "2px 6px",
                                color: "#44444F",
                                border: "1px solid #DFE1E6",
                              }}
                            >
                              {row.name}
                              <div
                                onClick={(event) =>
                                  handleMarketplaceCheck(event, row)
                                }
                                className={"ml-2 w-3 cursor-pointer"}
                              >
                                <img src={Close} width={12} alt="close" />
                              </div>
                            </div>
                          ) : null
                        )}
                      </Grid> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Box mt={2}>
              <div className={classes.root}>
                <Grid container justify="space-between">
                  <Grid item className="flex">
                    <>
                      <div className="flex space-x-4">
                        <PageHeader title={props.headerTitle} />
                        {!props.channelHide && (
                          <div
                            className={`${
                              props.isLoading ? "cursor-not-allowed" : ""
                            }`}
                          >
                            <div
                              className={`${
                                props.isLoading
                                  ? "pointer-events-none cursor-not-allowed"
                                  : ""
                              }`}
                            >
                              <AccountSelectorButton
                                variant="outlined"
                                onClick={handleMarketplaceMenuButtonClick}
                              >
                                {getUpdatedMarketplaceText(
                                  props.filter.marketplace
                                )}
                                <ExpandMoreIcon />
                              </AccountSelectorButton>
                              <Menu
                                open={Boolean(anchorEl)}
                                keepMounted
                                anchorEl={anchorEl}
                                onClose={(event) =>
                                  handleMarketplaceMenuClose(event, "all")
                                }
                              >
                                <div
                                  className={`${
                                    props.isLoading ? "cursor-not-allowed" : ""
                                  }`}
                                >
                                  <div
                                    className={`${
                                      props.isLoading
                                        ? "pointer-events-none cursor-not-allowed"
                                        : ""
                                    }`}
                                  >
                                    {props.filter.marketplace.map((row) => (
                                      <CustomMenuItem key={row.id}>
                                        <CustomCheckBox
                                          checked={row.checked}
                                          color="default"
                                          onChange={(event) =>
                                            handleMarketplaceCheck(event, row)
                                          }
                                        />
                                        {row.name}
                                      </CustomMenuItem>
                                    ))}
                                  </div>
                                </div>
                              </Menu>
                            </div>
                          </div>
                        )}
                      </div>
                    </>

                    <>{props.customHeader}</>
                  </Grid>
                  <Grid item>
                    <Grid className="flex justify-end items-center pt-0 tablet:pt-2.5 flex-nowrap tablet:flex-wrap">
                      {props.showDateRanges !== false ? (
                        <div className="flex flex-wrap items-center">
                          <div
                            className={`${
                              props.isLoading ? "cursor-not-allowed" : ""
                            }`}
                          >
                            <div
                              className={`${
                                props.isLoading
                                  ? "pointer-events-none cursor-not-allowed"
                                  : ""
                              }`}
                            >
                              <Radio.Group
                                className="pr-4"
                                value={props.filter.dateFilter}
                                buttonStyle="solid"
                                size="large"
                                onChange={(e) =>
                                  handleQuickDateFilterType(e, e.target.value)
                                }
                              >
                                {quickDateFilters.map((row, i) => (
                                  <Radio.Button
                                    value={row.id}
                                    key={i}
                                    className="radioCls"
                                  >
                                    {row.name}
                                  </Radio.Button>
                                ))}
                              </Radio.Group>
                            </div>
                          </div>
                          <Box className="pt-0 tablet:pt-2.5">
                            <RangePicker
                              className="h-9 Border-radius-3"
                              placeholder={["Order from date", "Order to date"]}
                              onOpenChange={() => setDatePickerOpen(true)}
                              maskClos
                              dateRender={(current) => {
                                const style = {};
                                if (current.date() === 1) {
                                  style.border = "1px solid #1890ff";
                                  style.borderRadius = "50%";
                                }
                                return (
                                  <div
                                    className="ant-picker-cell-inner"
                                    style={style}
                                  >
                                    {current.date()}
                                  </div>
                                );
                              }}
                              disabled={props.isLoading}
                              disabledDate={(current) => {
                                return current < moment().subtract(3, "months");
                              }}
                              onChange={onChange}
                              value={[
                                props.filter.startDate
                                  ? moment(props.filter.startDate)
                                  : "",
                                props.filter.endDate
                                  ? moment(props.filter.endDate)
                                  : "",
                              ]}
                              open={datePickerOpen}
                              separator="-"
                              shouldCloseOnSelect={true}
                              renderExtraFooter={() => (
                                <div className="flex justify-end">
                                  <Button
                                    // type="primary"
                                    className="ml-2 pl-2 pr-3 pt-1 pb-1 text-sm rounded bg-blue-500 border-transparent border mb-1 mt-1 text-white"
                                    onClick={() => {
                                      onSubmit();
                                    }}
                                  >
                                    Submit
                                  </Button>
                                  <Button
                                    // type="primary"
                                    className="ml-2 pl-2 pr-3 pt-1 pb-1 text-sm rounded bg-blue-500 border-transparent border mb-1 mt-1 text-white"
                                    onClick={() => {
                                      setDatePickerOpen(false);
                                    }}
                                  >
                                    close
                                  </Button>
                                </div>
                              )}
                            />
                          </Box>
                        </div>
                      ) : null}
                      {props.showManualSync && (
                        <div>
                          <div className="pl-4 tablet:pl-0">
                            <ManualSyncButton
                              variant="contained"
                              disabled={!showManualSync}
                              onClick={() => {
                                if (!loading) {
                                  handleManualSync();
                                  props.handleManualSyncClick();
                                }
                              }}
                              style={{
                                background:
                                  loading || !showManualSync
                                    ? "#FAFBFC"
                                    : "#2684FF",
                                color:
                                  loading || !showManualSync
                                    ? "#717480"
                                    : "#fff",
                                // background: "#FAFBFC",
                                // color: "#717480",
                              }}
                              disableElevation
                            >
                              Manual Sync
                            </ManualSyncButton>
                            {props.syncDate && !loading && (
                              <div className="font-Inter text-xs text-SpaceBlack whitespace-nowrap absolute -ml-3">
                                Last sync {moment(props.syncDate).fromNow()}
                              </div>
                            )}
                          </div>
                          {loading && (
                            <div className="absolute top-10 right-2">
                              <LoadingOutlined
                                style={{ fontSize: 20, color: "#2684FF" }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {props.campaigns && (
                        <div>
                          <div className="pl-4 tablet:pl-0">
                            <CreateCampaignButton
                              variant="contained"
                              onClick={() => history.push(`/create-campaigns`)}
                              disableElevation
                            >
                              <PlusOutlined /> Create Campaign
                            </CreateCampaignButton>
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
        </div>
        <div
          style={{ position: "fixed", bottom: 20, right: 20 }}
          className="z-10 bk-white rounded-full cursor-pointer"
        >
          <div
            className="h-5 w-5 p-4 flex items-center justify-center rounded-full border-2 border-black opacity-50 z-10"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <MaterialIcon className="h-5 w-5" icon="keyboard_double_arrow_up" />
            {/* <img
              src={UpArrow}
              alt="up arrow"
              width={30}
              height={30}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            /> */}
          </div>
        </div>
        <div className={`${props.isLoading ? "cursor-not-allowed" : ""}`}>
          <div
            className={`relative ${
              props.isLoading ? " pointer-events-none" : ""
            }`}
          >
            {/* {props.isLoading && (
              <div className="absolute top-5 right-14">
                <LoadingOutlined style={{ fontSize: 20, color: "#2684FF" }} />
              </div>
            )} */}
            {props.showSearchBar && (
              <CustomSearchHeader
                onSearchTextEntered={props.onSearchTextEntered}
                searchFunction={props.searchFunction}
                onProfitToggleChange={props.onProfitToggleChange}
                showReviewToggle={props.showReviewToggle}
                selectedReviewStatus={props.filter.reviewStatus}
                onReviewTypeChanged={onReviewTypeChanged}
                showExportButton={props.showExportButton}
                getExportData={props.getExportData}
                publishButton={props.publishButton}
                showAllReviews={props.showAllReviews}
                searchInput={props.searchInput}
                campaigns={props.campaigns}
                columns={props.columns}
                rows={props.rows}
                searchHide={props.searchHide}
                exportFileName={props.exportFileName}
                bulkUpload={props.bulkUpload}
                onFilterUpdate={props.onFilterUpdate}
                customToggleProfit={props.customToggleProfit}
                customExportButton={props.customExportButton}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

CustomFilterHeader.defaultProps = {
  handleMarketplaceChange: undefined,
  headerTitle: "",
  showDateRanges: true,
  showManualSync: true,
  handleManualSyncClick: undefined,
  onSearchTextEntered: undefined,
  onProfitToggleChange: undefined,
  showReviewToggle: false,
  onReviewTypeChanged: undefined,
  showExportButton: true,
  showSearchBar: true,
  manualSyncModule: "",
  customHeader: <></>,
};

CustomFilterHeader.propTypes = {
  handleMarketplaceChange: PropTypes.func,
  headerTitle: PropTypes.string,
  showDateRanges: PropTypes.bool,
  showManualSync: PropTypes.bool,
  handleManualSyncClick: PropTypes.func,
  onSearchTextEntered: PropTypes.func,
  onProfitToggleChange: PropTypes.func,
  showReviewToggle: PropTypes.bool,
  onReviewTypeChanged: PropTypes.func,
  showExportButton: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  manualSyncModule: PropTypes.string,
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => {
  return {
    updateMarketplace: (marketplaceInfo) =>
      dispatch({
        type: "filter/updateMarketplace",
        payload: {
          marketplace: marketplaceInfo.marketplace,
        },
      }),
    updateDateFilter: (dateInfo) =>
      dispatch({
        type: "filter/updateDateFilter",
        payload: {
          dateFilter: dateInfo.dateFilter,
          startDate: dateInfo.startDate,
          endDate: dateInfo.endDate,
        },
      }),
    updateReviewFilter: (reviewInfo) =>
      dispatch({
        type: "filter/updateReviewFilter",
        payload: {
          reviewStatus: reviewInfo.reviewStatus,
        },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFilterHeader);
