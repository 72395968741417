import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducer.js";
import { composeWithDevTools } from "redux-devtools-extension";

const loggerMiddleware = storeAPI => next => action => {
  console.log("dispatching", action);
  let result = next(action);
  console.log("next state", storeAPI.getState());
  return result;
};

const middlewareEnhancer = composeWithDevTools(
  applyMiddleware(loggerMiddleware)
);
const store = createStore(rootReducer, middlewareEnhancer);

export default store;
