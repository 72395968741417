import { ThemeProvider } from "@material-ui/core/styles";
import "antd/dist/antd.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import IdleTimerContainer from "./components/SessionTimeOut/sessionTimeOut";
import { TableContext } from "./context/TableContent";
import { UserContext } from "./context/UserContext";
import "./axios/index.js";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./Routes";
import store from "./store";
import { theme } from "./utils/Themes";
import { CookiesProvider } from "react-cookie";

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  const [tableRows, setTableRows] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [company, setCompany] = React.useState({});

  return (
    <CookiesProvider>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <TableContext.Provider
          value={{
            rows: tableRows,
            setRows: setTableRows,
          }}
        >
          <UserContext.Provider
            value={{
              user,
              setUser,
              company,
              setCompany,
            }}
          >
            <Routes />
            <IdleTimerContainer />
          </UserContext.Provider>
        </TableContext.Provider>
      </Provider>
    </ThemeProvider>
    </CookiesProvider>
  );
};
ReactDOM.render(<App />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
