import { Button, Drawer, Form, Image, Input, Modal, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import AntTable from "../../components/AntTableV2";
import CloseIcon from "../../images/closeIcon.svg";
import PublishInventoryIcon from "../../images/icons/PublishInventory.svg";
import ApiWrapper from "../../utils/ApiWrapper";

const TitleContainer = ({ title, subTitle }) => {
  return (
    <div>
      <div className="inline-flex items-start justify-items-center mb-4 text-sm">
        <div className="font-Inter font-medium text-SpaceBlack">{title}</div>
        <div className="font-Inter font-normal text-SpaceBlack ml-2">
          {subTitle}
        </div>
      </div>
    </div>
  );
};
const DrawerContainer = ({
  setDrawerOpen,
  drawerOpen,
  product,
  sellerName,
  setModalOpen,
  setMarketplaceData,
  ownername,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(false);
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const onSaveRow = async (record) => {
    try {
      const row = await form.validateFields();
      let changedRow = {
        channel: record.channel,
        sku: record.sku,
        price: row[editIndex],
        // price:
      };
      setMarketplaceData([changedRow]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Drawer
      title={
        <>
          <div className="pt-1 pl-3 flex ">
            <div style={{ minWidth: 36 }}>
              <img width={36} height={36} src={product.product_image} />
            </div>
            <div className="ml-5 font-Inter font-medium text-lg text-OuterSpace">
              {product.product_name}
            </div>
          </div>
          <div className="pl-4 pr-4 grid gap-6">
            <Form form={form} component={false}>
              <div>
                <div>
                  <div className="flex justify-end">
                    <>
                      {sellerName?.flipkartSellerName === product?.buybox ||
                      sellerName?.amazonSellerName ===
                        product?.buybox ? null : (
                        <button
                          className="ml-3 pl-3  pr-3 pt-1 pb-1 text-xl  font-bold rounded bg-primaryGreenStroke border-transparent border mb-3 text-white "
                          onClick={() => {
                            setIsEditing(true);
                            setEditIndex(0);
                            form.setFieldsValue({
                              [0]:
                                parseInt(
                                  product?.seller_info[0]?.product_price
                                ) - 1,
                            });
                          }}
                        >
                          REPRICER
                        </button>
                      )}
                    </>
                    <>
                      <Modal
                        title="REPRICER"
                        visible={isEditing}
                        maskClosable={false}
                        onCancel={() => {
                          setIsEditing(false);
                        }}
                        footer={[
                          <div>
                            <Button
                              type="default"
                              onClick={() => {
                                setIsEditing(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                onSaveRow(product);
                                setModalOpen(true);
                                setIsEditing(false);
                              }}
                            >
                              Proceed
                            </Button>
                          </div>,
                        ]}
                      >
                        <div className="flex items-center justify-left">
                          <div className="font-Inter font-medium text-SpaceBlack w-40">
                            Current Price
                          </div>
                          <div className="flex mt-2">
                            <div>
                              {(product.seller_info || []).map(
                                (compitator_info, compitator_index) => {
                                  if (
                                    sellerName.flipkartSellerName ===
                                      compitator_info.seller_name ||
                                    sellerName.amazonSellerName ===
                                      compitator_info.seller_name
                                  ) {
                                    return (
                                      <Form.Item>
                                        <Input
                                          value={compitator_info.product_price}
                                          readOnly
                                        />
                                      </Form.Item>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center justify-left">
                          <div className="font-Inter font-medium text-SpaceBlack w-40">
                            Recommended Price
                          </div>
                          <div className="flex -ml-2">
                            <div>
                              <Form.Item
                                style={{
                                  margin: 0,
                                }}
                                name={0}
                                rules={[
                                  {
                                    required: true,
                                    message: `Product Price is required.`,
                                  },
                                ]}
                              >
                                <Input
                                  className="heightInitial text-left w-30 ml-2"
                                  ref={inputRef}
                                  type="number"
                                  onChange={(e) => {
                                    form.setFieldsValue({
                                      [0]: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </>
      }
      width={650}
      closable={false}
      onClose={() => {
        setDrawerOpen(false);
        setEditIndex(false);
      }}
      className="drawer-container"
      visible={drawerOpen}
    >
      {drawerOpen && (
        <div
          className="p-4 absolute -left-11 top-0 bg-white cursor-pointer"
          onClick={() => {
            setDrawerOpen(false);
            setEditIndex(false);
          }}
        >
          <img src={CloseIcon} width={14} height={14} />
        </div>
      )}

      <div className="pl-4 pr-4 grid grid-cols-2 gap-6">
        <Form form={form} component={false}>
          {(product.seller_info || []).map(
            (compitator_info, compitator_index) => {
              return (
                <div
                  key={`Compitator-${compitator_index}`}
                  className={`border border-platinum rounded-lg p-3 flex flex-col justify-between`}
                >
                  <div className="font-Inter font-medium text-SpaceBlack mb-4 text-base">
                    {compitator_info.seller_name}
                    {compitator_info.IsBuyBoxWinner == "Yes" && (
                      <Tag className="ml-2 mr-1 text-xs" color="green">
                        Owner
                      </Tag>
                    )}
                  </div>
                  <div>
                    <TitleContainer
                      title="Product Price:"
                      subTitle={`Rs. ${compitator_info.product_price}`}
                    ></TitleContainer>
                    <TitleContainer
                      title="Review:"
                      subTitle={`${compitator_info.feedback_count} Reviews`}
                    ></TitleContainer>
                    <TitleContainer
                      title="Rating:"
                      subTitle={`${compitator_info.feedback_rating}`}
                    ></TitleContainer>
                    <TitleContainer
                      title="Shipping Price:"
                      subTitle={`${compitator_info.shipping_price}`}
                    ></TitleContainer>
                    <TitleContainer
                      title="Shipping Time:"
                      subTitle={`${compitator_info.shipping_time}`}
                    ></TitleContainer>
                  </div>
                </div>
              );
            }
          )}
        </Form>
      </div>
      {/* <div className="pl-3 pr-3 border mt-2 mb-2"></div> */}
    </Drawer>
  );
};

export default function BuyBoxTab2(props) {
  const buyboxSellerName = JSON.parse(localStorage.getItem("user_info"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [ownerName, setOwnerName] = useState([]);

  const RefColumns = [
    {
      title: "Product",
      align: "center",
      field: "product_id",
      type: "string",
      width: 120,
      render: (record, value, rowIndex) => (
        <>
          {" "}
          <div
            className={`${
              record.channel === "Amazon-India"
                ? "amazon-logo-small"
                : record.channel === "Flipkart"
                ? "flipkart-logo-small"
                : null
            }`}
          />
          <div className="flex items-center justify-center">
            <Image
              width={30}
              height={30}
              src={record.product_image}
              fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
            />
              {" "}
              <Button className="-ml-3 text-sm" type="link">
                <a
                  href={
                    record.channel === "Amazon-India"
                      ? `https://www.amazon.in/dp/${record.product_id}`
                      : `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${record.product_id}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {record.product_id?.replace(/(.{15})..+/, "$1…") || ""}
                </a>
              </Button>
          </div>
        </>
      ),
      sorter: (a, b) => {
        a = (a.product_id || "").toLowerCase();
        b = (b.product_id || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product Name",
      align: "center",
      field: "product_name",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 160,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.product_name && record.product_name !== undefined ? (
          record.product_name.replace(/(.{45})..+/, "$1…")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.product_name || "").toLowerCase();
        b = (b.product_name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "SKU",
      align: "center",
      isTotal: false,
      field: "sku",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 100,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.sku !== undefined ? (
          record.sku.replace(/(.{15})..+/, "$1…")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.sku || "").toLowerCase();
        b = (b.sku || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Seller Name",
      align: "center",
      field: "seller_name",
      hidden: true,
    },
    {
      title: "Product Price",
      align: "center",
      field: "product_price",
      hidden: true,
    },
    {
      title: "Product Ratings",
      align: "center",
      field: "product_rating",
      hidden: true,
    },
    {
      title: "Owner Name",
      align: "center",
      field: "buyBox_owner",
      hidden: true,
    },

    {
      title: "",
      align: "center",
      field: "action",
      width: 175,
      render: (record, rowIndex, isLoading) => (
        <button
          className="pl-3 pr-3 pt-1 pb-1 rounded bg-GhostWhite border-platinum border "
          onClick={() => {
            setSelectedProduct(record);
            setDrawerOpen(true);
          }}
        >
          See Buybox Owner
        </button>
      ),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [marketplaceData, setMarketplaceData] = useState({});

  const handlePublish = async () => {
    let requestJsonBody = {
      listings: marketplaceData,
    };
    let reqData = {
      updated_at: new Date(),
      module_name: "BuyBox Repricer",
      updated_value: marketplaceData,
    };
    ApiWrapper("updateInventory_v2", requestJsonBody).then(
      function (response) {
        setDrawerOpen(false);
        if (response.status) {
          ApiWrapper("updateChangeLog", reqData).then(
            function (response) {},
            function (error) {}
          );
        }
      },
      function (error) {}
    );
  };

  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
  }) => {
    props.tableFilterData(paginationCondition, sortCondition, filterCondition);
  };

  useEffect(() => {
    if (props.activeTab === "1") {
      props.setColumn(RefColumns);
    }
  }, [props.activeTab]);
  return (
    <>
      <DrawerContainer
        product={selectedProduct}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        sellerName={buyboxSellerName}
        setModalOpen={setModalOpen}
        setMarketplaceData={setMarketplaceData}
        ownername={ownerName}
      />
      <Modal
        title={
          <div className="flex">
            <img
              src={PublishInventoryIcon}
              alt="map product icon"
              className={"mr-4"}
            />
            <span className={"text-lg text-SpaceBlack text-medium"}>
              Publish to Marketplace
            </span>
          </div>
        }
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        closable={true}
        footer={[
          <div className={"flex items-center justify-end mt-2"}>
            <button
              className={`ml-2 pl-3 pr-3 pt-1 pb-1 rounded bg-GhostWhite border-platinum border mb-3`}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className={`ml-3 pl-3 pr-3 pt-1 pb-1 rounded bg-primaryGreenStroke border-transparent border mb-3 text-white`}
              onClick={() => {
                handlePublish();
                setModalOpen(false);
              }}
            >
              Publish
            </button>
          </div>,
        ]}
      >
        <div>
          <span>
            The changes you have made will be published to marketplace
          </span>
        </div>
      </Modal>

      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          stickyHeader={{
            offsetHeader: 65,
          }}
          columns={RefColumns}
          dataSource={props.tableSorce}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={props.isLoading}
          hideSummary={true}
          onDataChange={onTableChange}
          totalRecords={props.pageCount}
        />
      </div>
    </>
  );
}
