import { Button, message, Select, Spin } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet"; 
import CustomAppBar from "../../components/CustomAppBar";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import { PostValues } from "../../utils/api-client";
import InSights from "./insights/insights";
import BuyBoxTab2 from './productList';
import BrandProtectorTab from './brandProtector';
import ApiWrapper from "../../utils/ApiWrapper";
import Tabs from "../../components/Tabs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ExportButton from "../../components/Styles/ExportButton";
import { useLocation } from "react-router-dom";

const { Option } = Select;
const TabsList = [
    "Summary",
    "Buy Box",
    "Brand Protector"
];
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export default function BuyBoxIndex() {

    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingTable, setIsLoadingTable] = React.useState(false);

    const [SelectSellers, setSelectSeller] = useState([]);
    const [sellersList, setSellerList] = useState([]);
    const [columns, setColumns] = React.useState([]);
    const [buyBoxData, setBuyboxdata] = useState([]);
    const [marketPlaces, setMarketPlaces] = useState([]);
    const [requestData, setRequestData] = React.useState({});
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [searchText, setSearchText] = useState("");
    const [startDate, seStarttDate] = React.useState({});
    const [endDate, setEndDate] = React.useState({});
    const [tableRes, setTableRes] = React.useState([]);
    const [isExportLoading, setIsExportLoading] = React.useState(false);
    const [buyBoxSummary, setBuyBoxSummary] = React.useState([]);
    const [callToActionId, setCallToActionId] = useState("");
    const [dashboard,setDashboard] = useState("")
    const [callToActionBool, setCallToActionBool] = React.useState(false);
    const [selectBool, setSelectBool] = React.useState(true)
    const [channelName, setChannelName] = React.useState("Amazon-India")
    const [sellerListSummaryTable, setSellerListSummaryTable] = React.useState([]);
    const [allSellerList, setAllSellerList] = React.useState([])
    const [summatySellerList, setSummatySellerList] = React.useState([])
    const [totalRecordsBuybox, setTotalRecordsBuybox] = React.useState(0);
    const location = useLocation();

    const getApiBuybox = async (requestData) => {
        let response = await PostValues("BuyBoxMonitorV2", requestData);
        return response;
    };
    const getApiBrandList = async (requestData) => {
        let response = await PostValues("getBrandListV2", requestData);
        return response;
    };
    const getExportApi = async (requestData) => {
        let response = await PostValues("getOrganisationReportApi", requestData);
        return response;
    };

    const handleMarketplaceChange = async (
        selectedMarketplaces,
        fromDate,
        toDate
    ) => {
        setIsLoading(true);
        setMarketPlaces(selectedMarketplaces);
        seStarttDate(fromDate);
        setEndDate(toDate)
        getAllData(selectedMarketplaces, fromDate, toDate)

    };
    const callToActionBuyBox = (tab, name) => {
        let data = {
            'id': name,
        }
        setTabValue(tab)
        setCallToActionId(data)
        setCallToActionBool(true)
    }

    React.useEffect(() => {
        if (location?.state?.record?.id == "salesDefence") {
            setTabValue(1)
        }
    }, [location])
    React.useEffect(() => {
        if (tabValue == 2)
            getAllData(marketPlaces, startDate, endDate, searchText);
    }, [callToActionId])
    const getAllData = async (
        selectedMarketplaces,
        fromDate,
        toDate,
        searchText,
        paginationCond,
        sortCond,
        filterCond = {}
    ) => {
        setIsLoading(true)
        setBuyBoxSummary([])
        if (searchText) {
            requestData.filters["search"] = searchText;
        }
        if (tabValue === 0) {
            let jsonbody = []
            for (let i = 0; i < selectedMarketplaces.length; i++) {
                let requestJsonBody = {
                    channels: [selectedMarketplaces[i].name],
                    pagination: {
                        pageNo: 0,
                        pageSize: 10,
                    }
                };
                jsonbody.push(ApiWrapper("buyboxInsights", requestJsonBody))
            }
            Promise.all(jsonbody).then(result => {
                onSuccessMergeChannel(result, true)
            })
        }
        if (tabValue === 1) {
            let requestData = {
                channels: selectedMarketplaces.map((mp) => mp.name),
                filters: {
                    ...filterCond,
                },
                ...(paginationCond
                    ? paginationCond
                    : {
                        pagination: {
                            pageNo: 0,
                            pageSize: 25,
                        },
                    }),
                ...(sortCond ? sortCond : {}),
            };
            if (callToActionBool) {
                requestData["metric"] = callToActionId.id;
            }
            if(location?.state?.record?.id == "salesDefence"){
                requestData["metric"] = "LostBuyBox";
            }
            setRequestData(requestData);
            setIsLoading(true);
            const data = await getApiBuybox(requestData);
            if (data.Items) {
                setBuyboxdata(data.Items);
                setSelectSeller(data?.other_sellers);
                setSellerList(data?.other_sellers);
                setTotalRecordsBuybox(data.pagination.count);
                setIsLoading(false);
            }
            if (!selectedMarketplaces.length) {
                setIsLoading(false);
                setBuyboxdata([]);
            }
        }
        if (tabValue === 2) {
            let requestData = {
                channels: selectedMarketplaces.map((mp) => mp.name),
                filters: {
                    ...filterCond,
                },
                ...(paginationCond
                    ? paginationCond
                    : {
                        pagination: {
                            pageNo: 0,
                            pageSize: 25,
                        },
                    }),
                ...(sortCond ? sortCond : {}),
            };
            setRequestData(requestData);
            setIsLoading(true);
            const data = await getApiBrandList(requestData);
            if (data.Items) {
                setBuyboxdata(data.Items);
                setSelectSeller(data.other_sellers);
                setSellerList(data.other_sellers);
                setTotalRecords(data.pagination.count);
                setIsLoading(false);
            }
            if (!selectedMarketplaces.length) {
                setIsLoading(false);
                setBuyboxdata([]);
            }
        }

    }

    const onSuccessMergeChannel = (result, status) => {
        if (result.length == 1) {
            var lost_buy_box_cal = result[0]?.lost_buybox_percentage == 0 ? ((result[0].lost_buybox_count / (result[0].lost_buybox_count / result[0].lost_buybox_percentage)) * 100) : result[0].lost_buybox_percentage;
            var no_buy_box_cal = result[0]?.no_buybox_percentage == 0 ? ((result[0].no_buybox_count / (result[0].no_buybox_count / result[0].no_buybox_percentage)) * 100) : result[0].no_buybox_percentage;
            let dataMerge = {
                'lost_buybox_count': result[0]?.lost_buybox_count,
                'lost_buybox_percentage': result[0]?.lost_buybox_percentage,
                'no_buybox_count': result[0]?.no_buybox_count,
                'no_buybox_percentage': result[0]?.no_buybox_percentage,
                'opportunity_lost': result[0]?.opportunity_lost,
                'LostBuyBoxChart': result[0]?.LostBuyBoxChart,
                'BuyBoxOwnersChart': result[0]?.BuyBoxOwnersChart,
                'AllSellersChart': result[0]?.AllSellersChart,
                "LastSyncAt": result[0]?.LastSyncAt,
                "status": status,
                'lostBuyBoxCal': lost_buy_box_cal,
                'noBuyBoxCal': no_buy_box_cal
            }
            setBuyBoxSummary(dataMerge)
            setIsLoading(false);
        }
        if (result.length == 2) {
            var lost_buy_box_cal = result[0]?.lost_buybox_percentage == 0 || result[1]?.lost_buybox_percentage == 0 ? result[0].lost_buybox_percentage + result[1].lost_buybox_percentage : (result[0].lost_buybox_count + result[1].lost_buybox_count) / ((result[0]?.lost_buybox_count / result[0]?.lost_buybox_percentage * 100) + (result[1]?.lost_buybox_count / result[1]?.lost_buybox_percentage) * 100) * 100
            var no_buy_box_cal = result[0]?.no_buybox_percentage == 0 || result[1]?.no_buybox_percentage == 0 ? result[0].no_buybox_percentage + result[1].no_buybox_percentage : (result[0]?.no_buybox_count + result[1]?.no_buybox_count) / ((result[0]?.no_buybox_count / result[0]?.no_buybox_percentage * 100) + (result[1]?.no_buybox_count / result[1]?.no_buybox_percentage) * 100) * 100
            let dataMerge = {
                'lost_buybox_count': result[0]?.lost_buybox_count + result[1]?.lost_buybox_count,
                'lost_buybox_percentage': result[0]?.lost_buybox_percentage + result[1]?.lost_buybox_percentage,
                'no_buybox_count': result[0]?.no_buybox_count + result[1]?.no_buybox_count,
                'no_buybox_percentage': result[0]?.no_buybox_percentage + result[1]?.no_buybox_percentage,
                'opportunity_lost': result[0]?.opportunity_lost + result[1]?.opportunity_lost,
                'LostBuyBoxChart': [...result[0]?.LostBuyBoxChart, ...result[1]?.LostBuyBoxChart],
                'BuyBoxOwnersChart': [...result[0]?.BuyBoxOwnersChart, ...result[1]?.BuyBoxOwnersChart],
                'AllSellersChart': [...result[0]?.AllSellersChart, ...result[1]?.AllSellersChart],
                "LastSyncAt": [...result[0]?.LastSyncAt, ...result[1]?.LastSyncAt],
                "status": status,
                "lostBuyBoxCal": lost_buy_box_cal,
                "noBuyBoxCal": no_buy_box_cal
            }
            setBuyBoxSummary(dataMerge)
            setIsLoading(false);
        }
    }

    const summatyTableApiCall = (channelName, paginationCond, sortCond,
        filterCond = {}) => {
        setChannelName(channelName)
        setIsLoadingTable(true)
        let requestJsonBody = {
            channels: [channelName],
            other_sellers: summatySellerList?.length ? summatySellerList : [],
            ...(paginationCond
                ? paginationCond
                : {
                    pagination: {
                        pageNo: 0,
                        pageSize: 10,
                    },
                }),
            filters: {
                ...filterCond,
            },
            ...(sortCond ? sortCond : {})
        };
        ApiWrapper("buyboxSummary", requestJsonBody).then(
            function (response) {
                if (response.status === true) {
                    setIsLoadingTable(false)
                    setTableRes(response.SummaryTable)
                    setTotalRecords(response.pagination.count);
                }
            },
            function (error) {
                console.error(error);
            }
        );

    }

    const onSearch = async (ds, searchText) => {
        let params = { ...requestData };
        if (params.filters) {
            params.filters.search = searchText;
        } else {
            params.filters = {
                search: searchText,
            };
        }
        setIsLoading(true);
        if (tabValue == 1) {
            const data = await getApiBuybox(params);
            setIsLoading(false);
            if (data.Items) {
                setTotalRecordsBuybox(data.pagination.count);
                return await data.Items;
            }
        }
        if (tabValue == 2) {
            const data = await getApiBrandList(params);
            setIsLoading(false);
            if (data.Items) {
                setTotalRecords(data.pagination.count);
                return await data.Items;
            }
        }
        return [];
    };

    const ExportAllData = async () => {
        try {
            setIsExportLoading(true)
            let exportRequestData = {
                params: {
                    channels: tabValue === 0 ? [channelName] : marketPlaces.map((mp) => mp.name),
                },
                reportType: tabValue === 0 && "BuyboxSummary" || tabValue === 1 && "Buybox" || tabValue === 2 && "Brandprotector",
            };
            const data = await getExportApi(exportRequestData);
            if (data.status) {
                setIsExportLoading(false);
                message.success('The export is being processed, please check the Downloads page ', 10);
            } else {
                setIsExportLoading(false)

            }
        } catch (error) {
            setIsExportLoading(false);
        }
    };

    const GetData = async (selectedSellerList) => {
        let requestData = {
            channels: marketPlaces.map((mp) => mp.name),
            other_sellers: selectedSellerList?.length ? selectedSellerList : [],
            pagination: {
                pageNo: 0,
                pageSize: 25,
            },
        };
        if (tabValue == 1) {
            setIsLoading(true);
            const data = await getApiBuybox(requestData);
            setRequestData(requestData);
            if (data.Items) {
                setBuyboxdata(data.Items);
                setSelectSeller(data.other_sellers);
                setSellerList(data.other_sellers);
                setTotalRecordsBuybox(data.pagination.count);
                setIsLoading(false);
            }
        }
        if (tabValue == 2) {
            setIsLoading(true);
            const data = await getApiBrandList(requestData);
            setRequestData(requestData);
            if (data.Items) {
                setBuyboxdata(data.Items);
                setSelectSeller(data.other_sellers);
                setSellerList(data.other_sellers);
                setTotalRecords(data.pagination.count);
                // setSellerName(data.name);
                setIsLoading(false);
            }
        }

        if (!marketPlaces.length) {
            setIsLoading(false);
            setBuyboxdata([]);
        }
    };

    const GetDataSummarySellerList = async (seller, channelName) => {
        setSummatySellerList(seller)
        let requestData = {
            channels: [channelName],
            other_sellers: seller?.length ? seller : [],
            pagination: {
                pageNo: 0,
                pageSize: 25,
            },
        };
        setIsLoadingTable(true)
        ApiWrapper("buyboxSummary", requestData).then(
            function (response) {
                if (response.status === true) {
                    setIsLoadingTable(false)
                    setTableRes(response.SummaryTable)
                    setTotalRecords(response.pagination.count);
                    setSellerListSummaryTable(response.other_sellers)
                }
            },
            function (error) {
                console.error(error);
            }
        );
    }

    React.useEffect(() => {
        getAllData(marketPlaces);
        if (tabValue == 0) {
            let requestJsonBodySummary = {
                channels: ["Amazon-India"],
                other_sellers: summatySellerList?.length ? summatySellerList : [],
                pagination: {
                    pageNo: 0,
                    pageSize: 10,
                }
            };
            summaryApi(requestJsonBodySummary)
        }
        if (tabValue != 1) {
            setCallToActionBool(false)
        }
        setSelectSeller([])
    }, [tabValue]);

    const summaryApi = (requestJsonBody) => {
        setIsLoadingTable(true)
        ApiWrapper("buyboxSummary", requestJsonBody).then(
            function (response) {
                if (response.status === true) {
                    setIsLoadingTable(false)
                    setTableRes(response.SummaryTable)
                    setTotalRecords(response.pagination.count);
                    setAllSellerList(response.other_sellers)
                    setSellerListSummaryTable(response.other_sellers)
                }
            },
            function (error) {
                console.error(error);
            }
        );
    }
    const summaryDataExport = () => {
        ExportAllData()
    }
    const summaryTableSearch = (val, channelName) => {
        let requestJsonBodySummary = {
            channels: [channelName],
            other_sellers: summatySellerList?.length ? summatySellerList : [],
            filters: {
                search: val
            },
            pagination: {
                pageNo: 0,
                pageSize: 10,
            }
        };
        summaryApi(requestJsonBodySummary)
    }

    return (
        <>
            <Helmet>
                <title>{"Forcesight"}</title>
            </Helmet>
            <CustomAppBar />
            {tabValue == 2 &&
                <div
                    className="relative"
                    style={{ marginLeft: "90px", marginRight: "90px" }}
                >
                    <div className="absolute top-10 right-0">
                        <a
                            href="https://www.amazon.in/report/infringement?"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button type="primary">Report</Button>
                        </a>
                    </div>
                </div>
            }
            <CustomFilterHeader
                headerTitle={
                    <div className="flex items-center justify-items-center">
                        BuyBox{" "}
                    </div>
                }
                handleMarketplaceChange={handleMarketplaceChange}
                searchFunction={onSearch}
                showExportButton={false}
                getExportData={ExportAllData}
                showManualSync={false}
                columns={columns}
                searchHide={tabValue != 0 ? true : false}
                onFilterUpdate={(data) => {
                    setBuyboxdata(data);
                }}
                showDateRanges={false}
                exportFileName="buyBoxMonitor"
                customToggleProfit={
                    tabValue !== 0 &&
                    <Spin spinning={isExportLoading}>
                        <div className="ml-6">
                            <ExportButton
                                variant="outlined"
                                onClick={ExportAllData}
                            >
                                Export to CSV
                            </ExportButton>
                        </div>
                    </Spin>
                }
                customHeader={tabValue != 0 ?
                    <Select
                        mode="multiple"
                        dropdownRender={(menu) => {
                            return (
                                <div>
                                    {
                                        selectBool &&
                                        <div
                                            className="text-primary text-sm cursor-pointer pb-2 border-b text-right p-2"
                                            onClick={() => {
                                                setSelectBool(false)
                                                setSelectSeller(sellersList);
                                            }}
                                        >
                                            Select all
                                        </div>
                                    }
                                    {
                                        !selectBool &&
                                        <div
                                            className="text-primary text-sm cursor-pointer pb-2 border-b text-left p-2"
                                            onClick={() => {
                                                setSelectBool(true)
                                                setSelectSeller([]);
                                            }}
                                        >
                                            Unselect all
                                        </div>
                                    }

                                    {menu}
                                    <div className="flex items-center justify-between p-2 border-t pb-1 mt-2">
                                        <div className="flex">
                                            <div
                                                className="text-primary text-sm cursor-pointer"
                                                onClick={() => {
                                                    setSelectSeller([]);
                                                    GetData([]);
                                                }}
                                            >
                                                Reset
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="bg-primary text-white rounded p-2 pl-3 text-xs cursor-pointer"
                                                onClick={() => {
                                                    GetData(SelectSellers);
                                                }}
                                            >
                                                Apply
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                        placeholder="BuyBox owner"
                        value={SelectSellers}
                        style={{ width: 200 }}
                        className="ml-5"
                        onChange={(e) => {
                            setSelectSeller(e);
                            //   GetData(e);
                        }}
                        maxTagCount={0}
                        maxTagPlaceholder={(values) => {
                            return `Show: ${values.length} seller${values.length > 1 ? "s" : ""
                                } selected`;
                        }}
                    >
                        {sellersList?.map((seller, sellerIndex) => {
                            return (
                                <Option key={`seller-${sellerIndex}`} value={seller}>
                                    {seller}
                                </Option>
                            );
                        })}
                    </Select> : null
                }
            />

            <div style={{ marginLeft: 20, marginRight: 20 }} className="-mt-3 ">
                <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <Tabs
                        value={tabValue}
                        list={TabsList}
                        onChange={(value) => {
                            setTabValue(value);
                        }}
                    />
                </div>
                <TabPanel value={tabValue} index={0}>
                    <InSights allSellerList={allSellerList} GetDataSummarySellerList={GetDataSummarySellerList} sellerListSummaryTable={sellerListSummaryTable} summaryTableSearch={summaryTableSearch} summaryDataExport={summaryDataExport} marketPlaces={marketPlaces} callToActionBuyBox={callToActionBuyBox} buyBoxSummary={buyBoxSummary} pageCount={totalRecords} summatyTableApiCall={summatyTableApiCall} isLoadingTable={isLoadingTable} activeTab={`${tabValue}`} isLoading={isLoading} summaryTable={tableRes} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <BuyBoxTab2
                        tableSorce={buyBoxData}
                        pageCount={totalRecordsBuybox}
                        searchText={searchText}
                        isLoading={isLoading}
                        activeTab={`${tabValue}`}
                        setColumn={(col) => {
                            setColumns(col);
                        }}
                        tableFilterData={(paginationCondition, sortCond, filterCond) => {
                            getAllData(
                                marketPlaces,
                                startDate,
                                endDate,
                                searchText,
                                paginationCondition,
                                sortCond,
                                filterCond
                            );
                        }} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <BrandProtectorTab
                        tableSorce={buyBoxData}
                        pageCount={totalRecords}
                        searchText={searchText}
                        isLoading={isLoading}
                        activeTab={`${tabValue}`}
                        setColumn={(col) => {
                            setColumns(col);
                        }}
                        tableFilterData={(paginationCondition, sortCond, filterCond) => {
                            getAllData(
                                marketPlaces,
                                startDate,
                                endDate,
                                searchText,
                                paginationCondition,
                                sortCond,
                                filterCond
                            );
                        }} />
                </TabPanel>
            </div>

        </>
    );
}
