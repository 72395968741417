import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Skeleton, Table } from "antd";
import { Bar } from "@ant-design/charts";
export const SentimentSliderTable = (props) => {
  const columns = [
    {
      title: () => (
        <span className="text-SpaceBlack text-sm font-Inter font-bold">
          Words
        </span>
      ),
      dataIndex: "words",
      key: "words",
    },
    {
      title: () => (
        <span className="text-SpaceBlack text-sm font-Inter font-bold">
          Frequency
        </span>
      ),
      dataIndex: "frequency",
      key: "frequency",
    },
  ];
  const data = [
    ...props?.data?.map((val) => ({
      words: val?.y,
      frequency: val?.COUNT,
    })),
  ];
  return (
    <Table
      columns={columns}
      bordered
      size="small"
      loading={props.isLoading}
      dataSource={data}
      pagination={false}
      className="cursor-pointer"
      onRow={(record, rowIndex) => ({
        onClick: (event) => props.setSentimentData(record.words),
      })}
      // scroll={{ y: 240 }}
    />
  );
};
export const SentimentGramsGraphs = (props) => {
  const config = {
    data: props?.data,
    xField: "COUNT",
    yField: "y",
    height: 250,
    meta: {
      y: {
        alias: "Words",
      },
      COUNT: {
        alias: "Count",
      },
    },
    yAxis: {
      title: {
        text: `Frequency-${props.title} word`,
        // rotate: 0,
      },
    },
    // minBarWidth: 20,
    // maxBarWidth: 20,
  };
  return props.isLoading ? (
    <Skeleton />
  ) : (
    <Bar
      {...config}
      onReady={(plot) => {
        plot.on("plot:click", (evt) => {
          const { x, y } = evt;
          const { xField } = plot.options;
          const tooltipData = plot.chart.getTooltipItems({ x, y });
          props.setBarValue(tooltipData[0]?.title);
        });
      }}
    />
  );
};
