import { Button, Modal, Select } from "antd";
import React from "react";
import CategoryIcon from "../../images/category_outlined.svg";
import ApiWrapper from "../../utils/ApiWrapper";
import OrderCellData from "./OrderCellData.js";

const { Option } = Select;

const TBCPopup = (props) => {
  const [mainCategory, setMainCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [isBoxOpen, setBoxOpen] = React.useState(false);
  const [reconciliation, setReconciliation] = React.useState("");

  const fetchAmazonCategories = async () => {
    let requestJsonBody = {};
    ApiWrapper("getAmazonCategories", requestJsonBody).then(
      function (response) {
        if (response.status === true) {
          setMainCategory("Select primary category");
          setSubCategory("Select secondary category");
          setCategories(response.data);
        }
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };

  React.useEffect(() => {
    (async () => {
      await fetchAmazonCategories();
    })();
  }, []);

  const onMainCategorySelected = (newMainCategory) => {
    setMainCategory(newMainCategory);
    for (var i = 0; i < categories.length; i++) {
      if (Object.keys(categories[i])[0] === newMainCategory) {
        setSubCategories(categories[i][`${newMainCategory}`]);
        break;
      }
    }
  };

  const onSubCategorySelected = (newSubCategory) => {
    setSubCategory(newSubCategory);

    updateCategoryAndGetRow(mainCategory, newSubCategory, props.row);
  };

  const updateCategoryAndGetRow = async (mainCategory, subCategory, row) => {
    let updateRequestBody = [
      {
        category: mainCategory + "%23" + subCategory,
        sku: props?.row.SKU,
      },
    ];
    ApiWrapper("updateAmazonCategory", updateRequestBody).then(
      function (updateResponse) {
        if (updateResponse.status === true) {
          let updatedRowRequestBody = {
            category: mainCategory + "%23" + subCategory,
            sku: props?.row.SKU,
            "order-id": props.row?.OrderID,
            apply: false,
          };
          ApiWrapper(
            "updateAmazonCategoryReconciliation",
            updatedRowRequestBody
          ).then(
            function (newRowResponse) {
              if (newRowResponse.status === true) {
                updateOrderData(
                  props.row?.OrderID,
                  newRowResponse.data.Reconciliation
                );
              }
            },
            function (error) {
              // message.error(
              //   "We encountered an issue. Please log out and log back in. "
              // );
            }
          );
        }
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };

  const updateOrderData = (orderId, newReconciliation) => {
    setReconciliation(newReconciliation);
    setBoxOpen(true);
  };

  const handleCancel = () => {};

  const handleApply = () => {
    props.onCategoryUpdate(mainCategory, subCategory, props.row);
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      footer={null}
    >
      <div>
        <div className="flex items-center">
          <img src={CategoryIcon} alt="category icon" className="mr-3" />
          <span className="text-SpaceBlack font-medium text-lg">
            Please set category
          </span>
        </div>
        <div className="mt-10">
          <Select
            className={"w-full"}
            value={mainCategory}
            size={"large"}
            onChange={(value) => onMainCategorySelected(value)}
          >
            {categories.map((mainCategory, i) => (
              <Option
                key={`Category-${i}`}
                value={Object.keys(mainCategory)[0]}
              >
                {Object.keys(mainCategory)[0]}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mt-5">
          <Select
            className="w-full"
            value={subCategory}
            size={"large"}
            onChange={(value) => onSubCategorySelected(value)}
          >
            {subCategories.map((subCategory, i) => (
              <Option key={`SubCat-${i}`} value={subCategory.key}>
                {subCategory.key} -{" "}
                {JSON.stringify(subCategory.value).replace(/[{""}]/g, "")}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mb-8">
          {isBoxOpen && (
            <div className="shadow-md border mt-5 bg-white bg-opacity-5 rounded">
              <OrderCellData
                row={props.row}
                title={props.title}
                breakup={props.breakup}
                type={props.type}
                marketplace={props.marketplace}
                isReconcilicationAvailable={true}
                reconciliation={reconciliation[`${props.type}`]}
              />
            </div>
          )}
        </div>
        {subCategory !== "" && (
          <div className="mb-5">
            <div className=" font-normal ">
              {`The following ${mainCategory} - ${subCategory} will be applied to
            this order and will be applied automatically to all future orders of
            the SKU ${props?.row?.SKU || ""}`}
            </div>
          </div>
        )}
        <div className={``}>
          <div className="text-right flex items-center justify-end">
            <div className="ml-3">
              <Button onClick={props.onClose}>Cancel</Button>
            </div>
            <div className="ml-3">
              <Button type="primary" onClick={handleApply}>
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TBCPopup;
