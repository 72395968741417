import {
  Box,
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Modal,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import MaterialSelect from "../../components/FormItem/MaterialSelect.js";
import CategoryIcon from "../../images/category_outlined.svg";
import ApiWrapper from "../../utils/ApiWrapper";

const CustomMenuItem = withStyles({
  root: {
    fontWeight: 500,
    fontSize: 14,
  },
})((props) => <MenuItem {...props} />);

const CancelButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
  },
  contained: {
    fontFamily: "inter",
    background: "#F4F5F7",
    borderColor: "#DFE1E6",
    borderRadius: 5,
    color: "#253858",
    "&:hover": {
      background: "#0065FF",
      color: "#253858",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const ApplyButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
  },
  contained: {
    fontFamily: "inter",
    background: "#57D9A3",
    borderColor: "#4ECD98",
    borderRadius: 5,
    color: "#FFFFFF",
    "&:hover": {
      background: "#0065FF",
      color: "#FFFFFF",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: 550,
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid #F4F5F7",
    borderRadius: 5,
  },
  middle: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  bottom: {
    paddingBottom: 32,
    paddingLeft: 32,
    paddingRight: 32,
  },
  top: {
    paddingTop: 32,
    paddingLeft: 32,
    paddingRight: 32,
  },
  headerImage: {
    marginRight: 16,
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: "#44444F",
  },
});

const CategoryConfigPopup = (props) => {
  const { open, handleClose, sku } = props;
  const [mainCategory, setMainCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const classes = useStyles();

  const fetchAmazonCategories = async () => {
    let requestJsonBody = {};
    ApiWrapper("getAmazonCategories", requestJsonBody).then(
      function (response) {
        if (response.status === true) {
          setMainCategory("Select primary category");
          setSubCategory("Select secondary category");
          setCategories(response.data);
        }
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };

  React.useEffect(() => {
    (async () => {
      await fetchAmazonCategories();
    })();
  }, []);

  const onMainCategorySelected = (newMainCategory) => {
    setMainCategory(newMainCategory);
    for (var i = 0; i < categories.length; i++) {
      if (Object.keys(categories[i])[0] === newMainCategory) {
        setSubCategories(categories[i][`${newMainCategory}`]);
        break;
      }
    }
  };

  const onSubCategorySelected = (newSubCategory) => {
    setSubCategory(newSubCategory);
  };

  const handleCancel = () => {};

  const handleApply = () => {
    props.onCategoryUpdate(mainCategory, subCategory, sku);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.root}>
        <div className={classes.top} style={{ marginBottom: 28 }}>
          <img
            src={CategoryIcon}
            alt="category icon"
            className={classes.headerImage}
          />
          <span className={classes.modalTitle}>Please set category</span>
        </div>
        <div className={classes.middle}>
          <MaterialSelect
            className={classes.padding}
            fullWidth
            value={mainCategory}
            onChange={(e) => onMainCategorySelected(e.target.value)}
          >
            {categories.map((mainCategory, i) => (
              <CustomMenuItem key={i} value={Object.keys(mainCategory)[0]}>
                {Object.keys(mainCategory)[0]}
              </CustomMenuItem>
            ))}
          </MaterialSelect>
        </div>
        <div className={classes.middle}>
          <MaterialSelect
            fullWidth
            value={subCategory}
            onChange={(e) => onSubCategorySelected(e.target.value)}
          >
            {subCategories.map((subCategory, i) => (
             <CustomMenuItem key={i} value={subCategory.key}>
             {subCategory.key} - { JSON.stringify(subCategory.value).replace(/[{""}]/g,'') }
           </CustomMenuItem>
            ))}
          </MaterialSelect>
        </div>
        {subCategory !== "" && (
          <div
            style={{
              marginLeft: 24,
              marginRight: 24,
              marginBottom: 24,
            }}
          >
            <Typography>
              {`The following ${mainCategory} - ${subCategory} will be applied to
            this order and will be applied automatically to all future orders of
            the SKU ${props.sku}`}
            </Typography>
          </div>
        )}
        <div className={classes.bottom}>
          <Grid container justify="flex-end">
            <Box ml={1}>
              <CancelButton
                variant="contained"
                onClick={handleClose}
                disableElevation
              >
                Cancel
              </CancelButton>
            </Box>
            <Box ml={1}>
              <ApplyButton
                variant="contained"
                onClick={handleApply}
                disableElevation
              >
                Apply
              </ApplyButton>
            </Box>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

export default CategoryConfigPopup;
