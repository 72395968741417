import { Button, Drawer, Image, Select } from "antd";
import React, { useState,useEffect } from "react";
import { withRouter } from "react-router-dom";
import AntTable from "../../components/AntTableV2";
import CloseIcon from "../../images/closeIcon.svg"; 
import { PostValues } from "../../utils/api-client";


const TitleContainer = ({ title, subTitle }) => {
  return (
    <div className="inline-flex items-start justify-items-center mb-4 text-sm">
      <div className="font-Inter font-medium text-SpaceBlack">{title}</div>
      <div className="font-Inter font-normal text-SpaceBlack ml-2">
        {subTitle}
      </div>
    </div>
  );
};
const DrawerContainer = ({ setDrawerOpen, drawerOpen, product, ownername }) => {
  return (
    <Drawer
      title={
        <div className="pt-1 pl-3 flex items-center ">
          <div style={{ minWidth: 36 }}>
            <img width={36} height={36} src={product.product_image} />
          </div>
          <div className="ml-5 font-Inter font-medium text-lg text-OuterSpace">
            {product.product_name}
          </div>
          <div className="ml-6">
            <a
              href="https://www.amazon.in/report/infringement?"
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary">Report</Button>
            </a>
          </div>
        </div>
      }
      width={650}
      closable={false}
      onClose={() => {
        setDrawerOpen(false);
      }}
      className="drawer-container"
      visible={drawerOpen}
    >
      {drawerOpen && (
        <div
          className="p-4 absolute -left-11 top-0 bg-white cursor-pointer"
          onClick={() => {
            setDrawerOpen(false);
          }}
        >
          <img src={CloseIcon} width={14} height={14} />
        </div>
      )}

      <div className="pl-4 pr-4 grid grid-cols-2 gap-6">
        {(product.seller_info || []).map(
          (compitator_info, compitator_index) => {
            return (
              <div
                key={`Compitator-${compitator_index}`}
                className={`border border-platinum rounded-lg p-3 flex flex-col justify-between`}
              >
                <div className="font-Inter font-medium text-SpaceBlack mb-4 text-base">
                  {compitator_info.seller_name}
                </div>
                <div>
                  <TitleContainer
                    title="Product Price:"
                    subTitle={`Rs. ${compitator_info.product_price}`}
                  ></TitleContainer>
                  <TitleContainer
                    title="Review:"
                    subTitle={`${compitator_info.feedback_count} Reviews`}
                  ></TitleContainer>
                  <TitleContainer
                    title="Rating:"
                    subTitle={`${compitator_info.feedback_rating}`}
                  ></TitleContainer>
                  <TitleContainer
                    title="Shipping Price:"
                    subTitle={`${compitator_info.shipping_price}`}
                  ></TitleContainer>
                  <TitleContainer
                    title="Shipping Time:"
                    subTitle={`${compitator_info.shipping_time}`}
                  ></TitleContainer>
                </div>
              </div>
            );
          }
        )}
      </div>
      {/* <div className="pl-3 pr-3 border mt-2 mb-2"></div> */}
    </Drawer>
  );
};

function BrandProtectorTab(props) {
  const [open, setOpen] = React.useState(false);

  const RefColumns = [
    {
      title: "Product",
      align: "center",
      field: "product_id",
      type: "string",
      width: 120,
      render: (record, value, rowIndex) => (
        <>
          {" "}
          <div
            className={`${
              record.channel === "Amazon-India"
                ? "amazon-logo-small"
                : record.channel === "Flipkart"
                ? "flipkart-logo-small"
                : null
            }`}
          />
          <div className="flex items-center justify-center">
            <Image
              width={30}
              height={30}
              src={record.product_image}
              fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
            />
              {" "}
              <Button className="-ml-3 text-sm" type="link">
                <a
                  href={
                    record.channel === "Amazon-India"
                      ? `https://www.amazon.in/dp/${record.product_id}`
                      : `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${record.product_id}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {record.product_id?.replace(/(.{15})..+/, "$1…") || ""}
                </a>
              </Button>
          </div>
        </>
      ),
      sorter: (a, b) => {
        a = (a.product_id || "").toLowerCase();
        b = (b.product_id || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product Name",
      align: "center",
      field: "product_name",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 180,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.product_name && record.product_name !== undefined ? (
          record.product_name.replace(/(.{45})..+/, "$1…")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.product_name || "").toLowerCase();
        b = (b.product_name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "SKU",
      align: "center",
      isTotal: false,
      field: "sku",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 100,
      defaultSortOrder: "descend",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.sku !== undefined ? (
          record.sku
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.sku || "").toLowerCase();
        b = (b.sku || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Seller Name",
      align: "center",
      field: "seller_name",
      hidden: true,
    },
    {
      title: "Product Price",
      align: "center",
      field: "product_price",
      hidden: true,
    },
    {
      title: "Product Ratings",
      align: "center",
      field: "product_rating",
      hidden: true,
    },

    {
      title: "",
      align: "center",
      field: "action",
      width: 175,
      render: (record, rowIndex, isLoading) => {
        return (
          <button
            className="pl-3 pr-3 pt-1 pb-1 rounded bg-GhostWhite border-platinum border "
            onClick={() => {
              onSaveRow(record);
            }}
          >
            Other Sellers
          </button>
        );
      },
    },
  ];

  const [brandList, setBrandList] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [ownerName, setOwnerName] = useState([]);

  const onSaveRow = async (record) => {
    setOpen(true);
    let productDetails = {
      ...record,
      // compitator_infos: record.compitator_infos,
    };
    setSelectedProduct(productDetails);

    try {
    } catch (error) { }
  };

  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
  }) => {
    props.tableFilterData(paginationCondition, sortCondition, filterCondition);
  };

  useEffect(() => {
    if (props.activeTab === "2") {
      props.setColumn(RefColumns);
    }
  }, [props.activeTab]);
  return (
    <>
      <div
        className="relative"
        style={{ marginLeft: "90px", marginRight: "90px" }}
      >
        <div className="absolute top-10 right-0">
          <a
            href="https://www.amazon.in/report/infringement?"
            target="_blank"
            rel="noreferrer"
          >
            <Button type="primary">Report</Button>
          </a>
        </div>
      </div>

      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          columns={RefColumns}
          dataSource={props.tableSorce}
          stickyHeader={{
            offsetHeader: 65,
          }}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={props.isLoading}
          hideSummary={true}
          onDataChange={onTableChange}
          totalRecords={props.pageCount}
        />
      </div>
      <div>
        <DrawerContainer
          product={selectedProduct}
          drawerOpen={open}
          setDrawerOpen={setOpen}
          ownername={ownerName}
        />
      </div>
    </>
  );
}
export default withRouter(BrandProtectorTab);
