import { Image, message, Spin } from "antd";
import { compact, uniq } from "lodash";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import AntTable from "../../../components/AntTableV2";
import CustomAppBar from "../../../components/CustomAppBar";
import CustomFilterHeader from "../../../components/CustomFilterHeader";
import ExportButton from "../../../components/Styles/ExportButton";
import { PostValues } from "../../../utils/api-client";
import ViewFulfillmentCenters from "./ViewFulfillmentCentersV2";



const StorageLocations = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [lastSyncAt, setLastSyncAt] = React.useState("");
  const [marketPlaces, setMarketPlaces] = React.useState([]);
  const [requestData, setRequestData] = React.useState({});
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [isExportLoading, setIsExportLoading] = React.useState(false);

  const getStorageLocations = async (requestData) => {
    let response = await PostValues("getStorageLocationsV2", requestData);
    return response;
  };
  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };

  const handleMarketplaceChange = async (
    selectedMarketplaces
  ) => {
    setMarketPlaces(selectedMarketplaces);
    setIsLoading(true);

    let requestData = {
      channels: selectedMarketplaces.map((mp) => mp.name),
    
      pagination: {
        pageNo: 0,
        pageSize: 25,
      },
    };
    const data = await getStorageLocations(requestData);
    setRequestData(requestData);

    if(data.Items) {
      setTableData([...data.Items]);
      setTotalRecords(data.pagination.count);
      setIsLoading(false);
    }
  };

  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
    extra,
  }) => {
    setIsLoading(true);

    let requestData = {
      channels: marketPlaces.map((mp) => mp.name),
    
      filters: {
        ...filterCondition,
      },
      ...paginationCondition,
      ...sortCondition,
    };
    const data = await getStorageLocations(requestData);
    setRequestData(requestData);

    if (data.Items) {
      setTableData([...data.Items]);
      setTotalRecords(data.pagination.count);
      setIsLoading(false);
    }
  };
  const ExportAllData = async () => {
    try {
      setIsExportLoading(true)
      let exportRequestData = {
        params: {
          channels: marketPlaces.map((mp) => mp.name),
          // fromDate: fromDate,
          // toDate: toDate,
        },
        reportType: "StorageLocation",
      };
      const data = await getExportApi(exportRequestData);
      if (data.status) {
      setIsExportLoading(false);
      message.success('The export is being processed, please check the Downloads page ', 10);
      }else{
      setIsExportLoading(false)
  
      }
  } catch (error) {
      setIsExportLoading(false);
  }
  };
  const onSearch = async (ds, searchText) => {
    let params = { ...requestData };
    if (params.filters) {
      params.filters.search = searchText.trim();
    } else {
      params.filters = {
        search: searchText.trim(),
      };
    }
    setIsLoading(true);
    const data = await getStorageLocations(params);
    setIsLoading(false);
    if (data.Items) {
      setTotalRecords(data.pagination.count);
      return await data.Items;
    }
    return [];
  };
  

  const columns = [
    {
      title: "SKU",
      align: "center",
      field: "sku",
      defaultSortOrder: "ascend",
      isFilterable: true,
      width: 120,
      fixed: "left",
      render: (record, value, rowIndex) => (
        <>
          {" "}
          <div
            className={`${
              record.channel === "Amazon-India"
                ? "amazon-logo-small"
                : record.channel === "Flipkart"
                ? "flipkart-logo-small"
                : null
            }`}
          />
          <div className="flex items-center justify-center">
            <Image
              width={36}
              height={36}
              src={record.imageURL}
              fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
            />
            <span className="mt-2 ml-2">{`${record.sku}`}</span>
          </div>
        </>
      ),
      sorter: (a, b) => {
        a = (a.sku || "").toLowerCase();
        b = (b.sku || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "Master SKU",
      align: "center",
      field: "masterSKU",
      isFilterable: true,
      width: 80,
      fixed: "left",
      render: (record, value, rowIndex) => record.masterSKU || "-",
      sorter: (a, b) => {
        a = (a.masterSKU || "").toLowerCase();
        b = (b.masterSKU || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "Product Name",
      align: "center",
      field: "name",
      isFilterable: true,
      width: 200,
      ellipsis: true,
      tooltip: true,
      sorter: (a, b) => {
        a = (a.name || "").toLowerCase();
        b = (b.name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "Total",
      align: "center",
      field: "quantity",
      type: "number",
      isFilterable: true,
      width: 120,
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "",
      align: "center",
      field: "",
      width: 150,
      render: (record, rowIndex, isLoading) => (
        <ExportButton
          variant="outlined"
          onClick={() => {
            setIsOpen(true);
            setSelectedData(record);
          }}
        >
          View Fulfillment Centers
        </ExportButton>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <div>
        <CustomAppBar />
        <CustomFilterHeader
          headerTitle="Storage Locations"
          handleMarketplaceChange={handleMarketplaceChange}
          showDateRanges={false}
          searchFunction={onSearch}
          getExportData={ExportAllData}
          showExportButton={false}
          showManualSync={false}
          isLoading={isLoading}
          rows={tableData}
          searchHide={true}
          columns={columns}
          syncDate={lastSyncAt}
          exportFileName="storageLocations"
          manualSyncModule={"INVENTORY"}
          onFilterUpdate={(data) => {
            setTableData(data);
          }}
          customToggleProfit={
            <Spin spinning={isExportLoading}>
              <div className="ml-6">
                <ExportButton
                  variant="outlined"
                  onClick={ExportAllData}
                >
                  Export to CSV
                </ExportButton>
              </div>
            </Spin>
          }
        />
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <AntTable
            columns={compact(columns)}
            dataSource={tableData}
            stickyHeader={{ offsetHeader: 65 }}
            rowKey={(row, rowIndex) => rowIndex}
            isLoading={isLoading}
            searchText={[searchText]}
            hideSummary
            totalRecords={totalRecords}
            onDataChange={onTableChange}
            // scroll={{ x: "calc(700px + 50%)" }}
          />
        </div>
      </div>
      {isOpen && (
        <ViewFulfillmentCenters
          open={isOpen}
          setOpen={setIsOpen}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
    </React.Fragment>
  );
};

export default StorageLocations;
