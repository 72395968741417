import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const ExportButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "inter",
  },
  outlined: {
    fontFamily: "Inter",
    background: "#2684FF",
    color: "white",
    borderColor: "#DFE1E6",
    "&:hover": {
      background: "#3232D7",
      color: "white",
      borderColor: "#C1C7D0",
    },
    "&:focus": {
      borderColor: "#C1C7D0",
      outlined: "none",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export default ExportButton;
