import { Modal, Spin, Table } from "antd";
import React from "react";
import ExportTableButton from "../../components/AntTable/ExportTable";

export default function CtaModal(props) {

  return (
    <>
      <Modal
        className="overflow-hidden rounded-xl p-0 shadow-2xl m-2.5"
        title={props.title}
        visible={props.isCtaModalActive}
        footer={null}
        centered
        onCancel={props.hideCtaModal}
        bodyStyle={{ padding: "10px" }}
        width={1450}
      >
        {" "}
        {/* <div className=" flex flex-row-reverse	">
          <ExportTableButton
            columns={props.columns}
            fileName={props.title}
            dataSource={props.data}
          />
        </div> */}
        <Spin tip="loading... " spinning={props.loading}>
        {props.data && (
          <Table
            scroll={{ x: true }}
            columns={props.columns}
            dataSource={props.data}
            size="medium"
            bordered
            pagination={{
              current: props.currentPageNumber,
              total: props.value,
            }}
            onChange={(pagination, filters, sorter, extra) => {
              if (props.onDataChange) {
                props.onDataChange({
                  pagination,
                  filters,
                  sorter,
                  extra,
                });
              }
            }}
          />
        )}
        </Spin>
      </Modal>
    </>
  );
}
