import { Skeleton, Spin, Table, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import Highlighter from "react-highlight-words";
import { TableContext } from "../../context/TableContent";
import TableFilterDropdown from "../TableFilterDropdown";
import "./index.css";

const summary = (colTotal, columns, expandable) => {
  return (
    <Table.Summary
      fixed
      style={{
        background: "#F4F5F7",

        border: "1px solid #DFE1E6",
      }}
    >
      <Table.Summary.Row>
        {columns.map((column, columnIndex) => {
          if (
            columnIndex === 0 ||
            column.dataIndex === "Sno" ||
            column["dataIndex"] === "checkbox"
          ) {
            if (expandable) {
              return (
                <>
                  {/* <Table.Summary.Cell >
                    {" "}
                  </Table.Summary.Cell> */}
                  <Table.Summary.Cell index={0} colSpan={2}>
                    Total
                  </Table.Summary.Cell>
                </>
              );
            } else {
              return (
                <Table.Summary.Cell index={0} colSpan={1}>
                  {column["dataIndex"] === "checkbox" ? "" : "Total"}
                </Table.Summary.Cell>
              );
            }
          } else {
            let checkIndex = expandable ? 1 : 1;
            if (columnIndex >= checkIndex) {
              if (!column.isTotal) {
                return <Table.Summary.Cell></Table.Summary.Cell>;
              }
              // let total = colTotal[column.dataIndex];
              let total = column.total;
              return (
                <Table.Summary.Cell
                  className={
                    column.isTotalCurrency || column.isNegative
                      ? total < 0 || (column.isNegative && total)
                        ? "text-center text-red-600 "
                        : "text-center"
                      : "text-center"
                  }
                >
                  {!isNaN(total)
                    ? Number(total) && total % 1 !== 0
                      ? column.isTotalCurrency
                        ? total?.toLocaleString("en-IN", {
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency: "INR",
                          })
                        : (total || 0).toFixed() + (column.isPercent ? "%" : "")
                      : column.isTotalCurrency
                      ? total?.toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: "INR",
                        })
                      : (total || 0) + (column.isPercentageTotal ? "%" : "")
                      ? column.isNegative
                        ? "-" +
                          total?.toLocaleString("en-IN", {
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency: "INR",
                          })
                        : total.toLocaleString("en-IN", {
                            maximumFractionDigits: 0,
                            currency: "INR",
                          })
                      : column.isPercent
                      ? total?.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "INR",
                        })
                      : (total || 0) + (column.isPercentageTotal ? "%" : "")
                    : "-"}
                </Table.Summary.Cell>
              );
            }
          }
        })}
      </Table.Summary.Row>
    </Table.Summary>
  );
};

const HighlightWrapper = (props) => {
  return (
    <Tooltip placement="topLeft" title={props.text}>
      {props.isSearchable && !props.render ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#2E87FC",
            color: "#FFFFFF",
            padding: 0,
          }}
          searchWords={props.searchText || []}
          autoEscape
          textToHighlight={props.textToHighlight}
        ></Highlighter>
      ) : props.render ? (
        props.render()
      ) : (
        props.text
      )}
    </Tooltip>
  );
};

function AntTable(props) {
  const [isRender, setIsRender] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [colTotal, setColTotal] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const TableConsumerContext = useContext(TableContext);
  let tempCol = [...props.columns]
    .map((column) => {
      if (props.onDataChange) {
        if (column.filters) {
          column.onFilter = () => {
            return true;
          };
        }
        if (column.sorter) {
          column.sorter = true;
        }
      }
      if (!column.dataIndex && column.field) {
        let isSearchable =
          column.searchPriority || column.onSearch || column.isSearchable;

        if (!column.hidden)
          return {
            ...column,
            dataIndex: column.field,
            isSearchable: isSearchable,
            editable: column.editable,
            filterDropdown: (column.filters || []).length
              ? TableFilterDropdown
              : null,
            render: props.isLoading
              ? () => (
                  <Skeleton
                    active
                    size="small"
                    key={column.dataIndex}
                    title={true}
                    paragraph={false}
                  />
                )
              : column.render
              ? (text, record, index) => (
                  <HighlightWrapper
                    textToHighlight={(
                      column.render(record, currentIndex + index, false) || ""
                    ).toString()}
                    text={text}
                    render={() => {
                      return column.render(record, currentIndex + index, false);
                    }}
                    isSearchable={isSearchable}
                    searchText={props.searchText}
                  />
                )
              : (text, record, index) => (
                  <HighlightWrapper
                    textToHighlight={(text || "").toString()}
                    searchText={props.searchText}
                    isSearchable={isSearchable}
                    text={text}
                  />
                ),
          };
      } else {
        if (!column.hidden) return column;
      }
    })
    .filter((data) => data);

  // useEffect(() => {

  //   setColumns(tempCol);
  // }, [props.columns, props.isLoading, props.searchText]);

  const onChange = (pagination, filters, sorter, extra) => {
    if (props.onDataChange) {
      let filterObj = {};
      if (Object.keys(filters).length) {
        Object.keys(filters).map((field) => {
          if (filters[field]) {
            filterObj[field] = filters[field].reduce(
              (prev, curr) => prev.concat(curr),
              []
            );
          }
        });
      }

      let sortCondition = {};
      if (sorter && sorter.field) {
        sortCondition = {
          sort: {
            [sorter.field]: sorter.order === "descend" ? -1 : 1,
          },
        };
      }

      let filterCondition = {};
      if (Object.keys(filterObj).length) {
        Object.keys(filterObj).map((field) => {
          filterCondition[field] = {
            IN: filterObj[field],
          };
        });
      }

      let paginationCondition = {
        pagination: {
          pageNo: pagination.current - 1,
          pageSize: pagination.pageSize,
          count: pagination.total,
        },
      };

      props.onDataChange({
        paginationCondition,
        sortCondition,
        filterCondition,
        extra,
      });
    }

    TableConsumerContext.setRows(extra.currentDataSource);
  };

  return (
    <React.Fragment>
      {props.isDataLoading && (
        <div className="relative">
          <div className="absolute bottom-1 right-3">
            <Spin />
          </div>
        </div>
      )}
      <div
        className={`${
          props.isDataLoading ? "isTableLoading" : ""
        } header-table-layout`}
      >
        <Table
          columns={tempCol}
          sticky={props.stickyHeader}
          components={props.components}
          size={"small"}
          bordered
          dataSource={
            props.isLoading
              ? [{}, {}, {}, {}, {}, {}, {}, {}]
              : [...props.dataSource]
          }
          scroll={props.scroll}
          onRow={props.onRow}
          rowSelection={props.rowSelection}
          rowClassName={props.rowClassName}
          showSorterTooltip={false}
          // showSorterTooltip={isRender}
          rowKey={props.rowKey}
          // sortDirections={isRender ? ["ascend", "descend"] : ["ascend"]}
          expandable={
            props.expandable
              ? {
                  ...props.expandable,
                  ...{
                    expandedRowKeys: selectedRowKeys,
                    onExpandedRowsChange: (expandedRows) => {
                      var keys = [];
                      keys.push(
                        expandedRows.length !== 0
                          ? expandedRows.pop()
                          : expandedRows
                      );
                      setSelectedRowKeys(keys);
                      // console.log(keys)
                    },
                  },
                }
              : null
          }
          summary={
            props.hideSummary || props.isLoading || props.isDataLoading
              ? null
              : (pageData) => summary(colTotal, tempCol, props.expandable)
          }
          pagination={
            props.isLoading
              ? false
              : {
                  size: "small",
                  defaultPageSize: props.pageSizeSet ? 10 : 25,
                  pageSizeOptions: [25, 50, 100],
                  style: props.paginationStyle,
                  // total: props.dataSource.length,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {},
                  showTotal: (total, range) => {
                    setCurrentIndex(range[0]);
                    return <div>{`${range[0]}-${range[1]} of ${total}`}</div>;
                  },
                  total: props.totalRecords,
                }
          }
          onChange={onChange}
        />
      </div>
    </React.Fragment>
  );
}
AntTable.defaultProps = {
  hideSummary: false,
  isDataLoading: false,
  paginationStyle: {},
};
// AntTable.propTypes = {
//
// };

export default AntTable;
