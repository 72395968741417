import { Box, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Anchor, Col, Row } from "antd";
import React from "react";
import LossSKU from "../../../images/icons/LossSKU.svg";
import LowStock from "../../../images/icons/LowStock.svg";
import SellerReview from "../../../images/icons/SellerReview.svg";
import ProductReview from "../../../images/icons/ProductReview.svg";

const { Link } = Anchor;

const useStyles = makeStyles((theme) => ({
  // ctaContainer: {
  //   marginTop: 16,
  //   display: "flex"
  // },
  ctaLabelText: {
    fontSize: 16,
    fontWeight: 600,
    color: "#44444F",
    fontFamily: "inter",
    textAlign: "left",
    // marginTop: 1,
  },
  ctaValueText: {
    fontSize: 24,
    fontWeight: 600,
    color: "#171725",
    fontFamily: "inter",
    textAlign: "left",
  },
  ctaTitleText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
  },
  rowDivider: {
    borderTop: "1px solid rgba(223, 225, 230, 0.5)",
  },
  columnPointer: {
    cursor: "pointer",
  },
}));

export default function CallToActionReviewInsights(props) {
  const classes = useStyles();

  const dataArr = [
    {
      id: "WithoutRatingsReviews",
      title: "# Active products without Reviews/Ratings",
      call_to_action_params: "WithoutRatingsReviews",
      value: props?.summaryData?.lifetime_ratings_reviews_zero_count || 0,
      icon: LossSKU,
    },
    {
      id: "LessThan10Ratings",
      title: "# Active products < 10 Ratings",
      call_to_action_params: "LessThan10Ratings",
      value: props?.summaryData?.lifetime_ratings_less_than_10_count || 0,
      icon: ProductReview,
    },
    {
      id: "LessThan10Reviews",
      title: "# Active products < 10 Reviews",
      call_to_action_params: "LessThan10Reviews",
      value: props?.summaryData?.lifetime_reviews_less_than_10_count || 0,
      icon: SellerReview,
    },
    {
      id: "NegativeRatings",
      title: "Products with Life Time Ratings < 4 Stars",
      call_to_action_params: "NegativeRatings",
      value: props?.summaryData?.lifetime_stars_less_than_4_count || 0,
      icon: LowStock,
    },
  ];

  const showExpandedModal = (record) => {
    props.callToActionReview(1, record.id);
  };

  const getDataColumn = (isLoading, data, value) => {
    return (
      <div className={`${isLoading ? "cursor-not-allowed" : ""}`}>
        <div
          className={`relative ${
            props.isLoading ? " pointer-events-none" : ""
          }`}
        >
          <div
            className={classes.columnPointer}
            onClick={() => showExpandedModal(data)}
          >
            <div>
              {data.icon && (
                <Typography
                  style={{
                    borderRadius: "50%",
                    padding: "10px",
                    backgroundColor: "#f7fbfc",
                    width: "fit-content",
                  }}
                >
                  {" "}
                  <img src={data.icon} alt="callToAction" className="w-6" />
                </Typography>
              )}
            </div>
            {isLoading && data.icon ? (
              <div className="item-left">
                <div className="item-title" />
              </div>
            ) : data.value === undefined || data.value === null ? (
              <Typography className={classes.ctaValueText}>{"-"}</Typography>
            ) : (
              <Typography className={classes.ctaValueText}>
                {data.value}
              </Typography>
            )}
            <Typography className={classes.ctaTitleText}>
              {data.title}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const getDataRow = (isLoading, data1, data2, data3, data4) => {
    return (
      <>
        <Row>
          <Col span={4} style={{ padding: 24, marginLeft: 18 }}>
            {getDataColumn(isLoading, data1)}
          </Col>
          <Col span={2} />
          <Col span={4} style={{ padding: 24 }}>
            {getDataColumn(isLoading, data2)}
          </Col>
          <Col span={2} />
          <Col span={4} style={{ padding: 24 }}>
            {getDataColumn(isLoading, data3)}
          </Col>
          <Col span={2} />
          <Col span={4} style={{ padding: 24 }}>
            {getDataColumn(isLoading, data4)}
          </Col>
          <Col span={2} />
        </Row>
      </>
    );
  };

  const getDataTable = (isLoading, data1, data2, data3, data4, className) => {
    return (
      <Box
        border={1}
        borderRadius={5}
        borderColor="rgba(223, 225, 230, 1)"
        color="red"
        className={className}
      >
        <Card elevation={0} style={{ backgroundColor: "#ebf5fa" }}>
          {getDataRow(isLoading, data1, data2, data3, data4)}
          {/* {getDataRow(isLoading, data5, data6, data7, data8)} */}
        </Card>
      </Box>
    );
  };

  return (
    <>
      <div>
        <Typography className={classes.ctaLabelText}>
          Call to action
        </Typography>
      </div>
      <Box className="flex mt-1 flex-nowrap tablet:flex-wrap gap-5 tablet:gap-0">
        <div className="w-full tablet:w-full">
          {getDataTable(
            props.isLoading,
            dataArr[0],
            dataArr[1],
            dataArr[2],
            dataArr[3]
          )}
        </div>
      </Box>
    </>
  );
}
