import { ExclamationCircleOutlined } from "@ant-design/icons";
import { makeStyles, Modal } from "@material-ui/core";
import { message } from "antd";
import React from "react";
import ExportButton from "../../../components/Styles/ExportButton";
import { DangerButton } from "../../../components/Styles/StyledButton";
import ApiWrapper from "../../../utils/ApiWrapper";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: 550,
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid #F4F5F7",
    padding: 32,
    borderRadius: 5,
  },
  headerImage: {
    marginRight: 16,
    color: "#DE350B",
    fontSize: 20,
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: "#44444F",
  },
  modalContent: {
    fontFamily: "Inter",
    fontSize: 14,
    color: "#717480",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 28,
  },
  marginRight: {
    marginRight: 14,
  },
});

const DeleteCampaign = (props) => {
  const classes = useStyles();

  const handleCancel = () => {
    props.setOpen(false);
  };

  const handleDelete = () => {
    ApiWrapper("deleteCampaign", { campaign_id: props.campaign_id }).then(
      (response) => {
        message.success("Campaign deleted successfully");
        props.refetchList();
        props.setOpen(false);
      },
      (error) => {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={handleCancel}
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.root}>
        <div
          style={{ marginBottom: 22, display: "flex", alignItems: "center" }}
        >
          <ExclamationCircleOutlined className={classes.headerImage} />
          <span className={classes.modalTitle}>Delete Campaign</span>
        </div>
        <span className={classes.modalContent}>
          Are you sure you want to delete <b>Future Campaign</b>? This action is
          not reversible.
        </span>
        <div className={classes.buttonContainer}>
          <ExportButton
            variant="outlined"
            className={classes.marginRight}
            onClick={handleCancel}
          >
            Cancel
          </ExportButton>
          <DangerButton variant="outlined" onClick={handleDelete}>
            Delete
          </DangerButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteCampaign;
