import moment from "moment";

export const AdvertisementColumn = [
  {
    title: "Channel",
    align: "center",
    key: "channel",
    render: (record, rowIndex, isLoading) =>
      record.channel === "Amazon-India" ? (
        <div className="amazon-logo-small" />
      ) : record.channel === "Flipkart" ? (
        <div className="flipkart-logo-small" />
      ) : null,
  },
  {
    title: "Settlement Id",
    align: "center",
    key: "settlement_id",
    render: (record, rowIndex, isLoading) =>
      record.settlement_id !== undefined ? record.settlement_id : "-",
  },

  {
    title: "Ads Cost",
    align: "center",
    key: "ads_cost",
    render: (record, rowIndex, isLoading) =>
      record.ads_cost !== undefined
        ? record.ads_cost?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
  {
    title: "Deposit Date",
    align: "center",
    key: "deposit_date",
    defaultSortOrder: "descend",
    render: (record, rowIndex, isLoading) =>
      record.deposit_date !== undefined
        ? moment(record?.deposit_date).format("DD-MM-YYYY")
        : "-",
        sorter: (a, b) => {
          a = a.deposit_date
            ? a.deposit_date !== "-"
              ? new Date(
                  (a.deposit_date || "").replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    "$2/$1/$3"
                  )
                ).getTime()
              : 0
            : 0;
          b = b.deposit_date
            ? b.deposit_date !== "-"
              ? new Date(
                  (b.deposit_date || "").replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    "$2/$1/$3"
                  )
                ).getTime()
              : 0
            : 0;
          return a - b;
        },
        sortDirections: ["descend", "ascend", "descend"],
  },
];

export const ReserveAdjustments = [
  {
    title: "Channel",
    align: "center",
    key: "channel",
    render: (record, rowIndex, isLoading) =>
      record.channel === "Amazon-India" ? (
        <div className="amazon-logo-small" />
      ) : record.channel === "Flipkart" ? (
        <div className="flipkart-logo-small" />
      ) : null,
  },
  {
    title: "Settlement Id",
    align: "center",
    key: "settlement_id",
    render: (record, rowIndex, isLoading) =>
      record.settlement_id !== undefined ? record.settlement_id : "-",
  },

  {
    title: "Previous Reserve",
    align: "center",
    key: "previous_reserve",
    render: (record, rowIndex, isLoading) =>
      record.previous_reserve !== undefined
        ? record.previous_reserve?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
  {
    title: "Current Reserve",
    align: "center",
    key: "current_reserve",
    render: (record, rowIndex, isLoading) =>
      record.current_reserve !== undefined
        ? record.current_reserve?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
  {
    title: "Reserve Adjsutment",
    align: "center",
    key: "reserve_adjustments",
    render: (record, rowIndex, isLoading) =>
      record.reserve_adjustments !== undefined
        ? record.reserve_adjustments?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
  {
    title: "Deposit Date",
    align: "center",
    key: "deposit_date",
    defaultSortOrder: "descend",
    render: (record, rowIndex, isLoading) =>
      record.deposit_date !== undefined
        ? moment(record?.deposit_date).format("DD-MM-YYYY")
        : "-",
        sorter: (a, b) => {
          a = a.deposit_date
            ? a.deposit_date !== "-"
              ? new Date(
                  (a.deposit_date || "").replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    "$2/$1/$3"
                  )
                ).getTime()
              : 0
            : 0;
          b = b.deposit_date
            ? b.deposit_date !== "-"
              ? new Date(
                  (b.deposit_date || "").replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    "$2/$1/$3"
                  )
                ).getTime()
              : 0
            : 0;
          return a - b;
        },
        sortDirections: ["descend", "ascend", "descend"],
  },
];
export const OtherAdjsutments = [
  {
    title: "Channel",
    align: "center",
    key: "channel",
    render: (record, rowIndex, isLoading) =>
      record.channel === "Amazon-India" ? (
        <div className="amazon-logo-small" />
      ) : record.channel === "Flipkart" ? (
        <div className="flipkart-logo-small" />
      ) : null,
  },
  {
    title: "Settlement Id",
    align: "center",
    key: "settlement_id",
    render: (record, rowIndex, isLoading) =>
      record.settlement_id !== undefined ? record.settlement_id : "-",
  },
  {
    title: "TDS",
    align: "center",
    key: "tds",
    render: (record, rowIndex, isLoading) =>
      record.tds !== undefined
        ? record.tds?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
  {
    title: "Storage",
    align: "center",
    key: "other_adjustments",
    render: (record, rowIndex, isLoading) =>
      record.storage !== undefined
        ? record.storage?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
  {
    title: "Other Fees",
    align: "center",
    key: "other_adjustments",
    render: (record, rowIndex, isLoading) =>
      record.other_adjustments !== undefined
        ? (
            record.other_adjustments -
            record.tds -
            record.storage
          ).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
  // {
  //   title: "Other Adj",
  //   align: "center",
  //   key: "other_adjustments",
  //   render: (record, rowIndex, isLoading) =>
  //     record.other_adjustments !== undefined
  //       ? record.other_adjustments.toLocaleString("en-IN", {
  //         maximumFractionDigits: 2,
  //         style: "currency",
  //         currency: "INR",
  //       })
  //       : 0,
  // },
  {
    title: "Deposit Date",
    align: "center",
    key: "deposit_date",
    defaultSortOrder: "descend",
    render: (record, rowIndex, isLoading) =>
      record.deposit_date !== undefined
        ? moment(record?.deposit_date).format("DD-MM-YYYY")
        : "-",
    sorter: (a, b) => {
      a = a.deposit_date
        ? a.deposit_date !== "-"
          ? new Date(
              (a.deposit_date || "").replace(
                /(\d{2})-(\d{2})-(\d{4})/,
                "$2/$1/$3"
              )
            ).getTime()
          : 0
        : 0;
      b = b.deposit_date
        ? b.deposit_date !== "-"
          ? new Date(
              (b.deposit_date || "").replace(
                /(\d{2})-(\d{2})-(\d{4})/,
                "$2/$1/$3"
              )
            ).getTime()
          : 0
        : 0;
      return a - b;
    },
    sortDirections: ["descend", "ascend", "descend"],
  },
];
export const Remittance_amount = [
  {
    title: "Channel",
    align: "center",
    key: "channel",
    render: (record, rowIndex, isLoading) =>
      record.channel === "Amazon-India" ? (
        <div className="amazon-logo-small" />
      ) : record.channel === "Flipkart" ? (
        <div className="flipkart-logo-small" />
      ) : null,
  },
  {
    title: "Settlement Id",
    align: "center",
    key: "settlement_id",
    render: (record, rowIndex, isLoading) =>
      record.settlement_id !== undefined ? record.settlement_id : "-",
  },

  {
    title: "Remittance Amount",
    align: "center",
    key: "remittance_amount",
    isTotal: true,
    render: (record, rowIndex, isLoading) =>
      record.remittance_amount !== undefined
        ? record.remittance_amount?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
  {
    title: "Deposit Date",
    align: "center",
    key: "deposit_date",
    defaultSortOrder: "descend",
    render: (record, rowIndex, isLoading) =>
      record.deposit_date !== undefined
        ? moment(record?.deposit_date).format("DD-MM-YYYY")
        : "-",
        sorter: (a, b) => {
          a = a.deposit_date
            ? a.deposit_date !== "-"
              ? new Date(
                  (a.deposit_date || "").replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    "$2/$1/$3"
                  )
                ).getTime()
              : 0
            : 0;
          b = b.deposit_date
            ? b.deposit_date !== "-"
              ? new Date(
                  (b.deposit_date || "").replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    "$2/$1/$3"
                  )
                ).getTime()
              : 0
            : 0;
          return a - b;
        },
        sortDirections: ["descend", "ascend", "descend"],
  },
];
export const TotalDiffAmount = [
  {
    title: "Channel",
    align: "center",
    key: "channel",
    render: (record, rowIndex, isLoading) =>
      record.channel === "Amazon-India" ? (
        <div className="amazon-logo-small" />
      ) : record.channel === "Flipkart" ? (
        <div className="flipkart-logo-small" />
      ) : null,
  },
  {
    title: "Settlement Id",
    align: "center",
    key: "settlement_id",
    render: (record, rowIndex, isLoading) =>
      record.settlement_id !== undefined ? record.settlement_id : "-",
  },

  {
    title: "Amount",
    align: "center",
    key: "amount",
    isTotal: true,
    render: (record, rowIndex, isLoading) =>
      record.amount !== undefined
        ? record.amount?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        : 0,
  },
];