import { Tag } from "antd";
import React, { useEffect } from "react";
import AntTable from "../../../components/AntTable";
import ExportButton from "../../../components/Styles/ExportButton";
// import Tag from "../../../components/Styles/Tags";
import MapProductToSKU from "./MapProductToSKU";
import { Fulfilment_filter } from "../../../utils/data";

const UnMappedSkUs = (props) => {
  const { dataSource, isLoading, isDataLoading } = props;

  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (data) => {
    setData(data);
    setOpen(true);
  };

  const handleClose = (event,reason) => {
    if(reason !== "backdropClick"){
      setData(null);
      setOpen(false);
    }
  };

  const columns = [
    {
      title: "Sno",
      field: "marketplace",
      width: 60,
      render: (record, rowIndex) => rowIndex,
    },
    {
      title: "Channel",
      field: "marketplace",
      width: 75,
      render: (record, rowIndex, isLoading) =>
        record.marketplace === "Amazon-India" ? (
          <div className="amazon-logo-small" />
        ) : record.marketplace === "Flipkart" ? (
          <div className="flipkart-logo-small" />
        ) : null,
    },
    {
      title: "SKU Code",
      field: "sku",
      render: (record, rowIndex, isLoading) => record.sku || "-",
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        a = (a.sku || "").toLowerCase();
        b = (b.sku || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product ID",
      field: "productId",
      render: (record, rowIndex, isLoading) => record.productId || "-",
      sorter: (a, b) => {
        a = (a.productId || "").toLowerCase();
        b = (b.productId || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product",
      field: "name",
      tooltip: true,
      render: (record, rowIndex, isLoading) =>
        record.name ? record["name"].replace(/(.{23})..+/, "$1…") : "-",
      sorter: (a, b) => {
        a = (a.name || "").toLowerCase();
        b = (b.name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Fulfilment",
      align: "left",
      field: "fulfillmentChannel",
      width: 180,
      render: (record, rowIndex, isLoading) => record.fulfillmentChannel || "-",
      searchPriority: 3,
      onSearch: (searchText) => (data) =>
        (data || "").toLowerCase().includes(searchText.toLowerCase()),
      editable: false,
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.fulfillmentChannel) {
            return true;
          }
        } else {
          let isFilter = false;
          value.map((fulfillmentChannel) => {
            if (
              fulfillmentChannel.toLowerCase() ===
              (record.fulfillmentChannel || "").toLowerCase()
            ) {
              isFilter = true;
            }
          });
          return isFilter;
        }
      },
      filters: Fulfilment_filter,
    },
    {
      title: "Listing Status",
      field: "status",
      render: (record, rowIndex, isLoading) => (
        <Tag
          color={
            (record.status || "").toLowerCase() === "active" ? "green" : "red"
          }
          className="capitalize"
        >
          {(record.status || "").toLowerCase()}
        </Tag>
      ),
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.status) {
            return true;
          }
        } else {
          return (record.status || "").toLowerCase() === value.toLowerCase();
        }
      },
      filters: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
    },
    {
      title: "",
      field: "Listing Status",
      align: "left",
      // width: "10%",
      render: (record, rowIndex, isLoading) => (
        <ExportButton variant="outlined" onClick={() => handleOpen(record)}>
          <div className="text-sm">Map Product</div>
        </ExportButton>
      ),
    },
  ];

  useEffect(() => {
    if (props.activeTab === 0) {
      props.setColumn(columns);
    }
  }, [props.activeTab]);
  return (
    <React.Fragment>
      <AntTable
        columns={columns}
        dataSource={dataSource}
        stickyHeader={{
          offsetHeader: 65,
          // offsetScroll: 216,
        }}
        // scroll={{ y: 500 }}
        rowKey={(row, rowIndex) => rowIndex}
        isLoading={isLoading}
        isDataLoading={isDataLoading}
        searchText={[props.searchText]}
        hideSummary
      />
      {/* <MaterialTable
				columns={columns}
				dataSource={dataSource}
				stickyHeader={true}
				rowKey={(row, rowIndex) => rowIndex}
				isLoading={isLoading}
				searchText={props.searchText}
				pagination={{
					count: dataSource.length,
				}}
			/> */}
      {open && (
        <MapProductToSKU
          open={open}
          handleClose={handleClose}
          data={data}
          refreshList={props.refreshList}
        />
      )}
    </React.Fragment>
  );
};

export default UnMappedSkUs;
