import React, { useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  categoryText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#171725",
    fontFamily: "inter",
    textAlign: "left"
  },
  titleText: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
    textAlign: "left"
  }
}));

function ReturnProcessingLegend(props) {
  const classes = useStyles();

  return (
    <>
      <div>
        <Typography className={classes.categoryText}>Customer</Typography>
        {props.data.slice(0, 4).map((item, i) => (
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                marginLeft: 4,
                display: "inline-block",
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: `${item.color}`
              }}
            />
            <Typography
              className={classes.titleText}
              style={{
                display: "inline-block",
                color: `${item.color}`,
                marginLeft: 4
              }}
            >
              {item.name}
            </Typography>
          </div>
        ))}
      </div>
      <div>
        <Typography className={classes.categoryText}>Courier</Typography>
        {props.data.slice(4, 8).map((item, i) => (
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                marginLeft: 4,
                display: "inline-block",
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: `${item.color}`
              }}
            />
            <Typography
              className={classes.titleText}
              style={{ display: "inline-block", color: `${item.color}` }}
            >
              {item.name}
            </Typography>
          </div>
        ))}
      </div>
    </>
  );
}

export default ReturnProcessingLegend;
