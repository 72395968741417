import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ReturnStatusText from "./ReturnStatusText.js";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter"
  },
  markReceived: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter"
  },
  noLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter"
  },
  yesLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter"
  },
  cell: {
    padding: "6px 6px 6px 6px"
  },
  trackerFilterBar: {
    marginTop: 44
  },
  searchFilterBar: {
    marginTop: 31
  },
  table: {
    marginTop: 22
  }
}));

const CustomTableCell = withStyles({
  sizeSmall: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 10,
    paddingBottom: 10
  },
  root: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter"
  }
})(TableCell);

const CustomTableHeaderCell = withStyles({
  sizeSmall: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 10,
    paddingBottom: 10
  },
  root: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter"
  }
})(TableCell);

function ReturnsDetailPanel(props) {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>Product Image</CustomTableHeaderCell>
            <CustomTableCell colSpan={2}>
              <Typography className={classes.title}>-</Typography>
            </CustomTableCell>
            <CustomTableHeaderCell>Product Name</CustomTableHeaderCell>
            <CustomTableCell colSpan={6}>
              {props.row.ProductDetails !== undefined ? (
                props.row.ProductDetails?.Name.length > 45 ? (
                  <Typography className={classes.title}>
                    {props.row.ProductDetails?.Name.substring(0, 45) + "..."}
                  </Typography>
                ) : (
                  <Typography className={classes.title}>
                    {props.row.ProductDetails?.Name}
                  </Typography>
                )
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableHeaderCell>Customer Name</CustomTableHeaderCell>
            <CustomTableCell colSpan={4}>
              <Typography className={classes.title}>-</Typography>
            </CustomTableCell>
          </TableRow>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>
              Marketplace return status
            </CustomTableHeaderCell>
            <CustomTableCell colSpan={2}>
              <ReturnStatusText text={props.row.FSOrderStatus} />
            </CustomTableCell>
            <CustomTableHeaderCell>Return type</CustomTableHeaderCell>
            <CustomTableCell colSpan={6}>
              <Typography className={classes.title}>
                {(props.row.ReturnDetails || {}).ReturnType}
              </Typography>
            </CustomTableCell>
            <CustomTableHeaderCell>Customer address</CustomTableHeaderCell>
            <CustomTableCell colSpan={4}>
              <Typography className={classes.title}>
                {props.row.ShipCity}-{props.row.ShipCountry}
                {props.row.ShipPostalCode}
              </Typography>
            </CustomTableCell>
          </TableRow>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>Seller return status</CustomTableHeaderCell>
            <CustomTableCell colSpan={2}>
              <ReturnStatusText
                text={props.row.mark_received ? "Delivered" : "Not Delivered"}
              />
            </CustomTableCell>
            <CustomTableHeaderCell>Weight</CustomTableHeaderCell>
            <CustomTableCell colSpan={6}>
              <Typography className={classes.title}>-</Typography>
            </CustomTableCell>
            <CustomTableHeaderCell>Courier</CustomTableHeaderCell>
            <CustomTableCell colSpan={4}>
              <Typography className={classes.title}>-</Typography>
            </CustomTableCell>
          </TableRow>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>Dimensions</CustomTableHeaderCell>
            <CustomTableCell colSpan={2}>
              <Typography className={classes.title}>-</Typography>
            </CustomTableCell>
            <CustomTableHeaderCell>AWB No:</CustomTableHeaderCell>
            <CustomTableCell colSpan={6}>
              <Typography className={classes.title}>-</Typography>
            </CustomTableCell>
            <CustomTableHeaderCell>Reason</CustomTableHeaderCell>
            <CustomTableCell colSpan={4}>
              <Typography className={classes.title}>
                {(props.row.ReturnDetails || {}).ReturnReason}
              </Typography>
            </CustomTableCell>
          </TableRow>
          {props.row.mark_received && (
            <TableRow>
              <CustomTableCell colSpan={2} />
              <CustomTableHeaderCell>
                No of sellable returns
              </CustomTableHeaderCell>
              <CustomTableCell colSpan={2}>
                {props.row.sellable}
              </CustomTableCell>
              <CustomTableHeaderCell>
                No of non-sellable returns
              </CustomTableHeaderCell>
              <CustomTableCell colSpan={6}>
                {props.row.non_acceptable}
              </CustomTableCell>
              <CustomTableHeaderCell>
                Return quantity received
              </CustomTableHeaderCell>
              <CustomTableCell colSpan={4}>
                {props.row.total_received_quantity}
              </CustomTableCell>
            </TableRow>
          )}
        </TableHead>
      </Table>
    </TableContainer>
  );
}

export default ReturnsDetailPanel;
