import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Image, Rate, Tooltip } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";
import "../../../App.css";
import AntTable from "../../../components/AntTableV2/index.js";
import "../../../Styles/review.css";

const useStyles = makeStyles((theme) => ({
  customerName: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    textAlign: "left",
    paddingLeft: 8,
  },
  customerReviewInfo: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
    paddingLeft: 8,
  },
  customerReviewText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "center",
  },
  skuLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: "#717480",
    fontFamily: "inter",
    display: "inline-block",
  },
  skuValue: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    display: "inline-block",
  },
  productName: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
  },
  markReceived: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
  },
  noLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
  },
  yesLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
  },
}));

function ProductReviewsTab(props) {
  const classes = useStyles();

  const columns = [
    // {
    //   title: "Channel",
    //   field: "channel",
    //   align: "center",
    //   width: "6%",
    //   render: (record, rowIndex, isLoading) =>
    //     isLoading ? (
    //       <div className="item">
    //         <div className="item-image" />
    //       </div>
    //     ) : record.channel === "Amazon-India" ? (
    //       <div className="amazon-logo-small" />
    //     ) : record.channel === "Flipkart" ? (
    //       <div className="flipkart-logo-small" />
    //     ) : (
    //       "-"
    //     ),
    // },
    // {
    //   title: "Product Image",
    //   field: "product_name",
    //   align: "center",
    //   width: "8%",
    //   render: (record, rowIndex, isLoading) =>
    //     isLoading ? (
    //       <div className="item">
    //         <div className="item-title" />
    //       </div>
    //     ) : (
    //       <div className="flex items-center justify-center">
    //         <Image
    //           width={36}
    //           height={36}
    //           src={record.product_image}
    //           preview={false}
    //           fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
    //         />
    //       </div>
    //     ),
    // },

    {
      title: "Product",
      field: "product_id",
      align: "center",
      width: "15%",
      defaultSortOrder: "descend",
      render: (record, value, rowIndex) => (
        <>
          {" "}
          <div
            className={`${
              record.channel === "Amazon-India"
                ? "amazon-logo-small"
                : record.channel === "Flipkart"
                ? "flipkart-logo-small"
                : record.channel ==="Meesho"
                ? "meesho-logo-small"
                : null
            }`}
          />
          <div className="flex items-center justify-center">
            <Image
              width={30}
              height={30}
              src={record.product_image}
              fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
            />{" "}
            <Button className="-ml-3 text-sm" type="link">
              <a
                href={
                  record.channel === "Amazon-India"
                    ? `https://www.amazon.in/dp/${record.product_id}`
                    :record.channel === "Flipkart" 
                    ? `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${record.product_id}`
                    : record.channel === "Meesho"
                    ? `https://meesho.com/Forcesight-flipkart-url-redirect/p/${record.product_id}`
                    : null
                }
                target="_blank"
                rel="noreferrer"
              >
                {record.product_id?.replace(/(.{15})..+/, "$1…") || ""}
              </a>
            </Button>
          </div>
        </>
      ),
      sorter: (a, b) => {
        a = (a.product_id || "").toLowerCase();
        b = (b.product_id || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      searchPriority: 2,
      onSearch: (searchText) => (data) =>
        (data || "").toLowerCase().includes(searchText.toLowerCase()),
    },
    {
      title: "Product Name",
      field: "product_name",
      align: "center",
      tooltip: true,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <div>
            <Button
              type="link"
              onClick={() => {
                props.selectedProduct(2, record.product_id, record.sku);
              }}
            >
              <Typography className={classes.productName}>
                <a>
                  {record?.product_name?.replace(/(.{25})..+/, "$1…") || "-"}{" "}
                </a>
              </Typography>
            </Button>
          </div>
        ),
      sorter: (a, b) => {
        a = (a.product_name || "").toLowerCase();
        b = (b.product_name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      searchPriority: 1,
      onSearch: (searchText) => (data) =>
        (data || "").toLowerCase().includes(searchText.toLowerCase()),
    },
    // {
    //   title: "Period Selected",
    //   children: [
        {
          title: "# Ratings",
          field: "total_rating",
          align: "center",
          width: "7%",
          render: (record, rowIndex, isLoading) =>
            isLoading ? (
              <div className="item">
                <div className="item-title" />
              </div>
            ) : (
              <Typography className={classes.customerReviewText}>
                {record.total_rating}
              </Typography>
            ),
            sorter: (a, b) => {
              a = (a.total_rating || "");
              b = (b.total_rating || "");
              return a > b ? -1 : b > a ? 1 : 0;
            },
        },
        {
          title: "# Reviews",
          field: "total_reviews",
          align: "center",
          width: "7%",
          render: (record, rowIndex, isLoading) =>
            isLoading ? (
              <div className="item">
                <div className="item-title" />
              </div>
            ) : (
              <Typography className={classes.customerReviewText}>
                {record.total_reviews}
              </Typography>
            ),
          sorter: (a, b) => Number(a.total_reviews) - Number(b.total_reviews),
        },
        {
          title: "Stars",
          field: "average_rating",
          align: "center",
          tooltip: false,
          render: (record, rowIndex, isLoading) =>
            isLoading ? (
              <div className="item">
                <div className="item-title" />
              </div>
            ) : (
              <div>
                <Tooltip>
                  <Rate
                    defaultValue={record?.average_rating}
                    value={record?.average_rating}
                    disabled
                    count={5}
                    allowClear={false}
                  />
                </Tooltip>
              </div>
            ),
          filters: [
            { text: 1, value: 1 },
            { text: 2, value: 2 },
            { text: 3, value: 3 },
            { text: 4, value: 4 },
            { text: 5, value: 5 },
          ],
          onFilter: (value, record) => record?.average_rating === value,
        },
    //   ],
    // },
    // {
    //   title: "Lifetime",
    //   children: [
        {
          title: "# LT Ratings",
          field: "lifetime_rating",
          align: "center",
          width: "7%",
          render: (record, rowIndex, isLoading) =>
            isLoading ? (
              <div className="item">
                <div className="item-title" />
              </div>
            ) : (
              <Typography className={classes.customerReviewText}>
                {record.lifetime_rating}
              </Typography>
            ),
          sorter: (a, b) => a.lifetime_rating - b.lifetime_rating,
        },
        {
          title: "# LT Reviews",
          field: "lifetime_reviews",
          align: "center",
          width: "7%",
          render: (record, rowIndex, isLoading) =>
            isLoading ? (
              <div className="item">
                <div className="item-title" />
              </div>
            ) : (
              <Typography className={classes.customerReviewText}>
                {record.lifetime_reviews}
              </Typography>
            ),
          sorter: (a, b) => a.lifetime_reviews - b.lifetime_reviews,
        },
        {
          title: "LT Stars",
          field: "average_lifetime_rating",
          align: "center",
          tooltip: false,
          render: (record, rowIndex, isLoading) =>
            isLoading ? (
              <div className="item">
                <div className="item-title" />
              </div>
            ) : (
              <div>
                <Tooltip>
                  <Rate
                    defaultValue={record?.average_lifetime_rating}
                    value={record?.average_lifetime_rating}
                    disabled
                    count={5}
                    allowClear={false}
                  />
                </Tooltip>
              </div>
            ),
          filters: [
            { text: 1, value: 1 },
            { text: 2, value: 2 },
            { text: 3, value: 3 },
            { text: 4, value: 4 },
            { text: 5, value: 5 },
          ],
          onFilter: (value, record) =>
            record?.average_lifetime_rating === value,
        },
    //   ],
    // },
  ];

  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
  }) => {
    props.tableFilterData(paginationCondition, sortCondition, filterCondition);
  };
  // React.useEffect(() => {
  //   if (props.activeTab === "1") {
  //     props.setColumn(columns);
  //   }
  // }, [props.activeTab]);
  return (
    <>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          columns={columns}
          dataSource={props.productReviewsData}
          stickyHeader={{ offsetHeader: 65 }}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={props.isLoading}
          totalRecords={props.pageCount}
          // onRow={(record, rowIndex) => ({
          //   onClick: (event) => {
          //     props.selectedProduct(2, record.product_id, record.sku)
          //     // props.history.push(
          //     //   `/selected-product-reviews/${record.product_id}/${record.sku}`
          //     // ),
          //   }
          // })}
          hideSummary
          onDataChange={onTableChange}
        />
      </div>
    </>
  );
}

export default withRouter(ProductReviewsTab);
