import moment from "moment";
import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import CustomerDetails from "./pages/AdminDashboard/adminDashboard.js";
import SyncDetails from "./pages/adminSyncDetails/adminSyncDetails.js";
import ChangePassword from "./pages/auth/changePassword";
import ForgotPassword from "./pages/auth/forgot";
import LoginPage from "./pages/auth/login";
import Otp from "./pages/auth/otp";
import Signup from "./pages/auth/signup";
import Inventorytracker_V2 from "./pages/inventory/InventoryTracker/inventorytracker_V2";
import Inventorytracker_V3 from "./pages/inventory/InventoryTracker/inventorytracker_V3";
import MasterSKUMapping from "./pages/inventory/MasterSKUMapping";
import StorageLocations_V2 from "./pages/inventory/storageLocations/indexV2"
import OrdersTrackerV2 from "./pages/orders/OrdersTrackerV2";
import ProductConfiguration from "./pages/productConfiguration";
import ReturnsTrackerV3 from "./pages/returns/ReturnsTrackerV3";
import CreateCampaign from "./pages/ReviewV2/Campaigns/CreateCampaign";
import EditCampaign from "./pages/ReviewV2/Campaigns/EditCampaign";
import AccountSettings from "./userSettings/UserSettings.js";
import Profitability from './pages/profitv2/index';
import ProfitabilityV4 from './pages/profitv2/indexV4';
import ProfitabilityTest from './pages/profitv2/indexV4Test';
import DashboardV3 from "./pages/cockpit/DashboardV3";
import Payments from "./pages/paymentsV2/index";
import PaymentsBank from "./pages/paymentsV2/indexV2";
import BuyBoxIndex from "./pages/buyboxv2/index";
import ReviewsInsightsV4 from "./pages/ReviewV2/indexV4";
import AllExport from "./pages/ModuleExport/index";
import OrdersTrackerV3 from "./pages/orders/OrdersTrackerV3";
import NotAuth from "./pages/NotAuth/NotAuth";
import PageNotFound from "./pages/PageNotFound/pageNotFound";
import DashboardMap from "./pages/DashboardMap"
import KeywordTracker from "./pages/keywordTrack/index.js";
import URLRotator from "./pages/URLRotator/index.js";
import ChangeLog from "./pages/changeLogs/index.js";


const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        userPermissions(props) ?
          !renewplanFunction() ? (
            <Component {...props} />
          ) : (
            <Redirect to="/account-settings" />
          )
          : <Route component={NotAuth} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
const userPermissions = (val) => {
  let dataArray = JSON.parse(localStorage.getItem('userPermission'))
  let pathName = (val.match.path.replace(/[^\w\s]/gi, '') + '_read').toUpperCase()
  if (dataArray.includes(pathName))
    return true
}


const renewplanFunction = () => {
  let days = localStorage.getItem("subscriptionDays");
  var start = new Date();
  var end = moment(days, "YYYY-MM-DD");
  const subscriptiondays = Math.round((end - start) / 1000 / 60 / 60 / 24) + 1;
  if (subscriptiondays <= 0) {
    return true;
  }
  return false;
};
const Routes = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <AuthRoute path="/dashboard" component={DashboardV3} />
        <AuthRoute exact path="/payment-tracker" component={PaymentsBank} />
        <AuthRoute exact path="/profitability" component={ProfitabilityV4} />
        <AuthRoute exact path="/reviews" component={ReviewsInsightsV4} />
        <AuthRoute exact path="/master-sku-mapping" component={MasterSKUMapping} />
        <AuthRoute exact path="/sales-defence" component={BuyBoxIndex} />
        <AuthRoute exact path="/storage-locations" component={StorageLocations_V2} />
        <AuthRoute exact path="/product-config" component={ProductConfiguration} />
        <AuthRoute exact path="/admin-dashboard" component={CustomerDetails} />
        <AuthRoute exact path="/downloads" component={AllExport} />
        <AuthRoute exact path="/inventory-tracker" component={Inventorytracker_V3} />
        <AuthRoute exact path="/orders-tracker" component={OrdersTrackerV3} />
        <AuthRoute exact path="/returns-tracker" component={ReturnsTrackerV3} />
        <AuthRoute exact path="/sync-details" component={SyncDetails} />
        
        <Route path="/account-settings">
          <AccountSettings />
        </Route>
        <Route path="/keyword-tracker">
          <KeywordTracker />
        </Route>
        <Route path="/url-rotator">
          <URLRotator />
        </Route>
        <Route path="/history">
          <ChangeLog />
        </Route>

        {/* <Route path="/returns-tracker-V2">
          <ReturnsTracker />
        </Route> */}
        <Route path="/orders-tracker-V2">
          <OrdersTrackerV2 />
        </Route>
        <Route path="/map">
          <DashboardMap />
        </Route>

        <Route path="/inventory-tracker-V2">
          <Inventorytracker_V2 />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/register">
          <Signup />
        </Route>
        <Route path="/forgot-password" >
          <ForgotPassword />
        </Route>
        <Route path="/enter-otp">
          <Otp {...props} />
        </Route>
        <Route path="/change-password">
          <ChangePassword {...props} />
        </Route>
        {/* <Route path="/returns-tracker">
          <ReturnsTracker />
        </Route> */}
        <Route path="/create-campaigns">
          <CreateCampaign />
        </Route>
        <Route path="/edit-campaigns/:id">
          <EditCampaign />
        </Route>
        <Route path="/profitability-V2">
          <Profitability />
        </Route>
        <Route path="/profitability-V4">
          <ProfitabilityTest />
        </Route>
        <Route path="/reviews-V4">
          <ReviewsInsightsV4 />
        </Route>  
        {/* <Route path="/contact-us">
          <SupportContactUs />
        </Route> */}
        <Route path="/payment-tracker-V2">
          <Payments />
        </Route>
        <Route render={() => <PageNotFound />} />
      </Switch>
    </Router>
  );
};
export default Routes;
