import { Button, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import CustomFormFooter from "../../components/CustomFormFooter";
import CustomFormHeader from "../../components/CustomFormHeader";
import CustomTextField from "../../components/CustomTextField";
import ApiWrapper from "../../utils/ApiWrapper";
import AuthLayout from "./authLayout";
import { useCookies } from 'react-cookie';

const signupValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Required"),
  password: Yup
    .string()
    .trim()
    .required('No password provided.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: Yup.string()
    .min(8)
    .oneOf([Yup.ref("password"), null], "Password mismatch")
    .required("Required"),
  gst_number: Yup.string()
    // .required("Required")
    .matches(
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
      "Invalid GST number"
    ),
  phone_number: Yup.string()
    .trim()
    .matches(/^[6-9]\d{9}$/, "Invalid Mobile number")
    .required("Required"),
  pincode: Yup.string()
    .required("Required")
    .matches(/\d{6}/, "Invalid Pin code")
    .max(6, "Must be exactly 6 digits"),
  name: Yup.string().trim().required("Required"),
  org_name: Yup.string().trim().required("Required"),
  address: Yup.string().trim().required("Required"),
  city: Yup.string().trim().required("Required"),
  state: Yup.string().trim().required("Required"),
});

function Signup(props) {
  const [isConfirmDetailsOpen, setIsConfirmDetailsOpen] = React.useState(false);
  const FormRef = React.useRef(null);
  const [cookies, setCookie] = useCookies('');


  useEffect(() => {
    if (props.location.search) {
      let dataof = props.location.search.split("?")
      setCookie('userCode', dataof[1], { path: '/' });
    }
  }, [])
  const handleSignupClick = async (values, actions) => {

    let response_values = { ...values };

    response_values.phone_number = "+91" + values.phone_number.toString();

    ApiWrapper("registerSelf", response_values).then(
      (response) => {
        if (response.status) {
          localStorage.setItem("token", response.fs_auth_token);
          props.history.replace(`/enter-otp?action=${response.action}`);
        } else {
          if (response.message === "User Already Exists") {
            actions.setErrors({
              email: "Email already in use",
            });
            message.error("Email already in use");
          } else {
            message.error(response.message);
          }
        }
        actions.setSubmitting(false);
      },
      function (error) { }
    );
  };
  return (
    <div>
      <AuthLayout page="Signup">
        <CustomFormHeader
          title="Create an account"
          subtitle="Fill up all the fields given below to register"
        />
        <Formik
          initialValues={{
            name: "",
            org_name: "",
            email: "",
            gst_number: "",
            phone_number: "",
            password: "",
            confirmPassword: "",
            address: "",
            city: "",
            state: "",
            pincode: "",
          }}
          innerRef={FormRef}
          validationSchema={signupValidationSchema}
          onSubmit={(values, actions) => {
            if (cookies.userCode) {
              let reqBody = {
                "userCode": cookies.userCode,
                "name": values.org_name,
                "email": values.email,
                "phone_number": values.phone_number
              }
              ApiWrapper("influencerCode", reqBody).then(
                (response) => {
                  if (response.status) {
                    console.log(response)
                  }
                },
                function (error) { }
              );
            }
            setIsConfirmDetailsOpen(true);
            // handleSignupClick(values, actions);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <CustomTextField title="Name" name="name" type="text" />
                <CustomTextField
                  title="Business Name"
                  name="org_name"
                  type="text"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <CustomTextField title="Email Id" name="email" type="email" />
                <CustomTextField
                  title="Mobile Number"
                  name="phone_number"
                  type="number"
                />{" "}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <CustomTextField
                  title="Password"
                  name="password"
                  type="password"
                />
                <CustomTextField
                  title="Confirm Password"
                  name="confirmPassword"
                  type="password"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <CustomTextField
                  title="GST Number"
                  name="gst_number"
                  type="text"
                />
                <CustomTextField title="Address" name="address" type="text" />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <CustomTextField title="City" name="city" type="text" />
                <CustomTextField title="State" name="state" type="text" />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <CustomTextField
                  title="Pin code"
                  name="pincode"
                  type="number"
                />
              </div>
              <div style={{ marginTop: 30 }}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="uppercase w-full shadow-lg hover:shadow-xl rounded font-semibold"
                  disabled={isSubmitting}
                // onClick={submitForm}
                >
                  Register
                </Button>
              </div>
              <div className="mt-8">
                <CustomFormFooter
                  linkHelpText="Do you already have an account?"
                  linkText="Login"
                  clickAction={() => {
                    props.history.push("/login");
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
        <Modal
          title="Authentication error"
          visible={isConfirmDetailsOpen}
          maskClosable={false}
          onCancel={() => {
            setIsConfirmDetailsOpen(false);
            FormRef?.current?.setSubmitting(false);
          }}
          footer={[
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setIsConfirmDetailsOpen(false);
                  FormRef?.current?.setSubmitting(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setIsConfirmDetailsOpen(false);
                  handleSignupClick(FormRef?.current?.values, FormRef?.current);
                }}
              >
                Proceed
              </Button>
            </div>,
          ]}
        >
          <p>
            Please make sure the mobile number -{" "}
            <b>{FormRef?.current?.values?.phone_number}</b> and email -{" "}
            <b>{FormRef?.current?.values?.email}</b> entered here are correct
          </p>
        </Modal>
      </AuthLayout>
    </div>
  );
}
export default withRouter(Signup);
