import { Button, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { message, Modal, notification, Spin, Select } from "antd";
import { Form, Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { boolean } from "yup/lib/locale";
import ".././App.css";
import CustomAppBar from "../components/./CustomAppBar.js";
import CustomSectionHeader from "../components/./CustomSectionHeader.js";
import CustomTextField from "../components/./CustomTextField.js";
import PageHeader from "../components/./PageHeader.js";
import AmazonLogo from "../images/amazonLogo1.svg";
import FlipkartLogo from "../images/flipkartLogo.svg";
import MeeshoLogo from "../images/MeeshoLogo.svg"
import "../Styles/settings.css";
import ApiWrapper from "../utils/ApiWrapper";
import ChannelStatusDialog from "./ChannelStatusDialog.js";
import FlipkartUserDetailsDialog from "./FlipkartUserDetailsDialog.js";
import InvoiceTab from "./invoiceTab.js";
import PinCode from "./PincodeScreen";
import "./userSettings.css";
import UsersTable from "./UsersTable.js";

// const validationSchema = Yup.object().shape({
//   gst_number: Yup.string().trim().required("Required"),
// });
const UservalidationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
});
// const ChangePasswordSchema = Yup.object().shape({
//   email: Yup.string().email().required("Required"),
//   password: Yup.string().required("Required"),
// });
const ChangePasswordSchema = Yup.object().shape({
  new_password: Yup
  .string()
  .trim()
  .required('No password provided.')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  ),
  confirm_new_pasword: Yup.string().trim()
    .oneOf([Yup.ref("new_password"), null], "Password mismatch")
    .required("Required"),
    old_password:Yup.string().required("Required")
});
const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#44444F",
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#717480",
  },
  fontMedium: {
    fontWeight: 500,
  },
  gridContainer: {
    marginRight: 10,
    marginBottom: 20,
    width: 280,
    border: "1px solid #DFE1E6",
  },
  margin: {
    margin: theme.spacing(1),
  },
  btnPrimary: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  btnSecondary: {
    color: "#fff",
    backgroundColor: theme.palette.primary.light,
  },
}));
const mapStateToProps = (state) => {
  return {
    userInfo: state.account.userInfo,
    companyInfo: state.account.companyInfo,
  };
};

const args = {
  message: "",
  description: "",
};
const { Option } = Select;
function handleChange(value) {
  let jsonRequestBody = {
    step_program: value,
    channel: "Amazon-India"
  };
  ApiWrapper("updateAmazonChannelAccess", jsonRequestBody).then(
    function (response) {
      if (response.status) {
        message.success("Update successfully!");
      } else {
        // message.error("");
      }
    },
    function (error) { }
  );
}
const mapDispatchToPorps = (dispatch) => {
  return {
    updateUserInfo: (userInfo) =>
      dispatch({
        type: "user/updateInfo",
        payload: {
          name: userInfo.name,
          email: userInfo.email,
          phone_number: userInfo.phone_number,
        },
      }),
    updateCompanyInfo: (companyInfo) =>
      dispatch({
        type: "company/updateInfo",
        payload: {
          org_name: companyInfo.org_name,
          address: companyInfo.address,
          city: companyInfo.city,
          gst_number: companyInfo.gst_number,
          state: companyInfo.state,
          country: companyInfo.country,
          postal_code: companyInfo.postal_code,
        },
      }),
  };
};
const LogoList = { "Amazon India": AmazonLogo, "Amazon India (Ads)": AmazonLogo, Flipkart: FlipkartLogo ,Meesho: MeeshoLogo};
const ButtonContainer = ({
  title,
  isSubmitting = false,
  className = "",
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Button
      color="primary"
      className={` primary text-capitalize ${className}  ${classes.fontMedium} ${classes.btnPrimary}`}
      size="large"
      style={{ marginTop: 20 }}
      disabled={isSubmitting}
      onClick={onClick}
    >
      <div className="font-Inter text-sm text-white">{title}</div>
    </Button>
  );
};
const GridContainer = ({
  channel,
  handleChannelClick,
  disconnectChannelHandler,
  handleMarketplaceInfoUpdate,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const classes = useStyles();
  return (
    <div className={`${classes.gridContainer}`}>
      <div className="pt-3 pb-3 mt-4 ml-4 mr-4 mb-4">
        <div className="text-center">
          <img
            src={LogoList[channel.name]}
            alt="logo"
            className="img-fluid"
            style={{
              height: "50px",
            }}
          />
        </div>
        <div
          className={`font-Inter text-lg font-medium mt-3`}
          style={{
            color: "#44444F",
          }}
        >
          {channel.name}
        </div>
        <div
          className={`font-Inter text-sm mt-2`}
          style={{
            color: "#717480",
          }}
        />
        <Button
          size="large"
          className={`w-full mt-5 primary text-capitalize text-white font-Inter text-sm ${classes.fontMedium
            } ${channel.isConnected ? classes.btnSecondary : classes.btnPrimary}`}
          onClick={() => {
            channel.isConnected
              ? setIsModalVisible(true)
              : handleChannelClick(channel);
          }}
        >
          <div>{channel.isConnected ? "DisConnect" : "Connect"}</div>
        </Button>
        {channel.name === "Amazon India (Ads)"}
        <Modal
          title="MarketPlace Setup "
          visible={isModalVisible}
          maskClosable={false}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          footer={[
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  disconnectChannelHandler(channel);
                  setIsModalVisible(false);
                }}
              >
                Proceed
              </Button>
            </div>,
          ]}
        >
          <p>{`Are you sure to Disconnect - ${channel.name} Marketplace`}</p>
        </Modal>

        {channel.isConnected ? (
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values) => {
              const errors: Partial<Values> = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (values.password === "") {
                errors.password = "Required";
              }
              return errors;
            }}
            onSubmit={(values, actions) => {
              handleMarketplaceInfoUpdate(values, channel);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form style={{ marginTop: 10 }}>
                <CustomTextField title="Email" name="email" type="email" />
                <CustomTextField
                  title="Password"
                  name="password"
                  type="password"
                />
                <Button
                  size="large"
                  className={`w-full mt-5 primary text-capitalize text-white font-Inter text-sm ${classes.fontMedium} ${classes.btnPrimary}`}
                  onClick={() => {
                    submitForm();
                  }}
                >
                  <div>Save Changes</div>
                </Button>
              </Form>
            )}
          </Formik>
        ) : null}
      </div>
    </div>
  );
};
class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
      userInfo: {
        name: "",
      },
      companyInfo: {},
      tabValue: "",
      channels: [],
      open: false,
      flipkartDialogOpen: false,
      amazonData: {},
      amazonAdsData: {},
      userInfoFormType: "",
      channel: "",
      channelAction: {
        titleText: "",
        contextText: "",
        primaryButtonText: "",
        onPrimaryClick: "",
      },
      flipkart: {
        code: "",
      },
      planList: [],
      subscription: {},
      orderTable: [],
      payment: [],
      changePlan: false,
      inputvalue: "",
      code: "",
      couponCodeVal: {},
      subscriptionDays: "",
      antmodel: false,
      modelShowval: {},
      currentplanShow: false,
      loading: "",
      user_infoVal: {},
      couponinputType: "",
      couponinputerror: boolean,
      step_programData: ""
    };
  }

  componentDidMount() {
    this.getUsersList();
    this.getChannels();
    this.getPlan();
    let user_info = JSON.parse(localStorage.getItem("user_info"));
    let cmpy_info = JSON.parse(localStorage.getItem("cmpy_info"));
    let days = localStorage.getItem("subscriptionDays");
    var start = new Date();
    var end = moment(days, "YYYY-MM-DD");
    const subscriptiondays = (Math.round((end - start) / 1000 / 60 / 60 / 24)) + 1;

    if (subscriptiondays <= 0) {
      var dayscheck = null;
    } else {
      var dayscheck = subscriptiondays;
    }
    this.setState({
      subscriptionDays: dayscheck,
      user_infoVal: user_info,
    });
    if (user_info) {
      let namesArray = (user_info.name || "").split(" ");
      user_info.first_name = namesArray.shift();
      user_info.last_name = namesArray.join(" ");

      this.setState({ userInfo: user_info || {} });
      this.setState({ companyInfo: cmpy_info || {} });
      if (
        days === undefined ||
        localStorage.getItem("renewPlan") == "true" ||
        subscriptiondays <= 0
      ) {
        this.setState({ tabValue: "plan" });
        localStorage.setItem("renewPlan", false);
        this.getPlan();
      } else {
        this.setState({ tabValue: "account" });
      }
    }
    // For setting the tab value
    if (window.location.pathname.startsWith("/account-settings/marketplace")) {
      this.setState({
        tabValue: "marketplace",
      });

      if (
        window.location.pathname.startsWith(
          "/account-settings/marketplace/amazonindia"
        )
      ) {
        console.log("From Amazon");
        const parsed = queryString.parse(window.location.search);
        this.setState({
          flipkartDialogOpen: true,
          amazonData: parsed,
          channel: "amazon",
        });
        // console.log(parsed);
        // this.getAmazonAccessToken(parsed);
      } else if (
        window.location.pathname.startsWith(
          "/account-settings/marketplace/flipkart"
        )
      ) {
        const parsed = queryString.parse(window.location.search);
        this.setState({
          flipkartDialogOpen: true,
          channel: "flipkart",
          flipkart: {
            ...this.state.flipkart,
            code: parsed.code,
          },
        });
      } else if (
        window.location.pathname.startsWith(
          "/account-settings/marketplace/amazonIndiaAds"
        )
      ) {
        const parsed = queryString.parse(window.location.search);
        this.setState({
          flipkartDialogOpen: true,
          channel: "Amazon-India-Ads",
          amazonAdsData: parsed.code,

        });
      }
    }
  }
  componentWillUnmount() {
    message.destroy();
  }
  showModal(total, planCode, planval, addonCode, addonPrice) {
    this.state.couponCodeVal = [];
    let dataof = {
      totalAmout: total,
      planCode: planCode,
      planVal: planval,
      addonCode: addonCode,
      addonPrice: addonPrice,
    };
    this.setState({
      antmodel: true,
      modelShowval: dataof,
    });
  }
  handleCancel() {
    this.setState({
      antmodel: false,
    });
  }
  couponInputTypeCheck(event) {
    const re = /^[a-zA-Z0-9-_\b]+$/;
    if (event === "" || re.test(event) === true) {
      this.setState({ couponinputType: event });
      this.setState({ couponinputerror: true });
    } else {
      this.setState({ couponinputerror: false });
    }
  }
  planCheck(event) {
    const re = /^[0-9\b]+$/;
    if (event === "" || re.test(event) === true) {
      this.setState({ inputvalue: event });
      this.state.orderTable = [];
      this.state.payment = [];
      var planValue = document.getElementById("planValue").value;
      for (let order of this.state.planList.plans) {
        if (planValue <= 600) {
          if (order.description.split(",")[0] <= 600) {
            this.state.orderTable.push(order);
          }
        } else if (planValue > 600 && planValue <= 1500) {
          if (
            order.description.split(",")[0] > 600 &&
            order.description.split(",")[0] <= 1500
          ) {
            this.state.orderTable.push(order);
          }
        } else if (planValue > 1500 && planValue <= 5000) {
          if (
            order.description.split(",")[0] > 1500 &&
            order.description.split(",")[0] <= 5000
          ) {
            this.state.orderTable.push(order);
          }
        } else if (planValue > 5000) {
          if (order.description.split(",")[0] > 5000) {
            this.state.orderTable.push(order);
          }
        }
      }
      this.state.payment.push({
        name: this.state.orderTable[0]?.description.split(",")[1],
        monthly: this.state.orderTable[0]?.recurring_price,
        quarterly: this.state.orderTable[1]?.recurring_price,
        halfyearly: this.state.orderTable[2]?.recurring_price,
        annual: this.state.orderTable[3]?.recurring_price,
      });
      if (planValue > 10000) {
        this.state.payment.push({
          name: "Add on Monthly Orders",
          monthly:
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price,
          quarterly:
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price *
            3,
          halfyearly:
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price *
            6,
          annual:
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price *
            12,
        });
        this.state.payment.push({
          name: "GST",
          monthly:
            (((planValue - 10000) *
              this.state.planList.addons[0].price_brackets[0].price +
              this.state.orderTable[0]?.recurring_price) *
              18) /
            100,
          quarterly:
            (((planValue - 10000) *
              3 *
              this.state.planList.addons[0].price_brackets[0].price +
              this.state.orderTable[1]?.recurring_price) *
              18) /
            100,
          halfyearly:
            (((planValue - 10000) *
              6 *
              this.state.planList.addons[0].price_brackets[0].price +
              this.state.orderTable[2]?.recurring_price) *
              18) /
            100,
          annual:
            (((planValue - 10000) *
              12 *
              this.state.planList.addons[0].price_brackets[0].price +
              this.state.orderTable[3]?.recurring_price) *
              18) /
            100,
        });
        this.state.payment.push({
          name: "Total Amount Due",
          monthly:
            this.state.orderTable[0]?.recurring_price +
            (((planValue - 10000) *
              this.state.planList.addons[0].price_brackets[0].price +
              this.state.orderTable[0].recurring_price) *
              18) /
            100 +
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price,
          quarterly:
            this.state.orderTable[1]?.recurring_price +
            (((planValue - 10000) *
              3 *
              this.state.planList.addons[0].price_brackets[0].price +
              this.state.orderTable[1]?.recurring_price) *
              18) /
            100 +
            (planValue - 10000) *
            3 *
            this.state.planList.addons[0].price_brackets[0].price,
          halfyearly:
            this.state.orderTable[2]?.recurring_price +
            (((planValue - 10000) *
              6 *
              this.state.planList.addons[0].price_brackets[0].price +
              this.state.orderTable[2]?.recurring_price) *
              18) /
            100 +
            (planValue - 10000) *
            6 *
            this.state.planList.addons[0].price_brackets[0].price,
          annual:
            this.state.orderTable[3]?.recurring_price +
            (((planValue - 10000) *
              12 *
              this.state.planList.addons[0].price_brackets[0].price +
              this.state.orderTable[3]?.recurring_price) *
              18) /
            100 +
            (planValue - 10000) *
            12 *
            this.state.planList.addons[0].price_brackets[0].price,
          planCodeMo: this.state.orderTable[0]?.plan_code,
          planCodeQa: this.state.orderTable[1]?.plan_code,
          planCodeHa: this.state.orderTable[2]?.plan_code,
          planCodeAn: this.state.orderTable[3]?.plan_code,
          amountmon: this.state.orderTable[0]?.recurring_price,
          amountqu: this.state.orderTable[1]?.recurring_price,
          amountha: this.state.orderTable[2]?.recurring_price,
          amountan: this.state.orderTable[3]?.recurring_price,
          addonCode: this.state.planList.addons[0].addon_code,
          addonMo:
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price,
          addonQa:
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price,
          addonHa:
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price,
          addonAn:
            (planValue - 10000) *
            this.state.planList.addons[0].price_brackets[0].price,
        });
      } else {
        this.state.payment.push({
          name: "GST",
          monthly: (this.state.orderTable[0]?.recurring_price * 18) / 100,
          quarterly: (this.state.orderTable[1]?.recurring_price * 18) / 100,
          halfyearly: (this.state.orderTable[2]?.recurring_price * 18) / 100,
          annual: (this.state.orderTable[3]?.recurring_price * 18) / 100,
        });
        this.state.payment.push({
          name: "Total Amount Due",
          monthly:
            this.state.orderTable[0]?.recurring_price +
            (this.state.orderTable[0]?.recurring_price * 18) / 100,
          quarterly:
            this.state.orderTable[1]?.recurring_price +
            (this.state.orderTable[1]?.recurring_price * 18) / 100,
          halfyearly:
            this.state.orderTable[2]?.recurring_price +
            (this.state.orderTable[2]?.recurring_price * 18) / 100,
          annual:
            this.state.orderTable[3]?.recurring_price +
            (this.state.orderTable[3]?.recurring_price * 18) / 100,
          planCodeMo: this.state.orderTable[0]?.plan_code,
          planCodeQa: this.state.orderTable[1]?.plan_code,
          planCodeHa: this.state.orderTable[2]?.plan_code,
          planCodeAn: this.state.orderTable[3]?.plan_code,
          amountmon: this.state.orderTable[0]?.recurring_price,
          amountqu: this.state.orderTable[1]?.recurring_price,
          amountha: this.state.orderTable[2]?.recurring_price,
          amountan: this.state.orderTable[3]?.recurring_price,
          addonCode: this.state.planList.addons[0].addon_code,
          addonMo: 0,
          addonQa: 0,
          addonHa: 0,
          addonAn: 0,
        });
      }
    } else {
      return;
    }
  }

  razorpayPayment(razorpayval, couponval) {
    if (couponval == 0) {
      var coupon = "";
    } else {
      var coupon = couponval.coupon_code;
    }
    if (couponval.discount_type == "percentage") {
      var payAmount =
        this.state.modelShowval.totalAmout -
        (this.state.modelShowval.totalAmout *
          this.state.couponCodeVal.discount_value) /
        100;
    } else if (couponval.discount_type == "flat") {
      var payAmount =
        this.state.modelShowval.totalAmout -
        this.state.couponCodeVal.discount_value;
    } else {
      var payAmount = this.state.modelShowval.totalAmout;
    }
    const price = Math.round(payAmount) * 100;
    const url = "https://forcesight.in/wp-content/uploads/2021/01/FS-Logo.svg";
    var options = {
      key: "rzp_live_hTfpIvgxI0WnDl",
      amount: price,
      name: "ForceSight",
      description: "Payment",
      image: url,
      handler: function (response) {
        let requestBody = {
          razorpay_payment_id: response.razorpay_payment_id,
          plan_code: razorpayval.planCode,
          plan_price: razorpayval.planVal,
          addon_code: razorpayval.addonCode,
          addon_price: razorpayval.addonPrice,
          coupon_code: coupon,
        };
        args["message"] = "Please wait!";
        args["description"] = "Payment Under Process Please Wait For Sometime";
        args["className"] = "notification-loading";
        args["duration"] = 0;
        notification.open(args);
        try {
          ApiWrapper("zohoSubscription", requestBody).then(
            (response) => {
              if (response.message == "Success") {
                notification.destroy();
                this.setState({
                  changePlan: false,
                });
                this.getPlan();
                args["message"] = "Success!";
                args["description"] = "Payment Success";
                args["className"] = "notification-success";
                args["duration"] = 3;
                notification.open(args);
              } else {
                notification.destroy();
                args["message"] = "Error!";
                args["description"] = "Payment Failed";
                args["className"] = "notification-error";
                args["duration"] = 3;
                notification.open(args);
              }
            },
            (error) => {
              notification.destroy();
              console.log(error);
            }
          );
        } catch (error) {
          notification.destroy();
          console.error(error);
        }
      }.bind(this),
      prefill: {
        name: this.state.usersList[0].name,
        email: this.state.usersList[0].email,
        contact: this.state.usersList[0].phone_number,
      },
      notes: {
        address: "address",
      },
      theme: {
        color: "#15b8f3", // screen color
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  renewPlan(subscription, planValue) {
    const price = Math.round(planValue + (planValue * 18) / 100) * 100;
    const url = "https://forcesight.in/wp-content/uploads/2021/01/FS-Logo.svg";
    var options = {
      key: "rzp_live_hTfpIvgxI0WnDl",
      amount: price,
      name: "ForceSight",
      description: "Payment",
      image: url,
      handler: function (response) {
        let requestBody = {
          razorpay_payment_id: response.razorpay_payment_id,
          plan_code: subscription.plan_code,
          plan_price: subscription.sub_total,
          addon_code: 0,
          addon_price: 0,
          coupon_code: "",
        };
        args["message"] = "Please wait!";
        args["description"] = "Payment Under Process Please Wait For Sometime";
        args["className"] = "notification-loading";
        args["duration"] = 0;
        notification.open(args);
        try {
          ApiWrapper("zohoSubscription", requestBody).then(
            (response) => {
              if (response.message == "Success") {
                notification.destroy();
                this.setState({
                  changePlan: false,
                });
                this.getPlan();
                args["message"] = "Success!";
                args["description"] = "Payment Success";
                args["className"] = "notification-success";
                args["duration"] = 3;
                notification.open(args);
              } else {
                notification.destroy();
                args["message"] = "Error!";
                args["description"] = "Payment Failed";
                args["className"] = "notification-error";
                args["duration"] = 3;
                notification.open(args);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        } catch (error) {
          console.error(error);
        }
      }.bind(this),
      prefill: {
        name: this.state.usersList[0].name,
        email: this.state.usersList[0].email,
        contact: this.state.usersList[0].phone_number,
      },
      notes: {
        address: "address",
      },
      theme: {
        color: "#15b8f3", // screen color
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  // freeTrialFunCall() {
  //   try {
  //     ApiWrapper("freeTrial", {}).then(
  //       (response) => {
  //         args["message"] = "Success!";
  //         args["description"] =
  //           "Congratulations! Your free trial is now active ";
  //         args["className"] = "notification-success";
  //         args["duration"] = 3;
  //         notification.open(args);
  //         this.getPlan();
  //         this.setState({ tabValue: "marketplace" });
  //       },
  //       (error) => {}
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  changePlan() {
    this.setState({
      changePlan: true,
    });
  }

  handleDialogClose = (e) => {
    this.setState({
      open: false,
    });
  };

  handleFlipkartDialogClose = (e) => {
    this.setState({
      flipkartDialogOpen: false,
    });
  };

  handleFlipkartDialogSubmit = (values) => {
    this.setState({
      flipkartDialogOpen: false,
    });
    this.getFlipkartAccessToken(this.state.flipkart.code, values);
  };

  getChannels = async (e) => {
    try {
      ApiWrapper("getChannelURL", {}).then((response) => {
        if (response.status) {
          const found = response?.channels.find(element => element.channel == 'Amazon-India');
          this.setState({
            channels: [...response.channels],
            step_programData: found.step_program
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  getPlan = async () => {
    this.setState({
      loading: true,
    });
    try {
      ApiWrapper("planDetails", {}).then(
        (response) => {
          if (response.message == "Success") {
            var start = new Date();
            var end = moment(
              response?.organisation?.SubscriptionEndDate?.S,
              "YYYY-MM-DD"
            );
            const subscriptiondays = (Math.round(
              (end - start) / 1000 / 60 / 60 / 24
            )) + 1;
            if (subscriptiondays <= 0) {
              var dayscheck = null;
            } else {
              var dayscheck = subscriptiondays;
            }
            localStorage.setItem(
              "subscriptionDays",
              response?.organisation?.SubscriptionEndDate?.S
            );
            this.setState({
              planList: response,
              subscription: response?.subscription[0],
              loading: false,
              subscriptionDays: dayscheck,
            });
          } else {
            // message.error("Can't update. Please contact support team", 0);
          }
        },
        (error) => { }
      );
    } catch (error) {
      console.error(error);
    }
  };
  couponCodeApply(modelval) {
    const couponCode = document.getElementById("couponCode").value;
    let requestBody = {
      coupon_code: couponCode,
      plan_code: modelval.planCode,
    };
    try {
      ApiWrapper("couponCode", requestBody).then(
        (response) => {
          if (response?.coupon?.message == "Invalid coupon passed") {
            args["message"] = "Error!";
            args["description"] = response?.coupon?.message;
            args["className"] = "notification-error";
            args["duration"] = 3;
            this.setState({
              couponCodeVal: 0,
            });
            notification.open(args);
          } else if (response?.coupon?.code == 0) {
            this.setState({
              couponCodeVal: response?.coupon?.coupon,
            });
            args["message"] = "Success!";
            args["description"] = "Coupon code applied successfully.";
            args["className"] = "notification-success";
            args["duration"] = 3;
            notification.open(args);
          } else if (response?.coupon?.length == 0) {
            args["message"] = "Warning!";
            args["description"] = "Coupon code not available.";
            args["className"] = "notification-warning";
            args["duration"] = 3;
            notification.open(args);
            this.setState({
              couponCodeVal: 0,
            });
          }
        },
        (error) => { }
      );
    } catch (error) {
      console.error(error);
    }
  }

  getFlipkartAccessToken = async (oauthCode, values) => {
    let requestBody = {
      // orgId: 100,
      username: values.email, //"robustgroupsindia@gmail.com",
      password: values.password, //"Magathi2017@",
      userNameType: "email",
      oauthCode: oauthCode,
      channel: "Flipkart",
    };
    try {
      ApiWrapper("requestFlipkart", requestBody).then(
        (response) => {
          if (response.status) {
            this.setState({
              open: true,
              channelAction: {
                titleText: "Success",
                contextText: response.message,
                primaryButtonText: "Close",
              },
            });
          } else {
            this.setState({
              open: true,
              channelAction: {
                titleText: "Error",
                contextText: response.message,
                primaryButtonText: "Close",
              },
            });
          }
        },
        (error) => {
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  getAmazonAccessToken = async (values) => {
    this.setState({
      flipkartDialogOpen: false,
    });
    let requestBody = {
      // orgId: 100,
      sellerId: this.state.amazonData.selling_partner_id,
      username: values.email, //"robustgroupsindia@gmail.com",
      oauthCode: this.state.amazonData.spapi_oauth_code,
      password: values.password, //"Magathi2017@",
      channel: "Amazon-India",
    };
    try {
      ApiWrapper("requestAmazon", requestBody).then(
        (response) => {
          if (response.status) {
            this.setState({
              open: true,
              channelAction: {
                titleText: "Success",
                contextText: response.message,
                primaryButtonText: "Close",
              },
            });
          } else {
            this.setState({
              open: true,
              channelAction: {
                titleText: "Error",
                contextText: response.message,
                primaryButtonText: "Close",
              },
            });
          }
        },
        (error) => {
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    } catch (error) { }
  };
  getAmazonIndiaAdsAccessToken = async (values) => {
    this.setState({
      flipkartDialogOpen: false,
    });
    let requestBody = {

      channel: "Amazon-India-Ads",
      username: values.email,
      password: values.password,
      oauthCode: this.state.amazonAdsData,
    };
    try {
      ApiWrapper("requestAmazonIndiaAds", requestBody).then(
        (response) => {
          if (response.status) {
            this.setState({
              open: true,
              channelAction: {
                titleText: "Success",
                contextText: response.message,
                primaryButtonText: "Close",
              },
            });
          } else {
            this.setState({
              open: true,
              channelAction: {
                titleText: "Error",
                contextText: response.message,
                primaryButtonText: "Close",
              },
            });
          }
        },
        (error) => {
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    } catch (error) { }
  };

  getMeeshoAccessToken = async (values) => {
    this.setState({
      flipkartDialogOpen: false,
    });
    let requestBody = {

      channel: "Meesho",
      type : "login",
      email: values.email,
      password: values.password,
    };
    try {
      ApiWrapper("requestMeesho", requestBody).then(
        (response) => {
          if (response.status) {
            this.setState({
              open: true,
              channelAction: {
                titleText: "Success",
                contextText: response.message,
                primaryButtonText: "Close",
              },
            });
          } else {
            this.setState({
              open: true,
              channelAction: {
                titleText: "Error",
                contextText: response.message,
                primaryButtonText: "Close",
              },
            });
          }
        },
        (error) => {
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    } catch (error) { }
  };
  showChannelStatusDialog = (e) => {
    this.setState({
      open: true,
    });
  };

  getUsersList = async (e) => {
    try {
      ApiWrapper("listUserAdmin", {}).then(
        (response) => {
          if (response.status) {
            this.setState({
              usersList: response.users,
            });
          }
        },
        (error) => {
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    } catch (error) { }
  };

  handleTab = (value) => {
    if (value == "plan") {
      // this.getPlan();
      this.setState({
        changePlan: false,
        currentplanShow: false,
      });
    }
    if (this.state.subscriptionDays <= 0) {
      this.setState({ tabValue: "plan" });
    } else {
      this.setState({ tabValue: value });
    }
  };

  handleChangePassword = async (values, actions, e) => {
    console.log("CHANGE PASSWORD REQUESTED");
    let changePassword = {
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_new_password: values.confirm_new_pasword,
    };

    try {
      message.loading("Updating password", 0);
      ApiWrapper("changePassword", changePassword).then(
        (response) => {
          if (response.status) {
            message.destroy();
            message.success("Password is changed successfully");
            let reqData = {
              updated_at: new Date(),
              module_name: "ChangePassword",
              updated_value: {
                medium: "email",
                action: "forgotpassword",
              },
            };
            ApiWrapper("updateChangeLog", reqData).then(
              function (response) {
              },
              function (error) {}
            );
          }
        },
        (error) => {
          message.destroy();
        }
      );
    } catch (error) {
      message.destroy();
    }
  };

  handleUpdateUserInfo = async (values, actions, e) => {
    let userInfo = {
      name: values.first_name + " " + values.last_name,
      email: this.state.userInfo.email,
    };

    try {
      message.loading("Updating in progress..", 0);
      ApiWrapper("editUserInfo", userInfo).then(
        (response) => {
          if (response.status) {
            let user_info = { ...this.state.userInfo };
            user_info.name = values.first_name + " " + values.last_name;
            user_info.first_name = values.first_name;
            user_info.last_name = values.last_name;
            localStorage.setItem("user_info", JSON.stringify(user_info));
            this.setState({ userInfo: user_info || {} });
            this.getUsersList();
            message.destroy();
            message.success("User Info updated");
            this.props.updateUserInfo(userInfo);
          }
          actions.setSubmitting(false);
        },
        (error) => {
          message.destroy();
        }
      );
    } catch (error) {
      message.destroy();
    }
  };

  handleUpdateCompanyInfo = async (values, actions, e) => {
    let companyInfo = {
      org_name: values.org_name,
      address: values.address,
      state: values.state,
      gst_number: values.gst_number,
      postal_code: values.pincode.toString(),
      city: values.city,
      country: values.country,
    };
    try {
      ApiWrapper("editOrganisation", companyInfo).then(
        (response) => {
          if (response.status) {
            let cmpy_info = { ...this.state.companyInfo, ...companyInfo };
            this.setState({ companyInfo: cmpy_info || {} });

            localStorage.setItem("cmpy_info", JSON.stringify(cmpy_info));
            this.props.updateCompanyInfo(companyInfo);
            message.success("Company Info updated");
          }
          actions.setSubmitting(false);
        },
        (error) => {
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  handleButtonClick = (values, actions, e) => {
    if (
      values.confirm_new_pasword !== "" &&
      values.confirm_new_pasword === values.new_password
    ) {
      this.handleChangePassword(values, actions);
    }
    if (values.first_name !== null) {
      this.handleUpdateUserInfo(values, actions);
    }
  };

  handleChannelClick = (e) => {
    if (!e.isConnected && e.channel !== "Meesho") {
      window.open(e.auth[0].url, "_blank");
    }else {
      this.setState({
        flipkartDialogOpen: true,
      });
    }
  };
  disconnectChannelHandler = (e) => {
    let jsonRequestBody = {
      channel: e.channel,
      isDisconnected: true
    };
    if (e.channel === "Amazon-India") {
      ApiWrapper("updateAmazonChannelAccess", jsonRequestBody).then(
        function (response) {
          if (response.status) {
            message.success("Marketplace Disconnected!");
            this.getChannels();
          }
        }.bind(this),
        function (error) {
          message.error(error);
        }
      );
    } else if (e.channel === "Flipkart") {
      ApiWrapper("updateFlipkartChannelAccess", jsonRequestBody).then(
        function (response) {
          if (response.status) {
            message.success("Marketplace Disconnected!");
            this.getChannels();
          }
        }.bind(this),
        function (error) {
          message.error(error);
        }
      );
    } else {
      ApiWrapper("updateAmazonAdsChannelAccess", jsonRequestBody).then(
        function (response) {
          if (response.status) {
            message.success("Marketplace Disconnected!");
            this.getChannels();
          }
        }.bind(this),
        function (error) {
          message.error(error);
        }
      );
    }
  };

  handleMarketplaceInfoUpdate = (values, channel) => {
    let jsonRequestBody = {
      username: values.email,
      password: values.password,
      userNameType: "email",
      channel: channel.channel,
    };
    if (channel.channel === "Amazon-India") {
      ApiWrapper("updateAmazonChannelAccess", jsonRequestBody).then(
        function (response) {
          if (response.status) {
            message.success("Details updates successfully!");
          } else {
            // message.error("");
          }
        },
        function (error) { }
      );
    } else if (channel.channel === "Flipkart") {
      ApiWrapper("updateFlipkartChannelAccess", jsonRequestBody).then(
        function (response) {
          if (response.status) {
            message.success("Details updates successfully!");
          } else {
            args["message"] = "Error!";
            args["description"] = "Entered marketplace credentials are invalid";
            args["className"] = "notification-error";
            args["duration"] = 3;
            notification.open(args);
          }
        },
        function (error) { }
      );
    } else {
      ApiWrapper("updateAmazonAdsChannelAccess", jsonRequestBody).then(
        function (response) {
          if (response.status) {
            message.success("Details updates successfully!");
          } else {
            // message.error("");
          }
        },
        function (error) { }
      );
    }
  };

  render() {
    return (
      <div style={{ fontFamily: "inter" }}>
        <Helmet>
          <title>{"Forcesight"}</title>
        </Helmet>
        <div>
          <CustomAppBar />
          <div className="settings-header">
            <Container>
              <PageHeader title="Settings" />
              <div className="mt-5">
                <span
                  className={
                    this.state.tabValue === "account"
                      ? "setting-tab-active"
                      : "setting-tab"
                  }
                  onClick={() => this.handleTab("account")}
                >
                  Account Settings
                </span>
                {this.state.userInfo?.role === "admin" && (
                  <span
                    className={
                      this.state.tabValue === "marketplace"
                        ? "setting-tab-active"
                        : "setting-tab"
                    }
                    onClick={() => this.handleTab("marketplace")}
                  >
                    Marketplace Settings
                  </span>
                )}
                {this.state.userInfo?.role === "admin" && (
                  <span
                    className={
                      this.state.tabValue === "user"
                        ? "setting-tab-active"
                        : "setting-tab"
                    }
                    onClick={() => this.handleTab("user")}
                  >
                    User Management
                  </span>
                )}
                <span
                  className={
                    this.state.tabValue === "compInfo"
                      ? "setting-tab-active"
                      : "setting-tab"
                  }
                  onClick={() => this.handleTab("compInfo")}
                >
                  Company Info
                </span>

                {this.state.userInfo?.role === "admin" && (
                  <span
                    className={
                      this.state.tabValue === "plan"
                        ? "setting-tab-active"
                        : "setting-tab"
                    }
                    onClick={() => this.handleTab("plan")}
                  >
                    Plan
                  </span>
                )}
                {this.state.userInfo?.role === "admin" && (
                  <span
                    className={
                      this.state.tabValue === "invoice history"
                        ? "setting-tab-active"
                        : "setting-tab"
                    }
                    onClick={() => this.handleTab("invoice history")}
                  >
                    Invoice History
                  </span>
                )}
                {this.state.userInfo?.role === "admin" && (
                  <span
                    className={
                      this.state.tabValue === "Pincode"
                        ? "setting-tab-active"
                        : "setting-tab"
                    }
                    onClick={() => this.handleTab("Pincode")}
                  >
                    Pincode
                  </span>
                )}
              </div>
            </Container>
          </div>

          <Container style={{ marginTop: 25, fontFamily: "Inter var" }}>
            {this.state.tabValue === "account" ? (
              <div className="inter">
                <CustomSectionHeader title="User Info" subtitle="" />
                {this.state.userInfo.email && (
                  <Formik
                    initialValues={{
                      first_name: this.state.userInfo.first_name || "",
                      last_name: this.state.userInfo.last_name || "",
                      phone_number: "",
                      email: "",
                      org_name: this.state.companyInfo.org_name,
                      address: this.state.companyInfo.address,
                      state: this.state.companyInfo.state,
                      gst_number: this.state.companyInfo.gst_number,
                      pincode: parseInt(this.state.companyInfo.postal_code),
                      city: this.state.companyInfo.city,
                      country: this.state.companyInfo.country,
                      old_password: "",
                      new_password: "",
                      confirm_new_pasword: "",
                    }}
                    validationSchema={ChangePasswordSchema}
                    // validate={(values) => {
                    //   const errors: Partial<Values> = {};
                    //   if (
                    //     values.new_password !== "" &&
                    //     values.new_password.trim().length < 8
                    //   ) {
                    //     errors.new_password =
                    //       "Password must be at least 8 characters";
                    //   } else if (
                    //     values.confirm_new_pasword !== values.new_password
                    //   ) {
                    //     errors.confirm_new_pasword = "Passwords do not match";
                    //     errors.new_password = "Passwords do not match";
                    //   } else if (values.confirm_new_pasword !== "") {
                    //     if (
                    //       values.confirm_new_pasword === values.new_password &&
                    //       !values.old_password
                    //     ) {
                    //       errors.old_password = "Required";
                    //     }
                    //   }

                    //   return errors;
                    // }}

                    onSubmit={(values, actions) => {
                      if (this.state.userInfoFormType === "2") {
                        if (
                          values.confirm_new_pasword !== "" &&
                          values.confirm_new_pasword === values.new_password
                        ) {
                          this.handleChangePassword(values, actions);
                        }
                      } else if (this.state.userInfoFormType === "1") {
                        this.handleUpdateUserInfo(values, actions);
                      }
                    }}
                  >
                    {({ submitForm, isSubmitting }) => (
                      <Form>
                        <Grid
                          container
                          justify="left"
                          style={{ marginBottom: 50, fontFamily: "inter" }}
                        >
                          <Grid>
                            <div
                              style={{
                                marginTop: 30,
                                width: 350,
                                maxWidth: 350,
                                marginRight: 50,
                              }}
                            >
                              <CustomTextField
                                title="First Name"
                                name="first_name"
                                type="text"
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 30,
                                width: 350,
                                maxWidth: 350,
                                marginRight: 50,
                              }}
                            >
                              <CustomTextField
                                title="Mobile Number"
                                name="phone_number"
                                type="number"
                                disabled={true}
                                helpText={this.state.userInfo.phone_number}
                              />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <ButtonContainer
                                title="Save Changes"
                                onClick={() => {
                                  this.setState(
                                    { userInfoFormType: "1" },
                                    () => {
                                      submitForm();
                                    }
                                  );
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid>
                            <div
                              style={{
                                marginTop: 30,
                                width: 350,
                                maxWidth: 350,
                              }}
                            >
                              <CustomTextField
                                title="Last Name"
                                name="last_name"
                                type="text"
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 30,
                                width: 350,
                                maxWidth: 350,
                              }}
                            >
                              <CustomTextField
                                title="Email"
                                name="email"
                                type="number"
                                disabled={true}
                                helpText={this.state.userInfo.email}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <hr
                          style={{
                            color: "#000000",
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            height: 0.1,
                            borderColor: "rgba(0, 0, 0, 0.1)",
                          }}
                        />
                        <CustomSectionHeader
                          title="Change Password"
                          subtitle=""
                        />
                        <Grid container>
                          <Grid>
                            <div
                              style={{
                                marginTop: 24,
                                width: 350,
                                maxWidth: 350,
                                marginRight: 50,
                              }}
                            >
                              <CustomTextField
                                title="Old Password"
                                name="old_password"
                                type="password"
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 24,
                                width: 350,
                                maxWidth: 350,
                              }}
                            >
                              <CustomTextField
                                title="New Password"
                                name="new_password"
                                type="password"
                              />
                            </div>
                          </Grid>
                          <Grid>
                            <div
                              style={{
                                marginTop: 118,
                                width: 350,
                                maxWidth: 350,
                                marginRight: 50,
                              }}
                            >
                              <CustomTextField
                                title="Retype New Password"
                                name="confirm_new_pasword"
                                type="password"
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            marginBottom: 50,
                            marginTop: 10,
                          }}
                        >
                          <ButtonContainer
                            title="Save Changes"
                            disabled={isSubmitting}
                            onClick={() => {
                              this.setState({ userInfoFormType: "2" }, () => {
                                submitForm();
                              });
                            }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            ) : null}
            {this.state.tabValue === "user" ? (
              <>
                <CustomSectionHeader
                  title="User Management"
                // subtitle="Pickled heirloom coloring book gastropub iceland etsy consectetur portland tbh pork belly labore chillwave tousled succulents."
                />
                <UsersTable
                  usersList={this.state.usersList}
                  onUpdateUser={(user) => {
                    this.setState({ userInfo: user });
                  }}
                />
              </>
            ) : null}
            {this.state.tabValue === "marketplace" ? (
              <>
                <CustomSectionHeader title="Setup marketplace" subtitle="" />
                <Grid container className="mt-5">
                  {this.state.channels &&
                    this.state.channels.map((channel) => (
                      <Grid key={channel.name}>
                        <GridContainer
                          channel={channel}
                          handleChannelClick={this.handleChannelClick}
                          disconnectChannelHandler={this.disconnectChannelHandler}
                          handleMarketplaceInfoUpdate={
                            this.handleMarketplaceInfoUpdate
                          }
                        />
                        {/* <Button
                          variant="contained"
                          onClick={this.handleChannelClick.bind(this, channel)}
                        >
                          {channel.name}
                        </Button> */}
                      </Grid>
                    ))}
                </Grid>
                <ChannelStatusDialog
                  dialogTitleText={this.state.channelAction.titleText}
                  dialogContentText={this.state.channelAction.contextText}
                  primaryButtonText={this.state.channelAction.primaryButtonText}
                  onPrimaryClick={this.handleDialogClose}
                  open={this.state.open}
                  onClose={this.handleDialogClose}
                />
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={UservalidationSchema}
                  onSubmit={(values, actions) => {
                    if (this.state.channel === "flipkart") {
                      this.handleFlipkartDialogSubmit(values);
                    } else if (this.state.channel === "amazon") {
                      this.getAmazonAccessToken(values);
                    } else if (this.state.channel === "Amazon-India-Ads") {
                      this.getAmazonIndiaAdsAccessToken(values)
                    } else {
                      this.getMeeshoAccessToken(values)
                    }
                  }}
                >
                  {({ submitForm, isSubmitting }) => (
                    <FlipkartUserDetailsDialog
                      channel={this.state.channel}
                      open={this.state.flipkartDialogOpen}
                      onClose={this.handleFlipkartDialogClose}
                      onSubmitClick={submitForm}
                    />
                  )}
                </Formik>

                <CustomSectionHeader title="Select Your Step Program" subtitle="" />
                <Select defaultValue={this.state.step_programData} style={{ width: 300 }} onChange={handleChange}>
                  <Option value="Premium">Premium</Option>
                  <Option value="Advanced">Advanced</Option>
                  <Option value="Standard">Standard</Option>
                  <Option value="Basic">Basic</Option>
                </Select>
              </>
            ) : null}
          </Container>

          {this.state.tabValue === "compInfo" ? (
            <>
              <Formik
                initialValues={{
                  org_name: this.state.companyInfo.org_name,
                  gst_number: this.state.companyInfo.gst_number,
                  address: this.state.companyInfo.address,
                  state: this.state.companyInfo.state,
                  pincode: parseInt(this.state.companyInfo.postal_code),
                  city: this.state.companyInfo.city,
                  country: this.state.companyInfo.country,
                }}
                validate={(values) => {
                  const errors: Partial<Values> = {};
                  if (!values.org_name) {
                    errors.org_name = "Cannot be empty";
                  }
                  if (!values.gst_number) {
                    errors.gst_number = "Cannot be empty";
                  } else if (
                    !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                      values.gst_number
                    )
                  ) {
                    errors.gst_number = "Invalid GST number";
                  }
                  return errors;
                }}
                onSubmit={(values, actions) => {
                  console.log("Here");
                  this.handleUpdateCompanyInfo(values, actions);
                }}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form style={{ marginLeft: "10rem" }}>
                    <div style={{ width: "max-content" }}>
                      <CustomSectionHeader title="Company Info" subtitle="" />
                    </div>
                    <Grid container justify="left" style={{ marginBottom: 20 }}>
                      <Grid
                        style={{
                          width: 350,
                        }}
                      >
                        <div className="mb-3">
                          <CustomTextField
                            title="Company Name"
                            name="org_name"
                            type="text"
                          />
                        </div>
                        <div className="mb-3">
                          <CustomTextField
                            title="Street Address"
                            name="address"
                            type="text"
                          />
                        </div>

                        <div className="mb-3">
                          <CustomTextField
                            title="State"
                            name="state"
                            type="text"
                          />
                        </div>
                        <div className="mb-3">
                          <CustomTextField
                            title="Country"
                            name="country"
                            type="text"
                            helpText="India"
                          />
                        </div>
                      </Grid>
                      <Grid
                        style={{
                          marginLeft: 20,
                          width: 350,
                        }}
                      >
                        <div className="mb-3">
                          <CustomTextField
                            title="GST Number"
                            name="gst_number"
                            type="text"
                          />
                        </div>
                        <div className="mb-3">
                          <CustomTextField
                            title="City"
                            name="city"
                            type="text"
                          />
                        </div>
                        <div className="mb-3 pincodeNumber">
                          <CustomTextField
                            title="Pincode"
                            name="pincode"
                            type="number"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Button
                      size="large"
                      className={` primary text-capitalize text-white `}
                      onClick={submitForm}
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontWeight: 500,
                        color: "#fff",
                        marginBottom: 100,
                        backgroundColor: "#2684FF",
                      }}
                    >
                      Save Changes
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          ) : null}

          {this.state.tabValue === "plan" ? (
            <Container>
              {this.state.loading ? (
                <Spin
                  size="large"
                  tip="Loading..."
                  style={{
                    marginLeft: "50%",
                  }}
                ></Spin>
              ) : null}

              {/* Free Trial Start */}
              {/* <div className="flex items-center">
                {this.state.planList?.length == 0 ||
                  (!this.state?.planList?.organisation?.IsTrailUsed?.BOOL && (
                    <Button
                      size="large"
                      className={` primary text-capitalize text-white`}
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontWeight: 500,
                        color: "#4ECD98",
                        backgroundColor: "#4ECD98",
                      }}
                      onClick={() => this.freeTrialFunCall()}
                    >
                      Free Trial
                    </Button>
                  ))}
              </div> */}
              <div className="flex items-center justify-end">
                <p>
                  <b>{this.state.subscriptionDays || 0} </b> Days Remaining
                </p>
              </div>
              {/* Free Trial End */}

              {this.state.planList?.subscription?.length == 0 ||
                this.state.changePlan ? (
                <>
                  <div style={{ width: "max-content" }}>
                    <CustomSectionHeader title="Plans " subtitle="" />
                  </div>
                  <Grid container justify="left" style={{ marginBottom: 20 }}>
                    <Grid>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label
                          style={{
                            paddingRight: "20px",
                          }}
                        >
                          No of Monthly Orders
                        </label>
                        <form>
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "5px",
                            }}
                          >
                            <input
                              style={{
                                border: "1px solid #707070",
                                borderRadius: "3px",
                                marginRight: "10px",
                                height: "46px",
                                width: "200px",
                                fontSize: "18px",
                                paddingLeft: "12px",
                              }}
                              value={this.state.inputvalue}
                              name="planValue"
                              id="planValue"
                              onChange={(e) => this.planCheck(e.target.value)}
                            />
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </Grid>

                  <div className="ag-theme-alpine" style={{ width: 1000 }}>
                    {this.state.payment.length > 1 && (
                      <>
                        {/* Plan Table Start*/}
                        <table
                          style={{
                            width: "1000px",
                          }}
                          className="plan_table"
                        >
                          <thead>
                            <tr>
                              <th>Plans</th>
                              {this.state.planList?.organisation?.is_plan_enable?.BOOL && (
                                <th>Monthly Plan </th>
                              )}
                              <th>Quarterly Plan</th>
                              <th>Half Yearly Plan</th>
                              <th>Annual Plan</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.payment.map((data) => {
                              return (
                                <>
                                  <tr>
                                    <td>{data.name}</td>
                                    {this.state.planList?.organisation?.is_plan_enable?.BOOL && (
                                      <td class="wrapper">
                                        {new Intl.NumberFormat("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                        }).format(
                                          data.monthly ||
                                          (data.monthly *
                                            this.state?.couponCodeVal
                                              ?.discount_value) /
                                          100
                                        )}
                                        {data.name == "Total Amount Due" && (
                                          <Button
                                            size="large"
                                            class="button"
                                            onClick={() =>
                                              this.showModal(
                                                data.monthly,
                                                data.planCodeMo,
                                                data.amountmon,
                                                data.addonCode,
                                                data.addonMo
                                              )
                                            }
                                          >
                                            pay
                                          </Button>
                                        )}
                                      </td>
                                    )}

                                    {!this.state.planList?.organisation?.is_plan_enable?.BOOL && !data.monthly && (
                                      <td class="wrapper">
                                        {new Intl.NumberFormat("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                        }).format(
                                          data.monthly ||
                                          (data.monthly *
                                            this.state?.couponCodeVal
                                              ?.discount_value) /
                                          100
                                        )}
                                        {data.name == "Total Amount Due" && (
                                          <Button
                                            size="large"
                                            class="button"
                                            onClick={() =>
                                              this.showModal(
                                                data.monthly,
                                                data.planCodeMo,
                                                data.amountmon,
                                                data.addonCode,
                                                data.addonMo
                                              )
                                            }
                                          >
                                            pay
                                          </Button>
                                        )}
                                      </td>
                                    )}
                                    <td class="wrapper">
                                      {new Intl.NumberFormat("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                      }).format(data.quarterly)}
                                      {data.name == "Total Amount Due" && (
                                        <Button
                                          size="large"
                                          class="button"
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 500,
                                            color: "#4ECD98",
                                            backgroundColor: "#4ECD98",
                                          }}
                                          onClick={() =>
                                            this.showModal(
                                              data.quarterly,
                                              data.planCodeQa,
                                              data.amountqu,
                                              data.addonCode,
                                              data.addonQa
                                            )
                                          }
                                        >
                                          Pay
                                        </Button>
                                      )}
                                    </td>
                                    <td class="wrapper">
                                      {new Intl.NumberFormat("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                      }).format(data.halfyearly)}
                                      {data.name == "Total Amount Due" && (
                                        <Button
                                          size="large"
                                          class="button"
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 500,
                                            color: "#4ECD98",
                                            backgroundColor: "#4ECD98",
                                          }}
                                          onClick={() =>
                                            this.showModal(
                                              data.halfyearly,
                                              data.planCodeHa,
                                              data.amountha,
                                              data.addonCode,
                                              data.addonHa
                                            )
                                          }
                                        >
                                          Pay
                                        </Button>
                                      )}
                                    </td>
                                    <td class="wrapper">
                                      {new Intl.NumberFormat("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                      }).format(data.annual)}
                                      {data.name == "Total Amount Due" && (
                                        <Button
                                          size="large"
                                          class="button"
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            fontWeight: 500,
                                            color: "#4ECD98",
                                            backgroundColor: "#4ECD98",
                                          }}
                                          onClick={() =>
                                            this.showModal(
                                              data.annual,
                                              data.planCodeAn,
                                              data.amountan,
                                              data.addonCode,
                                              data.addonAn
                                            )
                                          }
                                        >
                                          Pay
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* Plan Table End*/}

                        {/* Ant Model Start */}
                        <Modal
                          title="Plan Details"
                          okText="Pay Now"
                          visible={this.state.antmodel}
                          onOk={() =>
                            this.razorpayPayment(
                              this.state.modelShowval,
                              this.state.couponCodeVal
                            )
                          }
                          onCancel={() => this.handleCancel()}
                          width={400}
                        >
                          <p className="flex items-center justify-between p-1.5">
                            <span>Plan Amount </span>
                            <span>
                              {" "}
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                              }).format(this.state.modelShowval.totalAmout)}
                            </span>
                          </p>
                          {this.state?.planList?.organisation?.Coupon_enable
                            ?.BOOL && (
                              <>
                                {this.state.couponCodeVal ? (
                                  <>
                                    <p className="flex items-center justify-between p-1.5">
                                      <span>Total Discount </span>
                                      {this.state.couponCodeVal.discount_type ==
                                        "percentage" ? (
                                        <span>
                                          -{" "}
                                          {new Intl.NumberFormat("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                          }).format(
                                            (this.state.modelShowval.totalAmout *
                                              this.state.couponCodeVal
                                                .discount_value) /
                                            100
                                          ) || ""}
                                        </span>
                                      ) : null}
                                      {this.state.couponCodeVal.discount_type ==
                                        "flat" ? (
                                        <span>
                                          -{" "}
                                          {new Intl.NumberFormat("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                          }).format(
                                            this.state.couponCodeVal
                                              .discount_value
                                          )}
                                        </span>
                                      ) : null}
                                    </p>
                                    <p className="flex items-center justify-between p-1.5">
                                      <b>Total Amount </b>
                                      {this.state.couponCodeVal.discount_type ==
                                        "percentage" ? (
                                        <b>
                                          {" "}
                                          {new Intl.NumberFormat("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                          }).format(
                                            this.state.modelShowval.totalAmout -
                                            (this.state.modelShowval
                                              .totalAmout *
                                              this.state.couponCodeVal
                                                .discount_value) /
                                            100
                                          ) || ""}{" "}
                                        </b>
                                      ) : null}
                                      {this.state.couponCodeVal.discount_type ==
                                        "flat" ? (
                                        <b>
                                          {" "}
                                          {new Intl.NumberFormat("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                          }).format(
                                            this.state.modelShowval.totalAmout -
                                            this.state.couponCodeVal
                                              .discount_value
                                          ) || ""}{" "}
                                        </b>
                                      ) : null}
                                    </p>
                                  </>
                                ) : null}

                                <form className={`flex justify-between ml-1.5`}>
                                  <div className={`flex flex-col`}>
                                    <label
                                      style={{
                                        paddingRight: "20px",
                                      }}
                                    >
                                      Coupon Code
                                    </label>

                                    <input
                                      style={{
                                        border: "1px solid #707070",
                                        borderRadius: "3px",
                                        marginRight: "10px",
                                        height: "32px",
                                        width: "200px",
                                        fontSize: "16px",
                                        paddingLeft: "12px",
                                        marginTop: "5px",
                                      }}
                                      value={this.state.couponinputType}
                                      onChange={(e) =>
                                        this.couponInputTypeCheck(e.target.value)
                                      }
                                      name="couponCode"
                                      id="couponCode"
                                    />
                                    {!this.state.couponinputerror && (
                                      <div>
                                        Special characters not allowed expect{" "}
                                        <b>hyphen</b> and <b>underscore</b>{" "}
                                      </div>
                                    )}
                                  </div>
                                  <Button
                                    size="large"
                                    className={` primary text-capitalize text-white `}
                                    style={{
                                      fontFamily: "Inter",
                                      fontSize: "14px",
                                      height: "32px",
                                      width: "80px",
                                      lineHeight: "22px",
                                      color: "#4ECD98",
                                      margin: "27px 110px 0 0",
                                      backgroundColor: "#4ECD98",
                                    }}
                                    onClick={() =>
                                      this.couponCodeApply(
                                        this.state.modelShowval
                                      )
                                    }
                                  >
                                    Apply
                                  </Button>
                                </form>
                              </>
                            )}
                        </Modal>
                        {/* Ant Model End */}
                      </>
                    )}
                  </div>
                </>
              ) : null}

              {/* Current Plan Start */}
              {this.state.planList?.subscription?.length > 0 ||
                this.state.currentplanShow ? (
                <>
                  <div style={{ width: "max-content" }}>
                    <CustomSectionHeader title="Current Plan" subtitle="" />
                  </div>
                  <div className="ag-theme-alpine" style={{ width: 300 }}>
                    <table
                      style={{
                        width: "450px",
                      }}
                      className="invoice_table"
                    >
                      <thead>
                        <tr>
                          <th>Plan</th>
                          <th>{this.state?.subscription?.plan_name}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state?.subscription?.name}</td>
                          <td>
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                            }).format(
                              this.state?.planList?.plan?.recurring_price || ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Payment Date</td>
                          <td>
                            {this.state?.subscription?.current_term_starts_at}
                          </td>
                        </tr>
                        <tr>
                          <td>Next Billing Date</td>
                          <td>
                            {this.state?.subscription?.current_term_ends_at}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "30px",
                    }}
                  >
                    <Button
                      size="large"
                      className={` primary text-capitalize text-white `}
                      style={{
                        width: "130px",
                        height: "42px",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontWeight: 500,
                        color: "#fff",
                        backgroundColor: "#2684FF",
                      }}
                      onClick={() =>
                        this.renewPlan(
                          this.state.subscription,
                          this.state?.planList?.plan?.recurring_price
                        )
                      }
                    >
                      Renew Plan
                    </Button>
                    <a
                      style={{
                        paddingTop: "9px",
                        paddingLeft: "5px",
                        color: "#40a9ff",
                      }}
                      onClick={() => this.changePlan()}
                    >
                      Change Plan ?
                    </a>
                  </div>
                </>
              ) : null}
              {/* Current Plan End */}
            </Container>
          ) : null}
          {this.state.tabValue === "invoice history" ? (
            <InvoiceTab invoiceTab={this.state.planList} />
          ) : null}

          {this.state.tabValue === "Pincode" ? (
           <PinCode />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToPorps)(AccountSettings)
);
