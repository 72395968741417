import { Button, message, Result } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Form, Formik } from "formik";
import React from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import CustomFormFooter from "../../components/CustomFormFooter";
import CustomFormHeader from "../../components/CustomFormHeader";
import CustomTextField from "../../components/CustomTextField";
import ApiWrapper from "../../utils/ApiWrapper";
import AuthLayout from "./authLayout";

const OTPvalidationSchema = Yup.object().shape(
  {
    emailOtp: Yup.string().when("mobileOtp", {
      is: (mobileOtp) => !mobileOtp || mobileOtp.length === 0,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    // .trim()
    // .required("Required"),
    mobileOtp: Yup.string().when("emailOtp", {
      is: (emailOtp) => !emailOtp || emailOtp.length === 0,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
  },
  ["emailOtp", "mobileOtp"]
);
function Otp(props) {
  const [isResendOpen, setIsResendOpen] = React.useState(false);
  let params = new URL(window.location.href).searchParams;
  let userMedium = params.get("medium");
  let userAction = params.get("action");
  const handleEmailOtp = async (values, actions, e) => {
    let OptData = {
      action: userAction,
      medium: "email",
      otp: values.emailOtp,
    };
    ApiWrapper("verifyOtpSelf", OptData, localStorage.emailOTPToken).then(
      (response) => {
        if (response.status) {
          // if (OptData.action !== "signup") {
          //   // handleMobileOtp(values, actions);
          // } else {
          // }
          localStorage.clear();
          message.success("OTP verified successfully");
          props.history.replace("/login");
        } else {
          if (response.message_code === "E131") {
            actions.setErrors({
              emailOtp: "Incorrect OTP",
            });
          } else {
            message.error(response.message);
          }
          actions.setSubmitting(false);
        }
      },
      function (error) {}
    );
  };

  const handleMobileOtp = async (values, actions, e) => {
    let OptData = {
      action: userAction,
      medium: "mobile",
      otp: values.mobileOtp,
    };
    ApiWrapper(
      "verifyOtpSelf",
      OptData,
      localStorage.getItem("mobileOTPToken")
    ).then(
      (response) => {
        if (response.status) {
          localStorage.clear();

          message.success("User created successfully");
          props.history.replace("/login");
        } else {
          if (response.message_code === "E131") {
            actions.setErrors({
              mobileOtp: "Incorrect OTP",
            });
          } else {
            message.error(response.message);
          }
        }
        actions.setSubmitting(false);
      },
      function (error) {}
    );
  };

  const handleResendOtpClick = (e) => {
    callSendOtpEmail();
    callSendOtpMobile();
  };

  const callSendOtpEmail = async (e) => {
    let otpRequestJson = {
      medium: "email",
      action: userAction,
    };
    ApiWrapper(
      "sendOtpSelf",
      otpRequestJson,
      localStorage.getItem("emailOTPToken")
    ).then(
      (response) => {
        if (response.status) {
          callSendOtpMobile();
        }
      },
      function (error) {}
    );
  };

  const callSendOtpMobile = async (e) => {
    let otpRequestJson = {
      medium: "mobile",
      action: userAction,
    };
    ApiWrapper(
      "sendOtpSelf",
      otpRequestJson,
      localStorage.getItem("mobileOTPToken")
    ).then(
      (response) => {
        setIsResendOpen(true);
      },
      function (error) {}
    );
  };
  return (
    <AuthLayout>
      {!userAction && (
        <Result
          title="Unable to process your request"
          extra={
            <Button type="primary" key="console">
              Back to login
            </Button>
          }
        />
      )}
      {userAction && (
        <>
          <CustomFormHeader
            title="Enter OTP"
            subtitle="An OTP(One Time Passcode) has been sent to your registered email id  and registered mobile number.
                            Please enter the OTPs in the fields below to verify your email id and password."
          />
          <Formik
            initialValues={{
              emailOtp: "",
              mobileOtp: "",
            }}
            validationSchema={OTPvalidationSchema}
            onSubmit={(values, actions) => {
              if (values.emailOtp) {
                handleEmailOtp(values, actions);
              } else if (values.mobileOtp) {
                handleMobileOtp(values, actions);
              }
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <div style={{ marginTop: 36 }}>
                  <CustomTextField
                    title="Enter Email OTP"
                    name="emailOtp"
                    type="number"
                  />
                </div>
                {}
                <div className="mt-6 mb-6 border relative">
                  <div className="absolute left-0 right-0 text-center -top-2.5">
                    <span className="bg-white p-2">OR</span>
                  </div>
                </div>
                <div style={{ marginTop: 30 }}>
                  <CustomTextField
                    title="Enter Mobile OTP"
                    name="mobileOtp"
                    type="number"
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className="uppercase w-full shadow-lg hover:shadow-xl rounded font-semibold"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Validate OTP
                  </Button>
                </div>
                <div style={{ marginTop: 30 }}>
                  <CustomFormFooter
                    linkHelpText="Didn't receive OTP?"
                    linkText="Resend OTP"
                    clickAction={handleResendOtpClick}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <Modal
            title="Authentication "
            visible={isResendOpen}
            maskClosable={false}
            footer={[
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    setIsResendOpen(false);
                  }}
                >
                  Okay
                </Button>
              </div>,
            ]}
          >
            <p>New OTPs sent to email and mobile successfully.</p>
          </Modal>
        </>
      )}
    </AuthLayout>
  );
}
export default withRouter(Otp);
