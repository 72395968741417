import React from "react";
import { Helmet } from "react-helmet";

export default function AuthLayout(props) {
  return (
    <div className=" font-Roboto">
      <Helmet>
        <title>{`Forcesight | ${props.page}`}</title>
      </Helmet>

      <div className="pb-2 pt-5 min-h-screen h-full flex justify-center items-center loginLayout">
        <div className="p-10 shadow-2xl rounded max-w-md bg-gray-100 w-full">
          {props.children}
        </div>
      </div>
    </div>
  );
}
