import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pie } from "@ant-design/charts";
import { Skeleton } from "antd";

export const ProfitPieChart = (props) => {
  const data = [
    {
      type: "NegativeProfitMargin",
      name: "< 0%",
      value: props?.data?.NegativeProfitMargin[0]?.count || 0,
      profit: props?.data?.NegativeProfitMargin[0]?.profit || 0,
    },
    {
      type: "ProfitMargin(0 to 5%)",
      name: "0 to 5%",
      value: props?.data?.["ProfitMargin(0 to 5%)"][0]?.count || 0,
      profit: props?.data?.["ProfitMargin(0 to 5%)"][0]?.profit || 0,
    },
    {
      type: "ProfitMargin(5 to 10%)",
      name: "5 to 10%",
      value: props?.data?.["ProfitMargin(5 to 10%)"][0]?.count || 0,
      profit: props?.data?.["ProfitMargin(5 to 10%)"][0]?.profit || 0,
    },
    {
      type: "ProfitMargin(10 to 15%)",
      name: "10 to 15%",

      value: props?.data?.["ProfitMargin(10 to 15%)"][0]?.count || 0,
      profit: props?.data?.["ProfitMargin(10 to 15%)"][0]?.profit || 0,
    },
    {
      type: "ProfitMargin(15 to 30%)",
      name: "15 to 30%",
      value: props?.data?.["ProfitMargin(15 to 30%)"][0]?.count || 0,
      profit: props?.data?.["ProfitMargin(15 to 30%)"][0]?.profit || 0,
    },
    {
      type: "ProfitMargin(30%+)",
      name: "> 30%",
      value: props?.data?.["ProfitMargin(30%+)"][0]?.count || 0,
      profit: props?.data?.["ProfitMargin(30%+)"][0]?.profit || 0,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "name",
    color: ["#fa9393","#588BF7", '#9F7AEA', '#ED8936',"#f584f1",'#38B2AC'],
    radius: 0.75,
    label: {
      type: "inner",
      labelHeight: 28,
      content: "{value}",
    },
    tooltip: {
      fields: ["value", "profit"],
      showTitle: true,
      title: "type",
    },

    meta: {
      profit: {
        formatter: (v) =>
          `${v.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })}`,
        alias: "Amount",
      },
      value: { alias: "Count" },
    },

    // legend: {
    //   layout: "vertical",
    //   position: "top",
    // },

    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  return props.isLoading ? (
    <Skeleton />
  ) : (
    <Pie
      {...config}
      // style={{height:"250px"}}
      onReady={(plot) => {
        plot.on("plot:click", (evt) => {
          const { x, y } = evt;
          const { xField } = plot.options;
          const tooltipData = plot.chart.getTooltipItems({ x, y });
          props.setCardValue(tooltipData[0]?.title, props.fullfillmentType);
        });
      }}
    />
  );
};
