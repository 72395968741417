import React from "react";
import { Waterfall } from "@ant-design/charts";
import { Skeleton } from "antd";

const ProfitWaterfall = (props) => {
  const data = [
    {
      type: "Invoice",
      total: "Invoice Price",
      amount: props?.data?.Total_invoice,
    },
    {
      type: "COGS",
      total: "Product Cost",
      amount: props?.data?.Total_cost_per,
    },
    {
      type: "Others",
      total: "Other Fees",
      amount: props?.data?.Total_market + props?.data?.Total_shipping + props?.data?.Total_ads +props?.data?.GSTImpact,
    },
    // {
    //   type: "SH",
    //   total: "Shipping Fees",
    //   amount: props?.data?.Total_shipping,
    // },
    // {
    //   type: "AD",
    //   total: "Ads",
    //   amount: props?.data?.Total_ads,
    // },

    {
      type: "Return",
      total: "Return Fees",
      amount: props?.data?.Total_return,
    },

    // {
    //   type: "GI",
    //   total: "GST Impact",
    //   amount: props?.data?.GSTImpact,
    // },

  ];

  const config = {
    data: data,
    xField: "type",
    yField: "amount",
    columnWidthRatio: 0.5,
    risingFill: "#30bf78",
    fallingFill: " #f4664a",
    appendPadding: [1, 0, 0, 0],
    meta: {
      type: {
        alias: "type",
      },
      amount: {
        alias: "Amount",
        formatter: (v) =>
          `${v?.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          })}`,
      },
    },
    // minColumnWidth: 2,
    // maxColumnWidth: 5,

    label: false,
    // {
    //   style: {
    //     fontSize: 14,
    //     fill: "rgba(0,0,0,0.65)",
    //   },
    //   layout: [
    //     {
    //       type: "interval-adjust-position",
    //     },
    //   ],
    // },
    total: {
      label: "Profit",
      style: {
        fill: "#96a6a6",
      },
    },
    tooltip: {
      showTitle: true,
      title: "total",
    },

    // total: false,
  };
  return props.isLoading ? <Skeleton /> : <Waterfall {...config} />;
};

export default ProfitWaterfall;
