import React from "react";
import { Table, Tooltip } from "antd";

const ProfitabilityTable = (props) => {
  const columns = [
    {
      title: () => <div className="text-sm font-medium font-sans">SKU</div>,
      dataIndex: ["SKU"],
      key: "SKU",
      width: 80,
      render: (text, record) => (
        <Tooltip title={record?.title}>
          <p>{text?.replace(/(.{8})..+/, "$1…")}</p>
        </Tooltip>
      ),
    },
    {
      title: () => <div className="text-sm font-medium font-sans">QTY</div>,
      dataIndex: "QuantityShippedSum",
      key: "QuantityShippedSum",
      width: 20,
    },
    {
      title: () => <div className="text-sm font-medium font-sans">Profit</div>,
      dataIndex: "Profit",
      key: "Profit",
      width: 60,
      render: (record, rowIndex) => (
        <div>
          {record
            ? record.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: "INR",
              })
            : "-"}
        </div>
      ),
    },
    {
      title: () => <div className="text-sm font-medium font-sans">Margin</div>,
      dataIndex: "ProfitMargin",
      key: "ProfitMargin",
      width: 30,
      render: (record, rowIndex) => (
        <div>
          {record
            ? record.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                currency: "INR",
              }) + "%"
            : "-"}
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      title={() => <div className="text-xs font-semibold">{props.title}</div>}
      dataSource={props?.data}
      loading={props.isLoading}
      pagination={false}
      bordered
      style={{ border: "1px solid black", borderRadius: "5px", padding: "1px" }}
      rowClassName={"text-xs"}
      size="small"
    />
  );
};

export default ProfitabilityTable;
