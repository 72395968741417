import { Box, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Anchor, Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const { Link } = Anchor;

const useStyles = makeStyles((theme) => ({
  // ctaContainer: {
  //   marginTop: 16,
  //   display: "flex"
  // },
  ctaLabelText: {
    fontSize: 18,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    textAlign: "left",
    marginTop: 32,
  },
  ctaValueText: {
    fontSize: 24,
    fontWeight: 600,
    color: "#171725",
    fontFamily: "inter",
    textAlign: "left",
  },
  ctaTitleText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
  },
  // leftTableCard: {
  // marginRight: 20,
  // width: "50%",
  // boxShadow: "0px 9px 30px 0px rgba(24, 24, 24, 0.03)"
  // },
  // rightTableCard: {
  //   width: "50%",
  //   boxShadow: "0px 9px 30px 0px rgba(24, 24, 24, 0.03)"
  // },
  rowDivider: {
    borderTop: "1px solid rgba(223, 225, 230, 0.5)",
  },
  columnPointer: {
    cursor: "pointer",
  },
}));

function CallToActionInfo(props) {
  let history = useHistory();

  const classes = useStyles();

  const dataArr = [
    {
      id: "profit",
      title: "Number of loss making SKUs",
      call_to_action_params: "NumberOfLossMakingSkus",
      value: props.profitCardValue?.noOfLossMakingSkus?.toLocaleString(),
    },
    {
      id: "profit",
      title: "Number of profit making SKUs",
      call_to_action_params: "NumberOfProfitMakingSkus",
      value: props.profitCardValue?.noOfProfitMakingSkus?.toLocaleString(),
    },
    {
      id: "sellerReviews",
      title: "Number of negative seller reviews",
      call_to_action_params: "Seller",
      value: props.reviewValue?.sellerNegativeReviews?.toLocaleString(),
    },
    {
      id: "productReviews",
      title: "Number of negative product reviews",
      call_to_action_params: "Product",
      value: props.reviewValue?.productNegativeReviews?.toLocaleString(),
    },
    {
      id: "overstock",
      title: "No. of overstock products (> 60 days DIO)",
      call_to_action_params: "OverStock60Days",
      value: props.inventoryValue?.overstock?.toLocaleString(),
    },
    {
      id: "outOfStock",
      title: "No. of products out of stock",
      call_to_action_params: "OutOfStock",
      value: props.inventoryValue?.outOfStock?.toLocaleString(),
    },
    {
      id: "overstock",
      title: "No. of products out of stock in 7 days",
      call_to_action_params: "OutOfStock7Days",
      value: props.inventoryValue?.outOfStock7Days?.toLocaleString(),
    },
    {
      id: "",
      title: "",
      value: "",
    },
  ];

  const showExpandedModal = (record) => {
    if (record.id === "profit") {
      history.push({ pathname: "/profitability", state: { record} })
    }
    props.showCtaModal(record);
  };

  const getDataColumn = (isLoading, data, value) => {
    return (
      <div className={`${isLoading ? "cursor-not-allowed" : ""}`}>
        <div
          className={`relative ${props.isLoading ? " pointer-events-none" : ""
            }`}
        >
          <div
            className={classes.columnPointer}
            onClick={() => showExpandedModal(data)}
          >
            <Typography className={classes.ctaTitleText}>
              {data.title}
            </Typography>
            {isLoading ? (
              <div className="item-left">
                <div className="item-title" />
              </div>
            ) : data.value === undefined || data.value === null ? (
              <Typography className={classes.ctaValueText}>{"-"}</Typography>
            ) : (
              <Typography className={classes.ctaValueText}>
                {data.value}
              </Typography>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getDataRow = (isLoading, data1, data2) => {
    return (
      <Row>
        <Col span={9} style={{ padding: 24, marginLeft: 18 }}>
          {getDataColumn(isLoading, data1)}
        </Col>
        <Col span={3} />
        <Col span={9} style={{ padding: 24 }}>
          {getDataColumn(isLoading, data2)}
        </Col>
        <Col span={3} />
      </Row>
    );
  };

  const getDataTable = (isLoading, data1, data2, data3, data4, className) => {
    return (
      <Box
        border={1}
        borderRadius={5}
        borderColor="rgba(223, 225, 230, 1)"
        color="red"
        className={className}
      >
        <Card elevation={0}>
          {getDataRow(isLoading, data1, data2)}
          <div className={classes.rowDivider} />
          {getDataRow(isLoading, data3, data4)}
        </Card>
      </Box>
    );
  };

  return (
    <>
      <div>
        <Typography className={classes.ctaLabelText}>
          Call to action
        </Typography>
      </div>
      <Box className="flex mt-4 flex-nowrap tablet:flex-wrap gap-5 tablet:gap-0">
        <div className="w-2/4 tablet:w-full">
          {getDataTable(
            props.isLoading,
            dataArr[0],
            dataArr[1],
            dataArr[2],
            dataArr[3]
          )}
        </div>
        <div className="w-2/4 tablet:w-full pt-0 tablet:pt-2.5">
          {getDataTable(
            props.isLoading,
            dataArr[4],
            dataArr[5],
            dataArr[6],
            dataArr[7]
          )}
        </div>
      </Box>
    </>
  );
}

export default CallToActionInfo;
