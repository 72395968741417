import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const StyledButton = withStyles({
	root: {
		fontSize: 14,
		fontWeight: 500,
		fontFamily: "Inter"
	},
	outlined: {
		fontFamily: "Inter",
		background: "#57D9A3",
		border: "1px solid #4ECD98",
		color: "#FFFFFF",
		"&:hover": {
			background: "#57D9A3",
			border: "1px solid #4ECD98",
			color: "#FFFFFF",
		},
		"&:focus": {
			outlined: "none",
		}
	},
	label: {
		textTransform: "capitalize"
	}
})(Button);

const DangerButton = withStyles({
	root: {
		fontSize: 14,
		fontWeight: 500,
		fontFamily: "Inter"
	},
	outlined: {
		fontFamily: "Inter",
		background: "#FF7452",
		border: "1px solid #FF7452",
		color: "#FFFFFF",
		"&:hover": {
			background: "#FF7452",
			border: "1px solid #FF7452",
			color: "#FFFFFF",
		},
		"&:focus": {
			outlined: "none",
		}
	},
	label: {
		textTransform: "capitalize"
	}
})(Button);

const PrimaryButton = withStyles({
	root: {
		fontSize: 14,
		fontWeight: 500,
		fontFamily: "Inter"
	},
	outlined: {
		fontFamily: "Inter",
		background: "#0065FF",
		border: "1px solid #0065FF",
		color: "#FFFFFF",
		"&:hover": {
			background: "#0065FF",
			border: "1px solid #0065FF",
			color: "#FFFFFF",
		},
		"&:focus": {
			outlined: "none",
		}
	},
	label: {
		textTransform: "capitalize"
	}
})(Button);

export { DangerButton, PrimaryButton };

export default StyledButton;
