import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Menu, message, Switch } from "antd";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import ApiWrapper from "../../../utils/ApiWrapper";
import DeleteCampaign from "./DeleteCampaign";

const CampaignCard = (props) => {
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const changeStatus = (checked) => {
    setLoading(true);
    ApiWrapper("getCampaignDetails", {
      campaign_id: props.data.campaign_id,
    }).then(
      (response) => {
        let requestBody = {
          name: response.data.name,
          mode: response.data.name,
          campaign_status: checked ? "on" : "off",
          skus: response.ActiveInventory.map((item) => item.sku),
          campaign_id: response.data.campaign_id,
          location: response.data.location,
          customer_type: response.data.customer_type,
          feedback: response.data.feedback,
          start_date: response.data.start_date,
          end_date: response.data.end_date,
        };
        ApiWrapper("createCampaign", requestBody).then(
          (response) => {
            message.success("Campaign successfully updated");
            props.refetchList();
            setLoading(false);
          },
          (error) => {
            // message.error(
            //   "We encountered an issue. Please log out and log back in. "
            // );
            setLoading(false);
          }
        );
      },
      (error) => {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
        setLoading(false);
      }
    );
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          history.push(`/edit-campaigns/${props.data.campaign_id}`)
        }
      >
        Edit
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setOpen(true)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className="border rounded">
        <div className="p-4">
          <div className="flex items-center justify-between">
            {props.loading ? (
              <div className="flex-1 h-6 bg-gray-200 animate-pulse" />
            ) : (
              <>
                <span className="text-base text-ErieBlack">
                  {props.data.name}
                </span>
                <Dropdown overlay={dropdownMenu}>
                  <Button
                    className="flex items-center justify-center bg-button"
                    icon={<EllipsisOutlined />}
                  />
                </Dropdown>
              </>
            )}
          </div>
          <div className="flex items-center mt-2">
            {props.loading ? (
              <div className="h-6 w-1/2 bg-gray-200 animate-pulse" />
            ) : (
              <>
                <Switch
                  loading={loading}
                  defaultChecked={props.data.campaign_status === "on"}
                  checked={props.data.campaign_status === "on"}
                  onChange={changeStatus}
                />
                <div className="text-sm ml-2">
                  {props.data.campaign_status === "on" ? "Active" : "Paused"}
                </div>
              </>
            )}
          </div>
          <div className="flex items-center mt-3">
            {props.loading ? (
              <div className="h-6 w-1/2 bg-gray-200 animate-pulse" />
            ) : (
              <>
                <span className="text-base text-DimGray">{`Created: `}</span>
                <span className="text-base text-ErieBlack">{` ${moment(
                  props.data.created_at,
                  "x"
                ).format("DD-MMM-YYYY")}`}</span>
              </>
            )}
          </div>
          {props.data.last_sent && (
            <div className="flex items-center mt-1">
              {props.loading ? (
                <div className="h-6 w-1/2 bg-gray-200 animate-pulse" />
              ) : (
                <>
                  <span className="text-base text-DimGray">{`Last Sent: `}</span>
                  <span className="text-base text-ErieBlack">{` ${moment(
                    props.data.last_sent,
                    "x"
                  ).format("DD-MMM-YYYY")}`}</span>
                </>
              )}
            </div>
          )}
        </div>
        <div className="border-t bg-GhostWhite p-4">
          <div className="flex items-center justify-between">

            <div className="flex items-center justify-start w-5/12">
              {props.loading ? (
                <div className="h-6 w-full bg-gray-200 animate-pulse" />
              ) : (
                <>
                  <span className="text-base text-DimGray">{`Sent: `}</span>
                  <span className="text-base text-ErieBlack">{` ${props.data.sent}`}</span>
                </>
              )}
            </div>
            <Divider type="vertical" />
            <div className="flex items-center justify-start w-5/12">
              {props.loading ? (
                <div className="h-6 w-full bg-gray-200 animate-pulse" />
              ) : (
                <>
                  <span className="text-base text-DimGray">{`Missing Products Count: `}</span>
                  <span className="text-base text-ErieBlack">{` ${props.data.missing_products_count}`}</span>
                </>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between mt-3">
            <div className="flex items-center justify-center w-5/12">
              {props.loading ? (
                <div className="h-6 w-1/2 bg-gray-200 animate-pulse" />
              ) : (
                <>
                  <span className="text-base text-DimGray">{`Ratings Recieved: `}</span>
                  <span className="text-base text-ErieBlack">{` ${props.data.ratings_received}`}</span>
                </>
              )}
            </div>
            <Divider type="vertical" />
            <div className="flex items-center justify-start w-5/12">
              {props.loading ? (
                <div className="h-6 w-1/2 bg-gray-200 animate-pulse" />
              ) : (
                <>
                  <span className="text-base text-DimGray">{`Reviews Recieved: `}</span>
                  <span className="text-base text-ErieBlack">{` ${props.data.reviews_received}`}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {open && (
        <DeleteCampaign
          campaign_id={props.data.campaign_id}
          open={open}
          setOpen={setOpen}
          refetchList={props.refetchList}
        />
      )}
    </React.Fragment>
  );
};

export default CampaignCard;
