const moment = require("moment");
export const filterFunctions = {
  "=": (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }
    return Number(rowValue) === Number(filterItem.value);
  },
  "!=": (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    return Number(rowValue) !== Number(filterItem.value);
  },
  ">": (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    return Number(rowValue) > Number(filterItem.value);
  },
  ">=": (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    return Number(rowValue) >= Number(filterItem.value);
  },
  "<": (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    return Number(rowValue) < Number(filterItem.value);
  },

  "<=": (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    return Number(rowValue) <= Number(filterItem.value);
  },

  is: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    // const time = new Date(filterItem.value).getTime();

    // if (!rowValue) {
    //   return false;
    // }
    // if (rowValue instanceof Date) {
    //   return new Date(rowValue).getTime() === time;
    // }
    // return new Date(rowValue.toString()).getTime() === time;
    let filterDate = moment(moment(filterItem.value).format("YYYY-MM-DD"));
    let rowDate = moment(moment(rowValue).format("YYYY-MM-DD"));
    return rowDate.isSame(filterDate, "day");
  },
  not: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    // const time = new Date(filterItem.value).getTime();

    // if (!rowValue) {
    //   return false;
    // }
    // if (rowValue instanceof Date) {
    //   return new Date(rowValue).getTime() !== time;
    // }
    // return new Date(rowValue.toString()).getTime() !== time;
    let filterDate = moment(moment(filterItem.value).format("YYYY-MM-DD"));
    let rowDate = moment(moment(rowValue).format("YYYY-MM-DD"));
    return !rowDate.isSame(filterDate, "day");
  },

  after: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    // const time = new Date(filterItem.value).getTime();

    // if (!rowValue) {
    //   return false;
    // }
    // if (rowValue instanceof Date) {
    //   return new Date(rowValue).getTime() > time;
    // }
    // return new Date(rowValue.toString()).getTime() > time;
    let filterDate = moment(moment(filterItem.value).format("YYYY-MM-DD"));
    let rowDate = moment(moment(rowValue).format("YYYY-MM-DD"));
    return rowDate.isAfter(filterDate);
  },
  onOrAfter: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    // const time = new Date(filterItem.value).getTime();

    // if (!rowValue) {
    //   return false;
    // }
    // if (rowValue instanceof Date) {
    //   return new Date(rowValue).getTime() >= time;
    // }
    // return new Date(rowValue.toString()).getTime() >= time;
    let filterDate = moment(moment(filterItem.value).format("YYYY-MM-DD"));
    let rowDate = moment(moment(rowValue).format("YYYY-MM-DD"));
    return rowDate.isSameOrAfter(filterDate);
  },

  before: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    // const time = new Date(filterItem.value).getTime();

    // if (!rowValue) {
    //   return false;
    // }
    // if (rowValue instanceof Date) {
    //   return new Date(rowValue).getTime() < time;
    // }
    // return new Date(rowValue.toString()).getTime() < time;
    let filterDate = moment(moment(filterItem.value).format("YYYY-MM-DD"));
    let rowDate = moment(moment(rowValue).format("YYYY-MM-DD"));
    return rowDate.isBefore(filterDate);
  },

  onOrBefore: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    // const time = new Date(filterItem.value).getTime();

    // if (!rowValue) {
    //   return false;
    // }
    // if (rowValue instanceof Date) {
    //   return new Date(rowValue).getTime() <= time;
    // }
    // return new Date(rowValue.toString()).getTime() <= time;
    let filterDate = moment(moment(filterItem.value).format("YYYY-MM-DD"));
    let rowDate = moment(moment(rowValue).format("YYYY-MM-DD"));
    return rowDate.isSameOrBefore(filterDate);
  },
  contains: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }
    const filterRegex = new RegExp(filterItem.value, "i");
    return filterRegex.test((rowValue || "").toString());
  },
  equals: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }
    // alert()

    return (
      (filterItem.value || "").localeCompare(
        (rowValue || "").toString(),
        undefined,
        {
          sensitivity: "base",
        }
      ) === 0
    );
  },
  startsWith: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    const filterRegex = new RegExp(`^${filterItem.value}.*$`, "i");

    return filterRegex.test((rowValue || "").toString());
  },

  endsWith: (filterItem, rowValue) => {
    // if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
    //   return null;
    // }

    const filterRegex = new RegExp(`.*${filterItem.value}$`, "i");

    return filterRegex.test((rowValue || "").toString());
  },
};
