import axios from "axios";
import queryString from "query-string";
import { ApiTable } from "./ApiTable";

// (name, jsonBody, authToken, onMethodSuccess)

export const PostValues = async (
  name,
  jsonBody,
  authToken,
  onMethodSuccess,
  config
) => {
  const apiConfig = ApiTable[name];

  let url = `${apiConfig.baseUrl}${apiConfig.endpoint}`;
  if (config && config.queryParam) {
    url = `${url}${
      queryString ? `?${queryString.stringify(config.queryParam)}` : ""
    }`;
  } else if (config && config.pathParam.length) {
    config.pathParam.map((param) => {
      url = `${url}/${param}`;
    });
  }
  try {
    const { data } = await axios.post(url, jsonBody);
    return data;
  } catch (error) {
    throw error;
  }
};

// export const PostValues = async (path, data, config) => {
//   const url = `${API_ROOT}${path}`;
//   try {
//     const response = await axios.post(url, data, config);
//     return { response, data: response.data };
//   } catch (error) {
//     console.log(data, config);
//     console.log(error.message, error.request, error.response);
//     throw error;
//   }
// };

// export const PutValues = async (path, data, config) => {
//   const url = `${API_ROOT}${path}`;
//   try {
//     const response = await axios.put(url, data, config);
//     console.log(response);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };
