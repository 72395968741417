import React, { useContext, useEffect, useRef, useState } from "react";
import { Container } from "@material-ui/core";
import { PostValues } from "../utils/api-client";
import ApiWrapper from "../utils/ApiWrapper";
import { Form, Input, Switch } from "antd";
import AntTable from "../components/AntTable/index.js";
import { compact } from "lodash";
import { EditOutlined } from "@ant-design/icons";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input
                    className="heightInitial text-left"
                    ref={inputRef}
                    onPressEnter={save}
                    onBlur={save}
                />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
            >
                {children}
                <EditOutlined size={30} onClick={toggleEdit} />
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default function PinCode() {
    const [form] = Form.useForm();

    let initialValues = {
        pincode: "",
    };
    const RefColumns = [
        {
            title: "Sno",
            align: "center",
            field: "fulfillment-center-id",
            render: (record, rowIndex) => rowIndex,
        },
        {
            title: "Fulfillment Center Id",
            field: "fulfillment-center-id",
            hidden: false,
        },
        {
            title: "Pincode",
            align: "center",
            isFilterable: true,
            isTotal: true,
            field: "pincode",
            type: "number",
            editable: true,
            sorter: (a, b) => {
                a = (a || {}).pincode || 0;
                b = (b || {}).pincode || 0;
                return a - b;
            },
            sortDirections: ["descend", "ascend", "descend"],
            onCell: (record) => ({
                record,
                editable: true,
                dataIndex: "pincode",
                title: "pincode",
                align: "center",
                handleSave: async (data) => {
                    let requestJsonBody = {
                        "fc_list": [{
                            "fulfillment-center-id": data['fulfillment-center-id'],
                            "pincode": data.pincode
                        }
                        ]
                    }
                    ApiWrapper("updatePincodeApi", requestJsonBody).then(
                        function (response) {
                            if (response.status) {
                                ApiGetList();
                            }
                        },
                        function (error) {

                        }
                    );
                },
            }),
        },

    ];
    const [isLoading, setIsLoading] = useState(true);
    const [listOfCenterId, setListOfCenterId] = useState([]);
    const [amazonIxdToggle, setAmazonIxdToggle] = useState();

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const mergedColumns = compact(RefColumns).map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col
        };
    });
    const pincodeGetApi = async (requestData) => {
        let response = await PostValues("pincodeApi", requestData);
        return response;
    };
    const ToggleAmazonIxd = async (requestData) => {
        let response = await PostValues("amazonIxdApi", requestData);
        return response;
    };
    React.useEffect(() => {
        ApiGetList()
        AmazonIxdToggle({})
    }, [])

    const ApiGetList = async () => {
        setIsLoading(true);
        let requestData = {
            "channel": "Amazon-India",
            "type": "All"
        }
        let data = await pincodeGetApi(requestData);
        if (data.Items) {
            // data.Items.map((dataOf) => {
            //     return dataOf['pinCode'] = ''
            // })
            setListOfCenterId(data.Items)
            setIsLoading(false)
        }
    }
    const AmazonIxdToggle = async (requestData) => {
        let data = await ToggleAmazonIxd(requestData);
        if(data.status){
            setAmazonIxdToggle(data.ixd_status);
        }
    }
    const onChange = (checked) => {
        let requestData = { 
            toggle: checked
        }
        AmazonIxdToggle(requestData)
      };

    return (
      <div style={{ fontFamily: "inter" }}>
        <Container>
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div className="mb-2 font-semibold">
              <span>Amazon-India IXD : </span>
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={amazonIxdToggle}
                onChange={onChange}
              />
            </div>
            <Form form={form} component={false} initialValues={initialValues}>
              <AntTable
                columns={mergedColumns}
                dataSource={listOfCenterId}
                components={components}
                stickyHeader={{
                  offsetHeader: 65,
                }}
                rowKey={(row, rowIndex) => rowIndex}
                isLoading={isLoading}
                hideSummary={true}
              />
            </Form>
          </div>
        </Container>
      </div>
    );
}