import { Card } from "antd";
import React from "react";
import { Pie } from "@ant-design/charts";

function Leaks(props) {
  const setTabValue = (val) => {
    if (props.isLoading) return;
    else props.filterTabvalue(val);
  };
  var data = [
    {
      type: "a",
      value: props.data[0]?.amazon[0].commission_problem_order,
    },
    {
      type: "b",
      value: props.data[0]?.amazon[0].fbaweightbasefee_problem_order,
    },
  ];
  var config = {
    appendPadding: 10,
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: function content(_ref) {
        return "".concat(_ref.value, "%");
      },
      style: {
        fontSize: 14,
        textAlign: "middle",
      },
    },
    interactions: [{ type: "element-active" }],
  };
  return (
    <>
      <div className="mt-5">
        <div
          className={`${
            props.isLoading ? "cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <div className="grid grid-cols-4 gap-8">
            {[
              {
                title: "Commission Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.commission_problem_order +
                  props.data[0]?.flipkart[0]?.commission_problem_order,
                estimate:
                  props.data[0]?.amazon[0]?.commission_fee_estimated_variance +
                  props.data[0]?.flipkart[0]?.commission_fee_estimated_variance,
                tabData: "commission",
                bgColor: "#E6FCFF",
              },
              {
                title: "FBA Weight Base Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.fbaweightbasefee_problem_order +
                  props.data[0]?.flipkart[0]?.fbaweightbasefee_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.fbaweightbasefee_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.fbaweightbasefee_fee_estimated_variance,
                tabData: "fbaweightbasefee",
                bgColor: "rgb(234, 230, 255)",
              },
              {
                title: "Technology Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.technologyfee_problem_order +
                  props.data[0]?.flipkart[0]?.technologyfee_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.technologyfee_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.technologyfee_fee_estimated_variance,
                tabData: "technologyfee",
                bgColor: "rgb(227, 252, 239)",
              },
              {
                title: "Fixed Close Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.fixedclosefee_problem_order +
                  props.data[0]?.flipkart[0]?.fixedclosefee_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.fixedclosefee_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.fixedclosefee_fee_estimated_variance,
                tabData: "fixedclosefee",
                bgColor: "rgb(255, 250, 230)",
              },
              {
                title: "UnSettled Payment",
                ordersCount:
                  props.data[0]?.amazon[0]?.unsettled_orders +
                  props.data[0]?.flipkart[0]?.unsettled_orders,
                estimate:
                  props.data[0]?.amazon[0]?.unsettled_amount +
                  props.data[0]?.flipkart[0]?.unsettled_amount,
                tabData: "unsettledPayment",
                bgColor: "#E6FCFF",
              },
              // {
              //   title: "Category Missing",
              //   ordersCount: props.data[0]?.amazon[5]?.commission_pblm  + props.data[0]?.flipkart[5]?.commission_pblm,
              //   estimate: 0,
              //   tabData: "Commission",
              //   isMissingOrder: true,
              //   bgColor: "#E6FCFF",
              // },
              // {
              //   title: "FBA Missing",
              //   ordersCount: props.data[0]?.amazon[4]?.FBA_pblm + props.data[0]?.flipkart[4]?.FBA_pblm,
              //   estimate: 0,
              //   tabData: "Commission",
              //   isMissingOrder: true,
              //   bgColor: "rgb(234, 230, 255)",
              // },
            ].map((fee, feeIndex) => {
              return (
                <Card
                  bgcolor="white"
                  className="relative rounded-lg tablet:w-48 mobile:w-45"
                  onClick={() =>
                    fee.tabData !== "unsettledPayment"
                      ? setTabValue(fee.tabData)
                      : ""
                  }
                  key={`Fee-${feeIndex}`}
                >
                  <div className="flex justify-center">
                    <div
                      className="absolute rounded-lg"
                      style={{
                        background: fee.bgColor,
                        padding: "2px 16px",
                        top: "-11px",
                      }}
                    >
                      {fee.title}
                    </div>
                  </div>

                  {props.isLoading ? (
                    <div className="item ">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <p className="text-center mt-5 font-bold font-32">
                      {fee.ordersCount || "0"}
                    </p>
                  )}

                  <p className="text-center text-base font-medium">
                    No. of problem orders
                  </p>
                  {props.isLoading ? (
                    <div className="item">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <p className="text-center text-2xl font-semibold mt-4">
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(fee.estimate || "0")}
                    </p>
                  )}

                  <p className="text-center text-base font-medium mb-7">
                    {fee.isMissingOrder
                      ? "No.of orders with missing category"
                      : "Estimate Variance"}
                  </p>
                </Card>
              );
            })}
          </div>
        </div>
        {/* <div className="grid grid-cols-4 gap-8">
        <Pie {...config} />
        <Pie {...config} />
        </div> */}
      </div>
    </>
  );
}

export default Leaks;
