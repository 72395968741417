import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import ReactTooltip from "react-tooltip";
import { Progress, Tooltip } from "antd";
import { Card, Row, Table, Spin } from "antd";

import andamannicobar from "../../assets/topojsons/states/andamannicobarislands.json";
import andhrapradesh from "../../assets/topojsons/states/andhrapradesh.json";
import arunachalpradesh from "../../assets/topojsons/states/arunachalpradesh.json";
import assam from "../../assets/topojsons/states/assam.json";
import bihar from "../../assets/topojsons/states/bihar.json";
import chhattisgarh from "../../assets/topojsons/states/chhattisgarh.json";
import delhi from "../../assets/topojsons/states/delhi.json";
import goa from "../../assets/topojsons/states/goa.json";
import gujarat from "../../assets/topojsons/states/gujarat.json";
import haryana from "../../assets/topojsons/states/haryana.json";
import himachalpradesh from "../../assets/topojsons/states/himachalpradesh.json";
import jammukashmir from "../../assets/topojsons/states/jammukashmir.json";
import jharkhand from "../../assets/topojsons/states/jharkhand.json";
import karnataka from "../../assets/topojsons/states/karnataka.json";
import kerala from "../../assets/topojsons/states/kerala.json";
import lakshadweep from "../../assets/topojsons/states/lakshadweep.json";
import madhyapradesh from "../../assets/topojsons/states/madhyapradesh.json";
import maharashtra from "../../assets/topojsons/states/maharashtra.json";
import manipur from "../../assets/topojsons/states/manipur.json";
import meghalaya from "../../assets/topojsons/states/meghalaya.json";
import mizoram from "../../assets/topojsons/states/mizoram.json";
import nagaland from "../../assets/topojsons/states/nagaland.json";
import odisha from "../../assets/topojsons/states/odisha.json";
import punjab from "../../assets/topojsons/states/punjab.json";
import rajasthan from "../../assets/topojsons/states/rajasthan.json";
import sikkim from "../../assets/topojsons/states/sikkim.json";
import tamilnadu from "../../assets/topojsons/states/tamilnadu.json";
import telangana from "../../assets/topojsons/states/telangana.json";
import tripura from "../../assets/topojsons/states/tripura.json";
import uttarakhand from "../../assets/topojsons/states/uttarakhand.json";
import uttarpradesh from "../../assets/topojsons/states/uttarpradesh.json";
import westbengal from "../../assets/topojsons/states/westbengal.json";
import ladakh from "../../assets/topojsons/states/ladakh.json";
import dadranagarhaveli from "../../assets/topojsons/states/dadranagarhaveli.json";
import LinearGradient from "./LinearGradient.js";
import chadigarh from "../../assets/topojsons/states/chandigarh.json";

const COLOR_RANGE = [
  "#f7fbff",
  "#deebf7",
  "#c6dbef",
  "#9ecae1",
  "#6baed6",
  "#4292c6",
  "#2171b5",
  "#08519c",
  "#08306b",
];

const DEFAULT_COLOR = "#D6D6DA";

const geographyStyle = {
  default: {
    outline: "none",
  },
  hover: {
    fill: "red",
    transition: "all 250ms",
    outline: "none",
  },
  pressed: {
    outline: "none",
  },
};
const gradientData = {
  fromColor: COLOR_RANGE[0],
  toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
};
function StateChartPageTwo(props) {
  let geoURL;
  let zoomMap = 1;
  let centerMap = [80, 22];
  let scaleMap = 400;
  if (props.selectedState === "Andaman & Nicobar Islands") {
    geoURL = andamannicobar;
    scaleMap = 1000;
    centerMap = [93, 10];
  } else if (props.selectedState === "Andhra Pradesh") {
    geoURL = andhrapradesh;
    scaleMap = 800;
    centerMap = [80, 17];
  } else if (props.selectedState === "Arunachal Pradesh") {
    geoURL = arunachalpradesh;
    scaleMap = 1200;
    centerMap = [94.5, 28];
  } else if (props.selectedState === "Assam") {
    geoURL = assam;
    scaleMap = 1350;
    centerMap = [92.9, 26];
  } else if (props.selectedState === "Bihar") {
    geoURL = bihar;
    scaleMap = 1300;
    centerMap = [85.5, 26];
  } else if (
    props.selectedState === "Chattisgarh" ||
    props.selectedState === "Chhattisgarh"
  ) {
    geoURL = chhattisgarh;
    scaleMap = 1100;
    centerMap = [82, 21];
  } else if (props.selectedState === "Delhi") {
    geoURL = delhi;
    scaleMap = 11000;
    centerMap = [77.05, 28.6];
  } else if (props.selectedState === "Goa") {
    geoURL = goa;
    scaleMap = 6000;
    centerMap = [74, 15.25];
  } else if (props.selectedState === "Gujarat") {
    geoURL = gujarat;
    scaleMap = 1000;
    centerMap = [71.5, 22];
  } else if (props.selectedState === "Haryana") {
    geoURL = haryana;
    scaleMap = 1700;
    centerMap = [76, 29];
  } else if (props.selectedState === "Himachal Pradesh") {
    geoURL = himachalpradesh;
    scaleMap = 2000;
    centerMap = [77.4, 31.8];
  } else if (props.selectedState === "Jammu & Kashmir") {
    geoURL = jammukashmir;
    scaleMap = 1000;
    centerMap = [76.3, 35];
  } else if (props.selectedState === "Jharkhand") {
    geoURL = jharkhand;
    scaleMap = 1700;
    centerMap = [85.7, 23.6];
  } else if (props.selectedState === "Karnataka") {
    geoURL = karnataka;
    scaleMap = 1100;
    centerMap = [76.5, 15];
  } else if (props.selectedState === "Kerala") {
    geoURL = kerala;
    scaleMap = 1800;
    centerMap = [76, 10.5];
  } else if (props.selectedState === "Lakshadweep") {
    geoURL = lakshadweep;
    scaleMap = 2300;
    centerMap = [73, 11];
  } else if (props.selectedState === "Madhya Pradesh") {
    geoURL = madhyapradesh;
    scaleMap = 900;
    centerMap = [78.5, 24];
  } else if (props.selectedState === "Maharashtra") {
    geoURL = maharashtra;
    scaleMap = 1000;
    centerMap = [76.8, 19.3];
  } else if (props.selectedState === "Manipur") {
    geoURL = manipur;
    scaleMap = 3400;
    centerMap = [93.8, 24.7];
  } else if (props.selectedState === "Meghalaya") {
    geoURL = meghalaya;
    scaleMap = 2500;
    centerMap = [91.3, 25.4];
  } else if (props.selectedState === "Mizoram") {
    geoURL = mizoram;
    scaleMap = 2900;
    centerMap = [92.8, 23.25];
  } else if (props.selectedState === "Nagaland") {
    geoURL = nagaland;
    scaleMap = 4000;
    centerMap = [94.3, 26.1];
  } else if (props.selectedState === "Odisha") {
    geoURL = odisha;
    scaleMap = 1300;
    centerMap = [84.4, 20.25];
  } else if (props.selectedState === "Punjab") {
    geoURL = punjab;
    scaleMap = 2300;
    centerMap = [75.35, 31.1];
  } else if (props.selectedState === "Rajasthan") {
    geoURL = rajasthan;
    scaleMap = 900;
    centerMap = [74, 26.3];
  } else if (props.selectedState === "Sikkim") {
    geoURL = sikkim;
    scaleMap = 6000;
    centerMap = [88.45, 27.6];
  } else if (props.selectedState === "Tamil Nadu") {
    geoURL = tamilnadu;
    scaleMap = 1300;
    centerMap = [78.25, 10.8];
  } else if (props.selectedState === "Telangana") {
    geoURL = telangana;
    scaleMap = 1800;
    centerMap = [79.5, 17.9];
  } else if (props.selectedState === "Tripura") {
    geoURL = tripura;
    scaleMap = 4500;
    centerMap = [91.75, 23.75];
  } else if (props.selectedState === "Uttarakhand") {
    geoURL = uttarakhand;
    scaleMap = 2000;
    centerMap = [79.3, 30];
  } else if (props.selectedState === "Uttar Pradesh") {
    geoURL = uttarpradesh;
    scaleMap = 1000;
    centerMap = [80.8, 27];
  } else if (props.selectedState === "West Bengal") {
    geoURL = westbengal;
    scaleMap = 1200;
    centerMap = [87.7, 24.2];
  } else if (props.selectedState === "Ladakh") {
    geoURL = ladakh;
    scaleMap = 1000;
    centerMap = [76.3, 35];
  } else if (
    props.selectedState === "DADRA AND NAGAR HAVELI AND DAMAN AND DIU"
  ) {
    geoURL = dadranagarhaveli;
    scaleMap = 1000;
    centerMap = [76.8, 19.3];
  } else if (props.selectedState === "Chadigarh") {
    geoURL = chadigarh;
    scaleMap = 2300;
    centerMap = [75.35, 31.1];
  }
  const colorScale = scaleQuantile()
    .domain(
      Object.entries(props?.resData?.total_District).map(
        (d) => d?.[1]?.[0]?.Profit
      )
    )
    .range(COLOR_RANGE);
  const [contentDis, setContentDis] = React.useState("");
  const [contentD, setContentD] = React.useState("");
 
  const onMouseEnter = (geo, current = { count: "NA" }) => {
    if (current[1]) {
      return () => {
        setContentDis(geo.properties.district);
        setContentD(current[1][0]);
      };
    } else {
      return () => {
        setContentDis(geo.properties.district);
        setContentD("");
      };
    }
  };

  return (
    <>
      <ComposableMap
        width={200}
        height={130}
        data-tip=""
        projection="geoMercator"
      // projectionConfig={{ scale: 800 }}
      projectionConfig={{ scale: scaleMap }}
      >
        <ZoomableGroup zoom={zoomMap} center={centerMap}>
          {/* <ZoomableGroup zoom={zoomMap} center={centerMap}> */}
          <Geographies geography={geoURL}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const current = Object.entries(
                  props?.resData?.total_District
                ).find(
                  (s) =>
                    s[0].toUpperCase() === geo.properties.district.toUpperCase()
                );
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={onMouseEnter(geo, current)}
                    onMouseLeave={() => {
                      setContentDis("");
                    }}
                    onClick={() => {
                      const { district } = geo.properties.district;
                      //  props.setDistrictName(`${district}`);
                    }}
                    fill={
                      current?.[1]?.[0]?.Profit
                        ? colorScale(current?.[1]?.[0]?.Profit)
                        : DEFAULT_COLOR
                    }
                    style={geographyStyle}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <LinearGradient data={gradientData} />

      {/* ReactTooltip */}
      {contentDis ? (
        <ReactTooltip>
          <b style={{
            fontSize: "18px"

          }}> {contentDis}</b>
          
          {contentD.Profit ? (
            <>
              <br></br>
              Profit:  {contentD?.Profit?.toLocaleString(
                  "en-IN",
                  {
                    maximumFractionDigits: 0,
                    style: "currency",
                    currency: "INR",
                  }
                )}
            </>
          ) : null
          }
          {contentD.ProfitMargin ? (
            <>
              <br></br>
              Profit Margin:  {contentD?.ProfitMargin?.toLocaleString(
                  "en-IN",
                  {
                    maximumFractionDigits: 0,
                    //style: "currency",
                    currency: "INR",
                  }
                ) + "%"}
            </>
          ) : null
          }
        </ReactTooltip>
      ) : null
      }
    </>
  );
}

export default StateChartPageTwo;
