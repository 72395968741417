import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from "antd";
import Carousel from 'react-material-ui-carousel'
import ExportButton from "../Styles/ExportButton";

const ImageCarousel = props => {
	return (
		<Modal
			title={null}
			visible={props.open}
			onCancel={() => props.setOpen(false)}
			closable={false}
			width={950}
			footer={[
				<ExportButton
					variant="outlined"
					onClick={() => props.setOpen(false)}
				>
					Close
				</ExportButton>
			]}
		>
			<Carousel
				autoPlay={false}
				animation="slide"
				indicators={false}
				cycleNavigation={false}
				navButtonsAlwaysVisible={true}
			>
				{props.images.map((image, i) => (
					<img
						src={image}
						alt={`Review-${i + 1}`}
						key={i}
						style={{
							width: "916px",
							height: "456px",
						}}
					/>
				))}
			</Carousel>
		</Modal>
	);
};

ImageCarousel.defaultProps = {
	images: [],
	imageIndex: 0,
	open: false,
	setOpen: () => {},
}

ImageCarousel.propTypes = {
	images: PropTypes.arrayOf(PropTypes.string).isRequired,
	imageIndex: PropTypes.number,
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
};

export default ImageCarousel;
