import { Modal, Table, Skeleton, Spin, Button } from "antd";
import React, { useEffect, useState } from "react";
import { PostValues } from "../../../utils/api-client";
import ExportTableButton from "../../../components/AntTable/ExportTable";

export default function BuyBoxModal(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [modalValue, setmodalValue] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);

  const RefColumns = [
    {
      title: "Channel",
      align: "center",
      key: "channel",
      render: (text, record, index) =>
        isLoading ? (
          <Skeleton
            active
            size="small"
            key={record.index}
            title={true}
            paragraph={false}
          />
        ) : (
          <p>
            {record.channel === "Amazon-India" ? (
              <div className="amazon-logo-small" />
            ) : record.channel === "Flipkart" ||
              record.channel !== "Amazon-India" ? (
              <div className="flipkart-logo-small" />
            ) : (
              "-"
            )}
          </p>
        ),
    },
    {
      title: "SKU",
      align: "center",
      key: "sku",
      defaultSortOrder: "descend",
      render: (text, record, index) =>
        isLoading ? (
          <Skeleton
            active
            size="small"
            key={record.index}
            title={true}
            paragraph={false}
          />
        ) : (
          <p>{text.sku}</p>
        ),
      sorter: (a, b) => {
        a = (a.sku || "").toLowerCase();
        b = (b.sku || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "Product ID",
      align: "center",
      key: "product_id",
      render: (text, record, index) =>
        isLoading ? (
          <Skeleton
            active
            size="small"
            key={record.index}
            title={true}
            paragraph={false}
          />
        ) : (
          <p><Button type="link">
            <a
              href={
                text.channel === "Amazon-India"
                  ? `https://www.amazon.in/dp/${text.product_id}`
                  : `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${text.product_id}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {text.product_id || ""}
            </a>
            </Button>
          </p>
        ),
      sorter: (a, b) => {
        a = (a.product_id || "").toLowerCase();
        b = (b.product_id || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  ];
  useEffect(() => {
    let requestData = {
      channels: props.marketPlaces.map((mp) => mp.name),
      pagination: {
        pageNo: 0,
        pageSize: 10,
      },
      metric: "NoBuyBox",
    };
    modelApiRes(requestData);
  }, [props]);

  const onDataChange = (pagination) => {
    setCurrentPageNumber(pagination.current);
    let requestData = {
      channels: props.marketPlaces.map((mp) => mp.name),
      pagination: {
        pageNo: pagination.current - 1,
        pageSize: 10,
      },
      metric: "NoBuyBox",
    };
    modelApiRes(requestData);
  };

  // const exportData = () =>{
  //   let requestData = {
  //     channels: props.marketPlaces.map((mp) => mp.name),
  //     pagination: {
  //       pageNo: totalRecords,
  //       pageSize: totalRecords,
  //     },
  //     metric: 'NoBuyBox'
  //   };
  //   const data = await PostValues("buyboxInsightModal", requestData);
  //   if (data.Items) {
  //     return data.Items
  //   }
  // }
  const modelApiRes = async (requestData) => {
    setIsLoading(true);
    const data = await PostValues("buyboxInsightModal", requestData);
    if (data.Items) {
      setmodalValue(data.Items);
      setTotalRecords(data.pagination.count);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        className="overflow-hidden rounded-xl p-0 shadow-2xl m-2.5"
        title="Missing Buy Box"
        visible={props.isModalActive}
        footer={null}
        centered
        onCancel={props.modelHide}
        bodyStyle={{ padding: "10px" }}
        width={1450}
      >
        {" "}
        {/* <div className=" flex flex-row-reverse	">
          <ExportTableButton
            columns={RefColumns}
            fileName="Missing Buy Box"
            dataSource={modalValue}
          />
        </div> */}
        {modalValue && (
          <Table
            columns={RefColumns}
            dataSource={modalValue}
            size="medium"
            bordered
            pagination={{
              current: currentPageNumber,
              total: totalRecords,
            }}
            onChange={onDataChange}
          />
        )}
      </Modal>
    </>
  );
}
