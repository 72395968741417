import { Typography } from "antd";
import React from "react";
import ".././App.css";

function CustomFormFooter(props) {
  return (
    <>
      <div>
        {props.linkHelpText && props.linkText && (
          <Typography className={`text-sm font-semibold text-center text-Gray`}>
            {props.linkHelpText}&nbsp;
            <span
              className={`text-sm font-semibold text-center text-DarkCornFlowerBlue cursor-pointer`}
              onClick={props.clickAction}
            >
              {props.linkText}
            </span>
          </Typography>
        )}
        {/* <Typography
          className={`text-sm text-center font-medium mt-5 text-DarkCornFlowerBlue`}
        >
          Powered by
        </Typography>
        <div className="logo" /> */}
      </div>
    </>
  );
}

export default CustomFormFooter;
