import { Today } from "@material-ui/icons";
import { Spin, Tabs } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader.js";
import ExportButton from "../../components/Styles/ExportButton.js";
import { PostValues } from "../../utils/api-client";
import { DATE_FORMAT } from "../../utils/data.js";
import Leaks from "./Leaks.js";
import OrdersTable from "./OrdersTable";

const { TabPane } = Tabs;

export default function Payments({ history, location }) {
  const [actualPayments, setActualPayments] = useState([]);
  const [estimatedPayments, setEstimatedPayments] = useState([]);
  const [filteredActualPayments, setFilteredActualPayments] = useState([]);
  const [filteredEstimatedPayments, setFilteredEstimatedPayments] = useState([]);
  const [tabValue, setTabValue] = React.useState("1");
  const [searchText, setSearchText] = React.useState("");
  const [columns, setColumns] = useState([]);
  const [dashboardPayment, setDashboardPayment] = useState([]);
  const [paymentloader, paymentDashboardloader] = useState(false);
  const [marketPlaces, setMarketPlaces] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [allDataLoaded, setAllDataLoaded] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});
  const [leaksColumn, setLeaksColumn] = React.useState(null);
  const [settledOrdersTotal, setSettledOrdersTotal] = React.useState({});
  const [unSettledOrdersTotal, setUnSettledOrdersTotal] = React.useState({});
  const [isExportLoading, setIsExportLoading] = React.useState(false);

  const handleManualSyncClick = (event) => {};
  const onActualPaymentSuccess = (resData) => {
    return resData.map((row) => {
      let record = { ...row };
      if (record.InvoicePrice) {
        record.InvoicePrice.total = parseInt(row.InvoicePrice.total);
        record.InvoicePrice.Principal =
          row.InvoicePrice.breakup[0]?.Principal?.CurrencyAmount || "-";
        record.InvoicePrice.Tax =
          row.InvoicePrice.breakup[1]?.Tax?.CurrencyAmount || "-";
        record.InvoicePrice.ShippingCharge =
          row.InvoicePrice.breakup[2]?.ShippingCharge?.CurrencyAmount || "-";
        record.InvoicePrice.ShippingTax =
          row.InvoicePrice.breakup[3]?.ShippingTax?.CurrencyAmount || "-";
        record.InvoicePrice.Promotion1 =
          row.InvoicePrice.breakup[4]?.Promotion?.CurrencyAmount || "-";
        record.InvoicePrice.Promotion2 =
          row.InvoicePrice.breakup[5]?.Promotion?.CurrencyAmount || "-";
        record.InvoicePrice.Promotion3 =
          row.InvoicePrice.breakup[6]?.Promotion?.CurrencyAmount || "-";
        record.InvoicePrice.Promotion4 =
          row.InvoicePrice.breakup[7]?.Promotion?.CurrencyAmount || "-";
      }
      if (record.MarketplaceFees) {
        record.MarketplaceFees.FBAWeightBasedFee =
          row.MarketplaceFees?.breakup[0]?.FBAWeightBasedFee?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.TechnologyFee =
          row.MarketplaceFees?.breakup[1]?.TechnologyFee?.CurrencyAmount || "-";
        record.MarketplaceFees.Commission =
          row.MarketplaceFees?.breakup[2]?.Commission?.CurrencyAmount ||
          row.MarketplaceFees?.breakup[1]?.Commission?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.FixedClosingFee =
          row.MarketplaceFees?.breakup[3]?.FixedClosingFee?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.ShippingChargeback =
          row.MarketplaceFees?.breakup[4]?.ShippingChargeback?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.FixedFee =
          row.MarketplaceFees?.breakup[2]?.["Fixed Fee"]?.CurrencyAmount || "-";
        record.MarketplaceFees.CollectionFee =
          row.MarketplaceFees?.breakup[1]?.["Collection Fee"]?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.Taxes =
          row.MarketplaceFees?.breakup[0]?.Taxes?.CurrencyAmount || "-";
      }
      if (record.TCS) {
        record.TCS.TCS_IGST =
          row.TCS?.breakup[0]?.["TCS-IGST"]?.CurrencyAmount || "-";
      }
      record.marketPlace = row.channel;
      record.OrderDate =
        row.OrderDate && row.OrderDate !== "-"
          ? moment(new Date(row.OrderDate)).format(DATE_FORMAT)
          : "-";
      record.SettledDate =
        row.SettledDate && row.SettledDate !== "-"
          ? moment(new Date(row.SettledDate)).format(DATE_FORMAT)
          : "-";
      record.OrderDetailStatus = row.ReturnDetails?.OrderStatus
        ? row.ReturnDetails?.OrderStatus
        : row.OrderStatus || "";
      return record;
    });
  };

  const onEstimatedPaymentSuccess = (resData, status) => {
    return resData.map((row) => {
      let record = { ...row };
      record.FSOrderStatus = row.order_status
      record.invoice = record?.invoice;
      record.Marketplace = record?.Marketplace;
      record.tcs = record?.tcs;
      record.ShippingFee = record?.ShippingFees;
      record.settled_amount = record?.settled_amount;
      record.marketPlace = row.channel;
      record.order_date =
        row.order_date && row.order_date !== "-"
          ? moment(new Date(row.order_date)).format(DATE_FORMAT)
          : "-";
      return record;
    });
  };
  const handleMarketplaceChange = async (
    selectedMarketplaces,
    fromDate,
    toDate
  ) => {
    setMarketPlaces(selectedMarketplaces);
    setFromDate(fromDate);
    setToDate(toDate);
    getPayments(selectedMarketplaces, fromDate, toDate,searchText);
  };

  const onSearchTextEntered = (searchText) => {
    setSearchText(searchText);
  };
  const getOrders = async (requestData) => {
    let response = await PostValues("getOrdersV3", requestData);
    return response;
  };

  const getUnSettledOrders = async (requestData) => {
    let response = await PostValues("unSettledOrders", requestData);
    return response;
  };

  const leaksApi = async (requestData) => {
    let response = await PostValues("leaksPayment", requestData);
    return response;
  };
  const getPayments = async (
    selectedMarketplaces,
    fromDate,
    toDate,
    searchText,
    paginationCond,
    sortCond,
    filterCond = {}
  ) => {
    setIsLoading(true);
    setAllDataLoaded(true);
    let requestData = {
      channels: selectedMarketplaces.map((mp) => mp.name),
      filters: {
        fromDate: fromDate,
        toDate: toDate,
        ...filterCond,
      },
      ...(paginationCond
        ? paginationCond
        : {
            pagination: {
              pageNo: 0,
              pageSize: 25,
            },
          }),
      ...(sortCond ? sortCond : {}),
    };
    if (searchText) {
      requestData.filters["search"] = searchText;
    }
    setRequestData(requestData);
    if (tabValue === "1") {
      let leaksData = await leaksApi(requestData);
      if (leaksData.data) {
        setDashboardPayment([leaksData.data]);
      }
    }
    if (tabValue === "2") {
      requestData.filters["SettledAmount"] = { ISNULL: false };
      requestData.sum = [
        "QuantityShipped",
        "OrderTotal.Amount",
        "SettledAmount",
        "TCS.total",
        "InvoicePrice.total",
        "MarketplaceFees.total",
        "ShippingFees.total",
        "ReturnPrice.total",
      ];
      if (leaksColumn) {
        requestData.filters[leaksColumn] = { ISNULL: false, LT: "0" };
      }
      let data = await getOrders(requestData);
      if (data.orders) {
        var actualOrders = await onActualPaymentSuccess(data.orders);
        setActualPayments([...actualOrders]);
        setFilteredActualPayments([...actualOrders]);
        setTotalRecords(data.pagination.count);
        setSettledOrdersTotal(data.total);
      }
    }
    if (tabValue === "3") {
      let data = await getUnSettledOrders(requestData);
      if (data.orders) {
        var estimateOrders = await onEstimatedPaymentSuccess(data.orders);
        setEstimatedPayments([...estimateOrders]);
        setFilteredEstimatedPayments([...estimateOrders]);
        setTotalRecords(data?.pagination?.count);
        setUnSettledOrdersTotal(data.total[0]);
      }
    }
    setIsLoading(false);
    setAllDataLoaded(false);
  };

  const filterTabvalue = async (data) => {
    setLeaksColumn(data);
    if (data !== "unsettledPayment") {
      setTabValue("2");
    } else {
      setTabValue("3");
    }
  };

  const ExportAllData = async () => {
    try {
      let exportRequestData = { ...requestData };
      if (tabValue === "2") {
        exportRequestData.filters["SettledAmount"] = { ISNULL: false };
        exportRequestData.export = "payment";
        if (leaksColumn) {
          requestData.filters[leaksColumn] = { ISNULL: false, LT: "0" };
        }
        setIsExportLoading(true);
        const data = await getOrders({
          ...exportRequestData,
          pagination: {
            pageNo: 0,
            pageSize: totalRecords,
            count: totalRecords,
          },
        });
        if (data.orders) {
          var url = data.orders;
          var elem = document.createElement("a");
          elem.href = url;
          elem.click();
          // return await onActualPaymentSuccess(data.orders);
        }
        setIsExportLoading(false);
      }
       if (tabValue === "3") {
        setAllDataLoaded(true);
        const unsettled = await getUnSettledOrders({
          ...exportRequestData,
          pagination: {
            pageNo: 0,
            pageSize: totalRecords,
            count: totalRecords,
          },
        });
        setAllDataLoaded(false);
        if (unsettled.orders) {
            return await onEstimatedPaymentSuccess(unsettled.orders);
        }
      }
    } catch (error) {
      setAllDataLoaded(false);
      setIsExportLoading(false);
    }
  };
  React.useEffect(() => {
    if (marketPlaces.length > 0) {
      getPayments(marketPlaces, fromDate, toDate);
      if (tabValue !== "2") {
        setLeaksColumn(null);
      }
    }
  }, [tabValue]);
  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <CustomAppBar />
      <CustomFilterHeader
        headerTitle="Payment Tracker"
        handleManualSyncClick={handleManualSyncClick}
        handleMarketplaceChange={handleMarketplaceChange}
        onSearchTextEntered={onSearchTextEntered}
        searchFunction={(ds, searchText) => {
          getPayments(marketPlaces, fromDate, toDate, searchText.trim());
        }}
        showExportButton={tabValue === "3"}
        getExportData={ExportAllData}
        columns={columns}
        searchHide={tabValue === "1" ? false : true}
        isLoading={allDataLoaded}
        showManualSync={false}
        manualSyncModule={"PAYMENTS"}
        exportFileName="PaymentTracker_unsettledOrders"
        paymentContent={true}
        rows={
          tabValue === "2"
            ? actualPayments
            : tabValue === "3"
            ? estimatedPayments
            : []
        }
        customToggleProfit={ tabValue === "2" &&
          <Spin spinning={isExportLoading}>
            <div className="ml-6">
              <ExportButton
                variant="outlined"
                onClick={ExportAllData}
              >
                Export to CSV
              </ExportButton>
            </div>
          </Spin>
        }
        onFilterUpdate={(data) => {
          if (tabValue === "2") {
            paymentDashboardloader(false);
            setFilteredActualPayments(data || []);
          } else if (tabValue === "3") {
            paymentDashboardloader(false);
            setFilteredEstimatedPayments(data || []);
          }
        }}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }} className="-mt-3 ">
        {/* {paymentloader && <Spin className="flex flex-row-reverse -mb-5" />} */}
        <Tabs
          type="card"
          defaultActiveKey={tabValue}
          tabBarStyle={{ color: "gray", fontWeight: "600" }}
          activeKey={tabValue}
          onChange={(value) => {
            setTabValue(value);
          }}
        >
          <TabPane tab="Summary" key="1">
            <Leaks
              paymentloader={paymentloader}
              data={dashboardPayment}
              filterTabvalue={filterTabvalue}
              isLoading={isLoading}
            />
          </TabPane>
          <TabPane tab="Settled Orders" key="2">
            <OrdersTable
              data={filteredActualPayments}
              pageCount={totalRecords}
              activeTab={`${tabValue}`}
              setColumn={(col) => {
                setColumns(col);
              }}
              settledOrdersTotal={settledOrdersTotal}
              setData={(data) => {
                setFilteredActualPayments(data || []);
              }}
              isLoading={isLoading}
              searchText={searchText}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getPayments(
                  marketPlaces,
                  fromDate,
                  toDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
            />
          </TabPane>
          <TabPane tab="Unsettled Orders" key="3">
            <OrdersTable
              data={filteredEstimatedPayments}
              setData={(data) => {
                setFilteredEstimatedPayments(data || []);
              }}
              pageCount={totalRecords}
              activeTab={`${tabValue}`}
              setColumn={(col) => {
                setColumns(col);
              }}
              unSettledOrdersTotal={unSettledOrdersTotal}
              isLoading={isLoading}
              searchText={searchText}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getPayments(
                  marketPlaces,
                  fromDate,
                  toDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
