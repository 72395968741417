import { makeStyles, MenuItem, Modal } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import MaterialInput from "../../../components/FormItem/MaterialInput";
import MaterialRadio, {
  StyledRadio,
} from "../../../components/FormItem/MaterialRadio";
import MaterialSelect from "../../../components/FormItem/MaterialSelect";
import ExportButton from "../../../components/Styles/ExportButton";
import StyledButton from "../../../components/Styles/StyledButton";
import MapProductIcon from "../../../images/MapProduct.svg";
import ApiWrapper from "../../../utils/ApiWrapper";

const CustomMenuItem = withStyles({
  root: {
    fontWeight: 500,
    fontSize: 14,
  },
})((props) => <MenuItem {...props} />);

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: 550,
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid #F4F5F7",
    padding: 32,
    borderRadius: 5,
  },
  headerImage: {
    marginRight: 16,
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: "#44444F",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 28,
  },
  marginRight: {
    marginRight: 14,
  },
});

const MapProductToSKU = (props) => {
  const { open, handleClose, data } = props;
  const classes = useStyles();

  const [type, setType] = React.useState("user_input");
  const [masterSKU, setMasterSKU] = React.useState("");
  const [masterSKUs, setMasterSKUs] = React.useState([]);
  const [error, setError] = React.useState("");

  const fetchMasterSKUs = async () => {
    ApiWrapper("getMasterSKUs", {}).then(
      function (response) {
        if (response.listings.Items.length > 0) {
          setMasterSKUs(response.listings.Items);
        }
      },
      function (error) {
        // message.error("We encountered an issue. Please log out and log back in.")
      }
    );
  };

  const mapProductToSKU = async () => {
    if (masterSKU) {
      let requestJsonBody = {
        listings: [
          {
            masterSKU: masterSKU,
            channel: data.marketplace,
            sku: data.sku,
            isMap: true,
          },
        ],
      };
      ApiWrapper("updateMasterSKU", requestJsonBody).then(
        function (response) {
          if (response.status) {
            props.refreshList();
            props.handleClose();
          }
        },
        function (error) {
          // message.error("We encountered an issue. Please log out and log back in.")
        }
      );
    } else {
      setError("Please enter a master SKU to proceed.");
    }
  };

  React.useEffect(() => {
    (async () => {
      await fetchMasterSKUs();
    })();
  }, []);

  const handleTypeChange = (e) => {
    setMasterSKU("");
    setError("");
    setType(e.target.value);
    if (e.target.value === "use_product_sku") {
      setMasterSKU(data.sku || "");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.root}>
        <div
          className="flex items-center justify-start"
          style={{ marginBottom: 28 }}
        >
          <img
            src={MapProductIcon}
            alt="map product icon"
            className={classes.headerImage}
          />
          <span className={classes.modalTitle}>Map Product</span>
        </div>
        <MaterialInput
          label="Product SKU"
          disabled
          fullWidth
          value={data ? data.sku : ""}
        />
        <MaterialRadio
          label="User Input"
          value={type}
          onChange={handleTypeChange}
          layout="horizontal"
        >
          <StyledRadio value="user_input" label="User Input" />
          <StyledRadio value="choose_master_sku" label="Choose Master SKU" />
          <StyledRadio value="use_product_sku" label="Use Product SKU" />
        </MaterialRadio>
        {type === "user_input" && (
          <MaterialInput
            fullWidth
            value={masterSKU}
            placeholder="Enter details"
            onChange={(e) => setMasterSKU(e.target.value)}
            errorMessage={error}
          />
        )}
        {type === "choose_master_sku" && (
          <React.Fragment>
            <MaterialSelect
              fullWidth
              value={masterSKU}
              placeholder="Enter details"
              onChange={(e) => setMasterSKU(e.target.value)}
              errorMessage={error}
            >
              {masterSKUs.map((masterSKU, i) => (
                <CustomMenuItem
                  key={i}
                  value={masterSKU.masterSKU}
                  disabled={
                    masterSKU[
                      `${data?.marketplace}-${data?.fulfillmentChannel}-sku`
                    ] !== undefined
                  }
                >
                  {masterSKU.masterSKU}
                </CustomMenuItem>
              ))}
            </MaterialSelect>
            <MaterialInput
              fullWidth
              value={masterSKU}
              disabled
              onChange={(e) => setMasterSKU(e.target.value)}
            />
          </React.Fragment>
        )}
        {type === "use_product_sku" && (
          <MaterialInput
            fullWidth
            value={masterSKU}
            disabled
            onChange={(e) => setMasterSKU(e.target.value)}
          />
        )}
        <div className={classes.buttonContainer}>
          <ExportButton
            variant="outlined"
            className={classes.marginRight}
            onClick={handleClose}
          >
            Cancel
          </ExportButton>
          <StyledButton variant="outlined" onClick={mapProductToSKU}>
            Submit
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
};

MapProductToSKU.defaultProps = {
  data: { marketplace: "Amazon-India" },
};

export default MapProductToSKU;
