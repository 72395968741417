import React from "react";
import ReviewInsightChart from "./reviewInsightChart";
import CallToActionReviewInsights from "./callToAction";
import {
  SentimentAnalysisGraph,
  SentimentAnalysisTable
} from "./sentimentTable";
import { Rate } from "antd";

export default function ReviewsInSights(props) {
  const showExpandedModal = (tab) => {
    props.callToActionReview(tab);
  };

  return (
    <>
      <div class="w-full justify-between flex flex-nowrap tablet:flex-wrap gap-5 ">
        <div
          className="flex-shrink-0 shadow-xl rounded-3xl relative overflow-hidden max-w-xs w-3/12 tablet:w-48 tablet:pointer-events-none mobile:w-45"
          style={{ backgroundColor: "#418ec5" }}
          onClick={() => showExpandedModal(2)}
        >
          <svg
            className="absolute bottom-0 left-0 mb-8"
            viewBox="0 0 375 283"
            fill="none"
            style={{ transform: "scale(1.5)", opacity: "0.1" }}
          >
            <rect
              x="159.52"
              y="175"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 159.52 175)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 0 107.48)"
              fill="white"
            />
          </svg>
          <div className="relative pt-3 px-5 flex items-center justify-center">
            <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
          </div>
          <div className="relative text-white px-6 pb-2">
            <span class="block font-semibold text-xl">Product Reviews</span>
          </div>
          <div className="relative text-white px-6 pb-4">
            <div class="flex justify-between">
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <span class="block font-semibold text-lg">
                  {props.summaryData.product_reviews_count || 0}
                </span>
              )}
            </div>
            <span className="block opacity-75 -mb-1">Selected Period</span>
          </div>
          <div className="relative text-white px-6 pb-2">
            <div class="flex justify-between">
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <span class="block font-semibold text-lg">
                  {props.summaryData.lifetime_reviews_count || 0}
                </span>
              )}
            </div>
            <span className="block opacity-75 -mb-1">Life Time</span>
          </div>
          <div className=" pb-1"></div>
        </div>
        <div
          className="flex-shrink-0 shadow-2xl rounded-3xl relative overflow-hidden max-w-xs w-3/12 tablet:w-48 tablet:pointer-events-none mobile:w-45"
          style={{ backgroundColor: "#418ec5" }}
          onClick={() => showExpandedModal(1)}
        >
          <svg
            className="absolute bottom-0 left-0 mb-8"
            viewBox="0 0 375 283"
            fill="none"
            style={{ transform: "scale(1.5)", opacity: "0.1" }}
          >
            <rect
              x="159.52"
              y="175"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 159.52 175)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 0 107.48)"
              fill="white"
            />
          </svg>
          <div className="relative pt-3 px-5 flex items-center justify-center">
            <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
          </div>
          <div className="relative text-white px-6 pb-2">
            <span class="block font-semibold text-xl">Product Ratings</span>
          </div>
          <div className="relative text-white px-6 pb-4">
            <div class="flex justify-between">
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <span class="block font-semibold text-lg">
                  {props.summaryData.product_ratings_count || 0}
                </span>
              )}
            </div>
            <span className="block opacity-75 -mb-1">Selected Period</span>
          </div>
          <div className="relative text-white px-6 pb-2">
            <div class="flex justify-between">
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <span class="block font-semibold text-lg">
                  {props.summaryData.lifetime_ratings_count || 0}
                </span>
              )}
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <div className="mx-4">
                  <Rate
                    allowHalf
                    defaultValue={props.summaryData.lifetime_average_stars}
                    value={props.summaryData.lifetime_average_stars}
                    disabled
                    count={5}
                    allowClear={false}
                  />
                </div>
              )}
            </div>
            <span className="block opacity-75 -mb-1">Life Time</span>
          </div>
          <div className=" pb-1"></div>
        </div>
        <div
          className="flex-shrink-0 shadow-2xl rounded-3xl relative overflow-hidden max-w-xs w-3/12 tablet:w-48 tablet:pointer-events-none mobile:w-45"
          style={{ backgroundColor: "#418ec5" }}
          onClick={() => showExpandedModal(3)}
        >
          <svg
            className="absolute bottom-0 left-0 mb-8"
            viewBox="0 0 375 283"
            fill="none"
            style={{ transform: "scale(1.5)", opacity: "0.1" }}
          >
            <rect
              x="159.52"
              y="175"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 159.52 175)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 0 107.48)"
              fill="white"
            />
          </svg>
          <div className="relative pt-3 px-5 flex items-center justify-center">
            <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
          </div>
          <div className="relative text-white px-6 pb-2">
            <span class="block font-semibold text-xl">Seller Reviews</span>
          </div>
          <div className="relative text-white px-6 pb-4">
            <div class="flex justify-between">
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <span class="block font-semibold text-lg">
                  {props.summaryData.seller_reviews_count || 0}
                </span>
              )}
            </div>
            <span className="block opacity-75 -mb-1">Selected Period</span>
          </div>
          <div className="relative text-white px-6 pb-2">
            <div class="flex justify-between">
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <span class="block font-semibold text-lg">
                  {props.summaryData.lifetime_seller_reviews || 0}
                </span>
              )}
              {/* {props.isLoading ? (
                                <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                </div>
                            ) : (
                                <div className="mx-4">
                                    <Rate
                                        allowHalf
                                        defaultValue={props.summaryData.lifetime_average_stars}
                                        value={props.summaryData.lifetime_average_stars}
                                        disabled
                                        count={5}
                                        allowClear={false}
                                    />
                                </div>
                            )} */}
            </div>
            <span className="block opacity-75 -mb-1">Life Time</span>
          </div>
          <div className=" pb-1"></div>
        </div>
        <div
          className="flex-shrink-0 shadow-2xl rounded-3xl relative overflow-hidden max-w-xs w-3/12 tablet:w-48 tablet:pointer-events-none mobile:w-45"
          style={{ backgroundColor: "#418ec5" }}
        >
          <svg
            className="absolute bottom-0 left-0 mb-8"
            viewBox="0 0 375 283"
            fill="none"
            style={{ transform: "scale(1.5)", opacity: "0.1" }}
          >
            <rect
              x="159.52"
              y="175"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 159.52 175)"
              fill="white"
            />
            <rect
              y="107.48"
              width="152"
              height="152"
              rx="8"
              transform="rotate(-45 0 107.48)"
              fill="white"
            />
          </svg>
          <div className="relative pt-3 px-5 flex items-center justify-center">
            <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
          </div>
          <div className="relative text-white px-6 pb-2">
            <span class="block font-semibold text-xl">Conversion</span>
          </div>
          <div className="relative text-white px-6 pb-4">
            <div class="flex justify-between">
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <span class="block font-semibold text-lg">
                  {props.summaryData.orders_to_ratings_ratio + " %" || 0}
                </span>
              )}
            </div>
            <span className="block opacity-75 -mb-1">
              Order to Review/Rating Ratio
            </span>
          </div>
          <div className="relative text-white px-6 pb-2">
            <div class="flex justify-between">
              {props.isLoading ? (
                <div className="item bg-indigo-500 opacity-25">
                  <div className="item-title" />
                </div>
              ) : (
                <span class="block font-semibold text-lg">
                  {props.summaryData.emails_to_ratings_ratio + " %" || 0}
                </span>
              )}
            </div>
            <span className="block opacity-75 -mb-1">
              Emails to Review/Rating Ratio
            </span>
          </div>
          <div className=" pb-1"></div>
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-between">
        <div className="w-1/2 mt-5  tablet:w-full border-2 border rounded p-4 flex-wrap  ">
        <CallToActionReviewInsights
            callToActionReview={props.callToActionReview}
            isLoading={props.isLoading}
            summaryData={props.summaryData}
          />
          <p className="text-base font-semibold antialiased text-stone-800">
            Frequency-TwoWords
          </p>
          <div className="border-2 border rounded" style={{backgroundColor:"#ebf5fa"}}>
            <SentimentAnalysisGraph
              data={
                props.sentimentSummaryData
                  ? props?.sentimentSummaryData?.bigrams
                  : []
              }
              isLoading={props.isLoading}
            />
          </div>
        </div>
        <div className="w-1/2  mt-5 tablet:w-full border-2 border rounded bg-white p-4 flex-wrap  ">
          <SentimentAnalysisTable
            data={
              props.sentimentSummaryData
                ? props?.sentimentSummaryData?.sentiment_table
                : []
            }
            isLoading={props.isLoading}
          />
        </div>
        {/* <div class="h-1/3 w-2/5 mt-5">
          <CallToActionReviewInsights
            callToActionReview={props.callToActionReview}
            isLoading={props.isLoading}
            summaryData={props.summaryData}
          />
        </div> */}
      </div>
      <div className="flex flex-wrap-reverse w-full justify-between">
        {/* <div class="h-1/2 w-full">
          <CallToActionReviewInsights
            callToActionReview={props.callToActionReview}
            isLoading={props.isLoading}
            summaryData={props.summaryData}
          />
        </div> */}
        {/* <div class="h-1/2 w-48 border rounded bg-white p-6 " >
                        <b> Ratings & Reviews</b>
                        {props.isLoading ? <Skeleton /> :
                            <ResponsiveContainer height={230}  >
                                <LineChart
                                    data={props.summaryData.ratings_reviews_vs_date_graph}
                                    margin={{ top: 20, bottom: 15, right: 10 }}
                                >
                                    <XAxis angle="90" dataKey="name" tickCount={4} dy={15} >
                                        <Label value="Date" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Ratings & Reviews', angle: -90, position: 'insideLeft', fontSize: '10px', fontWeight: 'bold' }} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Line
                                        type="monotone"
                                        dataKey="value"
                                        stroke="#FCAA0B"
                                        strokeWidth={3}
                                        activeDot={{ r: 8 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        }
                    </div> */}
      </div>
      <br />
      <ReviewInsightChart
        className="flex mt-4 flex-nowrap"
        isLoading={props.isLoading}
        ratingReviewGrap={props.ratingReviewGrap}
        summaryData={props.summaryData}
        reviewGrap={props.reviewGrap}
      />
    </>
  );
}
