import { Modal, Table } from "antd";
import React from "react";


export default function BankSettlementModel(props) {
  return (
    <>
      <Modal
        className="overflow-hidden rounded-xl p-0 shadow-2xl m-2.5"
        title={props.title}
        visible={props.isModalVisible}
        footer={null}
        centered
        onCancel={props.hideCtaModal}
        bodyStyle={{ padding: "10px" }}
        width={900}
      >
        {" "}
        {props.data && (
          <Table
            scroll={{ x: true }}
            columns={props.columns}
            dataSource={props.data}
            loading={props.orderTableLoading}
            size="medium"
            bordered
            pagination={true}
            // footer={() => (
            //   <div className="flex items-center justify-between">
            //     <span>Total</span>
            //     <span>
            //       {props.data.reduce((sum, val) => sum + Number(val?.reserve_adjustments), 0)}
            //     </span>
            //   </div>
            // )}
          />
        )}
      </Modal>
    </>
  );
}
