import { Button, Form, Input, Modal } from "antd";
import React from "react";
import CategoryIcon from "../../images/category_outlined.svg";

const PincodePopup = (props) => {
  const onFinish = (values) => {
    props.onSetFcPincode(
      props.fbaObject?.["fulfillment-center-id"],
      values.pincode
    );
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      footer={null}
    >
      <div className={`flex items-center`}>
        <img src={CategoryIcon} alt="category icon" className={`inline`} />
        <span className={`text-SpaceBlack font-medium text-lg ml-4`}>
          Please set pincode ({props?.fbaObject?.["fulfillment-center-id"]})
        </span>
      </div>
      <div className={`mt-8`}>
        <Form
          onFinish={onFinish}
          initialValues
          onFinishFailed={(errorInfo) => {
            console.log("Failed:", errorInfo);
          }}
        >
          <Form.Item
            name="pincode"
            rules={[
              {
                required: true,
                message: "Pincode is required",
              },
              {
                pattern: new RegExp(/^(?:\d*)$/),
                message: "Value should contain number only",
              },
              {
                pattern: new RegExp(/^(\d{6,6})$/),
                message: "Value should be exactly 6 digits",
              },
            ]}
          >
            <Input placeholder="Enter 6 digit pincode" maxLength={6} />
          </Form.Item>
          <Form.Item className="flex justify-end mb-0 text-right">
            <Button onClick={props.onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" className="ml-3">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default PincodePopup;
