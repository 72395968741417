import { makeStyles, Modal } from "@material-ui/core";
import React from "react";
import PopOver from "../../../components/Popover";
import ExportButton from "../../../components/Styles/ExportButton";
import StyledButton from "../../../components/Styles/StyledButton";
import PublishInventoryIcon from "../../../images/icons/PublishInventory.svg";
import ApiWrapper from "../../../utils/ApiWrapper";
import { PostValues } from "../../../utils/api-client.js";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: 550,
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid #F4F5F7",
    padding: 32,
    borderRadius: 5,
  },
  headerImage: {
    marginRight: 16,
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: "#44444F",
  },
  modalContent: {
    fontFamily: "Inter",
    fontSize: 14,
    color: "#717480",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 28,
  },
  marginRight: {
    marginRight: 14,
  },
});

const PublishInventory = (props) => {
  const [open, setOpen] = React.useState(false);
  const [intervalId, setIntervalId] = React.useState(null);

  const classes = useStyles();

  // React.useEffect(() => {
  //   if (props.requestId) {
  //     let interval = setInterval(() => {
  //       ApiWrapper("getPendingRequest", {}).then((response) => {
  //         // props.setRequestId(response?.pendingRequests?.[0].requestId);
  //         // props.setRequestId(response.requestId);
  //       });
  //     }, 5000);
  //     setIntervalId(interval);
  //   } else {
  //     clearInterval(intervalId);
  //   }
  // }, [props.requestId]);

  const handleCancel = () => {
    props.refreshList();
    setOpen(false);
  };

  const handlePublish = async () => {
    let requestJsonBody = {
      listings: props.data,
    };
    let reqData = {
      updated_at: new Date(),
      module_name: "Listings",
      updated_value:  props.data ,
    };
    let response = await PostValues("updateInventory_v2", requestJsonBody);
    props.setRequestId(response.requestId);
    setOpen(false);
    if (response.status) {
      ApiWrapper("updateChangeLog", reqData).then(
        function (response) {
        },
        function (error) {}
      );
    }
    // ApiWrapper("updateInventory", requestJsonBody).then(
    //   function (response) {
    //     props.setRequestId(response.requestId);
    //     setOpen(false);
    //   },
    //   function (error) {
    //     // message.error("We encountered an issue. Please log out and log back in.")
    //   }
    // );
  };

  return (
    <React.Fragment>
      {props.requestId && (
        <PopOver title="Already a sync is in progress, Please wait.">
          <StyledButton
            variant="outlined"
            onClick={() => setOpen(true)}
            style={{ marginRight: 16 }}
            disabled={props.requestId}
          >
            Publish
          </StyledButton>
        </PopOver>
      )}
      {!props.requestId && (
        <StyledButton
          variant="outlined"
          onClick={() => setOpen(true)}
          style={{ marginRight: 16 }}
          disabled={props.requestId}
        >
          Publish
        </StyledButton>
      )}
      <Modal
        open={open}
        onClose={handleCancel}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.root}>
          <div
            className="flex items-center justify-start"
            style={{ marginBottom: 28 }}
          >
            <img
              src={PublishInventoryIcon}
              alt="map product icon"
              className={classes.headerImage}
            />
            <span className={classes.modalTitle}>Publish to Marketplace</span>
          </div>
          <span className={classes.modalContent}>
            The changes you have made will be published to marketplace
          </span>
          <div className={classes.buttonContainer}>
            <ExportButton
              variant="outlined"
              className={classes.marginRight}
              onClick={handleCancel}
            >
              Cancel
            </ExportButton>
            <StyledButton variant="outlined" onClick={handlePublish}>
              Publish
            </StyledButton>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

PublishInventory.defaultProps = {
  data: { marketplace: "Amazon-India" },
};

export default PublishInventory;
