import { Button, message, Modal, Typography, notification } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import "../.././App.css";
import CustomFormFooter from "../../components/./CustomFormFooter.js";
// import CustomFormHeader from "../../components/./CustomFormHeader.js";
import CustomTextField from "../../components/./CustomTextField.js";
// import { TableContext } from "../../context/TableContent";
import { UserContext } from "../../context/UserContext";
import ApiWrapper from "../../utils/ApiWrapper";
import AuthLayout from "./authLayout";
// import RegisterModal from "./registerModal";

const loginvalidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Required"),
  password: Yup.string().trim().min(8).required("Required")
});

// var MobileDetect = require("mobile-detect");
const querystring = require("querystring");

function LoginPage(props) {
  const [isAuthFailureModalOpen, setIsAuthFailureModalOpen] = useState(false);
  const UserConsumerContext = React.useContext(UserContext);
  useEffect(() => {
    let parsedObject = querystring.parse(
      props.location.search.substr(1, props.location.search.length)
    );
    if (parsedObject?.redirect_reason === "auth_failure") {
      setIsAuthFailureModalOpen(true);
    }
  }, []);
  const callSendOtpEmail = async (
    medium,
    requestAction,
    authToken,
    actions,
    e
  ) => {
    let otpRequestJson = {
      medium: "email",
      action: requestAction,
    };
    ApiWrapper("sendOtpSelf", otpRequestJson, authToken).then(
      (response) => {
        if (response.status) {
          localStorage.setItem("emailOTPToken", response.fs_auth_token);
          if (medium === "email|mobile") {
            callSendOtpMobile(medium, requestAction, authToken, actions);
          } else {
            actions.setSubmitting(false);
            props.history.push(
              `/enter-otp?action=${response.action}&medium=${response.medium}`
            );
          }
        } else {
          message.error(response.message);
        }
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };

  const callSendOtpMobile = async (
    medium,
    requestAction,
    authToken,
    actions,
    e
  ) => {
    let otpRequestJson = {
      medium: "mobile",
      action: requestAction,
    };
    ApiWrapper("sendOtpSelf", otpRequestJson, authToken).then(
      (response) => {
        if (response.status) {
          localStorage.setItem("mobileOTPToken", response.fs_auth_token);
          actions.setSubmitting(false);
          props.history.push(
            `/enter-otp?action=${response.action}&medium=${response.medium}`
          );
        }
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };
  const args = {
    message: "",
    duration: 5,
    className: 'notification-warning'
  };
  const handleLoginClick = async (values, actions, e) => {
    console.log("login called");
    ApiWrapper("loginSelf", values).then(
      (response) => {
        // var md = new MobileDetect(window.navigator.userAgent);
        if (response.status) {
          response.organisation_info.channel_data.filter(data => {
            if (data.channel === 'Amazon-India' && data.isInvalid) {
              args["message"] = `${data.channel}` + " password changed so please contact your administrator";
              notification.open(args);
            } else if (data.channel === 'Flipkart' && data.isInvalid) {
              setTimeout(() => {
                args["message"] = `${data.channel}` + " password changed so please contact your administrator";
                notification.open(args);
              })
            }
          })
          actions.setSubmitting(false);
          localStorage.setItem("token", response.fs_auth_token);
          localStorage.setItem("refresh_token", response.fs_refresh_token);
          localStorage.setItem("email", values.email);
          localStorage.setItem("user_info", JSON.stringify(response.user_info));
          localStorage.setItem("subscriptionDays", response.user_info.SubscriptionEndDate);
          localStorage.setItem("userPermission", JSON.stringify(response.organisation_info.permissions))
          localStorage.setItem(
            "cmpy_info",
            JSON.stringify(response.organisation_info)
          );
          window.pendo.initialize({
            visitor: {
              id: response.user_info.email,
              email: response.user_info.email,
              full_name: response.user_info.name
            },
            account: {
              id: response.user_info.email, // Required if using Pendo Feedback
              name: response.user_info.name
            }
          });
          UserConsumerContext.setUser(response.user_info);
          UserConsumerContext.setUser(response.organisation_info);
          if (response.admin_dashboard) {
            let arrayAdd = ["ADMINDASHBOARD_READ", "ADMINDASHBOARD_WRITE", "SYNCDETAILS_READ"]
            let ar1 = arrayAdd.concat(response.organisation_info.permissions);
            localStorage.setItem("userPermission", JSON.stringify(ar1))
            localStorage.setItem("org_access", response.OrgAccess);
            localStorage.setItem("isAgencyDashboard", response.user_info.Agency);
            props.history.replace("/admin-dashboard");
          } else {

            localStorage.setItem("modelopenRenew", true);
            // if (md.phone() || md.tablet()) {
            if (response?.user_info?.["SubscriptionEndDate"] === undefined) {
              props.history.replace("/account-settings");
            } else {
              props.history.replace("/dashboard");
            }
            // } else {
            //   if (response?.user_info?.["SubscriptionEndDate"] === undefined) {
            //     props.history.replace("/account-settings");
            //   } else {
            //     props.history.replace("/dashboard");
            //   }
            // }
          }
        } else {
          if (response.message_code === "E101") {
            actions.setErrors({ email: "Email ID does not exist" });
            actions.setSubmitting(false);
          } else if (response.message_code === "E107") {
            actions.setErrors({ password: "Incorrect password" });
            actions.setSubmitting(false);
          } else if (response.action === "verify") {
            localStorage.setItem("token", response.fs_auth_token);

            if (response.medium === "email") {
              callSendOtpEmail(
                response.medium,
                response.action,
                response.fs_auth_token,
                actions
              );
            } else if (response.medium === "mobile") {
              callSendOtpMobile(
                response.medium,
                response.action,
                response.fs_auth_token,
                actions
              );
            } else {
              callSendOtpEmail(
                response.medium,
                response.action,
                response.fs_auth_token,
                actions
              );
            }
          } else if (response.message_code === "E134") {
            message.error(response.message);
          }
        }
      },
      function (error) { }
    );
  };
  return (
    <AuthLayout page="Login">
      <div className="logo" />
      {/* <CustomFormHeader
        title={`Login`}
        subtitle={``}
      /> */}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginvalidationSchema}
        onSubmit={(values, actions) => {
          handleLoginClick(values, actions);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <div className="mt-9">
              <CustomTextField
                title="Registered Email Id"
                name="email"
                type="email"
              />
            </div>
            <div className="mt-1">
              <CustomTextField
                title="Password"
                name="password"
                type="password"
              />
            </div>
            <div className="mt-2 text-center">
              <Typography>
                <div
                  onClick={() => {
                    props.history.push("/forgot-password");
                  }}
                  className="text-sm text-right font-bold text-DarkCornFlowerBlue cursor-pointer"
                >
                  Forgot Password?
                </div>
              </Typography>
            </div>
            <div className="mt-8">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="uppercase w-full shadow-lg hover:shadow-xl rounded font-semibold"
                disabled={isSubmitting}
              // onClick={submitForm}
              >
                Login
              </Button>
            </div>

            <div className="mt-8">
              {/* Register Popup
              <CustomFormFooter
                linkHelpText="Don't have an account?"
                linkText={<RegisterModal />}
              /> */}
              <CustomFormFooter
                linkHelpText="Don't have an account?"
                linkText="Register"
                clickAction={() => {
                  props.history.push("/register");
                }}
              />

            </div>
          </Form>
        )}
      </Formik>
      <Modal
        title="Authentication error"
        visible={isAuthFailureModalOpen}
        onCancel={() => {
          setIsAuthFailureModalOpen(false);
        }}
        footer={[
          <div>
            <Button
              type="primary"
              onClick={() => {
                setIsAuthFailureModalOpen(false);
              }}
            >
              Close{" "}
            </Button>
          </div>,
        ]}
      >
        <p>Please login again to continue</p>
      </Modal>
    </AuthLayout>
  );
}
export default withRouter(LoginPage);
