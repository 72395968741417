function filterArray(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    // returns true when one filter criteria is satisfied
    return filterKeys.some((key) => {
      // ignores non-function predicates
      if (typeof filters[key] !== "function") return true;
      return filters[key](item[key]);
    });
  });
}

const searchArray = (searchData, filters) => {
  let searchResults = new Set();

  Object.keys(filters).forEach((filter) => {
    filterArray(searchData, filters).forEach((x) => {
      if (filters[filter](x[filter])) {
        searchResults.add(x);
      }
    });
  });

  return Array.from(searchResults);
};

export { searchArray };
