import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import moment from "moment";
import { PostValues } from "../../utils/api-client";
import AntTable from "../../components/AntTableV2";
import CustomAppBar from "../../components/CustomAppBar";
import PageHeader from "../../components/PageHeader";
import { Popover, Tooltip } from "antd";

const CustomTooltip = (props) => {
  console.log(props);
  return (
    <div className="text-xs">
      <p>{`SKU : ${props.data?.M?.sku?.S}`}</p>
      <p>{`COGS : ${props.data?.M?.COGS?.S || props.data?.M?.COGS?.N}`}</p>
      <p>{`COGSTax : ${props.data?.M?.COGSTax?.S || props.data?.M?.COGSTax?.N}`}</p>
      <p>{`UpdatedCOGS : ${props.data?.M?.UpdatedCOGS?.S}`}</p>
      <p>{`UpdatedCOGSTax : ${props.data?.M?.UpdatedCOGSTax?.S}`}</p>
    </div>
  );
};

export default function ChangeLog() {
  const [changeLogList, setChangeLogList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const RefColumns = [
    {
      title: "Sno",
      align: "center",
      isTotal: false,
      field: "sno",
      width: 20,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          rowIndex
        ),
    },
    {
      title: "Email Id",
      align: "center",
      isTotal: false,
      field: "user_id.S",
      width: 75,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.user_id !== undefined ? (
          <p> {record?.user_id.S}</p>
        ) : (
          "-"
        ),
    },
    {
      title: "ModuleName",
      align: "center",
      isTotal: false,
      field: "module_name.S",
      width: 75,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.module_name !== undefined ? (
          <div className="cursor-pointer">
            {record?.module_name.S === "InputProductCost" || record?.module_name.S === "ProductCofiguration" ? (
              <Popover content={<CustomTooltip data={record.updated_value} />}>
                <p> {record?.module_name.S}</p>
              </Popover>
            ) : (
              <p> {record?.module_name.S}</p>
            )}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "Updated At",
      align: "center",
      isTotal: false,
      field: "updated_at.S",
      width: 75,
      defaultSortOrder: "descend",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.updated_at !== undefined ? (
          moment(record?.updated_at?.S).format("MMM Do YYYY, hh:mm:ss A")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = a?.updated_at?.S
          ? a?.updated_at?.S !== "-"
            ? new Date(
                (a?.updated_at?.S || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        b = b?.updated_at?.S
          ? b?.updated_at?.S !== "-"
            ? new Date(
                (b?.updated_at?.S || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        return a - b;
      },
      // sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
    },
  ];

  const getChangeLogs = async (requestData) => {
    let response = await PostValues("queryUserActivity", requestData);
    return response;
  };
  React.useEffect(() => {
    UserChangeLog();
  }, []);

  const UserChangeLog = async () => {
    setIsLoading(true);
    let requestData = {};
    let changeLogs = await getChangeLogs(requestData);
    if (changeLogs.Items) {
      setChangeLogList([...changeLogs.Items]);
    }
    setIsLoading(false);
  };

  return (
    <div style={{ fontFamily: "inter" }}>
      <CustomAppBar />
      <div className="settings-header mb-5">
        <PageHeader title="Change Logs" />
      </div>
      <Container>
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <AntTable
            columns={RefColumns}
            dataSource={changeLogList}
            isLoading={isLoading}
            stickyHeader={{
              offsetHeader: 65,
            }}
            rowKey={(row, rowIndex) => rowIndex}
            hideSummary={true}
          />
        </div>
      </Container>
    </div>
  );
}
