import { Anchor, Tag, Table } from "antd";
import React from "react";
import { PaymentTrackerContext } from "./OrdersTable";
import moment from "moment";

const { Link } = Anchor;
function getCalculatedValue(json, key) {
  var i,
    len = json.length;
  var obj = {
    currency: null,
    isLink: false,
    isSetFcPincode: false,
    fbaObject: {},
  };

  for (i = 0; i < len; i++) {
    if (json[i] && json[i].hasOwnProperty(key)) {
      let requestedObject = json[i][key];
      obj.currency = requestedObject.CurrencyAmount;
      obj.isLink = requestedObject.ChangeCategory;
      obj.isSetFcPincode = requestedObject.ChangeFCPincode;
      if (requestedObject.ChangeFCPincode) {
        obj.fbaObject = requestedObject;
      }
      return obj;
    }
  }

  return obj;
}

function getCalculatedPercentage(json, key) {
  var i,
    len = json.length;

  for (i = 0; i < len; i++) {
    if (json[i] && json[i].hasOwnProperty(key)) {
      let requestedObject = json[i][key];
      return requestedObject.Percentage;
    }
  }

  return null;
}
const arrayData = [
  "Commission",
  "FBAWeightBasedFee",
  "PickandPackFee",
  "FixedClosingFee",
  "ShippingChargeback",
  "TechnologyFee",
];

const RenderRow = ({
  row,
  rowKey,
  isReconcilicationAvailable,
  type,
  reconciliation,
  onCategoryChange,
  activeTab,
  ...rest
}) => {
  const key = rowKey;
  const recon = isReconcilicationAvailable
    ? activeTab !== "3"
      ? getCalculatedValue(reconciliation, key)
      : type !== "MarketplaceFees"
      ? getCalculatedValue(reconciliation, key)
      : null
    : null;
  const reconPercentage = isReconcilicationAvailable
    ? activeTab !== "3"
      ? getCalculatedPercentage(reconciliation, key)
      : type !== "MarketplaceFees"
      ? getCalculatedPercentage(reconciliation, key)
      : null
    : null;
  const PaymentTrackerContextConsumer = React.useContext(PaymentTrackerContext);
  return (
    <div className="flex">
      <div className="w-20 font-Inter font-normal p-1 text-SpaceBlack">
        <div>{key}</div>
      </div>
      {key === "Commission" ? (
        <>
          <div
            className={`${
              isReconcilicationAvailable ? " w-36" : "w-20"
            } font-Inter font-normal p-1 flex items-center`}
          >
            <div>
              {row[`${key}`].CurrencyAmount.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </div>
            <div className="font-Inter font-normal pl-1">
              {activeTab !== "3" && (
                <div>
                  {row[`${key}`].Percentage && (
                    <Tag color={"green"} className="rounded-xl">
                      {row[`${key}`].Percentage + "%"}
                    </Tag>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          className={`${
            isReconcilicationAvailable ? " w-36" : "w-20"
          }  font-Inter font-normal p-1`}
        >
          {row[`${key}`].CurrencyAmount?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })}
        </div>
      )}

      {recon && recon.currency && (
        <div
          colSpan={key === "Commission" ? 1 : 2}
          className={`${key === "Commission" ? "" : "w-20"}`}
        >
          {recon.isLink ? (
            <div className={`w-20 font-Inter font-normal p-1`}>
              <div
              // className="text-primary cursor-pointer"
              // onClick={() => {
              //   PaymentTrackerContextConsumer.onSetCategory(
              //     rest.rowData,
              //     rest.title,
              //     rest.breakup,
              //     type,
              //     rest.marketplace,
              //     reconciliation
              //   );
              // }}
              >{`₹ ${recon.currency}`}</div>
            </div>
          ) : recon.isSetFcPincode ? (
            <div className={` w-20 font-Inter font-normal p-1`}>
              <div
                onClick={() =>
                  PaymentTrackerContextConsumer.onSetFcPincode(
                    recon.fbaObject,
                    rest.rowData
                  )
                }
                className="text-primary cursor-pointer"
              >
                {"Set Pincode"}
              </div>
            </div>
          ) : (
            <div className={` w-20 font-Inter font-normal p-1`}>
              {`${
                key === "FBAWeightBasedFee" && recon.currency > 0
                  ? "₹" + "-" + recon.currency
                  : "₹" + recon.currency
              }`}
              {/* {"₹" + recon.currency} */}
            </div>
          )}
        </div>
      )}
      {key === "Commission" && reconPercentage && (
        <div className={`w-20 font-Inter font-normal p-1`}>
          <div>
            <Tag color={"green"} style={{ borderRadius: 10 }}>
              {reconPercentage + "%"}
            </Tag>
          </div>
        </div>
      )}
    </div>
  );
};

export default function OrderCellData(props) {
  return (
    <div className="mr-3 m-2">
      <div className="border border-Platinum rounded bg-GhostWhite">
        <div className={`p-3 flex items-center`}>
          <div className="w-20 font-Inter font-medium p-1"> {props.title}</div>
          <div
            className={` ${
              props.isReconcilicationAvailable ? " w-36" : "w-18"
            }  text-SpaceBlack text-xs font-Inter font-medium text-left p-1`}
          >
            {props.marketplace}
          </div>
          {props.isReconcilicationAvailable && props.activeTab !== "3" && (
            <div className=" w-20 font-medium  text-SpaceBlack text-xs font-Inter text-left p-1">
              Calculated
            </div>
          )}
        </div>
        <div>
          {props.breakup &&
            props.breakup.map((row, i) => {
              return (
                <div
                  key={i}
                  className={` p-2 ${i % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                >
                  <RenderRow
                    row={row}
                    key={`row-${i}`}
                    rowKey={Object.keys(row)[0]}
                    isReconcilicationAvailable={
                      props.isReconcilicationAvailable
                    }
                    type={props.type}
                    reconciliation={props.reconciliation}
                    title={props.title}
                    breakup={props.breakup}
                    rowData={props.row}
                    marketplace={props.marketplace}
                    activeTab={props.activeTab}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
