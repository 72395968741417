import { CheckOutlined } from "@ant-design/icons";
import { Image, message, Select, Steps, Switch, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Prompt, useHistory } from "react-router-dom";
import {
  customer_types,
  feedbacks,
  location_data,
} from "../../../../assets/campaign/audience_type.json";
import CampaignToolbar from "../../../../components/CampaignToolbar";
import MaterialInput from "../../../../components/FormItem/MaterialInput";
import ExportButton from "../../../../components/Styles/ExportButton";
import { PrimaryButton } from "../../../../components/Styles/StyledButton";
import ApiWrapper from "../../../../utils/ApiWrapper";
import { Fulfilment_filter } from "../../../../utils/data";

const customDot = (dot, { status, index }) => {
  if (status === "finish") {
    return (
      <div
        style={{
          width: 24,
          height: 24,
          borderRadius: "50%",
          background: "#0065FF",
          boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.02)",
          marginTop: "-10px",
          marginLeft: "-5px",
        }}
      >
        <CheckOutlined style={{ fontSize: 12, color: "#FFFFFF" }} />
      </div>
    );
  } else if (status === "process") {
    return (
      <div
        style={{
          width: 24,
          height: 24,
          borderRadius: "50%",
          background: "#0065FF",
          boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.02)",
          marginTop: "-10px",
          marginLeft: "-5px",
        }}
      />
    );
  } else if (status === "wait") {
    return (
      <div
        style={{
          width: 24,
          height: 24,
          borderRadius: "50%",
          background: "#DFE1E6",
          boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.02)",
          marginTop: "-10px",
          marginLeft: "-5px",
        }}
      />
    );
  }
};

const CreateCampaign = (props) => {
  const [blockNavigate, setBlockNavigate] = useState(false);
  const [step, setStep] = React.useState(0);
  const [campaignName, setCampaignName] = React.useState("");
  const [inventory, setInventory] = React.useState([]);
  const [selectedSKUs, setSelectedSKUs] = React.useState([]);
  const [priorFeedback, setPriorFeedback] = React.useState([]);
  const [customerType, setCustomerType] = React.useState([]);
  const [location, setLocation] = React.useState([]);
  const [oldCustomers, setOldCustomers] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectrow, setSelectRow] = React.useState(false);

  const history = useHistory();

  const fetchData = () => {
    setLoading(true);
    ApiWrapper("getInventoryForCampaign", {}).then(
      (response) => {
        setInventory(
          (response.Items || response.data || []).filter(
            (item) => item.EmailCampaignDetails === undefined
          )
        );
        setBlockNavigate(true);
        setLoading(false);
      },
      (error) => {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
        setLoading(false);
      }
    );
  };

  const createCampaign = () => {
    setBlockNavigate(false);
    let requestBody = {
      name: campaignName,
      mode: "Automated",
      campaign_status: "on",
      skus: selectedSKUs,
      location: location,
      customer_type: customerType,
      feedback: priorFeedback,
      start_date: oldCustomers
        ? moment().subtract(30, "days").format("YYYY-MM-DD")
        : "",
      end_date: oldCustomers ? moment().format("YYYY-MM-DD") : "",
      select_all: selectrow
    };
    ApiWrapper("createCampaign", requestBody).then(
      (response) => {
        message.success("Campaign successfully created");
        history.push(`/reviews`);
      },
      (error) => {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };

  const handleSubmit = () => {
    if (campaignName === "" || selectedSKUs.length === 0) {
      message.error("Add all fields before creating campaigns");
    } else {
      createCampaign();
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "ASIN",
      dataIndex: "productId",
      key: "productId",
      sorter: (a, b) => {
        a = (a.productId || "").toLowerCase();
        b = (b.productId || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      sorter: (a, b) => {
        a = (a.sku || "").toLowerCase();
        b = (b.sku || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "Product Image",
      dataIndex: "imageURL",
      key: "imageURL",
      render: (text, record, index) => (
        <Image
          width={36}
          height={36}
          src={text}
          fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
        />
      ),
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        a = (a.name || "").toLowerCase();
        b = (b.name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "Fulfilment Channel",
      dataIndex: "fulfillmentChannel",
      key: "fulfillmentChannel",
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.fulfillmentChannel) {
            return true;
          }
        } else {
          let isFilter = false;
          value.map((fulfillmentChannel) => {
            if (
              fulfillmentChannel.toLowerCase() ===
              (record.fulfillmentChannel || "").toLowerCase()
            ) {
              isFilter = true;
            }
          });
          return isFilter;
        }
      },
      filters: Fulfilment_filter,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{"Create Campaigns"}</title>
      </Helmet>
      <Prompt
        when={blockNavigate}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <CampaignToolbar step={step} setStep={setStep} />
      <div className="flex items-center justify-center">
        <div className="w-5/6">
          <div className="text-2xl text-center font-semibold mt-8">
            Setup Automated Review Campaign
          </div>
          <div className="flex items-center justify-center mt-10">
            <Steps current={step} progressDot={customDot} className="w-1/4">
              <Steps.Step title="Select ASIN/Orders" />
              <Steps.Step title="Targeted Customers" />
            </Steps>
          </div>
          {step === 0 && (
            <React.Fragment>
              <div className="text-xl text-ErieBlack my-8">Campaign Setup</div>
              <div className="p-4 border rounded w-2/3">
                <div className="w-2/3">
                  <MaterialInput
                    fullWidth
                    label="Campaign Name"
                    value={campaignName}
                    placeholder="Enter Campaign Name"
                    onChange={(e) => setCampaignName(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-xl text-ErieBlack my-8">Set Preferences</div>
              <Table
                rowSelection={{
                  type: "checkbox",
                  onSelect: (record, selected, selectedRows, nativeEvent) =>{
                    setSelectRow(false);
                    setSelectedSKUs(selectedRows.map((row) => row.sku))},
                  getCheckboxProps: (record) => ({
                    name: record.sku,
                    checked: selectedSKUs.includes(record.sku),
                  }),
                  selectedRowKeys: selectedSKUs,
                  onSelectAll: (selected) =>{
                    setSelectRow(true);
                    selected
                      ? setSelectedSKUs(inventory.map((i) => i.sku))
                      : setSelectedSKUs([])
                    },
                }}
                columns={columns}
                dataSource={inventory}
                rowKey={(record, index) => record.sku}
                loading={loading}
              />
              <div className="mt-4 mb-8 pt-4 border-t flex items-center justify-end">
                <ExportButton
                  variant="outlined"
                  disabled={true}
                  style={{ marginRight: 16 }}
                >
                  Previous
                </ExportButton>
                <PrimaryButton variant="outlined" onClick={() => setStep(1)}>
                  Next
                </PrimaryButton>
              </div>
            </React.Fragment>
          )}
          {step === 1 && (
            <React.Fragment>
              <div className="text-xl text-ErieBlack my-8">Set Audience</div>
              <div className="flex items-center justify-start mt-8">
                {/* <Select
                  size="large"
                  style={{ width: 250 }}
                  mode="multiple"
                  maxTagCount="responsive"
                  showArrow
                  placeholder="Prior Feedback"
                  value={priorFeedback || undefined}
                  onChange={(value) => setPriorFeedback(value)}
                  className="bg-GhostWhite rounded mr-4"
                >
                  {feedbacks.map((feedback, index) => (
                    <Select.Option value={feedback.value} key={index}>
                      {feedback.key}
                    </Select.Option>
                  ))}
                </Select> */}
                <Select
                  size="large"
                  style={{ width: 300 }}
                  mode="multiple"
                  maxTagCount="responsive"
                  showArrow
                  placeholder="Customer Type"
                  value={customerType || undefined}
                  onChange={(value) => setCustomerType(value)}
                  className="bg-GhostWhite rounded mr-4"
                >
                  {customer_types.map((customer_type, index) => (
                    <Select.Option value={customer_type.value} key={index}>
                      {customer_type.key}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  size="large"
                  style={{ width: 450 }}
                  mode="multiple"
                  maxTagCount="responsive"
                  showArrow
                  placeholder="Location"
                  value={location || undefined}
                  onChange={(value) => setLocation(value)}
                  className="bg-GhostWhite rounded mr-4"
                >
                  {location_data.map((state, index) => (
                    <Select.Option value={state} key={index}>
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="text-xl text-ErieBlack my-8">Set Date</div>
              <div className="flex items-center justify-start mt-8">
                <div className="text-base text-ErieBlack mr-4">
                  All future orders are covered. Do you also want to send emails
                  for your past 30 days order?
                </div>
                <Switch
                  checked={oldCustomers}
                  onChange={(checked) => setOldCustomers(checked)}
                />
              </div>
              <div className="mt-16 mb-8 pt-4 border-t flex items-center justify-end">
                <ExportButton
                  variant="outlined"
                  onClick={() => setStep(0)}
                  style={{ marginRight: 16 }}
                >
                  Previous
                </ExportButton>
                <PrimaryButton
                  variant="outlined"
                  onClick={() => handleSubmit()}
                >
                  Start Campaign
                </PrimaryButton>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateCampaign;
