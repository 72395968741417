import { Button, Checkbox, Divider, Menu } from "antd";
import React from "react";

const CheckboxGroup = Checkbox.Group;
export default function TableFilterDropdown({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filters,
}) {
  return (
    <div>
      <>
        <div className="pl-3 pt-3">
          <Checkbox
            indeterminate={
              selectedKeys.length && selectedKeys.length !== filters.length
            }
            onChange={() => {
              if (selectedKeys.length === filters.length) {
                setSelectedKeys([]);
              } else {
                setSelectedKeys(
                  filters.map((data) => {
                    return data.value;
                  })
                );
              }
            }}
            checked={selectedKeys.length === filters.length}
          ></Checkbox>
        </div>
        <Divider className="mt-2 mb-0" />
        <CheckboxGroup value={selectedKeys} onChange={setSelectedKeys}>
          <Menu prefixCls={`ant-dropdown-menu`}>
            {filters.map((data, dataIndex) => {
              data.label = data.text;
              return (
                <Menu.Item key={`Checkbox-Filter-${dataIndex}`}>
                  <Checkbox value={data.value}>{data.label}</Checkbox>
                </Menu.Item>
              );
            })}
          </Menu>
        </CheckboxGroup>
      </>
      {/* {menu} */}
      <div className="flex items-center justify-between border-t py-2 pl-1 pr-2">
        <div className="flex">
          <Button
            type="link"
            size={"small"}
            onClick={() => {
              clearFilters();
            }}
            disabled={!selectedKeys.length}
          >
            Reset
          </Button>
        </div>
        <div>
          <Button
            // className="bg-primary text-white rounded p-2 pl-3 text-xs cursor-pointer"
            onClick={() => {
              confirm();
            }}
            size={"small"}
            type="primary"
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
}
