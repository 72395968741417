import React, { useState, useEffect } from "react";
import StateChartPage from "./stateMap";
import StateChartPageTwo from "./stateMapTwo";
import StateChartPageThree from "./stateMapThree";
import { PostValues } from "../../utils/api-client";
import { Spin, Button, Table, message, Tooltip } from "antd";
import MapChartIndia from "./mapChartIndia";
import MapChartIndiaTwo from "./mapChartIndiaTwo";
import MapChartIndiaThree from "./mapChartIndiaThree";
import { ArrowLeftOutlined } from "@ant-design/icons";
import MapChartIndiaFour from "./mapChartIndiaFour";
import StateChartPageFour from "./stateMapFour";
import ExportButton from "../../components/Styles/ExportButton.js";
import MaterialIcon from "react-google-material-icons";

function MapChartPage(props) {
  const RefColumns = [
    {
      title: "State",
      align: "left",
      key: "name",
      hidden: "true",
    },
    {
      title: "Total Orders",
      align: "left",
      key: "total_orders",
      hidden: "true",
    },
    {
      title: "Pending",
      align: "left",
      key: "pending",
      hidden: "true",
    },
    // {
    //   title: "Amount",
    //   align: "left",
    //   key: "amount",
    //   hidden: "true",
    // },
    {
      title: "Processing",
      align: "left",
      key: "processing",
      hidden: "true",
    },
    {
      title: "Delivered",
      align: "left",
      key: "delivered",
      hidden: "true",
    },
    {
      title: "Shipped",
      align: "left",
      key: "shipped",
      hidden: "true",
    },
    {
      title: "Cancelled",
      align: "left",
      key: "cancelled",
      hidden: "true",
    },
    {
      title: "return",
      align: "left",
      key: "return",
      hidden: "true",
    },
  ];

  const [arrayDataTableSta, setArrayTableSta] = useState([]);
  const [arrayDataTableDis, setArrayTableDis] = useState([]);
  const [arrayDataTable, setArrayDataTable] = useState([]);
  const [columns, setColumns] = React.useState(RefColumns);
  const [stateName, setStateName] = React.useState("");
  const [stateRes, setStateRes] = React.useState("");
  const [cardRes, setCardRes] = React.useState("");
  const [stateResCard, setStateResCard] = React.useState("");
  const [stateIsLoading, setStateIsLoading] = useState(false);
  const [stateShow, setStateShow] = useState(false);
  const [tableTitleName, setTableTitleName] = useState("State");
  const [nullData, setNullData] = useState(0);
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const [mapType, setmapType] = React.useState("state");
  const [tableName, setTableName] = React.useState("State Table");

  const [exportStateName, setExportStateName] = React.useState("");
  const [isExportProductLoading, setIsExportProductLoading] =
    React.useState(false);
  const columnsState = [
    {
      title: tableTitleName,
      key: "name",
      render: (row) => <> 
      <Tooltip title={row.name}>
      {row.name}
      </Tooltip>
      </>,
    },
    {
      title: "Qty Sold",
      key: "qtySold",
      defaultSortOrder: "descend",
      render: (row) => <> {row.qtySold}</>,
      sorter: {
        compare: (a, b) => a.qtySold - b.qtySold,
      },
    },
    {
      title: "Return Qty",
      key: "returnQtySum",
      render: (row) => (
        <>
          {" "}
          {row.returnQtySum.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            //style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.returnQtySum - b.returnQtySum,
      },
    },
    {
      title: "Sale Amount",
      key: "saleAmount",
      render: (row) => (
        <>
          {" "}
          {row.saleAmount.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.saleAmount - b.saleAmount,
      },
    },
    {
      title: "Ads Spent",
      key: "adsSpentSum",
      render: (row) => (
        <>
          {" "}
          {row.adsSpentSum.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.adsSpentSum - b.adsSpentSum,
      },
    },
    {
      title: "Ship Cost (Only Settled)",
      key: "settledShippingSum",
      render: (row) => (
        <>
          {" "}
          {row.settledShippingSum.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.settledShippingSum - b.settledShippingSum,
      },
    },
    {
      title: "Profit",
      key: "profit",
      render: (row) => (
        <>
          {" "}
          {row.profit.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.profit - b.profit,
      },
    },
    {
      title: "Profit Margin",
      key: "profitMargin",
      render: (row) => (
        <>
          {" "}
          {row.profitMargin.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            //style: "currency",
            currency: "INR",
          }) + "%"}
        </>
      ),
      sorter: {
        compare: (a, b) => a.profitMargin - b.profitMargin,
      },
    },
  ];

  const columnsTable = [
    // {
    //   title: "Channel",
    //   key: "channel",
    //   render: (row) =>
    //   props.tableLoader ? (
    //     <div className="item">
    //       <div className="item-image" />
    //     </div>
    //   ) : row.channel === "Amazon-India" ? (
    //     <div className="amazon-logo-small" />
    //   ) : row.channel === "Flipkart" ? (
    //     <div className="flipkart-logo-small" />
    //   ) : (
    //     "-"
    //   ),
    // },
    {
      title: "SKU",
      key: "SKU",
      field: "SKU",
      render: (row) => <> 
    <Tooltip title={row?.SKU}>
      {row?.SKU}
      </Tooltip>
      </>,
    },
    {
      title: "Qty Sold",
      key: "QuantityShippedSum",
      defaultSortOrder: "descend",
      field: "QuantityShippedSum",
      render: (row) => (
        <>
          {" "}
          {row.QuantityShippedSum.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            //  style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.QuantityShippedSum - b.QuantityShippedSum,
      },
    },
    {
      title: "Return Qty",
      key: "ReturnQtySum",
      field: "ReturnQtySum",
      render: (row) => <> {row.ReturnQtySum}</>,
      sorter: {
        compare: (a, b) => a.ReturnQtySum - b.ReturnQtySum,
      },
    },
    {
      title: "Ads Spent",
      key: "AdsSpentSum",
      render: (row) => (
        <>
          {" "}
          {row.AdsSpentSum.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.AdsSpentSum - b.AdsSpentSum,
      },
    },
    {
      title: "Ship Cost (Only Settled)",
      key: "SettledShipping",
      field: "SettledShipping",
      render: (row) => (
        <>
          {" "}
          {row?.SettledShipping?.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.SettledShipping - b.SettledShipping,
      },
    },
    {
      title: "Profit",
      key: "Profit",
      field: "Profit",
      render: (row) => (
        <>
          {" "}
          {row.Profit.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "INR",
          })}
        </>
      ),
      sorter: {
        compare: (a, b) => a.Profit - b.Profit,
      },
    },
    {
      title: "Profit Margin",
      key: "ProfitMargin",
      field: "ProfitMargin",
      render: (row) => (
        <>
          {" "}
          {row?.ProfitMargin?.toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            //style: "currency",
            currency: "INR",
          }) + "%"}
        </>
      ),
      sorter: {
        compare: (a, b) => a.ProfitMargin - b.ProfitMargin,
      },
    },
  ];
  useEffect(() => {
    if (props.isLoading) {
      setStateRes("");
    }
  }, [props.isLoading]);

  useEffect(() => {
    if (props?.resData) {
      const arr = Object.entries(props?.resData?.total_state).map(
        ([key, value]) => ({
          name: key,
          qtySold: value[0].QuantityShippedSum,
          saleAmount: value[0].WithInvoice,
          profit: value[0].Profit,
          profitMargin: value[0].ProfitMargin,
          returnQtySum: value[0].ReturnQtySum,
          adsSpentSum: value[0].AdsSpentSum,
          settledShippingSum: value[0].SettledShippingSum,
        })
      );
      Object.entries(props?.resData?.total_state).map(
        ([key, value]) =>
          key == "null" && setNullData(value[0].QuantityShippedSum)
      );
      setStateResCard(props?.resData);
      setCardRes(props?.resData);
      setArrayTableSta(arr);
      setArrayDataTable(arr);
    }
  }, [props?.resData]);
  const getStateApi = async (requestData) => {
    let response = await PostValues("StateBasedApi", requestData);
    return response;
  };

  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };

  const ExportAllData = async (dataVal) => {
    let exportRequestData = {
      params: {
        channels: props.selectedMarketplaces.map((mp) => mp.name),
        fromDate: props.fromDate,
        toDate: props.toDate,
        region: dataVal,
        map: mapType,
        state_name: exportStateName.toUpperCase(),
      },
      reportType: "map",
    };
    if (dataVal == "product") {
      setIsExportProductLoading(true);
    } else {
      setIsExportLoading(true);
    }
    try {
      const data = await getExportApi(exportRequestData);
      if (data.status) {
        setIsExportLoading(false);
        setIsExportProductLoading(false);
        message.success(
          "The export is being processed, please check the Downloads page ",
          10
        );
      } else {
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };
  const stateFuncApiCall = async (stName) => {
    setmapType("district");
    setTableName("District Table");
    setExportStateName(stName);
    props.TableApiState(stName);
    setStateName(stName);
    setStateIsLoading(true);
    let requestData = {
      channels: props.selectedMarketplaces.map((mp) => mp.name),
      filters: {
        fromDate: props.fromDate,
        toDate: props.toDate,
        state: stName.toUpperCase(),
      },
    };
    let dataRes = await getStateApi(requestData);
    if (dataRes.data) {
      setTableTitleName("Districts");
      setStateIsLoading(false);
      setStateRes(dataRes.data);
      setStateShow(true);
      setCardRes(dataRes.data);
      const arr = Object.entries(dataRes.data.total_District).map(
        ([key, value]) => ({
          name: key,
          qtySold: value[0].QuantityShippedSum,
          saleAmount: value[0].WithInvoice,
          profit: value[0].Profit,
          profitMargin: value[0].ProfitMargin,
          returnQtySum: value[0].ReturnQtySum,
          adsSpentSum: value[0].AdsSpentSum,
          settledShippingSum: value[0].SettledShippingSum,
        })
      );
      Object.entries(props?.resData?.total_state).map(
        ([key, value]) =>
          key == "null" && setNullData(value[0].QuantityShippedSum)
      );
      setArrayTableDis(arr);
      setArrayDataTable(arr);
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    props.TableApi(pagination, filters, sorter);
  };

  return (
    <>
      <div class="flex justify-end px-5 top-0 font-medium -mt-6 mr-2 bg-sky-300">
        *No. of qty sold without pincode info: {nullData}
      </div>

      <div class="flex px-5 gap-3 h-80">
        <div
          class="w-1/4 h-full box-border border-2 shadow-md rounded-md pb-2"
          style={{ backgroundColor: "antiquewhite" }}
        >
          <Spin spinning={stateIsLoading}>
            {stateShow ? (
              <>
                <div class="rounded-tl-lg  p-1 clearfix">
                  <h5 class="uppercase float-left font-bold"> Qty Sold </h5>
                  <div class="rounded-full circle bg-green float-right uppercase font-bold">
                    {stateName}
                    <Button
                      type="primary"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => {
                        props.backTableApiCall();
                        setmapType("state");
                        setTableName("State Table");
                        setExportStateName("");
                        setTableTitleName("State");
                        setStateShow(false);
                        setArrayDataTable(arrayDataTableSta);
                        setCardRes(stateResCard);
                      }}
                    ></Button>
                  </div>
                </div>
                <StateChartPage
                  stateIsLoading={stateIsLoading}
                  resData={stateRes}
                  selectedState={stateName}
                />
              </>
            ) : (
              <>
                <div class="rounded-tl-lg  p-1 clearfix">
                  <h5
                    class="uppercase float-left font-bold"
                    style={{ color: "#08306b" }}
                  >
                    {" "}
                    Qty Sold
                  </h5>
                </div>
                <MapChartIndia
                  isLoading={props.isLoading}
                  setTooltipContent={props.setTooltipContent}
                  setTooltipContentState={props.setTooltipContentState}
                  resData={props.resData}
                  stateFuncApiCall={stateFuncApiCall}
                />
              </>
            )}
          </Spin>
        </div>
        <div
          class="w-1/4 h-full box-border border-2 shadow-md rounded-md"
          style={{ backgroundColor: "antiquewhite" }}
        >
          <Spin spinning={stateIsLoading}>
            {stateShow ? (
              <>
                <div class="rounded-tl-lg  p-1 clearfix">
                  <div class="rounded-full circle bg-green float-right uppercase font-bold">
                    {stateName}
                    <Button
                      type="primary"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => {
                        setmapType("state");
                        setTableName("State Table");
                        setExportStateName("");
                        props.backTableApiCall();
                        setTableTitleName("State");
                        setStateShow(false);
                        setArrayDataTable(arrayDataTableSta);
                        setCardRes(stateResCard);
                      }}
                    ></Button>
                  </div>
                  <h5 class="uppercase float-left font-bold"> RETURN </h5>
                </div>
                <StateChartPageThree
                  stateIsLoading={stateIsLoading}
                  resData={stateRes}
                  selectedState={stateName}
                />
              </>
            ) : (
              <>
                <div class="rounded-tl-lg  p-1 clearfix">
                  <h5
                    class="uppercase float-left font-bold"
                    style={{ color: "#08306b" }}
                  >
                    Return
                  </h5>
                  {/* <div class="rounded-full circle bg-green float-right">
                    <Select defaultValue="return" style={{ width: 120 }} onChange={handleChange}>
                      <Option value="qtySold">Qty Sold</Option>
                      <Option value="profit">Profit</Option>
                      <Option value="return">Return</Option>
                    </Select>
                  </div> */}
                </div>
                <MapChartIndiaThree
                  isLoading={props.isLoading}
                  setTooltipContent={props.setTooltipContent}
                  setTooltipContentState={props.setTooltipContentState}
                  resDataThree={props.resDataThree}
                  stateFuncApiCall={stateFuncApiCall}
                />
              </>
            )}
          </Spin>
        </div>
        <div
          class="w-1/4 h-full box-border border-2 shadow-md rounded-md"
          style={{ backgroundColor: "antiquewhite" }}
        >
          <Spin spinning={stateIsLoading}>
            {stateShow ? (
              <>
                <div class="rounded-tl-lg  p-1 clearfix">
                  <div class="rounded-full circle bg-green float-right uppercase font-bold">
                    {stateName}
                    <Button
                      type="primary"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => {
                        setmapType("state");
                        setTableName("State Table");
                        setExportStateName("");
                        props.backTableApiCall();
                        setTableTitleName("State");
                        setStateShow(false);
                        setArrayDataTable(arrayDataTableSta);
                        setCardRes(stateResCard);
                      }}
                    ></Button>
                  </div>
                  <h5 class="uppercase float-left font-bold">
                    {" "}
                    Shipping COST{" "}
                  </h5>
                  (*Only Settled)
                </div>
                <StateChartPageFour
                  stateIsLoading={stateIsLoading}
                  resData={stateRes}
                  selectedState={stateName}
                />
              </>
            ) : (
              <>
                <div class="rounded-tl-lg  p-1 clearfix">
                  <h5
                    class="uppercase float-left font-bold"
                    style={{ color: "#08306b" }}
                  >
                    Shipping Cost
                  </h5>
                  <b>(*Only Settled)</b>
                  {/* <div class="rounded-full circle bg-green float-right">
                    <Select defaultValue="return" style={{ width: 120 }} onChange={handleChange}>
                      <Option value="qtySold">Qty Sold</Option>
                      <Option value="profit">Profit</Option>
                      <Option value="return">Return</Option>
                    </Select>
                  </div> */}
                </div>
                <MapChartIndiaFour
                  isLoading={props.isLoading}
                  setTooltipContent={props.setTooltipContent}
                  setTooltipContentState={props.setTooltipContentState}
                  resDataThree={props.resDataThree}
                  stateFuncApiCall={stateFuncApiCall}
                />
              </>
            )}
          </Spin>
        </div>
        <div
          class="w-1/4 h-full box-border border-2 shadow-md rounded-md"
          style={{ backgroundColor: "antiquewhite" }}
        >
          <Spin spinning={stateIsLoading}>
            {stateShow ? (
              <>
                <div class="rounded-tl-lg  p-1 clearfix">
                  <div class="rounded-full circle bg-green float-right uppercase font-bold">
                    {stateName}
                    <Button
                      type="primary"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => {
                        setmapType("state");
                        setTableName("State Table");
                        setExportStateName("");
                        props.backTableApiCall();
                        setTableTitleName("State");
                        setStateShow(false);
                        setArrayDataTable(arrayDataTableSta);
                        setCardRes(stateResCard);
                      }}
                    ></Button>
                  </div>
                  <h5 class="uppercase float-left font-bold"> Profit </h5>
                </div>
                <StateChartPageTwo
                  stateIsLoading={stateIsLoading}
                  resData={stateRes}
                  selectedState={stateName}
                />
              </>
            ) : (
              <>
                <div class="rounded-tl-lg  p-1 clearfix">
                  <h5
                    class="uppercase float-left font-bold"
                    style={{ color: "#08306b" }}
                  >
                    {" "}
                    Profit
                  </h5>
                  {/* <div class="rounded-full circle bg-green float-right">
                    <Select defaultValue="profit" style={{ width: 120 }} onChange={handleChange}>
                      <Option value="qtySold">Qty Sold</Option>
                      <Option value="profit">Profit</Option>
                      <Option value="return">Return</Option>
                    </Select>
                  </div> */}
                </div>
                <MapChartIndiaTwo
                  isLoading={props.isLoading}
                  setTooltipContent={props.setTooltipContent}
                  setTooltipContentState={props.setTooltipContentState}
                  resDataTwo={props.resDataTwo}
                  stateFuncApiCall={stateFuncApiCall}
                />
              </>
            )}
          </Spin>
        </div>
      </div>
      <div class="mt-6"></div>

      <div class="px-5 grid grid-cols-6 gap-4">
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#588BF7" }}
        >
          <p className="text-white font-semibold">QTY Sold</p>
          <p className="text-white">
            {cardRes?.totals?.QuantityShippedSum &&
              cardRes?.totals?.QuantityShippedSum?.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                // style: "currency",
                currency: "INR",
              })}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#588BF7" }}
        >
          <p className="text-white font-semibold">Sale Amount</p>
          <p className="text-white">
            {cardRes?.totals?.WithInvoice &&
              cardRes?.totals?.WithInvoice?.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: "INR",
              })}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#38B2AC" }}
        >
          <p className="text-white font-semibold">Return Quantity</p>
          <p className="text-white">
            {cardRes?.totals?.ReturnQtySum &&
              cardRes?.totals?.ReturnQtySum?.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                //style: "currency",
                currency: "INR",
              })}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#38B2AC" }}
        >
          <p className="text-white font-semibold">Shipping Fees</p>
          <p className="text-white">
            {cardRes?.totals?.SettledShippingSum &&
              cardRes?.totals?.SettledShippingSum?.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: "INR",
              })}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#9F7AEA" }}
        >
          <p className="text-white font-semibold">Profit Making SKUs #</p>
          <p className="text-white">
            {cardRes?.Loss_or_Profit?.ProfitMakingSKUSCount &&
              cardRes?.Loss_or_Profit?.ProfitMakingSKUSCount?.toLocaleString(
                "en-IN",
                {
                  maximumFractionDigits: 0,
                  // style: "currency",
                  currency: "INR",
                }
              )}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#9F7AEA" }}
        >
          <p className="text-white font-semibold">Profit Making SKUs (INR)</p>
          <p className="text-white">
            {cardRes?.Loss_or_Profit?.ProfitMakingAmount &&
              cardRes?.Loss_or_Profit?.ProfitMakingAmount?.toLocaleString(
                "en-IN",
                {
                  maximumFractionDigits: 0,
                  style: "currency",
                  currency: "INR",
                }
              )}
          </p>
        </div>
        <div
          className="mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#588BF7" }}
        >
          <p className="text-white font-semibold">Profit</p>
          <p className="text-white">
            {cardRes?.totals?.ProfitTotal &&
              cardRes?.totals?.ProfitTotal?.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: "INR",
              })}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#588BF7" }}
        >
          <p className="text-white font-semibold">Profit Margin</p>
          <p className="text-white">
            {cardRes?.totals?.MarginSum &&
              cardRes?.totals?.MarginSum?.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                // style: "currency",
                currency: "INR",
              }) + "%"}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#38B2AC" }}
        >
          <p className="text-white font-semibold">Ads Spent</p>
          <p className="text-white">
            {cardRes?.totals?.AdsSpentSum &&
              cardRes?.totals?.AdsSpentSum?.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: "INR",
              })}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#38B2AC" }}
        >
          <p className="text-white font-semibold">ROI</p>
          <p className="text-white">
            {cardRes?.totals?.ROISum &&
              cardRes?.totals?.ROISum?.toLocaleString("en-IN", {
                maximumFractionDigits: 0,
                // style: "currency",
                currency: "INR",
              }) + "%"}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#9F7AEA" }}
        >
          <p className="text-white font-semibold">Loss Making SKUs #</p>
          <p className="text-white">
            {cardRes?.Loss_or_Profit?.LossMakingSKUSCount &&
              cardRes?.Loss_or_Profit?.LossMakingSKUSCount?.toLocaleString(
                "en-IN",
                {
                  maximumFractionDigits: 0,
                  // style: "currency",
                  currency: "INR",
                }
              )}
          </p>
        </div>
        <div
          className="rounded-xl p-3 flex justify-between flex-col"
          style={{ backgroundColor: "#9F7AEA" }}
        >
          <p className="text-white font-semibold">Loss Making SKUs (INR)</p>
          <p className="text-white">
            {cardRes?.Loss_or_Profit?.LossMakingAmount &&
              cardRes?.Loss_or_Profit?.LossMakingAmount?.toLocaleString(
                "en-IN",
                {
                  maximumFractionDigits: 0,
                  style: "currency",
                  currency: "INR",
                }
              )}
          </p>
        </div>
      </div>
      <div class="mt-3"></div>

      <div class="flex px-5 gap-6">
        <div class="w-1/2 box-border border-2 shadow-md rounded-md">
          <div className="grid grid-cols-2 gap-4">
            <div class=" flex justify-end pt-1">
              <div className="text-base	font-semibold	">Product Table</div>
              <div></div>
            </div>
            <div className=" flex justify-end p-1 cursor-pointer" onClick={() => ExportAllData("product")}>
              <Spin spinning={isExportProductLoading}>
                {/* <ExportButton
                  variant="outlined"
                  onClick={() => ExportAllData("product")}
                > */}
                  <MaterialIcon icon="download" />
                {/* </ExportButton> */}
              </Spin>
            </div>
          </div>

          <Spin spinning={props.tableLoader}>
            <Table
              size="small"
              columns={columnsTable}
              dataSource={props?.tableData}
              onChange={onChange}
              pagination={{
                pageSize: 5,
                total: props?.pageSize,
                showSizeChanger: false,
              }}
            />
          </Spin>
        </div>
        <div class="w-1/2 box-border border-2 shadow-md rounded-md">
          <div className="grid grid-cols-2 gap-4">
            <div className=" flex justify-end pt-1">
              <div className="text-base	font-semibold	">{tableName}</div>
              <div></div>
            </div>
            <div className=" flex justify-end p-1 cursor-pointer" onClick={() => ExportAllData("state")}>
              <Spin spinning={isExportLoading}>
                {/* <ExportButton
                  variant="outlined"
                  onClick={() => ExportAllData("state")}
                > */}
                  <MaterialIcon icon="download" />
                {/* </ExportButton> */}
              </Spin>
            </div>
          </div>
          <Spin spinning={stateIsLoading}>
            <Table
              size="small"
              columns={columnsState}
              dataSource={arrayDataTable}
              pagination={{ pageSize: 5 }}
            />
          </Spin>
        </div>
      </div>

      {/* <div class="container mx-auto px-3 grid grid-cols-2 gap-3">
        <Spin spinning={stateIsLoading}>

          <div class="col-span-1 shadow-md rounded-md">
            <div className="w-full flex flex-nowrap tablet:flex-wrap gap-5">
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#588BF7" }}
              >
                <p className="text-white font-semibold">QTY Sold</p>
                <p className="text-white">
                  {cardRes?.totals?.QuantityShippedSum &&
                    cardRes?.totals?.QuantityShippedSum?.toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 0,
                        // style: "currency",
                        currency: "INR",
                      }
                    )}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#588BF7" }}
              >
                <p className="text-white font-semibold">Sale Amount</p>
                <p className="text-white">
                  {cardRes?.totals?.WithInvoice &&
                    cardRes?.totals?.WithInvoice?.toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "INR",
                    })}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#588BF7" }}
              >
                <p className="text-white font-semibold">Profit</p>
                <p className="text-white">
                  {cardRes?.totals?.ProfitTotal &&
                    cardRes?.totals?.ProfitTotal?.toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "INR",
                    })}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#588BF7" }}
              >
                <p className="text-white font-semibold">Profit Margin</p>
                <p className="text-white">
                  {cardRes?.totals?.MarginSum &&
                    cardRes?.totals?.MarginSum?.toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                      // style: "currency",
                      currency: "INR",
                    }) + "%"}
                </p>
              </div>
            </div>

            <div class="mt-3"></div>
            <div className="w-full flex flex-nowrap tablet:flex-wrap gap-5">
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#38B2AC" }}
              >
                <p className="text-white font-semibold">Settled Amount</p>
                <p className="text-white">
                  {cardRes?.totals?.SettledAmountTotalSum &&
                    cardRes?.totals?.SettledAmountTotalSum?.toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: "INR",
                      }
                    )}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#38B2AC" }}
              >
                <p className="text-white font-semibold">Shipping Fees</p>
                <p className="text-white">
                  {cardRes?.totals?.SettledShippingSum &&
                    cardRes?.totals?.SettledShippingSum?.toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: "INR",
                      }
                    )}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#38B2AC" }}
              >
                <p className="text-white font-semibold">TACOS</p>
                <p className="text-white">
                  {cardRes?.totals?.ACOTSSum &&
                    cardRes?.totals?.ACOTSSum?.toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                      // style: "currency",
                      currency: "INR",
                    }) + '%'}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#38B2AC" }}
              >
                <p className="text-white font-semibold">ROI</p>
                <p className="text-white">
                  {cardRes?.totals?.ROISum &&
                    cardRes?.totals?.ROISum?.toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                      // style: "currency",
                      currency: "INR",
                    }) + '%'}
                </p>
              </div>
            </div>

            <div class="mt-3"></div>
            <div className="w-full flex flex-nowrap tablet:flex-wrap gap-5">
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#9F7AEA" }}
              >
                <p className="text-white font-semibold">Profit Making SKUs #</p>
                <p className="text-white">
                  {cardRes?.Loss_or_Profit?.ProfitMakingSKUSCount &&
                    cardRes?.Loss_or_Profit?.ProfitMakingSKUSCount?.toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 0,
                        // style: "currency",
                        currency: "INR",
                      }
                    )}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#9F7AEA" }}
              >
                <p className="text-white font-semibold">
                  Profit Making SKUs (INR)
                </p>
                <p className="text-white">
                  {cardRes?.Loss_or_Profit?.ProfitMakingAmount &&
                    cardRes?.Loss_or_Profit?.ProfitMakingAmount?.toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: "INR",
                      }
                    )}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#9F7AEA" }}
              >
                <p className="text-white font-semibold">Loss Making SKUs #</p>
                <p className="text-white">
                  {cardRes?.Loss_or_Profit?.LossMakingSKUSCount &&
                    cardRes?.Loss_or_Profit?.LossMakingSKUSCount?.toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 0,
                        // style: "currency",
                        currency: "INR",
                      }
                    )}
                </p>
              </div>
              <div
                className="w-3/12 tablet:w-48 mobile:w-45 rounded-xl p-3 flex justify-between flex-col"
                style={{ backgroundColor: "#9F7AEA" }}
              >
                <p className="text-white font-semibold">
                  Loss Making SKUs (INR)
                </p>
                <p className="text-white">
                  {cardRes?.Loss_or_Profit?.LossMakingAmount &&
                    cardRes?.Loss_or_Profit?.LossMakingAmount?.toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 0,
                        style: "currency",
                        currency: "INR",
                      }
                    )}
                </p>
              </div>
            </div>
          </div>
        </Spin>
        <div class="col-span-1 box-border border-2 shadow-md rounded-md">
          <Spin spinning={stateIsLoading}>
            <Table
              columns={columnsState}
              dataSource={arrayDataTable}
              onChange={onChange}
              pagination={{ pageSize: 5 }}
            />
          </Spin>
        </div>
      </div> */}
    </>
  );
}

export default MapChartPage;
