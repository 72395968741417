import { Card } from "antd";
import React from "react";
import ProfitabilityTable from "./profitabilityTable";
import { ProfitPieChart } from "./profitPieChart";
import ProfitWaterfall from "./waterFallChart";

function ProfitSummary(props) {
  // let taxValue = JSON.parse(localStorage.getItem("toggleEvent"));

  const setCardValue = (val, type) => {
    if (props.isLoading) return;
    else props.filterTabvalue(val, type);
  };

  return (
    <>
      <div className="mt-5">
        <div
          className={`${
            props.isLoading ? "cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <Card
            bgcolor="white"
            className="relative rounded-lg border-black mt-5"
          >
            <div className="mt-5 w-full">
              <div className="flex justify-start">
                <div
                  className="absolute rounded-lg"
                  style={{
                    background: "white",
                    padding: "2px 16px",
                    top: "-16px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {"INSIGHTS"}
                </div>
              </div>
              <div className="w-full -mt-10 flex flex-wrap justify-between">
                <div className="w-1/4 -mt-5 tablet:w-1/2 justify-between flex-wrap ">
                  <div
                    onClick={() => setCardValue("merchant")}
                    className="w-full"
                  >
                    <div className="grid grid-cols-1 gap-4 tablet:grid-cols-1 mt-10 gap-10 flex-row ">
                      {[
                        {
                          title: "Profit ",
                          profit: props.profitCard.Merchant?.[0]?.Profit || 0,
                          profitMargin:
                            props.profitCard.Merchant?.[0]?.ProfitMargin || 0,
                          quantityShipped:
                            props.profitCard.Merchant?.[0]?.QuantityShipped ||
                            0,
                          sales: props.profitCard.Merchant?.[0]?.Sales || 0,
                          color: "#dceff5",
                        },
                      ].map((fee, feeIndex) => {
                        return (
                          <div
                            className="flex-shrink-0 w-11/12 rounded-3xl shadow-2xl relative overflow-hidden max-w-xs w-1/4 tablet:w-48 tablet:pointer-events-none tablet:cursor-default mobile:w-45"
                            style={{ backgroundColor: "#588BF7" }}
                          >
                            <svg
                              className="absolute bottom-0 left-0 mb-8"
                              viewBox="0 0 375 283"
                              fill="none"
                              style={{
                                transform: "scale(1.5)",
                                opacity: "0.1",
                              }}
                            >
                              <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                              />
                              <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                              />
                            </svg>
                            <div className="relative pt-5 px-10 flex items-center justify-center">
                              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <span class="block font-semibold text-sm">
                                MERCHANT
                              </span>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <div class="flex justify-between">
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {`${Math.trunc(
                                      fee.quantityShipped
                                    ).toLocaleString()}`}
                                  </span>
                                )}
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {fee.sales.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </span>
                                )}
                              </div>
                              <div class="flex justify-between">
                                <span className="block opacity-75 text-sm -mb-1">
                                  Quantity Shipped
                                </span>
                                <span className="block opacity-75 text-sm -mb-1">
                                  Sales
                                </span>
                              </div>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <div class="flex justify-between">
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {fee.profit.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </span>
                                )}
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {`${Math.trunc(
                                      fee.profitMargin
                                    ).toLocaleString()}%`}
                                  </span>
                                )}
                              </div>
                              <div class="flex justify-between">
                                <span className="block opacity-75 text-sm -mb-1">
                                  Profit
                                </span>
                                <span className="block opacity-75 text-sm -mb-1">
                                  Profit Margin
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="w-1/4 -mt-5 tablet:w-1/2 justify-between flex-wrap  ">
                  <div
                    onClick={() => setCardValue("smart")}
                    className=" w-full"
                  >
                    <div className="grid grid-cols-1 gap-4 tablet:grid-cols-1 mt-10 gap-10 flex-row ">
                      {[
                        {
                          title: "Profit ",
                          profit: props.profitCard.Smart?.[0]?.Profit || 0,
                          profitMargin:
                            props.profitCard.Smart?.[0]?.ProfitMargin || 0,
                          quantityShipped:
                            props.profitCard.Smart?.[0]?.QuantityShipped || 0,
                          sales: props.profitCard.Smart?.[0]?.Sales || 0,
                          color: "#dceff5",
                        },
                      ].map((fee, feeIndex) => {
                        return (
                          <div
                            className="flex-shrink-0 w-11/12 rounded-3xl shadow-2xl relative overflow-hidden max-w-xs w-1/4 tablet:w-48 tablet:pointer-events-none tablet:cursor-default mobile:w-45"
                            style={{ backgroundColor: "#38B2AC" }}
                          >
                            <svg
                              className="absolute bottom-0 left-0 mb-8"
                              viewBox="0 0 375 283"
                              fill="none"
                              style={{
                                transform: "scale(1.5)",
                                opacity: "0.1",
                              }}
                            >
                              <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                              />
                              <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                              />
                            </svg>
                            <div className="relative pt-5 px-10 flex items-center justify-center">
                              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <span class="block font-semibold text-sm">
                                SMART/FLEX <span className="font-normal text-xs italic">(Without Flex Estimated Orders)</span>
                              </span>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <div class="flex justify-between">
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {`${Math.trunc(
                                      fee.quantityShipped
                                    ).toLocaleString()}`}
                                  </span>
                                )}
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {fee.sales.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </span>
                                )}
                              </div>
                              <div class="flex justify-between">
                                <span className="block opacity-75 text-sm -mb-1">
                                  Quantity Shipped
                                </span>
                                <span className="block opacity-75 text-sm -mb-1">
                                  Sales
                                </span>
                              </div>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <div class="flex justify-between">
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {fee.profit.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </span>
                                )}
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {`${Math.trunc(
                                      fee.profitMargin
                                    ).toLocaleString()}%`}
                                  </span>
                                )}
                              </div>
                              <div class="flex justify-between">
                                <span className="block opacity-75 text-sm -mb-1">
                                  Profit
                                </span>
                                <span className="block opacity-75 text-sm -mb-1">
                                  Profit Margin
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="w-1/4 -mt-5 tablet:w-1/2 justify-between flex-wrap  ">
                  <div onClick={() => setCardValue("fba")} className="w-full">
                    <div className="grid grid-cols-1 gap-4 tablet:grid-cols-1 mt-10 gap-10 flex-row">
                      {[
                        {
                          title: "Profit ",
                          profit: props.profitCard.AFN?.[0]?.Profit || 0,
                          profitMargin:
                            props.profitCard.AFN?.[0]?.ProfitMargin || 0,
                          quantityShipped:
                            props.profitCard.AFN?.[0]?.QuantityShipped || 0,
                          sales: props.profitCard.AFN?.[0]?.Sales || 0,
                          color: "#dceff5",
                        },
                      ].map((fee, feeIndex) => {
                        return (
                          <div
                            className="flex-shrink-0 w-11/12 rounded-3xl shadow-2xl relative overflow-hidden max-w-xs w-1/4 tablet:w-48 tablet:pointer-events-none tablet:cursor-default mobile:w-45"
                            style={{ backgroundColor: "#ED8936" }}
                          >
                            <svg
                              className="absolute bottom-0 left-0 mb-8"
                              viewBox="0 0 375 283"
                              fill="none"
                              style={{
                                transform: "scale(1.5)",
                                opacity: "0.1",
                              }}
                            >
                              <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                              />
                              <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                              />
                            </svg>
                            <div className="relative pt-5 px-10 flex items-center justify-center">
                              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <span class="block font-semibold text-sm">
                                FBA/FBF <span className="font-normal text-xs italic">(Contains Flex Estimated Orders)</span> 
                              </span>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <div class="flex justify-between">
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {`${Math.trunc(
                                      fee.quantityShipped
                                    ).toLocaleString()}`}
                                  </span>
                                )}
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {fee.sales.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </span>
                                )}
                              </div>
                              <div class="flex justify-between">
                                <span className="block opacity-75 text-sm -mb-1">
                                  Quantity Shipped
                                </span>
                                <span className="block opacity-75 text-sm -mb-1">
                                  Sales
                                </span>
                              </div>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <div class="flex justify-between">
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {fee.profit.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </span>
                                )}
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {`${Math.trunc(
                                      fee.profitMargin
                                    ).toLocaleString()}%`}
                                  </span>
                                )}
                              </div>
                              <div class="flex justify-between">
                                <span className="block opacity-75 text-sm -mb-1">
                                  Profit
                                </span>
                                <span className="block opacity-75 text-sm -mb-1">
                                  Profit Margin
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="w-1/4 -mt-5 tablet:w-1/2 justify-between flex-wrap  ">
                  <div onClick={() => setCardValue("all")} className="w-full">
                    <div className="grid grid-cols-1 gap-4 tablet:grid-cols-1 mt-10 gap-10 flex-row">
                      {[
                        {
                          title: "Profit ",
                          profit: props.profitCard.All?.[0]?.Profit || 0,
                          profitMargin:
                            props.profitCard.All?.[0]?.ProfitMargin || 0,
                          quantityShipped:
                            props.profitCard.All?.[0]?.QuantityShipped || 0,
                          sales: props.profitCard.All?.[0]?.Sales || 0,
                          color: "#dceff5",
                        },
                      ].map((fee, feeIndex) => {
                        return (
                          <div
                            className="flex-shrink-0 w-11/12 rounded-3xl shadow-2xl relative overflow-hidden max-w-xs w-1/4 tablet:w-48 tablet:pointer-events-none tablet:cursor-default mobile:w-45"
                            style={{ backgroundColor: "#9F7AEA" }}
                          >
                            <svg
                              className="absolute bottom-0 left-0 mb-8"
                              viewBox="0 0 375 283"
                              fill="none"
                              style={{
                                transform: "scale(1.5)",
                                opacity: "0.1",
                              }}
                            >
                              <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                              />
                              <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                              />
                            </svg>
                            <div className="relative pt-5 px-10 flex items-center justify-center">
                              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <span class="block font-semibold text-sm">
                                ALL
                              </span>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <div class="flex justify-between">
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {`${Math.trunc(
                                      fee.quantityShipped
                                    ).toLocaleString()}`}
                                  </span>
                                )}
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {fee.sales.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </span>
                                )}
                              </div>
                              <div class="flex justify-between">
                                <span className="block opacity-75 text-sm -mb-1">
                                  Quantity Shipped
                                </span>
                                <span className="block opacity-75 text-sm -mb-1">
                                  Sales
                                </span>
                              </div>
                            </div>
                            <div className="relative text-white px-6 pb-6">
                              <div class="flex justify-between">
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {fee.profit.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </span>
                                )}
                                {props.isLoading ? (
                                  <div className="item bg-indigo-500 opacity-25">
                                    <div className="item-title" />
                                  </div>
                                ) : (
                                  <span class="block font-semibold text-sm">
                                    {`${Math.trunc(
                                      fee.profitMargin
                                    ).toLocaleString()}%`}
                                  </span>
                                )}
                              </div>
                              <div class="flex justify-between">
                                <span className="block opacity-75 text-sm -mb-1">
                                  Profit
                                </span>
                                <span className="block opacity-75 text-sm -mb-1">
                                  Profit Margin
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card
            bgcolor="white"
            className="relative rounded-lg border-black mt-5"
          >
            <div className="mt-5 w-full">
              <div className="flex justify-start">
                <div
                  className="absolute rounded-lg"
                  style={{
                    background: "white",
                    padding: "2px 16px",
                    top: "-16px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {"PROFIT MARGIN's"}
                </div>
              </div>
              <div className="w-full flex flex-wrap justify-between">
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                  <Card
                    bgcolor="white"
                    bodyStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "0px solid black",
                      borderRadius: "10px",
                    }}
                    className="relative rounded-lg border-white"
                  >
                    <div className="mt-5 w-full">
                      <div className="flex justify-start">
                        <div
                          className="absolute rounded-lg"
                          style={{
                            background: "white",
                            padding: "2px 16px",
                            top: "-16px",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {"MERCHANT"}
                        </div>
                      </div>
                      <div className="h-60 -my-8">
                        <ProfitPieChart
                          data={
                            props?.profitTableData?.Merchant?.ProfitabilityTable
                          }
                          isLoading={props.isLoading}
                          setCardValue={setCardValue}
                          fullfillmentType={"merchant"}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap  ">
                  <Card
                    bgcolor="white"
                    bodyStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "0px solid black",
                      borderRadius: "10px",
                    }}
                    className="relative rounded-lg border-white"
                  >
                    <div className="mt-5 w-full">
                      <div className="flex justify-start">
                        <div
                          className="absolute rounded-lg"
                          style={{
                            background: "white",
                            padding: "2px 16px",
                            top: "-16px",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {"SMART/FLEX"}
                        </div>
                      </div>
                      <div className="h-60 -my-8">
                        <ProfitPieChart
                          data={
                            props?.profitTableData?.Smart?.ProfitabilityTable
                          }
                          isLoading={props.isLoading}
                          setCardValue={setCardValue}
                          fullfillmentType={"smart"}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                  <Card
                    bgcolor="white"
                    bodyStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "0px solid black",
                      borderRadius: "10px",
                    }}
                    className="relative rounded-lg border-white"
                  >
                    <div className="mt-5 w-full">
                      <div className="flex justify-start">
                        <div
                          className="absolute rounded-lg"
                          style={{
                            background: "white",
                            padding: "2px 16px",
                            top: "-16px",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {"FBA/FBF"}
                        </div>
                      </div>
                      <div className="h-60 -my-8">
                        <ProfitPieChart
                          data={props?.profitTableData?.AFN?.ProfitabilityTable}
                          isLoading={props.isLoading}
                          setCardValue={setCardValue}
                          fullfillmentType={"fba"}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="w-1/4  tablet:w-1/2 flex-wrap ">
                  <Card
                    bgcolor="white"
                    bodyStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "0px solid black",
                      borderRadius: "10px",
                    }}
                    className="relative rounded-lg border-white"
                  >
                    <div className="mt-5 w-full">
                      <div className="flex justify-start">
                        <div
                          className="absolute rounded-lg"
                          style={{
                            background: "white",
                            padding: "2px 16px",
                            top: "-16px",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {"ALL"}
                        </div>
                      </div>
                      <div className="h-60 -my-8">
                        <ProfitPieChart
                          data={props?.profitTableData?.All?.ProfitabilityTable}
                          isLoading={props.isLoading}
                          setCardValue={setCardValue}
                          fullfillmentType={"all"}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </Card>
          <Card
            bgcolor="white"
            className="relative rounded-lg border-black mt-5"
          >
            <div className="mt-5 w-full">
              <div className="flex justify-start">
                <div
                  className="absolute rounded-lg"
                  style={{
                    background: "white",
                    padding: "2px 16px",
                    top: "-16px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {"TOP 5 SKU's"}
                </div>
              </div>
              <div className="w-full flex flex-wrap justify-between">
                <div className="w-1/4 tablet:w-1/2 flex-wrap  ">
                      <div >
                        <ProfitabilityTable
                          data={props?.profitTableData?.Merchant?.TopPerforming}
                          isLoading={props.isLoading}
                          title="MERCHANT"
                        />
                      </div>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                      <div className="ml-1">
                        <ProfitabilityTable
                          data={props?.profitTableData?.Smart?.TopPerforming}
                          isLoading={props.isLoading}
                          title="SMART/FLEX"
                        />
                      </div>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                      <div className="ml-1">
                        <ProfitabilityTable
                          data={props?.profitTableData?.AFN?.TopPerforming}
                          isLoading={props.isLoading}
                          title="FBA/FBF"
                        />
                      </div>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                      <div className="ml-1">
                        <ProfitabilityTable
                          data={props?.profitTableData?.All?.TopPerforming}
                          isLoading={props.isLoading}
                          title="ALL"
                        />
                      </div>
                </div>
              </div>
            </div>
          </Card>
          <Card
            bgcolor="white"
            className="relative rounded-lg border-black mt-5"
          >
            <div className="mt-5 w-full">
              <div className="flex justify-start">
                <div
                  className="absolute rounded-lg"
                  style={{
                    background: "white",
                    padding: "2px 16px",
                    top: "-16px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {"BOTTOM 5 SKU's"}
                </div>
              </div>
              <div className="w-full flex flex-wrap justify-between">
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                
                      <div>
                        <ProfitabilityTable
                          data={props?.profitTableData?.Merchant?.lowPerforming}
                          isLoading={props.isLoading}
                          title="MERCHANT"
                        />
                      </div>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                 
                      <div className="ml-1">
                        <ProfitabilityTable
                          data={props?.profitTableData?.Smart?.lowPerforming}
                          isLoading={props.isLoading}
                          title="SMART/FLEX"
                        />
                      </div>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap  ">
                      <div className="ml-1">
                        <ProfitabilityTable
                          data={props?.profitTableData?.AFN?.lowPerforming}
                          isLoading={props.isLoading}
                          title="FBA/FBF"
                        />
                      </div>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                      <div className="ml-1">
                        <ProfitabilityTable
                          data={props?.profitTableData?.All?.lowPerforming}
                          isLoading={props.isLoading}
                          title="ALL"
                        />
                      </div>
                </div>
              </div>
            </div>
          </Card>
          <Card
            bgcolor="white"
            className="relative rounded-lg border-black mt-5"
          >
            <div className="mt-5 w-full">
              <div className="flex justify-start">
                <div
                  className="absolute rounded-lg"
                  style={{
                    background: "white",
                    padding: "2px 16px",
                    top: "-16px",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {"PROFIT WATERFALL"}
                </div>
              </div>
              <div className="w-full flex flex-wrap justify-between">
                <div className="w-1/4 tablet:w-1/2 flex-wrap  ">
                  <Card
                    bgcolor="white"
                    bodyStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "0px solid black",
                      borderRadius: "10px",
                    }}
                    className="relative rounded-lg border-white"
                  >
                    <div className="mt-5 w-full">
                      <div className="flex justify-start">
                        <div
                          className="absolute rounded-lg"
                          style={{
                            background: "white",
                            padding: "2px 16px",
                            top: "-16px",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {" MERCHANT"}
                        </div>
                      </div>
                      <div className="h-80 mt-5">
                        <ProfitWaterfall
                          data={props.profitGraphData?.Merchant?.[0]}
                          isLoading={props.isLoading}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                  <Card
                    bgcolor="white"
                    bodyStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "0px solid black",
                      borderRadius: "10px",
                    }}
                    className="relative rounded-lg border-white"
                  >
                    <div className="mt-5 w-full">
                      <div className="flex justify-start">
                        <div
                          className="absolute rounded-lg"
                          style={{
                            background: "white",
                            padding: "2px 16px",
                            top: "-16px",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {"SMART/FLEX"}
                        </div>
                      </div>
                      <div className="h-80 mt-5">
                        <ProfitWaterfall
                          data={props.profitGraphData?.Smart?.[0]}
                          isLoading={props.isLoading}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap  ">
                  <Card
                    bgcolor="white"
                    bodyStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "0px solid black",
                      borderRadius: "10px",
                    }}
                    className="relative rounded-lg border-white"
                  >
                    <div className="mt-5 w-full">
                      <div className="flex justify-start">
                        <div
                          className="absolute rounded-lg"
                          style={{
                            background: "white",
                            padding: "2px 16px",
                            top: "-16px",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {"FBA/FBF"}
                        </div>
                      </div>
                      <div className="h-80 mt-5">
                        <ProfitWaterfall
                          data={props.profitGraphData?.AFN?.[0]}
                          isLoading={props.isLoading}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="w-1/4 tablet:w-1/2 flex-wrap ">
                  <Card
                    bgcolor="white"
                    bodyStyle={{
                      backgroundColor: "#f5f5f5",
                      border: "0px solid black",
                      borderRadius: "10px",
                    }}
                    className="relative rounded-lg border-white"
                  >
                    <div className="mt-5 w-full">
                      <div className="flex justify-start">
                        <div
                          className="absolute rounded-lg"
                          style={{
                            background: "white",
                            padding: "2px 16px",
                            top: "-16px",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          {"  ALL "}
                        </div>
                      </div>
                      <div className="h-80 mt-5">
                        <ProfitWaterfall
                          data={props.profitGraphData?.All?.[0]}
                          isLoading={props.isLoading}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ProfitSummary;
