import React, { useState } from "react";
import { PostValues } from "../../utils/api-client";
import { Modal, Select, Input, message } from "antd";


const { Option } = Select;

export default function AddProduct(props) {
    const [confirmLoading, setConfirmLoading] = React.useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [areaData, setAreaData] = React.useState('')
    const [channel, setChannel] = React.useState([])
    const [errorMessage, setErrorMessage] = React.useState('')

    React.useEffect(() => {
        if (props.isModalVisible) {
            setIsModalVisible(props.isModalVisible)
        }
    }, [props.isModalVisible])

    const addKeywordsApi = async (requestData) => {
        let response = await PostValues("addKeyWordsTrackers", requestData);
        return response;
    };

    const handleOk = async () => {
        let dataArray = areaData.split(/(\s+)/).filter(e => e.trim().length > 0)
        // let arrayProduct = dataArray.concat(productId)
        if (dataArray.length > 0 && channel) {
            setConfirmLoading(true)
            let reqBody = {
                "channel": channel,
                "metric": "product",
                "product_list": dataArray
            }
            const data = await addKeywordsApi(reqBody);
            if (data.status) {
                setTimeout(() => {
                    props.upadteProduct()
                }, 2000)
                message.success("Product Added Successfully")
                props.productmodalClose()
                setIsModalVisible(false)
                setConfirmLoading(false)
                setAreaData('')
                setChannel([])
                props.addProductkey(dataArray[0], channel)
            } else {
                setConfirmLoading(false)
                message.success(data.message)
            }
        } else {
            setErrorMessage("Please Enter Product Id")
        }
    };

    const handleChange = async (value) => {
        setChannel(value)
    }
    const handleCancel = () => {
        setAreaData('')
        setChannel([])
        props.productmodalClose()
        setIsModalVisible(false);
    };
    return (
        <>
            <Modal title="Add Product" maskClosable={false} confirmLoading={confirmLoading} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Select
                    allowClear
                    style={{ width: '100%' }}
                    value={channel}
                    placeholder="Select Channel"
                    onChange={handleChange}
                >
                    <Option value="Amazon-India" label="Amazon-India">
                        <div className="demo-option-label-item">
                            Amazon-India
                        </div>
                    </Option>
                    <Option value="Flipkart" label="Flipkart">
                        <div className="demo-option-label-item">
                            Flipkart
                        </div>
                    </Option>
                </Select>
                <div class="mt-3"></div>
                <div class="mt-3"></div>
                <Input placeholder='Product Id' value={areaData} rows={4} onChange={(e) => { setAreaData(e.target.value) }} />
                <div className="text-red-600"> {errorMessage}</div>
            </Modal>

        </>
    )
}