import { Tooltip, Image, Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "../../App.css";
import AntTable from "../../components/AntTableV2/index.js";
import "../../Styles/review.css";
import { DeleteOutlined, EyeOutlined, FileAddOutlined } from "@ant-design/icons";
import { PostValues } from "../../utils/api-client";
import AddKeywords from "./addKeywords";

function ProductTab(props) {
    const [keywordsmodal, setKeywordsmodal] = React.useState(false)
    const [rowData, setRowData] = React.useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [confirmloading, setConfirmLoading] = useState(false);
    const [bulkProductDelete, setBulkProductDelete] = useState([])
    const [productId, setProductId] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const columns = [
        {
            title: "Product",
            align: "center",
            field: "product_id",
            type: "string",
            width: 120,
            render: (record, value, rowIndex) => (
                <>
                    {" "}
                    <div
                        className={`${record.channel === "Amazon-India"
                            ? "amazon-logo-small"
                            : record.channel === "Flipkart"
                                ? "flipkart-logo-small"
                                : null
                            }`}
                    />
                    <div className="flex items-center justify-center">
                        <Image
                            width={30}
                            height={30}
                            src={record.product_image}
                            fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
                        />
                        {" "}
                        <Button className="-ml-3 text-sm" type="link">
                            <a
                                href={
                                    record.channel === "Amazon-India"
                                        ? `https://www.amazon.in/dp/${record.product_id}`
                                        : `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${record.product_id}`
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                {record.product_id?.replace(/(.{15})..+/, "$1…") || ""}
                            </a>
                        </Button>
                    </div>
                </>
            ),
            sorter: (a, b) => {
                a = (a.product_id || "").toLowerCase();
                b = (b.product_id || "").toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
            sortDirections: ["descend", "ascend", "descend"],
        },
        {
            title: "Product Name",
            align: "center",
            field: "product_name",
            tooltip: true,
            type: "string",
            width: 120,
            render: (record, rowIndex, isLoading) =>
                isLoading ? (
                    <div className="item">
                        <div className="item-title" />
                    </div>
                ) : record?.product_name !== undefined ? (
                    <>
                        <Tooltip title={record.product_name}>
                            {
                                record.product_name?.replace(/(.{30})..+/, "$1…")
                            }
                        </Tooltip>
                    </>
                ) : ('-')
        },
        {
            title: "Keywords Tracked",
            align: "center",
            field: "COUNT",
            tooltip: true,
            isSearchable: true,
            type: "string",
            width: 120,
            defaultSortOrder: "descend",
            sorter: (a, b) => {
                a = (a.COUNT || "");
                b = (b.COUNT || "");
                return a > b ? -1 : b > a ? 1 : 0;
            },
            sortDirections: ["descend", "ascend", "descend"],
        },
        {
            title: "Keyword with Best Organic Rank",
            align: "center",
            isTotal: false,
            field: "keyword_with_best_organic_rank",
            tooltip: true,
            isSearchable: true,
            type: "string",
            width: 100,
            render: (record, rowIndex, isLoading) =>
                isLoading ? (
                    <div className="item">
                        <div className="item-title" />
                    </div>
                ) : record?.keyword_with_best_organic_rank !== undefined ? (
                    record?.keyword_with_best_organic_rank
                ) : (
                    "-"
                ),

        },
        {
            title: "Best Organic Rank",
            align: "center",
            isTotal: false,
            field: "best_organic_rank",
            tooltip: true,
            isSearchable: true,
            type: "string",
            width: 120,
            render: (record, rowIndex, isLoading) =>
              isLoading ? (
                <div className="item">
                  <div className="item-title" />
                </div>
              ) : record?.best_organic_rank !== undefined ? (
                record?.best_organic_rank
              ) : (
                "-"
              ),
            sorter: (a, b) => {
              a = (a.best_organic_rank || "");
              b = (b.best_organic_rank || "");
              return a > b ? -1 : b > a ? 1 : 0;
            },
            sortDirections: ["descend", "ascend", "descend"],
          },
        {
            title: "Keyword with Best Ad Rank",
            align: "center",
            isTotal: false,
            field: "keyword_with_best_ad_rank",
            tooltip: true,
            isSearchable: true,
            type: "string",
            width: 120,
            render: (record, rowIndex, isLoading) =>
                isLoading ? (
                    <div className="item">
                        <div className="item-title" />
                    </div>
                ) : record?.keyword_with_best_ad_rank !== undefined ? (
                    record?.keyword_with_best_ad_rank
                ) : (
                    "-"
                ),
        },
        {
            title: "Best Ad Rank",
            align: "center",
            isTotal: false,
            field: "best_ad_rank",
            tooltip: true,
            isSearchable: true,
            type: "string",
            width: 120,
            render: (record, rowIndex, isLoading) =>
              isLoading ? (
                <div className="item">
                  <div className="item-title" />
                </div>
              ) : record?.best_ad_rank !== undefined ? (
                record?.best_ad_rank
              ) : (
                "-"
              ),
            sorter: (a, b) => {
              a = (a.best_ad_rank || "");
              b = (b.best_ad_rank || "");
              return a > b ? -1 : b > a ? 1 : 0;
            },
            sortDirections: ["descend", "ascend", "descend"],
          },
        {
            title: "Action",
            align: "center",
            field: "action",
            width: 120,
            render: (record, rowIndex, isLoading) => {
                return (
                    <>
                        <Tooltip title="View">
                            <Button
                                icon={
                                    <EyeOutlined />
                                }
                                onClick={() => {
                                    scrollButtonHandler(record)
                                }}
                            >
                            </Button>
                        </Tooltip>
                        <Tooltip title="Add Keywords">
                            <Button
                                icon={
                                    <FileAddOutlined />
                                }
                                onClick={async () => {
                                    setKeywordsmodal(true)
                                    setRowData(record)
                                }}
                            >
                            </Button>
                        </Tooltip>
                        <Tooltip title="Delete Product">
                            <Button icon={<DeleteOutlined />} onClick={() => {
                                setIsModalVisible(true)
                                setRowData(record)
                            }
                            } ></Button>
                        </Tooltip>

                    </>

                );
            },
        },
    ];
    // const deleteproducts = async (row) => {
    //     let requestData = {
    //         "product_list": [row.product_id]
    //     }
    //     let data = await deleteKeyWordsTrackers(requestData);
    //     if (data.status) {
    //         message.success('Product Delete Success');
    //         props.reloadApikey()
    //     }
    // }
    const deleteKeyWordsTrackers = async (requestData) => {
        let response = await PostValues("deleteKeyWordsTrackers", requestData);
        return response;
    };

    const scrollButtonHandler = async (record) => {
        props.keywordsTabClick(record);
    };
    const keywordmodalClose = () => {
        setKeywordsmodal(false)
    }
    const onTableChange = async ({
        paginationCondition,
        sortCondition,
        filterCondition,
    }) => {
        props.tableFilterData(paginationCondition, sortCondition, filterCondition);
    };
    const handleOk = async () => {
        setConfirmLoading(true)
        let requestData = {
            "product_list": [rowData.product_id]
        }
        let data = await deleteKeyWordsTrackers(requestData);
        if (data.status) {
            props.popupFalse()
            props.reloadApikey(rowData)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.success('Product Deleted Successfully');
        }
    };
    useEffect(() => {
        if (props.prodData && props.channelData) {
            let dataSet = {
                "channel": props.channelData,
                "product_id": props.prodData
            }
            setRowData(dataSet)
        }
        if (props.autoPopup) {
            setKeywordsmodal(true)
        }
    }, [props.prodData, props.channelData, props.autoPopup])
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    useEffect(() => {
        setIsLoading(props.isLoading)
    }, [props.isLoading])

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onSelectAll: (selected, selectedRows, changeRows) => {
            setBulkProductDelete([])
            setProductId([])
            setBulkProductDelete(selectedRows)
            selectedRows.map((id) => {
                setProductId(productId => [...productId, id.product_id]);
            })
            if (selectedRowKeys.length !== 0) {
                setSelectedRowKeys([]);
            }
        },
        onChange: (selectedRowKeys, selectedRows) => {
            setBulkProductDelete([])
            setProductId([])
            setBulkProductDelete(selectedRows)
            selectedRows.map((id) => {
                setProductId(productId => [...productId, id.product_id]);
            })
            setSelectedRowKeys(selectedRowKeys);
        }
    };
    const bulkDelte = async () => {
        setIsLoading(true)
        let requestData = {
            "product_list": productId
        }
        let data = await deleteKeyWordsTrackers(requestData);
        if (data.status) {
            message.success('Product Deleted Successfully');
            setTimeout(() => {
                setBulkProductDelete([])
                props.upadteProduct()
                setSelectedRowKeys([]);
            }, 2000);
        }
    }
    
    const keywordsModalClose = () =>{
        setKeywordsmodal(false)
    }

    return (
        <>

            <div style={{ marginLeft: 20, marginRight: 20 }}>
                <AddKeywords popupFalse={props.popupFalse} popupData={props?.autoPopup} keywordsModalClose={keywordsModalClose} upadteProduct={props.upadteProduct} isModalVisible={keywordsmodal} rowData={rowData} keywordmodalClose={keywordmodalClose} />
                {
                    bulkProductDelete?.length > 0 &&
                    <div class="flex justify-end ...">
                        <div><Button onClick={bulkDelte}>
                            Bulk Delete
                        </Button></div>
                    </div>
                }
                <AntTable
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={props.productItems}
                    rowKey={(row, rowIndex) => rowIndex}
                    isLoading={isLoading}
                    totalRecords={props.totalRecords}
                    hideSummary
                    stickyHeader={{ offsetHeader: 65 }}
                    onDataChange={onTableChange}
                />
                <Modal title="Are you sure to delete this Product?" confirmLoading={confirmloading} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    Product Id : <b>{rowData.product_id}</b>
                </Modal>
            </div>
        </>
    );
}

export default withRouter(ProductTab);
