import React from "react";
import './NotAuth.css'
import { useHistory } from "react-router-dom";
import CustomAppBar from "../../components/CustomAppBar.js";
import { Helmet } from "react-helmet";

export default function NotAuth(props) {
    let history = useHistory();
    return (
        <>
        <Helmet>
          <title>{"Forcesight"}</title>
        </Helmet>
        <CustomAppBar />
        <section class="page_404">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 ">
                        <div class="col-sm-10 col-sm-offset-1  text-center">
                            <div class="four_zero_four_bg">
                                <h1 class="text-center ">Not Authorised</h1>
                            </div>
                            <div class="contant_box_404">
                                <h3 class="h2">
                                    Look like you're not Authorised
                                </h3>
                                <br></br>
                                <br></br>
                                <h3>To view this page</h3>
                                <button class="link_404" onClick={() => history.goBack()}>Go to Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )

}