import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../../App.css";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import Tabs from "../../components/Tabs";
import { PostValues } from "../../utils/api-client";
import ReviewsInSights from "./inSights/insights";
import ProductReviewsTab from "./productReview/productReviewTab";
import AllReviewsTab from "./allReviews/allReviews";
import SellerReviewsTab from "./sellerReview/sellerReviewTab";
import { message, Rate, Spin } from "antd";
import Campaigns from "./Campaigns";
import { useHistory } from "react-router-dom";
import ExportButton from "../../components/Styles/ExportButton";
import { useLocation } from "react-router-dom";
import SentimentReviews from "./sentimentReviews";

const TabsList = [
  "Summary",
  "Product Summary",
  "Product Reviews",
  "Seller Reviews",
  "Campaign",
  "Review Analyser",
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ReviewsInsights(props) {
  const [searchText, setSearchText] = useState("");
  const [tabValue, setTabValue] = React.useState(0);
  const [columns, setColumns] = useState([]);
  const [startDate, seStarttDate] = React.useState({});
  const [endDate, setEndDate] = React.useState({});
  const [salesChannel, setSalesChannel] = useState([]);
  const [requestData, setRequestData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [productReview, setProductReview] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [reviewGrap, setReviewGrap] = useState([]);
  const [ratingReviewGrap, setRatingReviewGrap] = useState([]);
  const [allReviews, setAllReviews] = React.useState([]);
  const [sellerReviews, setSellerReviews] = React.useState([]);
  const [amazonData, setAmazonData] = React.useState([]);
  const [boolSelect, setBoolSelect] = React.useState(false);
  const [selectProductDetails, setSelectProductDetails] = React.useState({});
  const [cancelValue, setCancelValue] = React.useState(false);
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const [callToActionId, setCallToActionId] = useState("");
  const [callToActionBool, setCallToActionBool] = React.useState(false);
  const [sentimentAnalysisData, setSentimentAnalysisData] = React.useState();
  const [sentimentSummaryData, setSentimentSummaryData] = React.useState();
  const [sentimentData, setSentimentData] = React.useState();
  const [selectedWords, setSelectedWords] = React.useState(null);
  const [selectedReviews, setSelectedReviews] = React.useState([]);
  const [selectedReviewsCount, setSelectedReviewsCount] = React.useState(0);

  const handleManualSyncClick = () => {};
  const location = useLocation();

  const getApiProductReviews = async (requestData) => {
    let response = await PostValues("productReviewsV2", requestData);
    return response;
  };
  const getApiReviews = async (requestData) => {
    let response = await PostValues("allReviewsV2", requestData);
    return response;
  };
  const getApiSellerReviews = async (requestData) => {
    let response = await PostValues("sellerReviewsV2", requestData);
    return response;
  };
  const getSentimentAnalysisData = async (requestData) => {
    let response = await PostValues("sentimentAnalysis", requestData);
    return response;
  };
  const getSelectedWordsReview = async (requestData) => {
    let response = await PostValues("getSentimentKeyWords", requestData);
    return response;
  };
  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };
  const sentimentReviewWords = (val) => {
    setSelectedWords(val);
  };
  const ExportAllData = async () => {
    try {
      setIsExportLoading(true);
      let exportRequestData = {
        params: {
          channels: salesChannel.map((mp) => mp.name),
          fromDate: startDate,
          toDate: endDate,
        },
        reportType:
          (tabValue === 1 && "Ratings") ||
          (tabValue === 2 && "Reviews") ||
          (tabValue === 3 && "SellerReviews"),
      };
      const data = await getExportApi(exportRequestData);
      if (data.status) {
        setIsExportLoading(false);
        message.success(
          "The export is being processed, please check the Downloads page ",
          10
        );
      } else {
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };
  const handleMarketplaceChange = async (
    selectedMarketplaces,
    fromDate,
    toDate
  ) => {
    setIsLoading(true);
    seStarttDate(fromDate);
    setEndDate(toDate);
    setSalesChannel(selectedMarketplaces);
    setSelectedWords(null);
    setSelectedReviews([]);
    setSelectedReviewsCount(0);
    if (selectedMarketplaces.length > 0) {
      getProfit(selectedMarketplaces, fromDate, toDate, searchText);
    } else {
      setIsLoading(false);
      setReviewGrap([]);
      setRatingReviewGrap([]);
      setSummaryData([]);
    }
  };
  const getProfit = async (
    selectedMarketplaces,
    fromDate,
    toDate,
    searchText,
    paginationCond,
    sortCond,
    filterCond = {}
  ) => {
    setIsLoading(true);
    let requestData = {
      channels: selectedMarketplaces.map((mp) => mp.name),
      filters: {
        fromDate: fromDate,
        toDate: toDate,
        ...filterCond,
      },
      ...(paginationCond
        ? paginationCond
        : {
            pagination: {
              pageNo: 0,
              pageSize: 25,
            },
          }),
      ...(sortCond ? sortCond : {}),
    };
    if (searchText) {
      requestData.filters["search"] = searchText;
    }
    setRequestData(requestData);
    if (tabValue === 0) {
      Promise.all([
        PostValues("reviewsInsights", requestData),
        PostValues("reviewsInsightsCard", requestData),
        PostValues("sentimentAnalysis", requestData),
      ])
        .then(function (responses) {
          return Promise.all(
            responses.map(function (response) {
              return response;
            })
          );
        })
        .then(function (result) {
          onSuccessMerge(result);
        })
        .catch(function (error) {});
    }
    if (tabValue === 1) {
      setIsLoading(true);
      // if (callToActionBool) {
      //   requestData["metric"] = callToActionId.id;
      // }
      let productReview = await getApiProductReviews(requestData);
      if (productReview) {
        console.log(productReview.Items)
        setProductReview(productReview.Items);
        setTotalRecords(productReview.pagination.count);
        setIsLoading(false);
      }
    }
    if (tabValue === 2) {
      if (callToActionBool) {
        requestData["metric"] = callToActionId.id;
      }
      if (boolSelect) {
        requestData.filters["product_id"] = selectProductDetails.id;
        requestData.filters["status"] = 0;
      } else {
        requestData.filters["status"] = 0;
      }
      const allReview = await getApiReviews(requestData);
      if (allReview.Items) {
        setAllReviews(allReview.Items);
        setTotalRecords(allReview.pagination.count);
        setIsLoading(false);
      }
    }
    if (tabValue === 3) {
      if (callToActionBool) {
        requestData["metric"] = callToActionId.id;
      }
      requestData.filters["status"] = 0;
      const sellerReview = await getApiSellerReviews(requestData);
      if (sellerReview.Items) {
        setSellerReviews(sellerReview.Items);
        setTotalRecords(sellerReview.pagination.count);
        setAmazonData(sellerReview.seller_data);
        setIsLoading(false);
      }
    }

    if (tabValue === 5) {
      if (selectedWords !== null) {
        requestData.filters["search"] = selectedWords;
        const selectedReview = await getSelectedWordsReview(requestData);
        if (selectedReview.Items) {
          setSelectedReviews(selectedReview.Items);
          setSelectedReviewsCount(selectedReview.pagination.count);
        }
      }else {
      const sentimentReviewsData = await getSentimentAnalysisData(requestData);
      if (sentimentReviewsData.status) {
        setSentimentData(sentimentReviewsData);
      }
    }
    setIsLoading(false);
  }
  };

  const onSuccessMerge = (result) => {
    setReviewGrap(result[0].seller_reviews_graph);
    setRatingReviewGrap(result[0].lifetime_ratings_graph);
    setSummaryData(Object.assign(result[0], result[1]));
    setSentimentSummaryData(result[2]);
    setIsLoading(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const selectedProduct = (val, id, sku) => {
    let data = {
      id: id,
      sku: sku,
    };
    setBoolSelect(true);
    setSelectProductDetails(data);
    setTabValue(val);
  };
  const callToActionReview = (tab, name) => {
    let data = {
      id: name,
    };
    setTabValue(tab);
    setCallToActionId(data);
    setCallToActionBool(true);
  };
  const cancel = () => {
    setBoolSelect(false);
    setCancelValue(true);
    setSelectProductDetails({});
  };
  const campaignBack = () => {
    setTabValue(4);
  };
  React.useEffect(() => {
    if (cancelValue) {
      getProfit(salesChannel, startDate, endDate, searchText);
    }
  }, [cancelValue, callToActionId]);

  React.useEffect(() => {
    if (location?.state?.detail === "campaign") {
      setTabValue(4);
      return;
    }
  }, []);
  React.useEffect(() => {
    if (salesChannel.length > 0) {
      getProfit(salesChannel, startDate, endDate, searchText);
    }
    if (tabValue != 2) {
      setSelectProductDetails({});
      setBoolSelect(false);
    }
    if (tabValue != 1) {
      setCallToActionBool(false);
    }
    if (tabValue !== 5) {
      setSelectedWords(null);
      setSelectedReviews([]);
      setSelectedReviewsCount(0);
    }
  }, [tabValue]);
  React.useEffect(() => {
    if (salesChannel.length > 0) {
      getProfit(salesChannel, startDate, endDate, searchText);
    }
      return () => {
        setSelectedWords(null);
        setSelectedReviews([]);
        setSelectedReviewsCount(0);
      };
  }, [selectedWords]);
  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <div>
        <CustomAppBar />

        <CustomFilterHeader
          headerTitle="Feedback"
          channelHide={tabValue == 4 ? true : false}
          handleManualSyncClick={handleManualSyncClick}
          handleMarketplaceChange={handleMarketplaceChange}
          showDateRanges={tabValue == 4 ? false : true}
          showManualSync={false}
          searchFunction={(ds, searchText) => {
            getProfit(salesChannel, startDate, endDate, searchText.trim());
          }}
          getExportData={ExportAllData}
          showExportButton={false}
          searchHide={(tabValue === 0 || tabValue === 5) ? false : true}
          showSearchBar={tabValue == 4 ? false : true}
          columns={columns}
          campaignBack={campaignBack}
          isLoading={isLoading}
          onFilterUpdate={(data) => {}}
          exportFileName="Reviews"
          onSearchTextEntered={handleSearch}
          campaigns={tabValue == 4 ? true : false}
          customToggleProfit={
            (tabValue === 1 || tabValue === 2 || tabValue === 3) && (
              <Spin spinning={isExportLoading}>
                <div className="ml-6">
                  <ExportButton variant="outlined" onClick={ExportAllData}>
                    Export to CSV
                  </ExportButton>
                </div>
              </Spin>
            )
          }
          searchInput={
            tabValue == 3 && (
              <div className="flex items-center justify-start">
                <div className="flex flex-col p-4 border rounded mr-4">
                  <div className="mb-2 font-bold">Average Lifetime Ratings</div>
                  {amazonData.map((dataOf, index) => {
                    return (
                      <>
                        {dataOf.channel == "Amazon-India" ? (
                          <div className="flex items-center mb-2">
                            <div
                              className="amazon-logo-small"
                              style={{ margin: 0 }}
                            />
                            <div className="mx-4">
                              <Rate
                                allowHalf
                                defaultValue={dataOf.rating}
                                value={dataOf.rating}
                                disabled
                                count={5}
                                allowClear={false}
                              />
                            </div>
                            <div>
                              {dataOf.global_ratings
                                ? `(${dataOf.global_ratings})`
                                : "(0)"}
                            </div>
                          </div>
                        ) : null}
                        {dataOf.channel == "Flipkart" ? (
                          <div className="flex items-center mb-2">
                            <div
                              className="flipkart-logo-small"
                              style={{ margin: 0 }}
                            />
                            <div className="mx-4">
                              <Rate
                                allowHalf
                                defaultValue={dataOf.rating}
                                value={dataOf.rating}
                                disabled
                                count={5}
                                allowClear={false}
                              />
                            </div>
                            <div>
                              {dataOf.global_ratings
                                ? `(${dataOf.global_ratings})`
                                : "(0)"}
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            )
          }
        />

        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <Tabs
            value={tabValue}
            list={TabsList}
            onChange={(value) => {
              setTabValue(value);
            }}
          />
        </div>
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <TabPanel value={tabValue} index={0}>
            <ReviewsInSights
              callToActionReview={callToActionReview}
              summaryData={summaryData}
              activeTab={`${tabValue}`}
              reviewGrap={reviewGrap}
              ratingReviewGrap={ratingReviewGrap}
              sentimentSummaryData={sentimentSummaryData}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ProductReviewsTab
              selectedProduct={selectedProduct}
              activeTab={`${tabValue}`}
              setColumn={(col) => {
                setColumns(col);
              }}
              isLoading={isLoading}
              pageCount={totalRecords}
              productReviewsData={productReview}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getProfit(
                  salesChannel,
                  startDate,
                  endDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <AllReviewsTab
              cancel={cancel}
              boolSelect={boolSelect}
              selectProductDetails={selectProductDetails}
              activeTab={`${tabValue}`}
              setColumn={(col) => {
                setColumns(col);
              }}
              isLoading={isLoading}
              pageCount={totalRecords}
              allReviewsData={allReviews}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getProfit(
                  salesChannel,
                  startDate,
                  endDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <SellerReviewsTab
              activeTab={`${tabValue}`}
              setColumn={(col) => {
                setColumns(col);
              }}
              isLoading={isLoading}
              pageCount={totalRecords}
              allSellerReviewData={sellerReviews}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getProfit(
                  salesChannel,
                  startDate,
                  endDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <Campaigns activeTab={`${tabValue}`} />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <SentimentReviews
              data={sentimentData && sentimentData}
              isLoading={isLoading}
              activeTab={`${tabValue}`}
              sentimentReviewWords={sentimentReviewWords}
              selectedReviews={selectedReviews && selectedReviews}
              selectedReviewsCount={
                selectedReviewsCount && selectedReviewsCount
              }
              tableFilterData={
                selectedReviewsCount
                  ? (paginationCondition, sortCond, filterCond) => {
                      getProfit(
                        salesChannel,
                        startDate,
                        endDate,
                        searchText,
                        paginationCondition,
                        sortCond,
                        filterCond
                      );
                    }
                  : null
              }
            />
          </TabPanel>
        </div>
      </div>
    </>
  );
}
