import {
  DownCircleFilled,
  RightCircleFilled,
} from "@ant-design/icons";
import { message, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import AntTable from "../../components/AntTableV2";
import ApiWrapper from "../../utils/ApiWrapper";
import { Fs_status } from "../../utils/data";
import OrdersDetailPanel from "./OrderDetailsPanelV2";
import PincodePopup from "./PincodePopup";
import TBCPopup from "./TBCPopup";
export const PaymentTrackerContext = React.createContext("");

const PaymentStatusText = ({ text }) => {
  return (
    <Tooltip placement="top" title={text}>
      <Tag
        color={
          text?.toLowerCase().startsWith("cancelled")
            ? "red"
            : text?.toLowerCase().startsWith("shipped")
            ? "green"
            : text?.toLowerCase().startsWith("pending")
            ? "gold"
            : "red"
        }
      >
        {text?.replace(/(.{6})..+/, "$1…")}
      </Tag>
    </Tooltip>
  );
};

function OrdersTable(props) {
  const [pincodeVisbile, setPincodeVisbile] = useState(false);
  const [categoryVisible, setCategoryVisible] = useState(false);
  const [popupData, setPopupData] = useState({});
  // const refColumns = React.useMemo(() => {
  const columns = [
    // {
    //   title: "Sno",
    //   align: "center",
    //   field: "marketPlace",
    //   width: 50,
    //   render: (record, rowIndex, isLoading) => rowIndex,
    // },
    // {
    //   title: "Channel",
    //   align: "center",
    //   field: "marketPlace",
    //   width: 75,
    //   render: (record, rowIndex, isLoading) =>
    //     record.marketPlace === "Amazon-India" ? (
    //       <div className="amazon-logo-small" />
    //     ) : record.marketPlace === "Flipkart" ? (
    //       <div className="flipkart-logo-small" />
    //     ) : null,
    // },

    ...((props.activeTab === "2" && [
      {
        title: (
          <div className="flex items-center justify-center">
            Order
             {/* <CalendarOutlined className="pl-1" /> */}
          </div>
        ),
        colName: "Order Date",
        align: "center",
        field: "order_date",
        isFilterable: true,
        type: "date",
        width: 140,
        sorter: true,
        render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.order_date !== undefined ? (
          <>
            {" "}
              <div
                className={`${
                  record.channel === "Amazon-India"
                    ? "amazon-logo-small"
                    : record.channel === "Flipkart"
                    ? "flipkart-logo-small"
                    : "-"
                }`}
              />
            <div>{` ${record.order_date} /  ${record.sku?.replace(/(.{15})..+/, "$1…")}`}</div>
            <span> {record.order_id}</span>
          </>
        ) : (
          "-"
        ),
      },
      // {
      //   title: "Order ID",
      //   align: "center",
      //   field: "order_id",
      //   isFilterable: true,
      //   isSearchable: true,
      //   width: 180,
      //   type: "string",
      //   render: (record, rowIndex, isLoading) => record.order_id || "-",
      //   sorter: true,
      //   onFilter: () => {},
      // },
      {
        title: "Status",
        align: "center",
        field: "order_status",
        isFilterable: true,
        type: "string",
        width: 80,
        onFilter: (value, record) => {
          if (value === "-") {
            if (!record.order_status) {
              return true;
            }
          } else {
            let isFilter = false;
            value.map((orderStatus) => {
              if (
                orderStatus.toLowerCase() ===
                (record.order_status || "").toLowerCase()
              ) {
                isFilter = true;
              }
            });
            return isFilter;
          }
        },
        filters: Fs_status,
        render: (record, rowIndex, isLoading) =>
          record.order_status !== "-" ? (
            <PaymentStatusText text={record.order_status} />
          ) : (
            "-"
          ),
      },
      // {
      //   title: "SKU",
      //   align: "center",
      //   field: "sku",
      //   isFilterable: true,
      //   type: "string",
      //   width: 70,
      //   defaultSortOrder: "descend",
      //   render: (record, rowIndex, isLoading) =>
      //     record.sku?.replace(/(.{15})..+/, "$1…") || "-",
      //   sorter: true,
      // },
      {
        title: "Invoice",
        align: "center",
        field: "invoice",
        isFilterable: true,
        total: props.unSettledOrdersTotal?.invoice_totals,
        type: "number",
        // total: unSettledOrderTotal?.InvoicePricetotalSum,
        width: 95,

        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.invoice !== undefined
            ? record.invoice?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "Channel Fees",
        align: "center",
        field: "Marketplace",
        width: 95,
        total: props.unSettledOrdersTotal?.Marketplace_totals,
        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.Marketplace !== undefined
            ? record.Marketplace?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "TCS",
        align: "center",
        field: "tcs",
        isTotal: true,
        total: props.unSettledOrdersTotal?.tcs_totals,
        isTotalCurrency: true,
        width: 80,
        render: (record, rowIndex, isLoading) =>
          record.tcs !== undefined
            ? record.tcs?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "Ship Fee",
        align: "center",
        field: "ShippingFee",
        width: 100,
        total: props.unSettledOrdersTotal?.ShippingFees_totals,
        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.ShippingFee !== undefined
            ? record.ShippingFee?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: false,
      },
      {
        title: "Settled Amount",
        align: "center",
        field: "settled_amount",
        width: 100,
        total: props.unSettledOrdersTotal?.settled_amounts,
        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.settled_amount !== undefined
            ? record.settled_amount?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
    ]) ||
      []),

    ...((props.activeTab === "1" && [
      {
        title: (
          <div className="flex items-center justify-center">
            Order
             {/* <CalendarOutlined className="pl-1" /> */}
          </div>
        ),
        colName: "Order Date",
        align: "center",
        field: "OrderDate",
        isFilterable: true,
        type: "date",
        width: 200,
        sorter: true,
        render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.OrderDate !== undefined ? (
          <>
            {" "}
              <div
                className={`${
                  record.channel === "Amazon-India"
                    ? "amazon-logo-small"
                    : record.channel === "Flipkart"
                    ? "flipkart-logo-small"
                    : "-"
                }`}
              > </div>
            <div>{` ${record.OrderDate} / ${record.SKU?.replace(/(.{15})..+/, "$1…")}`}</div>
            <span> {record.OrderID}</span>
          </>
        ) : (
          "-"
        ),
      },
      // {
      //   title: "Order ID",
      //   align: "center",
      //   field: "OrderID",
      //   isFilterable: true,
      //   isSearchable: true,
      //   width: 180,
      //   type: "string",
      //   defaultSortOrder: "descend",
      //   render: (record, rowIndex, isLoading) => record.OrderID || "-",
      //   sorter: true,
      //   onFilter: () => {},
      // },
      {
        title: "Status",
        align: "center",
        field: "FSOrderStatus",
        isFilterable: true,
        type: "string",
        width: 80,
        onFilter: () => {},
        filters: Fs_status,
        render: (record, rowIndex, isLoading) =>
          record.FSOrderStatus !== "-" ? (
            <PaymentStatusText text={record?.FSOrderStatus} />
          ) : (
            "-"
          ),
      },
      // {
      //   title: "SKU",
      //   align: "center",
      //   field: "SKU",
      //   isFilterable: true,
      //   type: "string",
      //   width: 90,
      //   render: (record, rowIndex, isLoading) =>
      //     record.SKU?.replace(/(.{15})..+/, "$1…") || "-",
      //   sorter: true,
      // },
      {
        title: "Invoice",
        align: "center",
        field: "InvoicePrice.total",
        isFilterable: true,
        total: props.settledOrdersTotal?.InvoicePricetotalSum,
        type: "number",
        width: 95,

        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.InvoicePrice !== undefined
            ? record.InvoicePrice?.total?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "Channel Fees",
        align: "center",
        field: "MarketplaceFees.total",
        width: 95,
        total: props.settledOrdersTotal?.MarketplaceFeestotalSum,
        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.MarketplaceFees !== undefined
            ? record.MarketplaceFees?.total?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "Channel Principal",
        align: "center",
        field: "InvoicePrice.Principal",
        hidden: true,
      },
      {
        title: "Channel Tax",
        align: "center",
        field: "InvoicePrice.Tax",
        hidden: true,
      },
      {
        title: "Channel ShippingCharge",
        align: "center",
        field: "InvoicePrice.ShippingCharge",
        hidden: true,
      },
      {
        title: "Channel ShippingTax",
        align: "center",
        field: "InvoicePrice.ShippingTax",
        hidden: true,
      },
      {
        title: "Channel Promotion",
        align: "center",
        field: "InvoicePrice.Promotion1",
        hidden: true,
      },
      {
        title: "Channel Promotion",
        align: "center",
        field: "InvoicePrice.Promotion2",
        hidden: true,
      },
      {
        title: "Channel Promotion",
        align: "center",
        field: "InvoicePrice.Promotion3",
        hidden: true,
      },
      {
        title: "Channel Promotion",
        align: "center",
        field: "InvoicePrice.Promotion4",
        hidden: true,
      },
      {
        title: "Channel FBAWeightBasedFee",
        align: "center",
        field: "MarketplaceFees.FBAWeightBasedFee",
        hidden: true,
      },
      {
        title: "Channel TechnologyFee",
        align: "center",
        field: "MarketplaceFees.TechnologyFee",
        hidden: true,
      },
      {
        title: "Channel Commission",
        align: "center",
        field: "MarketplaceFees.Commission",
        hidden: true,
      },
      {
        title: "Channel FixedClosingFee",
        align: "center",
        field: "MarketplaceFees.FixedClosingFee",
        hidden: true,
      },
      {
        title: "Channel ShippingChargeback",
        align: "center",
        field: "Marketplace.ShippingChargeback",
        hidden: true,
      },
      {
        title: "Channel FixedFee",
        align: "center",
        field: "MarketplaceFees.FixedFee",
        hidden: true,
      },
      {
        title: "Channel CollectionFee",
        align: "center",
        field: "MarketplaceFees.CollectionFee",
        hidden: true,
      },
      {
        title: "Channel Taxes",
        align: "center",
        field: "MarketplaceFees.Taxes",
        hidden: true,
      },
      {
        title: "TCS",
        align: "center",
        field: "TCS.total",
        isTotal: true,
        total: props.settledOrdersTotal?.TCStotalSum,
        isTotalCurrency: true,
        width: 70,
        render: (record, rowIndex, isLoading) =>
          record.TCS !== undefined
            ? record.TCS?.total?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "TCS-IGST",
        align: "center",
        field: "TCS.TCS_IGST",
        hidden: true,
      },
      {
        title: "Ship Fee",
        align: "center",
        field: "ShippingFees.total",
        width: 90,
        total: props.settledOrdersTotal?.ShippingFeestotalSum,
        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.ShippingFees !== undefined
            ? record.ShippingFees?.total?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "Return",
        align: "center",
        field: "ReturnPrice.total",
        width: 80,
        total: props.settledOrdersTotal?.ReturnPricetotalSum,
        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.ReturnPrice !== undefined
            ? record.ReturnPrice?.total?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "Settled Amount",
        align: "center",
        field: "SettledAmount",
        width: 100,
        total: props.settledOrdersTotal?.SettledAmountSum,
        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.SettledAmount !== undefined
            ? record.SettledAmount?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-",
        sorter: true,
      },
      {
        title: "Bank Remitted",
        align: "center",
        field: "bank_settle_amount",
        width: 90,
        total: props.settledOrdersTotal?.bank_settle_amountSum,
        isTotal: true,
        isTotalCurrency: true,
        render: (record, rowIndex, isLoading) =>
          record.bank_settle_amount !== null
            ? record.bank_settle_amount?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : 0,
        sorter: true,
      },
      // {
      //   title: (
      //     <div className="flex items-center justify-center">
      //       Settled <CalendarOutlined className="pl-1" />
      //     </div>
      //   ),
      //   colName: "Settled Date",
      //   align: "center",
      //   field: "SettledDate",
      //   isFilterable: true,
      //   type: "date",
      //   width: 100,
      //   sorter: true,
      // },
    ]) ||
      []),
  ];

  //   return columns;
  // }, [props.activeTab]);
  React.useEffect(() => {
    if (props.activeTab === "1" || props.activeTab === "2")
      props.setColumn(columns);
  }, []);
  const DetailPanel = ({ row }) => {
    return (
      <OrdersDetailPanel
        row={row}
        onCategoryChange={props.handleOpen}
        onSetFcPincode={props.handleFcOpen}
        activeTab={props.activeTab}
      />
    );
  };
  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
  }) => {
    props.tableFilterData(paginationCondition, sortCondition, filterCondition);
  };
  const showPincodeModal = (fbaObject, row) => {
    setPincodeVisbile(true);
    setPopupData({ fbaObject, row });
  };
  const onSetCategoryModal = (
    row,
    title,
    breakup,
    type,
    marketplace,
    reconciliation
  ) => {
    setCategoryVisible(true);
    setPopupData({ row, title, breakup, type, marketplace, reconciliation });
  };
  const updateCategoryAndGetRow = async (mainCategory, subCategory, row) => {
    setCategoryVisible(false);
    let updateRequestBody = [
      {
        category: mainCategory + "%23" + subCategory,
        sku: popupData.row.SKU,
      },
    ];
    ApiWrapper("updateAmazonCategory", updateRequestBody).then(
      function (updateResponse) {
        if (updateResponse.status === true) {
          let updatedRowRequestBody = {
            category: mainCategory + "%23" + subCategory,
            sku: popupData.row.SKU,
            "order-id": popupData.row.OrderID,
            apply: true,
          };
          ApiWrapper(
            "updateAmazonCategoryReconciliation",
            updatedRowRequestBody
          ).then(
            function (newRowResponse) {
              if (newRowResponse.data.Reconciliation !== undefined) {
                updateActualPayments(
                  popupData.row.OrderID,
                  popupData.row.SKU,
                  newRowResponse.data.Reconciliation
                );
              }
            },
            function (error) {
              // message.error(
              //   "We encountered an issue. Please log out and log back in. "
              // );
            }
          );
        }
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };
  const onSetFcPincode = (fulfilmentCenterId, pincode) => {
    setPincodeVisbile(false);
    let updateRequestBody = {
      "fulfilment-center-id": fulfilmentCenterId,
      pincode: pincode,
      order_id: popupData?.row?.OrderID,
      sku: popupData?.row?.SKU,
    };
    ApiWrapper("setFcPincode", updateRequestBody).then(
      function (updateResponse) {
        message.success("Pincode updated successfully!");
        let data = [...props.data];
        for (var i = 0; i < data.length; i++) {
          let order = data[i];
          if (
            order.OrderID === popupData?.row?.OrderID &&
            order.SKU === popupData?.row?.SKU
          ) {
            data[i]["Reconciliation"] = updateResponse;
            break;
          }
        }
        props.setData([...data]);
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };
  const updateActualPayments = (orderId, skuId, newReconciliation) => {
    let data = [...props.data];
    for (var i = 0; i < data.length; i++) {
      let order = data[i];
      if (order.OrderID === orderId && order.SKU === skuId) {
        data[i]["Reconciliation"] = newReconciliation;
        break;
      }
    }
    props.setData([...data]);
    // setActualPayments([...data]);
  };
  return (
    <PaymentTrackerContext.Provider
      value={{
        onSetFcPincode: showPincodeModal,
        onSetCategory: onSetCategoryModal,
      }}
    >
      <AntTable
        columns={columns}
        dataSource={props.data}
        stickyHeader={{
          offsetHeader: 65,
          offsetScroll: 916,
        }}
        // scroll={{ y: 240 }}
        rowKey={(row, rowIndex) => rowIndex}
        isLoading={props.isLoading}
        hideSummary={!props.data.length}
        searchText={[props.searchText]}
        totalRecords={props.pageCount}
        expandable={
          props.activeTab === "1" && {
            expandedRowRender: (record) => <DetailPanel row={record} />,
            rowExpandable: (record) => true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownCircleFilled onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightCircleFilled onClick={(e) => onExpand(record, e)} />
              ),
          }
        }
        onDataChange={onTableChange}
      />
      <PincodePopup
        {...popupData}
        visible={pincodeVisbile}
        onClose={() => {
          setPincodeVisbile(false);
        }}
        onSetFcPincode={onSetFcPincode}
      />
      {categoryVisible && (
        <TBCPopup
          {...popupData}
          visible={categoryVisible}
          onClose={() => {
            setCategoryVisible(false);
          }}
          onCategoryUpdate={updateCategoryAndGetRow}
        />
      )}
    </PaymentTrackerContext.Provider>
  );
}

export default OrdersTable;
