import React from "react";
import { Drawer, Table } from "antd";
import { PrimaryButton } from "../../../components/Styles/StyledButton";
import { PostValues } from "../../../utils/api-client";

const ViewFulfillmentCenters = (props) => {
  const [tableData, setTableData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let data = {
      channel: props.selectedData.channel,
      sku: props.selectedData.sku,
      masterSKU: props.selectedData.masterSKU,
      fulfillmentChannel: props.selectedData.fulfillmentChannel,
      quantity: props.selectedData.quantity,
    };

    getStorageLocations(data);
    setLoading(true);
  }, [props]);

  const getStorageLocations = async (data) => {
    let response = await PostValues("getViewFullfillmentCenters", data);
    if (response) {
      let data = [
        {
          fulfillment_center_id: "Merchant warehouse",
          quantity: response.seller_warehouse,
        },
        ...response.fba_fulfillment_centers.map((warehouse) => ({
          fulfillment_center_id: warehouse["fulfillment-center-id"],
          quantity: warehouse.quantity || 0,
        })),
        {
          fulfillment_center_id: "Unaccounted Inventory",
          quantity: response.blocked_inventory,
        },
      ];
      setTableData(data);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Center Name",
      dataIndex: "fulfillment_center_id",
      key: "fulfillment_center_id",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    },
  ];

  return (
    <Drawer
      visible={props.open}
      width={650}
      title="View Fulfillment Centers"
      onClose={() => {
        props.setOpen(false);
        props.setSelectedData({});
      }}
      footer={[
        <div className="flex items-center justify-end">
          <PrimaryButton
            variant="outlined"
            onClick={() => {
              props.setOpen(false);
              props.setSelectedData({});
            }}
          >
            Close
          </PrimaryButton>
        </div>,
      ]}
    >
      <Table
        columns={columns}
        bordered={true}
        dataSource={tableData}
        pagination={false}
        loading={loading}
        footer={() => (
          <div className="flex items-center justify-between">
            <span>Total</span>
            <span>
              {tableData.reduce((sum, val) => sum + Number(val.quantity), 0)}
            </span>
          </div>
        )}
      />
    </Drawer>
  );
};

export default ViewFulfillmentCenters;
