import { Button, Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Spin } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import ExportTableButton from "../../../components/AntTable/ExportTable";
import CustomAppBar from "../../../components/CustomAppBar";
import { PostValues } from "../../../utils/api-client";
import ApiWrapper from "../../../utils/ApiWrapper";
import CampaignCard from "./CampaignCard";

const CreateCampaignButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
  },
  contained: {
    fontFamily: "inter",
    background: "#2684FF",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0065FF",
      color: "#FFFFFF",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const CustomTabs = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
  },
  indicator: {
    backgroundColor: "#2684FF",
    width: "auto",
  },
})(Tabs);

const CustomTab = withStyles({
  root: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#717480",
    minWidth: "auto",
    "&:focus": {
      outline: "none",
    },
    "&$selected": {
      fontWeight: 500,
      fontSize: 14,
      color: "#2684FF",
    },
    "&:hover": {
      color: "#2684FF",
    },
  },
  selected: {},
})(Tab);

let allCampaigns = [];
let allCampaignsReport = [];
const Campaigns = (props) => {
  const RefColumns = [
    {
      title: "AmazonOrderId",
      align: "left",
      key: "AmazonOrderId",
      hidden: "true",
    },

    {
      title: "SKU",
      align: "left",
      key: "TypeSellerSKU",
      hidden: "true",
    },
    {
      title: "Sent Date",
      align: "left",
      key: "SentAtIST",
      hidden: "true",
    },
  ];
  const [tab, setTab] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsReport, setCampaignsReport] = useState([]);
  const [columns, setColumns] = React.useState(RefColumns);
  const [loading, setLoading] = React.useState(false);
  const [reportLoading, setReportLoading] = React.useState(false);

  const history = useHistory();

  const onSuccess = (resData, status) => {
    let arr = [],
      response = { ...resData };
    if (response !== undefined && response.data.length > 0) {
      response.data.map((row) => arr.push(row));
    }
    if (!status) {
      allCampaigns = [...allCampaigns, ...arr];
      setCampaigns([...allCampaigns]);
    } else {
      setCampaigns([...allCampaigns, ...arr]);
    }
    setLoading(false);
  };
  // campaign sent  - excel report
  const onSuccessReport = (resData, status) => {
    let arr = [],
      response = { ...resData };
    if (response !== undefined && response.Items.length > 0) {
      response.Items.map((rowData) => {
        let row = { ...rowData };
        row.SentAtIST =
          row.SentAtIST !== undefined
            ? moment(row.SentAtIST).format("DD-MMM-YYYY,h:mm:ss a")
            : "-";

        arr.push(row);
      });
    }
    if (!status) {
      allCampaignsReport = [...allCampaignsReport, ...arr];
      setCampaignsReport([...allCampaignsReport]);
    } else {
      setCampaignsReport([...allCampaignsReport, ...arr]);
    }
  };

  const fetchData = () => {
    allCampaigns = [];
    setCampaigns([]);
    setReportLoading(true);
    setLoading(true);
    ApiWrapper("getCampaigns", {}, null, (resp) => {
      onSuccess(resp, true);
    }).then(
      (response) => {
        onSuccess(response, false);
      },
      (error) => {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
        if (!error instanceof DOMException) {
          setLoading(false);
        }
      }
    );
    // campaigns sent  - excel report

    ApiWrapper("getCampaignsReport", {}, null, (resp) => {
      allCampaignsReport = [];
      setCampaignsReport([]);
      onSuccessReport(resp, true);
    }).then(
      (response) => {
        onSuccessReport(response, false);
        setReportLoading(false);
      },
      (error) => {
        if (!error instanceof DOMException) {
          setReportLoading(false);
        }
      }
    );
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div class="flex justify-between">
        <p class="items-start mx-10"> *Note: Campaigns supported for Amazon. Flipkart not supported
        </p>
        <p class="items-end">
        <div class="grid grid-rows-2 grid-flow-col ">
          <Spin spinning={reportLoading}>
            <ExportTableButton
              columns={columns}
              fileName="Sent Email Report"
              dataSource={campaignsReport}
            >{`Sent Email Report`}</ExportTableButton>
          </Spin>
          <div className="m-2 text-xs font-normal">*Last 30 Days Report</div>
        </div>
        </p>
      </div>

      <div className="mx-10">
        <CustomTabs
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
          aria-label="Campaigns"
        >
          <CustomTab label="Active" />
          <CustomTab label="Inactive" />
        </CustomTabs>
        {tab === 0 && (
          <div className="grid grid-cols-4 gap-4 mt-6">
            {loading && (
              <React.Fragment>
                {[{}, {}, {}, {}].map((campaign, index) => (
                  <CampaignCard
                    key={index}
                    data={campaign}
                    refetchList={fetchData}
                    loading={loading}
                  />
                ))}
              </React.Fragment>
            )}
            {!loading && (
              <React.Fragment>
                {campaigns
                  .filter((campaign) => campaign.campaign_status === "on")
                  .map((campaign, index) => (
                    <CampaignCard
                      key={index}
                      data={campaign}
                      refetchList={fetchData}
                      loading={loading}
                    />
                  ))}
              </React.Fragment>
            )}
          </div>
        )}
        {tab === 1 && (
          <div className="grid grid-cols-4 gap-4 mt-6">
            {loading && (
              <React.Fragment>
                {[{}, {}, {}, {}].map((campaign, index) => (
                  <CampaignCard
                    key={index}
                    data={campaign}
                    refetchList={fetchData}
                    loading={loading}
                  />
                ))}
              </React.Fragment>
            )}
            {!loading && (
              <React.Fragment>
                {campaigns
                  .filter((campaign) => campaign.campaign_status === "off")
                  .map((campaign, index) => (
                    <CampaignCard
                      key={index}
                      data={campaign}
                      refetchList={fetchData}
                      loading={loading}
                    />
                  ))}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Campaigns;
