import React from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { withRouter } from "react-router-dom";
import AntTable from "../../components/AntTableV2/index.js";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader.js";
import { PostValues } from "../../utils/api-client.js";
import { Button, Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

function AllExport(props) {
  const [columns, setColumns] = React.useState([]);
  const [exportList, setExportList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const RefColumns = [
    {
      title: "Sno",
      align: "center",
      isTotal: false,
      field: "sno",
      width: 20,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          rowIndex
        ),
    },
    {
      title: "Report Type",
      align: "center",
      isTotal: false,
      field: "reportType.S",
      width: 80,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.reportType !== undefined && record?.params?.M?.payment ? (
          <>
            <p className="uppercase">{record?.reportType?.S}</p>
            <p className="capitalize">{`${record?.params?.M?.profitTab?.S}-${
              record?.params?.M?.payment?.S === "settled"
                ? "Without estimate"
                : "With estimate"
            }-${
              record?.params?.M?.toggle?.BOOL ? "Without Tax" : "With Tax"
            }`}</p>
          </>
        ) :  record.reportType.S === 'BuyboxSummary' ? (
          <>
            <p className="uppercase">{record?.reportType?.S}</p>
            <p className="capitalize">{record?.params?.M?.channels?.L[0]?.S}</p>
          </>
        ) : record.reportType !== undefined ? (
          <p className="uppercase">{record?.reportType?.S}</p>
        ) : (
          "-"
        ),
    },
    {
      title: "Period(FromDate-Todate)",
      align: "center",
      isTotal: false,
      field: "end_date.S",
      width: 100,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.params?.M?.fromDate !== undefined ? (
          ` ${moment(record?.params?.M?.fromDate?.S).format(
            "DD/MM/YYYY"
          )} - ${moment(record?.params?.M?.toDate?.S).format("DD/MM/YYYY")} `
        ) : (
          "-"
        ),
    },
    {
      title: "Status",
      align: "center",
      isTotal: false,
      field: "status.S",
      width: 75,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.status !== undefined ? (
          <p className="capitalize"> {record?.status?.S}</p>
        ) : (
          "-"
        ),
    },
    {
      title: "Updated At",
      align: "center",
      isTotal: false,
      field: "updatedAt.S",
      width: 75,
      defaultSortOrder: "descend",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.updatedAt !== undefined ? (
          moment(record?.updatedAt?.S).format("MMM Do YYYY, hh:mm:ss A")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = a?.updatedAt?.S
          ? a?.updatedAt?.S !== "-"
            ? new Date(
                (a?.updatedAt?.S || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        b = b?.updatedAt?.S
          ? b?.updatedAt?.S !== "-"
            ? new Date(
                (b?.updatedAt?.S || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        return a - b;
      },
      // sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: () => (
        <Tooltip title="Click here to Refresh the Page">
          <Button
            icon={<ReloadOutlined />}
            className="rounded-lg"
            onClick={handleChange}
          >
            {/* Refresh */}
          </Button>
        </Tooltip>
      ),
      align: "right",
      field: "",
      width: 60,
      render: (record, rowIndex, isLoading) => (
        <Button
          className="mr-12"
          variant="outlined"
          type="primary"
          loading={record?.status?.S !== "completed"}
          disabled={record?.status?.S !== "completed"}
          onClick={() => {
            GetData(record);
          }}
        >
          Export
        </Button>
      ),
    },
  ];
  React.useEffect(() => {
    setColumns(RefColumns);
  }, []);

  const getExportRecord = async () => {
    let response = await PostValues("getExportData", {});
    return response;
  };
  const getAlternativeDownloadApi = async (requestData) => {
    let response = await PostValues("getExpiredFileData", requestData);
    return response;
  };
  const handleMarketplaceChange = async () => {};

  const handleChange = async () => {
    setIsLoading(true);
    const exportData = await getExportRecord();
    if (exportData.Items) {
      setExportList([...exportData.Items]);
    }
    setIsLoading(false);
  };
  React.useEffect(() => {
    handleChange();
  }, []);

  const GetData = async (event) => {
    if (new Date().toISOString() < event?.reportExpiryDate?.S) {
      var url = event?.presigned_url?.S;
      var elem = document.createElement("a");
      elem.href = url;
      elem.click();
    } else {
      let requestData = {
        typeRequestId: event?.typeRequestId?.S,
      };
      const expiredFileDownload = await getAlternativeDownloadApi(requestData);
      var alternative_url = expiredFileDownload?.presigned_url;
      var alternative_elem = document.createElement("a");
      alternative_elem.href = alternative_url;
      alternative_elem.click();
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <CustomAppBar />
      <CustomFilterHeader
        headerTitle="Downloads"
        handleMarketplaceChange={handleMarketplaceChange}
        showDateRanges={false}
        showManualSync={false}
        channelHide={true}
        rows={exportList}
        searchHide={false}
        columns={columns}
        showExportButton={false}
        onFilterUpdate={(data) => {
          //   setFilteredSyncList(data);
        }}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          columns={RefColumns}
          dataSource={exportList}
          isLoading={isLoading}
          stickyHeader={{
            offsetHeader: 65,
          }}
          rowKey={(row, rowIndex) => rowIndex}
          hideSummary={true}
        />
      </div>
    </>
  );
}

export default withRouter(AllExport);
