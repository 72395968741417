import { uniq } from "lodash";
import { ApiTable } from "./ApiTable.js";
export let abortController = {};

let triggeredApi = {},
  requestIdList = {}
//  requestId = [];

export const generateRandomString = (length) => {
  var text = "";
  var possibleChars = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++)
    text += possibleChars.charAt(
      Math.floor(Math.random() * possibleChars.length)
    );
  return text;
};
// const tokenGenerator = () => {};
function ApiWrapper(name, jsonBody, authToken, onMethodSuccess) {
  const apiConfig = ApiTable[name];
  if (jsonBody?.channels && jsonBody?.channels.length) {
    jsonBody.channel = jsonBody?.channels[0];
  }
  if (jsonBody?.sales_channel) {
    jsonBody.channel = jsonBody?.sales_channel;
  }
  if (jsonBody?.channel) {
    if (
      triggeredApi[
        `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
      ]
    ) {
      Object.keys(
        triggeredApi[
          `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
        ]
      ).map((abortDataKey) => {
        triggeredApi[
          `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
        ][abortDataKey].abort();
      });
    }

    requestIdList[
      `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
    ] = {};
    triggeredApi[
      `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
    ] = !triggeredApi[
      `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
    ]
      ? {}
      : triggeredApi[
          `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
        ];
    // console.log(
    //   triggeredApi["https://kpi.dev.forcesight.in/dashboard-Amazon-India"]
    // );
  }
  if (apiConfig !== undefined) {
    switch (apiConfig.callType) {
      case "POST":
        return handlePostCall(apiConfig, jsonBody, authToken, onMethodSuccess);
      default:
        break;
    }
  }
}

function handlePostCall(apiConfig, jsonBody, authToken, onMethodSuccess) {
  if (apiConfig.isAuthenticated) {
    return new Promise(function (onSuccess, onError) {
      handleAuthPostCall(
        apiConfig,
        jsonBody,
        authToken,
        null,
        onSuccess,
        onError,
        onMethodSuccess
      );
    });
  } else {
    return new Promise(function (onSuccess, onError) {
      makeUnAuthPostCall(`${apiConfig.baseUrl}${apiConfig.endpoint}`, jsonBody)
        .then((response) => response.json())
        .then((data) => {
          onSuccess(data);
        })
        .catch((error) => {
          onError(error);
        });
    });
  }
}

function handleAuthPostCall(
  apiConfig,
  jsonBody,
  authToken,
  previousData,
  onSuccess,
  onError,
  onMethodSuccess
) {
  var newJsonBody;
  if (previousData !== null) {
    newJsonBody = {
      ...jsonBody,
      LastEvaluatedKey: previousData?.LastEvaluatedKey,
    };
  } else {
    newJsonBody = jsonBody;
  }
  let apiKey = generateRandomString(6);
  if (
    triggeredApi[
      `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
    ]
  )
    triggeredApi[
      `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
    ][apiKey] = new AbortController();
  // abortController[apiKey] = new AbortController(); //create a new instance everytime
  makeAuthPostCall(
    `${apiConfig.baseUrl}${apiConfig.endpoint}`,
    newJsonBody,
    authToken == null ? localStorage.token : authToken,
    {},
    apiKey,
    `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 403) {
        return handleAuthTokenExpiry(
          response,
          apiConfig,
          newJsonBody,
          authToken,
          previousData,
          onSuccess,
          onError,
          onMethodSuccess
        ).then(
          function pass(response) {
            return response;
          },
          function (error) {
            throw error;
          }
        );
      } else if (response.status === 401) {
        removeTokenAndShowLogin();
        throw new Error(response);
      } else {
        throw new Error(response);
      }
    })
    .then((data) => {
      triggeredApi[
        `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
      ] &&
        delete triggeredApi[
          `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
        ][apiKey];
      //  abortController[apiKey];
      if (data.typeRequestId !== undefined) {
        // requestIdList[`${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`]={}
        requestIdList[
          `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
        ][data.typeRequestId] = true;

        handleTypeRequestIdCall(
          data.typeRequestId,
          onSuccess,
          `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`,
          newJsonBody.channel
        );
      } else if (data.url !== undefined) {
        handleUrlTypeRequestCall(
          data.url,
          onSuccess,
          `${apiConfig.baseUrl}${apiConfig.endpoint}-${jsonBody?.channel}`
        );
      } else if (data.LastEvaluatedKey !== undefined) {
        var newData;
        if (previousData !== null) {
          // console.log(previousData);
          let newItems;
          if (data?.Items) {
            newItems = Array.isArray(data?.Items)
              ? previousData.Items.concat(data?.Items)
              : { ...previousData.Items, ...data?.Items };
          } else if (data?.data) {
            newItems = Array.isArray(data?.data)
              ? previousData.Items.concat(data?.data)
              : { ...previousData.Items, ...data?.data };
          }

          newData = {
            ...previousData,
            ...(data?.fulfillment_center_ids && {
              fulfillment_center_ids: uniq([
                ...(previousData.fulfillment_center_ids || []),
                ...(data?.fulfillment_center_ids || []),
              ]),
            }),

            Items: newItems,
            LastEvaluatedKey: data?.LastEvaluatedKey,
          };
        } else {
          newData = data;
        }
        // let finalData = {
        //   ...previousData,
        //   Items: newData,
        // };
        // console.log(onMethodSuccess);
        onMethodSuccess && onMethodSuccess(newData);
        handleAuthPostCall(
          apiConfig,
          jsonBody,
          authToken,
          newData,
          onSuccess,
          onError,
          onMethodSuccess
        );
      } else if (previousData !== null && previousData?.Items !== undefined) {
        let newItems = Array.isArray(data?.Items)
          ? previousData.Items.concat(data?.Items)
          : { ...previousData.Items, ...data?.Items };

        let finalData = {
          ...previousData,
          Items: newItems,
        };
        // console.log(finalData);
        onSuccess(finalData);
      } else {
        onSuccess(data);
      }
    })
    .catch((error) => {
      onError && onError(error);
    });
}

async function makeUnAuthPostCall(url, jsonBody) {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonBody),
  });
}

async function makeAuthPostCall(
  url,
  jsonBody,
  authToken,
  reqParam,
  apiKey,
  triggerApiConfig
) {
  // console.log(url,apiKey,triggeredApi[`${triggerApiConfig}`]?.[apiKey]?.signal)
  const signal = apiKey
    ? triggeredApi[`${triggerApiConfig}`]?.[apiKey]?.signal || null
    : null;
  return fetch(url, {
    method: "POST",
    ...{ reqParam },
    headers: {
      "Content-Type": "application/json",
      "X-FS-Authorization": authToken,
    },
    signal: signal, //new AbortController(),

    body: JSON.stringify(jsonBody),
  });
}

async function handleAuthTokenExpiry(
  originalResponse,
  apiConfig,
  jsonBody,
  authToken,
  previousData,
  onSuccess,
  onError
) {
  let responseData = await originalResponse.json();

  if (responseData?.authorization_status === 1) {
    let error = {
      errorCode: 1,
      errorMessage:
        responseData.message !== null
          ? responseData.message
          : "User does not have permission to call this API",
    };

    return new Promise(function (onPass, onFail) {
      onFail(error);
    });
  } else {
    const refreshApiConfig = ApiTable["refreshTokenSelf"];
    let requestJsonBody = {
      email: localStorage.email,
      refresh_token: localStorage.refresh_token,
    };
    let response = await makeAuthPostCall(
      `${refreshApiConfig.baseUrl}${refreshApiConfig.endpoint}`,
      requestJsonBody,
      localStorage.token,
      { redirect: "manual" }
    );
    // console.log(response);
    if (response.redirected) {
      window.location.href = response.url;
    }
    if (response.status === 401) {
      window.location.href = process.env.REACT_APP_API_LOGIN_PAGE;
    }
    // TODO: what if status is other than 200?
    if (response.status === 200) {
      let responseBodyJson = await response.json();
      localStorage.setItem("token", responseBodyJson.fs_auth_token);
      localStorage.setItem("refresh_token", responseBodyJson.fs_refresh_token);

      let originalCallNewResponse = await makeAuthPostCall(
        `${apiConfig.baseUrl}${apiConfig.endpoint}`,
        jsonBody,
        localStorage.token
      );

      return originalCallNewResponse.json();
    }
  }
}

async function handleTypeRequestIdCall(
  typeRequestId,
  onSuccess,
  triggerApiConfig,
  channel
) {
  const apiConfig = ApiTable["getResponse"];
  var isRequestPending = true,
    isHandleURL = false;
  while (
    isRequestPending &&
    requestIdList[`${triggerApiConfig}`][typeRequestId]
  ) {
    // requestId.push(typeRequestId)
    //console.log("While Executing  " +`${triggerApiConfig}` ,requestIdList)
    let apiKey = generateRandomString(8);
    triggeredApi[`${triggerApiConfig}`][apiKey] = new AbortController();
    // console.log(`${triggerApiConfig}`, apiKey);
    let response = await makeAuthPostCall(
      `${apiConfig.baseUrl}${apiConfig.endpoint}?typeRequestId=${typeRequestId}`,
      { channel },
      localStorage.token,
      {},
      apiKey,
      triggerApiConfig
    );
    triggeredApi[`${triggerApiConfig}`] &&
      delete triggeredApi[`${triggerApiConfig}`][apiKey];

    if (response.status === 200) {
      let responseBodyJson = await response.json();

      if (responseBodyJson.Item.status.S === "done") {
        isRequestPending = false;
        // requestIdList[`${triggerApiConfig}`]=false;

        if (responseBodyJson.Item.data?.S) {
          let responseDataJson = JSON.parse(responseBodyJson.Item.data.S);
          if (responseDataJson && responseDataJson.url) {
            isHandleURL = true;
            handleUrlTypeRequestCall(
              responseDataJson.url,
              onSuccess,
              triggerApiConfig
            );
            continue;
          }
        }
        let parsedResponse = JSON.parse(responseBodyJson.Item.data.S);

        onSuccess(parsedResponse);
      } else {
        console.log("Sleep");
        await sleep(5000);
      }
    }
  }
  if (requestIdList[`${triggerApiConfig}`]) {
    if (!isHandleURL) {
      onSuccess({});
    }
    delete requestIdList[`${triggerApiConfig}`][typeRequestId];
    // requestIdList[`${triggerApiConfig}`]=false;
  }
}

async function handleUrlTypeRequestCall(url, onSuccess, triggerApiConfig) {
  let apiKey = generateRandomString(6);
  triggeredApi[`${triggerApiConfig}`][apiKey] = new AbortController();
  const signal = apiKey
    ? triggeredApi[`${triggerApiConfig}`]?.[apiKey]?.signal || null
    : null;
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "X-FS-Authorization": localStorage.token,
    },
    signal, //new AbortController(),
  };

  fetch(url, options)
    .then((response) => response.json())
    .then((rawResponse) => {
      // profitabilityDataTmp.push(data);
      // setProfitabilityData([...profitabilityDataTmp]);
      let response = {};
      triggeredApi[`${triggerApiConfig}`][apiKey] &&
        delete triggeredApi[`${triggerApiConfig}`][apiKey];
      response.status = true;
      response.data = rawResponse;
      onSuccess(response);
      // console.log(
      //   "===============\n" + JSON.stringify(response) + "\n===============\n"
      // );
    })
    .catch((error) => console.error(error));
}

function sleep(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time || 1000);
  });
}

function removeTokenAndShowLogin() {
  localStorage.removeItem("token");
  window.open(
    `${process.env.REACT_APP_API_LOGIN_PAGE}?redirect_reason=auth_failure`,
    "_self"
  );
}

export default ApiWrapper;
