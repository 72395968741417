import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import { scaleQuantile } from 'd3-scale';
import india from '../../assets/topojsons/india.json';
import LinearGradient from './LinearGradient.js';
import { Button } from 'antd';
import { useHistory } from "react-router-dom";

const _ = require("lodash");

// Red Variants
const COLOR_RANGE = [
  "#f7fbff",
  "#deebf7",
  "#c6dbef",
  "#9ecae1",
  "#6baed6",
  "#4292c6",
  "#2171b5",
  "#08519c",
  "#08306b"
];

const DEFAULT_COLOR = '#D6D6DA' // Not Ava res;

const geographyStyle = {
  default: {
    outline: 'none'
  },
  hover: {
    fill: 'red',
    transition: 'all 250ms',
    outline: 'none'
  },
  pressed: {
    outline: 'none'
  }
};
const gradientData = {
  fromColor: COLOR_RANGE[0],
  toColor: COLOR_RANGE[COLOR_RANGE.length - 1]
};
function MapChart(props) {
  const history = useHistory();

  const RefColumns = [
    {
      title: "State",
      align: "left",
      key: "state",
      hidden: "true",
    },
    {
      title: "Total Orders",
      align: "left",
      key: "total_orders",
      hidden: "true",
    },
    {
      title: "Pending",
      align: "left",
      key: "pending",
      hidden: "true",
    },
    // {
    //   title: "Amount",
    //   align: "left",
    //   key: "amount",
    //   hidden: "true",
    // },
    {
      title: "Processing",
      align: "left",
      key: "processing",
      hidden: "true",
    },
    {
      title: "Delivered",
      align: "left",
      key: "delivered",
      hidden: "true",
    },
    {
      title: "Shipped",
      align: "left",
      key: "shipped",
      hidden: "true",
    },
    {
      title: "Cancelled",
      align: "left",
      key: "cancelled",
      hidden: "true",
    },
    {
      title: "return",
      align: "left",
      key: "return",
      hidden: "true",
    },
  ];
  const [arrayData, setArrayData] = useState([])
  const [columns, setColumns] = React.useState(RefColumns);

  const colorScale = scaleQuantile()
    .domain(
      Object.entries(props?.resData?.total_state).map(
        d => d?.[1]?.[0]?.QuantityShippedSum
      )
    )
    .range(COLOR_RANGE);
  const onMouseEnter = (geo, current = { count: 'NA' }) => {
    if (current[1]) {
      return () => {
        props.setTooltipContent(current[1][0]);
        props.setTooltipContentState(`${geo.properties.st_nm}`);
      }
    } else {
      return () => {
        props.setTooltipContentState(`${geo.properties.st_nm}`);
      }
    }
  };
  // useEffect(() => {
  //   if (props?.resData) {
  //     const arr = Object.entries(props?.resData?.total_state).map(([key, value]) => ({ state: key, pending: value?.PENDING, amount: value?.amount, total_orders: value?.total_orders, processing: value?.PROCESSING, delivered: value?.DELIVERED, shipped: value?.SHIPPED, cancelled: value?.CANCELLED, return: value?.['RETURN DELIVERED'] }));
  //     setArrayData(arr)
  //   }
  // }, [props?.resData])

  const onMouseLeave = () => {
    props.setTooltipContent('');
    props.setTooltipContentState('');

  };

  return (
    <>
      <div className="relative border rounded bg-white p-6 mb-2.5">
        <div class="relative">
          <p className="small-h absolute left-0">
            Order Count
          </p>
          <p class="absolute right-0">
          <Button type='link' onClick={() => history.push("/map")}>
            Detailed map view
          </Button>

            {/* {
              arrayData && (
                <ExportTableButton
                  className="h-11"
                  columns={columns}
                  fileName="State_Export"
                  dataSource={arrayData}
                />
              )
            } */}
          </p>
        </div>
        <ComposableMap data-tip="" projection="geoMercator" width={150} height={145} projectionConfig={{ scale: 220 }}>
          <ZoomableGroup zoom={1} center={[80, 22]}>
            <Geographies geography={india}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const current = Object.entries(props?.resData?.total_state).find(s =>
                    s[0] === geo.properties.st_nm.toUpperCase()
                  )
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={onMouseEnter(geo, current)}
                      onMouseLeave={onMouseLeave}
                      onClick={() => {
                        const { st_nm } = geo.properties;
                        history.push("/map")
                        props.setStateName(`${st_nm}`);
                        props.setShowDistrict(true);
                      }}
                      fill={current?.[1]?.[0]?.QuantityShippedSum ? colorScale(current?.[1]?.[0]?.QuantityShippedSum) : DEFAULT_COLOR}
                      style={geographyStyle}
                    />
                  )
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>

        <div style={{
          position: "absolute",
          left: "80px",
          bottom: "10px",
        }}>
          <LinearGradient data={gradientData} />
        </div>

        {/* <div class="flex justify-end ...">
          <Button type='link' onClick={() => history.push("/map")}>
            Detailed map view
          </Button>
        </div> */}
      </div>

    </>
  );
};

export default MapChart;