export const OutOfStockCtaColumns = [
  {
    title: "Channel",
    align: "center",
    key:"channel",
    render: (text, record, index) => (
      <p>
        {record.channel === "amazon" ? (
          <div className="amazon-logo-small" />
        ) : record.channel === "flipkart" || record.channel !== "amazon" ? (
          <div className="flipkart-logo-small" />
        ) : (
          "-"
        )}
      </p>
    )
  },
  {
    title: "SKU",
    align: "center",
    key:"sku",
    defaultSortOrder: "descend",
    render: (text, record, index) => <p>{text.sku}</p>,
    sorter: (a, b) => {
      a = (a.sku || "").toLowerCase();
      b = (b.sku || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Product ID",
    align: "center",
    key:"productId",
    render: (text, record, index) => <p>{text.productId}</p>,
    sorter: (a, b) => {
      a = (a.productId || "").toLowerCase();
      b = (b.productId || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Fulfillment Channel",
    align: "center",
    key:"fulfillmentChannel",
    render: (text, record, index) => <p>{text.fulfillmentChannel}</p>,
    sorter: (a, b) => {
      a = (a.fulfillmentChannel || "").toLowerCase();
      b = (b.fulfillmentChannel || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Status",
    align: "center",
    key:"status",
    render: (text, record, index) => <p>{text.status}</p>,
    sorter: (a, b) => {
      a = (a.status || "").toLowerCase();
      b = (b.status || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Quantity",
    align: "center",
    key:"quantity",
    render: (text, record, index) => <p>{text.quantity}</p>,
    sorter: (a, b) => a.quantity - b.quantity
  }
];

export const OverstockCtaColumns = [
  {
    title: "Channel",
    align: "center",
    key:"channel",
    render: (text, record, index) => (
      <p>
        {record.channel === "amazon" ? (
          <div className="amazon-logo-small" />
        ) : record.channel === "flipkart" ||  record.channel !== "amazon" ? (
          <div className="flipkart-logo-small" />
        ) : (
          "-"
        )}
      </p>
    )
  },
  {
    title: "SKU",
    align: "center",
    key:"sku",
    defaultSortOrder: "descend",
    render: (text, record, index) => <p>{text.sku}</p>,
    sorter: (a, b) => {
      a = (a.sku || "").toLowerCase();
      b = (b.sku || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Product ID",
    align: "center",
    key:"productId",
    render: (text, record, index) => <p>{text.productId}</p>,
    sorter: (a, b) => {
      a = (a.productId || "").toLowerCase();
      b = (b.productId || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Fulfillment Channel",
    align: "center",
    key:"fulfillmentChannel",
    render: (text, record, index) => <p>{text.fulfillmentChannel}</p>,
    sorter: (a, b) => {
      a = (a.fulfillmentChannel || "").toLowerCase();
      b = (b.fulfillmentChannel || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Status",
    align: "center",
    key:"status",
    render: (text, record, index) => <p>{text.status}</p>,
    sorter: (a, b) => {
      a = (a.status || "").toLowerCase();
      b = (b.status || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Quantity",
    align: "center",
    key:"quantity",
    render: (text, record, index) => <p>{text.quantity}</p>,
    sorter: (a, b) => a.quantity - b.quantity
  },
  {
    title: "Avg. Sales Per Day",
    align: "center",
    key:"Avg_Sales_Per_Day",
    render: (text, record, index) => (
      <p>
        {Math.round((text.Avg_Sales_Per_Day *100)/100)}
      </p>
    ),
    sorter: (a, b) => a.Avg_Sales_Per_Day - b.Avg_Sales_Per_Day
  }
];

export const ProfitCtaColumns = [
  {
    title: "Channel",
    align: "center",
    key:"Channel",
    width: 80,
    render: (text, record, index) => (
      <p>
        {record.Channel === "Amazon-India" ? (
          <div className="amazon-logo-small" />
        ) : record.Channel === "flipkart"  || record.Channel === "Flipkart"? (
          <div className="flipkart-logo-small" />
        ) : (
          "-"
        )}
      </p>
    )
  },
  // {
  //   title: "Product ID",
  //   align: "center",
  //   key:"ProductId",
  //   render: (text, record, index) => <p>{text.ProductId}</p>,
  //   sorter: (a, b) => {
  //     a = (a.ProductId || "").toLowerCase();
  //     b = (b.ProductId || "").toLowerCase();
  //     return a > b ? -1 : b > a ? 1 : 0;
  //   }
  // },
  {
    title: "SKU",
    align: "center",
    key:"SKU",
    width: 80,
    defaultSortOrder: "descend",
    render: (text, record, index) => <p>{text.SKU}</p>,
    sorter: (a, b) => {
      a = (a.SKU || "").toLowerCase();
      b = (b.SKU || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Qty Sold",
    align: "center",
    key:"QuantityShippedSum",
    width: 80,
    render: (text, record, index) => <p>{text.QuantityShippedSum}</p>,
    sorter: (a, b) => a.QuantityShippedSum - b.QuantityShippedSum
  },
  {
    title: "Invoice Price",
    align: "center",
    key:"InvoicePriceTotalSum",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text?.InvoicePriceTotalSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.InvoicePriceTotalSum - b?.InvoicePriceTotalSum
  },
  {
    title: "Product Cost",
    align: "center",
    key:"CostPerOrderSum",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text?.CostPerOrderSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.CostPerOrderSum - b?.CostPerOrderSum
  },
  {
    title: "Marketplace Fees",
    align: "center",
    key:"MarketplaceFeesTotalSum",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text?.MarketplaceFeesTotalSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.MarketplaceFeesTotalSum - b?.MarketplaceFeesTotalSum
  },
  {
    title: "Ad Spend",
    align: "center",
    key:"AdsSpentSum",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text.AdsSpentSum
            ? '-' + text.AdsSpentSum.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
              style: "currency",
              currency: "INR",
            })
            : "-"}
      </p>
    ),
    sorter: (a, b) => a?.AdsSpentSum - b?.AdsSpentSum
  },
  {
    title: "Shipping Fees",
    align: "center",
    key:"ShippingFeesTotalSum",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text?.ShippingFeesTotalSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.ShippingFeesTotalSum - b?.ShippingFeesTotalSum
  },
  // {
  //   title: "TCS",
  //   align: "center",
  //   key:"TCS",
  //   render: (text, record, index) => (
  //     <p>
  //       {text?.TCS?.toLocaleString("en-IN", {
  //         maximumFractionDigits: 2,
  //         style: "currency",
  //         currency: "INR"
  //       })}
  //     </p>
  //   ),
  //   sorter: (a, b) => a?.TCS - b?.TCS
  // },
  // {
  //   title: "AdSpent",
  //   align: "center",
  //   key:"AdsSpent",
  //   render: (text, record, index) => (
  //     <p>
  //       {text?.AdsSpent?.toLocaleString("en-IN", {
  //         maximumFractionDigits: 2,
  //         style: "currency",
  //         currency: "INR"
  //       })}
  //     </p>
  //   ),
  //   sorter: (a, b) => a?.AdsSpent - b?.AdsSpent
  // },
  {
    title: "Return Price",
    align: "center",
    key:"ReturnPriceTotalSum",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text?.ReturnPriceTotalSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.ReturnPriceTotalSum - b?.ReturnPriceTotalSum
  },
  {
    title: "Profit",
    align: "center",
    key:"Profit",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text?.Profit?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.Profit - b?.Profit
  },
  {
    title: "Profit Margin",
    align: "center",
    key:"ProfitMargin",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text?.ProfitMargin?.toLocaleString("en-IN", {
          maximumFractionDigits: 2
        })}
        {"%"}
      </p>
    ),
    sorter: (a, b) => a?.ProfitMargin - b?.ProfitMargin
  }
];

export const ProfitCtaMargin = [
  {
    title: "Channel",
    align: "center",
    key:"Channel",
    render: (text, record, index) => (
      <p>
        {record.Channel === "Amazon-India" ? (
          <div className="amazon-logo-small" />
        ) : record.Channel === "Flipkart" || record.Channel === "Flipkart" ? (
          <div className="flipkart-logo-small" />
        ) : (
          "-"
        )}
      </p>
    )
  },
  {
    title: "SKU",
    align: "center",
    key:"SKU",
    defaultSortOrder: "descend",
    render: (text, record, index) => <p>{text.SKU}</p>,
    sorter: (a, b) => {
      a = (a.SKU || "").toLowerCase();
      b = (b.SKU || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Qty Sold",
    align: "center",
    key:"QuantityShippedSum",
    render: (text, record, index) => <p>{text.QuantityShippedSum}</p>,
    sorter: (a, b) => a.QuantityShippedSum - b.QuantityShippedSum
  },
  {
    title: "Invoice Price",
    align: "center",
    key:"InvoicePriceTotalSum",
    render: (text, record, index) => (
      <p>
        {text?.InvoicePriceTotalSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.InvoicePriceTotalSum - b?.InvoicePriceTotalSum
  },
  {
    title: "Product Cost",
    align: "center",
    key:"CostPerOrderSum",
    render: (text, record, index) => (
      <p>
        {text?.CostPerOrderSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.CostPerOrderSum - b?.CostPerOrderSum
  },
  {
    title: "Marketplace Fees",
    align: "center",
    key:"MarketplaceFeesTotalSum",
    render: (text, record, index) => (
      <p>
        {text?.MarketplaceFeesTotalSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.MarketplaceFeesTotalSum - b?.MarketplaceFeesTotalSum
  },
  {
    title: "Ad Spend",
    align: "center",
    key:"AdsSpentSum",
    width: 80,
    render: (text, record, index) => (
      <p>
        {text.AdsSpentSum
            ? '-' + text.AdsSpentSum.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
              style: "currency",
              currency: "INR",
            })
            : "-"}
      </p>
    ),
    sorter: (a, b) => a?.AdsSpentSum - b?.AdsSpentSum
  },
  {
    title: "Shipping Fees",
    align: "center",
    key:"ShippingFeesTotalSum",
    render: (text, record, index) => (
      <p>
        {text?.ShippingFeesTotalSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.ShippingFeesTotalSum - b?.ShippingFeesTotalSum
  },
  // {
  //   title: "TCS",
  //   align: "center",
  //   key:"TCS",
  //   render: (text, record, index) => (
  //     <p>
  //       {text?.TCS?.toLocaleString("en-IN", {
  //         maximumFractionDigits: 2,
  //         style: "currency",
  //         currency: "INR"
  //       })}
  //     </p>
  //   ),
  //   sorter: (a, b) => a?.TCS - b?.TCS
  // },
  // {
  //   title: "AdSpent",
  //   align: "center",
  //   key:"AdsSpent",
  //   render: (text, record, index) => (
  //     <p>
  //       {text?.AdsSpent?.toLocaleString("en-IN", {
  //         maximumFractionDigits: 2,
  //         style: "currency",
  //         currency: "INR"
  //       })}
  //     </p>
  //   ),
  //   sorter: (a, b) => a?.AdsSpent - b?.AdsSpent
  // },
  {
    title: "Return Price",
    align: "center",
    key:"ReturnPriceTotalSum",
    render: (text, record, index) => (
      <p>
        {text?.ReturnPriceTotalSum?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.ReturnPriceTotalSum - b?.ReturnPriceTotalSum
  },
  {
    title: "Profit",
    align: "center",
    key:"Profit",
    render: (text, record, index) => (
      <p>
        {text?.Profit?.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR"
        })}
      </p>
    ),
    sorter: (a, b) => a?.Profit - b?.Profit
  },
  {
    title: "Profit Margin",
    align: "center",
    key:"ProfitMargin",
    render: (text, record, index) => (
      <p>
        {text?.ProfitMargin?.toLocaleString("en-IN", {
          maximumFractionDigits: 2
        })}
        {"%"}
      </p>
    ),
    sorter: (a, b) => a?.ProfitMargin - b?.ProfitMargin
  }
];

export const SellerReviewsCtaColumns = [
  {
    title: "Channel",
    align: "center",
    key:"channel",
    render: (text, record, index) => (
      <p>
        {record.channel === "amazon" ? (
          <div className="amazon-logo-small" />
        ) : record.channel === "flipkart" ? (
          <div className="flipkart-logo-small" />
        ) : (
          "-"
        )}
      </p>
    )
  },
  {
    title: "Rating",
    align: "center",
    key:"rating",
    defaultSortOrder: "descend",
    render: (text, record, index) => <p>{text.rating}</p>,
    sorter: (a, b) => a.rating - b.rating
  },
  {
    title: "Reviews",
    align: "center",
    key:"reviews",
    render: (text, record, index) => <p>{text.reviews}</p>
  },
  {
    title: "Date",
    align: "center",
    key:"date",
    render: (text, record, index) => <p>{text.date}</p>,
    sorter: (a, b) => {
      a = (a.date || "").toLowerCase();
      b = (b.date || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Username",
    align: "center",
    key:"user_name",
    render: (text, record, index) => <p>{text.user_name}</p>,
    sorter: (a, b) => {
      a = (a.user_name || "").toLowerCase();
      b = (b.user_name || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Review date",
    align: "center",
    key:"review_date",
    render: (text, record, index) => <p>{text.review_date}</p>,
    sorter: (a, b) => {
      a = (a.review_date || "").toLowerCase();
      b = (b.review_date || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  }
];

export const ProductReviewsCtaColumns = [
  {
    title: "Channel",
    align: "center",
    key:"channel",
    render: (text, record, index) => (
      <p>
        {record.channel === "amazon" ? (
          <div className="amazon-logo-small" />
        ) : record.channel === "flipkart" ||  record.channel !== "amazon" ? (
          <div className="flipkart-logo-small" />
        ) : (
          "-"
        )}
      </p>
    )
  },
  {
    title: "Product Id",
    align: "center",
    key:"product_id",
    render: (text, record, index) => <p>{text.product_id}</p>,
    sorter: (a, b) => {
      a = (a.product_id || "").toLowerCase();
      b = (b.product_id || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "SKU",
    align: "center",
    key:"sku",
    defaultSortOrder: "descend",
    render: (text, record, index) => <p>{text.sku}</p>,
    sorter: (a, b) => {
      a = (a.sku || "").toLowerCase();
      b = (b.sku || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Date",
    align: "center",
    key:"date",
    render: (text, record, index) => <p>{text.date}</p>,
    sorter: (a, b) => {
      a = (a.date || "").toLowerCase();
      b = (b.date || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
  },
  {
    title: "Review",
    align: "center",
    key:"reviews",
    render: (text, record, index) => <p>{text.reviews}</p>,
    sorter: (a, b) => a.reviews - b.reviews
  }
];
