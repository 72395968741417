import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React from "react";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",

    textAlign: "center",
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
      outline: 'none',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function TabsContainer(props) {
  const [value, setValue] = React.useState(0);

  return (
    <div>
      <AntTabs
        value={props.value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (props.onChange) {
            props.onChange(newValue);
          }
        }}
        aria-label="ant example"
      >
        {(props.list || []).map((tab, tabIndex) => {
          return <AntTab label={tab} disabled={tab == 'Keywords' && true} key={`TabContainer-${tabIndex}`} />;
        })}
      </AntTabs>
    </div>
  );
}
