import {
  Box,
  Button,
  Drawer,
  Grid,
  InputBase,
  Select,
  Typography,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useEffect, useState } from "react";
import "react-day-picker/lib/style.css";
import { withRouter } from "react-router-dom";
import ".././App.css";
import { TableContext } from "../context/TableContent";
import { filterFunctions } from "../utils/filterFunc";
import { searchArray } from "../utils/Search";
import ExportTableButton from "./AntTable/ExportTable";
import SearchTableInput from "./AntTable/searchTableInput";

const querystring = require("querystring");

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

let numberFilter = [
  { label: "=", value: "=" },
  { label: "!=", value: "!=" },
  { label: ">", value: ">" },
  { label: ">=", value: ">=" },
  { label: "<", value: "<" },
  { label: "<=", value: "<=" },
];
let dateFilter = [
  { label: "is", value: "is" },
  { label: "is not", value: "not" },
  { label: "is after", value: "after" },
  { label: "is on or after", value: "onOrAfter" },
  { label: "is before", value: "before" },
  { label: "is on or before", value: "onOrBefore" },
];
let stringFilter = [
  { label: "contains", value: "contains" },
  { label: "equals", value: "equals" },
  { label: "starts with", value: "startsWith" },
  { label: "ends with", value: "endsWith" },
];
const useStyles = makeStyles((theme) => ({
  searchFilterBar: {
    minWidth: 260,
  },
  searchIcon: {
    marginLeft: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "inter",
    color: "#000000",
  },
  searchingText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#717480",
    fontFamily: "inter",
  },
  btnPrimaryOutlined: {
    // fontFamily: "inter",
    // background: "#FAFBFC",
    // color: "#97A0AF",
    // "&:hover": {
    //   background: "#FAFBFC",
    //   color: "#44444F",
    //   borderColor: "#237BEF",
    // },
  },
  btnPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#717480",
  },
  fontMedium: {
    fontWeigth: 500,
  },
}));

const FilterButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "inter",
  },
  outlined: {
    fontFamily: "Inter",
    background: "#FAFBFC",
    color: "#717480",
    borderColor: "#DFE1E6",
    "&:hover": {
      background: "#FAFBFC",
      color: "#717480",
      borderColor: "#C1C7D0",
    },
    "&:focus": {
      borderColor: "#C1C7D0",
      outlined: "none",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

// const ExportButton = withStyles({
//   root: {
//     fontSize: 14,
//     fontWeight: 400,
//     fontFamily: "inter",
//   },
//   outlined: {
//     fontFamily: "inter",
//     background: "#FAFBFC",
//     color: "#97A0AF",
//     "&:hover": {
//       background: "#FAFBFC",
//       color: "#44444F",
//       borderColor: "#237BEF",
//     },
//   },
//   label: {
//     textTransform: "capitalize",
//   },
// })(Button);

const QuickDateFilterButton = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inter",
    // border: "1px solid rgba(193, 199, 208, 1)",
    background: "#FAFBFC",
    color: "#97A0AF",
    borderColor: "#DFE1E6",
    "&:hover": {
      background: "#d5d9de",
      color: "#97A0AF",

      borderColor: "#C1C7D0",
    },
    "&$selected": {
      backgroundColor: "#FAFBFC",
      color: "#97A0AF",

      borderColor: "#C1C7D0",
    },
  },
  selected: {
    background: "#2684FF",
    color: "#000000",
  },
  label: {
    textTransform: "capitalize",
  },
  sizeSmall: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 6,
    paddingBottom: 6,
  },
})(ToggleButton);

const responseTypeFilters = [
  { status: 0, name: "New Reviews", id: "response-pending" },
  { status: 1, name: "Marked as Read", id: "responded" },
  { status: 2, name: "All", id: "all" },
];

function CustomSearchHeader(props) {
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState("");
  // const [selectedReviewStatus, setSelectedReviewStatus] = React.useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lookupTable, setLookupTable] = React.useState({});
  const [lookupTableData, setLookupTableData] = useState({});
  const [filterVisible, setfilterVisible] = useState(false);
  const [linkedOperator, setLinkedOperator] = useState("OR");
  const [filteredColumn, setFilteredColumn] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchRows, setSearchRows] = useState([]);
  const [resultRows, setResultRows] = useState([]);
  const [tempCol, setTempCol] = useState([]);
  const [ExportCol, setExportCol] = useState([]);
  const [profitToggle, setProfitToggle] = useState(false);
  const TableConsumerContext = React.useContext(TableContext);

  useEffect(() => {
    // if (resultRows.length) {
    props.onFilterUpdate(resultRows);
    // }
  }, [resultRows]);

  useEffect(() => {
    let i = 0;
    // console.log(filteredColumn);
    let colRef = (props.columns || [])
      .map((column, columnIndex) => {
        if (column.isFilterable && column.type && column.field) {
          // console.log(
          //   i,
          //   filteredColumn[i],
          //   (filteredColumn[i] || {}).operatorType,
          //   (filteredColumn[i] || {}).value
          // );
          let operatorType =
            (filteredColumn[i] || {}).field === column.field
              ? (filteredColumn[i] || {}).operatorType || ""
              : "";
          let operatorVal =
            (filteredColumn[i] || {}).field === column.field
              ? (filteredColumn[i] || {}).value || ""
              : "";
          i++;

          return {
            type: column.type,
            title: column.title,
            columnField: column.field,
            id: columnIndex,
            // type: filteredColumn[] "",
            operatorType: operatorType,
            value: operatorVal,
          };
        }
      })
      .filter((data) => data);
    setFilteredColumn(colRef);
    let exportCol = [];
    let tempCol = [...props.columns]
      .map((column) => {
        if (!column.dataIndex && column.field) {
          let isSearchable =
            column.searchPriority || column.onSearch || column.isSearchable;
          let columnList = {
            ...column,
            dataIndex: column.field,
            isSearchable: isSearchable,
          };
          exportCol.push(columnList);
          if (!column.hidden) {
            return columnList;
          }
        } else {
          exportCol.push(column);
          if (!column.hidden) {
            return column;
          }
        }
      })
      .filter((data) => data);
    setExportCol(exportCol);
    setTempCol(tempCol);
  }, [props.columns]);

  useEffect(() => {
    let lookupDataEff = {},
      lookupTableEff = {};
    (props.rows || []).map((row, rowIndex) => {
      lookupDataEff[rowIndex] = row;
      lookupTableEff[rowIndex] = null;
    });
    setLookupTable(lookupTableEff);
    setLookupTableData(lookupDataEff);

  }, [props.rows]);

  useEffect(() => {
    if (anchorEl) {
      setIsOpen(true);
    }
  }, [anchorEl]);
  useEffect(() => {
    let parsedObject = querystring.parse(
      props.location.search.substr(1, props.location.search.length)
    );
    setTimeout(() => {
      if (parsedObject.filter === "") {
        // onResetFilter();
        props.history.replace(props.location.pathname);
      }
    }, 1000);
  }, [props.location.search]);
  const onSearching = (event) => {
    setSearchText(event.target.value);
    if (props.onSearchTextEntered) {
      props.onSearchTextEntered(event.target.value);
    }
    if (event.target.value) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    // if (isFilter) {
    //   onApplySearch(filteredRows, event.target.value);
    // } else {
    //   onApplySearch(props.rows, event.target.value);
    // }
  };
  // const profitToggleChange = (event) => {
  //   console.log(event)
  //   setProfitToggle(event)
  //   if (props.onProfitToggleChange) {
  //     props.onProfitToggleChange(event);
  //   }
  // };

  const handleReviewStatusChange = (event, newReviewStatus) => {
    // setSelectedReviewStatus(newReviewStatus);
    props.onReviewTypeChanged(newReviewStatus);
    // switch (newReviewStatus) {
    //   case "response-pending":
    //     break;
    //   case "responded":
    //     break;
    //   default:
    //     break;
    // }
  };

  const onApplySearch = (rows, searchText) => {
    if (searchText) {
      setIsSearch(true);
      let searchFilters = {};
      (props.columns || [])
        .filter((col) => col.onSearch)
        .sort((a, b) => (a.searchPriority || 0) - (b.searchPriority || 0))
        .forEach(
          (col) => (searchFilters[col.field] = col.onSearch(searchText))
        );
      let searchResult = searchArray(rows, searchFilters);
      setSearchRows(searchResult);
      setResultRows(searchResult);
    } else {
      setIsSearch(false);
      setSearchRows(rows);
      setResultRows(rows);
    }
  };
  const onResetFilter = () => {
    let rows = isSearch ? [...searchRows] : [...props.rows];
    let i = 0;
    let colRef = (props.columns || [])
      .map((column, columnIndex) => {
        if (column.isFilterable && column.type && column.field) {
          // console.log(
          //   i,
          //   filteredColumn[i],
          //   (filteredColumn[i] || {}).operatorType,
          //   (filteredColumn[i] || {}).value
          // );
          let operatorType =
            (filteredColumn[i] || {}).field === column.field
              ? (filteredColumn[i] || {}).operatorType || ""
              : "";
          let operatorVal =
            (filteredColumn[i] || {}).field === column.field
              ? (filteredColumn[i] || {}).value || ""
              : "";
          i++;

          return {
            type: column.type,
            title: column.title,
            columnField: column.field,
            id: columnIndex,
            // type: filteredColumn[] "",
            operatorType: operatorType,
            value: operatorVal,
          };
        }
      })
      .filter((data) => data);
    setFilteredColumn(colRef);
    setIsFilter(false);
    setFilteredRows([]);
    setResultRows(rows);

    setfilterVisible(false);
  };

  const onApplyFilter = () => {
    let rows = isSearch ? [...searchRows] : [...props.rows];
    setIsFilter(true);
    let modifiedRow = [];
    let lookupTemp = { ...lookupTable };
    rows.map((row, rowIndex) => {
      if (typeof lookupTemp[rowIndex] === "boolean") {
        if (lookupTemp[rowIndex]) {
          if (linkedOperator === "OR") {
            return false;
          }
          // regular Condition
        } else {
          if (linkedOperator === "AND") {
            return false;
          }
        }
      }
      let filterCondition = true;
      filteredColumn.map((filter) => {
        let filterValue = null;
        if (filterCondition) {
          if (filter.operatorType) {
            let func = filterFunctions[filter.operatorType];
            // console.log(func);
            let cellData = row;

            filter.columnField.split(".").map((key) => {
              if (cellData[key]) {
                cellData = cellData[key];
              } else {
                cellData = "";
              }
              // cellData = genObj[key];
            });
            filterValue = func(filter, cellData);
            // console.log(filterValue, row[filter.columnField], cellData);
            lookupTemp[rowIndex] = filterValue;
            if (filterValue) {
              if (linkedOperator === "OR") {
                filterCondition = false;
              }
              // regular Condition
            } else {
              if (linkedOperator === "AND") {
                filterCondition = false;
              }
            }
          }
        }
      });
      if (lookupTemp[rowIndex]) {
        modifiedRow.push(row);
      }
      setFilteredRows(modifiedRow);

      setResultRows(modifiedRow);
      setfilterVisible(false);
    });
  };

  return (
    <>
      <div className="search-header">
        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 24 }}>
          <Drawer
            anchor={"right"}
            open={filterVisible}
            onClose={() => {
              setfilterVisible(false);
            }}
          >
            <div className="p-4" style={{ width: 550 }}>
              {filteredColumn.map((column, columnIndex) => {
                return (
                  <Box mb={2} key={columnIndex}>
                    <Grid container justify="flex-end" alignItems="center">
                      <Grid
                        xs={2}
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Select
                          onChange={(e) => {
                            setLinkedOperator(e.target.value);
                          }}
                          style={{
                            width: "100%",
                          }}
                          value={linkedOperator}
                          disabled={columnIndex !== 0}
                          input={<BootstrapInput />}
                        >
                          <MenuItem
                            key={`1`}
                            value={"OR"}
                            style={{ fontSize: 16 }}
                          >
                            OR
                          </MenuItem>
                          <MenuItem
                            key={`1`}
                            value={"AND"}
                            style={{ fontSize: 16 }}
                          >
                            AND
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid
                        xs={3}
                        style={{
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <Box pl={2}>{column.title}</Box>
                      </Grid>
                      <Grid xs={3}>
                        <Box pl={2} pr={2}>
                          <Select
                            onChange={(e) => {
                              let filterColTem = [...filteredColumn];
                              filterColTem[columnIndex].operatorType =
                                e.target.value;
                              setFilteredColumn(filterColTem);
                            }}
                            style={{
                              width: "100%",
                            }}
                            value={column.operatorType}
                            input={<BootstrapInput />}
                          >
                            {(column.type === "number"
                              ? numberFilter
                              : column.type === "date"
                                ? dateFilter
                                : stringFilter
                            ).map((option, optIndex) => {
                              return (
                                <MenuItem
                                  key={`Opt-${optIndex}`}
                                  value={option.value}
                                  style={{ fontSize: 16 }}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Box>
                      </Grid>
                      <Grid xs={4}>
                        {column.type === "date" && (
                          <TextField
                            type="date"
                            variant="outlined"
                            value={column.value}
                            // format="mm/dd/yyyy"
                            onChange={(e) => {
                              let filterColTem = [...filteredColumn];
                              filterColTem[columnIndex].value = e.target.value;
                              setFilteredColumn(filterColTem);
                            }}
                            style={{ width: "100%" }}
                            // className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        {column.type !== "date" && (
                          <TextField
                            value={column.value}
                            style={{ width: "100%" }}
                            type={column.type === "number" ? "number" : "text"}
                            label="Value"
                            variant="outlined"
                            onChange={(e) => {
                              let filterColTem = [...filteredColumn];
                              filterColTem[columnIndex].value = e.target.value;
                              setFilteredColumn(filterColTem);
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
              {/* <input value={column.value} style={{ width: "100%" }}
                                      type={column.type === "number" ? "number" : "text"} onChange={(e) => {
                                        let filterColTem = [...filteredColumn];
                                        filterColTem[columnIndex].value = e.target.value;
                                        setFilteredColumn(filterColTem)
                                      }} /> */}
              <div style={{ textAlign: "center" }}>
                <Button
                  color="outlinedPrimary"
                  className={` outlinedPrimary text-capitalize ${classes.subtitle} ${classes.fontMedium} ${classes.btnPrimaryOutlined}`}
                  size="large"
                  style={{ marginTop: 10, marginRight: 20 }}
                  onClick={() => {
                    onResetFilter();
                    // onApplyFilter();
                  }}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  className={`primary font-Inter text-sm text-capitalize ${classes.fontMedium} ${classes.btnPrimary} text-white `}
                  size="large"
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    onApplyFilter();
                  }}
                >
                  <span className="text-white">Save</span>
                </Button>
              </div>
            </div>
          </Drawer>
          <Box pt={2}>
            <div className={classes.root}>
              <Grid container justify="space-between">
                {props.searchInput ? (
                  <React.Fragment>
                    <Grid item>{props.searchInput}</Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {props.searchHide &&
                    <>
                      <Grid item>
                        <Box
                          className={classes.searchFilterBar}
                          bgcolor="#FAFBFC"
                          borderColor="#FAFBFC"
                          color="#B5B5BE"
                        >
                          <SearchTableInput
                            columns={tempCol}
                            searchFunction={props.searchFunction}
                            dataSource={isFilter ? filteredRows : props.rows}
                            onChange={(e) => {
                              onSearching(e);
                            }}
                            setDataSource={(row) => {
                              // console.log(row);
                              setSearchRows(row);
                              setResultRows(row);
                            }}
                          />
                        </Box>

                      </Grid>
                    
                    <Grid item>
                      <Grid>
                        {searchText.length > 0 ? (
                          <Box ml={2}>
                            <Typography className={classes.searchingText}>
                              Showing results for "{searchText}"
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                    </>
                    }
                  </React.Fragment>
                )}
                <Grid item>
                  <Grid container justify="flex-end" alignItems="center">
                    {props.showAllReviews && (
                      <React.Fragment>{props.showAllReviews}</React.Fragment>
                    )}
                    {props.showReviewToggle && (
                      <Box ml={2}>
                        <ToggleButtonGroup
                          size="small"
                          value={props.selectedReviewStatus}
                          exclusive
                          onChange={handleReviewStatusChange}
                        >
                          {responseTypeFilters.map((row, i) => (
                            <QuickDateFilterButton value={row.status} key={i}>
                              {row.name}
                            </QuickDateFilterButton>
                          ))}
                        </ToggleButtonGroup>
                      </Box>
                    )}
                    {props.publishButton && (
                      <React.Fragment>{props.publishButton}</React.Fragment>
                    )}
                    {/* {props.onProfitToggleChange && (
                      <Switch
                        defaultChecked={false}
                        checked={profitToggle}
                        onChange={(e) => {
                          profitToggleChange(e);
                        }}
                      />
                    )} */}
                    {/* custom toggle for profitablity page */}
                    {<>{props.customToggleProfit}</>}
                    {<>{props.customExportButton}</>}
                    {props.showExportButton && (
                      <ExportTableButton
                        bulkUpload={props.bulkUpload}
                        columns={ExportCol}
                        fileName={props.exportFileName}
                        getExportData={props.getExportData}
                        dataSource={TableConsumerContext.rows}
                      />
                    )}

                    {filteredColumn.length !== 0 && (
                      <Box
                        ml={2}
                        className="position-relative"
                        style={{ display: "none" }}
                      >
                        <FilterButton
                          aria-describedby={"TableFilterId"}
                          variant="outlined"
                          onClick={(event) => {
                            // setAnchorEl(event.currentTarget);
                            setfilterVisible(true);
                          }}
                        >
                          <div className={"filter-icon"} />
                          <div style={{ paddingLeft: 10 }}>Filter</div>
                        </FilterButton>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}

export default withRouter(CustomSearchHeader);
