import React, { useEffect, useState } from "react";
import AntTable from "../../../components/AntTableV2";
import { Tabs, Button, Select, Input, Divider, Image } from 'antd';
import ExportButton from "../../../components/Styles/ExportButton";
import { Box, Typography } from "@material-ui/core";
import _debounce from "lodash/debounce";

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

export default function SummaryTable(props) {
  const [channelName, setChannelName] = React.useState("Amazon-India");
  const [searchData, setsearchData] = React.useState("");
  const [reload, setReload] = useState(false);

  const OrderColumns = [

    {
      title: "Product",
      align: "center",
      field: "product_id",
      width: 140,
      isFilterable: true,
      isSearchable: true,
      type: "string",
      render: (record, value, rowIndex) => (
        <>
          {" "}
          <div
            className={`${
              record.channel === "Amazon-India"
                ? "amazon-logo-small"
                : record.channel === "Flipkart"
                ? "flipkart-logo-small"
                : null
            }`}
          />
          <div className="flex items-center justify-center">
            <Image
              width={30}
              height={30}
              src={record.product_image}
              fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
            />
              {" "}
              <Button className="-ml-3 text-sm" type="link">
                <a
                  href={
                    record.channel === "Amazon-India"
                      ? `https://www.amazon.in/dp/${record.product_id}`
                      : `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${record.product_id}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {record.product_id?.replace(/(.{15})..+/, "$1…") || ""}
                </a>
              </Button>
          </div>
        </>
      ),
      sorter: (a, b) => {
        a = (a.product_id || "").toLowerCase();
        b = (b.product_id || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product Name",
      align: "center",
      field: "product_name",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 180,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.product_name && record.product_name !== undefined ? (
          record.product_name.replace(/(.{40})..+/, "$1…")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.product_name || "").toLowerCase();
        b = (b.product_name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "No. of Sellers",
      align: "center",
      field: "number_of_unique_sellers",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      sorter: (a, b) => {
        a = (a || {}).number_of_unique_sellers || 0;
        b = (b || {}).number_of_unique_sellers || 0;
        return a - b;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Buy Box Owner",
      align: "center",
      field: "buybox",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isNegative: true,
      type: "string",
      sorter: (a, b) => {
        a = (a || {}).buybox || 0;
        b = (b || {}).buybox || 0;
        return a - b;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Owner's Price",
      align: "center",
      field: "owners_offer",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isNegative: true,
      type: "number",
      sorter: (a, b) => {
        a = (a || {}).owners_offer || 0;
        b = (b || {}).owners_offer || 0;
        return a - b;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Your Price",
      align: "center",
      field: "your_offer",
      width: 80,
      isTotal: true,
      isTotalCurrency: true,
      isFilterable: true,
      type: "string",
      // render: (record, rowIndex) => "-",
    },
    {
      title: "Offers with higher price",
      align: "center",
      field: "number_of_sellers_higher_than_seller_price",
      width: 80,
      isTotal: true,
      isTotalCurrency: true,
      isFilterable: true,
      type: "number",
    },
    {
      title: "Offers with lower price",
      align: "center",
      field: "number_of_sellers_lower_than_seller_price",
      isFilterable: true,
      width: 80,
      type: "number",
      isTotal: false,
    },
    {
      title: "No. of days w/o sales",
      align: "center",
      field: "number_of_days_without_sales",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      sorter: (a, b) => {
        a = (a || {}).number_of_days_without_sales || 0;
        b = (b || {}).number_of_days_without_sales || 0;
        return a - b;
      },
      sortDirections: ["descend", "ascend", "descend"],
      // render: (record, rowIndex) => "-",
    },
  ];
  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
  }) => {
    let channel = channelName;
    props.summatyTableApiCall(
      channel,
      paginationCondition,
      sortCondition,
      filterCondition
    );
  };
  useEffect(() => {
    if (props.activeTab === "1") {
      props.setColumn(OrderColumns);
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (reload) {
      props.summaryTableSearch(searchData, channelName);
    }
  }, [reload]);
  const channelFun = (event) => {
    setsearchData("");
    setChannelName(event);
    props.summatyTableApiCall(event);
  };
  const exportChannel = () => {
    props.summaryDataExport();
  };

  const callApi = () => {
    setReload(true);
  }; // You might be able to call API directly here, I haven't tried
  const [debouncedCallApi] = useState(() => _debounce(callApi, 1000));
  const [SelectSellers, setSelectSeller] = useState([]);
  const [sellersList, setSellerList] = useState([]);
  const [selectBool, setSelectBool] = React.useState(true);

  useEffect(() => {
    setSellerList(props.allSellerList);
  }, [props.allSellerList]);
  useEffect(() => {
    setSelectSeller(props.sellerListSummaryTable);
  }, [props.sellerListSummaryTable]);

  const onSearch = (event) => {
    setReload(false);
    debouncedCallApi();
    setsearchData(event.target.value);
  };

  const GetData = (SelectSellers) => {
    props.GetDataSummarySellerList(SelectSellers, channelName);
  };

  return (
    <div style={{ marginLeft: 20, marginRight: 20 }}>
      <div class="grid grid-cols-4 gap-4">
        <div>
          <Search
            placeholder="Search..."
            value={searchData}
            onChange={onSearch}
            style={{ width: 250 }}
            allowClear
          />
        </div>
        <div>
          <Select
            mode="multiple"
            dropdownRender={(menu) => {
              return (
                <div>
                  {selectBool && (
                    <div
                      className="text-primary text-sm cursor-pointer pb-2 border-b text-right p-2"
                      onClick={() => {
                        setSelectBool(false);
                        setSelectSeller(sellersList);
                      }}
                    >
                      Select all
                    </div>
                  )}
                  {!selectBool && (
                    <div
                      className="text-primary text-sm cursor-pointer pb-2 border-b text-left p-2"
                      onClick={() => {
                        setSelectBool(true);
                        setSelectSeller([]);
                      }}
                    >
                      Unselect all
                    </div>
                  )}

                  {menu}
                  <div className="flex items-center justify-between p-2 border-t pb-1 mt-2">
                    <div className="flex">
                      <div
                        className="text-primary text-sm cursor-pointer"
                        onClick={() => {
                          setSelectSeller([]);
                          GetData();
                        }}
                      >
                        Reset
                      </div>
                    </div>
                    <div>
                      <div
                        className="bg-primary text-white rounded p-2 pl-3 text-xs cursor-pointer"
                        onClick={() => {
                          GetData(SelectSellers);
                        }}
                      >
                        Apply
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
            placeholder="BuyBox owner"
            value={SelectSellers}
            style={{ width: 200 }}
            className="ml-5"
            onChange={(e) => {
              setSelectSeller(e);
              //   GetData(e);
            }}
            maxTagCount={0}
            maxTagPlaceholder={(values) => {
              return `Show: ${values.length} Owner${
                values.length > 1 ? "s" : ""
              } selected`;
            }}
          >
            {sellersList?.map((seller, sellerIndex) => {
              return (
                <Option key={`seller-${sellerIndex}`} value={seller}>
                  {seller}
                </Option>
              );
            })}
          </Select>
        </div>

        <div className="flex justify-center">
          {searchData?.length > 0 ? (
            <Box ml={2}>
              <Typography>Showing results for "{searchData}"</Typography>
            </Box>
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-end ...">
          <ExportButton variant="outlined" onClick={exportChannel}>
            Export to CSV
          </ExportButton>
        </div>
      </div>

      {/* </Spin> */}
      <Tabs defaultActiveKey="Amazon-India" onChange={channelFun}>
        <TabPane tab="Amazon-India" key="Amazon-India">
          <AntTable
            pageSizeSet={true}
            columns={OrderColumns}
            dataSource={props.summaryTable}
            stickyHeader={{
              offsetHeader: 65,
            }}
            rowKey={(row, rowIndex) => rowIndex}
            isLoading={props.isLoadingTable}
            hideSummary
            searchText={[props.searchText]}
            onDataChange={onTableChange}
            totalRecords={props.pageCount}
          />
        </TabPane>
        <TabPane tab="Flipkart" key="Flipkart">
          <AntTable
            pageSizeSet={true}
            columns={OrderColumns}
            dataSource={props.summaryTable}
            stickyHeader={{
              offsetHeader: 65,
            }}
            rowKey={(row, rowIndex) => rowIndex}
            isLoading={props.isLoadingTable}
            hideSummary
            searchText={[props.searchText]}
            onDataChange={onTableChange}
            totalRecords={props.pageCount}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
