import React from "react";
import OrderCellData from "./OrderCellData";

function OrdersDetailPanel(props) {
  const DetailCol = React.useMemo(() => {
    let data = [
      {
        isVisible: (props.row.InvoicePrice?.breakup?.length || props.row.estimatedFees?.InvoicePrice?.breakup?.length || 0) > 0,
        title: "Invoice Price",
        type: "InvoicePrice",
        marketplace:
          props.row.AmazonOrderId !== null ? "Amazon-India" : "Flipkart",
        breakup: props.row?.InvoicePrice?.breakup ||  props.row?.estimatedFees?.InvoicePrice?.breakup,
        isReconcilicationAvailable:
          props.row.Reconciliation?.InvoicePrice  || false,
        reconciliation: props.row.Reconciliation?.InvoicePrice || false,
        isLink: props.row?.AmazonOrderId || false,
      },
      {
        isVisible: (props.row.MarketplaceFees?.breakup?.length || props.row?.Reconciliation?.MarketplaceFees?.length  || 0) > 0,
        title: "Marketplace Fees",
        type: "MarketplaceFees",
        marketplace:
        props.row?.estimatedFees ? "Calculated" : props.row.AmazonOrderId !== null ? "Amazon-India" : "Flipkart",
        breakup: props.row?.MarketplaceFees?.breakup || props.row?.Reconciliation?.MarketplaceFees,
        isReconcilicationAvailable:
          props.row.Reconciliation?.MarketplaceFees || false,
        reconciliation: props.row.Reconciliation?.MarketplaceFees || null,
        isLink: props.row?.AmazonOrderId || false,
        isEstimation : props.row?.estimatedFees !== null ? true : false,
      },
      {
        isVisible: (props.row?.TCS?.breakup?.length ||  props.row?.estimatedFees?.TCS?.breakup?.length || 0) > 0,
        title: "TCS",
        type: "TCS",
        marketplace:
        props.row?.estimatedFees ? "Calculated" : props.row.AmazonOrderId !== null ? "Amazon-India" : "Flipkart",
        breakup: props.row?.TCS?.breakup || props.row?.estimatedFees?.TCS?.breakup,
        isReconcilicationAvailable: props.row?.Reconciliation?.TCS || false,
        reconciliation: props.row.Reconciliation?.TCS || false,
        isLink: props.row.AmazonOrderId !== undefined ? true : false,
        
      },
      {
        isVisible: (props.row.ShippingFees?.breakup?.length || props.row.AmazonOrderId && props.row?.estimatedFees?.ShippingFees?.breakup?.length  || 0) > 0,
        title: "Shipping Fees",
        type: "ShippingFees",
        marketplace:
        props.row?.estimatedFees ? "Calculated" : props.row.AmazonOrderId !== null ?  "Amazon-India" : "Flipkart",
        breakup: props.row?.ShippingFees?.breakup || props.row?.estimatedFees?.ShippingFees?.breakup,
        isReconcilicationAvailable:
          props.row.Reconciliation?.ShippingFees || false,
        reconciliation: props.row.Reconciliation?.ShippingFees || false,
        isLink: props.row.AmazonOrderId !== undefined ? true : false,
      },
      {
        isVisible: (props.row.PromotionFees?.breakup?.length || 0) > 0,
        title: "Promotion Fees",
        type: "PromotionFees",
        marketplace:
          props.row.AmazonOrderId !== null ? "Amazon-India" : "Flipkart",
        breakup: props.row?.PromotionFees?.breakup,
        isReconcilicationAvailable:
          props.row.Reconciliation?.PromotionFees || false,
        reconciliation: props.row.Reconciliation?.PromotionFees || false,
        isLink: props.row.AmazonOrderId !== undefined ? true : false,
      },
      {
        isVisible: (props.row.ReturnPrice?.breakup.length || 0) > 0,
        title: "Return Price",
        type: "ReturnPrice",
        marketplace:
          props.row.AmazonOrderId !== null ? "Amazon-India" : "Flipkart",
        breakup: props.row?.ReturnPrice?.breakup,
        isReconcilicationAvailable:
          props.row.Reconciliation?.ReturnPrice || false,
        reconciliation: props.row.Reconciliation?.ReturnPrice || false,
        isLink: props.row.AmazonOrderId !== undefined ? true : false,
    },
      {
        isVisible: (props.row.SettledAmount?.breakup?.length || 0) > 0,
        title: "Settled Amount",
        type: "SettledAmount",
        marketplace:
          props.row.AmazonOrderId !== null ? "Amazon-India" : "Flipkart",
        breakup: props.row?.SettledAmount?.breakup,
        isReconcilicationAvailable:
          props.row.Reconciliation?.SettledAmount || false,
        reconciliation: props.row.Reconciliation?.SettledAmount,
        isLink: props.row.AmazonOrderId !== undefined ? true : false,
        isEstimation : props.row?.estimatedFees !== null ? true : false,
      },
    ];
    return data;
  }, [props.row]);
  return (
    <>
      <div className="flex flex-wrap">
        {DetailCol.map((order, orderIndex) => {
          if (order.isVisible)
            return (
              <div key={`Order-${orderIndex}`}>
                <OrderCellData
                  row={props.row}
                  title={order.title}
                  breakup={order.breakup}
                  type={order.type}
                  marketplace={order.marketplace}
                  isReconcilicationAvailable={order.isReconcilicationAvailable}
                  reconciliation={order.reconciliation}
                  onCategoryChange={props.onCategoryChange}
                  onSetFcPincode={props.onSetFcPincode}
                  isLink={order.isLink}
                  isEstimation={order.isEstimation}
                  activeTab={props.activeTab}
                />
              </div>
            );
        })}
      </div>
    </>
  );
}

export default OrdersDetailPanel;
