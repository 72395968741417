import { Box, Typography, Card } from "@material-ui/core";
import { Tag } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import ReturnProcessingLegend from "./ReturnProcessingLegend.js";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  PieChart,
  Pie,
  Legend,
  Cell
} from "recharts";

const useStyles = makeStyles(theme => ({
  chartHeaderText: {
    fontSize: 14,
    fontWeight: 500,
    color: "rgba(68, 68, 79, 1)",
    fontFamily: "inter"
  }
}));

function QuickInfoCharts(props) {
  const classes = useStyles();

  return (
    <>
      <div className="w-full flex justify-between flex-nowrap tablet:flex-wrap pt-5">
        {/* 1 Order flow  */}
        <div className="w-48 tablet:w-full border rounded bg-white p-2">
          <Typography
            className={classes.chartHeaderText}
            style={{ paddingLeft: 24, marginBottom: 16 }}
          >
            Order flow
          </Typography>
          <ResponsiveContainer width="100%" height={290} className="flex justify-center">
            <LineChart
              data={props.orderFlowData}
              margin={{ top: 0, left: 0, right: 0, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis angle="90" dataKey="showDate" interval={4} dy={20} />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#FCAA0B"
                strokeWidth={3}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        {/* 2 Order type distribution */}
        <div className="w-48 ml-1 tablet:w-full border rounded bg-white p-2 mt-0 tablet:mt-6">
          <Typography className={classes.chartHeaderText}>
            Order type distribution
          </Typography>
          {props.orderTypeData !== null && (
            <ResponsiveContainer width="100%" height={290} className="flex justify-center">
              <PieChart>
                <Pie data={props.orderTypeData} dataKey="value" label>
                  {props.orderTypeData?.map((item, i) => (
                    <Cell key={item.key} fill={item.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  align="left"
                  verticalAlign="bottom"
                  iconType="circle"
                  height={36}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
          {/* profit Chart */}
          <div className="w-48 ml-1 tablet:w-full border rounded bg-white p-2 mt-0 tablet:mt-6">
          <Typography className={classes.chartHeaderText}>
            Profit Margin
          </Typography>
          {props.profitPieChart !== null && (
            <ResponsiveContainer width="100%" height={290} className="flex justify-center">
              <PieChart>
                <Pie data={props.profitPieChart} dataKey="value" label>
                  {props.orderTypeData?.map((item, i) => (
                    <Cell key={item.key} fill={item.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  align="left"
                  verticalAlign="bottom"
                  iconType="circle"
                  height={36}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        {/* 3 Review rating distribution */}
        <div className="w-48 ml-1 tablet:w-full border rounded bg-white p-2 mt-0 tablet:mt-6">
          <Typography
            className={classes.chartHeaderText}
            style={{ paddingLeft: 24, marginBottom: 16 }}
          >
            Review rating distribution
          </Typography>
          <ResponsiveContainer width="100%" height={290} className="flex justify-center">
            <BarChart data={props.reviewRatingData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value.ProductReviewCount" name="Reviews" fill="#FD8C24" />
              <Bar dataKey="value.ProductRatingCount" name="Ratings" fill="#4C78FF" />
              <Legend
                verticalAlign="bottom"
                iconType="plainline"
                // formatter={(value, entry) => {
                //   const [start, text] = value.split(".");
                //   return text;
                // }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}
export default QuickInfoCharts;
      // <div style={{ marginTop: 32 }} />
      // <Box display="flex" style={{ display: "none" }}>
      //   <Box
      //     border={1}
      //     borderRadius={5}
      //     borderColor="rgba(223, 225, 230, 1)"
      //     bgcolor="white"
      //     style={{ width: "30%", padding: 24 }}
      //   >
      //     <div>
      //       <Typography className={classes.chartHeaderText}>
      //         Order processing status distribution
      //       </Typography>
      //       {props.orderProcessingData !== null && (
      //         <ResponsiveContainer width="100%" height={300}>
      //           <PieChart>
      //             <Pie
      //               data={props.orderProcessingData}
      //               dataKey="value"
      //               label
      //             >
      //               {props.orderProcessingData.map((item, i) => (
      //                 <Cell key={item.key} fill={item.color} />
      //               ))}
      //             </Pie>
      //             <Legend
      //               align="left"
      //               verticalAlign="bottom"
      //               iconType="circle"
      //               height={36}
      //             />
      //           </PieChart>
      //         </ResponsiveContainer>
      //       )}
      //     </div>
      //   </Box>
      //   <Box
      //     border={1}
      //     borderRadius={5}
      //     borderColor="rgba(223, 225, 230, 1)"
      //     bgcolor="white"
      //     style={{ width: "30%", padding: 0 }}
      //   >
      //     <div>
      //       <Typography
      //         className={classes.chartHeaderText}
      //         style={{ paddingLeft: 24, paddingTop: 24 }}
      //       >
      //         Return processing status distribution
      //       </Typography>
      //       {props.returnProcessingData !== null && (
      //         <ResponsiveContainer width="100%" height={300} label>
      //           <PieChart>
      //             <Pie data={props.returnProcessingData} dataKey="value">
      //               {props.returnProcessingData.map((item, i) => (
      //                 <Cell key={item.key} fill={item.color} />
      //               ))}
      //             </Pie>
      //             <Legend
      //               align="left"
      //               verticalAlign="bottom"
      //               content={
      //                 <ReturnProcessingLegend
      //                   data={props.returnProcessingData}
      //                 />
      //               }
      //             />
      //           </PieChart>
      //         </ResponsiveContainer>
      //       )}
      //     </div>
      //   </Box>
      //   <Box
      //     border={1}
      //     borderRadius={5}
      //     borderColor="rgba(223, 225, 230, 1)"
      //     bgcolor="white"
      //     style={{ width: "30%", padding: 24 }}
      //   >
      //     <div>
      //       <Typography className={classes.chartHeaderText}>
      //         Return reason distribution
      //       </Typography>
      //       {props.returnReasonData !== null && (
      //         <ResponsiveContainer width="100%" height={300} label>
      //           <PieChart>
      //             <Pie data={props.returnReasonData} dataKey="value">
      //               {props.returnReasonData.map((item, i) => (
      //                 <Cell key={item.key} fill={item.color} />
      //               ))}
      //             </Pie>
      //             <Legend
      //               align="left"
      //               verticalAlign="bottom"
      //               iconType="circle"
      //               height={36}
      //             />
      //           </PieChart>
      //         </ResponsiveContainer>
      //       )}
      //     </div>
      //   </Box>
      // </Box>