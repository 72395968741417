import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Image, Rate, Breadcrumb, Button } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";
import "../../.././App.css";
import AntTable from "../../../components/AntTableV2/index.js";
import ImageCarousel from "../../../components/Modals/ImageCarousel";
import ExportButton from "../../../components/Styles/ExportButton";
import User from "../../../images/user.png";
import "../../../Styles/review.css";
import { CloseCircleOutlined } from '@ant-design/icons';

const useStyles = makeStyles((theme) => ({
  customerName: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    textAlign: "left",
    paddingLeft: 8,
  },
  customerReviewInfo: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
    paddingLeft: 8,
  },
  customerReviewText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
  },
  skuLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: "#717480",
    fontFamily: "inter",
    display: "inline-block",
  },
  skuValue: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    display: "inline-block",
  },
  productName: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
  },
  markReceived: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
  },
  noLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
  },
  yesLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
  },
}));
function AllReviewsTab(props) {
  const classes = useStyles();
  const [imagesOpen, setImagesOpen] = React.useState(false);
  const [reviewImages, setReviewImages] = React.useState([]);

  const columns = [
    // {
    //   title: "Sno",
    //   align: "center",
    //   field: "channel",
    //   width: 60,
    //   render: (record, rowIndex) => rowIndex,
    // },
    // {
    //   title: "Channel",
    //   field: "channel",
    //   align: "center",
    //   width: "8%",
    //   render: (record, rowIndex, isLoading) =>
    //     isLoading ? (
    //       <div className="item">
    //         <div className="item-image" />
    //       </div>
    //     ) : record.channel === "Amazon-India" ? (
    //       <div className="amazon-logo-small" />
    //     ) : record.channel === "Flipkart" ? (
    //       <div className="flipkart-logo-small" />
    //     ) : (
    //       "-"
    //     ),
    // },
    // {
    //   title: "Product Image",
    //   field: "product_image",
    //   align: "center",
    //   width: "10%",
    //   render: (record, rowIndex, isLoading) =>
    //     isLoading ? (
    //       <div className="item">
    //         <div className="item-title" />
    //       </div>
    //     ) : (
    //       <div className="flex items-center justify-center">
    //         <Image
    //           width={36}
    //           height={36}
    //           src={record.product_image}
    //           fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
    //         />
    //       </div>
    //     ),
    // },
    {
      title: "Product",
      align: "center",
      field: "product_id",
      type: "string",
      width: "20%",
      render: (record, value, rowIndex) => (
        <>
          {" "}
          <div
            className={`${
              record.channel === "Amazon-India"
                ? "amazon-logo-small"
                : record.channel === "Flipkart"
                ? "flipkart-logo-small"
                : record.channel ==="Meesho"
                ? "meesho-logo-small"
                : null
            }`}
          />
          <div className="flex items-center justify-center">
            <Image
              width={30}
              height={30}
              src={record.product_image}
              fallback="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg"
            />
              {" "}
              <Button className="-ml-3 text-sm" type="link">
                <a
                  href={
                    record.channel === "Amazon-India"
                    ? `https://www.amazon.in/dp/${record.product_id}`
                    :record.channel === "Flipkart" 
                    ? `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${record.product_id}`
                    : record.channel === "Meesho"
                    ? `https://meesho.com/Forcesight-flipkart-url-redirect/p/${record.product_id}`
                    : null
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {record.product_id?.replace(/(.{15})..+/, "$1…") || ""}
                </a>
              </Button>
          </div>
        </>
      ),
      sorter: (a, b) => {
        a = (a.product_id || "").toLowerCase();
        b = (b.product_id || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product Name",
      field: "product_name",
      align: "center",
      width: "20%",
      tooltip: true,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <div>
            <Typography className={classes.productName}>
              {record.product_name.replace(/(.{30})..+/, "$1…")}
            </Typography>
          </div>
        ),
      sorter: (a, b) => {
        a = (a.product_name || "").toLowerCase();
        b = (b.product_name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "SKU",
      field: "sku",
      align: "center",
      width: "8%",
      defaultSortOrder: "descend",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <>{record.sku}</>
        ),
      sorter: (a, b) => {
        a = (a.sku || "").toLowerCase();
        b = (b.sku || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    {
      title: "Reviewer",
      field: "sorting_date_2",
      align: "center",
      width: "16%",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <div className="flex items-center justify-start">
            <div>
              <img
                style={{
                  backgroundColor: "#2684FF",
                  borderRadius: "55px",
                  padding: "12px",
                }}
                src={User}
                alt="User icon"
                className="mr-2"
              />
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="mb-2">
                <Typography className={classes.customerName}>{record.user_name}</Typography>
              </div>
              <div>
                <Typography className={classes.customerReviewInfo}>
                  {/* <div>Reviewed in {record?.user_location} on </div> */}
                  <div>
                    {record.sorting_date_2}
                  </div>
                </Typography>
              </div>
            </div>
          </div>
        ),
      sorter: (a, b) =>
        new Date(a.sorting_date_2) - new Date(b.sorting_date_2),
    },
    {
      title: "Review",
      field: "reviews",
      align: "center",
      // sorter: (a, b) => Number(a.rating) - Number(b.rating),
      filters: [
        { text: 1, value: "1" },
        { text: 2, value: "2" },
        { text: 3, value: "3" },
        { text: 4, value: "4" },
        { text: 5, value: "5" },
      ],
      onFilter: (value, record) => record.rating === value,
      render: (record, rowIndex, isLoading) => (
        <div>
          <div style={{ textAlign: "left", marginBottom: 10 }}>
            <Rate
              defaultValue={record.rating}
              value={record.rating}
              disabled
              count={5}
              allowClear={false}
            />
          </div>
          <div style={{ maxWidth: 600 }}>
            <h3 style={{textAlign: "left",fontWeight:"bold"}}>{record.title}</h3>
            <Typography className={classes.customerReviewText}>{record.reviews}</Typography>
          </div>
          {record?.review_images?.length !== 0 && (
            <div style={{ maxWidth: 400, textAlign: "left", marginTop: 14 }}>
              <ExportButton
                variant="outlined"
                onClick={() => {
                  setReviewImages(record?.review_images);
                  setImagesOpen(true);
                }}
              >
                <AttachFileIcon className="origin-center transform rotate-45 mr-1" />
                Attachments
              </ExportButton>
            </div>
          )}
        </div>
      ),
    },
  ];
  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
  }) => {
    props.tableFilterData(paginationCondition, sortCondition, filterCondition);
  };
  React.useEffect(() => {
    if (props.activeTab === "2") {
      props.setColumn(columns);
    }
  }, [props.activeTab]);
  return (
    <>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        {props.boolSelect &&
          <div style={{ margin: "10px 0" }}>
            <Breadcrumb class="flex justify-start ...">
              <Breadcrumb.Item><b>Selected SKU </b>: {props.selectProductDetails.sku || ""}</Breadcrumb.Item>
              <Button type="primary" shape="circle" onClick={() => {
                props.cancel()
              }} icon={<CloseCircleOutlined />} />
            </Breadcrumb>
          </div>
        }
        <AntTable
          columns={columns}
          dataSource={props.allReviewsData}
          stickyHeader={{ offsetHeader: 65 }}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={props.isLoading}
          totalRecords={props.pageCount}
          hideSummary
          onDataChange={onTableChange}

        />
        {imagesOpen && (
          <ImageCarousel
            images={reviewImages}
            open={imagesOpen}
            setOpen={setImagesOpen}
          />
        )}
      </div>
    </>
  );
}

export default withRouter(AllReviewsTab);
