import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Modal } from "antd";
import React from "react";
import CustomTextField from "../components/./CustomTextField.js";

function FlipkartUserDetailsDialog(props) {
  return (
    <div>
      <Modal visible={props.open} onCancel={props.onClose} footer={null}>
        <DialogTitle>Enter {props.channel} sign-in details</DialogTitle>
        <DialogContent>
          <CustomTextField title="Email" name="email" type="email" />
          <div className="mt-2">
            <CustomTextField title="Password" name="password" type="password" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onSubmitClick} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Modal>
    </div>
  );
}

export default FlipkartUserDetailsDialog;
