import { Typography } from "antd";
import React from "react";

function CustomFormHeader(props) {
  return (
    <>
      <div>
        <Typography
          className={`text-3xl text-DarkCornFlowerBlue text-center font-semibold`}
        >
          {props.title}
        </Typography>
        <Typography
          className={`text-base font-normal mt-3 text-center text-Gray`}
        >
          {props.subtitle}
        </Typography>
      </div>
    </>
  );
}

export default CustomFormHeader;
