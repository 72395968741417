import { Form, Input, Button, Space, Modal, message, Select } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { PostValues } from "../../utils/api-client";
const postUrlAPi = async (requestData) => {
  let response = await PostValues("CreateNewUrlApi", requestData);
  return response;
};
const channels = [
  { label: "Amazon-India", value: "amazon-India" },
  { label: "Flipkart", value: "flipkart" },
  { label: "General", value: "general" },
];
const EditUrlModal = (props) => {
  let arr = [];
  props.editUrl.all_urls?.map((val) => {
    let editableUrl = {
      // url: val,
      productId: val.productId,
      keywords: val.keywords,
      channel: val.channel
    };
    arr.push(editableUrl);
  });

  const [selectedChannel, setSelectedChannel] = React.useState();
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ urls: arr });
    return () => {
      form.setFieldsValue({ urls: [] });
    };
  }, [props.editUrl.all_urls]);

  const onFinish = async (values) => {
    let finalUrlArray = [];
    // Amazon and Flipkart URL
    values.urls.map((val) => {
      let modifiedUrl = {
        channel: val.channel,
        url:
          val.channel === "amazon-India"
            ? `https://www.amazon.in/dp/${
                val.productId
              }/keywords=${val.keywords?.replace(/ /g, "+")}`
            : val.channel === "flipkart"
            ? `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${
                val.productId
              }&q=${val.keywords?.replace(/ /g, "%20")}`
            : val.keywords,
        productId: val.productId,
        keywords: val.keywords,
      };
      finalUrlArray.push(modifiedUrl);
    });
    let requestData = {
      filters: {
        urls: finalUrlArray,
        short_url: props.editUrl.short_url,
        method: "update",
      },
    };
    let editUrl = await postUrlAPi(requestData);
    if (editUrl.status) {
      message.success("Successfully Updated");
      let urlListTmp = props.allUrls.map((url) => {
        if (url.short_url === props.editUrl.short_url) {
          url.link_rotated = values.urls.length || 0;
          url.all_urls = values.urls;
        }
        return url;
      });
      props.setAllUrls(urlListTmp);
    }
    form.resetFields(["urls"]);
    form.setFieldsValue({ urls: [] });
    props.setEditIsModalVisible(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleChange = (value) => {
    setSelectedChannel(value);
  };

  return (
    <Modal
      title="Edit Existing URL"
      visible={props.isEditModalVisible}
      onOk={handleOk}
      width={600}
      onCancel={() => {
        props.setEditIsModalVisible(false);
        form.resetFields(["urls"]);
        form.setFieldsValue({ urls: [] });
      }}
      closable={true}
      footer={[
        <div className={"flex items-center justify-end mt-2"}>
          <button
            className={`ml-2 pl-3 pr-3 pt-1 pb-1 rounded bg-GhostWhite border-platinum border mb-3`}
            onClick={() => {
              props.setEditIsModalVisible(false);
              form.resetFields(["urls"]);
              form.setFieldsValue({ urls: [] });
            }}
          >
            Cancel
          </button>
          <button
            className={`ml-3 pl-3 pr-3 pt-1 pb-1 rounded bg-primaryGreenStroke border-transparent border mb-3 text-white`}
            onClick={handleOk}
          >
            Submit
          </button>
        </div>,
      ]}
    >
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        // initialValues={{
        //   urls: props.editUrl.all_urls,
        // }}
      >
        <Form.List name="urls">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    name={[field.name, "channel"]}
                    // label="Area"
                    rules={[{ required: true, message: "Missing Name" }]}
                  >
                    <Select
                      options={channels}
                      placeholder="Select Channel Type"
                      style={{
                        width: 130,
                      }}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  {selectedChannel !== "general" && (
                    <Form.Item
                      {...field}
                      name={[field.name, "productId"]}
                      rules={[{ required: true, message: "Missing productId" }]}
                    >
                      <Input placeholder="Enter ProductID" />
                    </Form.Item>
                  )}
                  <Form.Item
                    {...field}
                    name={[field.name, "keywords"]}
                    rules={[{ required: true, message: "Missing url" }]}
                  >
                    <Input
                      placeholder={`${
                        selectedChannel !== "general"
                          ? "Enter KeyWords "
                          : "Enter Url"
                      }`}
                      style={{ width: "100%" }}
                      className="mt-3"
                    />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <div className=" flex flex-row-reverse	">
                      <DeleteOutlined onClick={() => remove(field.name)} />
                    </div>
                  ) : null}
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  className="w-max justify-center ml-10 mt-3"
                >
                  Add URLs
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default EditUrlModal;
