import { Button, message, Result } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Form, Formik } from "formik";
import React from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import CustomFormFooter from "../../components/CustomFormFooter";
import CustomFormHeader from "../../components/CustomFormHeader";
import CustomTextField from "../../components/CustomTextField";
import ApiWrapper from "../../utils/ApiWrapper";
import AuthLayout from "./authLayout";

const ChangePwdvalidationSchema = Yup.object().shape({
  emailOtp: Yup.string().trim().required("Required"),
  password: Yup
  .string()
  .trim()
  .required('No password provided.')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password mismatch")
    .required("Required"),
});

function ChangePassword(props) {
  const [isResendOpen, setIsResendOpen] = React.useState(false);
  let params = new URL(window.location.href).searchParams;
  let userToken = params.get("token");

  const handleEmailOtp = async (values, actions, e) => {
    let passwordJson = {
      medium: "email",
      action: "forgotpassword",
      otp: values.emailOtp,
      password: values.password,
    };
    ApiWrapper("verifyOtpSelf", passwordJson, userToken).then(
      (response) => {
        if (response.status) {
          message.success("Password changed successfully");
          let reqData = {
            updated_at: new Date(),
            module_name: "ChangePassword",
            updated_value: {
              medium: "email",
              action: "forgotpassword",
            },
          };
          ApiWrapper("updateChangeLog", reqData).then(
            function (response) {
            },
            function (error) {}
          );
          props.history.replace("/login");
        } else {
          if (response.message_code === "E131") {
            actions.setErrors({
              emailOtp: "Incorrect OTP",
            });
          } else {
            message.error(response.message);
          }
        }
        actions.setSubmitting(false);
      },
      function (error) {
        message.error(error);
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };

  const handleResendOtpClick = async (e) => {
    let resendJson = {
      action: "forgotpassword",
      medium: "email",
    };
    ApiWrapper("sendOtpSelf", resendJson, userToken).then(
      function (response) {
        console.log(response);
        if (response.status) {
          setIsResendOpen(true);
          props.history.replace(
            `/change-password?token=${response.fs_auth_token}`
          );
          localStorage.setItem("pwdToken", response.fs_auth_token);
        }
        if (!response.status) {
          message.error(response.message);
        }
      },
      function (error) {
        message.error(error);
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };
  return (
    <div>
      <AuthLayout page="Change password">
        {!userToken && (
          <Result
            title="Unable to process your request"
            extra={
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  props.history.replace("/login");
                }}
              >
                Back to login
              </Button>
            }
          />
        )}
        {userToken && (
          <>
            <CustomFormHeader
              title="Change Password"
              subtitle="An OTP(One Time Passcode) has been sent to your registered email id  and registered mobile number.
                            Please enter the OTPs in the fields below to verify your email id and password."
            />
            <Formik
              initialValues={{
                emailOtp: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={ChangePwdvalidationSchema}
              onSubmit={(values, actions) => {
                handleEmailOtp(values, actions);
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <div style={{ marginTop: 36 }}>
                    <CustomTextField
                      title="Enter Email OTP"
                      name="emailOtp"
                      type="number"
                    />
                  </div>
                  <div className="mt-2">
                    <CustomTextField
                      title="Enter Password"
                      name="password"
                      type="password"
                    />
                  </div>
                  <div className="mt-2">
                    <CustomTextField
                      title="Confirm Password"
                      name="confirmPassword"
                      type="password"
                    />
                  </div>
                  <div className="mt-2">
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="uppercase w-full shadow-lg hover:shadow-xl rounded font-semibold"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Set Password
                    </Button>
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <CustomFormFooter clickAction={handleResendOtpClick} />
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        <Modal
          title="Authentication "
          visible={isResendOpen}
          footer={[
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setIsResendOpen(false);
                }}
              >
                Okay
              </Button>
            </div>,
          ]}
        >
          <p>New OTPs sent to email and mobile successfully.</p>
        </Modal>
      </AuthLayout>
    </div>
  );
}
export default withRouter(ChangePassword);
