import { Box, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Anchor, Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import OverStock from "../../images/icons/OverStock.svg";
import LossSKU from "../../images/icons/LossSKU.svg";
import ProfitSKU from "../../images/icons/ProfitSKU.svg";
import LowStock from "../../images/icons/LowStock.svg";
import SellerReview from "../../images/icons/SellerReview.svg";
import ProductReview from "../../images/icons/ProductReview.svg";
import OutOfStock from "../../images/icons/OutOfStock.svg";
import SalesLost from "../../images/icons/SalesLost.svg";

const { Link } = Anchor;

const useStyles = makeStyles((theme) => ({
  // ctaContainer: {
  //   marginTop: 16,
  //   display: "flex"
  // },
  ctaLabelText: {
    fontSize: 18,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    textAlign: "left",
    marginTop: 32,
  },
  ctaValueText: {
    fontSize: 24,
    fontWeight: 600,
    color: "#171725",
    fontFamily: "inter",
    textAlign: "left",
  },
  ctaTitleText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
  },
  // leftTableCard: {
  // marginRight: 20,
  // width: "50%",
  // boxShadow: "0px 9px 30px 0px rgba(24, 24, 24, 0.03)"
  // },
  // rightTableCard: {
  //   width: "50%",
  //   boxShadow: "0px 9px 30px 0px rgba(24, 24, 24, 0.03)"
  // },
  rowDivider: {
    borderTop: "1px solid rgba(223, 225, 230, 0.5)",
  },
  columnPointer: {
    cursor: "pointer",
  },
}));

export default function CallToActionInfoV3(props) {
  let history = useHistory();

  const classes = useStyles();

  const dataArr = [
    {
      id: "productReviews",
      title: "# Negative product reviews",
      call_to_action_params: "Product",
      value: props.reviewValue?.productNegativeReviews?.toLocaleString(),
      icon: ProductReview,
    },
    {
      id: "sellerReviews",
      title: "# Negative seller reviews",
      call_to_action_params: "Seller",
      value: props.reviewValue?.sellerNegativeReviews?.toLocaleString(),
      icon: SellerReview,
    },
    {
      id: "profit",
      title: "# Loss making SKUs",
      call_to_action_params: "NumberOfLossMakingSkus",
      value: props.profitCardValue?.noOfLossMakingSkus?.toLocaleString(),
      icon: LossSKU,
    },
    {
      id: "outOfStock",
      title: "# Out of stock SKU",
      call_to_action_params: "OutOfStock",
      value: props.inventoryValue?.outOfStock?.toLocaleString(),
      icon: SalesLost,
    },

    // {
    //   id: "profit",
    //   title: "# Buybox Lost",
    //   call_to_action_params: "NumberOfProfitMakingSkus",
    //   value: props.profitCardValue?.noOfProfitMakingSkus?.toLocaleString(),
    //   icon: ProfitSKU,
    // },
    {
      id: "salesDefence",
      title: "Lost Buy Box",
      call_to_action_params: "LostBuyBox",
      value: props.reviewValue?.lostBuyBox.toLocaleString(),
      icon: ProfitSKU,
    },
    {
      id: "",
      title: "",
      value: "",
    },
    // {
    //   id: "salesDefence",
    //   title: "Opportunity Lost Sales",
    //   call_to_action_params: "OpportunitySalesLost",
    //   value: props.reviewValue?.opporrunityLost.toLocaleString("en-IN", {
    //     maximumFractionDigits: 0,
    //     style: "currency",
    //     currency: "INR",
    //   }),
    //   icon: OutOfStock,
    // },
    {
      id: "overstock",
      title: "# Overstock SKU (> 60 days DIO)",
      call_to_action_params: "OverStock60Days",
      value: props.inventoryValue?.overstock?.toLocaleString(),
      icon: OverStock,
    },
    {
      id: "overstock",
      title: "# Lowstock SKUs (< 7 days DIO)",
      call_to_action_params: "OutOfStock7Days",
      value: props.inventoryValue?.outOfStock7Days?.toLocaleString(),
      icon: LowStock,
    },
  ];

  const showExpandedModal = (record) => {
    if (record.id === "salesDefence") {
      history.push({ pathname: "/sales-defence", state: { record } });
    }
    props.showCtaModal(record);
  };

  const getDataColumn = (isLoading, data, value) => {
    return (
      <div className={`${isLoading ? "cursor-not-allowed" : ""}`}>
        <div
          className={`relative ${props.isLoading ? " pointer-events-none" : ""
            }`}
        >
          <div
            className={classes.columnPointer}
            onClick={() => showExpandedModal(data)}
          >
            <div>
              {data.icon && (
                <Typography
                  style={{
                    borderRadius: "50%",
                    padding: "10px",
                    backgroundColor: "#E2DFD4",
                    width: "fit-content",
                  }}
                >
                  {" "}
                  <img src={data.icon} alt="callToAction" className="w-6" />
                </Typography>
              )}
            </div>
            {isLoading && data.icon ? (
              <div className="item-left">
                <div className="item-title" />
              </div>
            ) : data.value === undefined || data.value === null ? (
              <Typography className={classes.ctaValueText}>{"-"}</Typography>
            ) : (
              <Typography className={classes.ctaValueText}>
                {data.value}
              </Typography>
            )}
            <Typography className={classes.ctaTitleText}>
              {data.title}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const getDataRow = (isLoading, data1, data2, data3, data4) => {
    return (
      <Row>
        <Col span={4} style={{ padding: 24, marginLeft: 18 }}>
          {getDataColumn(isLoading, data1)}
        </Col>
        <Col span={2} />
        <Col span={4} style={{ padding: 24 }}>
          {getDataColumn(isLoading, data2)}
        </Col>
        <Col span={2} />
        <Col span={4} style={{ padding: 24 }}>
          {getDataColumn(isLoading, data3)}
        </Col>
        <Col span={2} />
        <Col span={4} style={{ padding: 24 }}>
          {getDataColumn(isLoading, data4)}
        </Col>
        <Col span={2} />
      </Row>
    );
  };

  const getDataTable = (
    isLoading,
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    className
  ) => {
    return (
      <Box
        border={1}
        borderRadius={5}
        borderColor="rgba(223, 225, 230, 1)"
        color="red"
        className={className}
      >
        <Card elevation={0} style={{ backgroundColor: "#faf8f4" }}>
          {getDataRow(isLoading, data1, data2, data3, data4)}
          <div className={classes.rowDivider} />
          {getDataRow(isLoading, data5, data6, data7, data8)}
        </Card>
      </Box>
    );
  };

  return (
    <>
      {/* <div>
        <Typography className={classes.ctaLabelText}>
          Calls to action
        </Typography>
      </div> */}
      <Box className="flex flex-nowrap text-center tablet:flex-wrap gap-5 tablet:gap-0">
        <div className="w-full tablet:w-full">
          {getDataTable(
            props.isLoading,
            dataArr[0],
            dataArr[1],
            dataArr[2],
            dataArr[3],
            dataArr[4],
            dataArr[5],
            dataArr[6],
            dataArr[7]
          )}
        </div>
        {/* <div className="w-2/4 tablet:w-full pt-0 tablet:pt-2.5">
          {getDataTable(
            props.isLoading,
            dataArr[4],
            dataArr[5],
            dataArr[6],
            dataArr[7]
          )}
        </div> */}
      </Box>
    </>
  );
}
