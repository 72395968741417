import {
  DownCircleFilled,
  RightCircleFilled,
} from "@ant-design/icons";
import { message, Spin, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader.js";
import AntTable from "../../components/AntTableV2/index.js";
import { DATE_FORMAT, Fs_status, Fulfilment_filter } from "../../utils/data";
import OrdersDetailPanel from "./OrdersDetailPanel.js";
import OrderStatusText from "./OrderStatusText.js";
import { PostValues } from "../../utils/api-client.js";
import ExportButton from "../../components/Styles/ExportButton.js";

let OrderDataTable = [];
function OrdersTracker(props) {
  const handleManualSyncClick = (event) => {};
  const [allOrders, setAllOrders] = React.useState([]);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [columns, setColumns] = React.useState([]);
  const [lastSyncAt, setLastSyncAt] = React.useState("");
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [marketPlaces, setMarketPlaces] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [requestData, setRequestData] = React.useState({});
  const [orderTotalAmountSum, setOrderTotalAmountSum] = React.useState(0);
  const [totalQuantity, setTotalQuantity] = React.useState(0);
  const [isExportLoading, setIsExportLoading] = React.useState(false);

  const RefColumns = [
    // {
    //   title: "Sno",
    //   align: "center",
    //   isTotal: false,
    //   field: "channelName",
    //   width: 60,
    //   // skipExport: true,
    //   render: (record, rowIndex, isLoading) =>
    //     isLoading ? (
    //       <div className="item">
    //         <div className="item-title" />
    //       </div>
    //     ) : (
    //       rowIndex
    //     ),
    // },
    // {
    //   title: "Channel",
    //   align: "center",
    //   isTotal: false,
    //   field: "channelName",
    //   width: 55,
    //   render: (record, rowIndex, isLoading) =>
    //     isLoading ? (
    //       <div className="item">
    //         <div className="item-image" />
    //       </div>
    //     ) : record.channel === "Amazon-India" ? (
    //       <div className="amazon-logo-small" />
    //     ) : record.channel === "Flipkart" ? (
    //       <div className="flipkart-logo-small" />
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      title: (
        <div className="flex items-center justify-center">
          Order
          {/* <CalendarOutlined className="pl-1" /> */}
        </div>
      ),
      colName: "Order Date",
      align: "center",
      isTotal: false,
      field: "OrderDate",
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "date",
      width: 200,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.OrderDate !== undefined ? (
          <>
            {" "}
              <div
                className={`${
                  record.channel === "Amazon-India"
                    ? "amazon-logo-small"
                    : record.channel === "Flipkart"
                    ? "flipkart-logo-small"
                    : "-"
                }`}
              />
            <div>{` ${record.OrderDate} / ${record.OrderID}`}</div>
          </>
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = a.OrderDate
          ? a.OrderDate !== "-"
            ? new Date(
                (a.OrderDate || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        b = b.OrderDate
          ? b.OrderDate !== "-"
            ? new Date(
                (b.OrderDate || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        return a - b;
      },
      // sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
    },
    // {
    //   title: "Order ID",
    //   align: "center",
    //   isTotal: false,
    //   field: "OrderID",
    //   tooltip: true,
    //   isFilterable: true,
    //   isSearchable: true,
    //   type: "string",
    //   width: 180,
    //   defaultSortOrder: "descend",
    //   render: (record, rowIndex, isLoading) =>
    //     isLoading ? (
    //       <div className="item">
    //         <div className="item-title" />
    //       </div>
    //     ) : record.OrderID !== undefined ? (
    //       record.OrderID
    //     ) : (
    //       "-"
    //     ),
    //   sorter: (a, b) => {
    //     a = (a.OrderID || "").toLowerCase();
    //     b = (b.OrderID || "").toLowerCase();
    //     return a > b ? -1 : b > a ? 1 : 0;
    //   },
    //   sortDirections: ["descend", "ascend", "descend"],
    // },
    {
      title: "Status",
      align: "center",
      isTotal: false,
      field: "FSOrderStatus",
      type: "string",
      width: 80,
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.FSOrderStatus) {
            return true;
          }
        } else {
          let isFilter = false;
          value.map((orderStatus) => {
            if (
              orderStatus.toLowerCase() ===
              (record.FSOrderStatus || "").toLowerCase()
            ) {
              isFilter = true;
            }
          });
          return isFilter;
        }
      },
      filters: Fs_status,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record?.FSOrderStatus !== undefined ? (
          <OrderStatusText text={record.FSOrderStatus || undefined} />
        ) : (
          "-"
        ),
    },
    {
      title: "Product",
      align: "center",
      isTotal: false,
      field: "ProductDetails.Name",
      isFilterable: true,
      isSearchable: true,
      type: "string",
      width: 200,
      // ellipsis: true,
      render: (record, rowIndex) =>
        record.ProductDetails !== undefined ? (
          <Tooltip placement="topLeft" title={record.ProductDetails?.Name}>
            {(record.ProductDetails?.Name || "").replace(/(.{45})..+/, "$1…")}
          </Tooltip>
        ) : (
          "-"
        ),
      // record.ProductDetails !== undefined ? (record.ProductDetails.Name) : ("-"),
      sorter: (a, b) => {
        a = ((a.ProductDetails || {}).Name || "").toLowerCase();
        b = ((b.ProductDetails || {}).Name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "SKU",
      align: "center",
      isTotal: false,
      field: "SKU",
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "string",
      width: 80,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.SKU !== undefined ? (
          (record.SKU || "").replace(/(.{15})..+/, "$1…")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.SKU || "").toLowerCase();
        b = (b.SKU || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Fulfilment",
      align: "center",
      isTotal: false,
      field: "FulfillmentChannel",
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      // ellipsis: true,
      type: "string",
      width: 80,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.FulfillmentChannel !== undefined ? (
          record.FulfillmentChannel //.replace(/(.{15})..+/, "$1…")
        ) : (
          "-"
        ),
      // sorter: (a, b) => {
      //   a = (a.FulfillmentChannel || "").toLowerCase();
      //   b = (b.FulfillmentChannel || "").toLowerCase();
      //   return a > b ? -1 : b > a ? 1 : 0;
      // },
      // sortDirections: ["descend", "ascend", "descend"],
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.FulfillmentChannel) {
            return true;
          }
        } else {
          let isFilter = false;
          value.map((fulfillmentChannel) => {
            if (
              fulfillmentChannel.toLowerCase() ===
              (record.FulfillmentChannel || "").toLowerCase()
            ) {
              isFilter = true;
            }
          });
          return isFilter;
        }
      },
      filters: Fulfilment_filter,
    },

    {
      title: "Qty",
      align: "center",
      isTotal: true,
      field: "quantityShipped",
      isFilterable: true,
      isSearchable: true,
      type: "string",
      // ellipsis: true,
      width: 80,
      total: totalQuantity,
      render: (record, rowIndex) =>
        record.quantityShipped !== undefined ? (
          <Tooltip placement="topLeft" title={record.quantityShipped}>
            {record.quantityShipped}
          </Tooltip>
        ) : (
          "-"
        ),
      // record.ProductDetails !== undefined ? (record.ProductDetails.Name) : ("-"),
      // sorter: (a, b) => {
      //   a = a.quantityShipped || 0;
      //   b = b.quantityShipped || 0;
      //   return a - b;
      // },
      // sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Amt",
      align: "center",
      isTotal: true,
      isTotalCurrency: true,
      field: "OrderTotal.Amount",
      tooltip: true,
      isFilterable: true,
      type: "number",
      width: 110,
      total: orderTotalAmountSum,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.amount !== "-" && record.amount ? (
          record.amount.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        ) : record.amount === 0 ? (
          record.amount
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        return Number(a.amount || 0) - Number(b.amount || 0);
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    // {
    //   title: (
    //     <div className="flex items-center justify-center">
    //       Ship <CalendarOutlined className="pl-1" />
    //     </div>
    //   ),
    //   colName: "Ship Date",

    //   align: "center",
    //   isTotal: false,
    //   field: "ShipmentDate",
    //   tooltip: true,
    //   isFilterable: true,
    //   isSearchable: true,
    //   type: "date",
    //   width: 100,
    //   // render: (record, rowIndex, isLoading) =>
    //   //   isLoading ? (
    //   //     <div className="item">
    //   //       <div className="item-title" />
    //   //     </div>
    //   //   ) : record.ShipmentDate !== undefined ? (
    //   //     moment(new Date(record.ShipmentDate)).format("DD-MM-yyyy")
    //   //   ) : (
    //   //     "-"
    //   //   ),
    //   sorter: (a, b) => {
    //     a = a.ShipmentDate
    //       ? a.ShipmentDate !== "-"
    //         ? new Date(
    //             (a.ShipmentDate || "").replace(
    //               /(\d{2})-(\d{2})-(\d{4})/,
    //               "$2/$1/$3"
    //             )
    //           ).getTime()
    //         : 0
    //       : 0;
    //     b = b.ShipmentDate
    //       ? b.ShipmentDate !== "-"
    //         ? new Date(
    //             (b.ShipmentDate || "").replace(
    //               /(\d{2})-(\d{2})-(\d{4})/,
    //               "$2/$1/$3"
    //             )
    //           ).getTime()
    //         : 0
    //       : 0;
    //     return a - b;
    //   },
    //   sortDirections: ["descend", "ascend", "descend"],
    // },
    {
      title: "Customer Name",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "customerName",
      tooltip: true,
      isFilterable: true,
      type: "number",
      sorter: (a, b) => {
        return (
          ((a.InvoicePrice || {}).total || 0) -
          ((b.InvoicePrice || {}).total || 0)
        );
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product Name",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "productName",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Customer Address",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "customerAddress",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Customer Email",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "BuyerEmail",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Weight",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "productWeight",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Dimensions",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "packageDimensions",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    /*{
      title: "Courier",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "AWB No:",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },*/
  ];

  React.useEffect(() => {
    setColumns(RefColumns);
  }, []);

  const prepareData = (orders) => {
    return orders.map((rowData) => {
      let row = { ...rowData };
      row.channelName = row.channel !== undefined ? row.channel : "-";
      row.ShipmentDate =
        row.ShipmentDate && row.ShipmentDate !== "-"
          ? moment(new Date(row.ShipmentDate)).format(DATE_FORMAT)
          : "-";
      row.OrderDate =
        row.OrderDate && row.OrderDate !== "-"
          ? moment(new Date(row.OrderDate)).format(DATE_FORMAT)
          : "-";
      row.productName = row?.ProductDetails?.Name || "";
      row.quantityShipped =
        row?.isReplacement === true ? 0 : row.QuantityShipped;

      row.customerName =
        row.BuyerName !== undefined
          ? (row.BuyerName?.firstName || "") +
            " " +
            (row.BuyerName?.lastName || "")
          : "-";
      row.productWeight =
        row?.ProductDetails?.PackageDimensions?.weight?.value !== undefined
          ? Math.round(
              row.ProductDetails?.PackageDimensions?.weight.value * 100
            ) /
              100 +
            " " +
            (row?.ProductDetails?.PackageDimensions?.weight?.units !== "-"
              ? row?.ProductDetails?.PackageDimensions.weight?.units
              : "Kg")
          : "-";

      row.amount =
        row?.InvoicePrice?.total !== undefined
          ? Number(row.InvoicePrice.total)
          : row?.OrderTotal?.Amount !== undefined
          ? Number(row.OrderTotal.Amount)
          : 0;

      row.packageDimensions =
        row?.ProductDetails?.PackageDimensions !== undefined
          ? Math.round(
              (row?.ProductDetails?.PackageDimensions?.length?.value * 100) /
                100 || 0
            ) +
            "L " +
            Math.round(
              (row?.ProductDetails?.PackageDimensions?.breadth?.value * 100) /
                100 || 0
            ) +
            "B " +
            Math.round(
              (row?.ProductDetails?.PackageDimensions?.height?.value * 100) /
                100 || 0
            ) +
            "H"
          : "-";
      row.customerAddress = `${row.ShipCity}, ${row.ShipCountry}, ${row.ShipPostalCode}`;
      return row;
    });
  };

  const getOrders = async (requestData) => {
    let response = await PostValues("getOrdersV3", requestData);
    return response;
  };
  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };

  const handleMarketplaceChange = async (
    selectedMarketplaces,
    fromDate,
    toDate
  ) => {
    setMarketPlaces(selectedMarketplaces);
    setFromDate(fromDate);
    setToDate(toDate);
    setAllOrders([]);
    setFilteredOrders([]);
    setIsLoading(true);

    OrderDataTable = [];
    var noOfMarketplaces = selectedMarketplaces.length;

    let requestData = {
      channels: selectedMarketplaces.map((mp) => mp.name),
      filters: {
        fromDate: fromDate,
        toDate: toDate,
      },
      pagination: {
        pageNo: 0,
        pageSize: 25,
      },
    };
    if (searchText) {
      requestData.filters["search"] = searchText;
    }
    const data = await getOrders(requestData);
    setRequestData(requestData);

    if (data.orders) {
      const orderData = await prepareData(data.orders);
      setAllOrders([...orderData]);
      setFilteredOrders([...orderData]);
      setTotalRecords(data.pagination.count);
      let totalOrder =
        data.total.QuantityShippedSum - data.total.isReplacementCount;
      setTotalQuantity(totalOrder);
      setOrderTotalAmountSum(data.total.OrderTotalAmountSum);
      setLastSyncAt(data.lastSyncAt.ordersLastSyncAt);
      setIsLoading(false);
    }

    if (!selectedMarketplaces.length) {
      setIsLoading(false);
      setAllOrders([]);
      setFilteredOrders([]);
    }
  };

  const onSearchTextEntered = (searchText) => {
    setSearchText(searchText);
  };

  const DetailPanel = ({ row }) => {
    return <OrdersDetailPanel row={row} />;
  };

  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
    extra,
  }) => {
    setIsLoading(true);
    let requestData = {
      channels: marketPlaces.map((mp) => mp.name),
      filters: {
        fromDate: fromDate,
        toDate: toDate,
        ...filterCondition,
      },
      ...paginationCondition,
      ...sortCondition,
    };
    const data = await getOrders(requestData);
    setRequestData(requestData);

    if (data.orders) {
      const orderData = await prepareData(data.orders);
      setAllOrders([...orderData]);
      setFilteredOrders([...orderData]);
      setTotalRecords(data.pagination.count);
      let totalOrder =
        data.total.QuantityShippedSum - data.total.isReplacementCount;
      setTotalQuantity(totalOrder);
      setOrderTotalAmountSum(data.total.OrderTotalAmountSum);
      setLastSyncAt(data.lastSyncAt.ordersLastSyncAt);
      setIsLoading(false);
    }
  };

  const ExportAllData = async () => {
    try {
      setIsExportLoading(true);
      let exportRequestData = {
        params: {
          channels: marketPlaces.map((mp) => mp.name),
          fromDate: fromDate,
          toDate: toDate,
        },
        reportType: "orders",
      };
      const data = await getExportApi(exportRequestData);
      if (data.status) {
        setIsExportLoading(false);
        message.success(
          "The export is being processed, please check the Downloads page ",
          10
        );
      } else {
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const onSearch = async (ds, searchText) => {
    let params = { ...requestData };
    if (params.filters) {
      params.filters.search = searchText.trim();
    } else {
      params.filters = {
        search: searchText.trim(),
      };
    }
    setIsLoading(true);
    const data = await getOrders(params);
    setIsLoading(false);
    if (data.orders) {
      setTotalRecords(data.pagination.count);
      let totalOrder =
        data.total.QuantityShippedSum - data.total.isReplacementCount;
      setTotalQuantity(totalOrder);
      setOrderTotalAmountSum(data.total.OrderTotalAmountSum);
      return await prepareData(data.orders);
    }
    return [];
  };

  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <CustomAppBar />
      <CustomFilterHeader
        headerTitle="Orders"
        handleManualSyncClick={handleManualSyncClick}
        handleMarketplaceChange={handleMarketplaceChange}
        onSearchTextEntered={onSearchTextEntered}
        searchFunction={onSearch}
        columns={columns}
        rows={allOrders}
        isLoading={isLoading}
        showManualSync={false}
        manualSyncModule={"ORDERS"}
        syncDate={lastSyncAt}
        showExportButton={false}
        exportFileName="orderTracker"
        getExportData={ExportAllData}
        onFilterUpdate={(data) => {
          setFilteredOrders(data);
        }}
        searchHide={true}
        customToggleProfit={
          <Spin spinning={isExportLoading}>
            <div className="ml-6">
              <ExportButton variant="outlined" onClick={ExportAllData}>
                Export to CSV
              </ExportButton>
            </div>
          </Spin>
        }
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          columns={RefColumns}
          dataSource={filteredOrders}
          stickyHeader={{
            offsetHeader: 65,
            // offsetScroll: 216,
          }}
          // scroll={{ x: 1500 }}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={isLoading}
          hideSummary={!filteredOrders.length}
          searchText={[searchText]}
          totalRecords={totalRecords}
          expandable={{
            expandedRowRender: (record) => <DetailPanel row={record} />,
            rowExpandable: (record) => true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownCircleFilled onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightCircleFilled onClick={(e) => onExpand(record, e)} />
              ),
          }}
          onDataChange={onTableChange}
        />
      </div>
    </>
  );
}

export default withRouter(OrdersTracker);
