import React, { useState, useEffect } from "react";
import { TagCloud } from "react-tagcloud";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/charts";
import { Rate, Skeleton, Slider, Table, Tooltip } from "antd";

export const SentimentAnalysisTable = (props) => {
  const columns = [
    {
      title: () => (
        <span className="text-SpaceBlack text-sm font-Inter font-bold">
          # Reviews{" "}
        </span>
      ),
      dataIndex: "count",
      key: "count",
      width: "10%",
      align: "center",
    },
    {
      title: () => (
        <span className="text-SpaceBlack text-sm font-Inter font-bold">
          Given Star
        </span>
      ),
      dataIndex: "givenStar",
      key: "givenStar",
      width: "30%",
      align: "center",
      render: (star) => <Rate disabled defaultValue={star} value={star} />,
    },
    {
      title: () => (
        <span className="text-SpaceBlack text-sm font-Inter font-bold">
          Sentiment
        </span>
      ),
      dataIndex: "average",
      key: "average",
      width: "30%",
      align: "center",
      render: (val) => (
        <>
          <Slider
            min={-1}
            max={1}
            value={typeof val === "number" ? val : 0}
            step={0.01}
            marks={{
              [-1]: "-1",
              [val]: `${val}`,
              1: "1",
            }}
          />
        </>
      ),
    },
    {
      title: () => (
        <span className="text-SpaceBlack text-sm font-Inter font-bold">
          Derived Star
        </span>
      ),
      dataIndex: "derivedStar",
      key: "derivedStar",
      width: "30%",
      align: "center",
      render: (star) => (
        <Tooltip title={star?.toFixed(2)}>
          {" "}
          <Rate disabled allowHalf defaultValue={star} value={star} />
        </Tooltip>
      ),
    },
  ];
  const data = [
    ...props?.data?.map((val) => ({
      givenStar: Object.keys(val),
      average: val[Object.keys(val)]?.AVERAGE,
      count: val[Object.keys(val)]?.COUNT,
      derivedStar: ((val[Object.keys(val)]?.AVERAGE + 1) / 2) * 5,
    })),
  ];
  return (
    <Table
      columns={columns}
      bordered
      size="small"
      dataSource={data?.reverse()}
      pagination={false}
      loading={props?.isLoading}
    />
  );
};
const customRenderer = (tag, size, color) => {
  return (
    <span key={`${tag.value}_${tag.count}`} style={{ color }} className={`tag-${size}`}>
      <Tooltip title={tag.count}>
    {tag.value}
    </Tooltip>
  </span>
  );
};
export const SentimentAnalysisGraph = (props) => {
  const arr = [];
  props?.data.forEach((row, index) => {
      arr.push({
        value: row.y,
        count: row.COUNT,
        key:`${row.y}_${row.COUNT}`,
      });
    });
    return props.isLoading ? <Skeleton /> : (
    <TagCloud
      minSize={15}
      maxSize={40}
      tags={arr}
      // renderer={customRenderer}
    />
  );
};
