import {
  CalendarOutlined,
  DownCircleFilled,
  RightCircleFilled,
} from "@ant-design/icons";
import { Button, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button as  message, Modal, Spin, Switch } from "antd";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import ApiWrapper from "../../utils/ApiWrapper.js";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader.js";
import AntTable from "../../components/AntTableV2/index.js";
import CustomTextField from "../../components/CustomTextField";
import {
  DATE_FORMAT,
  Fulfilment_filter,
  Return_filter,
} from "../../utils/data";
import ReturnsDetailPanel from "./ReturnsDetailPanel.js";
import ReturnStatusText from "./ReturnStatusText.js";
import { PostValues } from "../../utils/api-client.js";
import ExportButton from "../../components/Styles/ExportButton.js";

const validationSchema = Yup.object().shape({
  totalQty: Yup.number().integer().positive().required("Required"),
  sellable: Yup.number().integer().min(0).required("Required"),
  nonAcceptable: Yup.number()
    .min(0)
    .required("Required")
    .when(["totalQty", "sellable"], (num1, num2, schema) => {
      return Yup.number()
        .min(num1 - num2, "Mismatch")
        .max(num1 - num2, "Mismatch");
    }),
  // .when(["sellable", "totalQty"], (num1, num2, schema) => {
  //   return num1 >= 0 && num2 >= 0 ? schema.max(0) : schema.max(0);
  // }),
});
// let ReturnDataTable = [];

function ReturnsTracker(props) {
  const [allOrders, setAllOrders] = React.useState([]);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [showModal, setshowModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedRow, setselectedRow] = useState({});
  const classes = useStyles();
  const [lastSyncAt, setLastSyncAt] = React.useState("");
  const [marketPlaces, setMarketPlaces] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [updateRowStatus, setRowupdateStatus] = React.useState("");
  const [requestData, setRequestData] = React.useState({});
  const [totalQuantity, setTotalQuantity] = React.useState(0);
  // const [totalRefundSum, setTotalRefundSum] = React.useState(0);
  const [isExportLoading, setIsExportLoading] = React.useState(false);

  const RefColumns = [
    {
      title: "Sno",
      align: "center",
      field: "channelName",
      width: 60,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          rowIndex
        ),
      //fixed: "left",
    },
    {
      title: "Channel",
      align: "center",
      field: "channelName",
      width: 75,

      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-image" />
          </div>
        ) : record.channel === "Amazon-India" ? (
          <div className="amazon-logo-small" />
        ) : record.channel === "Flipkart" ? (
          <div className="flipkart-logo-small" />
        ) : (
          "-"
        ),
    },
    {
      title: "Fulfillment",
      align: "center",
      field: "FulfillmentChannel",
      isFilterable: true,
      type: "string",
      width: 120,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.FulfillmentChannel &&
          record.FulfillmentChannel !== undefined &&
          record.FulfillmentChannel !== null ? (
          record.FulfillmentChannel.replace(/(.{15})..+/, "$1…")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.FulfillmentChannel || "").toLowerCase();
        b = (b.FulfillmentChannel || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.FulfillmentChannel) {
            return true;
          }
        } else {
          let isFilter = false;
          value.map((fulfillmentChannel) => {
            if (
              fulfillmentChannel.toLowerCase() ===
              (record.FulfillmentChannel || "").toLowerCase()
            ) {
              isFilter = true;
            }
          });
          return isFilter;
        }
      },

      filters: Fulfilment_filter,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: (
        <div className="flex items-center justify-center">
          Order <CalendarOutlined className="pl-1" />
        </div>
      ),
      colName: "Order Date",
      align: "center",
      field: "OrderDate",
      isFilterable: true,
      type: "date",
      width: 100,
      sorter: (a, b) => {
        a = a.OrderDate
          ? a.OrderDate !== "-"
            ? new Date(
                (a.OrderDate || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        b = b.OrderDate
          ? b.OrderDate !== "-"
            ? new Date(
                (b.OrderDate || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        return a - b;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Order ID",
      align: "center",
      field: "OrderID",
      isFilterable: true,
      isSearchable: true,
      width: 180,

      type: "string",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.OrderID !== undefined ? (
          record.OrderID
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.OrderID || "").toLowerCase();
        b = (b.OrderID || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "SKU",
      align: "center",
      field: "SKU",
      isFilterable: true,
      isSearchable: true,
      type: "string",
      width: 80,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.SKU !== undefined ? (
          record.SKU.replace(/(.{15})..+/, "$1…")
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.SKU || "").toLowerCase();
        b = (b.SKU || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Return quantity",
      align: "center",
      field: "ReturnQuantityTotal",
      isFilterable: true,
      isSearchable: true,
      isTotal: true,
      total: totalQuantity,
      type: "number",
      width: 80,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.ReturnQuantityTotal !== undefined ? (
          record.ReturnQuantityTotal
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        return (a.ReturnQuantityTotal || 0) - (b.ReturnQuantityTotal || 0);
      },
      sortDirections: ["descend", "ascend", "descend"],
    },

    {
      title: (
        <div className="flex items-center justify-center">
          Return <CalendarOutlined className="pl-1" />
        </div>
      ),
      colName: "Return Date",
      align: "center",
      field: "ReturnDate",
      defaultSortOrder: "descend",
      isFilterable: true,
      type: "date",
      width: 100,
      sorter: (a, b) => {
        a = a.ReturnDate
          ? a.ReturnDate !== "-"
            ? new Date(
                (a.ReturnDate || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;
        b = b.ReturnDate
          ? b.ReturnDate !== "-"
            ? new Date(
                (b.ReturnDate || "").replace(
                  /(\d{2})-(\d{2})-(\d{4})/,
                  "$2/$1/$3"
                )
              ).getTime()
            : 0
          : 0;

        return a - b;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Status",
      align: "center",
      field: "FSReconciledReturnOrderStatus",
      isFilterable: true,
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.FSReconciledReturnOrderStatus !== "-" ? (
          <ReturnStatusText text={record.FSReconciledReturnOrderStatus} />
        ) : (
          "-"
        ),
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.FSReconciledReturnOrderStatus) {
            return true;
          }
        } else {
          return (
            (record.FSReconciledReturnOrderStatus || "").toLowerCase() ===
            value.toLowerCase()
          );
        }
      },
      filters: Return_filter,
    },
    {
      title: "Received",
      align: "center",
      field: "mark_received",
      width: 120,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <div>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={1.5}>
                <div style={{ marginRight: 8 }}>
                  <Typography className={classes.noLabel}>No</Typography>
                </div>
              </Grid>
              <Grid item>
                <Switch
                  name="checkedC"
                  checked={record.mark_received || false}
                  disabled={
                    record.FulfillmentChannel === "Merchant" ||
                    record.FulfillmentType === "Seller Smart" ||
                    record.MarketplaceFees?.TechnologyFee
                      ? false
                      : true
                  }
                  onChange={(checked) => {
                    // console.log(record.mark_received);
                    onShowModal(true, record);
                  }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <div style={{ marginLeft: 8 }}>
                  <Typography className={classes.yesLabel}>Yes</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        ),
    },
    {
      title: "Refund",
      align: "right",
      field: "RefundedAmountTotal",
      isTotal: true,
      // total: totalRefundSum,
      isTotalCurrency: true,
      width: 80,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          record.RefundedAmountTotal.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })
        ),
      sorter: (a, b) => {
        return (a.RefundedAmountTotal || 0) - (b.RefundedAmountTotal || 0);
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product Name",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "ProductDetails.Name",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Marketplace return status",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "OrderStatus",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Return type",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "ReturnDetails.ReturnType",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Customer Address",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "customerAddress",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },

    {
      title: "Seller return status",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "sellerReturnStatus",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Reason",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "ReturnDetails.ReturnReason",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "No of sellable returns",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "sellable",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "No of non-sellable returns",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "non_acceptable",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
    {
      title: "Return quantity received",
      align: "center",
      isTotal: true,
      hidden: true,
      field: "total_received_quantity",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },

    {
      title: "TypeOrderItemId",
      align: "center",
      hidden: true,
      field: "TypeOrderItemId",
      tooltip: true,
      isFilterable: true,
      type: "string",
    },
  ];

  React.useEffect(() => {
    setColumns(RefColumns);
  }, []);

  const onSearchTextEntered = (searchText) => {
    setSearchText(searchText);
  };

  const handleManualSyncClick = (event) => {};

  const prepareData = (orders) => {
    return orders.map((rowData) => {
      let row = { ...rowData };
      row.channelName =
        row.FlipkartOrderId !== undefined
          ? "Flipkart"
          : row.AmazonOrderId !== undefined
          ? "Amazon-India"
          : "-";
      row.OrderDate =
        row.OrderDate && row.OrderDate !== "-"
          ? moment(new Date(row.OrderDate)).format(DATE_FORMAT)
          : "-";
      row.ReturnDate =
        row?.ReturnDetails?.ReturnDate &&
        row?.ReturnDetails?.ReturnDate !== "-" &&
        row?.ReturnDetails?.ReturnDate !== " "
          ? moment(new Date(row.ReturnDetails.ReturnDate)).format(DATE_FORMAT)
          : "-";

      row.ReturnQuantityTotal = row?.isReplacement === true ? 0 : row.ReturnDetails.ReturnQuantity;
      if (row.ReturnDetails.RefundedAmount !== undefined) {
        if (/\s+/.test(row.ReturnDetails.RefundedAmount)) {
          row.RefundedAmountTotal = "0";
        } else if (row.ReturnDetails.RefundedAmount === "-") {
          row.RefundedAmountTotal = "0";
        } else {
          row.RefundedAmountTotal = row.ReturnDetails.RefundedAmount;
        }
      } else {
        row.RefundedAmountTotal = "0";
      }

      row.channelStatus = row.ReturnDetails?.OrderStatus
        ? row.ReturnDetails?.OrderStatus
        : row.OrderStatus || "";
      row.customerAddress = `${row.ShipCity}, ${row.ShipCountry}, ${row.ShipPostalCode}`;
      row.sellerReturnStatus = row?.mark_received
        ? "Delivered"
        : "Not Delivered";
      if (row.MarketplaceFees) {
        row.MarketplaceFees.TechnologyFee =
          row.MarketplaceFees?.breakup[1]?.TechnologyFee?.CurrencyAmount;
      }

      return row;
    });
  };
  const getReturnOrders = async (requestData) => {
    let response = await PostValues("getOrdersV3", requestData);
    return response;
  };
  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };

  const handleMarketplaceChange = async (
    selectedMarketplaces,
    fromDate,
    toDate
  ) => {
    setMarketPlaces(selectedMarketplaces);
    setFromDate(fromDate);
    setToDate(toDate);
    setAllOrders([]);
    setFilteredOrders([]);
    setIsLoading(true);

    var noOfMarketplaces = selectedMarketplaces.length;

    let requestData = {
      channels: selectedMarketplaces.map((mp) => mp.name),

      filters: {
        fromDate: fromDate,
        toDate: toDate,
        ReturnDetails: { ISNULL: false },
      },
      pagination: {
        pageNo: 0,
        pageSize: 25,
      },
    };
    const data = await getReturnOrders(requestData);
    setRequestData(requestData);

    if (data.orders) {
      const orderData = await prepareData(data.orders);
      setAllOrders([...orderData]);
      setFilteredOrders([...orderData]);
      setTotalRecords(data.pagination.count);
      setTotalQuantity(data.total.QuantityShippedSum);
      // setTotalRefundSum(data.total.OrderTotalAmountSum);
      setLastSyncAt(data.lastSyncAt.returnsLastSyncAt);
      setIsLoading(false);
    }

    if (!selectedMarketplaces.length) {
      setIsLoading(false);
      setAllOrders([]);
      setFilteredOrders([]);
    }
  };

  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
    extra,
  }) => {
    setIsLoading(true);

    let requestData = {
      channels: marketPlaces.map((mp) => mp.name),
      filters: {
        fromDate: fromDate,
        toDate: toDate,
        ReturnDetails: { ISNULL: false },
        ...filterCondition,
      },
      ...paginationCondition,
      ...sortCondition,
    };
    const data = await getReturnOrders(requestData);
    setRequestData(requestData);

    if (data.orders) {
      const orderData = await prepareData(data.orders);
      setAllOrders([...orderData]);
      setFilteredOrders([...orderData]);
      setTotalRecords(data.pagination.count);
      setTotalQuantity(data.total.QuantityShippedSum);
      // setTotalRefundSum(data.total.OrderTotalAmountSum);
      setLastSyncAt(data.lastSyncAt.returnsLastSyncAt);
      setIsLoading(false);
    }
  };
  const ExportAllData = async () => {
    try {
      setIsExportLoading(true)
      let exportRequestData = {
        params: {
          channels: marketPlaces.map((mp) => mp.name),
          fromDate: fromDate,
          toDate: toDate,
          ReturnDetails: { ISNULL: false },
        },
        reportType: "returns",
      };
      const data = await getExportApi(exportRequestData);
      if (data.status) {
      setIsExportLoading(false);
      message.success('The export is being processed, please check the Downloads page ', 10);
      }else{
      setIsExportLoading(false)
  
      }
  } catch (error) {
      setIsExportLoading(false);
  }
  };

  const onSearch = async (ds, searchText) => {
    let params = { ...requestData };
    if (params.filters) {
      params.filters.search = searchText.trim();
    } else {
      params.filters = {
        search: searchText.trim(),
      };
    }
    setIsLoading(true);
    const data = await getReturnOrders(params);
    setIsLoading(false);
    if (data.orders) {
      setTotalRecords(data.pagination.count);
      setTotalQuantity(data.total.QuantityShippedSum);
      // setTotalRefundSum(data.total.OrderTotalAmountSum);
      return await prepareData(data.orders);
    }
    return [];
  };

  const onShowModal = (value, row) => {
    setRowupdateStatus(row.FSReconciledReturnOrderStatus);
    setselectedRow(row);
    setshowModal(value);
  };

  const DetailPanel = ({ row }) => {
    return (
      <ReturnsDetailPanel
        row={row}
        onShowModal={(value) => {
          setselectedRow(row);
          setshowModal(value);
        }}
        showModal={showModal}
      />
    );
  };
  const resetReceived = async () => {
    let flipkartReturn = {
      FlipkartOrderId: selectedRow.FlipkartOrderId,
      TypeOrderItemId: selectedRow.TypeOrderItemId,
    };
    let amazonReturn = {
      AmazonOrderId: selectedRow.AmazonOrderId,
      TypeSellerSKU: selectedRow.TypeSellerSKU,
    };
    let jsonRequestBody = {
      ...flipkartReturn,
      ...amazonReturn,
      mark_received: false,
      channel: selectedRow.AmazonOrderId ? "Amazon-India" : "Flipkart",
      sent_quantity: 0, //values.sendQty,
      total_received_quantity: 0,
      non_acceptable: 0,
      sellable: 0,
    };

    ApiWrapper("MarkProductReturn", jsonRequestBody).then(
      function (response) {
        if (response.status) {
          selectedRow.FSReconciledReturnOrderStatus =
            response.FSReconciledReturnOrderStatus;
          let tmpfilterOrder = filteredOrders.map((order) => {
            if (
              (selectedRow.AmazonOrderId &&
                selectedRow.AmazonOrderId === order.AmazonOrderId) ||
              (selectedRow.FlipkartOrderId &&
                selectedRow.FlipkartOrderId === order.FlipkartOrderId)
            ) {
              order.mark_received = false;
              order.total_received_quantity = 0;
              order.non_acceptable = 0;
              order.sellable = 0;
            }
            return order;
          });
          setFilteredOrders(tmpfilterOrder);
          let tmpOrder = allOrders.map((order) => {
            if (
              (selectedRow.AmazonOrderId &&
                selectedRow.AmazonOrderId === order.AmazonOrderId) ||
              (selectedRow.FlipkartOrderId &&
                selectedRow.FlipkartOrderId === order.FlipkartOrderId)
            ) {
              order.mark_received = false;
              order.total_received_quantity = 0;
              order.non_acceptable = 0;
              order.sellable = 0;
            }
            return order;
          });
          setAllOrders(tmpOrder);
          setshowModal(false);
          // alert("Updated Successfully");
        }
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };
  const MarkReturn = async (values, actions) => {
    let flipkartReturn = {
      FlipkartOrderId: selectedRow.FlipkartOrderId,
      TypeOrderItemId: selectedRow.TypeOrderItemId,
    };
    let amazonReturn = {
      AmazonOrderId: selectedRow.AmazonOrderId,
      TypeSellerSKU: selectedRow.TypeSellerSKU,
    };
    let jsonRequestBody = {
      ...flipkartReturn,
      ...amazonReturn,
      mark_received: true,
      channel: selectedRow.AmazonOrderId ? "Amazon-India" : "Flipkart",
      sent_quantity: values.sendQty,
      total_received_quantity: values.totalQty,
      non_acceptable: values.nonAcceptable,
      sellable: values.sellable,
    };

    ApiWrapper("MarkProductReturn", jsonRequestBody).then(
      function (response) {
        if (response.status) {
          selectedRow.FSReconciledReturnOrderStatus =
            response.FSReconciledReturnOrderStatus;
          actions.resetForm();
          let tmpfilterOrder = filteredOrders.map((order) => {
            if (
              (selectedRow.AmazonOrderId &&
                selectedRow.AmazonOrderId === order.AmazonOrderId) ||
              (selectedRow.FlipkartOrderId &&
                selectedRow.FlipkartOrderId === order.FlipkartOrderId)
            ) {
              order.mark_received = true;
              order.total_received_quantity = values.totalQty;
              order.non_acceptable = values.nonAcceptable;
              order.sellable = values.sellable;
            }
            return order;
          });
          setFilteredOrders(tmpfilterOrder);
          let tmpOrder = allOrders.map((order) => {
            if (
              (selectedRow.AmazonOrderId &&
                selectedRow.AmazonOrderId === order.AmazonOrderId) ||
              (selectedRow.FlipkartOrderId &&
                selectedRow.FlipkartOrderId === order.FlipkartOrderId)
            ) {
              order.mark_received = true;
              order.total_received_quantity = values.totalQty;
              order.non_acceptable = values.nonAcceptable;
              order.sellable = values.sellable;
            }
            return order;
          });
          setAllOrders(tmpOrder);
          setshowModal(false);
          // alert("Updated Successfully");
        }
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <CustomAppBar />
      <CustomFilterHeader
        headerTitle="Returns Tracker"
        handleManualSyncClick={handleManualSyncClick}
        handleMarketplaceChange={handleMarketplaceChange}
        onSearchTextEntered={onSearchTextEntered}
        columns={columns}
        rows={allOrders}
        isLoading={isLoading}
        searchHide={true}
        searchFunction={onSearch}
        syncDate={lastSyncAt}
        showExportButton={false}
        showManualSync={false}
        // manualSyncModule={"RETURNS"}
        exportFileName="ReturnTracker"
        getExportData={ExportAllData}
        bulkUpload="bulkUpload"
        onFilterUpdate={(data) => {
          setFilteredOrders(data);
        }}
        // customHeader={
        //   <div className="mx-5">
        //     <AntButton
        //       type="primary"
        //       size="middle"
        //       className="h-full"
        //       icon={
        //         <span className="h-full text-white">
        //           <QrcodeOutlined style={{ fontSize: 21 }} />
        //         </span>
        //       }
        //       onClick={() => {
        //         window.open("/qr", "_blank");
        //       }}
        //     >
        //       <span className="ml-2 text-white font-medium">Scan QR</span>
        //     </AntButton>
        //   </div>
        // }
        customToggleProfit={
          <Spin spinning={isExportLoading}>
          <div className="ml-6">
            <ExportButton
              variant="outlined"
              onClick={ExportAllData}
            >
              Export to CSV
            </ExportButton>
          </div>
          </Spin>
        }
      />
      {showModal && (
        <Modal
          visible={showModal}
          onCancel={() => {
            selectedRow.FSReconciledReturnOrderStatus = updateRowStatus;
            setshowModal(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          footer={null}
          // closeAfterTransition
          // BackdropComponent={Backdrop}
          // BackdropProps={{
          //   timeout: 500,
          // }}
        >
          <div>
            <div className="flex items-center mb-3">
              <span className={`mb-3 mt-2 ${classes.title}`}>Mark Returns</span>
            </div>
            <Formik
              initialValues={{
                orderId: selectedRow.id,
                sendQty: (selectedRow.ReturnDetails || {}).ReturnQuantity || 0,
                totalQty: (selectedRow.QuantityShipped) || 0,
                sellable: (selectedRow.non_acceptable >= 1  ? selectedRow?.sellable :  selectedRow.QuantityShipped  ) || 0,
                nonAcceptable: selectedRow.non_acceptable  || 0,
              }}
              onSubmit={(values, actions) => {
                MarkReturn(values, actions);
              }}
              validationSchema={validationSchema}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <div className="mt-3">
                    <CustomTextField
                      title="Send Quantity"
                      name="sendQty"
                      type="number"
                      disabled={true}
                    />
                  </div>
                  <div className="mt-4 pt-2">
                    <CustomTextField
                      title="Total Received Quantity"
                      name="totalQty"
                      type="number"
                      disabled={false}
                      helpText="Enter received quantity"
                    />
                  </div>
                  <Divider className="mt-4" />
                  <div className={`mt-4 ${classes.textFieldTitle}`}>
                    Condition of Returned Goods:
                  </div>
                  <div className="mt-4 pt-2">
                    <CustomTextField
                      title="Sellable"
                      name="sellable"
                      type="number"
                      disabled={false}
                      helpText="Enter acceptable quantity"
                    />
                  </div>
                  <div className="mt-4 pt-2">
                    <CustomTextField
                      title="Non-Sellable"
                      name="nonAcceptable"
                      type="number"
                      disabled={false}
                      helpText="Enter non-sellable quantity"
                    />
                  </div>
                  <div className="flex justify-between">
                    <Button
                      className={` text-capitalize text-white mr-4`}
                      onClick={() => {
                        resetReceived();
                      }}
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontWeight: 500,
                        marginTop: 24,
                        marginBottom: 20,
                        border: "1px solid #DFE1E6",
                        backgroundColor:
                          "linear-gradient(180deg, #FFFFFF 0%, #F4F5F7 100%)",
                      }}
                    >
                      <div style={{ color: "#253858" }}>Reset</div>
                    </Button>
                    <div className="flex justify-end align-center">
                      <Button
                        className={` text-capitalize text-white mr-4`}
                        onClick={() => {
                          setshowModal(false);
                        }}
                        style={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontWeight: 500,
                          marginTop: 24,
                          marginBottom: 20,
                          border: "1px solid #DFE1E6",
                          backgroundColor:
                            "linear-gradient(180deg, #FFFFFF 0%, #F4F5F7 100%)",
                        }}
                      >
                        <div style={{ color: "#253858" }}>Cancel</div>
                      </Button>
                      <Button
                        className={` primary text-capitalize text-white ${classes.btnPrimary}`}
                        onClick={() => {
                          submitForm();
                        }}
                        style={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontWeight: 500,
                          marginTop: 24,
                          marginBottom: 20,
                          backgroundColor: "#57D9A3",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          columns={RefColumns}
          dataSource={filteredOrders}
          stickyHeader={{
            offsetHeader: 65,
            // offsetScroll: 216,
          }}
          // scroll={{ x: 1400 }}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={isLoading}
          hideSummary={!filteredOrders?.length}
          searchText={[searchText]}
          totalRecords={totalRecords}
          expandable={{
            defaultExpandAllRows: false,
            defaultExpandedRowKeys: [],
            expandedRowRender: (record) => <DetailPanel row={record} />,
            // rowExpandable: (record) => false,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownCircleFilled onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightCircleFilled onClick={(e) => onExpand(record, e)} />
              ),
          }}
          onDataChange={onTableChange}
        />
      </div>
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "85%",
    maxWidth: 480,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow:
      "0px 24px 18px rgba(23, 43, 77, 0.04), 0px 35px 24px rgba(23, 43, 77, 0.08)",
    padding: "32px 20px 20px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: `0 !important`,
  },
  title: {
    align: "center",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "16px",
    color: "#44444F",
  },
  textFieldTitle: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#7A828A",
  },
  btnPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
}));
export default withRouter(ReturnsTracker);
