import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, message } from "antd";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import ".././App.css";
import CustomTextField from "../components/CustomTextField";
import alertCircle from "../icons/alert-circle.svg";
import deleteIcon from "../icons/deleteIcon.png";
import editIcon from "../icons/editIcon.png";
import memberIcon from "../icons/memberIcon.svg";
import "../Styles/settings.css";
import ApiWrapper from "../utils/ApiWrapper";
// import CreateRole from './createRole';

//  from 'react-bootstrap/Modal';
// var BootstrapModal = import("react-bootstrap/Modal");

const VerifyValidationSchema = Yup.object().shape({
  mobileOTP: Yup.string().trim().required("Required"),
  emailOTP: Yup.string().trim().required("Required"),
});
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 480,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow:
      "0px 24px 18px rgba(23, 43, 77, 0.04), 0px 35px 24px rgba(23, 43, 77, 0.08)",
    padding: "32px 20px 20px",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: `0 !important`,
  },
  tableCellHeading: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#44444F",
    padding: "12px 24px 12px 16px",
  },
  tableCellDesc: {
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#717480",
    padding: "12px 24px 12px 16px",
  },
  btnPrimary: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  linkHelp: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: "center",
    color: "#7A828A",
  },
  linkText: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: "center",
    color: "#283891",
  },
  poweredBy: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    color: "#425466",
    marginTop: 20,
  },
  table: {
    maxWidth: 832,
    marginTop: 22,
    marginBottom: 57,
  },

  title: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "16px",
    color: "#44444F",
  },
  desc: {
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#717480",
  },
  selectMenu: {
    minWidth: 80,
    marginRight: "10px",
    "&:before": {
      border: "none !important",
    },
    "&:after": {
      border: "none",
    },
    "& .MuiSelect-icon": {
      top: `calc(50% - 9px)`,
    },
  },
}));

const ModalContainer = ({
  showModal,
  setModal,
  title,
  titleIcon,
  message,
  buttonContainer,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => {
          setModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
        <div className={`${classes.paper}`}>
          <div className="flex items-center">
            <img src={titleIcon} alt="deleteIcon" />

            <span className={`ml-3 h6 ${classes.title}`}>{title}</span>
          </div>
          <div className={`mt-3 mb-4 text-muted ${classes.desc}`}>
            {message}
          </div>
          <div className="flex justify-end">
            <button
              style={{
                borderRadius: "5px",
              }}
              className={`px-3 py-2 border-0 ${classes.desc}`}
              onClick={() => setModal(false)}
            >
              Cancel
            </button>
            {buttonContainer ? buttonContainer : <></>}
          </div>
        </div>
      </Modal>
    </div>
  );
};

function UsersTable({ usersList, onUpdateUser }) {
  const classes = useStyles();
  // const [optionModal, setOptionModal] = useState(false);
  const [roles, setRoles] = useState(["Admin", "Member"]);
  const [addUserModal, setAddUserModal] = useState(false);
  // const [roleModal, setRoleModal] = useState(false);
  const [newRole, setNewRole] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [userCurrentRole, setUserCurrentRole] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState({});
  const [updatedUserList, setUpdatedUserList] = useState(usersList);
  const [userCreated, setUserCreated] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    useremail: Yup.string().trim().email().required("Required"),
    mobileNumber: Yup.string()
      .trim()
      .matches(
        /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/,
        "Invalid Mobile number"
      )
      .required("Required"),
    ...(!isEdit && {
      userpassword: Yup.string().trim().min(8).required("Required"),
    }),
  });
  React.useEffect(() => {
    if (!addUserModal) {
      setIsEdit(false);
      setUser({});
    }
  }, [addUserModal]);

  // useEffect(() => {}, [updatedUserList]);

  const getUsersList = async () => {
    try {
      ApiWrapper("listUserAdmin", {}).then(
        (response) => {
          if (response.status) {
            setUpdatedUserList(response.users);
          }
        },
        (error) => {
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
        }
      );
    } catch (error) {
      // message.error(
      //   "We encountered an issue. Please log out and log back in. "
      // );

      console.log(error);
    }
  };

  function removeUser(userEmail) {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_PROFILE_URL}/remove-member/admin`,
      headers: {
        "Content-Type": "application/json",
        "X-FS-Authorization": localStorage.token,
      },
      data: {
        email: userEmail,
      },
    })
      .then((response) => {
        setDeleteModal(false);
        getUsersList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function changeUserRole() {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_PROFILE_URL}/change-role/admin`,
      headers: {
        "Content-Type": "application/json",
        "X-FS-Authorization": localStorage.token,
      },
      data: {
        email: currentEmail,
        role: userCurrentRole === "member" ? "admin" : "member",
      },
    })
      .then((response) => {
        message.success(
          `${currentUser} is now an ${
            userCurrentRole === "member" ? "Admin" : "Member"
          }`
        );
        getUsersList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function verifyUser(values) {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_PROFILE_URL}/verify-otp/admin`,
      headers: {
        "Content-Type": "application/json",
        "X-FS-Authorization": localStorage.token,
      },
      data: {
        medium: "email",
        otp: values.emailOTP,
        action: "verify",
        email: email,
      },
    })
      .then((response) => {
        if (response.data.status) {
          getUsersList();
          verifyMobile(values);
        } else {
          setAddUserModal(false);

          message.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function verifyMobile(values) {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_PROFILE_URL}/verify-otp/admin`,
      headers: {
        "Content-Type": "application/json",
        "X-FS-Authorization": localStorage.token,
      },
      data: {
        medium: "mobile",
        otp: values.mobileOTP,
        action: "verify",
        email: email,
      },
    })
      .then((response) => {
        if (response.data.status) {
          getUsersList();
          setAddUserModal(false);
          setUserCreated(false);
        } else {
          setAddUserModal(false);
          message.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addUser(values) {
    setUserCreated(false);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_PROFILE_URL}/signup/admin`,
      headers: {
        "Content-Type": "application/json",
        "X-FS-Authorization": localStorage.token,
        Authorization: localStorage.token,
      },
      data: {
        email: values.email,
        phone_number: `+91${values.mobileNumber}`,
        name: values.name,
        password: values.password,
      },
    })
      .then((response) => {
        if (response.data.status) { 
          getUsersList();
          setUserCreated(true);
        } else {
          setAddUserModal(false);
          message.error(response.data.message);
        }
      })
      .catch((err) => {
         message.error({
          content: err.response.data.message,
          style: { textAlign: "right" }
          });
 
      });
  }
  const editUser = async (values) => {
    let userInfo = JSON.parse(localStorage.getItem("user_info"));
    let isCurrentUser = userInfo.email === values.email;
    try {
      // editUserAdmin
      ApiWrapper(isCurrentUser ? "editUserInfo" : "editUserAdmin", {
        email: values.email,
        name: values.name,
      }).then(
        (response) => {
          if (response.status) {
            if (isCurrentUser) {
              let namesArray = (values.name || "").split(" ");
              userInfo.name = values.name;
              userInfo.first_name = namesArray.shift();
              userInfo.last_name = namesArray.join(" ");
              localStorage.setItem("user_info", JSON.stringify(userInfo));

              onUpdateUser(userInfo);
            }
            getUsersList();
            setAddUserModal(false);
          }
        },
        (error) => {
          
        }
      );
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
    <div>
    {/* <CreateRole /> */}
      <Button
        className={`text-white primary hover:text-white hover:bg-primaryHover focus:bg-primaryHover focus:text-white rounded text-capitalize ${classes.btnPrimary}`}
        size="large"
        style={{ width: "max-content", position: "absolute", right: "7rem" }}
        onClick={() => {
          setAddUserModal(true);
          // setUserCreated(true)
        }}
      >
        Add New User
      </Button>

    </div>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHeading}>Name</TableCell>
              <TableCell className={classes.tableCellHeading} align="left">
                Email
              </TableCell>
              <TableCell className={classes.tableCellHeading} align="left">
                Mobile
              </TableCell>
              <TableCell className={classes.tableCellHeading} align="left">
                Status
              </TableCell>
              <TableCell className={classes.tableCellHeading} align="left">
                Roles
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updatedUserList?.map((user, index) => (
              <TableRow key={index}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCellDesc}
                >
                  <div style={{ width: "max-content" }}>{user.name}</div>
                </TableCell>
                <TableCell align="left" className={classes.tableCellDesc}>
                  {user.email}
                </TableCell>
                <TableCell align="left" className={classes.tableCellDesc}>
                  {user.phone_number}
                </TableCell>
                <TableCell align="left" className={classes.tableCellDesc}>
                  {user.verified ? "Verified" : "Pending"}
                </TableCell>
                <TableCell align="left" className={classes.tableCellDesc}>
                  <div className="flex" style={{ width: "max-content" }}>
                    <FormControl className={classes.formControl}>
                      <Select
                        onChange={(e) => {
                          setCurrentUser(user.name);
                          setCurrentEmail(user.email);
                          setUserCurrentRole(user.role);
                          setChangeRoleModal(true);

                          // changeRole(user.name, user.role, user.email);
                        }}
                        className={`${classes.selectMenu} ${classes.desc}`}
                        value={user.role}
                      >
                        <MenuItem
                          disabled
                          style={{
                            borderBottom: "2px solid rgba(0, 0, 0, 0.05)",
                          }}
                          className={classes.desc}
                        >
                          Change Role
                        </MenuItem>
                        {/* <MenuItem
                          style={{
                            fontSize: "1px",
                            backgroundColor: "#f2f2f2",
                          }}
                          disabled
                        ></MenuItem> */}
                        {roles
                          // .filter(
                          //   (item) => item.toLocaleLowerCase() !== user.role
                          // )
                          .map((ele, index) => (
                            <MenuItem
                              key={index}
                              value={ele.toLocaleLowerCase()}
                              style={{ minWidth: 62, color: "#44444F" }}
                              className={classes.tableCellDesc}
                            >
                              {`${ele}`}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    {/* <i
                      className="fa fa-angle-down mx-2"
                      onClick={() => {
                        setRoleModal(true);
                        // changeRole(user.name, user.role, user.email);
                      }}
                    ></i> */}

                    {/* <button
                      style={{ border: "none", width: "5rem" }}
                      onClick={() => setRoleModal(true)}
                    >
                      {`${user.role[0].toUpperCase()}${user.role.slice(1)}`}
                    </button>

                    {roleModal && (
                      <>
                        {roles
                          .filter(
                            (item) => item.toLocaleLowerCase() !== user.role
                          )
                          .map((ele, index) => (
                            <button
                              key={index}
                              value={ele}
                              onClick={(e) => handleClick(ele)}
                            >
                              {ele}
                            </button>
                          ))}
                      </>
                    )} */}

                    {/* <select
                      name="roles"
                      className="selectors"
                      // defaultValue={user.role}
                      // onSelect={(e) => handleClick(e)}
                      onChange={(e) => {
                        handleClick(e);
                        changeRole(user.name, user.role, user.email);
                      }}
                      style={{ border: "none", width: "5rem" }}
                    >
                      <option disabled hidden selected>
                        {`${user.role[0].toUpperCase()}${user.role.slice(1)}`}
                      </option>
                      <option disabled>Change Role</option>
                      <option
                        style={{ fontSize: "1px", backgroundColor: "#f2f2f2" }}
                        disabled
                      ></option>
                      {roles
                        .filter(
                          (item) => item.toLocaleLowerCase() !== user.role
                        )
                        .map((ele, index) => (
                          <option
                            key={index}
                            value={ele}
                            onClick={(e) => handleClick(ele)}
                          >
                            {ele}
                          </option>
                        ))}
                    </select> */}
                    <div>
                      <div className="flex">
                        <img
                          className={
                            user.role === "admin" ? "ml-2 mr-2" : "mx-2"
                          }
                          src={editIcon}
                          alt="edit icon"
                          style={{
                            border: "1px solid #DFE1E6",
                            borderRadius: "3px",
                            padding: "7px 7px",
                          }}
                          onClick={() => {
                            setUser(user);
                            setIsEdit(true);
                            setUserCreated(false);
                            setAddUserModal(true);
                          }}
                        />
                        <img
                          src={deleteIcon}
                          alt="delete icon"
                          style={{
                            border: "1px solid #DFE1E6",
                            borderRadius: "3px",
                            padding: "6px 7px",
                          }}
                          onClick={() => {
                            setCurrentUser(user.name);
                            setCurrentEmail(user.email);
                            setDeleteModal(true);
                            // removeUser(user.email);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalContainer
        showModal={newRole !== currentRole}
        setModal={() => {
          setCurrentRole(currentRole);
          //setRoleModal(false);
        }}
        title="Remove Member"
        titleIcon={alertCircle}
        message={`Are you sure you want to delete ${currentUser}? This action
            is not reversible.`}
        buttonContainer={
          <button
            style={{
              color: "#fff",
              backgroundColor: "#FF7452",
              borderRadius: "5px",
            }}
            className={`ml-3 px-3 py-2 border-0 ${classes.desc}`}
            onClick={() => removeUser(currentEmail)}
          >
            Delete
          </button>
        }
      />
      <ModalContainer
        showModal={deleteModal}
        setModal={setDeleteModal}
        title="Remove Member"
        titleIcon={alertCircle}
        message={`Are you sure you want to delete ${currentUser}? This action
            is not reversible.`}
        buttonContainer={
          <button
            style={{
              color: "#fff",
              backgroundColor: "#FF7452",
              borderRadius: "5px",
            }}
            className={`ml-3 px-3 py-2 border-0 ${classes.desc}`}
            onClick={() => removeUser(currentEmail)}
          >
            Delete
          </button>
        }
      />
      <ModalContainer
        showModal={changeRoleModal}
        setModal={() => setChangeRoleModal(false)}
        title={`Make ${userCurrentRole === "admin" ? "Member" : "Admin"}`}
        titleIcon={memberIcon}
        message={` Are you sure you want to make ${currentUser} ${
          userCurrentRole === "admin" ? "Member" : "Admin"
        }?`}
        buttonContainer={
          <button
            style={{
              color: "#fff",
              backgroundColor: "#57D9A3",
              borderRadius: "5px",
            }}
            className={`ml-3 px-3 py-2 border-0 ${classes.desc}`}
            onClick={() => {
              setChangeRoleModal(false);
              changeUserRole();
            }}
          >
            Confirm
          </button>
        }
      />
      <Modal
        open={addUserModal}
        onClose={(event,reason) => {
          if (reason !== 'backdropClick') {
            setAddUserModal(false);  
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
      
        <div className={`${classes.paper}`}>
          <div className="flex items-center"></div>
          <div>
            <span className="h5" style={{ color: "0065FF" }}>
              <i className="fa fa-user-plus"></i>
            </span>
            <span className="ml-2 h5">
              {isEdit
                ? "Edit User"
                : userCreated === false
                ? "Add New User"
                : "Verify User"}
            </span>
            <div className="my-3 text-muted">
              Enter the following details to
              {isEdit
                ? " edit"
                : userCreated === false
                ? " create new"
                : " verify new"}{" "}
              user
            </div>
            {userCreated && (
              <Formik
                initialValues={{
                  emailOTP: "",
                  mobileOTP: "",
                }}
                onSubmit={(values, actions) => {
                  verifyUser(values);
                }}
                validationSchema={VerifyValidationSchema}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <div className="form-group">
                      <CustomTextField
                        disabled={false}
                        title="Email OTP"
                        name="emailOTP"
                        type="text"
                        helpText="Enter Email OTP"
                      />
                    </div>
                    <div className="form-group">
                      <CustomTextField
                        disabled={false}
                        title="Mobile OTP"
                        name="mobileOTP"
                        type="text"
                        helpText="Enter Mobile OTP"
                      />
                    </div>

                    <div className="flex mt-5 justify-end">
                      <Button
                        className="mx-2"
                        type="link"
                        onClick={() => {
                          setAddUserModal(false);
                          setUserCreated(false);
                        }}
                        // block
                      >
                        Remind Me Later
                      </Button>
                      <Button
                        type="button"
                        className={`text-white primary hover:text-white hover:bg-primaryHover focus:bg-primaryHover focus:text-white rounded text-capitalize`}
                        // color="primary"
                        type="primary"
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        Verify User
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {!userCreated && (
              <Formik
                initialValues={
                  isEdit
                    ? {
                        name: user.name || "",
                        useremail: user.email || "",
                        mobileNumber: user.phone_number || "",
                        userpassword: "",
                      }
                    : {
                        name: "",
                        useremail: "",
                        mobileNumber: "",
                        userpassword: "",
                      }
                }
                onSubmit={(values, actions) => {
                  if (!isEdit) {
                    setEmail(values.useremail);
                    addUser({
                      ...values,
                      ...{
                        email: values.useremail,
                        password: values.userpassword,
                      },
                    });
                  } else {
                    editUser({
                      ...values,
                      ...{
                        email: values.useremail,
                        password: values.userpassword,
                      },
                    });
                  }
                }}
                validationSchema={validationSchema}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <div className="form-group pb-3">
                      <CustomTextField
                        disabled={false}
                        title="Name"
                        name="name"
                        type="text"
                        helpText="Enter Name"
                      />
                    </div>
                    <div className="form-group pb-3">
                      <CustomTextField
                        disabled={isEdit ? true : false}
                        title="Email address"
                        name="useremail"
                        type="text"
                        helpText="Enter email"
                      />
                    </div>
                    {!isEdit && (
                      <div className="form-group pb-3">
                        <CustomTextField
                          disabled={false}
                          title="Mobile Number"
                          name="mobileNumber"
                          helpText="Enter Mobile number"
                          type="text"
                        />
                      </div>
                    )}
                    {!isEdit && (
                      <div className="form-group pb-3">
                        <CustomTextField
                          disabled={false}
                          title="Password"
                          name="userpassword"
                          type="password"
                          helpText="Enter Password"
                        />
                      </div>
                    )}
                    <div className="flex justify-end">
                      <Button
                        // className="ml-auto mx-2 rounded"
                        className="mx-2"
                        type="link"
                        onClick={() => setAddUserModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={`text-white primary hover:text-white hover:bg-primaryHover focus:bg-primaryHover focus:text-white rounded text-capitalize`}
                        // color="primary"
                        type="primary"
                        // className="btn btn-success"
                        // className={` primary text-white rounded`}
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        {isEdit ? "Edit User" : "Create User"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UsersTable;
