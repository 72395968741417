import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  DatePicker,
  message,
  Select,
  Spin,
  Menu,
  Checkbox,
  Dropdown,
  Button,
} from "antd";
import moment from "moment";
import React, {  useState } from "react";
import { Helmet } from "react-helmet";
import "../../App.css";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import Tabs from "../../components/Tabs";
import { PostValues } from "../../utils/api-client";
import ProductTabV2 from "./productTabV4";
import OrderTabV2 from "./orderTabV4";
import ChannelTabV2 from "./channelTabV4";
import InputProductTabV2 from "./inputProductCostV4";
import ExportButton from "../../components/Styles/ExportButton";
import ProfitSummary from "./profitSummary";
import { DownOutlined } from "@ant-design/icons";

const TabsList = [
  "Summary",
  "Product Profitability",
  "Order Profitability",
  "Channel Profitability",
  "Input Product Cost",
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Profitability() {
  const [searchText, setSearchText] = useState("");
  const [tabValue, setTabValue] = React.useState(0);
  const [columns, setColumns] = useState([]);
  const [startDate, seStarttDate] = React.useState({});
  const [endDate, setEndDate] = React.useState({});
  const [salesChannel, setSalesChannel] = useState([]);
  const [toggledata, setToggleVal] = React.useState(false);
  const [adsEventVal, setAdsEventVal] = React.useState(true);
  const [unsettled, setUnsettled] = React.useState("all_orders");
  const [taxValue, setTaxValue] = React.useState("With Tax");
  const [adsValue, setAdsValue] = React.useState("With Ads");
  const [unsettledOrders, SetUnsettledOrders] = React.useState("all_orders");
  const [requestData, setRequestData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [getProductData, setProductData] = useState([]);
  const [getOrderData, setOrderData] = useState([]);
  const [getChannelData, setChannelData] = useState([]);
  const [getInputProductData, setInputProductData] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [profitTotal, setProfitTotal] = React.useState([]);
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const [taxStartDate, setTaxStartDate] = useState(moment().subtract(1, "month"));
  const [profitCard, setProfitCard] = useState([]);
  const [leaksColumn, setLeaksColumn] = React.useState(null);
  const [fullfillmentType, setFullfillmentType] = React.useState(null);
  const [profitTableData, setProfitTableData] = React.useState([]);
  const [profitGraphData, setProfitGraphData] = React.useState([]);

  // const [searchBool, setSearchBool] = React.useState(true);
  localStorage.setItem(
    "adsToggleEvent",
    JSON.stringify({ ads_event: adsEventVal })
  );
  localStorage.setItem(
    "toggleEvent",
    JSON.stringify({ gst_toggle: toggledata })
  );
  localStorage.setItem(
    "unsettled",
    JSON.stringify({ unsettledToggle: unsettled })
  );

  const handleManualSyncClick = () => {};

  const { Option } = Select;
  const getTabApi = async (requestData) => {
    let response = await PostValues("profitApiV4", requestData);
    return response;
  };
  const getInputProductApi = async (requestData) => {
    let response = await PostValues("profitInputProductCost", requestData);
    return response;
  };
  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };

  const onProfitToggleChange = () => {
    handleMarketplaceChange(salesChannel, startDate, endDate);
  };
  const onSuccess = (result) => {
    const cardData = Object.assign({}, ...result[0]?.data.cards);
    setProfitCard(cardData);
    const tableData = Object.assign({}, ...result[1]?.data);
    setProfitTableData(tableData);
    const graphData = Object.assign({}, ...result[2]?.data);
    setProfitGraphData(graphData);
    setIsLoading(false);
  };

  const filterTabvalue = async (data, type) => {
    setLeaksColumn(data);
    if (type !== undefined) {
      setFullfillmentType(type);
    }
    setTabValue(1);
  };

  const ExportAllData = async () => {
    let exportToggle = JSON.parse(localStorage.getItem("toggleEvent"));
    let exportAds = JSON.parse(localStorage.getItem("adsToggleEvent"));
    let exportEstimate = JSON.parse(localStorage.getItem("unsettled"));
    try {
      setIsExportLoading(true);
      let exportRequestData = {
        params: {
          channels: salesChannel.map((mp) => mp.name),
          fromDate: startDate,
          toDate: endDate,
          payment: exportEstimate?.unsettledToggle,
          profitTab:
            tabValue === 1
              ? "product"
              : tabValue === 2
              ? "order"
              : tabValue === 3
              ? "channel"
              : "",
          toggle: exportToggle?.gst_toggle,
          ads: exportAds?.ads_event,
          pagination: {
            pageNo: 0,
            pageSize: totalRecords,
            count: totalRecords,
          },
        },
        reportType: "profitability",
      };
      if (leaksColumn) {
        if (
          leaksColumn === "merchant" ||
          leaksColumn === "smart" ||
          leaksColumn === "fba" ||
          leaksColumn === "all"
        ) {
          exportRequestData.params["fulfillment"] = leaksColumn;
        } else {
          exportRequestData.params["margin"] = leaksColumn;
          exportRequestData.params["fulfillment"] = fullfillmentType;
        }
      }
      const data = await getExportApi(exportRequestData);
      if (data.status) {
        setIsExportLoading(false);
        message.success(
          "The export is being processed, please check the Downloads page ",
          10
        );
      } else {
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };
  const handleMarketplaceChange = async (
    selectedMarketplaces,
    fromDate,
    toDate
  ) => {
    seStarttDate(fromDate);
    setEndDate(toDate);
    setSalesChannel(selectedMarketplaces);
    if (selectedMarketplaces.length > 0) {
      getProfit(selectedMarketplaces, fromDate, toDate, searchText);
    } else {
      setProductData([]);
      setOrderData([]);
      setChannelData([]);
    }
  };
  const getProfit = async (
    selectedMarketplaces,
    fromDate,
    toDate,
    searchText,
    paginationCond,
    sortCond,
    filterCond = {}
  ) => {
    let dataOf = JSON.parse(localStorage.getItem("toggleEvent"));
    let adsToggle = JSON.parse(localStorage.getItem("adsToggleEvent"));
    let unsettled = JSON.parse(localStorage.getItem("unsettled"));
    let requestData = {
      channels: selectedMarketplaces.map((mp) => mp.name),
      filters: {
        fromDate: fromDate,
        toDate: toDate,
        ...filterCond,
      },
      ...(paginationCond
        ? paginationCond
        : {
            pagination: {
              pageNo: 0,
              pageSize: 25,
            },
          }),
      ...(sortCond ? sortCond : {}),
    };
    if (searchText) {
      requestData.filters["search"] = searchText;
    }
    setRequestData(requestData);
    if (tabValue === 0) {
      setIsLoading(true);
      requestData.filters["ads"] = adsToggle?.ads_event || false;
      requestData.filters["toggle"] = dataOf?.gst_toggle || false;
      requestData.filters["payment"] =
        unsettled?.unsettledToggle || "all_orders";
      requestData.filters["profitTab"] = "product";
      setIsLoading(true);
      Promise.all([
        PostValues("profitSummaryCard", requestData),
        PostValues("profitSummaryTable", requestData),
        PostValues("profitSummaryGraph", requestData),
      ])
        .then(function (responses) {
          return Promise.all(
            responses.map(function (response) {
              return response;
            })
          );
        })
        .then(function (result) {
          onSuccess(result);
        })
        .catch(function (error) {});
      // setIsLoading(false);
    }
    if (tabValue === 1) {
      // setSearchBool(true)
      setIsLoading(true);
      requestData.filters["ads"] = adsToggle?.ads_event || false;
      requestData.filters["toggle"] = dataOf?.gst_toggle || false;
      requestData.filters["payment"] =
        unsettled?.unsettledToggle || "all_orders";
      requestData.filters["profitTab"] = "product";
      if (leaksColumn) {
        if (
          leaksColumn === "merchant" ||
          leaksColumn === "smart" ||
          leaksColumn === "fba" ||
          leaksColumn === "all"
        ) {
          requestData.filters["fulfillment"] = leaksColumn;
        } else {
          requestData.filters["margin"] = leaksColumn;
          requestData.filters["fulfillment"] = fullfillmentType;
        }
      }
      let ProductData = await getTabApi(requestData);
      if (ProductData) {
        setProductData(ProductData.response);
        setTotalRecords(ProductData?.totals?.TotalCount);
        setProfitTotal(ProductData?.totals);
      }
      setIsLoading(false);
    }
    if (tabValue === 2) {
      // setSearchBool(true)
      setIsLoading(true);
      requestData.filters["ads"] = adsToggle?.ads_event || false;
      requestData.filters["toggle"] = dataOf?.gst_toggle || false;
      requestData.filters["payment"] =
        unsettled?.unsettledToggle || "all_orders";
      requestData.filters["profitTab"] = "order";
      let OrderData = await getTabApi(requestData);
      if (OrderData) {
        setOrderData(OrderData.response);
        setTotalRecords(OrderData?.totals?.TotalCount);
        setProfitTotal(OrderData?.totals);
      }
      setIsLoading(false);
    }
    if (tabValue === 3) {
      // setSearchBool(false)
      setIsLoading(true);
      requestData.filters["ads"] = adsToggle?.ads_event || false;
      requestData.filters["toggle"] = dataOf?.gst_toggle || false;
      requestData.filters["payment"] =
        unsettled?.unsettledToggle || "all_orders";
      requestData.filters["profitTab"] = "channel";
      let arr = [];
      const prepareData = (channel) => {
        channel.forEach((row, index) => {
          arr.push({
            ...row,
            Channel: row.channel || row.Channel,
          });
        });
        return arr;
      };
      let Channel = await getTabApi(requestData);
      if (Channel) {
        const channelData = await prepareData(Channel.response);
        setChannelData(channelData);
        setTotalRecords(Channel?.totals?.TotalCount);
        setProfitTotal(Channel?.totals);
      }
      setIsLoading(false);
    }
    if (tabValue === 4) {
      //  setSearchBool(true)
      setIsLoading(true);
      let requestDataInput = {
        channels: selectedMarketplaces.map((mp) => mp.name),
        filters: {
          ...filterCond,
          status: { IN: ["ACTIVE", "INACTIVE", "Active", "Inactive"] },
        },
        ...(paginationCond
          ? paginationCond
          : {
              pagination: {
                pageNo: 0,
                pageSize: 25,
              },
            }),
        ...(sortCond ? sortCond : {}),
      };
      requestDataInput.filters["search"] = searchText;

      let arr = [];
      const prepareData = (orders) => {
        orders.forEach((row, index) => {
          arr.push({
            ...row,
            key: `${row.channel}_${index}`,
          });
        });
        return arr;
      };
      let InputCost = await getInputProductApi(requestDataInput);
      if (InputCost.listings) {
        const listingsData = await prepareData(InputCost.listings);
        setInputProductData(listingsData);
        setTotalRecords(InputCost.pagination.count);
      }
      setIsLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };
  const settledUpdate = (event) => {
    let eventorder = event.target.value;
    SetUnsettledOrders(eventorder);
    localStorage.setItem(
      "unsettled",
      JSON.stringify({ unsettledToggle: eventorder })
    );
    setUnsettled(eventorder);
  };

  React.useEffect(() => {
    if (salesChannel.length > 0) {
      getProfit(salesChannel, startDate, endDate, searchText);
      if (tabValue !== 1) {
        setLeaksColumn(null);
        setFullfillmentType(null);
      }
    }
  }, [tabValue]);
  const menu = (
    <Menu>
      <div className="p-4">
        <div class="grid grid-cols-2 gap-4">
          <Checkbox
            className="ml-2"
            value="false"
            checked={!adsEventVal}
            onChange={(event) => {
              let adsEvent = !event.target.checked;
              setAdsValue(adsEvent == true ? "With Ads" : "Without Ads");
              localStorage.setItem(
                "adsToggleEvent",
                JSON.stringify({ ads_event: adsEvent })
              );
              setAdsEventVal(adsEvent);
            }}
          >
            Without Ads
          </Checkbox>
          <Checkbox
            value="true"
            checked={adsEventVal}
            onChange={(event) => {
              let adsEvent = event.target.checked;
              setAdsValue(adsEvent == true ? "With Ads" : "Without Ads");
              localStorage.setItem(
                "adsToggleEvent",
                JSON.stringify({ ads_event: adsEvent })
              );
              setAdsEventVal(adsEvent);
            }}
          >
            With Ads
          </Checkbox>
          {/* <Divider type="horizontal" /> */}
          <Checkbox
            value="true"
            checked={toggledata}
            onChange={(event) => {
              let eventTax = event.target.checked;
              setTaxValue(eventTax == true ? "WithOut Tax" : "With Tax");
              localStorage.setItem(
                "toggleEvent",
                JSON.stringify({ gst_toggle: eventTax })
              );
              setToggleVal(eventTax);
            }}
          >
            Without Tax
          </Checkbox>
          <Checkbox
            value="false"
            checked={!toggledata}
            onChange={(event) => {
              let eventTax = !event.target.checked;
              setTaxValue(eventTax == true ? "WithOut Tax" : "With Tax");
              localStorage.setItem(
                "toggleEvent",
                JSON.stringify({ gst_toggle: eventTax })
              );
              setToggleVal(eventTax);
            }}
          >
            With Tax
          </Checkbox>
          {/* <Divider type="horizontal" /> */}
          <Checkbox
            value="settled"
            checked={unsettled === "settled"}
            onChange={settledUpdate}
          >
            Without Estimate
          </Checkbox>
          <Checkbox
            value="all_orders"
            checked={unsettled === "all_orders"}
            onChange={settledUpdate}
          >
            With Estimate
          </Checkbox>
        </div>
      </div>
      <div className="flex flex-row-reverse">
        <Menu.Item>
          <Button onClick={onProfitToggleChange}>Apply</Button>
        </Menu.Item>
      </div>
    </Menu>
  );
  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <div>
        <CustomAppBar />
        <CustomFilterHeader
          headerTitle="Profitability Tracker"
          handleManualSyncClick={handleManualSyncClick}
          handleMarketplaceChange={handleMarketplaceChange}
          showDateRanges={tabValue !== 4}
          showManualSync={false}
          searchFunction={(ds, searchText) => {
            getProfit(salesChannel, startDate, endDate, searchText.trim());
          }}
          rows={tabValue === 1 ? getProductData : []}
          getExportData={ExportAllData}
          searchHide={true}
          showExportButton={false}
          showSearchBar={true}
          // showSearchBarBox={searchBool}
          columns={columns}
          isLoading={isLoading}
          onFilterUpdate={(data) => {}}
          exportFileName="profitabilityTracker"
          customExportButton={
            (tabValue === 1 || tabValue === 2) && (
              <Spin spinning={isExportLoading}>
                <div className="ml-6">
                  <ExportButton variant="outlined" onClick={ExportAllData}>
                    Export to CSV
                  </ExportButton>
                </div>
              </Spin>
            )
          }
          customToggleProfit={
            tabValue !== 4 ? (
              <>
                {" "}
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomLeft"
                  arrow
                >
                  <Button onClick={(e) => e.preventDefault()}>
                    Filters <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            ) : (
              <>
              <p className="mr-1">Change Product Cost From: </p>
              <DatePicker
                onChange={(date, dateStrings) => {
                  setTaxStartDate(date);
                }}
                format={"DD/MM/YYYY"}
                defaultValue={ moment().subtract(1, "month")}
                disabledDate={(date) => {
                  return date < moment().subtract(1, "month");
                }}
              />
              </>
            )
          }
          onSearchTextEntered={handleSearch}
        />
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <Tabs
            value={tabValue}
            list={TabsList}
            onChange={(value) => {
              setTabValue(value);
            }}
          />
        </div>
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <TabPanel value={tabValue} index={0}>
            <ProfitSummary
              profitCard={profitCard}
              isLoading={isLoading}
              filterTabvalue={filterTabvalue}
              taxValue={taxValue}
              profitTableData={profitTableData}
              profitGraphData={profitGraphData}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ProductTabV2
              data={getProductData}
              isLoading={isLoading}
              searchText={searchText}
              taxValue={taxValue}
              adsValue={adsValue}
              fullfillmentType={fullfillmentType}
              activeTab={`${tabValue}`}
              setColumn={(col) => {
                setColumns(col);
              }}
              pageCount={totalRecords}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getProfit(
                  salesChannel,
                  startDate,
                  endDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
              profitTotal={profitTotal}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <OrderTabV2
              data={getOrderData}
              activeTab={`${tabValue}`}
              taxValue={taxValue}
              adsValue={adsValue}
              setColumn={(col) => {
                setColumns(col);
              }}
              isLoading={isLoading}
              searchText={searchText}
              pageCount={totalRecords}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getProfit(
                  salesChannel,
                  startDate,
                  endDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
              profitTotal={profitTotal}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <ChannelTabV2
              data={getChannelData}
              isLoading={isLoading}
              searchText={searchText}
              taxValue={taxValue}
              adsValue={adsValue}
              activeTab={`${tabValue}`}
              setColumn={(col) => {
                setColumns(col);
              }}
              pageCount={totalRecords}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getProfit(
                  salesChannel,
                  startDate,
                  endDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
              profitTotal={profitTotal}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <InputProductTabV2
              data={getInputProductData}
              isLoading={isLoading}
              searchText={searchText}
              activeTab={`${tabValue}`}
              setColumn={(col) => {
                setColumns(col);
              }}
              taxStartDate={taxStartDate}
              setInputProductData={setInputProductData}
              pageCount={totalRecords}
              tableFilterData={(paginationCondition, sortCond, filterCond) => {
                getProfit(
                  salesChannel,
                  startDate,
                  endDate,
                  searchText,
                  paginationCondition,
                  sortCond,
                  filterCond
                );
              }}
              reloadApi={(data) => {
                let editData = getInputProductData.map((filterData) => {
                  if (
                    data.sku === filterData.sku &&
                    data.channel === filterData.channel
                  ) {
                    filterData.costPerUnit = data.costPerUnit;
                  }

                  return filterData;
                });
                setInputProductData(editData);
              }}
            />
          </TabPanel>
        </div>
      </div>
    </>
  );
}
