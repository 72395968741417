import { MailOutlined } from "@ant-design/icons";
import { Container } from "@material-ui/core";
import { message, notification, Spin } from "antd";
import React from "react";
import CustomSectionHeader from "../components/CustomSectionHeader.js";
import ApiWrapper from "../utils/ApiWrapper";

const args = {
  message: "",
  duration: 3,
};

class invoiceTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      InvoiceVal: [],
      loading: "",
    };
  }

  componentDidMount() {
    this.InvoiceDataApi();
  }
  componentWillUnmount() {
    message.destroy();
  }

  InvoiceDataApi = async () => {
    this.setState({
      loading: true,
    });
    try {
      ApiWrapper("planDetails", {}).then(
        (response) => {
          this.setState({
            InvoiceVal: response,
            loading: false,
          });
        },
        (error) => {}
      );
    } catch (error) {
      console.error(error);
    }
  };

  recepitDownload(invoiceId) {
    let requestBody = {
      invoice_id: invoiceId,
    };
    try {
      ApiWrapper("invoiceDownload", requestBody).then((response) => {
        if (response.message === "Success") {
          args["message"] = "Invoice sent to your e-mail";
          args["className"] = "notification-success";
          notification.open(args);
        } else {
          args["message"] = "Mail sent failed";
          args["className"] = "notification-error";
          notification.open(args);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    return (
      <div style={{ fontFamily: "inter" }}>
        <Container>
          {this.state.loading ? (
            <Spin
              size="large"
              tip="Loading..."
              style={{
                marginLeft: "50%",
              }}
            ></Spin>
          ) : null}
          {!this.state.loading ? (
            <>
              <div style={{ width: "max-content" }}>
                <CustomSectionHeader title="Invoice History " subtitle="" />
                <h6
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <b>{this.state?.InvoiceVal?.invoices?.length || "No"}</b>{" "}
                  Invoice raised
                </h6>
              </div>
              <div
                className="ag-theme-alpine"
                style={{ height: 250, width: 800 }}
              >
                <table
                  style={{
                    width: "616px",
                  }}
                  className="invoice_table"
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Invoice Number</th>
                      <th>Amount</th>
                      <th>Receipt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state?.InvoiceVal?.invoices?.map((invoice, index) => {
                      return (
                        <tr key={index}>
                          <td>{invoice.invoice_date}</td>
                          <td>{invoice.number}</td>
                          <td>{invoice.total}</td>
                          <td>
                            <MailOutlined
                              onClick={() =>
                                this.recepitDownload(invoice.invoice_id)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default invoiceTab;
