import React from 'react';
import {makeStyles, Select, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CustomSelect = withStyles(() => ({
	root: {
		fontWeight: 500,
		fontSize: 14,
		display: "flex",
		alignItems: "center",
	},
	icon: {
		color: "#061938",
		marginRight: 10,
		marginTop: 4,
		fontSize: 16,
	}
}))((props) => <Select {...props}/>)

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	label: {
		fontWeight: 500,
		fontSize: 14,
		color: '#44444F',
		marginBottom: 12,
	},
	disabled: {
		backgroundColor: '#F4F5F7',
	},
	input: {
		fontWeight: 500,
		fontSize: 14,
	},
	error: {
		minHeight: 28,
		color: "#F2453D"
	}
});

const MaterialSelect = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			{props.label && (
				<div className={classes.label}>{props.label}</div>
			)}
			<CustomSelect
				fullWidth={props.fullWidth}
				disabled={props.disabled}
				value={props.value}
				placeholder={props.placeholder}
				onChange={props.onChange}
				variant="outlined"
				IconComponent={ExpandMoreIcon}
			>
				{props.children}
			</CustomSelect>
			<div className={props.showError ? classes.error : {}}>
				{props.errorMessage}
			</div>
		</div>
	);
}

MaterialSelect.defaultProps = {
	label: null,
	value: "",
	disabled: false,
	fullWidth: false,
	onChange: (e) => {},
	errorMessage: "",
	placeholder: "",
	showError: true,
}

MaterialSelect.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	onChange: PropTypes.func,
	errorMessage: PropTypes.string,
	placeholder: PropTypes.string,
	showError: PropTypes.bool,
}

export default MaterialSelect;
