import { Button, message } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import CustomFormFooter from "../../components/CustomFormFooter";
import CustomFormHeader from "../../components/CustomFormHeader";
import CustomTextField from "../../components/CustomTextField";
import ApiWrapper from "../../utils/ApiWrapper";
import AuthLayout from "./authLayout";

const loginvalidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Required"),
});
function ForgotPassword(props) {
  const handleForgotPasswordClick = async (values, actions, e) => {
    ApiWrapper("forgotPasswordSelf", values).then(
      (response) => {
        if (response.status) {
          console.log(response);
          // localStorage.setItem("pwdToken", response.fs_auth_token);
          props.history.replace(
            `/change-password?action=${response.otp_action}&medium=${response.otp_medium}&token=${response.fs_auth_token}`
          );
        } else {
          if (response.message_code === "E101") {
            message.error("Email ID does not exist");
            actions.setErrors({ email: "Email ID does not exist" });
          }
        }
        actions.setSubmitting(false);
      },
      function (error) {
        // message.error(
        //   "We encountered an issue. Please log out and log back in. "
        // );
      }
    );
  };
  return (
    <AuthLayout page="Forgot Password">
      <CustomFormHeader
        title="Forgot Password"
        subtitle="Fill up all the fields given below to reset password"
      />
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values, actions) => {
          handleForgotPasswordClick(values, actions);
        }}
        validationSchema={loginvalidationSchema}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <div style={{ marginTop: 36 }}>
              <CustomTextField
                title="Registered Email Id"
                name="email"
                type="email"
              />
            </div>
            <div className="mt-3">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="uppercase w-full shadow-lg hover:shadow-xl rounded font-semibold"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Send Request
              </Button>
            </div>
            <div className="mt-8">
              <CustomFormFooter
                linkHelpText="Don't have an account?"
                linkText="Register"
                clickAction={() => {
                  props.history.push("/register");
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
}
export default withRouter(ForgotPassword);
