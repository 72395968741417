import {
  CheckOutlined,
  CloseOutlined,
  CreditCardOutlined,
  LoginOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Input,
  message,
  Radio,
  Spin,
  Switch,
  Tabs,
  Tag,
  Tooltip,
  Modal,
  Select,
  Checkbox
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../../App.css";
import ExportTableButton from "../../components/AntTable/ExportTable";
import PageHeader from "../../components/PageHeader";
import ApiWrapper from "../../utils/ApiWrapper";
import ListView from "./adminListView";
import MaterialIcon from "react-google-material-icons";
const CheckboxGroup = Checkbox.Group;

const { Option } = Select;

const { TabPane } = Tabs;
const plainOptions = [
  { label: 'DASHBOARD', value: 'DASHBOARD_READ' },
  { label: 'REVIEWS', value: 'REVIEWS_READ' },
  { label: 'PAYMENT TRACKER', value: 'PAYMENTTRACKER_READ' },
  { label: 'SALES DEFENCE', value: 'SALESDEFENCE_READ' },
  { label: 'INVENTORY TRACKER', value: 'INVENTORYTRACKER_READ' },
  { label: 'MASTERSKU MAPPING', value: 'MASTERSKUMAPPING_READ' },
  { label: 'STORAGE LOCATIONS', value: 'STORAGELOCATIONS_READ' },
  { label: 'ORDERS TRACKER', value: 'ORDERSTRACKER_READ' },
  { label: 'PRODUCT CONFIG', value: 'PRODUCTCONFIG_READ' },
  // { label: 'ACCOUNT SETTINGS', value: 'ACCOUNTSETTINGS_READ' },
  { label: 'RETURNS TRACKER', value: 'RETURNSTRACKER_READ' },
  { label: 'DOWNLOADS', value: 'DOWNLOADS_READ' },
  { label: 'PROFITABILITY', value: 'PROFITABILITY_READ'}
];
// const defaultCheckedList = ["DASHBOARD_READ", "REVIEWS_READ", "PAYMENTTRACKER_READ", "DOWNLOADS_READ", "RETURNSTRACKER_READ", "ACCOUNTSETTINGS_READ", "SALESDEFENCE_READ", "PRODUCTCONFIG_READ", "ORDERSTRACKER_READ", "INVENTORYTRACKER_READ", "MASTERSKUMAPPING_READ", "STORAGELOCATIONS_READ"];

function CustomerDetails({ history }) {
  const isAgencyDashboard = JSON.parse(localStorage.getItem("isAgencyDashboard"));

  const RefColumns = [
    {
      title: "OrgId",
      align: "left",
      key: "org_id",
      hidden: "true",
    },
    {
      title: "Company",
      align: "left",
      key: "org_name",
      hidden: "true",
    },

    {
      title: "Email",
      align: "left",
      key: "master_email",
      hidden: "true",
    },
    {
      title: "Created Date",
      align: "left",
      key: "created_at",
      hidden: "true",
    },
    {
      title: "Expiry Date",
      align: "left",
      key: "SubscriptionEndDate",
      hidden: "true",
    },
  ];

  const [customerList, setCustomerList] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState([]);
  const [inactiveCustomer, setinActiveCustomer] = useState([]);
  const [sizebutton, setsize] = useState("active");
  const [columns, setColumns] = React.useState(RefColumns);
  const [expitySoon, setexpitySoon] = useState([]);
  const [type, setType] = useState("grid");

  const GetCustomerData = async (value) => {
    let OrgAccess = localStorage.getItem("org_access");
    setLoading(true);
    ApiWrapper("getCustomerDetails").then(function (response) {
      setLoading(false);
      if (OrgAccess !== "undefined") {
        var accessGranted = response.Items.filter((val) =>
          OrgAccess.includes(val.org_id)
        );
        var activeCustomer = accessGranted.filter(
          (name) => !name.isSyncDisabled
        );
        setActiveCustomer(activeCustomer);
        var inactiveCustomer = accessGranted.filter(
          (name) => name.isSyncDisabled
        );
        setinActiveCustomer(inactiveCustomer);
      } else {
        var activeCustomer = response.Items.filter(
          (name) => !name.isSyncDisabled
        );
        setActiveCustomer(activeCustomer);
        var inactiveCustomer = response.Items.filter(
          (name) => name.isSyncDisabled
        );
        setinActiveCustomer(inactiveCustomer);
      }

      var start = moment(new Date()).format("YYYY-MM-DD");
      var result = new Date();
      let expiryDate = result.setDate(result.getDate() + 7);
      var remaindays = moment(expiryDate).format("YYYY-MM-DD");
      let events = activeCustomer.filter((a) => {
        var end = moment(a.SubscriptionEndDate).format("YYYY-MM-DD");
        return end >= start && end <= remaindays;
      });
      setexpitySoon(events);
      setCustomerList([...activeCustomer]);
      setFilteredCustomerList([...activeCustomer]);
    });
  };

  const tabsChange = (activeKey) => {
    if (activeKey == 1) {
      setCustomerList([...activeCustomer]);
      setFilteredCustomerList([...activeCustomer]);
    }
    if (activeKey == 2) {
      setCustomerList([...inactiveCustomer]);
      setFilteredCustomerList([...inactiveCustomer]);
    }
    if (activeKey == 3) {
      setCustomerList([...expitySoon]);
      setFilteredCustomerList([...expitySoon]);
    }
  };
  const onEnterOrganisation = async (selectedOrg) => {
    // console.log(selectedOrg);
    let requestJsonBody = {
      org_id: selectedOrg.org_id,
      // customer_type : action,
    };
    setLoading(true);
    ApiWrapper("enterOrganisation", requestJsonBody).then(function (response) {
      let admin_token = localStorage.getItem("token");
      let admin_refresh_token = localStorage.getItem("refresh_token");
      let user_info = localStorage.getItem("user_info");
      let cmpy_info = localStorage.getItem("cmpy_info");
      localStorage.setItem("token", response.fs_auth_token);
      localStorage.setItem("refresh_token", response.fs_refresh_token);
      localStorage.setItem("fs_token", admin_token);
      localStorage.setItem("fs_refresh_token", admin_refresh_token);

      localStorage.setItem("user_info", JSON.stringify(response.user_info));
      localStorage.setItem(
        "cmpy_info",
        JSON.stringify(response.organisation_info)
      );
      localStorage.setItem("fs_user_info", user_info);
      localStorage.setItem("fs_cmpy_info", cmpy_info);
      // history.push("/dashboard");
      window.open("/dashboard", "_blank");
      setLoading(false);
    });
  };
  React.useEffect(() => {
    GetCustomerData();
  }, []);

  const sortCustomerList = (fieldName, sortOrder, tabName) => {
    setsize(tabName);
    let sortResult = [...filteredCustomerList];
    sortResult.sort((firstEle, secondEle) => {
      if (!firstEle[fieldName]) firstEle[fieldName] = "";
      if (!secondEle[fieldName]) secondEle[fieldName] = "";

      if (firstEle[fieldName] > secondEle[fieldName]) {
        return sortOrder === "Asc" ? 1 : -1;
      } else if (firstEle[fieldName] < secondEle[fieldName]) {
        return sortOrder === "Desc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredCustomerList(sortResult);
  };


  return (
    <Spin spinning={loading} size="large">
      <div>
        <div
          className="flex justify-between items-center pt-3 pb-3 sticky top-0 z-10 text-sm font-Inter ml-2 mr-2 bg-white font-medium text-DimGray "
          style={{
            boxShadow: `0px 0px 2px rgba(23, 43, 77, 0.04), 0px 3px 2px rgba(23, 43, 77, 0.08)`,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div
            onClick={() => {
              history.push("/admin-dashboard");
            }}
            className="cursor-pointer"
          >
            <div className="fs-logo-large" />
          </div>
          <div className="flex items-center">
            {!isAgencyDashboard && (
              <div
                onClick={() => {
                  history.push("/sync-details");
                }}
                className="pl-3 cursor-pointer"
              >
                Manual Sync
              </div>
            )}

            <div
              onClick={() => {
                localStorage.clear();
                history.push("/login");
              }}
              className="pl-4 cursor-pointer"
            >
              Logout
            </div>
          </div>
        </div>
        <div className="px-20 tablet:px-1 pt-12 tablet:pt-1">
          <PageHeader title="Admin Dashboard" />

          <div className="pt-2 flex justify-between mb-4 items-center mobile:flex-wrap">
            <div className="flex">
              <Input
                className="w-60 mobile:w-32 pr-2 h-9"
                placeholder="Search by name,email,city,phone."
                onChange={(evt) => {
                  var searchTerm = String(evt.target.value).toLowerCase();
                  var searchResults = [];
                  customerList.map((customer) => {
                    if (
                      !searchTerm ||
                      String(customer.org_name)
                        .toLowerCase()
                        .includes(searchTerm) ||
                      String(customer.master_email)
                        .toLowerCase()
                        .includes(searchTerm) ||
                      String(customer.city)
                        .toLowerCase()
                        .includes(searchTerm) ||
                      String(customer.state)
                        .toLowerCase()
                        .includes(searchTerm) ||
                      String(customer.phone_number)
                        .toLowerCase()
                        .includes(searchTerm)
                    ) {
                      searchResults.push(customer);
                    }
                  });
                  setFilteredCustomerList(searchResults);
                }}
              />
            </div>

            <div className="flex items-center">
              Sort by &nbsp;&nbsp;
              <Radio.Group
                className="flex items-center h-11"
                value={sizebutton}
              >
                <Radio.Button
                  value="newest"
                  onClick={() => {
                    sortCustomerList("created_at", "Desc", "newest");
                  }}
                >
                  Newest first
                </Radio.Button>
                <Radio.Button
                  value="oldest"
                  onClick={() => {
                    sortCustomerList("created_at", "Asc", "oldest");
                  }}
                >
                  Oldest first
                </Radio.Button>
              </Radio.Group>
            </div>

            <ExportTableButton
              className="h-11"
              columns={columns}
              fileName="Customer_Details"
              dataSource={customerList}
            />
          </div>
          <div className="relative">
            <div className="absolute right-0 z-1">
              <span
                className="text-4xl mr-5 cursor-pointer border-gray-500 border rounded px-4 bg-gray-100 hover:bg-white text-gray-600 hover:text-blue-600"
                onClick={() => {
                  setType("grid");
                }}
              >
                {/* <CreditCardOutlined className="text-2xl mr-5 cursor-pointer" /> */}
                <MaterialIcon className="text-2xl" icon="grid_view" />
              </span>
              <span
                className="text-4xl ml-5 tablet:ml-1 cursor-pointer border-gray-500 border rounded px-4 bg-gray-100 hover:bg-white text-gray-600 hover:text-blue-600"
                onClick={() => {
                  setType("list");
                }}
              >
                {/* <UnorderedListOutlined className="text-2xl cursor-pointer" /> */}
                <MaterialIcon
                  className="text-2xl"
                  icon="format_list_bulleted"
                />
              </span>
            </div>
          </div>
          <Tabs
            className="pt-0 mobile:pt-10"
            defaultActiveKey="1"
            onChange={tabsChange}
          >
            <TabPane key="1" tab={`Active (${activeCustomer.length || 0})`}>
              <GridListContainer
                data={filteredCustomerList}
                type={type}
                onEnterOrganisation={onEnterOrganisation}
                setLoading={setLoading}
                isAgencyDashboard={isAgencyDashboard}
              />
            </TabPane>
            <TabPane key="2" tab={`Inactive (${inactiveCustomer.length || 0})`}>
              <GridListContainer
                data={filteredCustomerList}
                type={type}
                onEnterOrganisation={onEnterOrganisation}
                setLoading={setLoading}
                isAgencyDashboard={isAgencyDashboard}
              />
            </TabPane>
            <TabPane key="3" tab={`ExpirySoon (${expitySoon.length || 0})`}>
              <GridListContainer
                data={filteredCustomerList}
                type={type}
                onEnterOrganisation={onEnterOrganisation}
                setLoading={setLoading}
                isAgencyDashboard={isAgencyDashboard}
              />
            </TabPane>
          </Tabs>
        </div>
        {/* {filteredCustomerList.length == 0 && <Empty />} */}
      </div>
    </Spin>
  );
}
const GridListContainer = ({ data, type, onEnterOrganisation, setLoading, isAgencyDashboard }) => {
  return (
    <>
      {data.length == 0 && type === "grid" && <Empty />}
      {type === "grid" && (
        <div className="grid  grid-cols-3 tablet:grid-cols-1 gap-10 flex-row  mt-5">
          {data.map((customer, index) => {
            return (
              <CustomerInfo
                customer={customer}
                index={index}
                onEnterOrganisation={onEnterOrganisation}
                setLoading={setLoading}
                isAgencyDashboard={isAgencyDashboard}
              />
            );
          })}
        </div>
      )}
      {type === "list" && <ListView customerList={data} />}
    </>
  );
};
const RoleUpdateModel = ({ customer }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  // const [userRoles, setUserRoles] = useState([]);
  // const [selectedRoles, setSelectedRoles] = useState("");
  // console.log(customer?.permissions)
  const [checkedList, setCheckedList] = React.useState(customer?.permissions);

  const showModal = () => {
    // ApiWrapper("getUserRoles").then(function (response) {
    //   setUserRoles(response.Items);
    // });
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let requestbody = {
      org_id: customer.org_id,
      permissions: checkedList,
    };
    console.log(customer, requestbody)
    setConfirmLoading(true);
    ApiWrapper("updatePermission",requestbody).then(function (response) {
      if (response.status) {
        setIsModalVisible(false);
        setConfirmLoading(false);
        message.success("User Role Updated Successfully");
      } else {
        setIsModalVisible(false);
        setConfirmLoading(false);
        message.error("Something Went Wrong.Please try again...");
      }
    });
  };

  const handleCancel = () => {
    // setSelectedRoles("");
    setIsModalVisible(false);
  };
  const onChangeRole = list => {
    setCheckedList(list);
  };
  return (
    <>
      <Button
        type="primary"
        className="hover:border-gray-500 rounded-lg px-2 mx-2 text-sm font-sm "
        onClick={showModal}
      >
        Role Updation
      </Button>
      <Modal
        title="UserRole Updation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            // disabled={!selectedRoles}
            type="primary"
            onClick={handleOk}
            loading={confirmLoading}
          >
            Update
          </Button>,
        ]}
      >
        {/* <Select
          style={{ width: 300 }}
          onChange={(value) => {
            setSelectedRoles(value);
          }}
        >
          {userRoles.map((val) => {
            return <Option value={val.role}>{val.role}</Option>;
          })}
        </Select> */}
        <div>
          <label>
            <b>
            Email
            </b>
          </label>
          <br />
          <div style={{
            fontSize:17
          }}>
          {customer.master_email}
          </div>
        </div>
        <div>
        <label>
            <b>
            Name
            </b>
          </label>
          <br />
          <div style={{
            fontSize:17
          }}>
          {customer.org_name}
          </div>
        </div>
        <Divider />
        <div>
          <label>
            <b>
              User Permission
            </b>
          </label>
          <CheckboxGroup style={{
            display: 'block'
          }} options={plainOptions} defaultValue={checkedList} onChange={onChangeRole} />
        </div>
      </Modal>
    </>
  );
};
const CustomerInfo = ({ customer, onEnterOrganisation, index, setLoading, isAgencyDashboard }) => {
  const [switchLoading, setSwitchLoading] = useState(false);
  const [isSyncEnabled, setIsSyncEnabled] = useState(
    customer.isOutwardInventorySync
  );
  const [isBuyboxEditEnabled, setBuyboxEditEnabled] = useState(
    customer.isOutwardBuyBoxSync
  );
  const [startDate, setStartDate] = useState("");
  const [expiryEndDate, setExpiryEndDate] = useState("");
  const [statusLoader, setStatusLoader] = useState(false);
  const [customerSyncStatus, setCustomerSyncStatus] = useState(false);
  const [couponEnabled, setCouponEnabled] = useState(false);
  const [planEnabled, setPlanEnabled] = useState(false);

  return (
    <div
      className="bg-white rounded-lg overflow-hidden shadow-2xl p-4 border border-Platinum border-opacity-25  "
      key={`Customer-${index}`}
    >
      <h2 className="text-gray-700 font-semibold text-2xl tracking-wide mb-5 break-words flex justify-between tablet:flex-wrap">
        <div className="flex items-center justify-items-center w-8/12 tablet:flex-wrap">
          {customer?.org_name && (
            <Tooltip title={customer?.org_name}>
              <span>{customer?.org_name.replace(/(.{13})..+/, "$1…")}</span>
            </Tooltip>
          )}
          <span className="flex items-center">
            {customer?.org_verified && (
              <Tag className="ml-2 mr-1 text-xs" color="green">
                {customer?.org_verified === "success" ? "Verified" : ""}
              </Tag>
            )}
          </span>
        </div>
        {/* <LoginOutlined
          className="ml-10"
          onClick={() => {
            onEnterOrganisation(customer);
          }}
        /> */}
        <button
          className="outline-none flex items-center text-gray-600 hover:text-blue-600 px-4 bg-gray-100 hover:bg-white border-gray-500 border rounded shadow-md"
          onClick={() => {
            onEnterOrganisation(customer);
          }}
        >
          {" "}
          <MaterialIcon icon="login" />
        </button>
      </h2>
      <div className="py-2 px-2 ">
        <div className="flex flex-col ">
          <div className="flex flex-col text-sm  text-gray-800 ">
            <span className="mb-1">Org ID: {customer?.org_id}</span>
            <span className="mb-1">Account: {customer?.org_name}</span>
            <span className="mb-1">Email Id: {customer?.master_email}</span>
            <span className="mb-1">Phone: {customer.phone_number}</span>
            <span className="mb-1">State: {customer.state}</span>
            <span className="mb-1">
              Created Date: {moment(customer.created_at).format("DD MMM YYYY")}
            </span>
            <span className="mb-1 text-blue-500">
              {" "}
              <b>
                Expiry Date:{" "}
                {moment(customer.SubscriptionEndDate).format("DD MMM YYYY")}{" "}
              </b>{" "}
            </span>
            {!isAgencyDashboard && <div>
              <span className="flex justify-between items-center mb-1">
                Update Expiry:
                <DatePicker
                  format={"DD MMM YYYY"}
                  onChange={(date, dateString) => {
                    var updateDate = moment(date).format("YYYY-MM-DD");
                    setExpiryEndDate(updateDate);
                  }}
                />
                <Button
                  className="hover:border-gray-500 rounded-lg px-2 mx-2 text-sm font-sm "
                  type="primary"
                  onClick={() => {
                    setLoading(true);
                    ApiWrapper("subscriptionEndDate", {
                      orgId: customer.org_id,
                      SubscriptionEndDate: expiryEndDate,
                    }).then(function (response) {
                      if (!response.status) {
                        message.error(" subscriptionEndDate Failed");
                      } else {
                        message.success("subscriptionEndDate Updated");
                      }
                      setLoading(false);
                    });
                  }}
                >
                  Update
                </Button>
              </span>
              <h6 className="flex gap-4 items-center mb-1 pt-2">
                <span>
                  Status:
                  <span>
                    {" "}
                    <Switch
                      checkedChildren="ACTIVE"
                      unCheckedChildren="INACTIVE"
                      checked={!customer.isSyncDisabled}
                      onChange={() => {
                        setStatusLoader(true);
                        ApiWrapper("customerSyncStatus", {
                          orgId: customer.org_id,
                        }).then(function (response) {
                          if (!response.status) {
                            message.error(response.message);
                          } else {
                            setCustomerSyncStatus(response.status);
                            message.success(
                              "Status will be updated from next Sync"
                            );
                          }
                          setStatusLoader(false);
                        });
                      }}
                      loading={statusLoader}
                      disabled={customerSyncStatus}
                    />
                  </span>
                </span>
                <Divider type="vertical" />
                <span>
                  Coupon:
                  <span>
                    {" "}
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={customer.Coupon_enable}
                      onChange={(checked) => {
                        ApiWrapper("getCouponData", {
                          orgId: customer.org_id,
                          code: "Coupon",
                          isToggle: checked,
                        }).then(function (response) {
                          setCouponEnabled(response.status);
                        });
                      }}
                      disabled={couponEnabled}
                    />
                  </span>
                </span>
              </h6>
            </div>}
          </div>
        </div>
      </div>
      {!isAgencyDashboard && <div className=" border-l-0	border-r-0	border-b-0	 border border-gray border-opacity-25 px-4 -mx-4 	">
        <div className="flex flex-col  mt-4">
          <h5 className="flex gap-4 items-center text-base font-medium mb-4 ">
            <span>
              Edit Inventory
              <span>
                {" "}
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={isSyncEnabled}
                  onChange={() => {
                    setSwitchLoading(true);
                    ApiWrapper("ToggleOutwardSync", {
                      orgId: customer.org_id,
                    }).then(function (response) {
                      setIsSyncEnabled(response.isOutwardInventorySync);
                      setSwitchLoading(false);
                    });
                  }}
                  loading={switchLoading}
                />
              </span>
            </span>
            <Divider type="vertical" />
            <span>
              Plan:
              <span>
                {" "}
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={customer.is_plan_enable}
                  onChange={(checked) => {
                    ApiWrapper("getCouponData", {
                      orgId: customer.org_id,
                      code: "Monthly",
                      isToggle: checked,
                    }).then(function (response) {
                      setPlanEnabled(response.status);
                      message.success("Success");
                    });
                  }}
                  disabled={planEnabled}
                />
              </span>
            </span>
          </h5>
          <h5 className="flex gap-4 items-center text-base font-medium mb-4 ">
            <span>
              Edit BuyBox
              <span>
                {" "}
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={isBuyboxEditEnabled}
                  onChange={(checked) => {
                    setBuyboxEditEnabled(checked);
                    // setSwitchLoading(true);
                    ApiWrapper("UpdateAdminDetails_v2", {
                      org_id: customer.org_id,
                      variable: "isOutwardBuyBoxSync",
                      value: checked,
                    }).then(function (response) {
                      // setSwitchLoading(false);
                    });
                  }}
                  loading={switchLoading}
                />
              </span>
            </span>
            <Divider type="vertical" />
            <span>
              <RoleUpdateModel customer={customer} />
            </span>
          </h5>
          <div className="flex flex-col text-sm text-gray-500">
            <span className="flex justify-between mb-1 ">
              <DatePicker
                onChange={(date) => {
                  setStartDate(date);
                }}
                disabledDate={(date) => {
                  return date < moment().subtract(1, "month");
                }}
              />{" "}
              <Button
                className="hover:border-gray-500 rounded-lg px-2 mx-2 text-sm font-sm "
                type="primary"
                onClick={() => {
                  setLoading(true);
                  ApiWrapper("AdminManualSync", {
                    orgId: customer.org_id,
                    module: "ALL",
                    ...(startDate && { startDate: startDate.utc().format() }),
                  }).then(function (response) {
                    if (!response.status) {
                      message.error("Manual sync failed");
                    } else {
                      message.success("Manual sync completed");
                    }
                    setLoading(false);
                  });
                }}
              >
                Manual Sync
              </Button>
            </span>
          </div>
        </div>
      </div>}
    </div>
  );
};
export default withRouter(CustomerDetails);
