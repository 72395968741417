import {
  Form,
  Input,
  Button,
  Space,
  Modal,
  Tooltip,
  message,
  Select,
} from "antd";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { PostValues } from "../../utils/api-client";
const { Option } = Select;
const postUrlAPi = async (requestData) => {
  let response = await PostValues("CreateNewUrlApi", requestData);
  return response;
};

const channels = [
  { label: "Amazon-India", value: "amazon-India" },
  { label: "Flipkart", value: "flipkart" },
  { label: "General", value: "general" },
];

const NewLinkModel = (props) => {
  const [newUrlLink, setNewUrlLink] = React.useState(null);
  const [selectedChannel, setSelectedChannel] = React.useState();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    // console.log(values)
    let finalUrlArray = [];
    values.urls.map((val) => {
      let modifiedUrl = {
        channel: val.channel,
        url:
          val.channel === "amazon-India"
            ? `https://www.amazon.in/dp/${
                val.productId
              }/keywords=${val.keywords?.replace(/ /g, "+")}`
            : val.channel === "flipkart"
            ? `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${
                val.productId
              }&q=${val.keywords?.replace(/ /g, "%20")}`
            : val.keywords,
        productId: val.productId,
        keywords: val.keywords,
      };
      finalUrlArray.push(modifiedUrl);
    });
    let requestData = {
      filters: {
        urls: finalUrlArray,
        method: "create",
        name: values.name,
      },
    };
    // console.log(requestData);
    let newUrl = await postUrlAPi(requestData);
    if (newUrl.status) {
      setNewUrlLink(newUrl.response);
    }
    props.setIsModalVisible(false);
    form.resetFields();
    success(newUrl.response);
  };
  const handleOk = () => {
    form.submit();
  };
  function handleCopyText(link) {
    const cb = navigator.clipboard;
    cb.writeText(link).then(() => message.success("Text copied"));
  }
  const handleChange = (value) => {
    setSelectedChannel(value);
    // form.setFieldsValue({ urls: [] });
  };
  const onValuesChange = (props) => {
    console.log(props)
}

  function success(link) {
    Modal.success({
      content: (
        <>
          <Input.Group compact>
            <Input
              style={{ width: "calc(100% - 100px)" }}
              defaultValue={link}
              readOnly
            />
            <Tooltip title="Copy Url">
              <Button
                type="text"
                shape="circle"
                icon={<CopyOutlined />}
                onClick={() => {
                  handleCopyText(link);
                }}
              ></Button>
            </Tooltip>
          </Input.Group>
        </>
      ),
      onOk() {
        props.getUrlData();
      },
    });
  }
  return (
    <Modal
      title="Generate New Url"
      visible={props.isModalVisible}
      onOk={handleOk}
      width={600}
      onCancel={() => {
        props.setIsModalVisible(false);
        form.resetFields();
      }}
      closable={true}
      footer={[
        <div className={"flex items-center justify-end mt-2"}>
          <button
            className={`ml-2 pl-3 pr-3 pt-1 pb-1 rounded bg-GhostWhite border-platinum border mb-3`}
            onClick={() => {
              props.setIsModalVisible(false);
              form.resetFields();
            }}
          >
            Cancel
          </button>
          <button
            className={`ml-3 pl-3 pr-3 pt-1 pb-1 rounded bg-primaryGreenStroke border-transparent border mb-3 text-white`}
            onClick={handleOk}
          >
            Generate
          </button>
        </div>,
      ]}
    >
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        autoComplete="off"
        initialValues={{
          urls: [],
          url: "",
        }}
      >
        <Form.Item
          name="name"
          // label="Area"
          rules={[{ required: true, message: "Missing Name" }]}
        >
          {/* <Select
            options={channels}
            placeholder="Select Channel Type"
            onChange={handleChange}
          /> */}
          <Input placeholder="Enter Name" />
        </Form.Item>
        <Form.List name="urls">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    name={[field.name, "channel"]}
                    // label="Area"
                    rules={[{ required: true, message: "Missing channel" }]}
                  >
                    <Select
                      options={channels}
                      placeholder="Select Channel Type"
                      style={{
                        width: 130,
                      }}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  {selectedChannel !== "general" && (
                    <Form.Item
                      {...field}
                      name={[field.name, "productId"]}
                      rules={[{ required: true, message: "Missing productId" }]}
                    >
                      <Input placeholder={`Enter Product`} />
                    </Form.Item>
                  )}
                  <Form.Item
                    {...field}
                    name={[field.name, "keywords"]}
                    rules={[{ required: true, message: "Missing url" }]}
                  >
                    <Input
                      placeholder={`${
                        selectedChannel !== "general"
                          ? "Enter KeyWords "
                          : "Enter Url"
                      }`}
                      style={{ width: "100%" }}
                      className="mt-3"
                    />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <div className=" flex flex-row-reverse	">
                      <DeleteOutlined onClick={() => remove(field.name)} />
                    </div>
                  ) : null}
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  className="w-full justify-center mt-3"
                >
                  {`${
                    selectedChannel !== "general" ? "Add KeyWords " : "Add Url"
                  }`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default NewLinkModel;
