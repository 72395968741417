import React from 'react';
import { Popover } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const PopOver = (props) => {
  const classes = useStyles();
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <React.Fragment>
      <div
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={(e) => setAnchorEl(null)}
      >
        {props.children}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        {props.title}
      </Popover>
    </React.Fragment>
    
  )
};

export default PopOver;
