import { Form, Input, Tag, Spin, message } from "antd";
import { compact, isEmpty, uniqBy } from "lodash";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Prompt } from "react-router-dom";
import "../../../App.css";
import AntTable from "../../../components/AntTableV2/index";
import CustomAppBar from "../../../components/CustomAppBar.js";
import CustomFilterHeader from "../../../components/CustomFilterHeader";
import ExportButton from "../../../components/Styles/ExportButton";
import StyledButton from "../../../components/Styles/StyledButton";
import ApiWrapper from "../../../utils/ApiWrapper";
import { Fulfilment_filter } from "../../../utils/data";
import BulkUpdateInventory from "./BulkUpdateInventory";
import PublishInventory from "./PublishInventory";
import { PostValues } from "../../../utils/api-client.js";

const mergeRows = (a, b) => {
  let result = { ...a };
  if (isEmpty(b)) {
    return result;
  } else {
    result.marketplace = "Both";
    if (b.marketplace === "Amazon-India") {
      result.amazon_price = b.price;
      result.amazon_sku = b.sku;
    } else {
      result.flipkart_price = b.price;
      result.flipkart_sku = b.sku;
    }
  }
  return result;
};

const getInventoryList = (list) => {
  let result = [];
  let merchantData = [];
  list.forEach((item) => {
    if (item.masterSKU !== undefined) {
      merchantData.push(item);
    } else {
      result.push(item);
    }
  });
  let mapped = uniqBy(merchantData, "masterSKU");
  mapped.forEach((item) => {
    let value = list.filter((filter) => filter.masterSKU === item.masterSKU);
    result.push(mergeRows(value[0], value[1] || {}));
  });
  return [...result];
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let disabled;

  if (record) {
    disabled =
      Boolean(record[restProps.form_name] === "") ||
      (restProps.form_name === "quantity" && record.isEdit === 0);
  } else {
    disabled = false;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={restProps.form_name}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

let allInventoryList = [];
const InventoryTracker = () => {
  const [blockNavigate, setBlockNavigate] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [edited, setEdited] = useState([]);
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [totalQuantity, setTotalQuantity] = React.useState(0);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editKey, setEditKey] = useState("");
  const [requestId, setRequestId] = useState(null);
  const [columns, setColumns] = useState([]);
  const [marketPlaces, setMarketPlaces] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [lastSyncAt, setLastSyncAt] = React.useState("");
  const [requestData, setRequestData] = React.useState({});
  const [isExportLoading, setIsExportLoading] = React.useState(false);

  React.useEffect(() => {
    if (blockNavigate) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [blockNavigate]);

  const [form] = Form.useForm();

  let initialValues = {
    amazonPrice: "",
    flipkartPrice: "",
    quantity: "",
  };

  const onEditRow = (record) => {
    setBlockNavigate(true);
    form.setFieldsValue({
      amazonPrice: record.amazonPrice || "-",
      flipkartPrice: record.flipkartPrice || "-",
      quantity: record.quantity,
    });
    setEditKey(record.key);
  };

  const onCancelRow = (record) => {
    form.setFieldsValue({
      amazonPrice: "",
      flipkartPrice: "",
      quantity: "",
    });
    setEditKey("");
  };

  const onSaveRow = async (record) => {
    try {
      const row = await form.validateFields();
      let stateCopy = [...tableData];
      setTableData(
        stateCopy.map((table) => {
          if (table.key === record.key) {
            return {
              ...table,
              amazonPrice: row.amazonPrice,
              flipkartPrice: row.flipkartPrice,
              quantity: row.quantity,
            };
          } else {
            return table;
          }
        })
      );
      let changedRow = {};
      if (record.marketplace === "Both") {
        changedRow = {
          channel: record.marketplace,
          sku: record.sku,
          masterSKU: record.masterSKU,
          quantity: row.quantity,
          "Flipkart-sku": record.flipkartSKU,
          "Amazon-India-sku": record.amazonSKU,
          "Flipkart-price": row.flipkartPrice,
          "Amazon-India-price": row.amazonPrice,
        };
      } else {
        changedRow = {
          channel: record.channel,
          sku: record.sku,
          masterSKU: record.masterSKU,
          quantity: row.quantity,
          price:
            record.channel === "Amazon-India"
              ? row.amazonPrice
              : row.flipkartPrice,
        };
      }

      setEdited([...edited, changedRow]);
      setEditKey("");
    } catch (err) {
      console.log(err);
    }
  };
  let arr = [];
  let editFlag = {};
  const prepareData = (orders) => {
    orders.forEach((row, index) => {
      arr.push({
        ...row,
        channelName: row.channel,
        key: `${row.channel}_${index}`,
        flipkartSKU: row.channel !== "Amazon-India" ? row.sku : "",
        amazonSKU: row.channel === "Amazon-India" ? row.sku : "",
        flipkartPrice: row.channel !== "Amazon-India" ? row.price : "",
        amazonPrice: row.channel === "Amazon-India" ? row.price : "",
        // requestId : response.requestId,
      });
    });
    return arr;
  };

  const getInventory = async (requestData) => {
    let response = await PostValues("getInventory_V3", requestData);
    return response;
  };
  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };

  const handleMarketplaceChange = async (
    selectedMarketplaces,
    fromDate,
    toDate
  ) => {
    setIsLoading(true);
    setMarketPlaces(selectedMarketplaces);

    var noOfMarketplaces = selectedMarketplaces.length;

    let requestData = {
      channels: selectedMarketplaces.map((mp) => mp.name),

      filters: {
        // status: "INACTIVE"
        status: { IN: ["ACTIVE", "INACTIVE", "Active", "Inactive"] }, // for both status
      },
      pagination: {
        pageNo: 0,
        pageSize: 25,
      },
    };
    const data = await getInventory(requestData);
    setRequestData(requestData);

    if (data.listings) {
      const inventoryData = await prepareData(data.listings);
      setTableData([...inventoryData]);
      setTotalRecords(data.pagination.count);
      setTotalQuantity(data.total.quantitySum);
      setLastSyncAt(data.lastSyncAt.inventoryLastSyncAt);
      setIsEdit(data.lastSyncAt.isOutwardInventorySync);
      setRequestId(data?.pendingRequests?.[0].requestId);
      setIsLoading(false);
    }

    if (!selectedMarketplaces.length) {
      setIsLoading(false);
      setTableData([]);
      setIsEdit(false);
      setRequestId(null);
    }
  };
  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
    extra,
  }) => {
    setIsLoading(true);

    let requestData = {
      channels: marketPlaces.map((mp) => mp.name),

      filters: {
        status: { IN: ["ACTIVE", "INACTIVE", "Active", "Inactive"] },
        ...filterCondition,
      },
      ...paginationCondition,
      ...sortCondition,
    };
    const data = await getInventory(requestData);
    setRequestData(requestData);

    if (data.listings) {
      const inventoryData = await prepareData(data.listings);
      setTableData([...inventoryData]);
      setTotalRecords(data.pagination.count);
      setTotalQuantity(data.total.quantitySum);
      setLastSyncAt(data.lastSyncAt.inventoryLastSyncAt);
      setIsEdit(data.lastSyncAt.isOutwardInventorySync);
      setRequestId(data?.pendingRequests?.requestId);
      setIsLoading(false);
    }
  };
  const ExportAllData = async () => {
    try {
      setIsExportLoading(true);
      let exportRequestData = {
        params: {
          channels: marketPlaces.map((mp) => mp.name),
          status: { IN: ["ACTIVE", "INACTIVE", "Active", "Inactive"] },
        },
        reportType: "listings",
      };
      const data = await getExportApi(exportRequestData);
      if (data.status) {
        setIsExportLoading(false);
        message.success(
          "The export is being processed, please check the Downloads page ",
          10
        );
      } else {
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };
  const onSearch = async (ds, searchText) => {
    let params = { ...requestData };
    if (params.filters) {
      params.filters.search = searchText.trim();
    } else {
      params.filters = {
        search: searchText.trim(),
      };
    }
    setIsLoading(true);
    const data = await getInventory(params);
    setIsLoading(false);
    if (data.listings) {
      setTotalRecords(data.pagination.count);
      setTotalQuantity(data.total.quantitySum);
      setRequestId(data?.pendingRequests?.[0].requestId);
      return await prepareData(data.listings);
    }
    return [];
  };
  const RefColumns = [
    // {
    //   title: "Sno",
    //   field: "channelName",
    //   width: 60,
    //   render: (record, rowIndex) => rowIndex,
    // },
    // {
    //   title: "Channel",
    //   align: "center",
    //   field: "channelName",
    //   render: (record, rowIndex, isLoading) =>
    //     record.channelName === "Amazon-India" ? (
    //       <div className="amazon-logo-small" />
    //     ) : record.channelName === "Flipkart" ? (
    //       <div className="flipkart-logo-small" />
    //     ) : null,
    //   width: 75,
    //   editable: false,
    // },

    {
      title: "Product ID",
      align: "center",
      field: "productId",
      defaultSortOrder: "descend",
      isFilterable: true,
      isSearchable: true,
      type: "string",
      // searchPriority: 6,
      editable: false,
      width: 160,
      render: (record, rowIndex, isLoading) =>
        record.productId !== undefined ? (
          <>
            {" "}
            <div
              className={`${
                record.channelName === "Amazon-India"
                  ? "amazon-logo-small"
                  : record.channelName === "Flipkart"
                  ? "flipkart-logo-small"
                  : null
              }`}
            />
            <div>{` ${record.productId}`}</div>
          </>
        ) : null,
      // render: (record, rowIndex, isLoading) => record.productId || "-",
      sorter: (a, b) => {
        a = (a.productId || "").toLowerCase();
        b = (b.productId || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Product",
      align: "center",
      field: "name",
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "string",
      ellipsis: true,

      render: (record, rowIndex, isLoading) => record?.name || "-",
      sorter: (a, b) => {
        a = (a.name || "").toLowerCase();
        b = (b.name || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],

      editable: false,
      width: 160,
    },
    {
      title: "Fulfilment",
      align: "center",
      field: "fulfillmentChannel",
      isFilterable: true,
      isSearchable: true,
      type: "string",
      width: 130,
      render: (record, rowIndex, isLoading) =>
        record?.fulfillmentChannel || "-",
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.fulfillmentChannel) {
            return true;
          }
        } else {
          let isFilter = false;
          value.map((fulfillmentChannel) => {
            if (
              fulfillmentChannel.toLowerCase() ===
              (record.fulfillmentChannel || "").toLowerCase()
            ) {
              isFilter = true;
            }
          });
          return isFilter;
        }
      },

      filters: Fulfilment_filter,

      editable: false,
    },
    {
      title: "Status",
      field: "status",
      width: 90,
      render: (record, rowIndex, isLoading) => (
        <Tag
          color={
            (record?.status || "").toLowerCase() === "active" ? "green" : "red"
          }
        >
          {record?.status}
        </Tag>
      ),
      onFilter: (value, record) => {
        if (value === "-") {
          if (!record.status) {
            return true;
          }
        } else {
          return (record.status || "").toLowerCase() === value;
        }
      },
      filters: [
        { text: "Active", value: ["ACTIVE", "Active"] },
        { text: "Inactive", value: ["INACTIVE", "Inactive"] },
      ],
    },
    {
      title: "Master SKU",
      align: "center",
      field: "masterSKU",
      type: "string",
      isFilterable: true,
      isSearchable: true,
      width: 120,
      render: (record, rowIndex, isLoading) => record.masterSKU || "-",
      sorter: (a, b) => {
        a = (a.masterSKU || "").toLowerCase();
        b = (b.masterSKU || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],

      editable: false,
    },
    {
      title: "Amazon SKU",
      align: "center",
      field: "sku",
      isFilterable: true,
      isSearchable: true,
      type: "string",
      isEditable: true,
      render: (record, rowIndex, isLoading) => record.amazonSKU || "-",
      sorter: (a, b) => {
        a = (a.amazonSKU || "").toLowerCase();
        b = (b.amazonSKU || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      editable: false,
    },
    {
      title: "Flipkart SKU",
      align: "center",
      field: "sku",
      isFilterable: true,
      isSearchable: true,
      type: "string",
      isEditable: true,
      render: (record, rowIndex, isLoading) => record.flipkartSKU || "-",
      sorter: (a, b) => {
        a = (a.flipkartSKU || "").toLowerCase();
        b = (b.flipkartSKU || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],

      editable: false,
    },

    {
      title: "Amazon Price",
      align: "center",
      field: "price",
      type: "number",
      form_name: "amazonPrice",
      isTotal: false,
      isFilterable: true,
      isSearchable: true,
      isEditable: true,
      render: (record, rowIndex, isLoading) => record.amazonPrice || "-",
      sorter: (a, b) => {
        return Number(a.amazonPrice || 0) - Number(b.amazonPrice || 0);
      },
      sortDirections: ["descend", "ascend", "descend"],

      editable: true,
    },
    {
      title: "Flipkart Price",
      align: "center",
      field: "price",
      type: "number",
      form_name: "flipkartPrice",
      isFilterable: true,
      isSearchable: true,
      isEditable: true,
      isTotal: false,
      render: (record, rowIndex, isLoading) => record.flipkartPrice || "-",
      sorter: (a, b) => {
        return Number(a.flipkartPrice || 0) - Number(b.flipkartPrice || 0);
      },
      sortDirections: ["descend", "ascend", "descend"],

      editable: true,
    },
    {
      title: "Qty",
      align: "center",
      field: "quantity",
      type: "number",
      form_name: "quantity",
      isFilterable: true,
      isSearchable: true,
      isEditable: true,
      isTotal: true,
      total: totalQuantity,
      width: 120,
      render: (record, rowIndex, isLoading) => record.quantity,
      sorter: (a, b) => {
        return Number(a.quantity || 0) - Number(b.quantity || 0);
      },
      sortDirections: ["descend", "ascend", "descend"],
      editable: true,
    },
    isEdit && {
      title: "",
      align: "center",
      field: "",
      render: (record, rowIndex, isLoading) =>
        editKey === record.key ? (
          <React.Fragment>
            <StyledButton
              variant="outlined"
              onClick={() => onSaveRow(record)}
              style={{ marginRight: 16 }}
            >
              Save
            </StyledButton>
            <ExportButton
              variant="outlined"
              disabled={record.isEdit === 0}
              onClick={() => onCancelRow(record)}
            >
              Cancel
            </ExportButton>
          </React.Fragment>
        ) : (
          <ExportButton
            variant="outlined"
            disabled={editKey}
            onClick={() => onEditRow(record)}
          >
            Edit
          </ExportButton>
        ),
    },
  ];

  const mergedColumns = compact(RefColumns).map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: editKey === record.key,
        form_name: col.form_name || "",
      }),
    };
  });

  React.useEffect(() => {
    setColumns(RefColumns);
  }, []);

  return (
    <>
      <Prompt
        when={blockNavigate}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <div>
        <CustomAppBar />
        <CustomFilterHeader
          headerTitle="Inventory Tracker"
          handleMarketplaceChange={handleMarketplaceChange}
          showDateRanges={false}
          showManualSync={false}
          // manualSyncModule={"INVENTORY"}
          isLoading={isDataLoading}
          rows={inventory}
          columns={columns}
          searchFunction={onSearch}
          exportFileName="inventoryTracker"
          showExportButton={false}
          searchHide={true}
          onSearchTextEntered={(value) => setSearchText(value)}
          onFilterUpdate={(data) => {
            setTableData(data);
          }}
          syncDate={lastSyncAt}
          publishButton={
            isEdit ? (
              <div className="flex items-center justify-end">
                <div className="mr-4">
                  <BulkUpdateInventory setRequestId={setRequestId} />
                </div>
                <PublishInventory
                  data={edited}
                  refreshList={() => handleMarketplaceChange(marketPlaces)}
                  setRequestId={setRequestId}
                  requestId={requestId}
                />
              </div>
            ) : null
          }
          customToggleProfit={
            <Spin spinning={isExportLoading}>
              <div className="ml-6">
                <ExportButton variant="outlined" onClick={ExportAllData}>
                  Export to CSV
                </ExportButton>
              </div>
            </Spin>
          }
          getExportData={ExportAllData}
        />
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <Form form={form} component={false} initialValues={initialValues}>
            <AntTable
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              stickyHeader={{
                offsetHeader: 65,
                // offsetScroll: 216,
              }}
              columns={compact(mergedColumns)}
              dataSource={tableData}
              // scroll={{ x: 1500 }}
              rowKey={(row, rowIndex) => rowIndex}
              isLoading={isLoading}
              isDataLoading={isDataLoading}
              hideSummary={!tableData.length}
              totalRecords={totalRecords}
              searchText={[searchText]}
              onDataChange={onTableChange}
            />
          </Form>
          {/*<MaterialTable*/}
          {/*  columns={compact(columns)}*/}
          {/*  dataSource={tableData}*/}
          {/*  stickyHeader={true}*/}
          {/*  rowKey={(row, rowIndex) => rowIndex}*/}
          {/*  isLoading={isLoading}*/}
          {/*  searchText={searchText}*/}
          {/*  pagination={{*/}
          {/*    // rowsPerPageOptions: [25, 50, 100],*/}
          {/*    // component: "div",*/}
          {/*    count: tableData.length*/}
          {/*    // rowsPerPage: rowsPerPage,*/}
          {/*    // page: page,*/}
          {/*    // onChangePage: handleChangePage,*/}
          {/*    // onChangeRowsPerPage: handleChangeRowsPerPage,*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
      </div>
    </>
  );
};

export default InventoryTracker;
