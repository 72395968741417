import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Divider, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../../App.css";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import Tabs from "../../components/Tabs";
import { PostValues } from "../../utils/api-client";
import ProductTabV2 from "./productTab";
import OrderTabV2 from "./orderTab";
import ChannelTabV2 from "./channelTab";
import InputProductTabV2 from "./inputProductCost";


const TabsList = [
    "Product Profitability",
    "Order Profitability",
    "Channel Profitability",
    "Input Product Cost",
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Profitability() {
    const [searchText, setSearchText] = useState("");
    const [tabValue, setTabValue] = React.useState(0);
    const [columns, setColumns] = useState([]);
    const [startDate, seStarttDate] = React.useState({});
    const [endDate, setEndDate] = React.useState({});
    const [salesChannel, setSalesChannel] = useState([]);
    const [toggledata, setToggleVal] = React.useState(true);
    const [unsettled, setUnsettled] = React.useState('settled');
    const [taxValue, setTaxValue] = React.useState('Without Tax');
    const [unsettledOrders, SetUnsettledOrders] = React.useState('settled');
    const [requestData, setRequestData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [getProductData, setProductData] = useState([]);
    const [getOrderData, setOrderData] = useState([]);
    const [getChannelData, setChannelData] = useState([]);
    const [getInputProductData, setInputProductData] = useState([]);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [profitTotal, setProfitTotal] = React.useState({});
    // const [searchBool, setSearchBool] = React.useState(true);

    localStorage.setItem("toggleEvent", JSON.stringify({ gst_toggle: toggledata }))
    localStorage.setItem("unsettled", JSON.stringify({ unsettledToggle: unsettled }))

    const handleManualSyncClick = () => { };

    const { Option } = Select;

    const getTabApi = async (requestData) => {
        let response = await PostValues("profitApiV2", requestData);
        return response;
    };
    const getInputProductApi = async (requestData) => {
        let response = await PostValues("profitInputProductCost", requestData);
        return response;
    };

    const onProfitToggleChange = () => {
        handleMarketplaceChange(salesChannel, startDate, endDate);
    };
    const ExportAllData = async () => {
        try {
            let exportRequestData = { ...requestData };
            const data = await getTabApi({
                ...exportRequestData,
                pagination: {
                    pageNo: 0,
                    pageSize: totalRecords,
                    count: totalRecords,
                },
            });
            setIsLoading(false);
            if (data) {
                if (tabValue === 0) {
                    return await data
                }
                if (tabValue === 1) {
                    return await data
                }
                if (tabValue === 2) {
                    return await data
                }
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    const handleMarketplaceChange = async (
        selectedMarketplaces,
        fromDate,
        toDate,
    ) => {
        seStarttDate(fromDate)
        setEndDate(toDate)
        setSalesChannel(selectedMarketplaces);
        if (selectedMarketplaces.length > 0) {
            getProfit(selectedMarketplaces, fromDate, toDate, searchText);
        }
        else {
            setProductData([])
            setOrderData([])
            setChannelData([])
        }
    };
    const getProfit = async (
        selectedMarketplaces,
        fromDate,
        toDate,
        searchText,
        paginationCond,
        sortCond,
        filterCond = {}
    ) => {
        setIsLoading(true);
        let dataOf = JSON.parse(localStorage.getItem("toggleEvent"));
        let unsettled = JSON.parse(localStorage.getItem("unsettled"));
        let requestData = {
            channels: selectedMarketplaces.map((mp) => mp.name),
            filters: {
                fromDate: fromDate,
                toDate: toDate,
                ...filterCond,
            },
            ...(paginationCond
                ? paginationCond
                : {
                    pagination: {
                        pageNo: 0,
                        pageSize: 25,
                    },
                }),
            ...(sortCond ? sortCond : {}),
        };
        if (searchText) {
            requestData.filters["search"] = searchText;
        }
        setRequestData(requestData);
        if (tabValue === 0) {
            // setSearchBool(true)
            requestData.filters['toggle'] = dataOf?.gst_toggle || false;
            requestData.filters['payment'] = unsettled?.unsettledToggle || 'settled'
            requestData.filters['profitTab'] = "product";
            let ProductData = await getTabApi(requestData);
            if (ProductData) {
                setProductData(ProductData)
                setTotalRecords(ProductData[0]?.Total_count);
                let total = {
                    Total_ads: Math.round(ProductData[0]?.Total_ads),
                    Total_cost_per: Math.round(ProductData[0]?.Total_cost_per),
                    Total_count: ProductData[0]?.Total_count,
                    Total_invoice: ProductData[0]?.Total_invoice,
                    Total_margin: ((ProductData[0]?.Total_profit / ProductData[0]?.Total_invoice) * 100),
                    Total_market: ProductData[0]?.Total_market,
                    Total_profit: ProductData[0]?.Total_profit,
                    Total_qty: ProductData[0]?.Total_qty,
                    Total_return: ProductData[0]?.Total_return,
                    Total_shipping: ProductData[0]?.Total_shipping,
                    Total_InGst: ProductData[0]?.Total_InGst,
                    Total_OUTGST: ProductData[0]?.Total_OUTGST,
                    Total_ACOTS: isFinite((ProductData[0]?.Total_ads / ProductData[0]?.Total_invoice) * 100) ? (ProductData[0]?.Total_ads / ProductData[0]?.Total_invoice) * 100 : 0 || 0,
                    Total_ROI: isFinite((ProductData[0]?.Total_profit / ProductData[0]?.Total_cost_per) * 100) ? (ProductData[0]?.Total_profit / ProductData[0]?.Total_cost_per) * 100 : 0 || 0,
                }
                setProfitTotal(total);
            }
        }
        if (tabValue === 1) {
            // setSearchBool(true)
            requestData.filters['toggle'] = dataOf?.gst_toggle || false;
            requestData.filters['payment'] = unsettled?.unsettledToggle || 'settled'
            requestData.filters['profitTab'] = "order";
            let OrderData = await getTabApi(requestData);
            if (OrderData) {
                setOrderData(OrderData)
                setTotalRecords(OrderData[0]?.Total_count);
                let total = {
                    Total_ads: Math.round(OrderData[0]?.Total_ads),
                    Total_cost_per: Math.round(OrderData[0]?.Total_cost_per),
                    Total_count: OrderData[0]?.Total_count,
                    Total_invoice: OrderData[0]?.Total_invoice,
                    Total_margin: ((OrderData[0]?.Total_profit / OrderData[0]?.Total_invoice) * 100),
                    Total_market: OrderData[0]?.Total_market,
                    Total_profit: OrderData[0]?.Total_profit,
                    Total_qty: OrderData[0]?.Total_qty,
                    Total_return: OrderData[0]?.Total_return,
                    Total_shipping: OrderData[0]?.Total_shipping,
                    Total_InGst: OrderData[0]?.Total_InGst,
                    Total_OUTGST: OrderData[0]?.Total_OUTGST,
                    Total_ACOTS: isFinite((OrderData[0]?.Total_ads / OrderData[0]?.Total_invoice) * 100) ? (OrderData[0]?.Total_ads / OrderData[0]?.Total_invoice) * 100 : 0 || 0,
                    Total_ROI: isFinite((OrderData[0]?.Total_profit / OrderData[0]?.Total_cost_per) * 100) ? (OrderData[0]?.Total_profit / OrderData[0]?.Total_cost_per) * 100 : 0 || 0,
                }
                setProfitTotal(total);
            }
        }
        if (tabValue === 2) {
            // setSearchBool(false)
            requestData.filters['toggle'] = dataOf?.gst_toggle || false;
            requestData.filters['payment'] = unsettled?.unsettledToggle || 'settled'
            requestData.filters['profitTab'] = "channel";
            let Channel = await getTabApi(requestData);
            if (Channel) {
                setChannelData(Channel)
                setTotalRecords(Channel[0]?.Total_count);
                let total = {
                    Total_ads: Math.round(Channel[0]?.Total_ads),
                    Total_cost_per: Math.round(Channel[0]?.Total_cost_per),
                    Total_count: Channel[0]?.Total_count,
                    Total_invoice: Channel[0]?.Total_invoice,
                    Total_margin: ((Channel[0]?.Total_profit / Channel[0]?.Total_invoice) * 100),
                    Total_market: Channel[0]?.Total_market,
                    Total_profit: Channel[0]?.Total_profit,
                    Total_qty: Channel[0]?.Total_qty,
                    Total_return: Channel[0]?.Total_return,
                    Total_shipping: Channel[0]?.Total_shipping,
                    Total_InGst: Channel[0]?.Total_InGst,
                    Total_OUTGST: Channel[0]?.Total_OUTGST,
                    Total_ACOTS: isFinite((Channel[0]?.Total_ads / Channel[0]?.Total_invoice) * 100) ? (Channel[0]?.Total_ads / Channel[0]?.Total_invoice) * 100 : 0 || 0,
                    Total_ROI: isFinite((Channel[0]?.Total_profit / Channel[0]?.Total_cost_per) * 100) ? (Channel[0]?.Total_profit / Channel[0]?.Total_cost_per) * 100 : 0 || 0,
                }
                setProfitTotal(total);
            }
        }
        if (tabValue === 3) {
            //  setSearchBool(true)
            let requestDataInput = {
                channels: selectedMarketplaces.map((mp) => mp.name),
                filters: {
                    ...filterCond,
                    status: { IN: ["ACTIVE", "INACTIVE", "Active", "Inactive"] },
                },
                ...(paginationCond
                    ? paginationCond
                    : {
                        pagination: {
                            pageNo: 0,
                            pageSize: 25,
                        },
                    }),
                ...(sortCond ? sortCond : {}),
            };
            requestDataInput.filters["search"] = searchText;
            let InputCost = await getInputProductApi(requestDataInput);
            if (InputCost.listings) {
                setInputProductData(InputCost.listings)
                setTotalRecords(InputCost.pagination.count);
            }
        }
        setIsLoading(false);
    }

    const handleSearch = (value) => {
        setSearchText(value);
    };


    const taxUpdate = (event) => {
        let eventTax = JSON.parse(event)
        setTaxValue(eventTax == true ? "WithOut Tax" : "With Tax")
        localStorage.setItem("toggleEvent", JSON.stringify({ gst_toggle: eventTax }))
        setToggleVal(eventTax);
        onProfitToggleChange();
    }

    const settledUpdate = (event) => {
        let eventorder = event
        SetUnsettledOrders(eventorder)
        localStorage.setItem("unsettled", JSON.stringify({ unsettledToggle: eventorder }))
        setUnsettled(eventorder);
        onProfitToggleChange();
    }


    React.useEffect(() => {
        if (salesChannel.length > 0) {
            getProfit(salesChannel, startDate, endDate, searchText);
        }
    }, [tabValue]);

    return (
        <>
            <Helmet>
                <title>{"Forcesight"}</title>
            </Helmet>
            <div>
                <CustomAppBar />
                <CustomFilterHeader
                    headerTitle="Profitability Tracker"
                    handleManualSyncClick={handleManualSyncClick}
                    handleMarketplaceChange={handleMarketplaceChange}
                    showDateRanges={true}
                    showManualSync={true}
                    searchFunction={(ds, searchText) => {
                        getProfit(salesChannel, startDate, endDate, searchText.trim());
                    }}
                    rows={
                        tabValue === 0
                            ? getProductData
                            : []
                    }
                    getExportData={ExportAllData}
                    searchHide={true}
                    showExportButton={tabValue !== 3}
                    showSearchBar={true}
                    // showSearchBarBox={searchBool}
                    columns={columns}
                    isLoading={isLoading}
                    onFilterUpdate={(data) => { }}
                    exportFileName="profitabilityTracker"
                    customToggleProfit={
                        tabValue !== 3 && (
                            <>
                                <Select
                                    className="mx-1"
                                    defaultValue={taxValue}
                                    style={{ width: 122 }}
                                    onSelect={taxUpdate}>
                                    <Option value="false">With Tax</Option>
                                    <Option value="true">Without Tax</Option>
                                </Select>
                                <Divider type="vertical" />
                                <Select
                                    className="mx-1"
                                    defaultValue={unsettledOrders}
                                    style={{ width: 155 }}
                                    onSelect={settledUpdate}>
                                    <Option value="settled">Without Estimate</Option>
                                    <Option value="all_orders">With Estimate</Option>

                                </Select>
                            </>
                        )
                    }
                    onSearchTextEntered={handleSearch}
                />
                <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <Tabs
                        value={tabValue}
                        list={TabsList}
                        onChange={(value) => {
                            setTabValue(value);
                        }}
                    />
                </div>
                <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <TabPanel value={tabValue} index={0}>
                        <ProductTabV2
                            data={getProductData}
                            isLoading={isLoading}
                            searchText={searchText}
                            taxValue={taxValue}
                            activeTab={`${tabValue}`}
                            setColumn={(col) => {
                                setColumns(col);
                            }}
                            pageCount={totalRecords}
                            tableFilterData={(paginationCondition, sortCond, filterCond) => {
                                getProfit(
                                    salesChannel,
                                    startDate,
                                    endDate,
                                    searchText,
                                    paginationCondition,
                                    sortCond,
                                    filterCond
                                );
                            }}
                            profitTotal={profitTotal}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <OrderTabV2
                            data={getOrderData}
                            activeTab={`${tabValue}`}
                            taxValue={taxValue}
                            setColumn={(col) => {
                                setColumns(col);
                            }}
                            isLoading={isLoading}
                            searchText={searchText}
                            pageCount={totalRecords}
                            tableFilterData={(paginationCondition, sortCond, filterCond) => {
                                getProfit(
                                    salesChannel,
                                    startDate,
                                    endDate,
                                    searchText,
                                    paginationCondition,
                                    sortCond,
                                    filterCond
                                );
                            }}
                            profitTotal={profitTotal}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <ChannelTabV2
                            data={getChannelData}
                            isLoading={isLoading}
                            searchText={searchText}
                            taxValue={taxValue}
                            activeTab={`${tabValue}`}
                            setColumn={(col) => {
                                setColumns(col);
                            }}
                            pageCount={totalRecords}
                            tableFilterData={(paginationCondition, sortCond, filterCond) => {
                                getProfit(
                                    salesChannel,
                                    startDate,
                                    endDate,
                                    searchText,
                                    paginationCondition,
                                    sortCond,
                                    filterCond
                                );
                            }}
                            profitTotal={profitTotal}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <InputProductTabV2
                            pageCount={totalRecords}
                            data={getInputProductData}
                            isLoading={isLoading}
                            searchText={searchText}
                            activeTab={`${tabValue}`}
                            setColumn={(col) => {
                                setColumns(col);
                            }}
                            tableFilterData={(paginationCondition, sortCond, filterCond) => {
                                getProfit(
                                    salesChannel,
                                    startDate,
                                    endDate,
                                    searchText,
                                    paginationCondition,
                                    sortCond,
                                    filterCond
                                );
                            }}
                            reloadApi={(data) => {
                                let editData = getInputProductData.map(
                                    (filterData) => {
                                        if (
                                            data.sku === filterData.sku &&
                                            data.channel === filterData.channel
                                        ) {
                                            filterData.costPerUnit = data.costPerUnit;
                                        }

                                        return filterData;
                                    }
                                );
                                setInputProductData(editData);

                            }}
                        />
                    </TabPanel>
                </div>
            </div>
        </>
    );
};