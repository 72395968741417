import { Box } from "@material-ui/core";
import { Button, Modal, Spin } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import CustomAppBar from "../../components/./CustomAppBar.js";
import CustomFilterHeader from "../../components/./CustomFilterHeader.js";
import AreaSelector from "../../components/topoMap/AreaSelector"; // Map
import ApiWrapper from "../../utils/ApiWrapper.js";
import CallToActionInfo from "./CallToActionsInfo.js";
import MaterialIcon from "react-google-material-icons";
import {
  OutOfStockCtaColumns,
  OverstockCtaColumns,
  ProductReviewsCtaColumns,
  ProfitCtaColumns,
  SellerReviewsCtaColumns,
  ProfitCtaMargin,
} from "./CtaColumns.js";
import CtaModal from "./CtaModal.js";
import ProfitabilityInfoTable from "./ProfitabilityInfoTable.js";
import QuickInfoCharts from "./QuickInfoCharts.js";
import CallToActionInfoV3 from "./CallToActionInfoV3.js";
import ProfitabilityInfoTableV3 from "./ProfitabilityInfoTableV3.js";
// import CarouselSlider from './Carousel'

export default function DashboardV3() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCallToActionLoading, setIsCallToActionLoading] =
    React.useState(false);
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);
  const [cockpitInfo, setCockpitInfo] = React.useState(null);
  const [profitInfo, setProfitInfo] = React.useState(null);
  const [reviewInfo, setReviewInfo] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [orderFlowData, setOrderFlowData] = React.useState(null);
  const [orderTypeData, setOrderTypeData] = React.useState(null);
  const [orderProcessingData, setOrderProcessingData] = React.useState(null);
  const [returnProcessingData, setReturnProcesssingData] = React.useState(null);
  const [returnReasonData, setReturnReasonData] = React.useState(null);
  const [reviewRatingData, setReviewRatingData] = React.useState(null);
  const [ctaData, setCtaData] = React.useState(null);
  const [reviewValue, setReviewValue] = React.useState(null);
  const [profitCtaData, setProfitCtaData] = React.useState(null);
  const [profitCardValue, setProfitCardValue] = React.useState(null);
  const [stockCtaData, setStockCtaData] = React.useState(null);
  const [inventoryValue, setInventoryValue] = React.useState(null);
  const [profitTableData, setProfitTableData] = React.useState([]);
  const [profitPieChart, setProfitPieChart] = React.useState([]);
  const [mapresData, mapsendData] = React.useState("");
  const [isCtaModalActive, setIsCtaModalActive] = React.useState(false);
  const [ctaModalData, setCtaModalData] = React.useState(null);
  const [ctaModalDataType, setCtaModalDataType] = React.useState("");
  const [modelValue, SetModelValue] = React.useState("");
  const [ctaTitle, setCtaTitle] = React.useState("");
  const [modelParams, setModelParams] = React.useState("");
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [tableLoader, setTableLoader] = React.useState(false);

  const onSearchTextEntered = (searchText) => { };
  const [visible, setVisible] = React.useState(false);

  let days = localStorage.getItem("subscriptionDays");
  var start = new Date();
  var end = moment(days, "YYYY-MM-DD");
  const subscriptiondays = Math.round((end - start) / 1000 / 60 / 60 / 24) + 1;

  const handleManualSyncClick = (event) => { };

  const handleMarketplaceChange = async (
    selectedMarketplaces,
    fromDate,
    toDate
  ) => {
    if (selectedMarketplaces.length <= 0) {
      setCockpitInfo({});
      setProfitInfo({});
      setReviewInfo({});
      setOrderFlowData({});
      setOrderTypeData(null);
      setOrderProcessingData(null);
      setReturnProcesssingData(null);
      setReturnReasonData(null);
      setReviewRatingData({});
      setCtaData({});
      setReviewValue({});
      setProfitCtaData({});
      setProfitCardValue({});
      setStockCtaData({});
      setInventoryValue({});
      mapsendData(null);
      setProfitTableData(null);
      setProfitPieChart(null);
    } else {
      setIsLoading(true);
      setIsCallToActionLoading(true);
      setStartDate(fromDate);
      setEndDate(toDate);

      var isCockpitLoaded,
        isProfitLoaded,
        isCallToActionProfitLoaded,
        isCallToActionReviewloaded,
        isCallToActionInventoryLoaded,
        isReviewLoaded = false;

      let channelsArray = [];
      for (let i = 0; i < selectedMarketplaces.length; i++) {
        channelsArray.push(selectedMarketplaces[i].name);
      }
      setSelectedMarketplaces(channelsArray);
      let jsonRequestBody = {
        channels: channelsArray,
        start_date: fromDate,
        end_date: toDate,
      };
      ApiWrapper("cockpitReviews_v2", jsonRequestBody).then(
        function (response) {
          if (response.status === true) {
            let data2 = {
              sellerNegativeReviews:
                response?.data?.CallsToAction?.NumberOfNegativeReviews?.Seller,
              productNegativeReviews:
                response?.data?.CallsToAction?.NumberOfNegativeReviews?.Product,
              lostBuyBox: response?.data?.LostBuyBox,
              opporrunityLost: response?.data?.OpportunityLost
            };
            setReviewValue(data2);
            formatReviewRatingChartData(
              response.data?.ReviewRatingDistribution
            );
            setReviewInfo(response.data?.Reviews);

            isReviewLoaded = true;
            isCallToActionReviewloaded = true;
            if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
              setIsLoading(false);
            }
            if (
              isCallToActionProfitLoaded &&
              isCallToActionInventoryLoaded &&
              isCallToActionReviewloaded
            ) {
              setIsCallToActionLoading(false);
            }
          } else {
            isReviewLoaded = true;
            isCallToActionReviewloaded = true;
            if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
              setIsLoading(false);
            }
            if (
              isCallToActionProfitLoaded &&
              isCallToActionInventoryLoaded &&
              isCallToActionReviewloaded
            ) {
              setIsCallToActionLoading(false);
            }
          }
        },
        function (error) {
          isReviewLoaded = true;
          isCallToActionReviewloaded = true;
          if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
            setIsLoading(false);
          }
          if (
            isCallToActionProfitLoaded &&
            isCallToActionInventoryLoaded &&
            isCallToActionReviewloaded
          ) {
            setIsCallToActionLoading(false);
          }
        }
      );
      ApiWrapper("cockpit_v2", jsonRequestBody).then(
        function (response) {
          if (response.status === true && response.data !== undefined) {
            setCockpitInfo(response.data);
            formatOrderFlowChartData(response.data.OrderFlow);
            formatOrderTypeChartData(response.data.OrderTypeDistribution);
            formatOrderProcessingChartData(
              response.data.OrderProcessingStatusDistribution
            );
            formatReturnProcessingChartData(
              response.data.ReturnProcessingStatusDistribution
            );
            formatReturnReasonChartData(response.data.ReturnReasonDistribution);

            isCockpitLoaded = true;
            if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
              setIsLoading(false);
            }
          } else {
            isCockpitLoaded = true;
            if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
              setIsLoading(false);
            }
          }
        },
        function (error) {
          isCockpitLoaded = true;
          if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
            setIsLoading(false);
          }
        }
      );
      ApiWrapper("cockpit_Inventory_v2", jsonRequestBody).then(
        function (response) {
          if (response.status === true && response.data !== undefined) {
            let data1 = {
              outOfStock: response?.data?.CallsToAction?.OutOfStock,
              outOfStock7Days: response?.data?.CallsToAction?.OutOfStock7Days,
              overstock: response?.data?.CallsToAction?.OverStock60Days,
            };
            setInventoryValue(data1);

            isCallToActionInventoryLoaded = true;
            if (
              isCallToActionProfitLoaded &&
              isCallToActionInventoryLoaded &&
              isCallToActionReviewloaded
            ) {
              setIsCallToActionLoading(false);
            }
          } else {
            isCallToActionInventoryLoaded = true;
            if (
              isCallToActionProfitLoaded &&
              isCallToActionInventoryLoaded &&
              isCallToActionReviewloaded
            ) {
              setIsCallToActionLoading(false);
            }
          }
        },
        function (error) {
          isCallToActionInventoryLoaded = true;
          if (
            isCallToActionProfitLoaded &&
            isCallToActionInventoryLoaded &&
            isCallToActionReviewloaded
          ) {
            setIsCallToActionLoading(false);
          }
        }
      );
      ApiWrapper("profit_cardV2", jsonRequestBody).then(
        function (response) {
          if (response.status === true) {
            formatProfitInfo(response?.data?.Profit);

            isProfitLoaded = true;
            if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
              setIsLoading(false);
            }
          } else {
            isProfitLoaded = true;
            if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
              setIsLoading(false);
            }
          }
        },
        function (error) {
          isProfitLoaded = true;
          if (isProfitLoaded && isCockpitLoaded && isReviewLoaded) {
            setIsLoading(false);
          }
        }
      );
      ApiWrapper("profitabilityDashboard_v3", jsonRequestBody).then(
        function (response) {
          if (response.status === true) {
            // formatProfitInfo(response?.data?.Profit);

            let data = {
              noOfLossMakingSkus:
                response?.data?.CallsToAction?.NumberOfLossMakingSkus,
              noOfProfitMakingSkus:
                response?.data?.CallsToAction?.NumberOfProfitMakingSkus,
            };
            setProfitCardValue(data);
            formatProfitChartData(response?.data?.ProfitabilityTable);
            // setProfitPieChart(response?.data?.ProfitabilityTable)
            // setProfitTableData(response?.data?.ProfitabilityTable);

            isCallToActionProfitLoaded = true;
            if (
              isCallToActionProfitLoaded &&
              isCallToActionInventoryLoaded &&
              isCallToActionReviewloaded
            ) {
              setIsCallToActionLoading(false);
            }
          } else {
            isCallToActionProfitLoaded = true;
            if (
              isCallToActionProfitLoaded &&
              isCallToActionInventoryLoaded &&
              isCallToActionReviewloaded
            ) {
              setIsCallToActionLoading(false);
            }
          }
        },
        function (error) {
          isCallToActionProfitLoaded = true;
          if (
            isCallToActionProfitLoaded &&
            isCallToActionInventoryLoaded &&
            isCallToActionReviewloaded
          ) {
            setIsCallToActionLoading(false);
          }
        }
      );
      let requestData = {
        channels: jsonRequestBody.channels,
        filters: {
            fromDate: jsonRequestBody.start_date,
            toDate: jsonRequestBody.end_date,
        }
    }
      ApiWrapper("DashboardMap",requestData).then(
        function (response) {
          if (response.status === true) {
            mapData(response.data || response);
          }
        },
        function (error) {
          console.error(error);
        }
      );
    }

    if (
      subscriptiondays <= 7 &&
      localStorage.getItem("modelopenRenew") == "true"
    ) {
      setVisible(true);
      localStorage.setItem("modelopenRenew", false);
    }
  };
  const call_to_action = (data) => {
    let jsonRequestBody = {
      channels: selectedMarketplaces,
      filters: {
        fromDate: startDate,
        toDate: endDate,
        calls_to_action: data?.call_to_action_params,
      },
      pagination: {
        pageNo: 0,
        pageSize: 10,
      },
    };

    if (data.id === "profit") {
      profitFunction(jsonRequestBody);
    }
    if (data.id === "sellerReviews" || data.id === "productReviews") {
      reviews(jsonRequestBody);
    }
    if (data.id === "outOfStock" || data.id === "overstock") {
      stockData(jsonRequestBody);
    }
    if (data.id === "profitMargin") {
      profitFunction(jsonRequestBody);
    }
  };
  const reviews = (jsonRequestBody) => {
    setTableLoader(true);
    ApiWrapper("cockpitReviews_v2", jsonRequestBody).then(function (response) {
      if (response.status === true) {
        if (response.data.CallsToAction.NumberOfNegativeReviews.SellerItems) {
          setCtaModalData(
            response.data.CallsToAction.NumberOfNegativeReviews.SellerItems
          );
          setTotalRecords(response.pagination.count);
        }
        if (response.data.CallsToAction.NumberOfNegativeReviews.ProductItems) {
          setCtaModalData(
            response.data.CallsToAction.NumberOfNegativeReviews.ProductItems
          );
          setTotalRecords(response.pagination.count);
        }
        setTableLoader(false);
      }
    });
  };
  const profitFunction = (jsonRequestBody) => {
    setTableLoader(true);
    ApiWrapper("profitabilityDashboard_v3", jsonRequestBody).then(function (
      response
    ) {
      if (response.status === true) {
        if (response.data.CallsToAction.NumberOfLossMakingSkusItems) {
          setCtaModalData(
            response.data.CallsToAction.NumberOfLossMakingSkusItems
          );
          setTotalRecords(
            response.data.CallsToAction?.NumberOfLossMakingSkusItems[0]
              ?.Total_count
          );
        }
        if (response.data.CallsToAction.NumberOfProfitMakingSkusItems) {
          setCtaModalData(
            response.data.CallsToAction.NumberOfProfitMakingSkusItems
          );
          setTotalRecords(
            response.data.CallsToAction?.NumberOfProfitMakingSkusItems[0]
              ?.Total_count
          );
        }
        if (response.data.CallsToAction.Profit_Margin) {
          setCtaModalData(response.data.CallsToAction.Profit_Margin);
          setTotalRecords(
            response.data.CallsToAction?.Profit_Margin[0]?.Total_count
          );
        }
        setTableLoader(false);
      }
    });
  };
  const stockData = (jsonRequestBody) => {
    setTableLoader(true);
    ApiWrapper("cockpit_Inventory_v2", jsonRequestBody).then(function (
      response
    ) {
      if (response.status === true) {
        if (response.data.CallsToAction.OutOfStockItems.length) {
          setCtaModalData(response.data.CallsToAction.OutOfStockItems);
          setTotalRecords(response.pagination.count);
        }
        if (response.data.CallsToAction.OutOfStock7DaysItems.length) {
          setCtaModalData(response.data.CallsToAction.OutOfStock7DaysItems);
          setTotalRecords(response.pagination.count);
        }
        if (response.data.CallsToAction.OverStock60DaysItems.length) {
          setCtaModalData(response.data.CallsToAction.OverStock60DaysItems);
          setTotalRecords(response.pagination.count);
        }
        setTableLoader(false);
      }
    });
  };
  const showCtaModal = (data) => {
    setModelParams(data);
    call_to_action(data);
    setIsCtaModalActive(true);
    setCtaModalDataType(data.id);
    SetModelValue(data.value);
    setCtaTitle(data.title);
  };
  const paginationApiCall = (pagination, sorter) => {
    let jsonRequestBody = {
      channels: selectedMarketplaces,
      filters: {
        fromDate: startDate,
        toDate: endDate,
        calls_to_action: modelParams?.call_to_action_params,
      },
      pagination: {
        pageNo: pagination.current - 1,
        pageSize: pagination.pageSize,
      },
      sort: {
        [sorter.columnKey]: sorter.order === "descend" ? -1 : 1,
      },
    };
    if (modelParams.id === "profit") {
      profitFunction(jsonRequestBody);
    }
    if (
      modelParams.id === "sellerReviews" ||
      modelParams.id === "productReviews"
    ) {
      reviews(jsonRequestBody);
    }
    if (modelParams.id === "outOfStock" || modelParams.id === "overstock") {
      stockData(jsonRequestBody);
    }
    if (modelParams.id === "profitMargin") {
      profitFunction(jsonRequestBody);
    }
  };

  const hideCtaModal = (data) => {
    setCurrentPageNumber(1);
    setCtaModalData(null);
    setIsCtaModalActive(false);
  };

  const formatProfitInfo = async (info) => {
    setProfitInfo(info);
  };

  const formatOrderFlowChartData = async (orderFlow) => {
    setOrderFlowData(orderFlow);
  };

  const formatOrderTypeChartData = async (orderType) => {
    setOrderTypeData(orderType);
  };

  const formatOrderProcessingChartData = async (orderProcessing) => {
    setOrderProcessingData(orderProcessing);
  };
  const formatProfitChartData = async (profit) => {
    setProfitPieChart(profit);
  };

  const formatReturnProcessingChartData = async (returnProcessing) => {
    setReturnProcesssingData(returnProcessing);
  };

  const formatReturnReasonChartData = async (returnReason) => {
    setReturnReasonData(returnReason);
  };

  const formatReviewRatingChartData = async (reviewRating) => {
    setReviewRatingData(reviewRating);
  };

  const mapData = async (mapdata) => {
    mapsendData(mapdata);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const planPage = () => {
    localStorage.setItem("renewPlan", true);
  };
  const profitTable = (Name) => {
    let data = {
      id: "profitMargin",
      call_to_action_params: Name,
    };
    setModelParams(data);
    call_to_action(data);
    setCtaModalDataType("profitMargin");
    setIsCtaModalActive(true);
  };

  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <CustomAppBar />
      <CustomFilterHeader
        headerTitle="Cockpit"
        handleManualSyncClick={handleManualSyncClick}
        handleMarketplaceChange={handleMarketplaceChange}
        onSearchTextEntered={onSearchTextEntered}
        showSearchBar={false}
        showManualSync={false}
        isLoading={isLoading}
      />
      {/* <div className="flex justify-end mx-3.5	my-1.5 h-9 h-1.5">
          <PrinterOutlined  onClick={() => window.print()}/>
      </div> */}
      <div className={`${isLoading ? "cursor-not-allowed" : ""}`}>
        <div
          className={`${isLoading ? "pointer-events-none cursor-not-allowed" : ""
            }`}
        >
          <div className="px-5 mt-2 tablet:p-2.5">
            <div className="w-full ">
              <Spin spinning={isLoading}>
                <div class="w-full justify-between flex flex-nowrap tablet:flex-wrap gap-5 ">
                  {/* Box_1 */}
                  <div
                    className="flex-shrink-0 shadow-2xl rounded-3xl relative overflow-hidden max-w-xs w-3/12 tablet:w-48 tablet:pointer-events-none tablet:cursor-default mobile:w-45"
                    style={{ backgroundColor: "#588BF7" }}
                  >
                    <Link to="/orders-tracker">
                      <svg
                        className="absolute bottom-0 left-0 mb-8"
                        viewBox="0 0 375 283"
                        fill="none"
                        style={{ transform: "scale(1.5)", opacity: "0.1" }}
                      >
                        <rect
                          x="159.52"
                          y="175"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 159.52 175)"
                          fill="white"
                        />
                        <rect
                          y="107.48"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 0 107.48)"
                          fill="white"
                        />
                      </svg>
                      <div className="relative pt-5 px-10 flex items-center justify-center">
                        <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <span class="block font-semibold text-xl">Orders</span>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <div class="flex justify-between">
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span class="block font-semibold text-lg">
                              {cockpitInfo &&
                                cockpitInfo?.Orders &&
                                cockpitInfo?.Orders?.OrdersReceived
                                ? `${Math.trunc(
                                  cockpitInfo.Orders.OrdersReceived
                                ).toLocaleString()}`
                                : 0}
                            </span>
                          )}
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span
                              style={{ color: "#588BF7" }}
                              class="block bg-white rounded-full text-xs font-semibold px-1 py-1 leading-none flex items-center"
                            >
                              {`${cockpitInfo?.Orders?.ChangeInOrdersReceived} %`}
                              {cockpitInfo?.Orders?.ChangeInOrdersReceived >=
                                0 ? (
                                <MaterialIcon icon="trending_up" />
                              ) : null}
                              {cockpitInfo?.Orders?.ChangeInOrdersReceived < 0 ? (
                                <MaterialIcon icon="trending_down" />
                              ) : null}
                            </span>
                          )}
                        </div>
                        <span className="block opacity-75 -mb-1">
                          Orders received
                        </span>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <div class="flex justify-between">
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span class="block font-semibold text-lg">
                              {cockpitInfo &&
                                cockpitInfo?.Orders &&
                                cockpitInfo?.Orders?.TotalSalesRevenue
                                ? `${Math.trunc(
                                  cockpitInfo.Orders.TotalSalesRevenue
                                ).toLocaleString()}`
                                : 0}
                            </span>
                          )}
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span
                              style={{ color: "#588BF7" }}
                              class="block bg-white rounded-full text-xs font-semibold px-1 py-1 leading-none flex items-center"
                            >
                              {`${cockpitInfo?.Orders?.ChangeInTotalSalesRevenue} %`}
                              {cockpitInfo?.Orders?.ChangeInTotalSalesRevenue >=
                                0 ? (
                                <MaterialIcon icon="trending_up" />
                              ) : null}
                              {cockpitInfo?.Orders?.ChangeInTotalSalesRevenue <
                                0 ? (
                                <MaterialIcon icon="trending_down" />
                              ) : null}
                            </span>
                          )}
                        </div>
                        <span className="block opacity-75 -mb-1">
                          Total sales
                        </span>
                      </div>
                      <div className=" pb-2"></div>
                    </Link>
                  </div>
                  {/* Box_2 */}
                  <div
                    className="flex-shrink-0 shadow-2xl rounded-3xl relative overflow-hidden max-w-xs w-3/12 tablet:w-48 tablet:pointer-events-none tablet:cursor-default mobile:w-45"
                    style={{ backgroundColor: "#38B2AC" }}
                  >
                    <Link to="/returns-tracker">
                      <svg
                        className="absolute bottom-0 left-0 mb-8"
                        viewBox="0 0 375 283"
                        fill="none"
                        style={{ transform: "scale(1.5)", opacity: "0.1" }}
                      >
                        <rect
                          x="159.52"
                          y="175"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 159.52 175)"
                          fill="white"
                        />
                        <rect
                          y="107.48"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 0 107.48)"
                          fill="white"
                        />
                      </svg>
                      <div className="relative pt-5 px-10 flex items-center justify-center">
                        <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <span class="block font-semibold text-xl">Returns</span>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <div class="flex justify-between">
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span class="block font-semibold text-lg">
                              {cockpitInfo &&
                                cockpitInfo?.Returns &&
                                cockpitInfo?.Returns?.NumberOfReturns
                                ? `${Math.trunc(
                                  cockpitInfo.Returns.NumberOfReturns
                                ).toLocaleString()}`
                                : 0}
                            </span>
                          )}
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span
                              style={{ color: "#38B2AC" }}
                              class="block bg-white rounded-full text-xs font-semibold px-1 py-1 leading-none flex items-center"
                            >
                              {`${cockpitInfo?.Returns?.ChangeInNumberOfReturns} %`}
                              {cockpitInfo?.Returns?.ChangeInNumberOfReturns >=
                                0 ? (
                                <MaterialIcon icon="trending_up" />
                              ) : null}
                              {cockpitInfo?.Returns?.ChangeInNumberOfReturns <
                                0 ? (
                                <MaterialIcon icon="trending_down" />
                              ) : null}
                            </span>
                          )}
                        </div>
                        <span className="block opacity-75 -mb-1">
                          No. of return items
                        </span>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <div class="flex justify-between">
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span class="block font-semibold text-lg">
                              {cockpitInfo &&
                                cockpitInfo?.Returns &&
                                cockpitInfo?.Returns?.ReturnPercentage
                                ? `${cockpitInfo.Returns.ReturnPercentage}%`
                                : "0%"}
                            </span>
                          )}
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span
                              style={{ color: "#38B2AC" }}
                              class="block bg-white rounded-full text-xs font-semibold px-1 py-1 leading-none flex items-center"
                            >
                              {cockpitInfo?.Returns?.ChangeInReturnPercentage} %
                              {cockpitInfo?.Returns?.ChangeInReturnPercentage >=
                                0 ? (
                                <MaterialIcon icon="trending_up" />
                              ) : null}
                              {cockpitInfo?.Returns?.ChangeInReturnPercentage <
                                0 ? (
                                <MaterialIcon icon="trending_down" />
                              ) : null}
                            </span>
                          )}
                        </div>
                        <span className="block opacity-75 -mb-1">
                          Returns percentage
                        </span>
                      </div>
                      <div className=" pb-2"></div>
                    </Link>
                  </div>
                  {/* Box_3 */}
                  <div
                    className="flex-shrink-0 rounded-3xl shadow-2xl relative overflow-hidden max-w-xs w-3/12 tablet:w-48 tablet:pointer-events-none tablet:cursor-default mobile:w-45"
                    style={{ backgroundColor: "#9F7AEA" }}
                  >
                    <Link to="/reviews">
                      <svg
                        className="absolute bottom-0 left-0 mb-8"
                        viewBox="0 0 375 283"
                        fill="none"
                        style={{ transform: "scale(1.5)", opacity: "0.1" }}
                      >
                        <rect
                          x="159.52"
                          y="175"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 159.52 175)"
                          fill="white"
                        />
                        <rect
                          y="107.48"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 0 107.48)"
                          fill="white"
                        />
                      </svg>
                      <div className="relative pt-5 px-10 flex items-center justify-center">
                        <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <span class="block font-semibold text-xl">Reviews</span>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <div class="flex justify-between">
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span class="block font-semibold text-lg">
                              {reviewInfo && reviewInfo?.NumberOfProductReviews
                                ? `${Math.trunc(
                                  reviewInfo.NumberOfProductReviews
                                ).toLocaleString()}`
                                : 0}
                            </span>
                          )}
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span
                              style={{ color: "#9F7AEA" }}
                              class="block bg-white rounded-full text-xs font-semibold px-1 py-1 leading-none flex items-center"
                            >
                              {` ${reviewInfo?.ChangeInNumberOfProductReviews} %`}
                              {reviewInfo?.ChangeInNumberOfProductReviews >= 0 ? (
                                <MaterialIcon icon="trending_up" />
                              ) : null}
                              {reviewInfo?.ChangeInNumberOfProductReviews < 0 ? (
                                <MaterialIcon icon="trending_down" />
                              ) : null}
                            </span>
                          )}
                        </div>
                        <span className="block opacity-75 -mb-1">
                          No. of product reviews
                        </span>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <div class="flex justify-between">
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span class="block font-semibold text-lg">
                              {reviewInfo && reviewInfo?.NumberOfProductRatings
                                ? `${Math.trunc(
                                  reviewInfo.NumberOfProductRatings
                                ).toLocaleString()}`
                                : 0}
                            </span>
                          )}
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span
                              style={{ color: "#9F7AEA" }}
                              class="block bg-white rounded-full text-xs font-semibold px-1 py-1 leading-none flex items-center"
                            >
                              {` ${reviewInfo?.ChangeInNumberOfProductRatings} %`}
                              {reviewInfo?.ChangeInNumberOfProductRatings >= 0 ? (
                                <MaterialIcon icon="trending_up" />
                              ) : null}
                              {reviewInfo?.ChangeInNumberOfProductRatings < 0 ? (
                                <MaterialIcon icon="trending_down" />
                              ) : null}
                            </span>
                          )}
                        </div>
                        <span className="block opacity-75 -mb-1">
                          No. of product ratings
                        </span>
                      </div>
                      <div className=" pb-2"></div>
                    </Link>
                  </div>
                  {/* Box_4 */}
                  <div
                    className="flex-shrink-0 rounded-3xl shadow-2xl relative overflow-hidden max-w-xs w-3/12 tablet:w-48 tablet:pointer-events-none tablet:cursor-default mobile:w-45"
                    style={{ backgroundColor: "#ED8936" }}
                  >
                    <Link to="/profitability">
                      <svg
                        className="absolute bottom-0 left-0 mb-8"
                        viewBox="0 0 375 283"
                        fill="none"
                        style={{ transform: "scale(1.5)", opacity: "0.1" }}
                      >
                        <rect
                          x="159.52"
                          y="175"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 159.52 175)"
                          fill="white"
                        />
                        <rect
                          y="107.48"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 0 107.48)"
                          fill="white"
                        />
                      </svg>
                      <div className="relative pt-5 px-10 flex items-center justify-center">
                        <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <span class="block font-semibold text-xl">
                          Profitability
                        </span>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <div class="flex justify-between">
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span class="block font-semibold text-lg">
                              {profitInfo && profitInfo?.EstimatedProfit
                                ? `${Math.trunc(
                                  profitInfo.EstimatedProfit
                                ).toLocaleString()}`
                                : 0}
                            </span>
                          )}
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span
                              style={{ color: "#ED8936" }}
                              class="block bg-white rounded-full text-xs font-semibold px-1 py-1 leading-none flex items-center"
                            >
                              {` ${profitInfo?.ChangeInEstimatedProfit} % `}
                              {profitInfo?.ChangeInEstimatedProfit >= 0 ? (
                                <MaterialIcon icon="trending_up" />
                              ) : null}
                              {profitInfo?.ChangeInEstimatedProfit < 0 ? (
                                <MaterialIcon icon="trending_down" />
                              ) : null}
                            </span>
                          )}
                        </div>
                        <span className="block opacity-75 -mb-1">
                          Total Profit
                        </span>
                      </div>
                      <div className="relative text-white px-6 pb-2">
                        <div class="flex justify-between">
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span class="block font-semibold text-lg">
                              {profitInfo && profitInfo?.ProfitMargin
                                ? `${Math.round(profitInfo.ProfitMargin * 100) /
                                100
                                }%`
                                : "0%"}
                            </span>
                          )}
                          {isLoading ? (
                            <div className="item bg-indigo-500 opacity-25">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <span
                              style={{ color: "#ED8936" }}
                              class="block bg-white rounded-full text-xs font-semibold px-1 py-1 leading-none flex items-center"
                            >
                              {profitInfo?.ChangeInProfitMargin} %
                              {profitInfo?.ChangeInProfitMargin >= 0 ? (
                                <MaterialIcon icon="trending_up" />
                              ) : null}
                              {profitInfo?.ChangeInProfitMargin < 0 ? (
                                <MaterialIcon icon="trending_down" />
                              ) : null}
                            </span>
                          )}
                        </div>
                        <span className="block opacity-75 -mb-1">
                          Profit margin
                        </span>
                      </div>
                      <div className=" pb-2"></div>
                    </Link>
                  </div>
                </div>
                <div class="mt-2"></div>

                <div class="flex px-3 gap-3">
                  <div className="w-1/4">
                    <AreaSelector
                      resData={mapresData}
                      selectedMarket={selectedMarketplaces}
                      startDate={startDate}
                      endDate={endDate}
                    />

                  </div>

                  <div className="w-3/4 ">
                    <CallToActionInfoV3
                      ctaData={ctaData}
                      reviewValue={reviewValue}
                      profitCtaData={profitCtaData}
                      profitCardValue={profitCardValue}
                      stockCtaData={stockCtaData}
                      inventoryValue={inventoryValue}
                      isLoading={isLoading}
                      showCtaModal={showCtaModal}
                    />

                  </div>
                </div>

                <QuickInfoCharts
                  orderFlowData={orderFlowData}
                  orderTypeData={orderTypeData}
                  orderProcessingData={orderProcessingData}
                  reviewRatingData={reviewRatingData}
                  returnProcessingData={returnProcessingData}
                  returnReasonData={returnReasonData}
                  profitPieChart={profitPieChart}
                />
                <div class="mt-6"></div>

                {/* <ProfitabilityInfoTableV3
                  data={profitTableData}
                  profitTable={profitTable}
                  isLoading={isLoading}
                /> */}
                {/* <CarouselSlider /> */}

                <Modal
                  visible={visible}
                  footer={null}
                  onCancel={handleCancel}
                  width={400}
                  bodyStyle={{ padding: "20px" }}
                >
                  <div className={`flex items-center`}>
                    <p>
                      Your Subscription expires in <b>{subscriptiondays} days</b>
                    </p>
                    <Link to="/account-settings">
                      <Button
                        style={{
                          backgroundColor: "#40a9ff",
                          border: "unset",
                          color: "white",
                          marginLeft: "10px",
                        }}
                        onClick={planPage}
                      >
                        Renew
                      </Button>
                    </Link>
                  </div>
                </Modal>
                <CtaModal
                  isCtaModalActive={isCtaModalActive}
                  hideCtaModal={hideCtaModal}
                  currentPageNumber={currentPageNumber}
                  columns={
                    ctaModalDataType === "profitMargin"
                      ? ProfitCtaMargin
                      : ctaModalDataType === "profit"
                        ? ProfitCtaColumns
                        : ctaModalDataType === "productReviews"
                          ? ProductReviewsCtaColumns
                          : ctaModalDataType === "sellerReviews"
                            ? SellerReviewsCtaColumns
                            : ctaModalDataType === "overstock"
                              ? OverstockCtaColumns
                              : OutOfStockCtaColumns
                  }
                  title={ctaTitle}
                  data={ctaModalData}
                  dataType={ctaModalDataType}
                  value={totalRecords}
                  loading={tableLoader}
                  onDataChange={({ pagination, filters, sorter, extra }) => {
                    setCurrentPageNumber(pagination.current);
                    paginationApiCall(pagination, sorter);
                  }}
                />
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
