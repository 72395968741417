import React from "react";
import ".././App.css";

// const useStyles = makeStyles((theme) => ({
//   title: {
//     fontSize: 24,
//     fontWeight: 600,
//     color: "#171725",
//     marginTop: 2,
//     fontFamily: "Inter",
//   },
// }));

function PageHeader(props) {
  return (
    <>
      <div>
        <div className="heading">{props.title}</div>
      </div>
    </>
  );
}

export default PageHeader;
