import React, {  useState } from 'react';
import ReactTooltip from 'react-tooltip';
import MapChart from './MapChart';

const AreaSelector = (props) => {
    // console.log(props?.resData)

    const [content, setContent] = useState([]);
    const [STName, setSTName] = useState("")
    const [contentState, setContentState] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
    };

    return (
        <>
            {props?.resData && (
                <React.Fragment>
                    {/* <MapDialog resData={props?.resData} show={show} StateName={STName} closeModal={handleClose} /> */}
                    <div style={{ backgroundColor: 'white' }}>
                        <MapChart resData={props?.resData} selectMarket={props?.selectedMarket} startDate={props?.startDate} endDate={props?.endDate} setTooltipContent={setContent} setTooltipContentState={setContentState} setStateName={setSTName} setShowDistrict={setShow} />
                        {contentState &&
                            // <ReactTooltip>
                            //     <b style={{
                            //         fontSize: "18px"

                            //     }}> {contentState}</b>
                            //     {
                            //         content.PENDING ? (
                            //             <>
                            //                 <br></br>
                            //                 PENDING:  {content?.PENDING}
                            //             </>
                            //         ) : null
                            //     }
                            //     {
                            //         content.PROCESSING ? (
                            //             <>
                            //                 <br></br>
                            //                 PROCESSING:  {content?.PROCESSING}
                            //             </>
                            //         ) : null
                            //     }
                            //     {
                            //         content.DELIVERED ? (
                            //             <>
                            //                 <br></br>
                            //                 DELIVERED:  {content?.DELIVERED}
                            //             </>
                            //         ) : null
                            //     }
                            //     {
                            //         content.SHIPPED ? (
                            //             <>
                            //                 <br></br>
                            //                 SHIPPED:  {content?.SHIPPED}
                            //             </>
                            //         ) : null
                            //     }
                            //     {
                            //         content.CANCELLED ? (
                            //             <>
                            //                 <br></br>
                            //                 CANCELLED:  {content?.CANCELLED}
                            //             </>
                            //         ) : null
                            //     }
                            //     {
                            //         content['RETURN DELIVERED'] ? (
                            //             <>
                            //                 <br></br>
                            //                 RETURN:  {content?.['RETURN DELIVERED']}
                            //             </>
                            //         ) : null
                            //     }
                            //     {

                            //         content.amount ? (
                            //             <>
                            //                 <br></br>
                            //                 AMOUNT:  {content?.amount.toFixed(2)}
                            //             </>
                            //         ) : null
                            //     }
                            //     {
                            //         content.total_orders ? (
                            //             <>
                            //                 <br></br>
                            //                 TOTAL ORDERS:  {content?.total_orders}
                            //             </>
                            //         ) : null
                            //     }
                            // </ReactTooltip>
                            <ReactTooltip>
                            <b style={{
                              fontSize: "18px"
                  
                            }}> {contentState}</b>
                            {content.QuantityShippedSum ? (
                              <>
                                <br></br>
                                Qty Sold:  {
                                  content.QuantityShippedSum?.toLocaleString(
                                    "en-IN",
                                    {
                                      maximumFractionDigits: 0,
                                      //style: "currency",
                                      currency: "INR",
                                    }
                                  )
                                }
                              </>
                            ) : null}
                            {content.WithInvoice ? (
                              <>
                                <br></br>
                                Sale Amount:  {
                                  content?.WithInvoice?.toLocaleString(
                                    "en-IN",
                                    {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    }
                                  )
                  
                                }
                              </>
                            ) : null
                            }
                          </ReactTooltip>
                        }
                    </div>
                </React.Fragment>
            )}
        </>
    );
}

export default AreaSelector;