import React, { useState } from "react";
import ChartBar from "./chartbar";
import ChartPie from "./charpie";
import { Skeleton } from "antd";
import SummaryTable from "./summaryTable";
import { Link } from "react-router-dom";
import BuyBoxModal from "./buyBoxModal";

export default function InSights(props) {
  const [isModalActive, setisModalActive] = useState(false);

  const showExpandedModal = (tab, data) => {
    props.callToActionBuyBox(tab, data);
  };

  const modalOpen = () => {
    setisModalActive(true);
  };

  const modelHide = () => {
    setisModalActive(false);
  };
  return (
    <>
      <div
        className={`${
          props.isLoading ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <div className="w-full flex justify-between">
          <div className="width-30" style={{ height: "400px", width: "300px" }}>
            <div
              className="flex-shrink-0 shadow-xl rounded-3xl"
              style={{ backgroundColor: "#588BF7", height: "110px" }}
              onClick={() => showExpandedModal(1, "LostBuyBox")}
            >
              {/* <svg
                            className="absolute bottom-0 left-0 mb-8"
                            viewBox="0 0 375 283"
                            fill="none"
                            style={{ transform: "scale(1.5)", opacity: "0.1" }}
                        >
                            <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                            />
                            <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                            />
                        </svg> */}
              <div className="relative text-white px-6 pb-2 py-1">
                <span class="block font-semibold text-base">Lost Buy Box</span>
              </div>
              <div className="relative text-white px-6 pb-6">
                <div class="flex justify-between">
                  {props.isLoading ? (
                    <div className="item bg-indigo-500 opacity-25">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <span class="block font-semibold text-4xl">
                      {props.buyBoxSummary?.lost_buybox_count}
                    </span>
                  )}
                  {props.isLoading ? (
                    <div className="item bg-indigo-500 opacity-25">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <span
                      style={{ color: "#588BF7" }}
                      class="block bg-white rounded-full text-xs font-bold px-3 py-1 leading-none flex items-center"
                    >
                      {props.buyBoxSummary?.lostBuyBoxCal?.toFixed(2) + " %"}
                    </span>
                  )}
                </div>
                <span className="block opacity-75 -mb-1">
                  # Products / % on Active Listing
                </span>
              </div>

              <div className=" pb-6"></div>
            </div>
            <br />
            <div
              className="flex-shrink-0 shadow-xl rounded-3xl"
              style={{ backgroundColor: "#588BF7", height: "110px" }}
              onClick={modalOpen}
            >
              {/* <svg
                            className="absolute bottom-0 left-0 mb-8"
                            viewBox="0 0 375 283"
                            fill="none"
                            style={{ transform: "scale(1.5)", opacity: "0.1" }}
                        >
                            <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                            />
                            <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                            />
                        </svg> */}
              <div className="relative text-white px-6 pb-2 py-1">
                <span class="block font-semibold text-base">
                  Missing Buy Box
                </span>
              </div>
              <div className="relative text-white px-6 pb-6">
                <div class="flex justify-between">
                  {props.isLoading ? (
                    <div className="item bg-indigo-500 opacity-25">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <span class="block font-semibold text-4xl">
                      {props.buyBoxSummary?.no_buybox_count}
                    </span>
                  )}
                  {props.isLoading ? (
                    <div className="item bg-indigo-500 opacity-25">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <span
                      style={{ color: "#588BF7" }}
                      class="block bg-white rounded-full text-xs font-bold px-3 py-1 leading-none flex items-center"
                    >
                      {props.buyBoxSummary?.noBuyBoxCal?.toFixed(2) + " %"}
                    </span>
                  )}
                </div>
                <span className="block opacity-75 -mb-1">
                  # Products / % on Active Listing
                </span>
              </div>
            </div>
            <br />
            <div
              className="flex-shrink-0 shadow-xl rounded-3xl"
              style={{ backgroundColor: "#588BF7", height: "100px" }}
            >
              {/* <svg
                            className="absolute bottom-0 left-0 mb-8"
                            viewBox="0 0 375 283"
                            fill="none"
                            style={{ transform: "scale(1.5)", opacity: "0.1" }}
                        >
                            <rect
                                x="159.52"
                                y="175"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 159.52 175)"
                                fill="white"
                            />
                            <rect
                                y="107.48"
                                width="152"
                                height="152"
                                rx="8"
                                transform="rotate(-45 0 107.48)"
                                fill="white"
                            />
                        </svg> */}
              <div className="relative text-white px-6 pb-2 py-1">
                <span class="block font-semibold text-base">
                  Opportunity Lost Sales
                </span>
              </div>
              <div className="relative text-white px-6 pb-6">
                <div class="flex justify-between">
                  {props.isLoading ? (
                    <div className="item bg-indigo-500 opacity-25">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <span class="block font-semibold text-4xl">
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(props.buyBoxSummary?.opportunity_lost || "0")}
                    </span>
                  )}
                </div>
                <span className="block opacity-75 -mb-1">Sales</span>
              </div>

              <div className=" pb-6"></div>
            </div>
          </div>
        
          <div className="width-30 tablet:w-full border rounded bg-white p-6">
            <b> # Products Listed</b>
            {props.isLoading ? (
              <Skeleton />
            ) : (
              <>
                {props.buyBoxSummary?.AllSellersChart && (
                  <ChartBar allSeller={props.buyBoxSummary.AllSellersChart} />
                )}
              </>
            )}
          </div>
          <div className="width-30 tablet:w-full border rounded bg-white p-6">
            <b> # BuyBox Owned</b>
            {props.isLoading ? (
              <Skeleton />
            ) : (
              <>
                {props.buyBoxSummary?.BuyBoxOwnersChart && (
                  <ChartPie
                    buyBoxOwner={props.buyBoxSummary.BuyBoxOwnersChart}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="w-full flex flex-wrap">
        <SummaryTable
          allSellerList={props.allSellerList}
          GetDataSummarySellerList={props.GetDataSummarySellerList}
          sellerListSummaryTable={props.sellerListSummaryTable}
          summaryTableSearch={props.summaryTableSearch}
          summaryDataExport={props.summaryDataExport}
          summaryTable={props.summaryTable}
          pageCount={props.pageCount}
          isLoadingTable={props.isLoadingTable}
          summatyTableApiCall={props.summatyTableApiCall}
          isLoading={props.isLoading}
        />
      </div>
      {isModalActive && (
        <BuyBoxModal
          marketPlaces={props.marketPlaces}
          modelHide={modelHide}
          isModalActive={isModalActive}
        />
      )}
    </>
  );
}
