import React, { useState } from 'react';
import MapChartPage from './mapChart';
import CustomAppBar from "../../components/CustomAppBar";
import { Helmet } from "react-helmet";
import { Spin, message } from "antd";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import ApiWrapper from '../../utils/ApiWrapper';

const DashboardMap = (props) => {
    const [STName, setSTName] = useState("")
    const [show, setShow] = useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [columns, setColumns] = useState([]);
    const [allDataLoaded, setAllDataLoaded] = React.useState(false);
    const [marketPlaces, setMarketPlaces] = React.useState([]);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [mapRes, setMapRes] = React.useState("")
    const [mapResTwo, setMapResTwo] = React.useState("")
    const [mapResThree, setMapResThree] = React.useState("")
    const [tableData, setTableData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [pageSize, setPageSize] = React.useState(0)
    const [tableLoader, setTableLoader] = React.useState(true)
    const [statename, setStateName] = React.useState('')

    const handleManualSyncClick = (event) => { };

    const handleMarketplaceChange = async (
        selectedMarketplaces,
        fromDate,
        toDate
    ) => {
        setMarketPlaces(selectedMarketplaces);
        setFromDate(fromDate);
        setToDate(toDate);
        getMapApi(selectedMarketplaces, fromDate, toDate, searchText);
    };


    const ExportAllData = async () => {
    };

    const getMapApi = async (selectedMarketplaces,
        fromDate,
        toDate,
        searchText,
        paginationCond,
        sortCond,
        filterCond = {}
    ) => {
        setIsLoading(true)

        let requestDataTable = {
            channels: selectedMarketplaces.map((mp) => mp.name),
            filters: {
                fromDate: fromDate,
                toDate: toDate,
            },
            pagination: { "pageNo": 0, "pageSize": 5 }
        }
        if (statename) {
            requestDataTable.filters["state"] = statename;
        }
        ApiWrapper("DashboardMapTable", requestDataTable).then(
            function (response) {
                if (response.status === true) {
                    setTableLoader(false)
                    setTableData(response.data.response)
                    setPageSize(response?.data?.response[0]?.Total_count)
                }
            },
            function (error) {
                console.error(error);
            }
        );


        let requestData = {
            channels: selectedMarketplaces.map((mp) => mp.name),
            filters: {
                fromDate: fromDate,
                toDate: toDate,
            }
        }
        ApiWrapper("DashboardMap", requestData).then(
            function (response) {
                if (response.status === true) {
                    setIsLoading(false)
                    setMapResThree(response.data)
                    setMapResTwo(response.data)
                    setMapRes(response.data)
                }
            },
            function (error) {
                console.error(error);
            })


    }
    const onSearchTextEntered = (searchText) => {
        setSearchText(searchText);
    };

    const backTableApiCall = () => {
        setTableLoader(true)
        let requestDataTable = {
            channels: marketPlaces.map((mp) => mp.name),
            filters: {
                fromDate: fromDate,
                toDate: toDate,
            },
            pagination: { "pageNo": 0, "pageSize": 5 }
        }
        ApiWrapper("DashboardMapTable", requestDataTable).then(
            function (response) {
                if (response.status === true) {
                    setTableLoader(false)
                    setTableData(response.data.response)
                    setPageSize(response?.data?.response[0]?.Total_count)
                }
            },
            function (error) {
                console.error(error);
            }
        );
    }

    const TableApi = (pagination, filters, sorter, stName) => {
        setTableLoader(true)
        let requestDataTable = {
            channels: marketPlaces.map((mp) => mp.name),
            filters: {
                fromDate: fromDate,
                toDate: toDate,
            },
            sort: {
                [sorter.columnKey]: sorter.order === "descend" ? -1 : 1,
            },
            pagination: { "pageNo": pagination.current - 1, "pageSize": pagination.pageSize }
        }
        ApiWrapper("DashboardMapTable", requestDataTable).then(
            function (response) {
                if (response.status === true) {
                    setTableLoader(false)
                    setTableData(response.data.response)
                    setPageSize(response?.data?.response[0]?.Total_count)
                }
            },
            function (error) {
                console.error(error);
            }
        );
    }

    const TableApiState = (stName) => {
        setStateName(stName)
        setTableLoader(true)
        let requestDataTable = {
            channels: marketPlaces.map((mp) => mp.name),
            filters: {
                fromDate: fromDate,
                toDate: toDate,
                state: stName.toUpperCase()
            },
            pagination: { "pageNo": 0, "pageSize": 5 }
        }
        ApiWrapper("DashboardMapTable", requestDataTable).then(
            function (response) {
                if (response.status === true) {
                    setTableLoader(false)
                    setTableData(response.data.response)
                    setPageSize(response?.data?.response[0]?.Total_count)
                }
            },
            function (error) {
                console.error(error);
            }
        );
    }
    return (
        <>
            <Helmet>
                <title>{"Forcesight"}</title>
            </Helmet>
            <CustomAppBar />
            <CustomFilterHeader
                headerTitle="Map View"
                handleManualSyncClick={handleManualSyncClick}
                handleMarketplaceChange={handleMarketplaceChange}
                onSearchTextEntered={onSearchTextEntered}
                showExportButton={false}
                getExportData={ExportAllData}
                customToggleProfit={false}
                columns={columns}
                isLoading={allDataLoaded}
                showManualSync={false}
                paymentContent={true}
                onFilterUpdate={(data) => {
                }}
            />
            <Spin spinning={isLoading}>
                {mapRes && (
                    <div style={{ backgroundColor: 'white' }}>
                        <MapChartPage TableApi={TableApi} backTableApiCall={backTableApiCall} TableApiState={TableApiState} pageSize={pageSize} tableLoader={tableLoader} tableData={tableData} isLoading={isLoading} selectedMarketplaces={marketPlaces} fromDate={fromDate} toDate={toDate} resData={mapRes} resDataTwo={mapResTwo} resDataThree={mapResThree} selectMarket={props?.selectedMarket} startDate={props?.startDate} endDate={props?.endDate} setStateName={setSTName} setShowDistrict={setShow} />
                    </div>
                )}
            </Spin>
        </>
    );
}

export default DashboardMap;