import React, { useState } from "react";
import moment from "moment";
import { Button, DatePicker, message } from "antd";
import ExportButton from "../../components/Styles/ExportButton";
import AntTable from "../../components/AntTable";
import PageHeader from "../../components/PageHeader";
import ApiWrapper from "../../utils/ApiWrapper";

function ListView(props) {
  const RefColumns = [
    {
      title: "OrgId",
      align: "left",
      key: "org_id",
      hidden: "true",
    },
    {
      title: "Sno",
      align: "left",
      field: "sno",
      width: 20,
      fixed: "left",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          rowIndex
        ),
    },
    {
      title: "Company",
      align: "left",
      field: "org_name",
      type: "string",
      key: "org_name",
      width: 80,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.org_name !== undefined ? (
          record.org_name
        ) : (
          "-"
        ),
    },

    {
      title: "Email",
      align: "left",
      field: "master_email",
      type: "string",
      defaultSortOrder: "ascend",
      width: 80,
      key: "master_email",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.master_email !== undefined ? (
          record.master_email
        ) : (
          "-"
        ),
    },
    {
      title: "Created Date",
      align: "left",
      key: "created_at",
      hidden: "true",
    },
    {
      title: "Expiry Date",
      align: "left",
      field: "SubscriptionEndDate",
      type: "string",
      width: 40,
      key: "SubscriptionEndDate",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.SubscriptionEndDate !== undefined ? (
          moment(record.SubscriptionEndDate).format("DD MMM YYYY")
        ) : (
          "-"
        ),
    },
    {
      title: "Update",
      align: "center",
      field: "",
      width: 100,
      render: (record, rowIndex, isLoading) => (
        <>
          <DatePicker
            format={"DD MMM YYYY"}
            onChange={(date, dateString) => {
              var updateDate = moment(date).format("YYYY-MM-DD");
              setLoading(true);
              ApiWrapper("subscriptionEndDate", {
                orgId: record.org_id,
                SubscriptionEndDate: updateDate,
              }).then(function (response) {
                if (!response.status) {
                  message.error(" subscriptionEndDate Failed");
                } else {
                  message.success("subscriptionEndDate Updated");
                }
                setLoading(false);
              });
            }}
          />{" "}
        </>
      ),
    },
  ];

  const [columns, setColumns] = React.useState(RefColumns);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div>
        <AntTable
          columns={columns}
          dataSource={props.customerList}
          stickyHeader={{
            offsetHeader: 65,
          }}
          rowKey={(row, rowIndex) => rowIndex}
          hideSummary
        />
      </div>
    </>
  );
}
export default ListView;
