import React from 'react';
import { useHistory } from "react-router-dom";
import {Button} from "antd";
import {AppBar} from "@material-ui/core";
import { ArrowLeftOutlined } from "@ant-design/icons";
import StyledButton, { DangerButton } from "./Styles/StyledButton";

const CampaignToolbar = (props) => {
	
	const history = useHistory();
	
	return (
		<div
			style={{ position: "sticky", top: 0, zIndex: 9, flexGrow: 1 }}
		>
			<AppBar
				position="static"
				style={{
					background: "#0065FF",
					boxShadow: `0px 0px 2px rgba(23, 43, 77, 0.04), 0px 3px 2px rgba(23, 43, 77, 0.08)`,
				}}
			>
				<div className="flex items-center justify-between p-4">
					<div className="flex items-center justify-start">
						<Button
							type="link"
							icon={<ArrowLeftOutlined />}
							className="flex items-center text-white"
							onClick={() => 
								{
								history.push({
									pathname: '/reviews',
									state: { detail: 'campaign' }
								});
								//history.push(`/reviews`)
								}
							}
						>
							Back
						</Button>
					</div>
					<div className="flex items-center justify-end">
						<Button
							type="text"
							className="flex items-center text-white border-white rounded mr-4"
							disabled={props.step === 0}
							onClick={() => props.setStep(props.step - 1)}
						>
							Previous
						</Button>
						<StyledButton
							variant="outlined"
							style={{ marginRight: 16 }}
							onClick={() => props.setStep(props.step + 1)}
							disabled={props.step === 1}
						>
							Next
						</StyledButton>
						<DangerButton
							variant="outlined"
							onClick={() => 
								{
									history.push({
										pathname: '/reviews',
										state: { detail: 'campaign' }
									});
									//history.push(`/reviews`)
									}
							}
						>
							Close
						</DangerButton>
					</div>
				</div>
			</AppBar>
		</div>
	);
}

export default CampaignToolbar;
