import React from "react";
import { Waterfall } from "@ant-design/charts";
import { Skeleton } from "antd";

const PaymentWaterfall = (props) => {
  //   const data = Object.entries(props?.data).map(([key, value]) => ({
  //     type: key,
  //     amount: value,
  //   }));
  const data = [
    {
      type: "Invoice Price",
      amount: props?.data?.invoice_fees,
    },
    {
      type: "MarketPlace Fee",
      amount: props?.data?.marketplace_fees,
    },
    {
      type: "Return Fee",
      amount: props?.data?.return_fees,
    },
    {
      type: "Shipping Fee",
      amount: props?.data?.shipping_fees,
    },
    {
      type: "TCS",
      amount: props?.data?.tcs_fees,
    },
  ];

  const config = {
    data: data,
    xField: "type",
    yField: "amount",
    risingFill: "#30bf78",
    fallingFill: " #f4664a",
    appendPadding: [15, 0, 0, 0],
    meta: {
      type: {
        alias: "type",
      },
      amount: {
        alias: "Amount",
        formatter: (v) =>
          `${v?.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          })}`,
      },
    },
    label: {
      style: {
        fontSize: 14,
        fill: "rgba(0,0,0,0.65)",
      },
      layout: [
        {
          type: "interval-adjust-position",
        },
      ],
    },
    total: {
        label: 'Total',
        style: {
          fill: '#96a6a6',
        },
      },

    // total: false,
  };
  return props.isLoading ? <Skeleton /> : <Waterfall {...config} />;
};

export default PaymentWaterfall;
