import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ".././App.css";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: "#171725",
    fontFamily: 'Inter',
    marginTop: 28
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    marginTop: 11,
    lineHeight: '22px'
  },
  poweredBy: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    color: "#425466",
    marginTop: 20
  }
}));

function CustomFormFooter(props) {
  const classes = useStyles();

  return (
    <>
      <div>
        <Typography className={classes.title}>{props.title}</Typography>
        <Typography className={classes.subtitle}>{props.subtitle}</Typography>
      </div>
    </>
  );
}

export default CustomFormFooter;
