import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ChannelStatusDialog(props) {
  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{props.dialogTitleText}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.dialogContentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.secondaryButtonText && (
            <Button onClick={props.onSecondaryClick} color="primary">
              {props.secondaryButtonText}
            </Button>
          )}
          {props.primaryButtonText && (
            <Button onClick={props.onPrimaryClick} color="primary" autoFocus>
              {props.primaryButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
