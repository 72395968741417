import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../../../App.css";
import CustomAppBar from "../../../components/CustomAppBar.js";
import CustomFilterHeader from "../../../components/CustomFilterHeader";
import Tag from "../../../components/Styles/Tags";
import ApiWrapper from "../../../utils/ApiWrapper";
import MappedSkUs from "./MappedSKUs";
import UnMappedSkUs from "./UnMappedSKUs";

const CustomTabs = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
  },
  indicator: {
    backgroundColor: "#2684FF",
    width: "auto",
  },
})(Tabs);

const RefColumns = [
  {
    title: "Sno",
    field: "Sno",
    width: 100,
    render: (record, rowIndex) => rowIndex,
  },
  {
    title: "Master SKU",
    field: "masterSKU",
    searchPriority: 2,
    onSearch: (searchText) => (data) =>
      (data || "").toLowerCase().includes(searchText.toLowerCase()),
  },
  {
    title: "SKU Code",
    field: "sku",
    searchPriority: 1,
    onSearch: (searchText) => (data) =>
      (data || "").toLowerCase().includes(searchText.toLowerCase()),
    render: (record, rowIndex, isLoading) => record.sku || "-",
    defaultSortOrder: "descend",
    sorter: (a, b) => {
      a = (a.sku || "").toLowerCase();
      b = (b.sku || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    },
    sortDirections: ["descend", "ascend", "descend"],
  },
  {
    title: "Product ID",
    field: "productId",
    searchPriority: 3,
    onSearch: (searchText) => (data) =>
      (data || "").toLowerCase().includes(searchText.toLowerCase()),
    render: (record, rowIndex, isLoading) => record.productId || "-",
    sorter: (a, b) => {
      a = (a.productId || "").toLowerCase();
      b = (b.productId || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    },
    sortDirections: ["descend", "ascend", "descend"],
  },
  {
    title: "Product Name",
    field: "name",
    tooltip: true,
    searchPriority: 4,
    onSearch: (searchText) => (data) =>
      (data || "").toLowerCase().includes(searchText.toLowerCase()),
    render: (record, rowIndex, isLoading) =>
      record.name ? record["name"].replace(/(.{25})..+/, "$1…") : "-",
    sorter: (a, b) => {
      a = (a.name || "").toLowerCase();
      b = (b.name || "").toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    },
    sortDirections: ["descend", "ascend", "descend"],
  },
  {
    title: "Listing Status",
    field: "status",
    render: (record, rowIndex, isLoading) => (
      <Tag
        type={
          (record.status || "").toLowerCase() === "active"
            ? "success"
            : "danger"
        }
      >
        {record.status}
      </Tag>
    ),
  },
];
const CustomTab = withStyles({
  root: {
    fontWeight: "normal",
    fontSize: 14,
    color: "#717480",
    minWidth: "auto",
    "&:focus": {
      outline: "none",
    },
    "&$selected": {
      fontWeight: 500,
      fontSize: 14,
      color: "#2684FF",
    },
    "&:hover": {
      color: "#2684FF",
    },
  },
  selected: {},
})(Tab);

let allSkuList = [];
const MasterSKUMapping = () => {
  const [mapped, setMapped] = useState([]);
  const [unMapped, setUnMapped] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [inventory, setInventory] = useState([]);
  const [selectedMarketplaces, setSelectedMarketplaces] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [columns, setColumns] = useState([]);

  const handleSplit = (inventory) => {
    let mapped = [];
    let unMapped = [];
    inventory.forEach((row) => {
      if (row.masterSKU !== undefined) {
        mapped.push(row);
      } else {
        unMapped.push(row);
      }
    });
    setMapped(mapped);
    setUnMapped(unMapped);
  };

  const onSuccess = (resData, status, marketplace) => {
    let inventoryResponse = [],
      response = { ...resData };
    if (response.Items.length > 0) {
      response.Items.forEach((row) => {
        inventoryResponse.push({
          ...row,
          marketplace: marketplace.name,
        });
      });
      if (!status) {
        allSkuList = [...allSkuList, ...inventoryResponse];
        setInventory([...allSkuList]);
        handleSplit([...allSkuList]);
      } else {
        setInventory([...allSkuList, ...inventoryResponse]);
        handleSplit([...allSkuList, ...inventoryResponse]);
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleMarketplaceChange = (selectedMarketplaces) => {
    setSelectedMarketplaces(selectedMarketplaces);
    allSkuList = [];
    fetchList(selectedMarketplaces);
  };

  const fetchList = async (selectedMarketplaces) => {
    setIsLoading(true);
    setIsDataLoading(true);
    var noOfMarketplaces = selectedMarketplaces.length;
    selectedMarketplaces.map(async (marketplace, marketplaceIndex) => {
      let requestJsonBody = {
        channel: marketplace.name,
        type: "mapping",
      };
      ApiWrapper("getMappedListings", requestJsonBody, null, (respData) => {
        onSuccess(respData, true, marketplace);
      }).then(
        function (response) {
          if ((response.Items || []).length) {
            onSuccess(response, false, marketplace);
          }
          noOfMarketplaces = noOfMarketplaces - 1;
          if (noOfMarketplaces === 0) {
            setIsDataLoading(false);
            allSkuList = [];
            setIsLoading(false);
          }
        },
        function (error) {
          // message.error(
          //   "We encountered an issue. Please log out and log back in. "
          // );
          if (!error instanceof DOMException) {
            noOfMarketplaces = noOfMarketplaces - 1;
            if (noOfMarketplaces === 0) {
              setIsLoading(false);
              setIsDataLoading(false);
              allSkuList = [];
            }
          }
        }
      );
    });
    if (!selectedMarketplaces.length) {
      setMapped([]);
      setUnMapped([]);
      setIsDataLoading(false);
      setIsLoading(false);
      allSkuList = [];
    }
  };

  const refreshList = () => {
    fetchList(selectedMarketplaces);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <div>
        <CustomAppBar />
        <CustomFilterHeader
          headerTitle="Master SKU Mapping"
          handleMarketplaceChange={handleMarketplaceChange}
          showDateRanges={false}
          showManualSync={false}
          isLoading={isDataLoading}
          rows={inventory}
          columns={columns}
          searchHide={true}
          onSearchTextEntered={handleSearch}
          onFilterUpdate={(data) => {
            handleSplit(data);
          }}
          exportFileName="masterSKUMapping"
        />
        <div style={{ marginLeft: 20, marginRight: 20, marginTop: 22 }}>
          <CustomTabs
            value={tab}
            onChange={handleChange}
            aria-label="master SKU Mapping"
          >
            <CustomTab label="Unmapped" />
            <CustomTab label="Mapped" />
          </CustomTabs>
          {tab === 0 && (
            <UnMappedSkUs
              activeTab={tab}
              dataSource={unMapped}
              isLoading={isLoading}
              isDataLoading={isDataLoading}
              refreshList={refreshList}
              searchText={searchText}
              setColumn={(unmappedCols) => {
                setColumns(unmappedCols);
              }}
            />
          )}
          {tab === 1 && (
            <MappedSkUs
              activeTab={tab}
              dataSource={mapped}
              isLoading={isLoading}
              isDataLoading={isDataLoading}
              refreshList={refreshList}
              searchText={searchText}
              setColumn={(mappedCols) => {
                setColumns(mappedCols);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MasterSKUMapping;
