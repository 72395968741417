import { Typography } from "@material-ui/core";
import { makeStyles, } from "@material-ui/core/styles";
import { Rate } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";
import "../../.././App.css";
import AntTable from "../../../components/AntTableV2/index.js";
import User from "../../../images/user.png";
import "../../../Styles/review.css";

const useStyles = makeStyles((theme) => ({
  customerName: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    textAlign: "left",
    paddingLeft: 8,
  },
  customerReviewInfo: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
    paddingLeft: 8,
  },
  customerReviewText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
  },
  skuLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: "#717480",
    fontFamily: "inter",
    display: "inline-block",
  },
  skuValue: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    display: "inline-block",
  },
  productName: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
  },
  markReceived: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
  },
  noLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
  },
  yesLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
  },
}));


function SellerReviewsTab(props) {
    const classes = useStyles();

  const columns = [
    {
      title: "Reviewer",
      field: "sorting_date_2",
      align: "center",
      width: "18%",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-image" />
          </div>
        ) : (
          <>
             <div
            className={`${
              record.channel === "Amazon-India"
                ? "amazon-logo-small"
                : record.channel === "Flipkart"
                ? "flipkart-logo-small"
                : record.channel ==="Meesho"
                ? "meesho-logo-small"
                : null
            }`}
          />
          <div className="flex items-center justify-start">
            <div>
              <img
                style={{
                  backgroundColor: "#2684FF",
                  borderRadius: "55px",
                  padding: "12px",
                }}
                src={User}
                alt="User icon"
                className="mr-2"
              />
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="mb-2">
                <Typography className={classes.customerName}>
                  {record.user_name}
                </Typography>
              </div>
              <div>
                <Typography className={classes.customerReviewInfo}>
                  {/* {record.user_location !== undefined ? (
                    <div>{record.user_location} </div>
                  ) : (
                    "-"
                  )} */}
                  <div>
                    {record.review_date_2}
                  </div>
                </Typography>
              </div>
            </div>
          </div>
          </>
        ),
      sorter: (a, b) =>
        new Date(a.review_date_2) - new Date(b.review_date_2),
    },
    {
      title: "Review",
      field: "reviews",
      align: "center",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <div>
            <div style={{ textAlign: "left", marginBottom: 10 }}>
              <Rate
                defaultValue={record.rating}
                value={record.rating}
                disabled
                count={5}
                allowClear={false}
              />
            </div>
            <div style={{ maxWidth: 400 }}>
              <Typography className={classes.customerReviewText}>
                {record.reviews?.replace(/(.{200})..+/, "$1…")}
              </Typography>
            </div>
          </div>
        ),
    }
  ];
  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
  }) => {
    props.tableFilterData(paginationCondition, sortCondition, filterCondition);
  };
  return (
    <>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          columns={columns}
          dataSource={props.allSellerReviewData}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={props.isLoading}
          totalRecords={props.pageCount}
          hideSummary
          stickyHeader={{ offsetHeader: 65 }}
          onDataChange={onTableChange}
        />
      </div>
    </>
  );
}

export default withRouter(SellerReviewsTab);
