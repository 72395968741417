import { Today } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tabs from "../../components/Tabs";
import { message, Spin } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader.js";
import ExportButton from "../../components/Styles/ExportButton.js";
import { PostValues } from "../../utils/api-client";
import { DATE_FORMAT } from "../../utils/data.js";
import Leaks from "./LeaksV2";
import OrdersTable from "./OrdersTableV2";

const TabsList = ["Summary", "Settled Orders", "Unsettled Orders"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Payments({ history, location }) {
  const [actualPayments, setActualPayments] = useState([]);
  const [estimatedPayments, setEstimatedPayments] = useState([]);
  const [filteredActualPayments, setFilteredActualPayments] = useState([]);
  const [filteredEstimatedPayments, setFilteredEstimatedPayments] = useState(
    []
  );
  const [tabValue, setTabValue] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [columns, setColumns] = useState([]);

  const [dashboardPayment, setDashboardPayment] = useState([]);
  const [bankStatementData, setBankStatementData] = useState([]);
  const [paymentloader, paymentDashboardloader] = useState(false);
  const [marketPlaces, setMarketPlaces] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [requestData, setRequestData] = React.useState({});
  const [leaksColumn, setLeaksColumn] = React.useState(null);
  const [settledOrdersTotal, setSettledOrdersTotal] = React.useState({});
  const [unSettledOrdersTotal, setUnSettledOrdersTotal] = React.useState({});
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const [waterFallData, setWaterFallData] = React.useState({});
  const [orderTableLoading, setOrderTableLoading] = React.useState();

  const handleManualSyncClick = (event) => {};
  const onActualPaymentSuccess = (resData) => {
    return resData.map((row) => {
      let record = { ...row };
      if (record.InvoicePrice) {
        record.InvoicePrice.total = parseInt(row.InvoicePrice.total);
        record.InvoicePrice.Principal =
          row.InvoicePrice.breakup[0]?.Principal?.CurrencyAmount || "-";
        record.InvoicePrice.Tax =
          row.InvoicePrice.breakup[1]?.Tax?.CurrencyAmount || "-";
        record.InvoicePrice.ShippingCharge =
          row.InvoicePrice.breakup[2]?.ShippingCharge?.CurrencyAmount || "-";
        record.InvoicePrice.ShippingTax =
          row.InvoicePrice.breakup[3]?.ShippingTax?.CurrencyAmount || "-";
        record.InvoicePrice.Promotion1 =
          row.InvoicePrice.breakup[4]?.Promotion?.CurrencyAmount || "-";
        record.InvoicePrice.Promotion2 =
          row.InvoicePrice.breakup[5]?.Promotion?.CurrencyAmount || "-";
        record.InvoicePrice.Promotion3 =
          row.InvoicePrice.breakup[6]?.Promotion?.CurrencyAmount || "-";
        record.InvoicePrice.Promotion4 =
          row.InvoicePrice.breakup[7]?.Promotion?.CurrencyAmount || "-";
      }
      if (record.MarketplaceFees) {
        record.MarketplaceFees.FBAWeightBasedFee =
          row.MarketplaceFees?.breakup[0]?.FBAWeightBasedFee?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.TechnologyFee =
          row.MarketplaceFees?.breakup[1]?.TechnologyFee?.CurrencyAmount || "-";
        record.MarketplaceFees.Commission =
          row.MarketplaceFees?.breakup[2]?.Commission?.CurrencyAmount ||
          row.MarketplaceFees?.breakup[1]?.Commission?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.FixedClosingFee =
          row.MarketplaceFees?.breakup[3]?.FixedClosingFee?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.ShippingChargeback =
          row.MarketplaceFees?.breakup[4]?.ShippingChargeback?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.FixedFee =
          row.MarketplaceFees?.breakup[2]?.["Fixed Fee"]?.CurrencyAmount || "-";
        record.MarketplaceFees.CollectionFee =
          row.MarketplaceFees?.breakup[1]?.["Collection Fee"]?.CurrencyAmount ||
          "-";
        record.MarketplaceFees.Taxes =
          row.MarketplaceFees?.breakup[0]?.Taxes?.CurrencyAmount || "-";
      }
      if (record.TCS) {
        record.TCS.TCS_IGST =
          row.TCS?.breakup[0]?.["TCS-IGST"]?.CurrencyAmount || "-";
      }
      record.OrderDate =
        row.OrderDate && row.OrderDate !== "-"
          ? moment(new Date(row.OrderDate)).format(DATE_FORMAT)
          : "-";
      record.SettledDate =
        row.SettledDate && row.SettledDate !== "-"
          ? moment(new Date(row.SettledDate)).format(DATE_FORMAT)
          : "-";
      record.OrderDetailStatus = row.ReturnDetails?.OrderStatus
        ? row.ReturnDetails?.OrderStatus
        : row.OrderStatus || "";
      return record;
    });
  };

  const onEstimatedPaymentSuccess = (resData, status) => {
    return resData.map((row) => {
      let record = { ...row };
      record.FSOrderStatus = row.order_status;
      record.invoice = record?.invoice;
      record.Marketplace = record?.Marketplace;
      record.tcs = record?.tcs;
      record.ShippingFee = record?.ShippingFees;
      record.settled_amount = record?.settled_amount;
      record.order_date =
        row.order_date && row.order_date !== "-"
          ? moment(new Date(row.order_date)).format(DATE_FORMAT)
          : "-";
      return record;
    });
  };
  const handleMarketplaceChange = async (
    selectedMarketplaces,
    fromDate,
    toDate
  ) => {
    setMarketPlaces(selectedMarketplaces);
    setFromDate(fromDate);
    setToDate(toDate);
    getPayments(selectedMarketplaces, fromDate, toDate, searchText);
  };

  const onSearchTextEntered = (searchText) => {
    setSearchText(searchText);
  };
  const getOrders = async (requestData) => {
    let response = await PostValues("getOrdersV3", requestData);
    return response;
  };

  const getUnSettledOrders = async (requestData) => {
    let response = await PostValues("unSettledOrders", requestData);
    return response;
  };

  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };

  const onSuccess = (result) => {
    setDashboardPayment([result[0]?.data]);
    setBankStatementData(result[1]);
    setWaterFallData(result[2]?.data);
    setIsLoading(false);
  };
  const getPayments = async (
    selectedMarketplaces,
    fromDate,
    toDate,
    searchText,
    paginationCond,
    sortCond,
    filterCond = {}
  ) => {
    let requestData = {
      channels: selectedMarketplaces.map((mp) => mp.name),
      filters: {
        fromDate: fromDate,
        toDate: toDate,
        ...filterCond,
      },
      ...(paginationCond
        ? paginationCond
        : {
            pagination: {
              pageNo: 0,
              pageSize: 25,
            },
          }),
      ...(sortCond
        ? sortCond
        : leaksColumn === "orders" && tabValue === 1
        ? { sort: { OrderID: -1 } }
        : {}),
    };
    if (searchText) {
      requestData.filters["search"] = searchText;
    }
    setRequestData(requestData);
    if (tabValue === 0) {
      setIsLoading(true);
      Promise.all([
        PostValues("leaksPayment", requestData),
        PostValues("bankStatement", requestData),
        PostValues("payment_dashboard_cards", requestData),
      ])
        .then(function (responses) {
          return Promise.all(
            responses.map(function (response) {
              return response;
            })
          );
        })
        .then(function (result) {
          onSuccess(result);
        })
        .catch(function (error) {});
    }

    if (tabValue === 1) {
      setIsLoading(true);
      requestData.filters["SettledAmount"] = { ISNULL: false };
      requestData.sum = [
        "QuantityShipped",
        "OrderTotal.Amount",
        "SettledAmount",
        "TCS.total",
        "InvoicePrice.total",
        "MarketplaceFees.total",
        "ShippingFees.total",
        "ReturnPrice.total",
        "bank_settle_amount",
      ];
      if (leaksColumn) {
        if (leaksColumn === "orders") {
          let settlementId = bankStatementData?.clickable.map((val) => {
            return val.settlement_id;
          });
          requestData.filters["Settlement_ids"] = {
            ARRAY_INTERSECT: settlementId,
          };
          // requestData.filters["SettledAmount"] = { ISNULL: false };
          // requestData.filters["OrderDate"] = { ISNULL: false };
          requestData.filters.fromDate = null;
          requestData.filters.toDate = null;
        } else if (leaksColumn === "bank_variance") {
          requestData.filters[leaksColumn] = { ISNULL: false, NE: 0 };
        } else if (leaksColumn === "bank_settle_amount") {
          requestData.filters[leaksColumn] = { ISNULL: true };
        } else {
          requestData.filters[leaksColumn] = { ISNULL: false, LT: "0" };
        }
      }
      let data = await getOrders(requestData);
      if (data.orders) {
        var actualOrders = await onActualPaymentSuccess(data.orders);
        setActualPayments([...actualOrders]);
        setFilteredActualPayments([...actualOrders]);
        setTotalRecords(data.pagination.count);
        setSettledOrdersTotal(data.total);
      }
      setIsLoading(false);
    }
    if (tabValue === 2) {
      setIsLoading(true);
      let data = await getUnSettledOrders(requestData);
      if (data.orders) {
        var estimateOrders = await onEstimatedPaymentSuccess(data.orders);
        setEstimatedPayments([...estimateOrders]);
        setFilteredEstimatedPayments([...estimateOrders]);
        setTotalRecords(data?.pagination?.count);
        setUnSettledOrdersTotal(data.total[0]);
      }
      setIsLoading(false);
    }
  };

  const filterTabvalue = async (data) => {
    setLeaksColumn(data);
    if (data !== "unsettledPayment") {
      setTabValue(1);
    } else {
      setTabValue(2);
    }
  };

  const ExportAllData = async () => {
    try {
      setIsExportLoading(true);
      let exportJsonBody = {
        params: {
          channels: marketPlaces.map((mp) => mp.name),
          ...(leaksColumn !== "orders" && {
            fromDate: fromDate,
            toDate: toDate,
          }),
        },
        reportType:
           tabValue === 0 ? "variance"  : tabValue === 1 ? "payments" : tabValue === 2 ? "unsettled" : "",
      };
      if (tabValue === 1) {
        if (leaksColumn) {
          if (leaksColumn === "orders") {
            let settlementId = bankStatementData?.clickable.map((val) => {
              return val.settlement_id;
            });
            exportJsonBody.params["Settlement_ids"] = {
              ARRAY_INTERSECT: settlementId,
            };
            // exportJsonBody.params["OrderDate"] = { ISNULL: false };
            // exportJsonBody.params.fromDate = null;
            // exportJsonBody.params.toDate = null;
          } else if (leaksColumn === "bank_variance") {
            exportJsonBody.params[leaksColumn] = { ISNULL: false, NE: 0 };
          } else if (leaksColumn === "bank_settle_amount") {
            exportJsonBody.params[leaksColumn] = { ISNULL: true };
          } else {
            exportJsonBody.params[leaksColumn] = { ISNULL: false, LT: "0" };
          }
        }
        exportJsonBody.params["SettledAmount"] = { ISNULL: false };
      }
      const data = await getExportApi(exportJsonBody);
      if (data.status) {
        setIsExportLoading(false);
        message.success(
          "The export is being processed, please check the Downloads page ",
          10
        );
      } else {
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  React.useEffect(() => {
    if (marketPlaces.length > 0) {
      getPayments(marketPlaces, fromDate, toDate);
      if (tabValue !== 1) {
        setLeaksColumn(null);
      }
    }
  }, [tabValue]);
  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <CustomAppBar />
      <CustomFilterHeader
        headerTitle="Payment Tracker"
        handleManualSyncClick={handleManualSyncClick}
        handleMarketplaceChange={handleMarketplaceChange}
        onSearchTextEntered={onSearchTextEntered}
        searchFunction={(ds, searchText) => {
          getPayments(marketPlaces, fromDate, toDate, searchText.trim());
        }}
        showExportButton={false}
        getExportData={ExportAllData}
        columns={columns}
        searchHide={tabValue === 0 ? false : true}
        isLoading={isLoading}
        showManualSync={false}
        manualSyncModule={"PAYMENTS"}
        exportFileName="PaymentTracker_unsettledOrders"
        paymentContent={true}
        rows={
          tabValue === 1
            ? actualPayments
            : tabValue === 2
            ? estimatedPayments
            : []
        }
        customToggleProfit={
          (
            <>
            <Spin spinning={isExportLoading}>
              <div className="ml-2">
                <ExportButton variant="outlined" onClick={ExportAllData}>
                 {`${tabValue === 0 ? "Leaks Report" : " Export To CSV"}`}
                </ExportButton>
              </div>
            </Spin>
            </>
          )
        }
        onFilterUpdate={(data) => {
          if (tabValue === 1) {
            paymentDashboardloader(false);
            setFilteredActualPayments(data || []);
          } else if (tabValue === 2) {
            paymentDashboardloader(false);
            setFilteredEstimatedPayments(data || []);
          }
        }}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <Tabs
          value={tabValue}
          list={TabsList}
          onChange={(value) => {
            setTabValue(value);
          }}
        />
      </div>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <TabPanel value={tabValue} index={0}>
          <Leaks
            paymentloader={paymentloader}
            data={dashboardPayment}
            filterTabvalue={filterTabvalue}
            bankStatementData={bankStatementData}
            chartData={waterFallData}
            orderTableLoading={orderTableLoading}
            isLoading={isLoading}
            activeTab={`${tabValue}`}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <OrdersTable
            data={filteredActualPayments}
            pageCount={totalRecords}
            activeTab={`${tabValue}`}
            setColumn={(col) => {
              setColumns(col);
            }}
            settledOrdersTotal={settledOrdersTotal}
            setData={(data) => {
              setFilteredActualPayments(data || []);
            }}
            isLoading={isLoading}
            searchText={searchText}
            tableFilterData={(paginationCondition, sortCond, filterCond) => {
              getPayments(
                marketPlaces,
                fromDate,
                toDate,
                searchText,
                paginationCondition,
                sortCond,
                filterCond
              );
            }}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <OrdersTable
            data={filteredEstimatedPayments}
            setData={(data) => {
              setFilteredEstimatedPayments(data || []);
            }}
            pageCount={totalRecords}
            activeTab={`${tabValue}`}
            setColumn={(col) => {
              setColumns(col);
            }}
            unSettledOrdersTotal={unSettledOrdersTotal}
            isLoading={isLoading}
            searchText={searchText}
            tableFilterData={(paginationCondition, sortCond, filterCond) => {
              getPayments(
                marketPlaces,
                fromDate,
                toDate,
                searchText,
                paginationCondition,
                sortCond,
                filterCond
              );
            }}
          />
        </TabPanel>
      </div>
    </>
  );
}
