import { Button, Card, Table } from "antd";
import React from "react";
import {
  AdvertisementColumn,
  ReserveAdjustments,
  OtherAdjsutments,
  Remittance_amount,
  TotalDiffAmount,
} from "./bankSettlementColumns";
import BankSettlementModel from "./bankstatementModel";
import PaymentWaterfall from "./waterFallChart";

function Leaks(props) {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [activeTableData, setActiveTableData] = React.useState("");
  const [activeTableTitle, setActiveTableTitle] = React.useState("");
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (record, row, index) =>
        props.isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <div>
            {`${record}`}
            {record === "Orders Paid" && (
              <span
                className="ml-20"
                onClick={() => showExpandedModal(row, true)}
              >
                Variance
                <Button type="link">
                  <div className="font-bold  hover:underline">
                    {row.variance?.toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                      style: "currency",
                      currency: "INR",
                    })}
                  </div>
                </Button>
              </span>
            )}
          </div>
        ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      width: 120,
      render: (record, rowIndex, isLoading) =>
        props.isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <Button type="link">
            <div className="font-bold  hover:underline">
              {record?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </div>
          </Button>
        ),
      onCell: (record, rowIndex) => {
        return {
          onClick: () =>
            record.key !== "variance_amt"
              ? showExpandedModal(record, true)
              : showExpandedModal(record, false),
        };
      },
    },
  ];
  const dataSource = [
    {
      key: "remittance",
      name: "Remittance Amount",
      amount: props?.bankStatementData?.statement?.remittance,
      variance: null,
      isClick: true,
    },
    {
      key_redirect: "settled_orders",
      key: "variance_amt",
      name: "Orders Paid",
      amount: props?.bankStatementData?.statement?.settled_orders,
      variance: props?.bankStatementData?.statement?.total_diff,
      isClick: true,
    },
    {
      key: "ads_cost",
      name: "Advertisement Cost",
      amount: props?.bankStatementData?.statement?.ads_cost,
      variance: null,
      isClick: true,
    },
    {
      key: "reserve_adj",
      name: "Reserve Adjustment",
      amount: props?.bankStatementData?.statement?.reserve_adj,
      variance: null,
      isClick: true,
    },
    {
      key: "other_adj",
      name: "Other Adjustment",
      amount: props?.bankStatementData?.statement?.other_adj,
      variance: null,
      isClick: true,
    },
    // {
    //   key: "TotalDiff",
    //   name: "Variance",
    //   amount: props?.bankStatementData?.statement?.total_diff,
    //   isClick: true,
    // },
    // {
    //   key: "profit",
    //   name: "Profit",
    //   amount: props?.bankStatementData?.statement?.remittance + (props?.bankStatementData?.statement?.total_cost * -1),
    //   isClick: false,
    // },
  ];
  const setTabValue = (val) => {
    if (props.isLoading) return;
    else props.filterTabvalue(val);
  };
  const hideCtaModal = (data) => {
    setIsModalVisible(false);
  };
  const showExpandedModal = (record, isPopupOpen) => {
    if (record.key_redirect === "settled_orders" && !isPopupOpen) {
      props.filterTabvalue("orders");
    } else {
      setIsModalVisible(true);
      setActiveTableData(record.key);
      setActiveTableTitle(record.name);
    }
  };
  var data = [
    {
      type: "a",
      value: props.data[0]?.amazon[0].commission_problem_order,
    },
    {
      type: "b",
      value: props.data[0]?.amazon[0].fbaweightbasefee_problem_order,
    },
  ];
  return (
    <>
      <div className="mt-5">
        <div
          className={`${
            props.isLoading ? "cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <div className="grid grid-cols-6 gap-4 mt-5">
            {[
              {
                title: "Commission Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.commission_problem_order +
                  props.data[0]?.flipkart[0]?.commission_problem_order,
                estimate:
                  props.data[0]?.amazon[0]?.commission_fee_estimated_variance +
                  props.data[0]?.flipkart[0]?.commission_fee_estimated_variance,
                tabData: "commission",
                color: "#dceff5",
              },
              {
                title: "FBA Weight Base Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.fbaweightbasefee_problem_order +
                  props.data[0]?.flipkart[0]?.fbaweightbasefee_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.fbaweightbasefee_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.fbaweightbasefee_fee_estimated_variance,
                tabData: "fbaweightbasefee",
                color: "rgb(242, 248, 240)",
              },
              {
                title: "Technology Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.technologyfee_problem_order +
                  props.data[0]?.flipkart[0]?.technologyfee_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.technologyfee_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.technologyfee_fee_estimated_variance,
                tabData: "technologyfee",
                color: "#dce3f2",
              },
              {
                title: "Fixed Close Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.fixedclosefee_problem_order +
                  props.data[0]?.flipkart[0]?.fixedclosefee_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.fixedclosefee_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.fixedclosefee_fee_estimated_variance,
                tabData: "fixedclosefee",
                color: "#f1dcf2",
              },
              {
                title: "Refund Commision",
                ordersCount:
                  props.data[0]?.amazon[0]?.refund_comm_problem_order +
                  props.data[0]?.flipkart[0]?.refund_comm_problem_order,
                estimate:
                  props.data[0]?.amazon[0]?.refund_comm_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.refund_comm_fee_estimated_variance,
                tabData: "refund_comm",
                color: "#fff9e3",
              },
              {
                title: "Fixed Fees",
                ordersCount:
                  props.data[0]?.amazon[0]?.fixed_problem_order +
                  props.data[0]?.flipkart[0]?.fixed_problem_order,
                estimate:
                  props.data[0]?.amazon[0]?.fixed_fee_estimated_variance +
                  props.data[0]?.flipkart[0]?.fixed_fee_estimated_variance,
                tabData: "fixed_fee",
                color: "#f5f5f5",
              },
              {
                title: "Collection Fees",
                ordersCount:
                  props.data[0]?.amazon[0]?.collection_problem_order +
                  props.data[0]?.flipkart[0]?.collection_problem_order,
                estimate:
                  props.data[0]?.amazon[0]?.collection_fee_estimated_variance +
                  props.data[0]?.flipkart[0]?.collection_fee_estimated_variance,
                tabData: "collection_fee",
                color: "rgb(234, 230, 255)",
              },
              {
                title: "Shipping Fees",
                ordersCount:
                  props.data[0]?.amazon[0]?.shipping_var_problem_order +
                  props.data[0]?.flipkart[0]?.shipping_var_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.shipping_var_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.shipping_var_fee_estimated_variance,
                tabData: "shipping_var",
                color: "#cdcdcd",
              },
              {
                title: "Pick & Pack Fee",
                ordersCount:
                  props.data[0]?.amazon[0]?.pick_and_pack_problem_order +
                  props.data[0]?.flipkart[0]?.pick_and_pack_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.pick_and_pack_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.pick_and_pack_fee_estimated_variance,
                tabData: "pick_and_pack",
                color: "#e0f5c1",
              },
              {
                title: "Refunded Fees",
                ordersCount:
                  props.data[0]?.amazon[0]?.refund_fees_problem_order +
                  props.data[0]?.flipkart[0]?.refund_fees_problem_order,
                estimate:
                  props.data[0]?.amazon[0]?.refund_fees_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.refund_fees_fee_estimated_variance,
                tabData: "refund_fees",
                color: "#f2e2dc",
              },
              {
                title: "Refund Check",
                ordersCount:
                  props.data[0]?.amazon[0]?.refund_check_problem_order +
                  props.data[0]?.flipkart[0]?.refund_check_problem_order,
                estimate:
                  props.data[0]?.amazon[0]
                    ?.refund_check_fee_estimated_variance +
                  props.data[0]?.flipkart[0]
                    ?.refund_check_fee_estimated_variance,
                tabData: "refund_check",
                color: "#ffc9bd",
              },
              {
                title: "Missing Pincode",
                ordersCount:
                  props.chartData?.missing_pincodes !== undefined
                    ? props.chartData?.missing_pincodes[0]?.center_id
                    : 0,
                estimate:
                  props.chartData?.missing_pincodes !== undefined
                    ? props.chartData?.missing_pincodes[0]?.missing_pincodes
                    : 0,
                tabData: "missingPincode",
                isMissingPincode: true,
                color: "#ccdeff",
              },
            ].map((fee, feeIndex) => {
              return (
                <Card
                  bgcolor="white"
                  bodyStyle={{
                    backgroundColor: `${fee.color}`,
                    border: "1px solid black",
                    borderRadius: "10px",
                  }}
                  className={`relative tablet:w-48 mobile:w-45`}
                  onClick={() =>
                    fee.tabData !== "missingPincode"
                      ? setTabValue(fee.tabData)
                      : ""
                  }
                  key={`Fee-${feeIndex}`}
                >
                  <div className="flex justify-center">
                    <div
                      className="absolute rounded-lg"
                      style={{
                        background: fee.color,
                        padding: "2px 16px",
                        top: "-11px",
                        border: "1px dotted black",
                      }}
                    >
                      {fee.title}
                    </div>
                  </div>

                  {props.isLoading ? (
                    <div className="item ">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <p className="text-center mt-2 font-bold font-32">
                      {fee.ordersCount || "0"}
                    </p>
                  )}

                  <p className="text-center text-base font-medium">
                    {fee.isMissingPincode
                      ? "Center Id"
                      : "No. of problem orders"}
                  </p>
                  {props.isLoading ? (
                    <div className="item">
                      <div className="item-title" />
                    </div>
                  ) : (
                    <p className="text-center text-2xl font-semibold mt-2">
                      {new Intl.NumberFormat(
                        "en-IN",
                        !fee.isMissingPincode && {
                          style: "currency",
                          currency: "INR",
                        }
                      ).format(fee.estimate || "0")}
                    </p>
                  )}

                  <p className="text-center text-base font-medium mb-7">
                    {fee.isMissingPincode
                      ? "Missing Pincode"
                      : "Estimate Variance"}
                  </p>
                </Card>
              );
            })}
          </div>
        </div>
        <div className="w-full flex flex-wrap">
          <div className="w-1/2">
            <Card
              bgcolor="white"
              className="relative rounded-lg border-black mt-5"
            >
              <div className=" w-full ">
                <div className="flex justify-start">
                  <div
                    className="absolute rounded-lg"
                    style={{
                      background: "white",
                      padding: "2px 16px",
                      top: "-16px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {"Bank Transfer WorkFlow"}
                  </div>
                </div>
                <Table
                  columns={columns}
                  bordered={true}
                  size="small"
                  rowClassName={(record, index) =>
                    `${
                      index === 0
                        ? "font-bold cursor-pointer"
                        : record.isClick
                        ? "font-medium cursor-pointer"
                        : "font-medium"
                    }`
                  }
                  // style={{border: "1px solid black"}}
                  dataSource={dataSource}
                  pagination={false}
                  showHeader={false}
                  // onRow={(record) =>
                  //   !props.isLoading && {
                  //     onClick: (event) =>
                  //       record.isClick ? showExpandedModal(record) : "",
                  //   }
                  // }
                  summary={() => (
                    <span className="italic">
                      * Based on Bank Deposited Date
                    </span>
                  )}
                />
              </div>
            </Card>
          </div>
          <div className="w-48 h-200 ml-6">
            <Card
              bgcolor="white"
              className="relative rounded-lg border-black mt-5"
            >
              <div className="mt-5 w-full">
                <div className="flex justify-start">
                  <div
                    className="absolute rounded-lg"
                    style={{
                      background: "white",
                      padding: "2px 16px",
                      top: "-16px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {"CashFlow Planning"}
                  </div>
                </div>
                <div
                  className={`${
                    props.isLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                >
                  <div className="grid grid-cols-3 gap-6 -mt-2 pb-12 ">
                    {[
                      {
                        title: "UnSettled Not Paid",
                        ordersCount:
                          props.data[0]?.amazon[0]?.unsettled_orders +
                          props.data[0]?.flipkart[0]?.unsettled_orders,
                        estimate:
                          props.data[0]?.amazon[0]?.unsettled_amount +
                          props.data[0]?.flipkart[0]?.unsettled_amount,
                        tabData: "unsettledPayment",
                        isUnsettled: true,
                        bgColor: "#f7ebdf",
                      },
                      {
                        title: "Settled Not Paid",
                        ordersCount:
                          props?.bankStatementData.settled_variance !==
                          undefined
                            ? props?.bankStatementData?.settled_variance[0]
                                ?.settled_count
                            : 0,
                        estimate:
                          props?.bankStatementData.settled_variance !==
                          undefined
                            ? props?.bankStatementData.settled_variance[0]
                                ?.settlement_amount
                            : 0,
                        tabData: "bank_settle_amount",
                        bgColor: "#f5f5f5",
                        color: "#fbfcd9",
                      },
                      {
                        title: "Settled Adjustment",
                        ordersCount:
                          props?.bankStatementData?.bank_variance !== undefined
                            ? props?.bankStatementData?.bank_variance[0]
                                ?.variance_count
                            : 0,
                        estimate:
                          props?.bankStatementData?.bank_variance !== undefined
                            ? props?.bankStatementData?.bank_variance[0]
                                ?.variance
                            : 0,
                        tabData: "bank_variance",
                        bgColor: "#e4ebf2",
                        color: "#d4cfd3",
                      },
                    ].map((fee, feeIndex) => {
                      return (
                        <Card
                          bgcolor="white"
                          bodyStyle={{
                            backgroundColor: `${fee.bgColor}`,
                            border: "1px solid black",
                            borderRadius: "10px",
                            height: "230px",
                          }}
                          className={`relative tablet:w-48 mobile:w-45`}
                          onClick={() => setTabValue(fee.tabData)}
                          key={`Fee-${feeIndex}`}
                        >
                          <div className="flex justify-center">
                            <div
                              className="absolute rounded-lg"
                              style={{
                                background: fee.bgColor,
                                padding: "2px 16px",
                                top: "-11px",
                                border: "1px dotted black",
                              }}
                            >
                              {fee.title}
                            </div>
                          </div>

                          {props.isLoading ? (
                            <div className="item ">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <p className="text-center mt-5 font-bold font-32">
                              {fee.ordersCount || "0"}
                            </p>
                          )}

                          <p className="text-center text-base font-medium">
                            No of Orders
                          </p>
                          {props.isLoading ? (
                            <div className="item">
                              <div className="item-title" />
                            </div>
                          ) : (
                            <p className="text-center text-2xl font-semibold mt-4">
                              {new Intl.NumberFormat("en-IN", {
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency: "INR",
                              }).format(fee.estimate || "0")}
                            </p>
                          )}

                          <p className="text-center text-base font-medium mb-7">
                            {fee.isUnsettled ? "Estimated Amount" : "Amount"}
                          </p>
                        </Card>
                      );
                    })}
                  </div>
                </div>
              </div>
              <span className="italic ">* Based on Order Date</span>
            </Card>
          </div>
        </div>
        <div className="w-full flex flex-wrap">
          <div className="w-1/2">
            <Card
              bgcolor="white"
              className="relative rounded-lg border-black mt-5"
            >
              <div className="mt-5 w-full">
                <div className="flex justify-start">
                  <div
                    className="absolute rounded-lg"
                    style={{
                      background: "white",
                      padding: "2px 16px",
                      top: "-16px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {"Settled Payment WaterFall Chart"}
                  </div>
                </div>
                <div className="h-80 mt-5">
                  <PaymentWaterfall
                    data={
                      props.chartData?.waterfall !== undefined
                        ? props.chartData?.waterfall[0]
                        : null
                    }
                    isLoading={props.isLoading}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className="w-48 ml-6">
            <Card
              bgcolor="white"
              className="relative rounded-lg border-black mt-5"
            >
              <div className="mt-5 w-full">
                <div className="flex justify-start">
                  <div
                    className="absolute rounded-lg"
                    style={{
                      background: "white",
                      padding: "2px 16px",
                      top: "-16px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {"UnSettled Payment WaterFall Chart"}
                  </div>
                </div>
                <div className="h-80 mt-5">
                  <PaymentWaterfall
                    data={
                      props.chartData?.unsettled_waterfall_res !== undefined
                        ? props.chartData?.unsettled_waterfall_res[0]
                        : null
                    }
                    isLoading={props.isLoading}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <BankSettlementModel
          isModalVisible={isModalVisible}
          orderTableLoading={props?.orderTableLoading}
          columns={
            activeTableData === "remittance"
              ? Remittance_amount
              : activeTableData === "ads_cost"
              ? AdvertisementColumn
              : activeTableData === "other_adj"
              ? OtherAdjsutments
              : activeTableData === "reserve_adj"
              ? ReserveAdjustments
              : activeTableData === "variance_amt"
              ? TotalDiffAmount
              : ""
          }
          data={
            activeTableData === "variance_amt"
              ? props?.bankStatementData?.order_diff
              : props?.bankStatementData?.clickable
          }
          hideCtaModal={hideCtaModal}
          title={activeTableTitle}
        />
      </div>
    </>
  );
}

export default Leaks;
