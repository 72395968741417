import React, { useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";
import { scaleQuantile } from "d3-scale";
import india from "../../assets/topojsons/india.json";
import { Spin } from "antd";
import LinearGradient from "./LinearGradient.js";
import ReactTooltip from 'react-tooltip';

const geographyStyle = {
  default: {
    outline: "none",
  },
  hover: {
    fill: "red",
    transition: "all 250ms",
    outline: "none",
  },
  pressed: {
    outline: "none",
  },
};
const DEFAULT_COLOR = "#D6D6DA"; // Not Ava res;

// Red Variants
const COLOR_RANGE = [
  "#f7fbff",
  "#deebf7",
  "#c6dbef",
  "#9ecae1",
  "#6baed6",
  "#4292c6",
  "#2171b5",
  "#08519c",
  "#08306b",
];

const gradientData = {
  fromColor: COLOR_RANGE[0],
  toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
};
export default function MapChartIndiaFour(props) {
  const [contentState, setContentState] = useState('');
  const [content, setContent] = useState([]);

  const colorScale = scaleQuantile()
    .domain(
      Object.entries(props?.resDataThree?.total_state).map(
        (d) => Math.abs(d?.[1]?.[0]?.SettledShippingSum)
      )
    )
    .range(COLOR_RANGE);

  const onMouseEnter = (geo, current = { count: "NA" }) => {
    if (current[1]) {
      return () => {
        setContent(current[1][0]);
        setContentState(`${geo.properties.st_nm}`);
      };
    } else {
      return () => {
        setContentState(`${geo.properties.st_nm}`);
      };
    }
  };

  const onMouseLeave = () => {
    setContent("");
    setContentState("");
  };
  return (
    <>
      <Spin spinning={props.isLoading}>
        <ComposableMap
          data-tip=""
          projection="geoMercator"
          width={200}
          height={150}
          projectionConfig={{ scale: 240 }}
        >
          <ZoomableGroup zoom={1} center={[80, 22]}>
            <Geographies geography={india}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const current = Object.entries(
                    props?.resDataThree?.total_state
                  ).find((s) => s[0] === geo.properties.st_nm.toUpperCase());
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={onMouseEnter(geo, current)}
                      onMouseLeave={onMouseLeave}
                      onClick={() => {
                        const { st_nm } = geo.properties;
                        props.stateFuncApiCall(st_nm);
                        //   props.setStateName(`${st_nm}`);
                      }}
                      fill={
                        current?.[1]?.[0]?.SettledShippingSum
                          ? colorScale(Math.abs(current?.[1]?.[0]?.SettledShippingSum))
                          : DEFAULT_COLOR
                      }
                      style={geographyStyle}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <div style={{
          position: "absolute",
          right: "100px",
          bottom: "0px",
        }}>

        <LinearGradient data={gradientData} />
        </div>
      </Spin>
      {contentState ? (
        <ReactTooltip>
          <b style={{
            fontSize: "18px"

          }}> {contentState}</b>
          {content.SettledShippingSum ? (
            <>
              <br></br>
              Shipping Cost :  {'₹' + Math.abs(content?.SettledShippingSum.toFixed(0))}
            </>
          ) : null}

        </ReactTooltip>
      ) : null
      }
    </>
  );
}
