import React from 'react';
import clsx from 'clsx';
import {makeStyles, Radio, RadioGroup} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	label: {
		fontWeight: 500,
		fontSize: 14,
		color: '#44444F',
		marginBottom: 12,
	},
	radioRow: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	radioColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	error: {
		minHeight: 28,
		color: "#F2453D"
	}
});

const useRadioStyles = makeStyles({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	label: {
		fontWeight: 500,
		fontSize: 14,
		color: '#000000',
	},
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#2684FF',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#2684FF',
		},
	},
});

function StyledRadio(props) {
	const classes = useRadioStyles();
	
	return (
		<div className={classes.container}>
			<Radio
				className={classes.root}
				disableRipple
				color="default"
				checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
				icon={<span className={classes.icon} />}
				{...props}
			/>
			<span className={classes.label}>{props.label}</span>
		</div>
	);
}

const MaterialRadio = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			{props.label && (
				<div className={classes.label}>{props.label}</div>
			)}
			<RadioGroup
				defaultValue={props.value}
				value={props.value}
				className={props.layout === "horizontal" ? classes.radioRow : classes.radioColumn}
				onChange={props.onChange}
			>
				{props.children}
			</RadioGroup>
			<div className={classes.error}>
				{props.errorMessage}
			</div>
		</div>
	);
}

MaterialRadio.defaultProps = {
	label: null,
	value: "",
	disabled: false,
	layout: 'vertical',
	onChange: (e) => {},
}

MaterialRadio.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	layout: PropTypes.string,
	onChange: PropTypes.func,
}

export { StyledRadio };

export default MaterialRadio;
