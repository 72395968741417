import React, { useState } from "react";
import { PostValues } from "../../utils/api-client";
import { Modal, Select, Input, message } from "antd";

const { Option } = Select;
const { TextArea } = Input;

export default function AddKeywords(props) {
    const [confirmLoadingkeyword, setConfirmLoadingkeyword] = React.useState(false)
    const [isModalVisiblekeyword, setIsModalVisiblekeyword] = useState(false);
    const [areaDatakeyword, setAreaDatakeyword] = React.useState('')
    const [valueTime, setValueTime] = useState('11.00');
    const [errorFiled, seterrorFiled] = React.useState('')
    const timevalues = ['01.00', '01.30', '02.00', '02.30', '03.00', '03.30', '04.00', '04.30', '05.00', '05.30', '06.00', '06.30', '07.00', '07.30', '08.00', '08.30', '09.00', '09.30', '10.00', '10.30', '11.00', '11.30', '12.00', '12.30', '13.00', '13.30', '14.00', '14.30', '15.00', '15.30', '16.00', '16.30', '17.00', '17.30', '18.00', '18.30', '19.00', '19.30', '20.00', '20.30', '21.00', '21.30', '22.00', '22.30', '23.00', '23.30', '24.00', '24.30']

    React.useEffect(async () => {
        if (props.isModalVisible) {
            setIsModalVisiblekeyword(props.isModalVisible)
        }
    }, [props.isModalVisible])
    const addKeywordsApi = async (requestData) => {
        let response = await PostValues("addKeyWordsTrackers", requestData);
        return response;
    };

    const handleOkkeyword = async () => {
        let dataArray = areaDatakeyword.split('\n').filter(e => e.trim().length > 0)
        if (dataArray.length < 25) {
            if (dataArray.length > 0 && valueTime) {
                setConfirmLoadingkeyword(true)
                let reqBody = {
                    "channel": props.rowData.channel,
                    "metric": "keyword",
                    "sync_at": valueTime,
                    "product_id": props.rowData.product_id,
                    "keyword_list": dataArray
                }
                const data = await addKeywordsApi(reqBody);
                if (data.status) {
                    setTimeout(() => {
                        message.success("Keywords Added Successfully.Keywords update will take some time, please refresh the page")
                        props.popupFalse()
                        props.keywordsModalClose()
                        setConfirmLoadingkeyword(false)
                        setIsModalVisiblekeyword(false);
                        setAreaDatakeyword('')
                        props.upadteProduct()
                    },2000)
                } else {
                    setConfirmLoadingkeyword(false)
                    message.success(data.message)
                }
            } else {
                seterrorFiled("Please Enter All Fileds")
            }
        } else {
            setConfirmLoadingkeyword(false)
            message.error('Keyword list less then 25 is allowed');
        }

    };

    const handleCancelkeyword = () => {
        setAreaDatakeyword('')
        props.popupFalse()
        props.keywordsModalClose()
        setIsModalVisiblekeyword(false);
    };

    return (
        <Modal title="Add Keywords" maskClosable={false}
            confirmLoading={confirmLoadingkeyword}
            visible={isModalVisiblekeyword} onOk={handleOkkeyword} onCancel={handleCancelkeyword}>
            <div class="flex justify-between ...">
                <div className="text-xl	px-2 font-medium">{props.rowData.channel === "Amazon-India" ? (
                    <div className="amazon-logo-small" />
                ) : props.rowData.channel === "Flipkart" ? (
                    <div className="flipkart-logo-small" />
                ) : '-'
                }</div>
                <div>{
                    props.rowData.product_id
                }</div>
                <div className="px-2">
                    <Select onChange={value => setValueTime(value)}
                        value={valueTime}>
                        {timevalues.map((val) => {
                            return <Option value={val} key={val}>{`${val}`}</Option>;
                        })}
                    </Select>
                </div>
            </div>
            <div class="mt-3"></div>
            <div class="mt-3"></div>
            <TextArea placeholder="Enter Keywords(One per line)" value={areaDatakeyword} rows={4} onChange={(e) => {
                if (areaDatakeyword.length > 0 && valueTime) {
                    seterrorFiled("")
                }
                setAreaDatakeyword(e.target.value)
            }} />
            <div className="text-red-600	"> {errorFiled}</div>
        </Modal>
    )

}

