import React from "react";
import AntTable from "../../components/AntTableV2";
import { Tooltip } from "antd";

export default function ChannelTabV2(props) {
  const ChannelColumns = [
    {
      title: "# Products",
      align: "center",
      field: "QuantityShippedSum",
      isFilterable: true,
      isTotal: true,
      type: "number",
      width: 80,
      total: props.profitTotal?.QuantityShippedSum,
      render: (record, rowIndex) => (
        <>
          <div
            className={`${
              record.Channel === "Amazon-India"
                ? "amazon-logo-small"
                : record.Channel === "Flipkart"
                ? "flipkart-logo-small"
                : "-"
            }`}
          />

          <div>
            {record.QuantityShippedSum
              ? record.QuantityShippedSum.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  currency: "INR",
                })
              : "-"}
          </div>
        </>
      ),
      // sorter: (a, b) => {
      //   a = (a || {}).QuantityShippedSum || 0;
      //   b = (b || {}).QuantityShippedSum || 0;
      //   return a - b;
      // },
      // sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Invoice*",
      align: "center",
      field: "InvoicePriceTotalSum",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      total: props.profitTotal?.InvoicePriceTotalSum,
      // sorter: (a, b) => {
      //   a = (a || {}).InvoicePriceTotalSum || 0;
      //   b = (b || {}).InvoicePriceTotalSum || 0;
      //   return a - b;
      // },
      // sortDirections: ["descend", "ascend", "descend"],
      render: (record, rowIndex) => (
        <div>
          {record.InvoicePriceTotalSum
            ? record.InvoicePriceTotalSum.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-"}
        </div>
      ),
    },
    {
      title: "COGS*",
      align: "center",
      field: "CostPerOrderSum",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      total: props.profitTotal?.CostPerOrderSum,
      // sorter: (a, b) => {
      //   a = (a || {}).CostPerOrderSum || 0;
      //   b = (b || {}).CostPerOrderSum || 0;
      //   return a - b;
      // },
      // sortDirections: ["descend", "ascend", "descend"],
      render: (record, rowIndex) => (
        <div>
          {record.CostPerOrderSum
            ? record.CostPerOrderSum.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-"}
        </div>
      ),
    },
    {
      title: "Fees*",
      align: "center",
      field: "MarketplaceFeesTotalSum",
      width: 70,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      total: props.profitTotal?.MarketplaceFeesTotalSum,
      // sorter: (a, b) => {
      //   a = (a || {}).MarketplaceFeesTotalSum || 0;
      //   b = (b || {}).MarketplaceFeesTotalSum || 0;
      //   return a - b;
      // },
      // sortDirections: ["descend", "ascend", "descend"],
      render: (record, rowIndex) => (
        <div>
          {record.MarketplaceFeesTotalSum
            ? record.MarketplaceFeesTotalSum.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-"}
        </div>
      ),
    },
    {
      title: "Ship Fees*",
      align: "center",
      field: "ShippingFeesTotalSum",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      total: props.profitTotal?.ShippingFeesTotalSum,
      // sorter: (a, b) => {
      //   a = (a || {}).ShippingFeesTotalSum || 0;
      //   b = (b || {}).ShippingFeesTotalSum || 0;
      //   return a - b;
      // },
      render: (record, rowIndex) => (
        <div>
          {record.ShippingFeesTotalSum
            ? record.ShippingFeesTotalSum.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-"}
        </div>
      ),
      // sortDirections: ["descend", "ascend", "descend"],
      // render: (record, rowIndex) => "-",
    },
    {
      title: "Return*",
      align: "center",
      field: "ReturnPriceTotalSum",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      total: props.profitTotal?.ReturnPriceTotalSum,
      // sorter: (a, b) => {
      //   a = (a || {}).ReturnPriceTotalSum || 0;
      //   b = (b || {}).ReturnPriceTotalSum || 0;
      //   return a - b;
      // },
      render: (record, rowIndex) => (
        <div>
          {record.ReturnPriceTotalSum
            ? record.ReturnPriceTotalSum.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-"}
        </div>
      ),
      // sortDirections: ["descend", "ascend", "descend"],
      // render: (record, rowIndex) => "-",
    },
    {
      title: "Ad Cost",
      align: "center",
      field: "AdsSpentSum",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      total: props.profitTotal?.AdsSpentSum,
      // sorter: (a, b) => {
      //   a = (a || {}).AdsSpentSum || 0;
      //   b = (b || {}).AdsSpentSum || 0;
      //   return a - b;
      // },
      render: (record, rowIndex) => (
        <div>
          {record.AdsSpentSum
            ? record.AdsSpentSum.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-"}
        </div>
      ),
      // sortDirections: ["descend", "ascend", "descend"],
    },
    ...((props.taxValue === "With Tax" && [
      // {
      //   title: "GST OUT",
      //   align: "center",
      //   field: "OUTGST",
      //   isTotal: true,
      //   isTotalCurrency: true,
      //   width: 80,
      //   total: props.profitTotal?.Total_OUTGST,
      //   render: (record, rowIndex, isLoading) =>
      //     isLoading ? (
      //       <div className="item">
      //         <div className="item-title" />
      //       </div>
      //     ) : record.OUTGST ? (
      //       record.OUTGST.toLocaleString("en-IN", {
      //         maximumFractionDigits: 2,
      //         style: "currency",
      //         currency: "INR",
      //       })
      //     ) : (
      //       "-"
      //     ),
      //   // sorter: (a, b) => {
      //   //   a = a.Out_gst || 0;
      //   //   b = b.Out_gst || 0;
      //   //   return a - b;
      //   // },
      //   // sortDirections: ["descend", "ascend", "descend"],
      // },
      // {
      //   title: "GST IN",
      //   align: "center",
      //   field: "InGst",
      //   isTotal: true,
      //   isTotalCurrency: true,
      //   width: 80,
      //   total: props.profitTotal?.Total_InGst,
      //   render: (record, rowIndex, isLoading) =>
      //     isLoading ? (
      //       <div className="item">
      //         <div className="item-title" />
      //       </div>
      //     ) : record.InGst ? (
      //       record.InGst.toLocaleString("en-IN", {
      //         maximumFractionDigits: 2,
      //         style: "currency",
      //         currency: "INR",
      //       })
      //     ) : (
      //       "-"
      //     ),
      //   // sorter: (a, b) => {
      //   //   a = a.InGst || 0;
      //   //   b = b.InGst || 0;
      //   //   return a - b;
      //   // },
      //   // sortDirections: ["descend", "ascend", "descend"],
      // },
      {
        title: "GST Impact",
        align: "center",
        field: "GSTImpac",
        isTotal: true,
        isTotalCurrency: true,
        width: 80,
        total: props.profitTotal?.Total_GSTImpact,
        render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.GSTImpact ? (
          <Tooltip
            title={`GST OUT: ${record.OUTGST.toLocaleString("en-IN", {
              maximumFractionDigits: 0,
              style: "currency",
              currency: "INR",
            })} / GST IN: ${record.InGst.toLocaleString("en-IN", {
              maximumFractionDigits: 0,
              style: "currency",
              currency: "INR",
            })}`}
          >
            {record.GSTImpact.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
              style: "currency",
              currency: "INR",
            })}
          </Tooltip>
        ) : (
          "-"
        ),
        // sorter: (a, b) => {
        //   a = a.GSTImpact || 0;
        //   b = b.GSTImpact || 0;
        //   return a - b;
        // },
        // sortDirections: ["descend", "ascend", "descend"],
      },
    ]) ||
      []),
    {
      title: "Profit",
      align: "center",
      field: "Profit",
      width: 80,
      isFilterable: true,
      isTotal: true,
      isTotalCurrency: true,
      type: "number",
      total: props.profitTotal?.ProfitTotal,
      // sorter: (a, b) => {
      //   a = (a || {}).Profit || 0;
      //   b = (b || {}).Profit || 0;
      //   return a - b;
      // },
      render: (record, rowIndex) => (
        <div>
          {record.Profit
            ? record.Profit.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
            : "-"}
        </div>
      ),
      // sortDirections: ["descend", "ascend", "descend"],
      // render: (record, rowIndex) => "-",
    },
    {
      title: "Margin",
      align: "center",
      field: "ProfitMargin",
      width: 80,
      isFilterable: true,
      isTotal: true,
      type: "number",
      isPercent: true,
      total: props.profitTotal?.MarginSum,
      // sorter: (a, b) => {
      //   a = (a || {}).ProfitMargin || 0;
      //   b = (b || {}).ProfitMargin || 0;
      //   return a - b;
      // },
      render: (record, rowIndex) => (
        <div>
          {record.ProfitMargin
            ? record.ProfitMargin.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                currency: "INR",
              }) + "%"
            : "-"}
        </div>
      ),
      // sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "TACOS",
      align: "center",
      field: "ACOTS",
      width: 80,
      isFilterable: true,
      type: "number",
      isTotal: true,
      isPercent: true,
      total: props.profitTotal?.ACOTSSum,
      // sorter: (a, b) => {
      //   a = (a || {}).ACOTS || 0;
      //   b = (b || {}).ACOTS || 0;
      //   return a - b;
      // },
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.ACOTS ? (
          record.ACOTS.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
          }) + "%"
        ) : (
          "-"
        ),
      // sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "ROI",
      align: "center",
      field: "ROI",
      width: 80,
      isFilterable: true,
      // isTotalCurrency: true,
      type: "number",
      isTotal: true,
      isPercent: true,
      total: props.profitTotal?.ROISum,
      // sorter: (a, b) => {
      //   a = (a || {}).ROI || 0;
      //   b = (b || {}).ROI || 0;
      //   return a - b;
      // },
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.ROI ? (
          (record.ROI * 100).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            currency: "INR",
          }) + "%"
        ) : (
          "-"
        ),
      // sortDirections: ["descend", "ascend", "descend"],
    },
  ];
  React.useEffect(() => {
    if (props.activeTab === "3") {
      props.setColumn(ChannelColumns);
    }
  }, [props.activeTab, props.taxValue]);
  return (
    <div>
      <span className="flex flex-row-reverse text-xs italic font-semibold -mt-4 ">
        *Exc.GST
      </span>
      <AntTable
        columns={ChannelColumns}
        dataSource={props.data}
        stickyHeader={{
          offsetHeader: 65,
        }}
        rowKey={(row, rowIndex) => rowIndex}
        isLoading={props.isLoading}
        hideSummary={!props.data?.length}
        searchText={[props.searchText]}
      />
    </div>
  );
}
