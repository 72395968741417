import { Form, Input, Typography } from "antd";
import { Field } from "formik";
import React from "react";

const InputInterface = ({ interFace, ...restProps }) => {
  return (
    <>
      {interFace === "password" ? (
        <Input.Password {...restProps} />
      ) : (
        <Input {...restProps} />
      )}
    </>
  );
};

function CustomTextField({
  title,
  name,
  disabled,
  helpText,
  type,
  showValidateSuccess,
  showInitialErrorAfterTouched = false,
  size,
  showHelp = true,
  className,
  hasFeedback = true,
  ...restProps
}) {
  return (
    <>
      <div className="font-Roboto">
        <Typography className={`text-sm font-medium text-left text-black`}>
          {title}
        </Typography>
        <div className={`mt-2 w-full`}>
          <Field name={name}>
            {({
              field: { value, onChange, onBlur },
              form: { initialErrors },
              meta,
            }) => (
              <Form.Item
                validateStatus={
                  meta.error || (initialErrors[name] && !meta.touched)
                    ? "error"
                    : meta.touched && !meta.error && showValidateSuccess
                    ? "success"
                    : undefined
                }
                hasFeedback={meta.touched}
                help={
                  showHelp && (
                    <>
                      {meta.error && meta.touched && <div>{meta.error}</div>}
                      {initialErrors[name] &&
                        (!meta.touched || showInitialErrorAfterTouched) && (
                          <div>{initialErrors[name]}</div>
                        )}
                    </>
                  )
                }
              >
                <InputInterface
                  name={name}
                  value={value}
                  type={type}
                  interFace={type === "password" ? "password" : ""}
                  size={size || "large"}
                  disabled={
                    disabled !== null || disabled !== undefined
                      ? disabled
                      : false
                  }
                  placeholder={helpText}
                  className={`border-2 ${
                    meta.error && meta.touched
                      ? "border-red-200"
                      : "border-black border-opacity-10 "
                  } rounded ${className || ""}`}
                  {...restProps}
                  onChange={(event) => {
                    onChange(event);
                    restProps.onChange && restProps.onChange(event);
                  }}
                  onBlur={(event) => {
                    onBlur(event);
                    restProps.onBlur && restProps.onBlur(event);
                  }}
                />
              </Form.Item>
            )}
          </Field>
        </div>
      </div>
    </>
  );
}

export default CustomTextField;
