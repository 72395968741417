import { Select } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, withRouter } from "react-router-dom";
import CustomFilterHeader from "../../components/./CustomFilterHeader.js";
import AntTable from "../../components/AntTable/index.js";
import ApiWrapper from "../../utils/ApiWrapper";
const { Option } = Select;

function SyncDetails() {
  const RefColumns = [
    {
      title: "Sno",
      align: "center",
      field: "channel",
      width: 60,
      fixed: "left",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          rowIndex
        ),
    },
    {
      title: "Channel",
      align: "center",
      field: "channel",
      type: "string",
      width: 100,
      fixed: "left",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-image" />
          </div>
        ) : record.channel === "Amazon-India" ? (
          <div className="amazon-logo-small" />
        ) : record.channel === "Flipkart" ? (
          <div className="flipkart-logo-small" />
        ) : record.channel === "Amazon-India-Ads" ? (
          <div className="amazon-logo-small" />
        ) : (
          "-"
        ),
    },

    {
      title: "Email",
      align: "center",
      field: "email",
      type: "string",
      defaultSortOrder: "descend",
      fixed: "left",
      width: 180,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.email !== undefined ? (
          record.email
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.email || "").toLowerCase();
        b = (b.email || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Org Id",
      align: "center",
      field: "orgId",
      type: "number",
      fixed: "left",
      width: 180,
      sorter: (a, b) => {
        a = (a.orgId || "");
        b = (b.orgId || "");
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    // {
    //   title: "status",
    //   align: "center",
    //   field: "active",
    //   type: "string",
    //   fixed: "left",
    //   width: 100,
    //   onFilter: (value, record) => {
    //     if (value === "-") {
    //       if (!record.active) {
    //         return true;
    //       }
    //     } else {
    //       return (record.active || "").toLowerCase() === value.toLowerCase();
    //     }
    //   },
    //   filters: [
    //     { text: "Active", value: "Active" },
    //     { text: "Inactive", value: "Inactive" },
    //   ],
    // },
    {
      title: "Inventory Report",
      align: "center",
      field: "inventoryReportLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.inventoryReportLastSyncAt !== undefined
          ? moment(record.inventoryReportLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "FBA Inventory",
      align: "center",
      field: "FBAFulfillmentCurrentInventoryLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.FBAFulfillmentCurrentInventoryLastSyncAt !== undefined
          ? moment(record.FBAFulfillmentCurrentInventoryLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Orders Report",
      align: "center",
      field: "ordersReportLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.ordersReportLastSyncAt !== undefined
          ? moment(record.ordersReportLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Orders API",
      align: "center",
      field: "ordersAPILastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.ordersAPILastSyncAt !== undefined
          ? moment(record.ordersAPILastSyncAt).format("lll")
          : "-",
    },
    {
      title: "AdSpent ",
      align: "center",
      field: "AdsReportLastSyncAt",
      type: "string",
      width: 120,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.AdsReportLastSyncAt !== undefined ? (
          moment(record.AdsReportLastSyncAt).format("lll")
        ) : (
          "-"
        ),
    },
    {
      title: "SPAP AdSpent ",
      align: "center",
      field: "adsSPAPReportLastSyncAt",
      type: "string",
      width: 120,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.adsSPAPReportLastSyncAt !== undefined ? (
          moment(record.adsSPAPReportLastSyncAt).format("lll")
        ) : (
          "-"
        ),
    },
    {
      title: "Financial API",
      align: "center",
      field: "financeEventsLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.financeEventsLastSyncAt !== undefined
          ? moment(record.financeEventsLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Shipments API",
      align: "center",
      field: "ShipmentsLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.ShipmentsLastSyncAt !== undefined
          ? moment(record.ShipmentsLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "AF Shipments Report",
      align: "center",
      field: "AFShipmentsReportLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.AFShipmentsReportLastSyncAt !== undefined
          ? moment(record.AFShipmentsReportLastSyncAt).format("lll")
          : "-",
    },
   
    {
      title: "Dashboard Orders API",
      align: "center",
      field: "OrdersDashboardLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.OrdersDashboardLastSyncAt !== undefined
          ? moment(record.OrdersDashboardLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Dashboard Cancellations API",
      align: "center",
      field: "CancellationsDashboardLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.CancellationsDashboardLastSyncAt !== undefined
          ? moment(record.CancellationsDashboardLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Dashboard Listings API",
      align: "center",
      field: "ListingsDashboardLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.ListingsDashboardLastSyncAt !== undefined
          ? moment(record.ListingsDashboardLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Dashboard Transaction Report",
      align: "center",
      field: "TransactionsReportLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.TransactionsReportLastSyncAt !== undefined
          ? moment(record.TransactionsReportLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Product Reviews",
      align: "center",
      field: "ReviewsLastSyncAt",
      type: "string",
      width: 160,
      render: (record, rowIndex, isLoading) =>
        record.ReviewsLastSyncAt !== undefined
          ? moment(record.ReviewsLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Seller Review",
      align: "center",
      field: "SellersFeedbacksReportLastSyncAt",
      type: "string",
      width: 120,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.SellersFeedbacksReportLastSyncAt !== undefined ? (
          moment(record.SellersFeedbacksReportLastSyncAt).format("lll")
        ) : (
          "-"
        ),
    },
    {
      title: "Brand Protector",
      align: "center",
      field: "BrandProtectorLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.BrandProtectorLastSyncAt !== undefined
          ? moment(record.BrandProtectorLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "TransactionsByOrder",
      align: "center",
      field: "TransactionsByOrdersLastSyncAt",
      type: "string",
      width: 120,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record.TransactionsByOrdersLastSyncAt !== undefined ? (
          moment(record.TransactionsByOrdersLastSyncAt).format("lll")
        ) : (
          "-"
        ),
    }, 
    {
      title: "Returns API",
      align: "center",
      field: "ReturnsLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.ReturnsLastSyncAt !== undefined
          ? moment(record.ReturnsLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "FBA CSS Report",
      align: "center",
      field: "FBACSSReportLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.FBACSSReportLastSyncAt !== undefined
          ? moment(record.FBACSSReportLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "FBA Returns Report",
      align: "center",
      field: "FBAReturnsReportLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.FBAReturnsReportLastSyncAt !== undefined
          ? moment(record.FBAReturnsReportLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "MFN Returns Report",
      align: "center",
      field: "MFNPReturnsReportLastSyncAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.MFNPReturnsReportLastSyncAt !== undefined
          ? moment(record.MFNPReturnsReportLastSyncAt).format("lll")
          : "-",
    },
    {
      title: "Last UpdatedAt",
      align: "center",
      field: "updatedAt",
      type: "string",
      width: 150,
      render: (record, rowIndex, isLoading) =>
        record.updatedAt !== undefined
          ? moment(record.updatedAt).format("lll")
          : "-",
    },
   
   
  ];
  const [columns, setColumns] = React.useState(RefColumns);
  const [syncList, setSyncList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filteredSyncList, setFilteredSyncList] = React.useState([]);
  const [salesChannel, setSalesChannel] = React.useState(["Amazon-India", "Flipkart","Amazon-India-Ads"]);
  const history = useHistory();

  const onSearchTextEntered = (searchText) => {
    setSearchText(searchText);
  };
  const handleMarketplaceChange = async () => {  };



  function handleChange(channels) {
    setIsLoading(true);
    var arr = [];
    var noOfMarketplaces = channels.length;
    channels.map(async (marketplace, marketplaceIndex) => {
      let requestJsonBody = {
        channel: marketplace ,
      };
      ApiWrapper("getSyncDetails", requestJsonBody).then(
        function (response) {
          if (
            response !== undefined &&
            response.Items !== undefined &&
            response.Items.length > 0
          ) {
            response.Items.map((row) => {
              let record = { ...row };
              if ( record.createdAt) {
                
                  record.email =
                  record.email ||
                  record?.flipkartDashboard?.username ||
                  record?.amazonDashboard?.username;
                  // record.active = record.isDisabled !=true && record.isDisconnected !=true && record.isExpired !=true ? "Active" : "Inactive"
                  return arr.push(record);
                }
            });
          }

          setSyncList([...arr]);
          setFilteredSyncList([...arr]);

          noOfMarketplaces = noOfMarketplaces - 1;
          if (noOfMarketplaces === 0) {
            setIsLoading(false);
          }
        },
        function (error) {
          noOfMarketplaces = noOfMarketplaces - 1;
          if (noOfMarketplaces === 0) {
            setIsLoading(false);
          }
        }
      );
    });
    if (!channels.length) {
      setIsLoading(false);
      setSyncList([]);
      setFilteredSyncList([]);
    }
  }
  React.useEffect(() => {
    handleChange(salesChannel);
}, []);


  return (
    <>
      <Helmet>
        <title>{"ForceSight"}</title>
      </Helmet>
      <div
        className="flex justify-between items-center pt-3 pb-3 sticky top-0 z-10 text-sm font-Inter ml-2 mr-2 bg-white font-medium text-DimGray "
        style={{
          boxShadow: `0px 0px 2px rgba(23, 43, 77, 0.04), 0px 3px 2px rgba(23, 43, 77, 0.08)`,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          onClick={() => {
            history.push("/admin-dashboard");
          }}
          className="cursor-pointer"
        >
          <div className="fs-logo-large" />
        </div>
        <div className="flex items-center">
          <div
            onClick={() => {
              history.push("/admin-dashboard");
            }}
            className="pl-3 cursor-pointer"
          >
            Dashboard
          </div>
          <div
            onClick={() => {
              localStorage.removeItem("token");
              history.push("/login");
            }}
            className="pl-4 cursor-pointer"
          >
            Logout
          </div>
        </div>
      </div>
      <CustomFilterHeader
        headerTitle="Sync Details"
        handleMarketplaceChange={handleMarketplaceChange}
        showDateRanges={false}
        showManualSync={false}
        rows={syncList}
        channelHide={true}
        searchHide={true}
        columns={columns}
        exportFileName="SyncDetails"
        onSearchTextEntered={onSearchTextEntered}
        onFilterUpdate={(data) => {
          setFilteredSyncList(data);
        }}
        customHeader={
          <Select
            mode="multiple"
            placeholder="channel"
            defaultValue={salesChannel}
            style={{ width: 200 }}
            className="ml-5"
            maxTagCount={0}
            maxTagPlaceholder={(values) => {
              return `Channel: ${values.length} selected`;
            }}
            onChange={handleChange}
          >
            {salesChannel.map((channel, channelIndex) => {
              return (
                <Option key={`channel-${channelIndex}`} value={channel}>
                  {channel}
                </Option>
              );
            })}
          </Select>
        }
      />

      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          columns={columns}
          dataSource={filteredSyncList}
          stickyHeader={{
            offsetHeader: 65,
          }}
          scroll={{ y: 2500 }}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={isLoading}
          hideSummary
          searchText={[searchText]}
        />
      </div>
    </>
  );
}
export default withRouter(SyncDetails);
