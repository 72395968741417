import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "antd";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  leftTitleText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    textAlign: "left",
  },
  valueText: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
    textAlign: "right",
  },
  nameText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
    textAlign: "left",
  },
}));

const tableColumns = (classes, title) => [
  {
    title: (
      <Typography className={classes.leftTitleText} style={{ marginLeft: 16 }}>
        {title}
      </Typography>
    ),
    dataIndex: "Name",
    key: "Name",
    render: (text) => (
      <Typography className={classes.nameText} style={{ marginLeft: 16 }}>
        {text}
      </Typography>
    ),
  },
  {
    title: "This Period",
    dataIndex: "ThisPeriod",
    key: "ThisPeriod",
    align: "right",
    render: (text, record, index) => (
      <Typography className={classes.valueText}>
        {record.UnitType === "Currency"
          ? `₹${text.toFixed(2).toLocaleString()}`
          : record.UnitType === "Percentage"
            ? `${text.toLocaleString()}%`
            : `${text.toLocaleString()}`}
      </Typography>
    ),
  },
  {
    title: "Previous Period",
    dataIndex: "PreviousPeriod",
    key: "PreviousPeriod",
    align: "right",
    render: (text, record, index) => (
      <Typography className={classes.valueText}>
        {record.UnitType === "Currency"
          ? `₹${text.toFixed(2).toLocaleString()}`
          : record.UnitType === "Percentage"
            ? `${text.toLocaleString()}%`
            : `${text.toLocaleString()}`}
      </Typography>
    ),
  },
  {
    title: "Change",
    dataIndex: "Growth",
    key: "Growth",
    align: "right",
    render: (text) => (
      <Typography className={classes.valueText}>{`${text.toFixed(
        2
      )}%`}</Typography>
    ),
  },
];

function ProfitabilityInfoTable(props) {
  let history = useHistory();
  const classes = useStyles();

  return (
    <>
      <div className="w-full flex gap-14 tablet:gap-0 flex-nowrap tablet:flex-wrap pt-1">
        <div className="w-3/6 tablet:w-full">
          <Table
            columns={tableColumns(classes, "Per unit analysis")}
            dataSource={props.data?.slice(0, 6)}
            pagination={{ position: ["none"] }}
            bordered
            loading={props.isLoading}
            size="small"
          />
        </div>
        <div className="w-3/6 tablet:w-full mt-0 tablet:mt-6 tablet:pointer-events-none tablet:cursor-default">
          <Table
            columns={tableColumns(classes, "SKU #")}
            dataSource={props.data?.slice(6, 12)}
            pagination={{ position: ["none"] }}
            bordered
            loading={props.isLoading}
            size="small"
            className="cursor-pointer"
            // onRow={(record, rowIndex) => ({
            //   onClick: (event) =>
            //     history.push({ pathname: "/profitability", state: { record } }),
            // })}
          />
        </div>
      </div>
    </>
  );
}

export default ProfitabilityInfoTable;
