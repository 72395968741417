import React, { useRef, useState } from "react";
import {
  SentimentGramsGraphs,
  SentimentSliderTable,
} from "./sentimentSliderTable";
import { Select, Drawer, Skeleton } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { SentimentReviewsTable } from "./sentimentReviews";
import { flatten } from "lodash";

const { Option } = Select;

function SentimentReviews(props) {
  const bottomRef = useRef();
  const [selectedGrams, setSelectedGrams] = useState("all");
  const [visible, setVisible] = useState(true);

  const setSentimentData = (val) => {
    if (props.isLoading) return;
    else {
      props.sentimentReviewWords(val);
      setVisible(false);
      bottomRef.current.scrollIntoView({behavior: "smooth" });
    }
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const handleChange = async (value) => {
    setSelectedGrams(value);
  };
  return (
    <>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <div className="container mx-auto px-3 grid gap-3 grid-cols-3">
          <div className="shadow-md mt-1 tablet:w-full border-2 border rounded bg-white p-3 flex-wrap  ">
            {props.isLoading ? (
              <Skeleton active />
            ) : (
              <SentimentGramsGraphs
                data={props.data ? props?.data?.unigrams?.slice(0, 10) : []}
                isLoading={props.isLoading}
                title="One"
                setBarValue={setSentimentData}
              />
            )}
          </div>
          <div className="shadow-md mt-1 tablet:w-full border-2 border rounded bg-white p-3 flex-wrap  ">
          {props.isLoading ? (
              <Skeleton active />
            ) : (
            <SentimentGramsGraphs
              data={props.data ? props?.data?.bigrams?.slice(0, 10) : []}
              isLoading={props.isLoading}
              title="Two"
              setBarValue={setSentimentData}
            />
            )}
          </div>
          <div className="shadow-md mt-1 tablet:w-full border-2 border rounded bg-white p-3 flex-wrap  ">
              {props.isLoading ? (
              <Skeleton active />
            ) : (
            <SentimentGramsGraphs
              data={props.data ? props?.data?.trigrams?.slice(0, 10) : []}
              isLoading={props.isLoading}
              title="Three"
              setBarValue={setSentimentData}
            />
            )}
          </div>
        </div>
        <div className="w-full ">
        <div className="m-2">
        {" "}
        <div className="absolute left-7  bg-white cursor-pointer">
          {!visible && (
            <MenuUnfoldOutlined
              onClick={showDrawer}
              className="text-xl"
              style={{ color: "blue" }}
            />
          )}
        </div>
      </div>
          <div
            className={`${visible ? "h-80" : "h-fit"}`}
            style={{
              position: "relative",
              // height: "450px",
              padding: "4px",
              overflow: "hidden",
              textAlign: "left",
            }}
          >
            <div className={`${visible ? "ml-48" : "ml-2"}`}>
              <SentimentReviewsTable
                data={props.selectedReviews}
                isLoading={props.isLoading}
                pageCount={props.selectedReviewsCount}
                tableFilterData={props.tableFilterData}
              />
            </div>{" "}
            <Drawer
              placement="left"
              closable={true}
              onClose={onClose}
              visible={visible}
              closeIcon={
                <MenuFoldOutlined
                  style={{ color: "blue" }}
                  className="text-xl"
                />
              }
              getContainer={false}
              style={{ position: "absolute" }}
              maskStyle={{ backgroundColor: "unset" }}
              // mask={false}
            >
              <div ref={bottomRef}>
                <Select
                  defaultValue={selectedGrams}
                  style={{ width: 190 }}
                  onChange={handleChange}
                >
                  <Option value="unigrams">One Word</Option>
                  <Option value="bigrams">Two Word</Option>
                  <Option value="trigrams">Three Word</Option>
                  <Option value="all">All</Option>
                </Select>
                <SentimentSliderTable
                  data={
                    props.data
                      ? selectedGrams === "unigrams"
                        ? props.data.unigrams
                        : selectedGrams === "bigrams"
                        ? props.data.bigrams
                        : selectedGrams === "trigrams"
                        ? props.data.trigrams
                        : selectedGrams === "all"
                        ? flatten([
                            props.data.unigrams,
                            props.data.bigrams,
                            props.data.trigrams,
                          ])
                        : []
                      : []
                  }
                  isLoading={props.isLoading}
                  setSentimentData={setSentimentData}
                />
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
}
export default SentimentReviews;
