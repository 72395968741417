export const DATE_FORMAT = "DD-MM-yyyy";
export const Fulfilment_filter = [
  { text: "Amazon", value: ["Amazon","AMAZON_IN"], },
  { text: "Merchant", value: ["Merchant"], },
  { text: "FBF_LITE", value: ["FBF_LITE"], },
  { text: "FBF_AND_FBF_LITE", value: ["FBF_AND_FBF_LITE"], },
  { text: "FBF", value: ["FBF"], },
  { text: "-", value: ["-"], },
];
export const Fs_status = [
  { text: "New", value: ["APPROVED"] },
  // { text: "Pending", value: ["Pending"] },
  {
    text: "Processing",
    value: [
      "Processing",
      "New",
      "PROCESSING",
      "Unshipped",
      "Pending - Waiting for Pick Up",
      "PACKING_IN_PROGRESS",
      "READY_TO_DISPATCH",
      "PICKUP_COMPLETE",
      "ready_to_ship",
      "Packed",
      "pickup_completed",
    ],
  },
  {
    text: "Shipped",
    value: [
      "Shipped",
      "Shipped - Picked Up",
      "Shipped - Rejected by Buyer",
      "Shipped - Lost in Transit",
      "Shipped - Undeliverable",
      "Shipped - Out for Delivery",
      "SHIPPED",
    ],
  },
  {
    text: "Pending",
    value: ["Pending", "PENDING"],
  },
  {
    text: "Shipped*",
    value: ["Shipped*"],
  },
  {
    text: "Delivered",
    value: ["Shipped - Delivered to Buyer", "DELIVERED", "delivered"],
  },
  { text: "Cancelled", value: ["Cancelled", "CANCELLED"] },
  {
    text: "Return Initiated",
    value: [
      "RETURN_REQUESTED",
      "created",
      "return_requested",
      "Return Initiated",
    ],
  },
  {
    text: "Return InTransit",
    value: ["Shipped - Returning to Seller", "Return InTransit"],
  },
  {
    text: "Return Delivered",
    value: [
      "Shipped - Returned to Seller",
      "completed",
      "RETURNED",
      "Return Delivered",
      "RETURN DELIVERED"
    ],
  },
];
export const Return_filter = [
  { text: "To Be Claimed", value: "TO BE CLAIMED" },
  { text: "In Progress", value: "IN PROGRESS" },
  { text: "Received", value: "RECEIVED" },
  { text: "Marketplace Return", value: "MARKETPLACE RETURN"}
];
