import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
  },
  cell: {
    padding: "6px 6px 6px 6px",
  },
  trackerFilterBar: {
    marginTop: 44,
  },
  searchFilterBar: {
    marginTop: 31,
  },
  table: {
    marginTop: 22,
  },
}));

const CustomTableCell = withStyles({
  sizeSmall: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 10,
    paddingBottom: 10,
  },
  root: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 500,
    color: "#44444F",
    fontFamily: "inter",
  },
})(TableCell);

const CustomTableHeaderCell = withStyles({
  sizeSmall: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 10,
    paddingBottom: 10,
  },
  root: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 400,
    color: "#717480",
    fontFamily: "inter",
  },
})(TableCell);

function OrdersDetailPanel(props) {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>Product Image</CustomTableHeaderCell>
            <CustomTableCell>-</CustomTableCell>
            <CustomTableHeaderCell>Customer Name</CustomTableHeaderCell>
            <CustomTableCell>
              {props.row.BuyerName !== undefined
                ? (props.row.BuyerName?.firstName || "") +
                  " " +
                  (props.row.BuyerName?.lastName || "")
                : "-"}
            </CustomTableCell>
          </TableRow>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>Product Name</CustomTableHeaderCell>
            <CustomTableCell>
              {props.row.ProductDetails !== undefined ? (
                props.row.ProductDetails?.Name.length > 45 ? (
                  <Typography className={classes.title}>
                    {props.row.ProductDetails?.Name.substring(0, 45) + "..."}
                  </Typography>
                ) : (
                  <Typography className={classes.title}>
                    {props.row.ProductDetails?.Name}
                  </Typography>
                )
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableHeaderCell>Customer Address</CustomTableHeaderCell>
            <CustomTableCell>
              {props.row.ShipCity}, {props.row.ShipCountry},
              {props.row.ShipPostalCode}
            </CustomTableCell>
          </TableRow>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>Product Quantity</CustomTableHeaderCell>
            <CustomTableCell>{props.row.QuantityShipped}</CustomTableCell>
            <CustomTableHeaderCell>Customer Email</CustomTableHeaderCell>
            <CustomTableCell>{props.row.BuyerEmail}</CustomTableCell>
          </TableRow>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>Weight</CustomTableHeaderCell>
            <CustomTableCell>
              {props.row.ProductDetails?.PackageDimensions?.weight !== undefined
                ? Math.round(props.row.ProductDetails?.PackageDimensions.weight.value * 100)/100
                : "-"}{" "}
              {props.row.ProductDetails?.PackageDimensions.weight?.units !== "-"
                ? props.row.ProductDetails?.PackageDimensions.weight?.units ||
                  ""
                : "Kg"}
            </CustomTableCell>
            <CustomTableHeaderCell>Courier</CustomTableHeaderCell>
            <CustomTableCell>-</CustomTableCell>
          </TableRow>
          <TableRow>
            <CustomTableCell colSpan={2} />
            <CustomTableHeaderCell>Dimensions</CustomTableHeaderCell>
            <CustomTableCell>
              {props.row.ProductDetails?.PackageDimensions !== undefined
                ? parseInt(props.row.ProductDetails?.PackageDimensions?.length?.value ||
                    0).toFixed() +
                  "L " +
                  parseInt(props.row.ProductDetails?.PackageDimensions?.breadth
                    ?.value || 0).toFixed() +
                  "B " +
                  parseInt(props.row.ProductDetails?.PackageDimensions?.height?.value ||
                    0).toFixed() +
                  "H"
                : "-"}
            </CustomTableCell>
            <CustomTableHeaderCell>AWB No:</CustomTableHeaderCell>
            <CustomTableCell>-</CustomTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OrdersDetailPanel;
