import { CloudUploadOutlined, InboxOutlined } from "@ant-design/icons";
import { makeStyles, Modal } from "@material-ui/core";
import { Upload } from "antd";
import { compact } from "lodash";
import React from "react";
import ExportButton from "../../../components/Styles/ExportButton";
import StyledButton, {
  PrimaryButton,
} from "../../../components/Styles/StyledButton";
import { PostValues } from "../../../utils/api-client.js";
import ApiWrapper from "../../../utils/ApiWrapper";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: 550,
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid #F4F5F7",
    padding: 32,
    borderRadius: 5,
  },
  headerImage: {
    marginRight: 16,
    fontSize: 24,
    color: "#0065FF",
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: "#44444F",
  },
  modalContent: {
    fontFamily: "Inter",
    fontSize: 14,
    color: "#717480",
    marginTop: 16,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 28,
  },
  marginRight: {
    marginRight: 14,
  },
});

const BulkUpdateInventory = (props) => {
  const [open, setOpen] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [apiData, setApiData] = React.useState([]);

  const classes = useStyles();

  const handlePublish = async () => {
    let requestJsonBody = {
      listings: apiData,
    };
    let reqData = {
      updated_at: new Date(),
      module_name: "Listings",
      updated_value:  {
      action: "Bulk Listings updated",} ,
    };
      let response = await PostValues("updateInventory_v2", requestJsonBody);
      props.setRequestId(response.requestId);
      setOpen(false);
      setFileList([]);
      setApiData([]);
      if (response.status) {
        ApiWrapper("updateChangeLog", reqData).then(
          function (response) {
          },
          function (error) {}
        );
      }
   
    // ApiWrapper("updateInventory", requestJsonBody).then(
    //   function (response) {
    //     props.setRequestId(response.requestId);
    //     setOpen(false);
    //     setFileList([]);
    //     setApiData([]);
    //   },
    //   function (error) {
    //     // message.error("We encountered an issue. Please log out and log back in.")
    //   }
    // );
  };

  const handleCancel = () => {
    setOpen(false);
    setFileList([]);
    setApiData([]);
  };

  const onFileChange = (files) => {
    setFileList(files.fileList.map((file) => ({ ...file, status: "done" })));
    window.Papa.parse(files.file.originFileObj, {
      worker: true,
      complete: function (results) {
        let csvData = results.data.map((data, i) => {
          if (i > 0 && data[0] != '') {
            if (data[0] === "Both") {
              return {
                channel: data[0],
                sku: data[0] === "Amazon-India" ? data[6] : data[7],
                masterSKU: data[5],
                "Amazon-India-sku": data[6],
                "Flipkart-sku": data[7],
                "Amazon-India-price": data[8],
                "Flipkart-price": data[9],
                quantity: data[10],
              };
            } else {
              return {
                channel: data[0],
                masterSKU: data[5],
                sku: data[0] === "Amazon-India" ? data[6] : data[7],
                price: data[0] === "Amazon-India" ? data[8] : data[9],
                quantity: data[10],
              };
            }
          } else {
            return null;
          }
        });
        setApiData(compact(csvData));
      },
    });
  };

  return (
    <div>
      <PrimaryButton
        variant="outlined"
        onClick={() => setOpen(true)}
        style={{ marginRight: 16 }}
        disabled={props.requestId}
      >
        Upload
      </PrimaryButton>
      <Modal
        open={open}
        onClose={handleCancel}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.root}>
          <div
            className="flex items-center justify-start"
            style={{ marginBottom: 28 }}
          >
            <CloudUploadOutlined className={classes.headerImage} />
            <span className={classes.modalTitle}>Upload updated CSV file</span>
          </div>
          <Upload.Dragger 
            multiple={false}
            accept=".csv"
            onChange={onFileChange}
            fileList={fileList}
            maxCount={1}
          >
            <div className="text-primary mb-4 text-4xl">
              <InboxOutlined />
            </div>
            <div className="text-gray-1000 text-base mb-2">
              Click or drag file to this area to upload
            </div>
            <div className="text-gray-400">Upload only .csv files</div>
          </Upload.Dragger>
          <div className={classes.modalContent}>
            The changes you have made will be published to marketplace. Please
            wait, it might take some time
          </div>
          <div className={classes.buttonContainer}>
            <ExportButton
              variant="outlined"
              className={classes.marginRight}
              onClick={handleCancel}
            >
              Cancel
            </ExportButton>
            <StyledButton variant="outlined" onClick={handlePublish}>
              Publish
            </StyledButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BulkUpdateInventory;
