import React from 'react';
import { Column } from '@ant-design/charts';

const ChartPie: React.FC = (props) => {
  const config = {
    data: props.buyBoxOwner,
    xField: 'buybox',
    yField: 'COUNT',
    height: 300,
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    }
  };
  return <Column {...config} />;
};

export default ChartPie;