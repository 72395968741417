import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	success: {
		background: '#E3FCEF',
		border: '1px solid #ABF5D1',
		borderRadius: 3,
		color: '#006644',
		fontWeight: 600,
		fontSize: 10,
		fontFamily: 'Inter, sans-serif',
		padding: '4px 24px',
	},
	danger: {
		background: "#FFEBE6",
		border: "1px solid #FFBDAD",
		borderRadius: 3,
		color: "#B92500",
		fontWeight: 600,
		fontSize: 10,
		fontFamily: 'Inter, sans-serif',
		padding: '4px 24px',
	}
}));

const Tag = (props) => {
	const classes = useStyles();
	return (
		<span className={classes[props.type]}>{props.children}</span>
	);
}

Tag.defaultProps = {
	type: "success",
}

Tag.propTypes = {
	type: PropTypes.string.isRequired,
}

export default Tag;
