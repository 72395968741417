import {
  CalendarOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import CustomAppBar from "../../components/CustomAppBar.js";
import CustomFilterHeader from "../../components/CustomFilterHeader.js";
import AntTable from "../../components/AntTableV2/index.js";
import { Button, message, Modal, Tooltip } from "antd";
import NewLinkModel from "./createUrlLinkModal.js";
import { PostValues } from "../../utils/api-client.js";
import EditUrlModal from "./editUrl.js";

function UrlRotator(props) {
  const handleManualSyncClick = (event) => {};
  const [allUrls, setAllUrls] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [columns, setColumns] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
  const [editUrl, setEditUrl] = React.useState({});
  const [editArrayUrl, setEditArrayUrl] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [requestData, setRequestData] = React.useState({});
  const textAreaRef = useRef(null);

  const RefColumns = [
    {
      title: "FS Link",
      align: "center",
      isTotal: false,
      field: "short_url",
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "date",
      width: 220,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <div ref={textAreaRef} className="break-all">
            <a href={record.short_url} target="_blank" rel="noreferrer">
              {record.short_url}
            </a>{" "}
            <Tooltip title="Copy Url">
              <CopyOutlined
                className="ml-7"
                onClick={() => {
                  handleCopyText(record);
                }}
              />
            </Tooltip>
          </div>
        ),
    },
    {
      title: "Campaign Name",
      align: "center",
      isTotal: false,
      field: "name",
      tooltip: true,
      width: 80,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          record.name
        ),
    },
    {
      title: (
        <div className="flex items-center justify-center">
          Created <CalendarOutlined className="pl-1" />
        </div>
      ),
      colName: "created Date",
      align: "center",
      isTotal: false,
      field: "createdAt",
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "date",
      width: 70,
      defaultSortOrder: "descend",
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          record.createdAt?.split(" ")[0]
        ),
        sorter: (a, b) => {
          a = (a.createdAt || "");
          b = (b.createdAt || "");
          return a > b ? -1 : b > a ? 1 : 0;
        },
        sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "# Rotated",
      align: "center",
      isTotal: false,
      field: "link_rotated",
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "date",
      width: 60,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          record.link_rotated
        ),
    },
    {
      title: "Product Id",
      align: "center",
      isTotal: false,
      field: ["productId"],
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "date",
      width: 100,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <Tooltip title={record.productIds?.join(",")}>
          <span>{record.productIds?.join(",").replace(/(.{15})..+/, "$1…")}</span>
          </Tooltip>
        ),
    },
    {
      title: "KeyWord",
      align: "center",
      isTotal: false,
      field: ["keyword"],
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "date",
      width: 100,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <Tooltip title={record.keywords?.join(",")}>
          <span>{record.keywords?.join(",").replace(/(.{15})..+/, "$1…")}</span>
          </Tooltip>
        ),
    },
    {
      title: "Clicks",
      align: "center",
      isTotal: false,
      field: "click",
      tooltip: true,
      isFilterable: true,
      isSearchable: true,
      type: "date",
      width: 60,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : (
          <a
            // className="underline underline-offset-1"
            onClick={() => {
              //   handleEditLink(record);
            }}
          >
            {" "}
            {record.click || "0"}
          </a>
        ),
    },
    {
      title: "Action",
      align: "center",
      field: "action",
      width: 60,
      render: (record, rowIndex, isLoading) => {
        return (
          <>
            <Tooltip title="Edit Url">
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  handleEditLink(record);
                }}
              ></Button>
            </Tooltip>
            <Tooltip title="Delete Url">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  handleDeleteLink(record);
                }}
              ></Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const urlAPi = async (requestData) => {
    let response = await PostValues("CreateNewUrlApi", requestData);
    return response;
  };

  React.useEffect(() => {
    setColumns(RefColumns);
    getUrlData();
  }, []);


  const prepareData = (urls) => {
    return urls.map((rowData) => {
      let row = { ...rowData };
      row.productIds = row.all_urls?.map((val, index) => {
        let arrProduct = []
        arrProduct.push(val.productId)
        return arrProduct;
      });
      row.keywords = row.all_urls?.map((val, index) => {
        let arrKeywords = []
        arrKeywords.push(val.keywords)
        return arrKeywords;
      });
      return row;
    });
  };

  const getUrlData = async () => {
    let requestData = {
      filters: {
        method: "show",
      },
      pagination: {
        pageNo: 0,
        pageSize: 25,
      },
    };
    setIsLoading(true);
    let urlData = await urlAPi(requestData);
    setRequestData(requestData);
    if (urlData.response) {
      const urlValue = await prepareData(urlData.response.response);
      setAllUrls([...urlValue]);
      setTotalRecords(urlData.response.total[0].total_count);
      setIsLoading(false);
    }
  };
  const onSearch = async (ds, searchText) => {
    let params = { ...requestData };
    if (params.filters) {
      params.filters.search = searchText.trim();
    } else {
      params.filters = {
        search: searchText.trim(),
      };
    }
    setIsLoading(true);
    const data = await urlAPi(params);
    setIsLoading(false);
    if (data.response) {
      const urlData = await prepareData(data.response.response);
      setAllUrls([...urlData]);
      setTotalRecords(data.response.total[0].total_count);
      setIsLoading(false);
    }
    return [];
  };

  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
    extra,
  }) => {
    setIsLoading(true);

    let requestData = {
      filters: {
        method: "show",
        ...filterCondition,
      },
      ...paginationCondition,
      ...sortCondition,
    };
    let urlData = await urlAPi(requestData);
    if (urlData.response) {
      let url = await prepareData(urlData.response.response);
      setAllUrls([...url]);
      setTotalRecords(urlData.response.total[0].total_count);
      setIsLoading(false);
    }
  };
  const handleMarketplaceChange = () => {};

  const isCreateNewLinkModalOpen = () => {
    setIsModalVisible(true);
  };
  const handleEditLink = async (record) => {
    let requestData = {
      filters: {
        short_url: record.short_url,
        method: "edit",
      },
    };
    let editUrl = await urlAPi(requestData);
    if (editUrl.response.Item) {
      setEditUrl(editUrl.response.Item);
      setEditArrayUrl([...editUrl.response?.Item.all_urls]);
    }
    setIsEditModalVisible(true);
  };
  const handleDeleteLink = async (record) => {
    let requestData = {
      filters: {
        short_url: record.short_url,
        method: "delete",
      },
    };
    let deleteUrl = await urlAPi(requestData);
    if (deleteUrl.status) {
      let afterDeleteUrl = allUrls.filter(
        (item) => item.short_url !== record.short_url
      );
      setAllUrls(afterDeleteUrl);
      setTotalRecords(totalRecords - 1);
      message.success("Successfully Deleted");
    }
  };
  function handleCopyText(record) {
    const cb = navigator.clipboard;
    cb.writeText(record.short_url).then(() => message.success("Text copied"));
  }
  const onSearchTextEntered = (searchText) => {
    setSearchText(searchText);
  };

  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <CustomAppBar />
      <CustomFilterHeader
        headerTitle="URL Rotator"
        handleManualSyncClick={handleManualSyncClick}
        handleMarketplaceChange={handleMarketplaceChange}
        onSearchTextEntered={onSearchTextEntered}
        columns={columns}
        rows={allUrls}
        showDateRanges={false}
        isLoading={isLoading}
        showManualSync={false}
        onFilterUpdate={(data) => {}}
        channelHide={true}
        showExportButton={false}
        searchFunction={onSearch}
        publishButton={
          <Button type="primary" onClick={isCreateNewLinkModalOpen}>
            Create New Link
          </Button>
        }
        searchHide={true}
      />
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <AntTable
          columns={RefColumns}
          dataSource={allUrls}
          stickyHeader={{
            offsetHeader: 65,
            // offsetScroll: 216,
          }}
          // scroll={{ x: 1500 }}
          rowKey={(row, rowIndex) => rowIndex}
          isLoading={isLoading}
          hideSummary={true}
          searchText={[searchText]}
          totalRecords={totalRecords}
          onDataChange={onTableChange}
        />
        <NewLinkModel
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          getUrlData={getUrlData}
        />
        <EditUrlModal
          isEditModalVisible={isEditModalVisible}
          setEditIsModalVisible={setIsEditModalVisible}
          editUrl={editUrl}
          setAllUrls={setAllUrls}
          allUrls={allUrls}
          editArrayUrl={editArrayUrl}
          setEditArrayUrl={setEditArrayUrl}
        />
      </div>
    </>
  );
}

export default withRouter(UrlRotator);
