import { Button, message, Spin, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CustomAppBar from "../../components/CustomAppBar";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import { PostValues } from "../../utils/api-client";
import Tabs from "../../components/Tabs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ExportButton from "../../components/Styles/ExportButton";
import ProductTab from "./productTab";
import KeywordsTab from "./SearchTermTable";
import AddProduct from "./addProduct";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import { ReactExcel, readFile, generateObjects } from '@ramonak/react-excel';

const TabsList = [
    "Product",
    "Keywords",
];
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export default function BuyBoxIndex() {

    const [isLoading, setIsLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [marketPlaces, setMarketPlaces] = useState([]);
    const [requestData, setRequestData] = React.useState({});
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [searchText, setSearchText] = useState("");
    const [productItems, setProductItems] = React.useState([])
    const [keywordsTable, setKeywordsTable] = React.useState([])
    const [pageSizeKeywordsTab, setPageSizeKeywordsTab] = React.useState(0)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [keywordRowData, setKeywordRowData] = useState()
    const [isLoadingkeyword, setIsLoadingkeyword] = React.useState(false);
    const [isExportLoading, setIsExportLoading] = React.useState(false);
    const [autoPopup, setAutoPopup] = React.useState(false)
    const [prodData, setProdData] = React.useState('')
    const [channelData, setChannelData] = React.useState('')
    const [visible, setbulkmodalVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [initialData, setInitialData] = useState(undefined);
    const [currentSheet, setCurrentSheet] = useState({});

    const getExportApi = async (requestData) => {
        let response = await PostValues("getOrganisationReportApi", requestData);
        return response;
    };
    const getKeyWordsTrackers = async (requestData) => {
        let response = await PostValues("getKeyWordsTrackers", requestData);
        return response;
    };
    const keywordbulkUpload = async (requestData) => {
        let response = await PostValues("keywordbulkUpload", requestData);
        return response;
    };
    const handleMarketplaceChange = async (
        selectedMarketplaces
    ) => {
        setIsLoading(true);
        setMarketPlaces(selectedMarketplaces);
        getAllData(selectedMarketplaces)
    };
    const handleUpload = (event) => {
        const file = event.target.files[0];
        //read excel file
        readFile(file)
            .then((readedData) => setInitialData(readedData))
            .catch((error) => console.error(error));
    };


    const keywordsTabClick = async (tabData) => {
        setKeywordRowData(tabData)
        setIsLoading(true)
        setTabValue(1)
        let requestData = {
            channels: [tabData.channel],
            filters: {
                product_id: tabData.product_id
            }
        }
        let data = await getKeyWordsTrackers(requestData);
        if (data.Items) {
            setIsLoading(false)
            setKeywordsTable(data.Items)
            setPageSizeKeywordsTab(data.pagination.count)
        }
    }

    useEffect(() => {
        if (tabValue == 0) {
            setKeywordsTable([])
        }
    }, [tabValue])

    const reloadkeydelete = async (id) => {
        setIsLoading(true)
        setTimeout(async () => {
            let requestData = {
                channels: [keywordRowData.channel],
                filters: {
                    product_id: keywordRowData.product_id
                }
            }
            let data = await getKeyWordsTrackers(requestData);
            if (data.Items) {
                upadteProduct()
                setIsLoading(false)
                setKeywordsTable(data.Items)
                setPageSizeKeywordsTab(data.pagination.count)
            }
        }, 2000)

    }

    const reloadApikey = async (product) => {
        setIsLoading(true)
        const productIndex = productItems.indexOf(product)
        const Items = productItems.splice(productIndex, 1);
        setProductItems(productItems)
        setIsLoading(false)
        setTotalRecords(totalRecords - 1)
    }
    const upadteProduct = async () => {
        setIsLoading(true)
        let requestData = {
            channels: marketPlaces.map((mp) => mp.name),
            "pagination": { "pageNo": 0, "pageSize": 10 }
        }
        setRequestData(requestData);
        let data = await getKeyWordsTrackers(requestData);
        if (data.Items) {
            setIsLoading(false)
            setProductItems(data.Items)
            setTotalRecords(data.pagination.count)
        }
    }
    const addProductkey = (prdId, channel) => {
        setAutoPopup(true)
        setProdData(prdId)
        setChannelData(channel)
    }
    const popupFalse = () => {
        setAutoPopup(false)
    }

    const getAllData = async (
        selectedMarketplaces,
        searchText,
        paginationCond,
        sortCond,
        filterCond = {}
    ) => {
        if (searchText) {
            requestData.filters["search"] = searchText;
        }
        if (tabValue === 0) {
            setIsLoading(true)
            let requestData = {
                channels: selectedMarketplaces.map((mp) => mp.name),
                filters: {
                    ...filterCond,
                },
                ...(paginationCond
                    ? paginationCond
                    : {
                        pagination: {
                            pageNo: 0,
                            pageSize: 10,
                        },
                    }),
                ...(sortCond ? sortCond : {}),
            };
            setRequestData(requestData)
            console.log(requestData)
            const data = await getKeyWordsTrackers(requestData);
            if (data.Items) {
                setIsLoading(false)
                setProductItems(data.Items)
                setTotalRecords(data.pagination.count)
            }
        }
        if (tabValue == 1) {
            setIsLoading(true)
            let requestData = {
                channels: [keywordRowData.channel],
                filters: {
                    ...filterCond,
                },
                ...(paginationCond
                    ? paginationCond
                    : {
                        pagination: {
                            pageNo: 0,
                            pageSize: 10,
                        },
                    }),
                ...(sortCond ? sortCond : {})
            }
            requestData.filters["product_id"] = keywordRowData.product_id;
            let data = await getKeyWordsTrackers(requestData);
            if (data.Items) {
                setIsLoading(false)
                setKeywordsTable(data.Items)
                setPageSizeKeywordsTab(data.pagination.count)
            }
        }
    }
    const handleManualSyncClick = (event) => { };
    const onSearchTextEntered = (searchText) => {
        //  setSearchText(searchText);
    };

    const onSearch = async (ds, searchText) => {
        let params = { ...requestData };
        if (params.filters) {
            params.filters.search = searchText;
        } else {
            params.filters = {
                search: searchText,
            };
        }
        if (tabValue == 0) {
            setIsLoading(true);
            const data = await getKeyWordsTrackers(params);
            if (data.Items) {
                setIsLoading(false)
                setProductItems(data.Items)
                setTotalRecords(data.pagination.count);
            }
        }
        if (tabValue == 1) {
            setIsLoading(true)
            params.filters.product_id = keywordRowData.product_id;
            const data = await getKeyWordsTrackers(params);
            if (data.Items) {
                setIsLoading(false)
                setKeywordsTable(data.Items)
                setPageSizeKeywordsTab(data.pagination.count)
            }
        }
        return [];
    };
    const productmodalClose = () => {
        setIsModalVisible(false)
    }
    const ExportAllData = async () => {
        try {
            setIsExportLoading(true);
            let exportJsonBody = {
                params: {
                    channels: marketPlaces.map((mp) => mp.name),
                },
                reportType: "KeywordTracker",
            };
            const data = await getExportApi(exportJsonBody);
            if (data.status) {
                setIsExportLoading(false);
                message.success(
                    "The export is being processed, please check the Downloads page ",
                    10
                );
            } else {
                setIsExportLoading(false);
            }
        } catch (error) {
            setIsExportLoading(false);
        }
    }
    const bulkUploadModal = () => {
        setInitialData(undefined);
        setCurrentSheet({})
        setbulkmodalVisible(true);
    };
    const handleCancel = () => {
        setbulkmodalVisible(false);
    };
    const refreshApiProduct = () => {
        upadteProduct();
    }
    // const refreshApiKeyword = async() =>{
    //     setIsLoading(true)
    //     let requestData = {
    //         channels: [keywordRowData.channel],
    //         filters: {
    //             product_id: keywordRowData.product_id
    //         }
    //     }
    //     let data = await getKeyWordsTrackers(requestData);
    //     if (data.Items) {
    //         setIsLoading(false)
    //         setKeywordsTable(data.Items)
    //         setPageSizeKeywordsTab(data.pagination.count)
    //     }

    // }
    const handleOk = async () => {
        setConfirmLoading(true);
        const result = generateObjects(currentSheet);
        result.forEach((dataSplit) => {
            dataSplit.keyword_list = dataSplit.keyword_list.split('\r\n').filter(e => e.trim().length > 0)
        })
        if (result.length < 25) {
            let res = await keywordbulkUpload(result);
            if (res.status) {
                setTimeout(() => {
                    message.success('File uploaded successfully.Product update will take some time, please refresh the page');
                    // upadteProduct();
                    setbulkmodalVisible(false);
                    setConfirmLoading(false)
                }, 2000)
            } else {
                setConfirmLoading(false)
                message.error(res.message);
            }
        } else {
            message.error('file uploaded is failed. Product list only less then 25 is allowed');
            setConfirmLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{"Forcesight"}</title>
            </Helmet>
            <CustomAppBar />
            <CustomFilterHeader
                headerTitle="Keyword Tracker"
                showDateRanges={false}
                searchHide={true}
                searchFunction={onSearch}
                handleManualSyncClick={handleManualSyncClick}
                handleMarketplaceChange={handleMarketplaceChange}
                onSearchTextEntered={onSearchTextEntered}
                showExportButton={false}
                getExportData={ExportAllData}
                columns={columns}
                bulkUpload={true}
                isLoading={isLoading}
                publishButton={
                    <>
                        {tabValue == 0 &&
                            <Button type="primary" onClick={refreshApiProduct} style={{
                                right: "10px",
                            }} icon={<ReloadOutlined />} size="middle">
                                Refresh
                            </Button>
                            // :
                            // <Button type="primary" onClick={refreshApiKeyword} style={{
                            //     right: "10px",
                            // }} icon={<ReloadOutlined />} size="middle">
                            //     Refresh
                            // </Button>
                        }

                        {tabValue == 0 &&
                            <Button type="primary" onClick={() => {
                                setIsModalVisible(true)
                            }}>
                                Add Product
                            </Button>
                        }
                        {tabValue == 0 ? (
                            <>
                                <Button
                                    type="primary"
                                    style={{
                                        left: "10px",
                                    }}
                                    onClick={bulkUploadModal}
                                >
                                    Bulk Upload
                                </Button>
                                <Modal
                                    title="File Upload"
                                    visible={visible}
                                    onOk={handleOk}
                                    confirmLoading={confirmLoading}
                                    onCancel={handleCancel}
                                    okText="Submit"
                                >
                                    <input
                                        type='file'
                                        accept='.xlsx'
                                        onChange={handleUpload}
                                    />
                                    <Button
                                        icon={<DownloadOutlined />}
                                        href="https://tmp-forcesight-document.s3.ap-south-1.amazonaws.com/keyword_upload_sample.xlsx"
                                        color="transparent"
                                        target="_blank"
                                        download>Sample File
                                    </Button>

                                    <ReactExcel
                                        initialData={initialData}
                                        onSheetUpdate={(currentSheet) => setCurrentSheet(currentSheet)}
                                        activeSheetClassName='inactive-sheet'
                                        reactExcelClassName='react-excel'
                                    />
                                </Modal>
                            </>
                        ) : null}
                    </>
                }
                customToggleProfit={
                    <Spin spinning={isExportLoading}>
                        <div className="ml-6">
                            <ExportButton variant="outlined" onClick={ExportAllData}>
                                Export to CSV
                            </ExportButton>
                        </div>
                    </Spin>
                }
                showManualSync={false}
                paymentContent={true}
                onFilterUpdate={(data) => {
                }}
            />

            <div style={{ marginLeft: 20, marginRight: 20 }} className="-mt-3 ">
                <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <Tabs
                        value={tabValue}
                        list={TabsList}
                        onChange={(value) => {
                            setTabValue(value);
                        }}
                    />
                </div>
                <AddProduct productmodalClose={productmodalClose} addProductkey={addProductkey} upadteProduct={upadteProduct} isModalVisible={isModalVisible} />
                <TabPanel value={tabValue} index={0}>
                    <ProductTab autoPopup={autoPopup} popupFalse={popupFalse} prodData={prodData} channelData={channelData} tableFilterData={(paginationCondition, sortCond, filterCond) => {
                        getAllData(
                            marketPlaces,
                            searchText,
                            paginationCondition,
                            sortCond,
                            filterCond
                        );
                    }} reloadApikey={reloadApikey} upadteProduct={upadteProduct} productItems={productItems} keywordsTabClick={keywordsTabClick} isLoading={isLoading} totalRecords={totalRecords} />
                </TabPanel>
                <TabPanel value={tabValue} disabled={true} index={1} >
                    <KeywordsTab tableFilterData={(paginationCondition, sortCond, filterCond) => {
                        getAllData(
                            marketPlaces,
                            searchText,
                            paginationCondition,
                            sortCond,
                            filterCond
                        );
                    }} upadteProduct={upadteProduct} keywordsTable={keywordsTable} keywordRowData={keywordRowData} isLoading={isLoading} pageSize={pageSizeKeywordsTab} reloadkeydelete={reloadkeydelete} />
                </TabPanel>
            </div>

        </>
    );
}
