import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Form, Input, Button, Modal, message, Breadcrumb, Image, Spin, Tooltip } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PostValues } from "../../utils/api-client";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { Tooltip as RechartsTooltip } from "recharts";
import Trend from '../../images/download.png'
import AntTable from "../../components/AntTableV2/index.js";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          className="heightInitial text-left"
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
      >
        {children}
        <EditOutlined onClick={toggleEdit} />
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
export default function ProductTable(props) {
  const [rowData, setRowData] = React.useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmloading, setConfirmLoading] = useState(false);
  const [isModalVisibleGraph, setIsModalVisibleGraph] = useState(false);
  const [bulkProductDelete, setBulkProductDelete] = useState([])
  const [keywordId, setKeywordId] = useState([])
  const [dataGraph, setDataGraph] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [graphloading, setGraphloading] = useState(false)
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const addKeywordsApi = async (requestData) => {
    let response = await PostValues("addKeyWordsTrackers", requestData);
    return response;
  };

  const columns = [
    {
      title: "Search Terms",
      align: "center",
      field: "keyword",
      type: "string",
      tooltip: true,
      width: 120,
      render: (record, value, rowIndex) => (
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record?.keyword !== undefined ? (
          <>
            <Tooltip title={record.keyword}>
              {
                record.keyword?.replace(/(.{30})..+/, "$1…")
              }
            </Tooltip>
          </>
        ) : ('-')
      )
    },
    {
      title: "Scheduled Time",
      align: "center",
      field: "sync_at",
      tooltip: true,
      type: "string",
      width: 120,
      editable: true,
      sorter: (a, b) => {
        a = (a.sync_at || "");
        b = (b.sync_at || "");
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "sync_at",
        title: "Scheduled Time",
        align: "center",
        handleSave: async (cell) => {
          setIsLoading(true)
          console.log(cell)
          let reqBody = {
            "channel": cell.channel,
            "metric": "keyword",
            "sync_at": cell.sync_at,
            "product_id": cell.product_id,
            "product_keyword_2": cell.product_keyword_2,
            "keyword_list": [cell.keyword]
          }
          const data = await addKeywordsApi(reqBody);
          if (data.status) {
            message.success('keyword Update Success');
            props.reloadkeydelete()
          }
        },
      }),
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record?.sync_at !== undefined ? (
          record?.sync_at
        ) : ('-')
    },
    {
      title: "Organic Rank",
      align: "center",
      field: "organic_count",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 120,
      sorter: (a, b) => {
        a = (a.organic_count || "");
        b = (b.organic_count || "");
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Sponsored Ad Rank",
      align: "center",
      isTotal: false,
      field: "ad_count",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 100,
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        a = (a.ad_count || "");
        b = (b.ad_count || "");
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record?.ad_count !== undefined ? (
          record?.ad_count
        ) : (
          "-"
        )
    },
    {
      title: "Page",
      align: "center",
      isTotal: false,
      field: "organic_page",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 120,
      render: (record, rowIndex, isLoading) =>
        isLoading ? (
          <div className="item">
            <div className="item-title" />
          </div>
        ) : record?.organic_page !== undefined ? (
          record?.organic_page
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        a = (a.organic_page || "");
        b = (b.organic_page || "");
        return a > b ? -1 : b > a ? 1 : 0;
      },
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Organic Trend",
      align: "center",
      isTotal: false,
      field: "keyword",
      tooltip: true,
      isSearchable: true,
      type: "string",
      width: 120,
      render: (record, rowIndex, isLoading) => {
        return (
          <>
            <Image
              preview={{ visible: false }}
              style={{
                height: 36,
                width: 36,
                objectFit: "cover",
                border: "1px solid #EAE6FF",
              }}
              onClick={() => {
                setIsModalVisibleGraph(true);
                graphClick(record)
              }}
              src={Trend}
            />
          </>
        );
      }
    },
    {
      title: "Action",
      align: "center",
      field: "action",
      width: 120,
      render: (record, rowIndex, isLoading) => {
        return (
          <Button icon={<DeleteOutlined />} onClick={() => {
            setRowData(record)
            setIsModalVisible(true)
          }} className="rounded-full">
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    setIsLoading(props.isLoading)
  }, [props.isLoading])
  const handleCancelGraph = () => {
    setIsModalVisibleGraph(false);
  };

  const graphClick = async (rowData) => {
    setGraphloading(true)
    let reqBody = {
      "channels": [rowData.channel],
      "filters": {
        "product_id": rowData.product_id,
        "metric": "graph",
        "keyword": rowData.keyword,
        "product_keyword_2": rowData.product_keyword_2
      }
    }
    let data = await PostValues('getKeyWordsTrackers', reqBody)
    if (data.Items) {
      setGraphloading(false)
      setDataGraph(data.Items)
    }
  }

  const onTableChange = async ({
    paginationCondition,
    sortCondition,
    filterCondition,
  }) => {
    props.tableFilterData(paginationCondition, sortCondition, filterCondition);
  };

  const deleteKeyWordsTrackers = async (requestData) => {
    let response = await PostValues("deleteKeyWordsTrackers", requestData);
    return response;
  };
  const handleOk = async () => {
    setConfirmLoading(true)
    let requestData = {
      "metric": "keyword",
      "keyword_list": [rowData.product_keyword_2]
    }
    let data = await deleteKeyWordsTrackers(requestData);
    if (data.status) {
      props.reloadkeydelete(rowData.keyword)
      setConfirmLoading(false)
      setIsModalVisible(false);
      message.success('keyword Deleted Successfully');
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onSelectAll: (selected, selectedRows, changeRows) => {
      setKeywordId([])
      setBulkProductDelete(selectedRows)
      selectedRows.map((id) => {
        setKeywordId(productId => [...productId, id.product_keyword_2]);
      })
      if (selectedRowKeys.length !== 0) {
        setSelectedRowKeys([]);
      }
    },
    onChange: (selectedRowKeys, selectedRows) => {
      setKeywordId([])
      setBulkProductDelete(selectedRows)
      selectedRows.map((id) => {
        setKeywordId(productId => [...productId, id.product_keyword_2]);
      })
      setSelectedRowKeys(selectedRowKeys);
    }
  };
  const bulkDelte = async () => {
    setIsLoading(true)
    let requestData = {
      "metric": "keyword",
      "keyword_list": keywordId
    }
    let data = await deleteKeyWordsTrackers(requestData);
    if (data.status) {
      props.reloadkeydelete()
      setIsLoading(false)
      setSelectedRowKeys([]);
      message.success('keyword Deleted Successfully');
    }
  }

  return (
    <>
      {props.keywordRowData &&
        <>
          <div style={{ margin: "10px 0" }}>
            <Breadcrumb class="flex  ...">
              <Breadcrumb.Item ><b>Product Id </b>:
                <Button type="link">
                  <a
                    href={props.keywordRowData.product_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.keywordRowData.product_id || ""}
                  </a>
                </Button>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {
            bulkProductDelete?.length > 0 &&
            <div class="flex justify-end ...">
              <div><Button onClick={bulkDelte}>
                Bulk Delete
              </Button></div>
            </div>
          }
        </>
      }
      <AntTable
        rowSelection={rowSelection}
        columns={columns}
        components={components}
        dataSource={props?.keywordsTable}
        rowKey={(row, rowIndex) => rowIndex}
        isLoading={isLoading}
        totalRecords={props?.pageSize}
        hideSummary
        stickyHeader={{ offsetHeader: 65 }}
        onDataChange={onTableChange}
      />
      <Modal title="Trend Graph" visible={isModalVisibleGraph} footer={false} onCancel={handleCancelGraph}>
        <Spin spinning={graphloading}>
          <LineChart
            width={500}
            height={300}
            data={dataGraph.reverse()}
            margin={{
              top: 5,
              right: 50,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="created_at" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Line type="monotone" dataKey="organic_count" stroke="#1890ff" />
          </LineChart>
        </Spin>
      </Modal>
      <Modal title="Are you sure to delete this Keyword?" confirmLoading={confirmloading} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        Keyword : <b>{rowData.keyword}</b>
      </Modal>
    </>
  );
}
