import React from 'react';
import {makeStyles, TextField} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	label: {
		fontWeight: 500,
		fontSize: 14,
		color: '#44444F',
		marginBottom: 12,
	},
	disabled: {
		backgroundColor: '#F4F5F7',
	},
	input: {
		fontWeight: 500,
		fontSize: 14,
	},
	error: {
		minHeight: 28,
		color: "#F2453D"
	}
});

const MaterialInput = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			{props.label && (
				<div className={classes.label}>{props.label}</div>
			)}
			<TextField
				variant="outlined"
				fullWidth={props.fullWidth}
				disabled={props.disabled}
				value={props.value}
				placeholder={props.placeholder}
				className={props.disabled ? classes.disabled : {}}
				inputProps={{ className: classes.input }}
				onChange={props.onChange}
				error={Boolean(props.errorMessage)}
			/>
			<div className={classes.error}>
				{props.errorMessage}
			</div>
		</div>
	);
}

MaterialInput.defaultProps = {
	label: null,
	value: "",
	disabled: false,
	fullWidth: false,
	onChange: (e) => {},
	errorMessage: "",
	placeholder: "",
}

MaterialInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	onChange: PropTypes.func,
	errorMessage: PropTypes.string,
	placeholder: PropTypes.string,
}

export default MaterialInput;
