import React from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    Tooltip,
    BarChart,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Label,
    PieChart,
    Pie,
    Legend,
    Cell,
    LabelList
} from "recharts";
import { Skeleton } from 'antd';

const ReviewInsightChart: React.FC = (props) => {
    const lifeTimeRating = Object.entries(props?.ratingReviewGrap).map(([key, value]) => ({ star: key, count: value }));
    const arr = Object.entries(props?.reviewGrap).map(([key, value]) => ({ star: key, count: value }));
    return (
        <div className="w-full justify-between flex flex-wrap">
            <div className="width-30 tablet:w-full border rounded bg-white p-6">
                <b> Lifetime Ratings </b>
                {props.isLoading ? <Skeleton /> :
                    <ResponsiveContainer height={300}>
                        <BarChart
                            data={lifeTimeRating}
                            margin={{ top: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="star">
                                <Label value="Star" offset={0} position="insideBottom" />
                            </XAxis>
                            <YAxis label={{ value: 'SKU Count', angle: -90, position: 'insideLeft', fontSize:'13px', fontWeight:'bold' }} />
                            <Tooltip />
                            <Bar dataKey="count" fill="rgb(76, 120, 255)">
                                <LabelList fill="#4C78FF" dataKey="count" position="top" />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                }
            </div>
            <div className="width-30 tablet:w-full border rounded bg-white p-6">
                <b> Product Reviews & Ratings for selected Period </b>
                {props.isLoading ? <Skeleton /> :
                    <ResponsiveContainer height={300}>
                        <BarChart data={props.summaryData.ratings_reviews_graph} margin={{ top: 20,bottom: 5}}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" label={{ value: 'Star', offset: 0, position: 'insideBottom' }} />
                            <YAxis label={{ value: 'SKU Count', angle: -90, position: 'insideLeft',fontSize:'13px', fontWeight:'bold' }} />
                            <Tooltip />
                            <Bar dataKey="value.ProductReviewCount" name="Reviews" fill="#FD8C24" >
                                <LabelList fill="rgb(253, 140, 36)" dataKey="value.ProductReviewCount" position="top" />
                            </Bar>
                            <Bar dataKey="value.ProductRatingCount" name="Ratings" fill="#4C78FF" >
                                <LabelList fill="#4C78FF" dataKey="value.ProductRatingCount" position="top" />
                            </Bar>
                            <Legend layout="vetical" verticalAlign="top" align="right" />
                        </BarChart>
                    </ResponsiveContainer>
                }
            </div>
            <div className="width-30 tablet:w-full border rounded bg-white p-6">
                <b> Seller Reviews for selected Period </b>
                {props.isLoading ? <Skeleton /> :
                    <ResponsiveContainer height={300} >
                        <BarChart
                            data={arr}
                            margin={{ top: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="star">
                                <Label value="Star" offset={0} position="insideBottom" />
                            </XAxis>
                            <YAxis label={{ value: 'Review Count', angle: -90, position: 'insideLeft',fontSize:'13px', fontWeight:'bold' }} />
                            <Tooltip />
                            <Bar dataKey="count" fill="rgb(76, 120, 255)">
                                <LabelList fill="#4C78FF" dataKey="count" position="top" />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                }
            </div>
        </div>


    )
};

export default ReviewInsightChart;

