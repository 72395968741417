import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import Fuse from "fuse.js";
import debounceFn from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
const querystring = require("querystring");

const getGroupedColumnKeysFromChildren = (column, keys = []) => {
  for (const child of column.children) {
    if (child.children && Array.isArray(child.children)) {
      // If child has children, recurse
      keys = getGroupedColumnKeysFromChildren(child, keys);
    } else {
      if (!child.dataIndex) {
        continue;
      }

      if (Array.isArray(child.dataIndex)) {
        keys = [...keys, child.dataIndex.join(".")];
        continue;
      }

      keys = [...keys, child.dataIndex];
    }
  }

  return keys;
};

const createDefaultFuseKeys = (dataSource, columns) => {
  const firstRecord = dataSource ? dataSource[0] : {};
  const keys = columns
    .map((column) => {
      const { dataIndex, children } = column;
      // check if grouped column
      if (children && Array.isArray(children)) {
        const keys = getGroupedColumnKeysFromChildren(column, []);
        return keys ? keys.flat() : [];
      }
      // ant table allows nested objects with array of strings as dataIndex
      if (Array.isArray(dataIndex)) {
        return dataIndex.join(".");
      }

      // If in actual dataIndex the record is object literal but column specified as string, throw error.
      // Even though it's something you shouldn't do based on ant table's API, since users will see fuse.js `value.trim is not a function error` I'm throwing error.
      if (
        firstRecord &&
        Object.prototype.toString.call(firstRecord[dataIndex]) ===
          "[object Object]" &&
        typeof dataIndex === "string"
      ) {
        throw new Error(
          `'${dataIndex}' is an object in dataSource. But dataIndex is given as string. If it is an object, use array of strings as dataIndex.`
        );
      }
      return dataIndex;
    })
    .filter((dataIndex) => !!dataIndex)
    .flat(10)
    .filter((dataIndex) => typeof dataIndex === "string"); // after flattening max depth 10, if there are still arrays, ignore

  return keys;
};

export const SearchTableInput = ({
  searchFunction = null,
  dataSource,
  setDataSource,
  debounce = true,
  inputProps = {
    placeholder: "Search...",
  },
  fuzzySearch = false,
  columns,
  onChange,
  location,
  history,
  fuseProps,
}) => {
  const [query, setQuery] = useState("");
  const [showSearch, setshowSearch] = useState(true);
  const allData = useRef();
  const fuse = useRef();

  const _fuseProps = React.useMemo(() => {
    return {
      keys: createDefaultFuseKeys(dataSource, columns),
      threshold: fuzzySearch ? 0.6 : 0,
      // includeScore: true,
      // minMatchCharLength: 3,
      // useExtendedSearch: true,

      // includeMatches: true,
      // findAllMatches: true,
      ...fuseProps,
    };
  }, [fuseProps, dataSource, columns, fuzzySearch]);

  const searchTable = (_dataSource, searchTerm = "") => {
    if (searchTerm === "" || !fuse || !fuse.current) {
      return allData.current;
    }

    const newResults = fuse.current.search(searchTerm).map((res) => res.item);
    return newResults;
  };

  const searchTableDebounced = React.useCallback(
    debounceFn(
      async (dataSource, searchTerm, searchFn) => {
        const results = searchFn ? await searchFn(dataSource, searchTerm) : [];
        setDataSource(results);
      },
      1000,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    onChange(e);
    setQuery(value);

    if (debounce) {
      searchTableDebounced(
        dataSource,
        value,
        searchFunction === null ? searchTable : searchFunction
      );
    } else {
      const results = searchFunction
        ? searchFunction(dataSource, value)
        : searchTable(dataSource, value);
      setDataSource(results);
    }
  };

  useEffect(() => {
    if (!dataSource) {
      return;
    }

    allData.current = [...dataSource];
    fuse.current = new Fuse(dataSource, _fuseProps);
  }, [dataSource, _fuseProps]);
  useEffect(() => {
    let parsedObject = querystring.parse(
      location.search.substr(1, location.search.length)
    );
    if (parsedObject.search === "") {
      setQuery("");
      onChange({ target: { value: "" } });
      history.replace(location.pathname);
    }
  }, [location.search]);
  useEffect(() => {
    // If dataSource updates dynamically (for example, swr or react-query mutates) and the input box is not empty,
    // It should keep the new dataSource filtered if there is a value in input box
    if (!dataSource || !query) {
      return;
    }

    if (debounce) {
      searchTableDebounced(
        dataSource,
        query,
        searchFunction ? searchFunction : searchTable
      );
    } else {
      const results = searchFunction
        ? searchFunction(dataSource, query)
        : searchTable(dataSource, query);
      setDataSource(results);
    }
  }, [
    query,
    // dataSource,
    // searchTableDebounced,
    // searchFunction,
    // setDataSource,
    debounce,
  ]);

  return (
    <Input
      value={query}
      onChange={handleInputChange}
      onFocus={() => {
        setshowSearch(false);
      }}
      onBlur={() => {
        if (query) {
          setshowSearch(false);
        } else {
          setshowSearch(true);
        }
      }}
      allowClear={true}
      placeholder="Search..."
      prefix={showSearch && <SearchOutlined className="mr-1" />}
      {...inputProps}
      className={` ${
        query !== "" ? "border-gray" : "bg-GhostWhite  border-white border-gray"
      } p-2 rounded hover:border-gray hover:bg-white`}
    />
  );
};

export default withRouter(SearchTableInput);
