import { Checkbox, Modal, Button, Upload, message, Spin } from "antd";
import difference from "lodash/difference";
import flatten from "lodash/flatten";
import get from "lodash/get";
import set from "lodash/set";
import union from "lodash/union";
// import Papa from "papaparse";
import React, { Fragment, useEffect } from "react";
import ExportButton from "../Styles/ExportButton";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const getFieldsFromColumns = (columns) => {
  const fields = {};
  (columns || []).forEach((column) => {
    const { title, key, dataIndex, colName } = column;
    const fieldName = (
      Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex
    )
      ? Array.isArray(dataIndex)
        ? dataIndex.join(".")
        : dataIndex
      : key;
    if (fieldName) {
      // console.log(fieldName);

      if (typeof title === "string") {
        set(fields, fieldName, title);
      }
      if (typeof colName === "string") {
        set(fields, fieldName, colName);
      }
    }
  });
  // console.log(fields);
  return fields;
};

const getFieldStr = (obj) => {
  let str = "";
  Object.keys(obj).map((field) => {
    if (typeof obj[field] === "string" || typeof obj[field] === "number") {
      str = str + "-,&.,&- " + obj[field];
    } else {
      str = getFieldStr(obj[field]);
    }
  });
  return str;
};

const dataFieldStr = (obj, key) => {
  let str = "";
  if (obj) {
    if (typeof obj[key] === "string" || typeof obj[key] === "number") {
      str = obj[key];
    } else {
      str = dataFieldStr(obj[key], key);
    }
  } else {
    str = "";
  }
  return str;
};
const ObjFieldData = (recordValue, fieldValue) => {
  let str = "";
  if (recordValue)
    Object.keys(fieldValue).map((field) => {
      // console.log(field);
      // console.log(
      //   recordValue,
      //   fieldValue,
      //   typeof fieldValue[field],
      //   dataFieldStr(recordValue, field)
      // );
      if (
        typeof fieldValue[field] === "string" ||
        typeof fieldValue[field] === "number"
      ) {
        str = str + "-,&.,&- " + dataFieldStr(recordValue, field);
      } else {
        str = ObjFieldData(recordValue[field], fieldValue[field]);
      }
    });
  return str;
};
const cleanupDataSource = (dataSource, exportFieldNames, selectedFields) => {
  if (!dataSource || dataSource.length === 0) {
    return { data: [], fields: [] };
  }
  const newData = [...dataSource];
  const fieldsArr = selectedFields.map((fieldName) => {
    const fieldValue = get(exportFieldNames, fieldName);
    if (typeof fieldValue === "string" || typeof fieldValue === "number") {
      return fieldValue;
    }
    let fieldStr = getFieldStr(fieldValue);
    return fieldStr.split("-,&.,&- ").filter((data) => data); //getFieldStr(fieldValue);

    return fieldValue.header || "";
  });
  const fields = flatten(fieldsArr);
  const data = newData.map((record, rowIndex) => {
    let selectedStr = selectedFields.map((fieldName) => {
      let fieldValue = get(exportFieldNames, fieldName);
      const recordValue = get(record, fieldName) || "";
      if (typeof fieldValue === "string" || typeof fieldValue === "number") {
        return recordValue || "";
      }
      // console.log(recordValue, fieldValue);
      let ObjStr = ObjFieldData(recordValue, fieldValue);
      let ObjArr = ObjStr.split("-,&.,&- ").filter((data) => data);
      return ObjArr.length ? ObjArr : [""];

      return (fieldValue || {}).formatter
        ? fieldValue.formatter(recordValue, record, rowIndex)
        : null;
    });
    // if (record.OrderID === "405-0055678-1699531") {
    // console.log(selectedStr);
    // }
    return flatten(selectedStr);
  });
  return [fields, ...data];
};

export const ExportTableButton = (props) => {
  const {
    dataSource = [],
    fileName,
    fields,
    disabled,
    btnProps,
    columns = [],
    showColumnPicker = false,
    bulkUpload,
  } = props;

  const [showModal, setShowModal] = React.useState(false);

  const fieldsOrColumns = fields ? fields : getFieldsFromColumns(columns);
  const [selectedFields, setSelectedFields] = React.useState(() => {
    if (fields) {
      return Object.keys(fields);
    } else if (columns) {
      return Object.keys(getFieldsFromColumns(columns));
    }

    return [];
  });
  const [visible, setbulkmodalVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [uploaddata, setBulkuploadPost] = React.useState([]);
  const [isExportLoading,setIsExportLoading] = React.useState(false);

  const bulkUploadModal = () => {
    setbulkmodalVisible(true);
  };

  const handleCancel = () => {
    setbulkmodalVisible(false);
  };

  const bulkUploadantd = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    accept: ".csv",
    maxCount: 1,
    onChange(info) {
      if (info.file.status === "done") {
        setBulkuploadPost(info);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleOk = () => {
    var token = localStorage.getItem('token')
    setConfirmLoading(true);
    const data = new FormData();
    data.append("file", uploaddata.file.originFileObj);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_DASHBOARD_URL}/mark_returns_csv`,
      headers: {
        Accept: "application/json",
        "Content-Type":
          "application/json",
        "X-FS-Authorization": token,
      },
      data,
    })
      .then((response) => {
        message.success(`${uploaddata.file.name} file uploaded successfully`);
        setbulkmodalVisible(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        message.error(`${uploaddata.file.name} need to be valid file`);
        setConfirmLoading(false);
      });
  };
  useEffect(() => {
    if (fields) {
      setSelectedFields(Object.keys(fields));
    } else if (columns) {
      setSelectedFields(Object.keys(getFieldsFromColumns(columns)));
    }
  }, [fields, columns]);

  const handleDownloadCSV = React.useCallback(async () => {
    setIsExportLoading(true);
    let exportData = [];
    if (props.getExportData) {
      exportData = await props.getExportData();
    }
    if (!dataSource && !exportData.length) {
      return;
    }

    let selectedFieldsInOriginalOrder = Object.keys(fieldsOrColumns).filter(
      (name) => selectedFields.indexOf(name) > -1
    );

    const data = cleanupDataSource(
      exportData?.length ? exportData : dataSource,
      fieldsOrColumns,
      selectedFieldsInOriginalOrder
    );
    const csv = window.Papa.unparse(data, {
      greedy: true,
      header: false,
    });
    const blob = new Blob([csv]);
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = `${fileName || "table"}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setShowModal(false);
    setIsExportLoading(false);
  }, [dataSource, fieldsOrColumns, selectedFields, fileName]);

  const handleCheckboxChange = React.useCallback(
    (key, checked) => {
      let newSelectedFields = [...selectedFields];
      if (checked) {
        newSelectedFields = union(newSelectedFields, [key]);
      } else {
        newSelectedFields = difference(newSelectedFields, [key]);
      }
      setSelectedFields(newSelectedFields);
    },
    [selectedFields]
  );

  return (
    <Fragment>
      <Spin spinning={isExportLoading}>
      <ExportButton
        variant="outlined"
        onClick={() =>
          showColumnPicker ? setShowModal(true) : handleDownloadCSV()
        }
        disabled={disabled}
        {...btnProps}
      >
        {props.children ? props.children : `Export to CSV`}
      </ExportButton>
      </Spin>
      {props.bulkUpload == "bulkUpload" ? (
        <>
          <Button
            type="primary"
            style={{
              left: "10px",
            }}
            onClick={bulkUploadModal}
          >
            Bulk Upload
          </Button>
          <Modal
            title="File Upload"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            okText="Submit"
          >
            <Upload {...bulkUploadantd} maxFileSize= {28400000}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Modal>
        </>
      ) : null}
      {/*<Button*/}
      {/*  onClick={() =>*/}
      {/*    showColumnPicker ? setShowModal(true) : handleDownloadCSV()*/}
      {/*  }*/}
      {/*  disabled={disabled}*/}
      {/*  {...btnProps}*/}
      {/*>*/}
      {/*  {props.children ? props.children : `Export to CSV`}*/}
      {/*</Button>*/}
      {showColumnPicker ? (
        <Modal
          visible={showModal}
          onOk={() => handleDownloadCSV()}
          onCancel={() => setShowModal(false)}
          width={400}
          okButtonProps={{
            disabled: selectedFields.length < 1,
            title:
              selectedFields.length < 1
                ? "Please select at least one column."
                : null,
          }}
          okText={"Export"}
          title={"Select columns to export"}
        >
          <div className="flex flex-column align-start">
            {Object.entries(fieldsOrColumns).map(([key, value]) => {
              return (
                <Checkbox
                  key={key}
                  style={{ padding: 0, margin: 0 }}
                  defaultChecked={true}
                  checked={selectedFields.indexOf(key) > -1}
                  onChange={(e) => handleCheckboxChange(key, e.target.checked)}
                >
                  {typeof value === "string"
                    ? value
                    : (value || {}).header
                    ? (value || {}).header
                    : ""}
                </Checkbox>
              );
            })}
          </div>
        </Modal>
      ) : null}
    </Fragment>
  );
};

export default ExportTableButton;
